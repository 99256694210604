import { ReactElement } from 'react'

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

import { HOLDS_STATUS_LABELS, HoldEnumStatusType } from 'pages/Holds/types'

const HoldsStatusRadioGroup = ({
  disabled,
  value,
  onChange
}: {
  disabled: boolean
  value: unknown
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: HoldEnumStatusType
  ) => void
}): ReactElement => (
  <FormControl fullWidth disabled={disabled}>
    <RadioGroup
      aria-labelledby="hold-status"
      name="holdStatus"
      value={value}
      onChange={(event, value) => onChange(event, value as HoldEnumStatusType)}
    >
      <FormControlLabel
        value={HoldEnumStatusType.ON_HOLD}
        control={<Radio />}
        label={HOLDS_STATUS_LABELS[HoldEnumStatusType.ON_HOLD]}
      />
      <FormControlLabel
        value={HoldEnumStatusType.PURCHASED}
        control={<Radio />}
        label={HOLDS_STATUS_LABELS[HoldEnumStatusType.PURCHASED]}
      />
      <FormControlLabel
        value={HoldEnumStatusType.CANCELED}
        control={<Radio />}
        label={HOLDS_STATUS_LABELS[HoldEnumStatusType.CANCELED]}
      />
    </RadioGroup>
  </FormControl>
)

export default HoldsStatusRadioGroup
