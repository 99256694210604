import { ReactElement, useState } from 'react'
import { Alert } from '@mui/material'
import { SNACKBAR_DURATION } from 'pages/constants'
import BaseSnackbar from 'components/BaseSnackbar'

interface PropsT {
  itemUnavailable: boolean
}

const AddItemErrorSnackbar = (props: PropsT): ReactElement => {
  const { itemUnavailable } = props
  const [isDismissed, setIsDismissed] = useState(false)

  const errorMessage = itemUnavailable
    ? `Item sold out and can't be added`
    : 'Issue adding item. Try again.'

  const onClose = (_event: React.SyntheticEvent, reason: string) => {
    if (reason === 'timeout') {
      setIsDismissed(true)
    }
    return
  }

  return (
    <BaseSnackbar
      autoHideDuration={SNACKBAR_DURATION}
      onClose={onClose}
      open={!isDismissed}
    >
      <Alert severity="error">{errorMessage}</Alert>
    </BaseSnackbar>
  )
}

export default AddItemErrorSnackbar
