import { ReactElement } from 'react'
import { Avatar, Box, Grid, Typography, Stack } from '@mui/material'
import { useGetCustomerQuery } from 'services/employeeExperienceApi'
import Skeleton from 'components/Skeleton'
import PageError from 'components/PageError'

const errorTitle = 'Issue getting customer information.'

const LoadingSkeleton = () => (
  <Stack direction="column" spacing={1}>
    <Skeleton.Text textVariant="body2" />
    <Skeleton.Text textVariant="body2" />
  </Stack>
)

export type PropsT = {
  customerId: string
}

const CustomerInfo = (props: PropsT): ReactElement => {
  const { customerId } = props
  const { data, isLoading, isError, error, endpointName } = useGetCustomerQuery(
    customerId,
    {
      skip: !customerId
    }
  )

  const ErrorMessage = () => (
    <PageError
      isInlineAlert={true}
      errorTitle={errorTitle}
      errorDetails={{
        endpoint: endpointName,
        errorData: error,
        identifiers: {
          customerId: customerId,
          curationId: 'NO-DATA',
          employeeId: 'NO-DATA',
          shoppingSessionId: 'NO-DATA'
        }
      }}
    />
  )

  if (isLoading) return <LoadingSkeleton />

  if (isError) return <ErrorMessage />

  if (!data) return <></>

  const { firstName, lastName } = data
  const avatarInitial = firstName.charAt(0)

  return (
    <>
      <Grid item mb={2} display="flex">
        <Avatar sx={{ height: '47px', width: '47px' }}>{avatarInitial}</Avatar>
        <Box display="flex" flexDirection="column" pl={1}>
          <Typography fontWeight="bold" variant="body2">
            Customer
          </Typography>
          <Typography variant="body2">
            {firstName} {lastName}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

export default CustomerInfo
