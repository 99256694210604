import { ReactElement, ReactNode, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNavigation } from 'contexts/NavigationContext'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import { SvgIcon } from '@mui/material'
import SubPageAppBar from 'components/SubPageAppBar'

interface PropsT {
  children: ReactNode
  icon?: { component: React.ComponentType<typeof SvgIcon>; ariaLabel: string }
  onBackClick?: () => void
  title: string
}

const SubPageTemplateWithPageAppBar = (props: PropsT): ReactElement => {
  const { children, icon, onBackClick, title } = props
  const history = useHistory()
  const { setDisplayAppBar: setDisplayGlobalAppBar } = useNavigation()

  useEffect(() => {
    setDisplayGlobalAppBar(false)
    return () => {
      setDisplayGlobalAppBar(true)
    }
  }, [setDisplayGlobalAppBar])

  const handleBackClick = () => {
    onBackClick ? onBackClick() : history.goBack()
  }

  return (
    <BoxWithGladlySpace>
      <SubPageAppBar
        handleBackClick={handleBackClick}
        icon={icon}
        title={title}
      />
      {children}
    </BoxWithGladlySpace>
  )
}

export default SubPageTemplateWithPageAppBar
