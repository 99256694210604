import { useRef, ReactElement } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Typography,
  Box,
  Checkbox,
  IconButton,
  DialogActions,
  Button
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { useGetStoreQuery } from 'services/holdsApi'
import oktaTokenStorage from 'utils/okta-token-utils'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'

type PropsT = {
  isOpen: boolean
  isFilterSelected: boolean
  handleApply: (checked: boolean) => void
  handleClose: () => void
  isChecked: boolean
  setIsChecked: (isChecked: boolean) => void
}

const StoreAvailabilityModal = ({
  isOpen,
  isFilterSelected,
  isChecked,
  handleApply,
  handleClose,
  setIsChecked
}: PropsT): ReactElement => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const { data, isLoading, isError, isFetching, error, endpointName } =
    useGetStoreQuery(
      {
        using: 'employeeId',
        employeeId
      },
      { refetchOnMountOrArgChange: false }
    )

  const isLoadingOrFetching = isLoading || isFetching
  const checkboxRef = useRef<HTMLInputElement>(null)

  const onCancel = () => {
    handleClose()
    setIsChecked(isFilterSelected)
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle display="flex">
        <Box
          sx={{
            display: 'flex',
            flex: '1',
            alignItems: 'center'
          }}
        >
          <Typography fontWeight={'bold'} fontSize="23px">
            Store Availability
          </Typography>
        </Box>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isLoadingOrFetching && <LoadingSpinner sx={{ mb: '16px' }} />}
      {!isLoadingOrFetching && isError && (
        <DialogContent>
          <PageError
            errorDetails={{ endpoint: endpointName, errorData: error }}
            errorTitle="Issue getting your store -"
            customBoxStyle={{ pt: 2, px: 0 }}
          />
        </DialogContent>
      )}
      {!isLoadingOrFetching && !isError && data && (
        <>
          <DialogContent>
            <FormControlLabel
              label={
                <>
                  <Typography display="inline" fontSize="16px">
                    Available today at your store:{' '}
                  </Typography>
                  <Typography
                    display="inline"
                    fontWeight={'bold'}
                    fontSize="16px"
                  >
                    {data?.name ?? 'Store not found'}
                  </Typography>
                </>
              }
              control={
                <Checkbox
                  checked={isChecked}
                  onClick={() => setIsChecked(!isChecked)}
                  inputRef={checkboxRef}
                  style={{ marginLeft: '16px' }}
                />
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                handleApply(checkboxRef?.current?.checked ?? false)
              }
            >
              <Typography fontWeight={'bold'}>Apply</Typography>
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default StoreAvailabilityModal
