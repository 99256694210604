import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'

const UnpublishAlert = ({
  unpublishError
}: {
  unpublishError: unknown
}): ReactElement => {
  return (
    <BasePageAlert
      alertTitle={'Issue deleting board – try again'}
      errorDetails={{ errorData: unpublishError }}
    />
  )
}

export default UnpublishAlert
