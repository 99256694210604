import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FeatureFlagsT } from 'types/FeatureFlags'
import setStandardHeaders from 'utils/set-standard-headers'

export const configmanApi = createApi({
  reducerPath: 'configmanApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CONFIGMAN_API_URL,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })
      headers.set('accept', 'application/json')
      // Configman API Key is NOT a secret + the response does NOT contain any sensitive data
      // Discussion: https://nordstrom.slack.com/archives/C02GZ3LBZ8D/p1721681880899279?thread_ts=1720637963.082349&cid=C02GZ3LBZ8D
      headers.set('Authorization', 'fdbebca2-a4a9-4530-ae7d-70e0aa4922fc')
      headers.set('NordApiVersion', '3')

      return headers
    }
  }),
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<FeatureFlagsT, void>({
      query: () => ({
        url: '/',
        method: 'GET',
        params: {
          groups: 'StylingCuration',
          keys: 'isAnniversaryUSPreview,isAnniversaryUSPublic,isAnniversaryUSEarlyAccess4,isAnniversaryUSEarlyAccess3,isAnniversaryUSEarlyAccess2,isAnniversaryUSEarlyAccess1,isAnniversaryUSEarlyAccess'
        }
      }),
      transformResponse: ({
        features
      }: {
        features: { name: string; active: boolean; payload: string }[]
      }) => {
        return features.reduce((value, feature) => {
          value[feature.name] = {
            active: feature.active,
            payload: feature.payload
          }

          return value
        }, {} as FeatureFlagsT)
      }
    })
  })
})

export const { useGetFeatureFlagsQuery } = configmanApi
