export enum BOARD_TYPE_FILTERS {
  ALL = 'all',
  CUSTOMER = 'customer',
  PUBLIC = 'public'
}

export enum DATE_RANGE {
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  ALL_TIME = 'allTime'
}

export enum BOARD_GROUP {
  CUSTOMER = 'customer',
  PUBLIC = 'public'
}

export type CurationAttributionT = {
  id: string
  attributedAt: string
  customer: {
    name?: string
  }
  curation: {
    id: string
    title: string
  }
  order: {
    id: string
  }
  product: {
    brandName?: string
    color?: string
    imageUrl?: string
    productName?: string
    purchasePrice?: number
    size?: string
    status?: string
    style?: number
  }
}

export type GetCurationAttributionsQueryDataT = {
  attributions: CurationAttributionT[]
  meta: { count: number }
}

export type GetCurationAttributionsQueryParamsT = {
  perPage?: number
  page?: number
  startDate?: string
  endDate?: string
  group?: BOARD_TYPE_FILTERS
}
