import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { FeatureFlagsT } from 'types/FeatureFlags'
import { useGetFeatureFlagsQuery } from 'services/configmanApi'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import PageError from 'components/PageError/PageError'

const context = createContext<FeatureFlagsT>({})

export const useFeatureFlags = (): FeatureFlagsT => useContext(context)

export const FeatureFlagsProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsT>({})
  const {
    data: featureFlagData,
    endpointName,
    error,
    isLoading,
    isError
  } = useGetFeatureFlagsQuery()

  useEffect(() => {
    if (featureFlagData) {
      setFeatureFlags(featureFlagData)
    }
  }, [featureFlagData, setFeatureFlags])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <PageError errorDetails={{ endpoint: endpointName, errorData: error }} />
    )
  }

  return <context.Provider value={featureFlags}>{children}</context.Provider>
}
