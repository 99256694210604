import { ReactElement } from 'react'
import { Container, Grid } from '@mui/material'
import { CurationProductT } from 'types/Curation'
import { EditItemsProductT } from 'types/Product'
import EditCurationItem from '../EditCurationItem'

type PropsT = {
  publishedCurationItems: EditItemsProductT[]
  publishedCurationProducts: CurationProductT[] | undefined
  shouldShowSizes: boolean
  deactivatedCurationProductIds: number[]
  notes: Record<number, Record<string, string>>
  handleClickForPdp: (webStyleId: string) => void
}

const EditCurationItemsList = (props: PropsT): ReactElement => {
  const {
    deactivatedCurationProductIds,
    shouldShowSizes,
    publishedCurationItems,
    publishedCurationProducts,
    notes,
    handleClickForPdp
  } = props

  return (
    <Container disableGutters>
      <Grid container spacing={3}>
        {publishedCurationItems.map((publishedCurationItem, index) => {
          const curationProduct =
            publishedCurationProducts &&
            publishedCurationProducts.find((product) => {
              return product.rmsSku === publishedCurationItem.rmsSku
            })
          const productRemoved =
            curationProduct &&
            deactivatedCurationProductIds.includes(curationProduct.id)
              ? true
              : false
          return (
            <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
              <EditCurationItem
                product={publishedCurationItem}
                shouldShowSize={shouldShowSizes}
                curationProduct={curationProduct}
                productRemoved={productRemoved}
                note={curationProduct && notes[curationProduct?.id]?.note}
                handleClickForPdp={handleClickForPdp}
              />
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

export default EditCurationItemsList
