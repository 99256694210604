import { ReactElement } from 'react'
import { Container } from '@mui/material'
import SubPageTemplateWithPageAppBar from 'components/SubPageTemplateWithPageAppBar'
import DraftsBanner from './components/DraftsBanner'
import DraftsContent from './components/DraftsContent'

const DRAFTS_TITLE = 'Drafts'

const Drafts = (): ReactElement => {
  return (
    <SubPageTemplateWithPageAppBar title={DRAFTS_TITLE}>
      <DraftsBanner />
      <Container sx={{ mb: 3 }}>
        <DraftsContent />
      </Container>
    </SubPageTemplateWithPageAppBar>
  )
}

export default Drafts
