import { Box, Typography } from '@mui/material'
import { Fragment, ReactElement } from 'react'

import { ReduxMessage, messageStatusView } from 'types/Twilio'
import { getDateByFormat } from 'utils/date-utils'
import {
  hasMessageFailedToBeDelivered,
  isSupportedMessageType
} from 'services/twilioSlice/utils'
import { colors } from '../../../../constants'
import MediaContainer from './components/MediaContainer'
import Linkify from '../Linkify'
import MessageStatus from 'components/MessageStatus'

const SingleMessage = ({
  message,
  stylistEmail,
  employeeId,
  setClickedMessage,
  setIsMessageStatusModalOpen,
  isMobileDevice
}: {
  message: ReduxMessage
  stylistEmail?: string
  employeeId?: string
  setClickedMessage: (arg: ReduxMessage) => void
  setIsMessageStatusModalOpen: (arg: boolean) => void
  isMobileDevice: boolean
}): ReactElement => {
  const isMediaMessage = message.type === 'media'
  const isStylist =
    message.author === stylistEmail || message.author === employeeId

  const outerBoxStyles = isStylist
    ? {
        paddingRight: 0,
        textAlign: 'right'
      }
    : {
        paddingRight: isMobileDevice ? '86px' : 0
      }

  const mediaMessageStyles = isStylist
    ? {
        marginLeft: '12px',
        marginRight: isMobileDevice ? '106px' : '16px',
        textAlign: 'right'
      }
    : {
        marginLeft: '-12px',
        marginRight: 0
      }

  const getBackgroundColor = () => {
    const isSupported = isSupportedMessageType(message)
    let backgroundColor = colors.white
    if (isStylist && (message.type === 'text' || !isSupported)) {
      backgroundColor = colors.fromStylistContainer
    } else if (!isStylist && (message.type === 'text' || !isSupported)) {
      backgroundColor = colors.fromCustomerContainer
    }
    return backgroundColor
  }

  const handleMessageStatusClick = () => {
    if (message) {
      setClickedMessage(message)
      setIsMessageStatusModalOpen(true)
    }
  }

  const hasFailed = hasMessageFailedToBeDelivered(message)

  const messageSplitByLines = message.body?.split('\n')

  const declareStyle = isMediaMessage ? mediaMessageStyles : outerBoxStyles

  return (
    <>
      <>
        {message && (
          <Box sx={{ ...declareStyle, width: '100%' }}>
            <Box
              sx={{
                fontSize: isMobileDevice ? 16 : 18,
                textAlign: 'left',
                maxWidth: '500px',
                minWidth: '100px',
                padding: '8px 12px',
                margin: '4px 16px',
                backgroundColor: getBackgroundColor(),
                borderRadius: '4px',
                display: 'inline-block'
              }}
            >
              {isSupportedMessageType(message) ? (
                message.type === 'text' ? (
                  <Linkify>
                    <Typography id={message.sid} variant="body2">
                      {messageSplitByLines?.map((line, index) =>
                        index === messageSplitByLines.length - 1 ? (
                          line
                        ) : (
                          <Fragment key={`fragment-${index}-${line}`}>
                            {line}
                            <br data-testid={`br-${index}-${line}`} />
                          </Fragment>
                        )
                      ) || ''}
                    </Typography>
                  </Linkify>
                ) : (
                  <MediaContainer
                    media={message.attachedMedia}
                    messageSid={message.sid}
                  />
                )
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontStyle="italic"
                  id={message.sid}
                >
                  Message type not supported
                </Typography>
              )}
              <div style={{ textAlign: 'right', paddingTop: '4px' }}>
                <Typography variant="overline" color="text.secondary">
                  {getDateByFormat({
                    date: message?.dateCreated,
                    format: 'h:mm A'
                  })}
                </Typography>
              </div>
            </Box>
          </Box>
        )}
      </>
      {isStylist && hasFailed && (
        <Box sx={{ ...outerBoxStyles }}>
          <MessageStatus
            onClick={handleMessageStatusClick}
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginX: !isMobileDevice ? '14px' : 0,
              paddingBottom: '12px',
              marginBottom: '8px'
            }}
            view={messageStatusView.MESSAGE_THREAD}
          />
        </Box>
      )}
    </>
  )
}

export default SingleMessage
