import { ReactElement } from 'react'
import { Container, Typography, Link } from '@mui/material'

const NotFound = (): ReactElement => {
  return (
    <Container maxWidth="sm" sx={{ my: 3 }}>
      <Typography component="h1" fontWeight="bold" variant="h6" sx={{ mb: 3 }}>
        Oops! We can&apos;t find the page you&apos;re looking for.
      </Typography>
      <Link href="/">Go home.</Link>
    </Container>
  )
}

export default NotFound
