import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box
} from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  useSavePushSubscriptionMutation,
  useDeletePushSubscriptionMutation
} from 'services/notificationsApi'
import MobileAndDesktopContainer from 'components/MobileAndDesktopContainer'
import { isMessagingUser } from 'utils/userPermissions'
import { checkBrowserClient } from 'utils/userAgentDetector'
import PushSubscriptionToggle from './components/PushSubscriptionToggle'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import BasePageAlert from 'components/BasePageAlert'
import UnsubscribingPushNotificationError from './components/PushNotificationUnsubscribeError/PushNotificationUnsubscribeError'

const SettingsHome = (): ReactElement => {
  const history = useHistory()
  const isMobileDevice = checkBrowserClient.isMobile()
  const [
    isSavePushNotificationSubscriptionError,
    setIsSavePushNotificationSubscriptionError
  ] = useState<boolean>(false)
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const savePushNotificationErrorMessage = 'Issues enabling push notifications.'

  const goToProfile = () => {
    history.push('/settings/profile')
  }

  const [
    isErrorUnsubscribingPushNotification,
    setIsErrorUnsubscribingPushNotification
  ] = useState(false)
  const [pushNotificationErrorMessage, setPushNotificationErrorMessage] =
    useState('')
  const [savePushSubscriptionTrigger] = useSavePushSubscriptionMutation()
  const [deletePushSubscriptionTrigger] = useDeletePushSubscriptionMutation()
  const savePushNotificationSubscription = (
    pushSubscription: PushSubscriptionJSON
  ) => {
    return savePushSubscriptionTrigger(pushSubscription).unwrap()
  }

  const deletePushNotificationSubscription = (
    pushSubscription: PushSubscriptionJSON
  ) => {
    return deletePushSubscriptionTrigger(pushSubscription).unwrap()
  }

  const shouldDisplayNotificationsToggle = isMessagingUser()

  return (
    <Box sx={{ backgroundColor: '#F9FAFA', height: '100vh' }}>
      <MobileAndDesktopContainer>
        <MobileAndDesktopContainer sx={{ maxWidth: 1040 }}>
          <>
            <Typography
              variant="h6"
              sx={{
                paddingTop: '16px',
                marginLeft: isMobileDevice ? '16px' : '4px'
              }}
            >
              Profile & Settings
            </Typography>
            {isSavePushNotificationSubscriptionError && (
              <div style={{ marginTop: '24px' }}>
                <BasePageAlert
                  alertTitle={savePushNotificationErrorMessage}
                  isDismissable={true}
                  errorDetails={{ errorData: savePushNotificationErrorMessage }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  shouldShowRefresh={true}
                />
              </div>
            )}
            {isErrorUnsubscribingPushNotification && (
              <UnsubscribingPushNotificationError
                isErrorUnsubscribingPushNotification={
                  isErrorUnsubscribingPushNotification
                }
                pushNotificationErrorMessage={pushNotificationErrorMessage}
                setIsErrorUnsubscribingPushNotification={
                  setIsErrorUnsubscribingPushNotification
                }
              />
            )}
            <ListItem
              sx={{
                backgroundColor: 'white',
                marginY: '16px',
                cursor: 'pointer'
              }}
              onClick={goToProfile}
              data-testid="goToProfileButton"
            >
              <ListItemText>View and edit profile</ListItemText>
              <ListItemIcon sx={{ minWidth: 24 }}>
                <KeyboardArrowRightIcon />
              </ListItemIcon>
            </ListItem>
            {shouldDisplayNotificationsToggle && (
              <ListItem sx={{ backgroundColor: 'white' }}>
                <ListItemText>
                  Enable push notifications on this device
                </ListItemText>
                <PushSubscriptionToggle
                  savePushNotificationSubscription={
                    savePushNotificationSubscription
                  }
                  setIsSavePushNotificationSubscriptionError={
                    setIsSavePushNotificationSubscriptionError
                  }
                  deletePushNotificationSubscription={
                    deletePushNotificationSubscription
                  }
                  setIsErrorUnsubscribingPushNotification={
                    setIsErrorUnsubscribingPushNotification
                  }
                  setPushNotificationErrorMessage={
                    setPushNotificationErrorMessage
                  }
                />
              </ListItem>
            )}
            {isCopyToClipboardSuccess && (
              <CopiedErrorSnackbar
                setCopyToClipboardSuccess={setCopyToClipboardSuccess}
              />
            )}
          </>
        </MobileAndDesktopContainer>
      </MobileAndDesktopContainer>
    </Box>
  )
}

export default SettingsHome
