import {
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { v4 } from 'uuid'
import { useNavigation } from 'contexts/NavigationContext'
import { ReactElement, useEffect, useState, useCallback } from 'react'
import { useInterval } from 'react-use'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import {
  useGetCheckoutPageQuery,
  usePublishCurationMutation,
  useRemoveFromBagMutation,
  useSaveCurationAsDraftMutation
} from 'services/curationSvc'
import {
  useGenerateTitleAndDescriptionMutation,
  useRetrieveShoppingTokenMutation
} from 'services/employeeExperienceApi'

import HitASnagAlert from 'components/HitASnagAlert'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import BasePageAlert from 'components/BasePageAlert'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'

import FashionMapSearchDrawer from 'pages/FashionMapSearchDrawer'
import SnapshotDrawer from 'pages/SnapshotDrawer'
import ValidationAlert from 'pages/CurationReview/components/ValidationAlert'
import PublishAlert from 'pages/CurationReview/components/PublishAlert'
import TokenExpiredAlert from 'pages/CurationReview/components/TokenExpiredAlert'
import SyncErrorAlert from 'pages/CurationReview/components/SyncErrorAlert'
import RecentItemsDrawer from 'pages/RecentItemsDrawer'
import ScannerDrawer from 'pages/ScannerDrawer'
import AbandonOrContinueDialog from '../AbandonOrContinueDialog'
import CancelButton from '../CancelButton'
import CancelOrContinueDialog from '../CancelOrContinueDialog'
import CurationItemsList from 'components/CurationItemsList'
import CurationInProgressSnackbar from '../CurationInProgressSnackbar'
import CurationTitleAndDescriptionEntry from '../CurationTitleAndDescription/CurationTitleAndDescription'
import EmptyCurationMessage from '../EmptyCurationMessage'
import FashionMapSearchButton from 'components/FashionMapSearchButton'
import ItemsListHeader from '../ItemsListHeader'
import { isFeatureEnabledForUser, isMessagingUser } from 'utils/userPermissions'
import NordstromIFrame from 'components/NordstromIFrame'
import PublishButton from '../PublishButton'
import RemoveItemAlert from '../RemoveItemAlert'
import RecentItemsButton from 'components/RecentItemsButton'
import ReviewButtonGrid from 'components/ReviewButtonGrid'
import ScanButton from 'components/ScanButton'
import QuickLinksButton from 'components/QuickLinksButton'
import QuickLinksDialog from 'components/QuickLinksDialog'
import CurationMenuBar from '../CurationMenuBar'
import SaveAsDraftAlert from '../SaveAsDraftAlert'
import SaveAsDraftButton from '../SaveAsDraftButton'
import ShopButton from 'components/ShopButton'
import SyncButton from 'components/SyncButton'
import { useGetConsentedCustomerQuery } from 'services/clientelingApi'
import { useCreateConversationMutation } from 'services/twilioApi'

import {
  AbandonCurationArgsT,
  CurationReviewLocationT,
  CurationT,
  CURATION_TYPE
} from 'types/Curation'
import { ValidationResultT, ValidationStatus } from 'types/FormValidation'

import { setCustomerId } from 'app/customerSlice'
import { useAppDispatch, useIframeConfig } from 'app/hooks'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import useIsTokenExpired from 'hooks/useIsTokenExpired'
import oktaTokenStorage from 'utils/okta-token-utils'
import { scrollToTop } from 'utils/scrollIntoView'
import { copyToClipboard } from 'utils/copyToClipboard'
import {
  RECENT_ITEMS_HASH,
  SNAPSHOT_BASE_HASH,
  SNAPSHOT_DRAWER_WIDTH
} from 'pages/constants'
import {
  IFRAME_PATH,
  SET_TITLE_REQUIRED_ERROR,
  MIN_NUMBER_OF_ITEMS,
  SET_IN_PROGRESS_CURATION,
  SET_SHOPPING_SESSION_TOKEN,
  SET_FASHION_MAP_SEARCH_DRAWER_OPEN,
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  UPDATE_RECENT_ITEM_VIEWS,
  UPDATE_SCANNER_VIEWS,
  UPDATE_FASHION_MAP_VIEWS,
  UPDATE_SEED_ITEM_REC_VIEWS
} from '../../constants'

import {
  usePersistedCurationReviewReducer,
  CurationReviewDispatchContext
} from './CurationReviewReducer'
import LoadingBackdrop from 'components/LoadingBackdrop'
import {
  validateDescription,
  validateItemError,
  validateTitle
} from 'utils/curationValidation'
import determineShopButtonText from 'utils/determineShopButtonText'
import { CurationInProgressDialog } from 'pages/CurationReview/components/CurationReviewContainer/CurationReviewContainer'
import { saveDraftNotes } from 'utils/draftNotes'
import { saveDraftOrderedItems } from 'utils/draftOrderedItems'
import {
  generateNewRelicLogs,
  generateSaveAsDraftNewRelicLogs,
  generateProductDiscoveryAttributes
} from 'utils/newRelicCustomLogHelper'
import {
  determineProductSource,
  ProductDiscovery,
  ProductSourceType
} from 'utils/determineProductSourceHelper'
import useCurationFashionMapFeatures from 'utils/useCurationFashionMapFeatures'
import { resetCuration, setCuration } from 'app/curationSlice'
import { saveDraftProductDiscovery } from 'utils/draftProductDiscovery'
import { useScanningFeatures } from 'utils/useScanningFeatures'
import ItemRecommendations from '../SeedItemRecommendations/ItemRecommendations'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'

const NORDSTROM_BASE_URL = process.env.REACT_APP_NORDSTROM_URL

interface PropsT {
  handleAbandon?: (abandonCurationArgs: AbandonCurationArgsT) => void
  handleCancel: (abandonCurationArgs: AbandonCurationArgsT) => void
  curationInProgressDialog: CurationInProgressDialog
  initCheckoutToken: string
  inProgressCuration: CurationT
}

const CurationReview = (props: PropsT): ReactElement => {
  const {
    handleAbandon,
    handleCancel,
    curationInProgressDialog,
    initCheckoutToken,
    inProgressCuration
  } = props

  const appDispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation<CurationReviewLocationT | undefined>()
  const theme = useTheme()
  const isLargeScreenSize = useMediaQuery(theme.breakpoints.up('lg'))
  const { setDisplayAppBar } = useNavigation()
  const {
    isItemLimitEnabled,
    isAnniversaryUSPreview,
    isAnniversaryUSPublic,
    isAnniversaryUSEarlyAccess4,
    isAnniversaryUSEarlyAccess3,
    isAnniversaryUSEarlyAccess2,
    isAnniversaryUSEarlyAccess1,
    isAnniversaryUSEarlyAccess
  } = useFeatureFlags()
  const shouldShowFashionMap = useCurationFashionMapFeatures()
  const shouldShowScanButton = useScanningFeatures()

  const isCustomerInitiatedCuration =
    inProgressCuration.type === CURATION_TYPE.CUSTOMER_INITIATED
  const isOutsideQueueRequest =
    isCustomerInitiatedCuration && !inProgressCuration.ocpId
  const isCustomerInitiatedWithOcpId =
    isCustomerInitiatedCuration && !!inProgressCuration.ocpId
  const isSellerInitiatedCuration =
    inProgressCuration.type === CURATION_TYPE.SELLER_INITIATED
  const isPublicBoard =
    inProgressCuration.type === CURATION_TYPE.SELLER_INITIATED &&
    !inProgressCuration.ocpId

  const [validationResult, setValidationResult] =
    useState<ValidationResultT | null>(null)
  const [isInManualErrorSession, setIsInManualErrorSession] = useState(false)
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const [isQuickLinksDialogOpen, setIsQuickLinksDialogOpen] = useState(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [isScanDrawerOpen, setIsScanDrawerOpen] = useState(false)
  const [containerMargin, setContainerMargin] = useState('')
  const [didUserClickFinish, setDidUserClickFinish] = useState(false)
  const [didConversationCreationFail, setDidConversationCreationFail] =
    useState(false)
  const [isConsented, setIsConsented] = useState(false)
  const [newFetchedConversationSid, setNewFetchedConversationSid] = useState('')
  const [state, dispatch] = usePersistedCurationReviewReducer()
  const {
    initialIframePathAndTitle,
    setIframePathAndTitle,
    iframePathAndTitle
  } = useIframeConfig()
  const { data: customerData, refetch: refetchCustomerData } =
    useGetConsentedCustomerQuery(
      {
        customerId: inProgressCuration.ocpId || ''
      },
      {
        skip: !inProgressCuration.ocpId || isCustomerInitiatedCuration
      }
    )

  const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const { hash } = useLocation()
  const isSnapshotOpen = hash.startsWith(`#${SNAPSHOT_BASE_HASH}`)
  const mobile = customerData?.customer.mobile
  const reformattedMobileNumber = mobile ? `+1${mobile}` : ''

  const { isSeedItemRecsEnabled } = useFeatureFlags()
  const accessGroupsPayload =
    isSeedItemRecsEnabled?.payload as AccessGroupsFeatureFlagT
  const seedItemRecsEnabled = isFeatureEnabledForUser(accessGroupsPayload)

  const {
    notes,
    title,
    titleError,
    description,
    descriptionError,
    isFashionMapSearchDrawerOpen,
    orderedItems,
    shoppingToken,
    productDiscovery
  } = state

  const [
    generateTitleAndDescription,
    { isError: isGenerateTitleDescError, isLoading: isGenerateTitleDescLoading }
  ] = useGenerateTitleAndDescriptionMutation()

  const [
    publishCuration,
    {
      endpointName: publishCurationEndpointName,
      error: publishCurationError,
      isSuccess: isPublishCurationSuccess,
      isLoading: isPublishCurationLoading,
      isError: isPublishCurationError,
      originalArgs: publishCurationOriginalArgs
    }
  ] = usePublishCurationMutation()

  const [
    fetchToken,
    {
      data: fetchTokenData,
      endpointName: fetchTokenEndpoint,
      error: fetchTokenError,
      isError: isFetchTokenError,
      isLoading: isFetchTokenLoading,
      originalArgs: fetchTokenOriginalArgs
    }
  ] = useRetrieveShoppingTokenMutation()

  const [
    removeItem,
    {
      endpointName: removeItemEndpoint,
      error: removeItemError,
      isLoading: isRemoveItemLoading,
      isError: isRemoveItemError,
      originalArgs: removeItemArgs
    }
  ] = useRemoveFromBagMutation()

  const [
    saveCurationAsDraft,
    {
      endpointName: saveAsDraftEndpoint,
      error: saveCurationAsDraftError,
      isError: isSaveCurationAsDraftError,
      isLoading: isSaveCurationAsDraftLoading,
      originalArgs: saveCurationAsDraftOriginalArgs
    }
  ] = useSaveCurationAsDraftMutation()

  useEffect(() => {
    if (inProgressCuration) {
      const { id, preferredEmployeeId, shoppingSessionId, type } =
        inProgressCuration
      appDispatch(
        setCuration({ id, preferredEmployeeId, shoppingSessionId, type })
      )
      if (inProgressCuration.ocpId) {
        appDispatch(setCustomerId(inProgressCuration.ocpId))
      }
      dispatch({
        type: SET_IN_PROGRESS_CURATION,
        payload: {
          id: inProgressCuration.id
        }
      })
    }
    return () => {
      appDispatch(resetCuration())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgressCuration])

  //TODO change this to dispatch an action that tells the reducer the form was validated
  const checkTitleEntered = () => {
    if (!title) {
      dispatch({
        type: SET_TITLE_REQUIRED_ERROR
      })
    }
  }

  const validateForm = (
    title: string,
    titleError: string,
    descriptionError: boolean,
    currentNumberOfItems: number
  ): ValidationResultT => {
    let validationStatus = ValidationStatus.PASS

    //TODO dispatch an action that says form was validated?
    checkTitleEntered()

    const validationTitleError = validateTitle(title, titleError)
    const validationDescriptionError = validateDescription(descriptionError)
    const underLimit = currentNumberOfItems < MIN_NUMBER_OF_ITEMS
    const overLimit =
      isItemLimitEnabled?.active &&
      currentNumberOfItems > Number(isItemLimitEnabled?.payload)
    const validationItemError = validateItemError(underLimit, overLimit)

    if (
      validationTitleError !== undefined ||
      validationDescriptionError !== undefined ||
      validationItemError !== undefined
    ) {
      validationStatus = ValidationStatus.FAIL
    }

    return {
      validationStatus: validationStatus,
      titleError: validationTitleError,
      descriptionError: validationDescriptionError,
      itemError: validationItemError,
      itemLimit: Number(isItemLimitEnabled?.payload),
      validationId: v4()
    }
  }

  const linkToPublishedCuration = `${NORDSTROM_BASE_URL}/curation/${inProgressCuration.id}`
  const publishedCurationLinkShouldBeCopied =
    isSellerInitiatedCuration ||
    (!isSellerInitiatedCuration && !inProgressCuration.ocpId)

  const handlePublish = () => {
    const validationResult = validateForm(
      title,
      titleError,
      descriptionError,
      currentNumberOfItems
    )

    if (
      reformattedMobileNumber &&
      validationResult.validationStatus === ValidationStatus.PASS &&
      isMessagingUser()
    ) {
      linkMessaging()
    }

    setValidationResult(validationResult)

    const publishedAt = new Date()
    const startedAtDate = new Date(inProgressCuration.startedAt)
    const startedToPublishedElapsedTime =
      publishedAt.getTime() - startedAtDate.getTime()

    const productSources = productsWithNotes.map(
      (product) => product.source
    ) as ProductSourceType[]

    const productDiscoveryAttributes =
      generateProductDiscoveryAttributes(productSources)

    const attributes = {
      curationId: inProgressCuration.id,
      shoppingSessionId: shoppingSessionId,
      ocpId: inProgressCuration.ocpId,
      curationType: inProgressCuration.type,
      preferredEmployeeId: inProgressCuration.preferredEmployeeId,
      numberOfItemsInBag: currentNumberOfItems,
      validationStatus: validationResult.validationStatus,
      startedToPublishedElapsedTime: startedToPublishedElapsedTime,
      webStyleIds: productsWithNotes.map((product) => product.webStyleId),
      ...productDiscoveryAttributes
    }
    generateNewRelicLogs('publishCuration', attributes)

    if (validationResult.validationStatus === ValidationStatus.FAIL) {
      scrollToTop()
    }
    if (validationResult.validationStatus === ValidationStatus.PASS) {
      publishCuration({
        curationId: inProgressCuration.id,
        description: description,
        products: productsWithNotes,
        title: title
      })
        .unwrap()
        .catch(() => {
          scrollToTop()
        })
      publishedCurationLinkShouldBeCopied &&
        copyToClipboard(linkToPublishedCuration)
    }

    //TODO clear customerId from the store slice
  }

  const handleGenerateTitleAndDescription = () => {
    const customerNote = inProgressCuration.customerNote
    const occasions = inProgressCuration.occasions
    const customerNoteAndOccasions = {
      ...(customerNote && { customerNote }),
      ...(occasions && { occasions })
    }

    checkoutBagData &&
      checkoutBagData.length > 0 &&
      generateTitleAndDescription({
        checkoutBagData,
        curationId: inProgressCuration.id,
        ...customerNoteAndOccasions
      })
        .unwrap()
        .then((resp) => {
          dispatch({
            type: UPDATE_TITLE,
            payload: { title: resp.title }
          })
          dispatch({
            type: UPDATE_DESCRIPTION,
            payload: { description: resp.description }
          })
          return
        })
        .catch(() => null)
  }

  const [val, setValue] = useState(0)
  const [reRenderDelay, setReRenderDelay] = useState<null | number>(null)

  useInterval(function triggerReRenderAfterDelay() {
    setValue(val + 1)
  }, reRenderDelay)

  useEffect(
    function setAndClearReRenderDelay() {
      setReRenderDelay(10_000)
      return setReRenderDelay(null)
    },
    [reRenderDelay]
  )

  const shoppingSessionId = inProgressCuration.shoppingSessionId || ''
  const checkoutToken = fetchTokenData?.token || initCheckoutToken

  const { isTokenExpired: isShoppingTokenExpired } = useIsTokenExpired(
    shoppingToken || ''
  )

  const { isTokenExpired: isCheckoutTokenExpired } =
    useIsTokenExpired(checkoutToken)

  const {
    data: checkoutBagData,
    endpointName: checkoutBagEndpointName,
    error: checkoutBagError,
    isFetching: isGetCheckoutDataFetching,
    isLoading: isGetCheckoutDataLoading,
    isSuccess: isGetCheckoutDataSuccess,
    isError: isGetCheckoutDataError,
    originalArgs: checkoutBagOriginalArgs,
    refetch: refetchCheckoutBagData
  } = useGetCheckoutPageQuery(
    {
      shopperId: shoppingSessionId,
      token: checkoutToken
    },
    {
      skip: !checkoutToken || !shoppingSessionId || isCheckoutTokenExpired,
      refetchOnFocus: true
    }
  )

  const productsWithNotes =
    checkoutBagData && orderedItems
      ? orderedItems.reduce((acc, rmsSku, i) => {
          const matchingItem = checkoutBagData.find(
            (data) => data.rmsSku === rmsSku
          )
          if (matchingItem) {
            acc.push({
              rmsSku: matchingItem.rmsSku,
              webStyleId: matchingItem.webStyleId,
              note: notes[matchingItem.rmsSku],
              productOrder: i + 1,
              source: determineProductSource(
                matchingItem,
                productDiscovery as ProductDiscovery
              )
            })
          }
          return acc
        }, [] as Array<{ rmsSku: string; webStyleId?: string; note?: string; productOrder: number; source: ProductSourceType }>)
      : []

  const currentNumberOfItems = checkoutBagData?.length || 0
  const showEmptyState = !checkoutBagData?.length && isGetCheckoutDataSuccess
  const customerId = inProgressCuration.ocpId

  const overLimit =
    isItemLimitEnabled?.active &&
    currentNumberOfItems > Number(isItemLimitEnabled?.payload)
  const underLimit = validationResult
    ? currentNumberOfItems < MIN_NUMBER_OF_ITEMS
    : false

  const newRelicAttributes = {
    curationId: inProgressCuration.id,
    type: inProgressCuration.type,
    preferredEmployeeId: inProgressCuration.preferredEmployeeId,
    ocpId: inProgressCuration.ocpId
  }

  const openNordstromIFrame = () => {
    fetchToken({
      curationId: inProgressCuration.id,
      employeeEmail,
      employeeId
    })
      .unwrap()
      .then((fulfilled) => {
        if (fulfilled) {
          dispatch({
            type: SET_SHOPPING_SESSION_TOKEN,
            payload: { shoppingToken: fulfilled.token }
          })

          handleOpenIFrame()
        }
        return
      })
      .catch(() => {
        if (isQuickLinksDialogOpen) {
          setIsQuickLinksDialogOpen(false)
        }
        scrollToTop()
      })
  }

  const refetchToken = useCallback(() => {
    fetchToken({
      curationId: inProgressCuration.id,
      employeeEmail,
      employeeId
    })
  }, [fetchToken, inProgressCuration.id, employeeEmail, employeeId])

  const refetchTokenAndBag = useCallback(() => {
    refetchToken()
    refetchCheckoutBagData()
  }, [refetchToken, refetchCheckoutBagData])

  useEffect(() => {
    if (checkoutBagError) {
      if ('status' in checkoutBagError) {
        const errorMessage = JSON.stringify(checkoutBagError.data)
        const tokenExpired = errorMessage?.includes('Invalid token')
        if (tokenExpired) {
          refetchTokenAndBag()
        }
      }
    }
  }, [checkoutBagError, refetchTokenAndBag])

  const handleShopButtonClick = () => {
    const attributes = {
      curationId: inProgressCuration.id,
      shoppingSessionId: shoppingSessionId,
      ocpId: inProgressCuration.ocpId,
      curationType: inProgressCuration.type,
      preferredEmployeeId: inProgressCuration.preferredEmployeeId,
      numberOfItemsInBag: currentNumberOfItems
    }
    generateNewRelicLogs('ncomIFrameOpened', attributes)
    openNordstromIFrame()
  }

  const handleFashionMapSearchButtonClick = () => {
    const attributes = {
      curationId: inProgressCuration.id,
      ocpId: inProgressCuration.ocpId,
      curationType: inProgressCuration.type,
      preferredEmployeeId: inProgressCuration.preferredEmployeeId,
      numberOfItemsInBag: currentNumberOfItems
    }
    generateNewRelicLogs('fmsDrawerOpened', attributes)
    dispatch({
      type: SET_FASHION_MAP_SEARCH_DRAWER_OPEN,
      payload: true
    })
  }

  const onSnapshotOpen = () => {
    history.replace({ hash: SNAPSHOT_BASE_HASH })
  }

  const onSnapshotClose = () => {
    history.replace({ hash: undefined })
  }

  const handleOpenIFrame = () => {
    if (isQuickLinksDialogOpen) {
      setIsQuickLinksDialogOpen(false)
    }

    if (isSnapshotOpen && isLargeScreenSize) {
      history.push({
        pathname: '/review/build',
        hash: location.hash
      })
    } else {
      history.push({
        pathname: '/review/build'
      })
    }
  }

  const handleCloseIFrame = useCallback(() => {
    if (isSnapshotOpen) {
      history.push({
        pathname: '/review',
        hash: location.hash
      })
    } else {
      history.push({ pathname: '/review' })
    }

    setIframePathAndTitle(initialIframePathAndTitle)
  }, [
    history,
    setIframePathAndTitle,
    initialIframePathAndTitle,
    isSnapshotOpen,
    location
  ])

  const handleReturnHome = useCallback(() => {
    history.push({
      pathname:
        isSellerInitiatedCuration && customerId
          ? `/customers/details/${customerId}`
          : '/styleboards',
      state: { sourcePath: window.location.pathname }
    })
  }, [history, isSellerInitiatedCuration, customerId])

  const handleClickForPdp = (webStyleId: string) => {
    setIframePathAndTitle({
      path: `s/${webStyleId}`,
      title: 'nordstrom.com product details'
    })
    handleOpenIFrame()
  }

  const handleOpenPDPFromFMS = (webStyleId: string) => {
    const attributes = { curationId: inProgressCuration.id }
    generateNewRelicLogs('viewFromFashionMapClick', attributes)
    dispatch &&
      dispatch({
        type: UPDATE_FASHION_MAP_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleOpenPDPFromSeedItemRecs = (webStyleId: string) => {
    const attributes = { curationId: inProgressCuration.id, webStyleId }
    generateNewRelicLogs('viewFromSeedItemRecsClick', attributes)
    dispatch &&
      dispatch({
        type: UPDATE_SEED_ITEM_REC_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleOpenPDPFromScanner = (webStyleId: string) => {
    dispatch &&
      dispatch({
        type: UPDATE_SCANNER_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleOpenPDPFromRecentItems = (webStyleId: string) => {
    dispatch &&
      dispatch({
        type: UPDATE_RECENT_ITEM_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleRemoveItemClick = (itemIds: string[]) => {
    removeItem({ itemIds, shoppingSessionId, token: checkoutToken })
  }

  const handleSaveAsDraftButtonClick = useCallback(() => {
    saveCurationAsDraft({
      curationId: inProgressCuration.id,
      title,
      description
    })
      .unwrap()
      .then(() => {
        saveDraftNotes({ curationId: inProgressCuration.id, notes })
        saveDraftOrderedItems({
          curationId: inProgressCuration.id,
          orderedItems
        })
        saveDraftProductDiscovery({
          curationId: inProgressCuration.id,
          productDiscovery
        })
        generateSaveAsDraftNewRelicLogs(
          inProgressCuration.id,
          orderedItems,
          notes,
          title,
          description
        )
        history.replace('/styleboards')
        return
      })
      .catch(() => {
        scrollToTop()
      })
    // ignoring the data being logged to New Relic in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    saveCurationAsDraft,
    inProgressCuration.id,
    title,
    description,
    notes,
    orderedItems,
    history
  ])

  const onFashionMapSearchClose = () => {
    dispatch({
      type: SET_FASHION_MAP_SEARCH_DRAWER_OPEN,
      payload: false
    })
  }

  const onRecentItemsOpen = () => {
    generateNewRelicLogs('openRecentItemsTray', newRelicAttributes)
    history.replace({ hash: RECENT_ITEMS_HASH })
  }

  const onRecentItemsClose = () => {
    history.replace({ hash: undefined })
  }

  const onQuickLinksClick = () => {
    generateNewRelicLogs('quickLinksOpened', newRelicAttributes)
    setIsQuickLinksDialogOpen(true)
  }

  const onQuickLinksClose = () => {
    setIsQuickLinksDialogOpen(false)
  }

  const syncItems = () => {
    setIsInManualErrorSession(true)
    refetchCheckoutBagData()
  }

  const syncItemsClick = () => {
    generateNewRelicLogs('syncItemsClick', newRelicAttributes)
    syncItems()
  }

  useEffect(() => {
    if (!isGetCheckoutDataFetching && isGetCheckoutDataSuccess) {
      setIsInManualErrorSession(false)
    }
  }, [isGetCheckoutDataFetching, isGetCheckoutDataSuccess])

  useEffect(() => {
    // When component is mounted
    setDisplayAppBar(false)

    // When component is unmounted
    return () => {
      setDisplayAppBar(true)
    }
  }, [setDisplayAppBar])

  useEffect(() => {
    if (isShoppingTokenExpired) {
      const isIFrameOpen = location?.pathname.includes(IFRAME_PATH)
      isIFrameOpen && handleCloseIFrame()
      scrollToTop()
    }
  }, [handleCloseIFrame, isShoppingTokenExpired, location?.pathname])

  useEffect(() => {
    if (isSnapshotOpen) {
      setContainerMargin(`${SNAPSHOT_DRAWER_WIDTH}`)
    }
    if (!isSnapshotOpen) {
      setContainerMargin('')
    }
  }, [isSnapshotOpen])

  const conversationSid = customerData?.customer.conversationSid
  const conversationSidForRedirect =
    conversationSid || newFetchedConversationSid

  const [createConversation] = useCreateConversationMutation()

  const linkMessaging = async () => {
    setDidUserClickFinish(true)
    setIsConsented(true)
    try {
      if (!conversationSid && reformattedMobileNumber && customerId) {
        const newOrExistingConversationSid = (
          await createConversation({
            customerId
          }).unwrap()
        ).conversationSid
        setNewFetchedConversationSid(newOrExistingConversationSid)
        refetchCustomerData()
      }
    } catch (e) {
      setDidConversationCreationFail(true)
      setIsConsented(false)
    }
  }

  if (isPublishCurationSuccess) {
    // If an error occurs while creating a Twilio conversation redirect to the customer details page
    if (
      didUserClickFinish &&
      didConversationCreationFail &&
      isSellerInitiatedCuration &&
      !!inProgressCuration.ocpId
    ) {
      return (
        <Redirect
          to={{
            pathname: `/customers/details/${inProgressCuration.ocpId}`,
            state: {
              isStyleBoardLinkCopySuccess: true
            }
          }}
        />
      )
    }
    if (didUserClickFinish && isConsented) {
      if (
        isSellerInitiatedCuration &&
        !!inProgressCuration.ocpId &&
        conversationSidForRedirect
      ) {
        return (
          <Redirect
            to={{
              pathname: `/messages/${conversationSidForRedirect}`,
              state: {
                isStyleBoardLinkCopySuccess: true
              }
            }}
          />
        )
      }
    } else {
      if (isSellerInitiatedCuration && !inProgressCuration.ocpId) {
        return (
          <Redirect
            to={{
              pathname: '/styleboards',
              state: {
                isStyleBoardLinkCopySuccess: true,
                curationIdLink: linkToPublishedCuration
              }
            }}
          />
        )
      }
      if (isOutsideQueueRequest) {
        return (
          <Redirect
            to={{
              pathname: '/styleboards',
              state: {
                isStyleBoardLinkCopySuccess: true,
                isOutsideQueueRequest: true
              }
            }}
          />
        )
      }
      return (
        <Redirect
          to={{ pathname: '/styleboards', state: { isPublishCurationSuccess } }}
        />
      )
    }
  }

  return (
    <BoxWithGladlySpace sx={{ mr: containerMargin }}>
      <CurationReviewDispatchContext.Provider value={dispatch}>
        <LoadingBackdrop
          open={isRemoveItemLoading || (didUserClickFinish && isConsented)}
        />
        <CurationMenuBar
          customerId={inProgressCuration.ocpId}
          handleBackClick={
            location.pathname.includes(IFRAME_PATH)
              ? handleCloseIFrame
              : handleReturnHome
          }
          isEditRequest={false}
          isOutsideQueueRequest={isOutsideQueueRequest}
          isPublicBoard={isPublicBoard}
          onViewDetails={onSnapshotOpen}
          shouldShowShoppingHeader={location.pathname.includes(IFRAME_PATH)}
        />
        <Divider />
        {location.pathname.includes(IFRAME_PATH) ? (
          <NordstromIFrame
            checkoutToken={checkoutToken}
            pathAndTitle={iframePathAndTitle}
          />
        ) : (
          <>
            <Container sx={{ mt: 2 }}>
              {/* TODO: Make this its own component that decides whether to return a validation alert or a publish alert or nothing */}
              {validationResult &&
              validationResult.validationStatus === ValidationStatus.FAIL ? (
                <ValidationAlert
                  isEdit={false}
                  key={validationResult.validationId}
                  validationResult={validationResult}
                />
              ) : (
                isPublishCurationError && (
                  <PublishAlert
                    errorDetails={{
                      endpoint: publishCurationEndpointName,
                      errorData: publishCurationError,
                      identifiers: {
                        customerId: inProgressCuration?.ocpId || 'NO-DATA',
                        curationId: inProgressCuration?.id || 'NO-DATA',
                        employeeId,
                        shoppingSessionId:
                          inProgressCuration?.shoppingSessionId || 'NO-DATA'
                      },
                      originalArgs: publishCurationOriginalArgs
                    }}
                    setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  />
                )
              )}
              {isRemoveItemError && (
                <RemoveItemAlert
                  errorDetails={{
                    endpoint: removeItemEndpoint,
                    errorData: removeItemError,
                    originalArgs: removeItemArgs
                  }}
                />
              )}
              {isSaveCurationAsDraftError && (
                <SaveAsDraftAlert
                  errorDetails={{
                    endpoint: saveAsDraftEndpoint,
                    errorData: saveCurationAsDraftError,
                    originalArgs: saveCurationAsDraftOriginalArgs
                  }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                />
              )}
              <ItemsListHeader
                currentNumberOfItems={currentNumberOfItems}
                isItemLimitEnabled={isItemLimitEnabled?.active}
                itemLimit={Number(isItemLimitEnabled?.payload)}
                overLimit={overLimit}
                underLimit={underLimit}
              />
              {isShoppingTokenExpired && <TokenExpiredAlert />}
              {isGetCheckoutDataError && isInManualErrorSession && (
                <SyncErrorAlert
                  errorDetails={{
                    endpoint: checkoutBagEndpointName,
                    errorData: checkoutBagError,
                    identifiers: {
                      customerId: inProgressCuration?.ocpId || 'NO-DATA',
                      curationId: inProgressCuration?.id || 'NO-DATA',
                      employeeId,
                      shoppingSessionId:
                        inProgressCuration?.shoppingSessionId || 'NO-DATA'
                    },
                    originalArgs: checkoutBagOriginalArgs
                  }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  onDismissed={() => {
                    setIsInManualErrorSession(false)
                    setCopyToClipboardSuccess(false)
                  }}
                />
              )}
              {isFetchTokenError && (
                <HitASnagAlert
                  errorDetails={{
                    endpoint: fetchTokenEndpoint,
                    errorData: fetchTokenError,
                    identifiers: {
                      customerId: inProgressCuration?.ocpId || 'NO-DATA',
                      curationId: inProgressCuration?.id || 'NO-DATA',
                      employeeId,
                      shoppingSessionId:
                        inProgressCuration?.shoppingSessionId || 'NO-DATA'
                    },
                    originalArgs: fetchTokenOriginalArgs
                  }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                />
              )}
              {inProgressCuration.preferredEmployeeId &&
                inProgressCuration.preferredEmployeeId !== employeeId && (
                  <BasePageAlert
                    alertTitle="This curation was originally requested for a different stylist"
                    errorMessage="The requested stylist was unavailable"
                    severity="info"
                  />
                )}
              {showEmptyState && !isShoppingTokenExpired && (
                <EmptyCurationMessage />
              )}
              <ReviewButtonGrid
                fashionMapSearchButton={
                  <FashionMapSearchButton
                    onClick={handleFashionMapSearchButtonClick}
                  />
                }
                quickLinksButton={
                  <QuickLinksButton onClick={onQuickLinksClick} />
                }
                recentItemsButton={
                  <RecentItemsButton onClick={onRecentItemsOpen} />
                }
                scanButton={
                  <ScanButton onClick={() => setIsScanDrawerOpen(true)} />
                }
                shopButton={
                  <ShopButton
                    isLoading={isFetchTokenLoading}
                    onClick={handleShopButtonClick}
                    message={determineShopButtonText({
                      isShoppingTokenExpired,
                      shoppingToken
                    })}
                  />
                }
                shouldShowLoadingButton={
                  !isQuickLinksDialogOpen &&
                  (isGetCheckoutDataFetching || isGetCheckoutDataLoading)
                }
                shouldShowScanButton={shouldShowScanButton}
                shouldShowSyncButton={!isShoppingTokenExpired}
                syncButton={<SyncButton onSyncButtonClick={syncItemsClick} />}
                isSnapshotOpen={isSnapshotOpen}
              />
              {checkoutBagData && (
                <>
                  <CurationItemsList
                    shouldShowSizes={!!inProgressCuration.ocpId}
                    curationItems={checkoutBagData}
                    itemNotesObject={notes}
                    handleClickForPdp={handleClickForPdp}
                    handleRemoveClick={handleRemoveItemClick}
                    orderedItems={orderedItems}
                  />
                  {seedItemRecsEnabled && (
                    <ItemRecommendations
                      seedItem={checkoutBagData[0]}
                      onClickForPDP={handleOpenPDPFromSeedItemRecs}
                      curationId={inProgressCuration.id}
                    />
                  )}
                </>
              )}
            </Container>

            <Divider />

            <CurationTitleAndDescriptionEntry
              bagDataCount={checkoutBagData?.length || 0}
              description={description}
              descriptionError={descriptionError}
              isEdit={false}
              isGenerateTitleDescriptionError={isGenerateTitleDescError}
              isLoading={isGenerateTitleDescLoading}
              onGenerateTitleDescriptionClick={
                handleGenerateTitleAndDescription
              }
              title={title}
              titleError={titleError}
            />

            <Divider />

            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
              {isOutsideQueueRequest && (
                <Typography
                  sx={{
                    textAlign: { xs: 'left', sm: 'right' }
                  }}
                  color="text.secondary"
                  variant={'caption'}
                  mt={3}
                >
                  This board will not be tied to a customer account. You will
                  need to send the link to the customer.
                </Typography>
              )}
              <Grid
                container
                mb={3}
                sx={{
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  alignItems: { xs: 'center', sm: 'flex-end' },
                  flexDirection: { xs: 'column-reverse', sm: 'row' },
                  mt: { xs: 3, sm: 2 }
                }}
                columnSpacing={{ xs: 2 }}
              >
                <Grid
                  display={'flex'}
                  sx={{
                    flexDirection: { xs: 'row-reverse', sm: 'row' },
                    width: isSellerInitiatedCuration ? '305px' : undefined
                  }}
                  justifyContent={'space-between'}
                >
                  <Grid sx={{ mt: { xs: 1 }, ml: { xs: 1 } }} item>
                    <CancelButton
                      handleOpenCancelDialog={setIsCancelDialogOpen}
                      isEditing={false}
                    />
                  </Grid>
                  {isSellerInitiatedCuration && (
                    <Grid
                      sx={{ mt: { xs: 1 }, pl: { sm: 1 }, flexGrow: 1 }}
                      item
                    >
                      <SaveAsDraftButton
                        isLoading={isSaveCurationAsDraftLoading}
                        handleSaveAsDraft={handleSaveAsDraftButtonClick}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <PublishButton
                    handlePublish={handlePublish}
                    isLoading={isPublishCurationLoading}
                    shouldShowSendButtonText={isCustomerInitiatedWithOcpId}
                    didUserClickFinish={didUserClickFinish}
                  />
                </Grid>
              </Grid>
            </Container>
          </>
        )}

        <SnapshotDrawer
          handleClickForPdp={handleClickForPdp}
          id={inProgressCuration.ocpId}
          onClose={onSnapshotClose}
          isSellerInitiated={isSellerInitiatedCuration}
          isSnapshotOpen={isSnapshotOpen}
          shopperId={shoppingSessionId}
          token={checkoutToken}
        />

        <RecentItemsDrawer
          handleAddItem={syncItems}
          isEdit={false}
          onClose={onRecentItemsClose}
          handleClickForPdp={handleOpenPDPFromRecentItems}
          shopperId={shoppingSessionId}
          token={checkoutToken}
          refetchToken={refetchToken}
          open={location.hash.startsWith(`#${RECENT_ITEMS_HASH}`)}
        />

        <ScannerDrawer
          isEdit={false}
          open={isScanDrawerOpen}
          onClose={() => setIsScanDrawerOpen(false)}
          onClickForPdp={handleOpenPDPFromScanner}
          syncItems={syncItems}
          shoppingSessionId={inProgressCuration.shoppingSessionId || ''}
          token={checkoutToken}
          refetchToken={refetchToken}
        />

        {curationInProgressDialog ===
          CurationInProgressDialog.AbandonOrContinue &&
          checkoutBagData &&
          handleAbandon && (
            <AbandonOrContinueDialog
              handleAbandon={() =>
                handleAbandon({
                  curationId: inProgressCuration.id,
                  employeeId,
                  products: checkoutBagData.map(({ rmsSku }) => ({ rmsSku })),
                  isCancel: false
                })
              }
            />
          )}

        {isCancelDialogOpen && checkoutBagData && (
          <CancelOrContinueDialog
            curationType={inProgressCuration.type}
            handleCancel={() =>
              handleCancel({
                curationId: inProgressCuration.id,
                employeeId,
                products: checkoutBagData.map(({ rmsSku }) => ({ rmsSku })),
                isCancel: true
              })
            }
            handleClose={() => setIsCancelDialogOpen(false)}
            ocpId={inProgressCuration.ocpId}
          />
        )}

        {isQuickLinksDialogOpen && (
          <QuickLinksDialog
            isLoading={isFetchTokenLoading}
            onClose={onQuickLinksClose}
            onQuickLinkClick={(pathAndTitle) => {
              const attributes = {
                curationId: inProgressCuration.id,
                quickLinkTitle: pathAndTitle.title
              }
              generateNewRelicLogs('chosenQuickLink', attributes)
              setIframePathAndTitle(pathAndTitle)
              openNordstromIFrame()
            }}
            open={isQuickLinksDialogOpen}
            shouldShowAnniversaryLinks={[
              isAnniversaryUSPreview,
              isAnniversaryUSPublic,
              isAnniversaryUSEarlyAccess4,
              isAnniversaryUSEarlyAccess3,
              isAnniversaryUSEarlyAccess2,
              isAnniversaryUSEarlyAccess1,
              isAnniversaryUSEarlyAccess
            ].some((flag) => flag?.active)}
          />
        )}

        {shouldShowFashionMap && (
          <FashionMapSearchDrawer
            handleClickForPdp={handleOpenPDPFromFMS}
            open={
              isFashionMapSearchDrawerOpen &&
              !location.pathname.includes(IFRAME_PATH)
            }
            onClose={onFashionMapSearchClose}
          />
        )}

        {location?.state?.showAlreadyInProgressMessage && (
          <CurationInProgressSnackbar />
        )}

        {isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
      </CurationReviewDispatchContext.Provider>
    </BoxWithGladlySpace>
  )
}

export default CurationReview
