import { Link } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import ReactLinkify from 'react-linkify'

type PropsT = {
  children: ReactNode
}

const Linkify = ({ children }: PropsT): ReactElement => {
  const componentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number
  ) => {
    return (
      <Link
        key={`${decoratedText}-${key}`}
        href={decoratedHref}
        target="_blank"
        data-testid={`link-${decoratedText}`}
      >
        {decoratedText}
      </Link>
    )
  }

  return (
    <ReactLinkify componentDecorator={componentDecorator}>
      {children}
    </ReactLinkify>
  )
}

export default Linkify
