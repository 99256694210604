import { ReactElement } from 'react'

import {
  Button,
  Fade,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'

import { HoldsCustomerInfoT, HoldsHandleSubmitT } from 'pages/Holds/types'
import { HOLDS_STYLE_LABEL_ASTERISK } from 'pages/Holds/constants'
import { useHoldsToggle } from 'pages/Holds/hooks'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsDialogBodyAddCustomer from './components/HoldsDialogBodyAddCustomer'

const HoldsAddCustomer = ({
  value: customerInfo,
  onChange,
  error
}: {
  value: HoldsCustomerInfoT
  onChange: HoldsHandleSubmitT['handleSubmit']
  error?: boolean
}): ReactElement => {
  const {
    isOpen: isOpenAddCustomerDialog,
    handleOpen,
    handleClose
  } = useHoldsToggle()

  const handleSubmit: HoldsHandleSubmitT['handleSubmit'] = (value, type) => {
    onChange(value, type)

    handleClose()
  }

  return (
    <FormControl
      fullWidth
      error={error}
      sx={{
        '.MuiFormHelperText-root': {
          marginLeft: 0
        }
      }}
    >
      <Box display="flex" alignItems="center" mb={0.5}>
        <FormLabel
          required
          id="hold-customer"
          sx={{
            ...HOLDS_STYLE_LABEL_ASTERISK,
            color: 'text.primary',
            '&.Mui-error': {
              color: 'text.primary'
            },
            flex: 1
          }}
        >
          <strong>Customer</strong>
        </FormLabel>

        <Button
          data-testid="add_or_edit_customer_name"
          onClick={handleOpen}
          startIcon={
            customerInfo.customerMobileNumber ? <EditIcon /> : <AddIcon />
          }
          aria-label="add or edit customer"
        >
          {customerInfo.customerMobileNumber ? 'Edit' : 'Add'}
        </Button>
      </Box>

      {error && (
        <Fade in={error}>
          <FormHelperText>Customer name is required</FormHelperText>
        </Fade>
      )}

      <HoldsDialog open={isOpenAddCustomerDialog} onClose={handleClose}>
        <HoldsDialogBodyAddCustomer
          customerInfo={customerInfo}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      </HoldsDialog>

      <FormGroup>
        <Typography variant="body2">{customerInfo.customerName}</Typography>
        <Typography variant="body2">
          {customerInfo.customerMobileNumber}
        </Typography>
      </FormGroup>
    </FormControl>
  )
}

export default HoldsAddCustomer
