import { ToggleButtonGroup, ToggleButton, useTheme } from '@mui/material'

export enum FILTER_BY {
  ALL = 'all',
  FINISHED = 'published',
  DRAFT = 'draft'
}

type FilterPropsT = {
  selectedFilter: FILTER_BY
  handleFilterChange: (
    event: React.MouseEvent<HTMLElement>,
    filter: FILTER_BY
  ) => void
  hasDrafts: boolean
  hasPublished: boolean
}

const BoardsFilters = ({
  selectedFilter,
  handleFilterChange,
  hasDrafts,
  hasPublished
}: FilterPropsT): JSX.Element => {
  const theme = useTheme()
  const defaultOutlineColor = theme.palette.grey[400]
  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedFilter}
      exclusive
      onChange={handleFilterChange}
      sx={{ marginBottom: '16px' }}
      disabled={!hasDrafts || !hasPublished}
    >
      {hasDrafts && hasPublished && (
        <ToggleButton
          value={FILTER_BY.ALL}
          sx={{
            bgcolor:
              selectedFilter === FILTER_BY.ALL ? 'primary.main' : 'white',
            outline: `1px solid ${defaultOutlineColor}`,
            outlineOffset: '-1px'
          }}
        >
          All
        </ToggleButton>
      )}
      {hasPublished && (
        <ToggleButton
          value={FILTER_BY.FINISHED}
          sx={{
            bgcolor:
              selectedFilter === FILTER_BY.FINISHED ? 'primary.main' : 'white',
            outline: `1px solid ${defaultOutlineColor}`,
            outlineOffset: '-1px'
          }}
        >
          Finished Boards
        </ToggleButton>
      )}
      {hasDrafts && (
        <ToggleButton
          value={FILTER_BY.DRAFT}
          sx={{
            bgcolor:
              selectedFilter === FILTER_BY.DRAFT ? 'primary.main' : 'white',
            outline: `1px solid ${defaultOutlineColor}`,
            outlineOffset: '-1px'
          }}
        >
          Drafts
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  )
}

export default BoardsFilters
