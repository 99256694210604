import { ReactElement } from 'react'
import Skeleton from 'components/Skeleton'
import { Box, Card, CardContent, CardHeader } from '@mui/material'

const LoadingCurationCard = (): ReactElement => {
  return (
    <Box width={'100%'}>
      <Card variant={'outlined'}>
        <CardHeader
          subheader={<Skeleton.Text textVariant={'body1'} width={'100%'} />}
          title={<Skeleton.Text textVariant={'body1'} width={'100%'} />}
          sx={{
            pb: 0
          }}
        />
        <CardContent
          sx={{
            variant: 'body2',
            color: 'text.secondary',
            fontSize: 16,
            pt: 0
          }}
        >
          {<Skeleton.Text textVariant={'body2'} width={'100%'} />}
        </CardContent>
      </Card>
    </Box>
  )
}

export default LoadingCurationCard
