import type { RouterChildContext } from 'react-router-dom'

export const setURLSearchParam = (
  searchParam: string,
  value: string,
  history: RouterChildContext['router']['history']
): void => {
  const searchParams = new URLSearchParams(location.search)
  searchParams.set(searchParam, value)
  history.replace({ search: searchParams.toString() })
}

export const setURLSearchParams = (
  paramValuePairs: Record<string, string>,
  history: RouterChildContext['router']['history']
): void => {
  const searchParams = new URLSearchParams(location.search)
  for (const searchParam in paramValuePairs) {
    searchParams.set(searchParam, paramValuePairs[searchParam])
  }

  history.replace({ search: searchParams.toString() })
}

export const getURLSearchParam = (
  query: URLSearchParams,
  queryParam: string
): string | null => {
  return query.get(queryParam)
}

export const getDecodedURLSearchParam = (
  query: URLSearchParams,
  queryParam: string
): string | undefined => {
  const param = getURLSearchParam(query, queryParam)
  return param ? decodeURIComponent(param) : undefined
}

export const deleteURLSearchParams = (
  searchParam: string[],
  history: RouterChildContext['router']['history']
): void => {
  const searchParams = new URLSearchParams(location.search)
  searchParam.forEach((param) => searchParams.delete(param))
  history.replace({ search: searchParams.toString() })
}
