import { Box, styled } from '@mui/material'
import { HOLDS_BACKGROUND_COLOR_DIVIDER } from 'pages/Holds/constants'

const HoldsBoxDivider = styled(Box)(({ theme, height = 2, mt = 2 }) => ({
  height: theme.spacing(Number(height)),
  marginTop: theme.spacing(Number(mt)),
  // TODO: <Stack /> override styles "margin: 0" by selector "MuiStack-root>:not(style):not(style)"
  marginLeft: `${theme.spacing(-2)} !important`,
  marginRight: `${theme.spacing(-2)} !important`,
  backgroundImage: `linear-gradient(90deg, transparent 0%, ${HOLDS_BACKGROUND_COLOR_DIVIDER} 5%, ${HOLDS_BACKGROUND_COLOR_DIVIDER} 95%, transparent 100%)`
}))

export default HoldsBoxDivider
