import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import oktaTokenStorage from 'utils/okta-token-utils'
import setStandardHeaders from 'utils/set-standard-headers'
import {
  GetNotificationsResponse,
  SavedPushSubscription,
  PushSubscription
} from 'types/Notification'

const customErrorHandlerQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const result = await fetchBaseQuery({
    baseUrl: process.env.REACT_APP_NOTIFICATIONS,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })

      const accessToken = oktaTokenStorage.getOktaAccessToken()
      if (accessToken) {
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        headers.set('employee-id', employeeId)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    }
  })(args, api, extraOptions)
  if (result && result.error) {
    const nordRequestId = result.meta?.request.headers.get('nord-request-id')
    const traceContext = result.meta?.request.headers.get('TraceContext')

    const errorWithMetaData = {
      error: {
        ...result.error,
        errorMetaData: { nordRequestId, traceContext }
      }
    }

    // We don't want endpoints to retry when running unit tests. This section allows to do proper testing.
    if (process.env.NODE_ENV === 'test') {
      retry.fail(result.error)
    }

    return errorWithMetaData
  }

  return result
}

const baseQueryWithRetry = retry(customErrorHandlerQuery, { maxRetries: 3 })

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryWithRetry,
  endpoints: (builder) => ({
    getNotifications: builder.query<GetNotificationsResponse, void>({
      query: () => {
        return {
          url: `/notifications/feed`
        }
      }
    }),
    markNotificationsAsRead: builder.mutation<GetNotificationsResponse, void>({
      query: () => {
        return {
          url: `/notifications/mark-as-read`,
          method: 'PUT'
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            notificationsApi.endpoints.getUnreadNotificationsCount.initiate()
          )
        } catch (err) {
          console.log(err)
        }
      }
    }),
    getUnreadNotificationsCount: builder.query<
      { totalUnreadCount: number },
      void
    >({
      query: () => {
        return {
          url: `/notifications/unread-count`
        }
      }
    }),
    savePushSubscription: builder.mutation<
      SavedPushSubscription,
      PushSubscription
    >({
      query: (arg) => {
        return {
          url: `/push-subscriptions`,
          method: 'POST',
          body: {
            endpoint: arg.endpoint,
            keys: arg.keys
          }
        }
      }
    }),
    deletePushSubscription: builder.mutation<undefined, PushSubscription>({
      query: (arg) => {
        return {
          url: `/push-subscriptions`,
          method: 'DELETE',
          body: {
            endpoint: arg.endpoint
          }
        }
      }
    })
  })
})

export const {
  useGetNotificationsQuery,
  useMarkNotificationsAsReadMutation,
  useGetUnreadNotificationsCountQuery,
  useLazyGetNotificationsQuery,
  useSavePushSubscriptionMutation,
  useDeletePushSubscriptionMutation
} = notificationsApi
