import partition from 'lodash/partition'
import { ExtendedConsentedCustomerListItemT } from 'types/ConsentedCustomer'

const byAlphabetical = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  customers.sort((a, b) => {
    let aSortString = `${a.firstName} ${a.lastName}`.toLowerCase()
    let bSortString = `${b.firstName} ${b.lastName}`.toLowerCase()

    if (!a.firstName && !a.lastName) {
      aSortString = a.email
    }

    if (!b.firstName && !b.lastName) {
      bSortString = b.email
    }

    return aSortString < bSortString ? -1 : 1
  })

const byMessages = (
  customers: ExtendedConsentedCustomerListItemT[],
  oldestFirst: boolean
) => {
  const [customersWithMessages, customersWithoutMessages] = partition(
    customers,
    (customer) => !!customer.lastMessage
  )

  const sortedCustomersWithMessages = customersWithMessages.sort((a, b) => {
    const dateA = new Date(a.lastMessage as string)
    const dateB = new Date(b.lastMessage as string)

    return oldestFirst
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime()
  })

  const sortedCustomersWithoutMessages = byAlphabetical(
    customersWithoutMessages
  )

  return [...sortedCustomersWithMessages, ...sortedCustomersWithoutMessages]
}

const byOldestMessages = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => byMessages(customers, true)

const byNewestMessages = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] => byMessages(customers, false)

export const CUSTOMER_SORT_FUNCTIONS = {
  byAlphabetical,
  byOldestMessages,
  byNewestMessages
}

export const CUSTOMER_SORT_KEYS: Record<string, CustomerSortKeysT> = {
  byAlphabetical: 'byAlphabetical',
  byOldestMessages: 'byOldestMessages',
  byNewestMessages: 'byNewestMessages'
}

export type CustomerSortKeysT =
  | 'byAlphabetical'
  | 'byOldestMessages'
  | 'byNewestMessages'
