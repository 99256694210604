import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Link as ReactRouterLink } from 'react-router-dom'
import { RequestCardType, QueueType } from 'types/RequestCardTypes'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { checkBrowserClient } from 'utils/userAgentDetector'

const RequestCard: React.FC<RequestCardType> = ({
  renderIcon,
  buttonText,
  title,
  link,
  count,
  testId,
  colorIcon,
  queueType,
  visible
}) => {
  const handleClick = () => {
    const attributes = {
      page: 'homepage'
    }
    interface SourceMap {
      [key: string]: string
    }

    const sourceMap: SourceMap = {
      [QueueType.EDIT]: 'viewAllEditRequestsClick',
      [QueueType.PREFERRED_EMPLOYEE]: 'viewAllPreferredEmployeeRequestsClick',
      [QueueType.GENERAL]: 'startGeneralRequestFromQueueClick'
    }

    if (sourceMap[queueType]) {
      generateNewRelicLogs(sourceMap[queueType], attributes)
    }
  }

  const isMobileDevice = checkBrowserClient.isMobile()

  return (
    <>
      {visible && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: { xs: '100%', sm: '118px' },
            padding: isMobileDevice ? '12px' : '16px',
            borderRadius: '4px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '12px',
              height: '100%'
            }}
          >
            <Box
              my="auto"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'flex-start',
                bgcolor: colorIcon,
                borderRadius: '50%',
                width: 40,
                height: 40,
                marginRight: 2,
                minWidth: 40
              }}
            >
              {renderIcon()}
            </Box>
            <Typography variant="subtitle2">
              {count} {title}
            </Typography>
          </Box>
          <Button
            component={ReactRouterLink}
            to={link}
            onClick={handleClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              alignSelf: 'flex-end',
              cursor: 'pointer',
              color: '#482FE9',
              marginTop: 'auto',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            data-testid={testId}
          >
            <Typography
              variant="button"
              sx={{
                fontWeight: 'bold',
                marginRight: '8px'
              }}
            >
              {buttonText}
            </Typography>
            <ArrowForwardIosIcon
              sx={{
                fontSize: '15px',
                fontWeight: 'bold'
              }}
            />
          </Button>
        </Box>
      )}
    </>
  )
}

export default RequestCard
