export const checkBrowserClient = {
  isAndroid: (): RegExpMatchArray | null =>
    navigator.userAgent.match(/Android/i),
  isBlackBerry: (): RegExpMatchArray | null =>
    navigator.userAgent.match(/BlackBerry/i),
  isiOS: (): RegExpMatchArray | null =>
    navigator.userAgent.match(/iPhone|iPad|iPod/i),
  isWindows: (): RegExpMatchArray | null =>
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i),
  isMobile: (): RegExpMatchArray | null =>
    checkBrowserClient.isAndroid() ||
    checkBrowserClient.isBlackBerry() ||
    checkBrowserClient.isiOS() ||
    checkBrowserClient.isWindows()
}
