import { ReactElement } from 'react'
import { Container, Box, Typography } from '@mui/material'
import FolderCopyIcon from '@mui/icons-material/FolderCopy'
import EditIcon from '@mui/icons-material/Edit'
import FlareIcon from '@mui/icons-material/Flare'
import GroupsIcon from '@mui/icons-material/Groups'
import PersonIcon from '@mui/icons-material/Person'

export enum EmptyQueueIconOption {
  DRAFT = 'draft',
  EDIT = 'edit',
  FINISHED_BOARDS_CUSTOMER = 'finished-boards-customer',
  FINISHED_BOARDS_PUBLIC = 'finished-boards-public',
  PREFERRED = 'preferred'
}

interface EmptyQueueProps {
  title: string
  subtitle: string
  disableContainerGutters?: boolean
  height?: string
  icon: EmptyQueueIconOption
  maxWidth?: string
}

const EmptyQueue = (props: EmptyQueueProps): ReactElement => {
  const { title, subtitle, disableContainerGutters, height, icon, maxWidth } =
    props

  return (
    <Container
      disableGutters={disableContainerGutters ? true : false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: height ? height : '80vh',
        textAlign: 'center',
        maxWidth: maxWidth ? maxWidth : undefined
      }}
    >
      <Box
        sx={{
          backgroundColor: '#EBEFF1',
          borderRadius: '50px',
          width: '67px',
          height: '67px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px'
        }}
      >
        {icon === EmptyQueueIconOption.DRAFT && (
          <FolderCopyIcon fontSize="large" color="secondary" />
        )}
        {icon === EmptyQueueIconOption.EDIT && (
          <EditIcon fontSize="large" color="secondary" />
        )}
        {icon === EmptyQueueIconOption.FINISHED_BOARDS_CUSTOMER && (
          <PersonIcon fontSize="large" color="secondary" />
        )}
        {icon === EmptyQueueIconOption.FINISHED_BOARDS_PUBLIC && (
          <GroupsIcon fontSize="large" color="secondary" />
        )}
        {icon === EmptyQueueIconOption.PREFERRED && (
          <FlareIcon fontSize="large" color="secondary" />
        )}
      </Box>
      <Typography
        variant="subtitle2"
        sx={{ marginBottom: '4px', fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      <Typography variant="caption" color="secondary">
        {subtitle}
      </Typography>
    </Container>
  )
}

export default EmptyQueue
