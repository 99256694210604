import { ReactElement, useState } from 'react'

import { Button, Fade, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { HOLDS_STYLE_LABEL_ASTERISK_WITH_FOCUSED } from 'pages/Holds/constants'
import {
  HoldsCustomerInfoT,
  HoldsEnumCustomerType,
  HoldsHandleSubmitT,
  HoldsOpenDialogT
} from 'pages/Holds/types'

import { HoldsTextFieldMobileNumber } from './components'

const HoldsButtonCustomerAddGuestHold = ({
  customerInfo,
  dialogType,
  setDialogType,
  handleSubmit
}: {
  customerInfo: HoldsCustomerInfoT
} & HoldsHandleSubmitT &
  HoldsOpenDialogT): ReactElement => {
  const isOpenDialog = dialogType === HoldsEnumCustomerType.ADD_QUICK

  const [showErrorHelperText, setShowErrorHelperText] = useState(false)
  const [customerName, setCustomerName] = useState(
    !customerInfo.customerId && customerInfo.customerName
      ? customerInfo.customerName
      : ''
  )
  const [mobileNumber, setMobileNumber] = useState(
    !customerInfo.customerId && customerInfo.customerMobileNumber
      ? customerInfo.customerMobileNumber
      : ''
  )

  const isFilledMobileNumber = mobileNumber.length === 16 // with 6 extra symbols
  const allowAddGuestHold = Boolean(customerName && isFilledMobileNumber)

  const showErrorForCustomerName = showErrorHelperText && !customerName
  const showErrorForMobileNumber = showErrorHelperText && !isFilledMobileNumber

  return (
    <>
      {isOpenDialog && (
        <Fade in={isOpenDialog}>
          <Box mb={2}>
            <Typography
              sx={{
                mb: 2.5
              }}
            >
              Create a hold by entering the customer&#39;s info.
            </Typography>

            <Box display="grid" gap={2}>
              <TextField
                fullWidth
                required
                id="customerName"
                label="Customer Name"
                name="customerName"
                value={customerName}
                onChange={(event) => setCustomerName(event.target.value)}
                inputProps={{
                  'data-testid': 'customer_name_field',
                  maxLength: 254
                }}
                aria-label="Customer name text field"
                size="small"
                sx={{
                  ...HOLDS_STYLE_LABEL_ASTERISK_WITH_FOCUSED,
                  '.MuiFormLabel-root.Mui-error:not(.Mui-focused)': {
                    color: 'text.primary'
                  }
                }}
                error={showErrorForCustomerName}
                helperText={
                  showErrorForCustomerName && 'Customer name required'
                }
              />

              <HoldsTextFieldMobileNumber
                value={mobileNumber}
                onChange={setMobileNumber}
                required={true}
                sx={{
                  ...HOLDS_STYLE_LABEL_ASTERISK_WITH_FOCUSED,
                  '.MuiFormLabel-root.Mui-error:not(.Mui-focused)': {
                    color: 'text.primary'
                  }
                }}
                error={showErrorForMobileNumber}
                helperText={
                  showErrorForMobileNumber && 'Mobile number required'
                }
              />
            </Box>
          </Box>
        </Fade>
      )}

      <Button
        fullWidth
        variant={isOpenDialog ? 'contained' : 'outlined'}
        onClick={
          isOpenDialog
            ? () => {
                setShowErrorHelperText(true)

                if (!allowAddGuestHold) {
                  return
                }

                handleSubmit(
                  {
                    customerName,
                    customerMobileNumber: mobileNumber
                  },
                  HoldsEnumCustomerType.ADD_QUICK
                )
              }
            : () => setDialogType(HoldsEnumCustomerType.ADD_QUICK)
        }
      >
        Add New Customer
      </Button>
    </>
  )
}

export default HoldsButtonCustomerAddGuestHold
