import { Alert, AlertTitle, Box, Link } from '@mui/material'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

interface AlertMessageT {
  onClose: () => void
}

const AlertMessage = (props: AlertMessageT): JSX.Element => {
  const { onClose } = props
  const alertTitle = 'Help improve Personal Book'
  const infoMessage =
    'How do you feel about Personal Book? Provide feedback by October 7, 2024.'
  const pbSurveyLink =
    'https://survey.alchemer.com/s3/7999498/Personal-Book-Employee-Satisfaction-Sep-2024'

  const onClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('surveyLinkFromAlertClick', attributes)
  }

  return (
    <Box px={2} mt={3}>
      <Alert severity="info" onClose={onClose}>
        <AlertTitle>{alertTitle}</AlertTitle>
        {infoMessage}
        <span style={{ margin: '0 4px' }} />
        <Link
          href={pbSurveyLink}
          onClick={onClick}
          target="_blank"
          sx={{ color: 'black' }}
        >
          Complete the survey.
        </Link>
      </Alert>
    </Box>
  )
}

export default AlertMessage
