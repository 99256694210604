import { ReactElement } from 'react'
import { ChevronRight } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'

interface PropsT {
  curationId: number
}

const ResumeCurationButton = (props: PropsT): ReactElement => {
  const { curationId } = props
  const history = useHistory()
  const pathname = history.location.pathname
  const curationDetails = useAppSelector(curationSelector)

  const pagePathname = (pathname: string): string => {
    switch (pathname) {
      case '/':
        return 'homepage'
      case '/styleboards/drafts':
        return 'drafts'
      default:
        return ''
    }
  }

  const handleResumeCuration = (id: number) => {
    const attributes = {
      curationId: curationId,
      type: curationDetails?.type,
      preferredEmployeeId: curationDetails?.preferredEmployee,
      ocpId: curationDetails?.ocpId,
      page: pagePathname(pathname)
    }
    generateNewRelicLogs('resumeCurationFromDraftClick', attributes)
    history.push({ pathname: `/review`, search: `?resumeCuration=${id}` })
  }

  return (
    <Button onClick={() => handleResumeCuration(curationId)}>
      Continue <ChevronRight />
    </Button>
  )
}

export default ResumeCurationButton
