import { ReactElement, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Fade,
  IconButton,
  Link
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CopyErrorData from 'components/CopyErrorData'
import { CopyErrorDataT } from 'types/CopyErrorData'
import ClickableText from 'components/ClickableText'

interface PropsT {
  alertTitle?: string
  errorDetails?: CopyErrorDataT
  errorMessage?: string
  isDismissable?: boolean
  onDismissed?: () => void
  setCopyToClipboardSuccess?: (arg: boolean) => void
  severity?: 'warning' | 'error' | 'info' | undefined
  shouldShowRefresh?: boolean
}

export const BasePageAlert = (props: PropsT): ReactElement | null => {
  const {
    alertTitle,
    errorDetails,
    errorMessage,
    isDismissable = false,
    severity = 'error',
    shouldShowRefresh = false,
    onDismissed,
    setCopyToClipboardSuccess
  } = props

  const [isDismissed, setIsDismissed] = useState(false)

  if (isDismissed) {
    return null
  }

  const refreshPage = () => {
    location.reload()
  }

  return (
    <Container disableGutters>
      <Box sx={{ width: '100%' }} mb={2}>
        <Fade in={!isDismissed}>
          <Alert
            action={
              isDismissable && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsDismissed(true)
                    onDismissed && onDismissed()
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )
            }
            severity={severity}
          >
            <AlertTitle>
              <>
                <strong data-testid="error-title">
                  {alertTitle}{' '}
                  {severity === 'error' && shouldShowRefresh && (
                    <>
                      <Link
                        data-testid="base-page-alert-error-refresh-button"
                        component={ClickableText}
                        onClick={refreshPage}
                        sx={{
                          color: 'inherit',
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          fontSize: 'inherit'
                        }}
                        variant="body2"
                      >
                        Click here
                      </Link>{' '}
                      to refresh.
                    </>
                  )}
                </strong>
              </>
            </AlertTitle>
            {errorDetails ? (
              <CopyErrorData
                errorDetails={errorDetails}
                setCopyToClipboardSuccess={setCopyToClipboardSuccess}
              />
            ) : (
              errorMessage
            )}
          </Alert>
        </Fade>
      </Box>
    </Container>
  )
}

export default BasePageAlert
