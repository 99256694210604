import { ReactElement } from 'react'

import { Box, DialogContent } from '@mui/material'
import type { DialogContentProps } from '@mui/material'

const HoldsDialogContent = ({
  sx = {},
  children,
  ...restDialogContentProps
}: DialogContentProps): ReactElement => {
  return (
    <DialogContent
      sx={{
        overflowX: 'hidden',
        px: 0,
        pt: 2
      }}
      {...restDialogContentProps}
    >
      <Box
        sx={{
          width: '100%',
          minHeight: '100%',
          margin: '0 auto',
          maxWidth: 952,
          px: {
            xs: 2,
            sm: 3
          },
          ...sx
        }}
      >
        {children}
      </Box>
    </DialogContent>
  )
}

export default HoldsDialogContent
