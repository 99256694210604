import { ReactElement, useState, useEffect } from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  SvgIcon,
  IconButton,
  Drawer,
  Badge,
  Divider
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SmsIcon from '@mui/icons-material/Sms'
import { ReactComponent as NordstromLogo } from 'assets/nordstrom-logo.svg'
import { ReactComponent as Nlogo } from 'assets/Nlogo.svg'
import { useNavigation } from 'contexts/NavigationContext'
import MenuDrawer from '../MenuDrawer'
import { colors } from './constants'
import { hideWidget, showWidget } from 'venderScripts/gladly'
import { useHistory } from 'react-router-dom'
import { MENU_HASH } from 'components/MenuDrawer/MenuDrawer'
import { twilioSelectors } from 'services/twilioSlice'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { isMessagingUser, isStyleAdvisor } from 'utils/userPermissions'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useGetUnreadNotificationsCountQuery } from 'services/notificationsApi'
import oktaTokenStorage from '../../utils/okta-token-utils'
import { setOktaToken } from '../../app/employeeSlice'
import MenuBarTitle from 'components/MenuBarTitle'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { formattedPathName } from 'utils/formattedPathName'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { getIsUserSubscribed } from 'utils/service-worker-utils'

const MenuBar = (): ReactElement | null => {
  const appDispatch = useAppDispatch()
  const oktaAccessToken = oktaTokenStorage.getOktaAccessToken()

  const isMobileDevice = checkBrowserClient.isMobile()

  const { displayAppBar } = useNavigation()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number | null>(
    null
  )
  const history = useHistory()
  const currentPath = history?.location?.pathname

  const shouldShowConversationListIngress = isMessagingUser()

  const shouldShowNotificationsIngress = !isStyleAdvisor()

  const { data, refetch } = useGetUnreadNotificationsCountQuery()

  useEffect(() => {
    if (!displayAppBar && !shouldShowNotificationsIngress) {
      return
    }
    refetch()
  }, [refetch, currentPath, displayAppBar, shouldShowNotificationsIngress])

  useEffect(() => {
    if (oktaAccessToken) {
      appDispatch(setOktaToken({ oktaAccessToken }))
    }
  }, [oktaAccessToken, appDispatch])

  useEffect(() => {
    const checkIfUserIsSubscribed = async () => {
      const isUserSubscribed = await getIsUserSubscribed()
      localStorage.setItem(
        'isSubscribedToPushNotifications',
        isUserSubscribed.toString()
      )
    }
    checkIfUserIsSubscribed
  }, [])

  const unreadMessagesCount = useAppSelector(
    twilioSelectors.allUnreadMessagesCountSelector
  )

  const unreadNotificationsCount = data?.totalUnreadCount
    ? data?.totalUnreadCount
    : 0

  if (!displayAppBar) {
    return null
  }

  const onMenuOpen = () => {
    setIsDrawerVisible(true)
    history.replace({ hash: MENU_HASH })
    hideWidget()
  }
  const onMenuClose = () => {
    setIsDrawerVisible(false)
    history.replace({ hash: undefined })
    showWidget()
  }

  const goToConversations = () => {
    history.push({ pathname: '/messages' })
  }

  const goToNotifications = () => {
    history.push({
      pathname: '/notifications',
      state: { sourcePath: window.location.pathname }
    })
  }

  const goToHomePage = () => {
    const attributes = {
      page: formattedPathName(currentPath)
    }
    generateNewRelicLogs('nordstromIconClick', attributes)
    setSelectedMenuIndex(null)
    history.push({
      pathname: '/'
    })
  }

  return (
    <Box sx={{ flexGrow: 1, mb: '16px' }}>
      <AppBar
        position="fixed"
        sx={{
          transition: `top 450ms ease-in-out`,
          backgroundColor: 'background.default',
          boxShadow: 4
        }}
      >
        <Toolbar>
          <IconButton
            data-testid="menu-icon"
            onClick={() => onMenuOpen()}
            size="large"
            sx={{ marginRight: '31px', color: colors.primary }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flexGrow: 1,
              cursor: 'pointer'
            }}
            onClick={goToHomePage}
          >
            {isMobileDevice ? (
              <SvgIcon
                data-testid="nordstrom-logo"
                component={Nlogo}
                // SvgIcon doesn't use the viewBox property from the SVG file
                viewBox="20 10 70 100"
                sx={{
                  width: ['13.75px', '10px'],
                  color: 'common.black',
                  marginRight: '14px'
                }}
              />
            ) : (
              <SvgIcon
                data-testid="nordstrom-logo"
                component={NordstromLogo}
                // SvgIcon doesn't use the viewBox property from the SVG file
                viewBox="0 0 562.3 70.5"
                sx={{
                  width: ['50px', '128px'],
                  color: 'common.black',
                  marginRight: '8px'
                }}
              />
            )}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ background: 'black' }}
            />
            <MenuBarTitle />
          </Box>
          {shouldShowConversationListIngress && (
            <IconButton
              data-testid="conversationsIngress"
              onClick={goToConversations}
              size="large"
              sx={{ color: colors.primary }}
            >
              <Badge
                badgeContent={unreadMessagesCount}
                max={9}
                color="primary"
                data-testid="unreadMessagesBadge"
              >
                <SmsIcon />
              </Badge>
            </IconButton>
          )}
          {shouldShowNotificationsIngress && (
            <IconButton
              data-testid="notificationsIngress"
              onClick={goToNotifications}
              size="large"
              sx={{ color: colors.primary }}
            >
              <Badge
                badgeContent={unreadNotificationsCount}
                max={9}
                color="primary"
                data-testid="unreadNotificationsBadge"
              >
                <NotificationsIcon
                  sx={{
                    marginRight: unreadNotificationsCount < 10 ? '-2px' : '1px'
                  }}
                />
              </Badge>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {/* Added to make the AppBar fixed to its position */}
      <Toolbar />
      <Drawer
        anchor="left"
        open={isDrawerVisible}
        onClose={() => onMenuClose()}
        variant="temporary"
      >
        <MenuDrawer
          onClose={() => onMenuClose()}
          selectedIndex={selectedMenuIndex}
          setSelectedIndex={(index) => setSelectedMenuIndex(index)}
        />
      </Drawer>
    </Box>
  )
}

export default MenuBar
