import { ReactElement } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Typography } from '@mui/material'

type PropsT = {
  children: JSX.Element | JSX.Element[]
}

const Boundary = ({ children }: PropsT): ReactElement => (
  <ErrorBoundary
    fallback={
      <Typography mt={2} align="center" variant="body2" color="error">
        Oops. Something is not right.
      </Typography>
    }
  >
    {children}
  </ErrorBoundary>
)

export default Boundary
