import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { emDashCharacter } from '../../../constants'

const SAVE_ERROR_MESSAGE = `Issue saving style board ${emDashCharacter} try again`

interface PropsT {
  errorDetails?: CopyErrorDataT
  setCopyToClipboardSuccess?: (arg: boolean) => void
}

const SaveAsDraftAlert = (props: PropsT): ReactElement => {
  const { errorDetails, setCopyToClipboardSuccess } = props

  return (
    <BasePageAlert
      alertTitle={SAVE_ERROR_MESSAGE}
      errorDetails={errorDetails}
      isDismissable={true}
      setCopyToClipboardSuccess={setCopyToClipboardSuccess}
    />
  )
}

export default SaveAsDraftAlert
