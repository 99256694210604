import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DeliveryStatus } from '@twilio/conversations'

import {
  ConversationsRecord,
  MessagesCollection,
  ReduxConversation,
  ReduxMessage,
  DraftMessagesT,
  AttachedMediaOptions
} from 'types/Twilio'

export const initialState: {
  conversations: ConversationsRecord
  token: string
  messagesById: MessagesCollection
  isLoading: boolean
  activeConversationId: string | null
  draftMessages: DraftMessagesT
} = {
  conversations: {} as ConversationsRecord,
  token: '',
  messagesById: {} as MessagesCollection,
  isLoading: true,
  activeConversationId: null,
  draftMessages: {} as DraftMessagesT
}

export const twilioSlice = createSlice({
  name: 'twilioSlice',
  initialState: initialState,
  reducers: {
    setAuthToken: (
      state,
      action: PayloadAction<{
        token: string
      }>
    ) => {
      const { token } = action.payload
      state.token = token
    },
    setConversations: (
      state,
      action: PayloadAction<{
        conversations: ConversationsRecord
      }>
    ) => {
      const { conversations } = action.payload
      state.conversations = conversations
    },
    setDraftMessage: (
      state,
      action: PayloadAction<{
        message: string | null | undefined
        images: AttachedMediaOptions[] | null | undefined
        personalBookingLink: string | null
      }>
    ) => {
      const { message, images, personalBookingLink } = action.payload
      const { activeConversationId } = state
      if (activeConversationId) {
        state.draftMessages[activeConversationId] = {
          message,
          images,
          personalBookingLink
        }
      }
    },
    deleteDraftMessage: (state) => {
      const { activeConversationId } = state
      if (activeConversationId) {
        delete state.draftMessages[activeConversationId]
      }
    },
    setMostRecentMessages: (
      state,
      action: PayloadAction<{
        messagesById: Record<string, ReduxMessage[]>
      }>
    ) => {
      const { messagesById } = action.payload
      state.messagesById = { ...state.messagesById, ...messagesById }
    },
    setIsLoading: (
      state,
      action: PayloadAction<{
        value: boolean
      }>
    ) => {
      const { value } = action.payload
      state.isLoading = value
    },
    updateActiveConversationId: (
      state,
      action: PayloadAction<{
        sid: string | null
      }>
    ) => {
      const { sid } = action.payload
      state.activeConversationId = sid
    },
    addAndReplaceAllMessagesById: (
      state,
      action: PayloadAction<{
        id: string
        messages: ReduxMessage[]
      }>
    ) => {
      const { messages, id } = action.payload
      state.messagesById[id] = messages
    },
    addPreviousMessagesById: (
      state,
      action: PayloadAction<{
        id: string
        messages: ReduxMessage[]
      }>
    ) => {
      const { messages, id } = action.payload
      state.messagesById[id] = [...messages, ...state.messagesById[id]]
    },
    addRealTimeMessage: (
      state,
      action: PayloadAction<{
        message: ReduxMessage
        conversationSid: string
      }>
    ) => {
      const { message, conversationSid } = action.payload
      state.messagesById[conversationSid] = [
        ...(state.messagesById[conversationSid]
          ? state.messagesById[conversationSid]
          : []),
        message
      ]
    },
    updateUnreadMessagesCount: (
      state,
      action: PayloadAction<{
        conversationSid: string | null
        overwriteAmount: number
      }>
    ) => {
      const { conversationSid, overwriteAmount } = action.payload
      if (!conversationSid) {
        return state
      }
      if (state.conversations[conversationSid]) {
        state.conversations[conversationSid].unreadMessagesCount =
          overwriteAmount
      }
    },
    updateMessageStatus: (
      state,
      action: PayloadAction<{
        conversationSid: string
        messageSid: string
        status: DeliveryStatus
        errorCode: string | 0
      }>
    ) => {
      const {
        conversationSid,
        messageSid,
        status: newStatus,
        errorCode: newErrorCode
      } = action.payload
      if (!conversationSid || !messageSid) {
        return state
      }

      const messages = state.messagesById[conversationSid]

      const message = messages.find((message) => message.sid === messageSid)
      if (message) {
        message.deliveryReceipt.status = newStatus
        message.deliveryReceipt.errorCode = newErrorCode
      }
    },
    addOrUpdateConversationInRealTime: (
      state,
      action: PayloadAction<{
        conversation: ReduxConversation
      }>
    ) => {
      const { conversation: updatedConversation } = action.payload
      const { sid } = updatedConversation
      const existingConversation = state.conversations[sid]
      state.conversations[sid] = {
        ...existingConversation,
        ...updatedConversation,
        unreadMessagesCount: existingConversation
          ? existingConversation.unreadMessagesCount
          : 0
      }
    },
    removeMessageFromConversation: (
      state,
      action: PayloadAction<{
        conversationId: string
        messageId: string
      }>
    ) => {
      const { conversationId, messageId } = action.payload
      const messages = state.messagesById[conversationId]
      const arrayWithoutDeletedMessage = messages.filter(
        (message) => message.sid !== messageId
      )
      const totalRemainingMessages = arrayWithoutDeletedMessage.length
      state.messagesById[conversationId] = arrayWithoutDeletedMessage
      if (arrayWithoutDeletedMessage.length) {
        state.conversations[conversationId].mostRecentMessageDate =
          arrayWithoutDeletedMessage[totalRemainingMessages - 1].dateCreated
      } else {
        state.conversations[conversationId].mostRecentMessageDate = ''
      }
    }
  }
})

export const { actions } = twilioSlice

export const Actions = {
  ...actions
}

export default twilioSlice
