import { ReactNode } from 'react'
import {
  closestCorners,
  DndContext as CoreDndContext,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'

// This component tells the library that the area it wraps has draggable and droppable content
// It should be wrapped directly around the components that will be dragged
const DndContext = ({
  children,
  handleDragEnd,
  handleDragStart,
  isMobile,
  shouldRestrictToVerticalAxis
}: {
  children: ReactNode
  handleDragEnd: (event: DragEndEvent) => void
  handleDragStart: (event: DragStartEvent) => void
  isMobile: boolean
  shouldRestrictToVerticalAxis: boolean
}): JSX.Element => {
  const touchSensor = useSensor(TouchSensor)
  const pointerSensor = useSensor(PointerSensor)

  const sensors = useSensors(isMobile ? touchSensor : pointerSensor)
  const modifiers = []

  if (shouldRestrictToVerticalAxis) {
    modifiers.push(restrictToVerticalAxis)
  }

  return (
    <CoreDndContext
      collisionDetection={closestCorners}
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      {children}
    </CoreDndContext>
  )
}

export default DndContext
