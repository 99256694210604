import { Box, Grid, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ReactElement } from 'react'

type PropsT = {
  currentNumberOfItems: number
  isItemLimitEnabled: boolean
  itemLimit?: number
  overLimit: boolean
  underLimit: boolean
}

const ItemsListHeader = (props: PropsT): ReactElement => {
  const {
    currentNumberOfItems,
    isItemLimitEnabled,
    itemLimit,
    overLimit,
    underLimit
  } = props

  const hasProductAmountError = (isItemLimitEnabled && overLimit) || underLimit

  const noItemLimitCountGrid = (hasProductAmountError: boolean) => {
    if (hasProductAmountError) {
      return (
        <Grid container>
          <ErrorOutlineIcon
            data-testid="item-error-icon"
            color="error"
            sx={{ mr: 1 }}
          />
          <Typography color="error" fontWeight="bold">
            {currentNumberOfItems}
          </Typography>
          <Typography ml={0.5}>Items</Typography>
        </Grid>
      )
    }

    return <Typography>{currentNumberOfItems} Items</Typography>
  }

  const itemLimitCountGrid = (
    hasProductAmountError: boolean,
    currentNumberOfItems: number
  ) => {
    if (hasProductAmountError) {
      return (
        <Grid container>
          <ErrorOutlineIcon
            data-testid="item-error-icon"
            color="error"
            sx={{ mr: 1 }}
          />
          <Typography color="error" fontWeight="bold">
            {currentNumberOfItems}
          </Typography>
          <Typography> /{itemLimit} max</Typography>
        </Grid>
      )
    } else if (currentNumberOfItems) {
      return (
        <Grid container>
          <Typography>{currentNumberOfItems}</Typography>
          <Typography> /{itemLimit} max</Typography>
        </Grid>
      )
    } else {
      return <Typography>0/{itemLimit} max</Typography>
    }
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box display="flex" justifyContent="flex-start">
            <Typography
              gutterBottom
              component="h1"
              fontWeight="bold"
              variant="h6"
            >
              Items
            </Typography>
          </Box>
        </Grid>
        <Box>
          {isItemLimitEnabled
            ? itemLimitCountGrid(hasProductAmountError, currentNumberOfItems)
            : noItemLimitCountGrid(hasProductAmountError)}
        </Box>
      </Grid>
    </Box>
  )
}

export default ItemsListHeader
