import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri:
    window.location.origin + process.env.REACT_APP_OKTA_REDIRECT_PATH,
  postLogoutRedirectUri: window.location.origin + '/',
  pkce: true,
  tokenManager: {
    autoRenew: true,
    secure: true
  },
  scopes: [
    'openid',
    'email',
    'employee_number',
    'groups_filter',
    'first_name',
    'last_name',
    'store_number'
  ]
})

const SecurityProvider = ({
  children
}: {
  children: JSX.Element
}): ReactElement => {
  const history = useHistory()

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}

export default SecurityProvider
