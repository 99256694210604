import { ReactElement } from 'react'
import BaseSnackbar from 'components/BaseSnackbar'
import CloseIcon from '@mui/icons-material/Close'
import CopyErrorData from 'components/CopyErrorData'
import { Alert, AlertTitle, IconButton, Link } from '@mui/material'
import ClickableText from 'components/ClickableText'

type PropsT = {
  setIsErrorUnsubscribingPushNotification: (value: boolean) => void
  isErrorUnsubscribingPushNotification: boolean
  pushNotificationErrorMessage: string
}

const UnsubscribingPushNotificationError = ({
  setIsErrorUnsubscribingPushNotification,
  pushNotificationErrorMessage
}: PropsT): ReactElement => {
  const refreshPage = () => {
    location.reload()
  }

  return (
    <>
      <BaseSnackbar>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsErrorUnsubscribingPushNotification(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>
            <>
              <strong data-testid="error-title">
                Issue disabling push notifications -{' '}
                <Link
                  component={ClickableText}
                  onClick={refreshPage}
                  sx={{
                    color: 'inherit',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontSize: 'inherit'
                  }}
                  variant="body2"
                >
                  Click here
                </Link>{' '}
                to refresh
              </strong>
              <CopyErrorData
                errorDetails={{ errorData: pushNotificationErrorMessage }}
              />
            </>
          </AlertTitle>
        </Alert>
      </BaseSnackbar>
    </>
  )
}

export default UnsubscribingPushNotificationError
