import { ReactElement } from 'react'
import { Grid, Typography, Button, Box } from '@mui/material'

import { FavoriteProductT } from 'types/Product'
import ItemImage from 'components/ItemImage'
import { customStylingTheme } from 'theme'

export type PropsT = {
  products: Array<FavoriteProductT>
  removeFavorite: (arg: { webStyleIds: string[] }) => void
}
const ProductFavoriteGrid = (props: PropsT): ReactElement => {
  const { products, removeFavorite } = props

  return (
    <Grid
      container
      columnSpacing="16px"
      rowSpacing="24px"
      columns={{ xs: 1, sm: 2, md: 4, lg: 6 }}
    >
      {products.map((product, index) => {
        const { brandName, name, imageUrl, priceMax, priceMin, webStyleId } =
          product
        const shouldShowPriceRange =
          !!priceMin && !!priceMax && priceMax !== priceMin
        const priceRange = `${priceMin} - ${priceMax}`
        return (
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            key={index}
            display="flex"
            flexDirection="column"
          >
            <Grid
              item
              mb={1}
              width={customStylingTheme.spacing(24)}
              height={customStylingTheme.spacing(40)}
            >
              <ItemImage imageUrl={imageUrl} imageAltText={brandName} />
            </Grid>
            <Grid item container display="flex" flexDirection="column">
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  fontWeight: 'bold'
                }}
              >
                {brandName}
              </Typography>

              <Box height={40}>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {name}
                </Typography>
              </Box>
              <Grid item>
                <Typography display="inline" variant="body2">
                  {shouldShowPriceRange
                    ? priceRange
                    : priceMax
                    ? priceMax
                    : priceMin}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                onClick={() =>
                  webStyleId && removeFavorite({ webStyleIds: [webStyleId] })
                }
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ProductFavoriteGrid
