import { track } from './analytics'

export enum CATEGORIES {
  Snapshot = 'Snapshot Drawer',
  StyleBoard = 'Style Board',
  Curation = 'Curation',
  CustomerInfo = 'Customer Info',
  WishList = 'Wish List',
  PurchaseHistory = 'Purchase History'
}

export const whenSnapshotClosed = (): void =>
  track({
    event: 'Snapshot drawer closed',
    category: CATEGORIES.Snapshot
  })

export const whenStyleBoardSent = (): void =>
  track({
    event: 'Style board sent',
    category: CATEGORIES.StyleBoard,
    properties: { timeStamp: Date.now().toString() }
  })

export const whenCurationStarted = (): void =>
  track({
    event: 'Curation is started',
    category: CATEGORIES.Curation,
    properties: { timeStamp: Date.now().toString() }
  })

export const whenCustomerInfoViewed = (): void =>
  track({
    event: 'Customer info viewed',
    category: CATEGORIES.CustomerInfo,
    properties: { timeStamp: Date.now().toString() }
  })

export const whenCustomerWishListNotEmpty = (): void =>
  track({
    event: 'Customer has a Wish List',
    category: CATEGORIES.WishList
  })

// These will be used for GA unique views
export const whenCustomerPurchaseHistoryOpened = (): void =>
  track({
    event: 'Customer purchase history opened',
    category: CATEGORIES.PurchaseHistory
  })

export const whenCustomerPurchaseHistoryNotEmpty = (): void =>
  track({
    event: 'Customer has a purchase history',
    category: CATEGORIES.PurchaseHistory
  })

export const whenCustomerPurchaseHistoryEmpty = (): void =>
  track({
    event: 'Customer has no purchase history',
    category: CATEGORIES.PurchaseHistory
  })

export const whenCustomerWishListOpened = (): void =>
  track({
    event: 'Customer Wish List opened',
    category: CATEGORIES.WishList
  })
