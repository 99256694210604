import { Typography, Box } from '@mui/material'

const NewCustomerBadge: () => JSX.Element = () => {
  return (
    <Box
      data-testid="newCustomerBadge"
      style={{
        backgroundColor: '#482FE9',
        width: '46px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        marginLeft: '8px',
        alignSelf: 'center'
      }}
    >
      <Typography variant="body2" fontSize="13px" color="#FFF">
        New
      </Typography>
    </Box>
  )
}

export default NewCustomerBadge
