import { ReactElement, useState, useCallback } from 'react'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import {
  IconButton,
  Box,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
  AppBar,
  Toolbar,
  Badge
} from '@mui/material'
import SmsIcon from '@mui/icons-material/Sms'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArchiveIcon from '@mui/icons-material/Archive'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import NewCustomerBadge from 'components/NewCustomerBadge'
import AnniversaryBadge from 'components/AnniversaryBadge'
import ArchivedCustomerChip from '../ArchivedCustomerChip'
import ArchiveCustomerModal from '../ArchiveCustomerModal'
import { useHistory } from 'react-router-dom'
import { isMessagingUser } from 'utils/userPermissions'
import { useAppSelector } from 'app/hooks'
import { twilioSelectors } from 'services/twilioSlice'

type PropsT = {
  customerName: string
  goBackAction: () => void
  displayNewCustomerBadge: () => boolean
  anniversaryEligibleDate: string
  isArchivedCustomer?: boolean
  updateArchiveStatus: (value: boolean) => void
}

const DetailsHeader = (props: PropsT): ReactElement => {
  const {
    customerName = '',
    goBackAction,
    displayNewCustomerBadge,
    anniversaryEligibleDate,
    isArchivedCustomer,
    updateArchiveStatus
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isArchiveCustomerModalOpen, setIsArchiveCustomerModalOpen] =
    useState(false)
  const theme = useTheme()
  const history = useHistory()
  const { isClientelingAnniversaryStylingAppEnabled } = useFeatureFlags()

  const unreadMessagesCount = useAppSelector(
    twilioSelectors.allUnreadMessagesCountSelector
  )

  const goToConversations = async () => {
    history.push('/messages')
  }

  const handleArchiveClick = () => {
    setIsArchiveCustomerModalOpen(true)
    setAnchorEl(null)
  }

  const handleModalClose = () => {
    setIsArchiveCustomerModalOpen(false)
  }

  const handleUpdateArchiveStatus = useCallback(
    (value) => {
      updateArchiveStatus(value)
      handleModalClose()
    },
    [updateArchiveStatus]
  )

  const shouldShowMessaging = () => {
    return isMessagingUser()
  }

  const renderCustomerChips = () => {
    if (isArchivedCustomer) {
      return (
        <>
          <ArchivedCustomerChip />
        </>
      )
    }
    return (
      <>
        {displayNewCustomerBadge() && <NewCustomerBadge />}
        {anniversaryEligibleDate &&
          isClientelingAnniversaryStylingAppEnabled?.active && (
            <AnniversaryBadge date={`${anniversaryEligibleDate}`} />
          )}
      </>
    )
  }

  const renderCustomerMenuItem = useCallback(() => {
    const Icon = isArchivedCustomer ? UnarchiveIcon : ArchiveIcon
    const action = isArchivedCustomer
      ? () => {
          setAnchorEl(null)
          handleUpdateArchiveStatus(false)
        }
      : handleArchiveClick
    return (
      <MenuItem onClick={action} dense>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        {isArchivedCustomer ? 'Move to customer book' : 'Archive'}
      </MenuItem>
    )
  }, [isArchivedCustomer, handleUpdateArchiveStatus])

  return (
    <Box>
      <AppBar
        position="static"
        style={{ backgroundColor: 'white', boxShadow: 'none' }}
      >
        <Toolbar style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '10px',
              flex: 1
            }}
          >
            <IconButton aria-label="Back" onClick={goBackAction} size="large">
              <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
            </IconButton>
            <Box>
              {shouldShowMessaging() && (
                <>
                  <IconButton
                    data-testid="conversationsIngress"
                    onClick={goToConversations}
                    size="large"
                    sx={{ color: '#1F2122' }}
                  >
                    <Badge
                      badgeContent={unreadMessagesCount}
                      max={9}
                      color="primary"
                      data-testid="unreadMessagesBadge"
                    >
                      <SmsIcon data-testid="sms-icon" />
                    </Badge>
                  </IconButton>
                </>
              )}
              <IconButton
                data-testid="qebabButton"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <MoreVertIcon sx={{ color: 'text.primary' }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {renderCustomerMenuItem()}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {customerName && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            justifyContent: 'flex-start',
            paddingBottom: '8px',
            minHeight: '64px',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'center'
            }
          }}
        >
          <Box
            sx={{
              ml: '16px',
              [theme.breakpoints.up('md')]: {
                ml: '0px',
                width: '80%',
                justifyContent: 'flex-start'
              },
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginLeft: '10px',
                marginTop: '10px',
                marginBottom: '10px'
              }}
            >
              {customerName}
            </Typography>
            {renderCustomerChips()}
          </Box>
        </Box>
      )}
      <ArchiveCustomerModal
        customerName={customerName}
        isOpen={isArchiveCustomerModalOpen}
        onClose={handleModalClose}
        archiveCustomer={handleUpdateArchiveStatus}
      />
    </Box>
  )
}

export default DetailsHeader
