import { CSSProperties, ReactComponentElement } from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { colors } from '../MenuBar/constants'
import SvgIcon from '@mui/material/SvgIcon'
import { ListItemButton } from '@mui/material'

type PropsT = {
  icon: ReactComponentElement<typeof SvgIcon>
  title: string
  path: string
  index: number
  goToPath: ({ path, index }: { path: string; index: number }) => void
  isSelected: boolean
  customStyle?: CSSProperties
}

const NavMenuItem = (props: PropsT): JSX.Element => {
  const { icon, title, path, index, goToPath, isSelected, customStyle } = props
  return (
    <ListItemButton
      key={title}
      selected={isSelected}
      onClick={() => goToPath({ path, index })}
      sx={customStyle}
    >
      <IconButton
        sx={{
          color: isSelected ? colors.secondary : colors.primaryWithTransparency,
          display: 'flex',
          padding: '4px 32px 4px 0px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch'
        }}
      >
        {icon}
      </IconButton>
      <Typography color={isSelected ? colors.secondary : colors.primary}>
        {title}
      </Typography>
    </ListItemButton>
  )
}

export default NavMenuItem
