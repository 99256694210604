import { HashRouter, Route, useLocation } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import FiltersContent from './FiltersContent'

export const FILTERS_DRAWER_WIDTH = 320
export const FILTERS_HASH = 'filters'

type PropsT = {
  onClose: () => void
  customersCount: number
}

const FiltersDrawer = (props: PropsT): JSX.Element => {
  const { onClose, customersCount } = props
  const { hash } = useLocation()

  const isFiltersDrawerOpen = hash.startsWith(`#${FILTERS_HASH}`)

  return (
    <Drawer
      anchor="right"
      open={isFiltersDrawerOpen}
      onClose={onClose}
      variant="temporary"
      sx={{
        width: { xs: '100%', sm: `${FILTERS_DRAWER_WIDTH}px` },
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: `${FILTERS_DRAWER_WIDTH}px` }
        }
      }}
    >
      <HashRouter basename={FILTERS_HASH} hashType="noslash">
        <Route exact path="/">
          <FiltersContent onClose={onClose} customersCount={customersCount} />
        </Route>
      </HashRouter>
    </Drawer>
  )
}

export default FiltersDrawer
