import { AppBar, Box, Button, Dialog, Toolbar } from '@mui/material'
import { colors } from 'pages/CustomerMessages/constants'
import { ReactElement, useState } from 'react'
import { ReduxMedia } from 'types/Twilio'
import MediaMessage from './MediaMessage'

type PropsT = {
  media: ReduxMedia
  messageSid: string
}

const MediaContainer = ({ media, messageSid }: PropsT): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showControls, setShowControls] = useState(true)

  const handleMediaMessageClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const toggleShowControls = () => {
    setShowControls(!showControls)
  }

  return (
    <>
      <MediaMessage
        media={media}
        id={messageSid}
        onClick={handleMediaMessageClick}
      />

      <Dialog fullScreen open={isModalOpen} onClose={handleModalClose}>
        <Box
          onClick={toggleShowControls}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <MediaMessage
            fullScreen
            media={media}
            id={media.sid}
            onClick={toggleShowControls}
          />
        </Box>

        {showControls && (
          <>
            <AppBar
              elevation={0}
              sx={{
                backgroundColor: colors.modalLightGrey,
                position: 'absolute'
              }}
            >
              <Toolbar sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={handleModalClose} data-testid="done-button">
                  Done
                </Button>
              </Toolbar>
            </AppBar>

            <AppBar
              elevation={0}
              sx={{
                backgroundColor: colors.modalLightGrey,
                position: 'fixed',
                bottom: 0,
                top: 'auto'
              }}
            >
              <Toolbar />
            </AppBar>
          </>
        )}
      </Dialog>
    </>
  )
}

export default MediaContainer
