import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import setStandardHeaders from 'utils/set-standard-headers'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  HoldT,
  HoldFormData,
  GetHoldsQueryDataT,
  Product,
  HoldsCustomer,
  StoreFormData,
  HoldsApiGetStoresFindResponse,
  HoldsProductCompleteRequest,
  GetHoldsQueryDataParamsT,
  PrintHoldResponseT,
  CreateHoldResponse,
  HoldsGetHoldsByTicketNumberRequest
} from 'types/Holds'

export const holdsApi = createApi({
  reducerPath: 'holdsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_HOLDS_URL,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers })
      headers.set('accept', 'application/json')

      const accessToken = oktaTokenStorage.getOktaAccessToken()

      if (accessToken) {
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        const storeNumber =
          oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()

        headers.set('Employee-Id', employeeId)
        headers.set('Nord-Source-Store', storeNumber)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    }
  }),
  tagTypes: ['Holds'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getHoldsByEmployeeId: builder.mutation<
      GetHoldsQueryDataT,
      {
        employeeId: string
        offset: number
      } & GetHoldsQueryDataParamsT
    >({
      query: (params) => ({
        url: '/holds',
        method: 'GET',
        params
      }),
      invalidatesTags: ['Holds']
    }),
    getHoldsByPhoneNumber: builder.mutation<
      GetHoldsQueryDataT,
      {
        direction: 'ASC' | 'DESC'
        customerPhoneNumber: string
        offset: number
      }
    >({
      query: (params) => ({
        url: '/holds',
        method: 'GET',
        params
      }),
      invalidatesTags: ['Holds']
    }),
    getHoldsByTicketNumber: builder.mutation<
      HoldT,
      HoldsGetHoldsByTicketNumberRequest
    >({
      query: (arg) => ({
        url: `/holds/${arg.ticketNumber}`,
        method: 'GET'
      })
    }),
    createHold: builder.mutation<CreateHoldResponse, { body: HoldFormData }>({
      query: ({ body }) => {
        return {
          url: '/holds',
          method: 'POST',
          body
        }
      }
    }),
    createHoldsReport: builder.mutation<
      { jobId: string; status: string },
      void
    >({
      query: () => {
        return {
          url: '/holds/reports/jobs/activeHolds',
          method: 'POST'
        }
      }
    }),
    getActiveHoldsReportStatus: builder.query<void, { jobId: string }>({
      query: (arg) => {
        return {
          url: `/holds/reports/jobs/${arg.jobId}/activeHolds/status`,
          method: 'GET'
        }
      }
    }),
    getActiveHoldsReport: builder.query<void, { jobId: string }>({
      query: (arg) => {
        return {
          url: `/holds/reports/jobs/${arg.jobId}/activeHolds`,
          method: 'GET'
        }
      }
    }),
    getStore: builder.query<
      HoldsApiGetStoresFindResponse,
      { using: 'employeeId'; employeeId: string }
    >({
      keepUnusedDataFor: 300,
      query: (params) => {
        return {
          url: `/stores/find`,
          method: 'GET',
          params
        }
      }
    }),
    setStore: builder.mutation<void, { body: StoreFormData }>({
      query: ({ body }) => {
        return {
          url: '/stores',
          method: 'POST',
          body
        }
      }
    }),
    getProductByUPC: builder.mutation<Product, { upc: string }>({
      query: (arg) => {
        return {
          url: `/products/${arg.upc}`,
          method: 'GET'
        }
      }
    }),
    getCustomerByMobile: builder.mutation<
      HoldsCustomer,
      { phoneNumber: string }
    >({
      query: (arg) => {
        return {
          url: `/customers`,
          method: 'GET',
          params: {
            phoneNumber: arg.phoneNumber
          }
        }
      }
    }),
    completeHold: builder.mutation<HoldT, HoldsProductCompleteRequest>({
      query: (body) => {
        return {
          url: `/holds/${body.ticketNumber}/completion`,
          method: 'POST',
          body
        }
      }
    }),
    printHold: builder.mutation<
      PrintHoldResponseT,
      { body: { ticketNumber: string; printerName: string } }
    >({
      query: ({ body }) => {
        return {
          url: `/holds/${body.ticketNumber}/printing`,
          method: 'POST',
          body
        }
      }
    }),
    cancelHold: builder.mutation<void, { value: string }>({
      query: (arg) => {
        return {
          url: '/holds/cancellation',
          method: 'POST',
          params: {
            value: arg.value
          }
        }
      }
    })
  })
})

export const {
  useGetHoldsByEmployeeIdMutation,
  useGetHoldsByPhoneNumberMutation,
  useGetHoldsByTicketNumberMutation,
  useGetStoreQuery,
  useGetProductByUPCMutation,
  useGetCustomerByMobileMutation,
  useCreateHoldsReportMutation,
  useGetActiveHoldsReportStatusQuery,
  useGetActiveHoldsReportQuery,
  useCreateHoldMutation,
  useCompleteHoldMutation,
  usePrintHoldMutation,
  useCancelHoldMutation
} = holdsApi
