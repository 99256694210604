const readFromLocalStorage = (key: string) => {
  const READ_FAILURE_MSG = 'Failed to read from local storage'
  let localStorageData

  try {
    localStorageData = window.localStorage.getItem(key)
  } catch (e) {
    console.error(READ_FAILURE_MSG, e)
    return
  }

  return localStorageData ? JSON.parse(localStorageData) : undefined
}

const getOktaToken = () => {
  return readFromLocalStorage('okta-token-storage')
}

const getOktaIdToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.idToken?.idToken
}

const getOktaAccessToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.accessToken?.accessToken
}

const getEmployeeNumberFromOktaToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.idToken?.claims?.employee_number
}

const getUidFromOktaToken = (): string => {
  return getOktaToken().accessToken?.claims?.uid
}

const getEmployeeEmailFromOktaToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.idToken?.claims?.email
}

const getEmployeeGroupsFromOktaToken = (): string[] => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.accessToken?.claims?.groups_filter || []
}

const getEmployeeFirstNameFromOktaToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.idToken?.claims?.first_name
}

const getEmployeeLastNameFromOktaToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.idToken?.claims?.last_name
}

const getEmployeeStoreNumberFromOktaToken = (): string => {
  const oktaAccessToken = getOktaToken()
  return oktaAccessToken?.idToken?.claims?.store_number
}

const oktaTokenStorage = {
  getOktaIdToken,
  getOktaAccessToken,
  getEmployeeEmailFromOktaToken,
  getEmployeeNumberFromOktaToken,
  getUidFromOktaToken,
  getEmployeeGroupsFromOktaToken,
  getEmployeeFirstNameFromOktaToken,
  getEmployeeLastNameFromOktaToken,
  getEmployeeStoreNumberFromOktaToken
}

export default oktaTokenStorage
