import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { goBack } from 'utils/navigation-utils'

export type PropsT = {
  isCloseButton?: boolean
  title?: string
  onClick?: () => void
}

const SnapshotNavigation = (props: PropsT): ReactElement => {
  const { isCloseButton, title, onClick } = props
  const history = useHistory()

  const handleOnClick = () => {
    onClick ? onClick() : goBack(history)
  }

  return (
    <Stack direction="row" alignContent="center" spacing={2} my={1}>
      <Box width="50px" display="flex" alignItems="center">
        <IconButton
          aria-label={isCloseButton ? 'close' : 'back'}
          onClick={handleOnClick}
        >
          {isCloseButton ? (
            <CloseIcon sx={{ color: 'common.black' }} />
          ) : (
            <ChevronLeft
              sx={{ color: 'common.black' }}
              data-testid="backButton"
            />
          )}
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography fontWeight="bold" variant="h6">
          {title}
        </Typography>
      </Box>
    </Stack>
  )
}

export default SnapshotNavigation
