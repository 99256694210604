import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    oktaAccessToken: ''
  },
  reducers: {
    setOktaToken: (state, { payload }) => {
      const { oktaAccessToken } = payload
      state.oktaAccessToken = oktaAccessToken
    }
  }
})

export const { setOktaToken } = employeeSlice.actions

export const getOktaTokenSelector = (state: RootState): string =>
  state.employee.oktaAccessToken
