import { Chip } from '@mui/material'
import { ReactElement } from 'react'

interface PropsT {
  eligibilityDate: string | null | undefined
}

const AnniversaryEligibility = (props: PropsT): ReactElement => {
  const { eligibilityDate } = props

  return (
    <Chip
      label={`Eligible ${eligibilityDate}`}
      color="primary"
      style={{ backgroundColor: '#FBE446' }}
      sx={{ color: 'black' }}
    />
  )
}

export default AnniversaryEligibility
