import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  ListItemAvatar
} from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useHistory } from 'react-router-dom'
import { ReactElement } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DividerComponent from '../DividerComponent'

const ManageFavoriteItems = (): ReactElement => {
  const history = useHistory()
  const handleClick = () => {
    history.push('/favorites')
  }

  return (
    <>
      <DividerComponent />
      <Typography variant="h6">Favorite items</Typography>

      <ListItem
        onClick={handleClick}
        secondaryAction={
          <IconButton edge="end">
            <NavigateNextIcon />
          </IconButton>
        }
      >
        <ListItemButton sx={{ pl: 0 }}>
          <ListItemAvatar>
            <FavoriteIcon color="primary" />
          </ListItemAvatar>
          <ListItemText primary="Manage favorite items" />
        </ListItemButton>
      </ListItem>
    </>
  )
}

export default ManageFavoriteItems
