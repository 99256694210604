export const reportCustomNewRelicError = (opts: {
  customErrorText?: string
  error?: Error
}): undefined => {
  if (window.newrelic) {
    const error = opts.error || new Error(opts.customErrorText)

    // must use try/catch to report stack trace in all browsers
    // source: https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/#browser-limits
    try {
      throw error
    } catch (err) {
      window.newrelic.noticeError(err)
    }
  } else {
    // new relic agent is not run locally
    console.log('newrelic object is undefined')
  }
}
