import { Box } from '@mui/material'
import Badge from 'components/Badge'
import { ReactElement } from 'react'

interface PropsT {
  date: string
}

const AnniversaryBadge = ({ date }: PropsT): ReactElement => (
  <Box sx={{ marginLeft: '8px', alignSelf: 'center' }}>
    <Badge text={`Eligible ${date}`} />
  </Box>
)

export default AnniversaryBadge
