import { ReactElement, useEffect, useState } from 'react'
import { AbandonCurationArgsT, CurationT } from 'types/Curation'
import { useAbandonCurationMutation } from 'services/curationSvc'
import DeleteDraftDialog from 'pages/Drafts/components/DeleteDraftDialog'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { deleteDraftProductDiscovery } from 'utils/draftProductDiscovery'
import { deleteDraftOrderedItems } from 'utils/draftOrderedItems'
import { deleteDraftNotes } from 'utils/draftNotes'
import oktaTokenStorage from 'utils/okta-token-utils'
import BaseSnackbar from 'components/BaseSnackbar'
import CurationCard from 'components/CurationCard'

interface PropsT {
  curation: CurationT
  setHandleAbandonCurationError: (isAbandonCurationError: boolean) => void
}

const DraftCard = (props: PropsT): ReactElement => {
  const { curation, setHandleAbandonCurationError } = props
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState(false)
  const [curationIdToDelete, setCurationIdToDelete] = useState<
    number | undefined
  >()

  const [
    abandonCuration,
    { isError: isAbandonCurationError, isSuccess: isAbandonCurationSuccess }
  ] = useAbandonCurationMutation()

  const handleAbandon = async (abandonCurationArgs: AbandonCurationArgsT) => {
    try {
      await abandonCuration(abandonCurationArgs).unwrap()
      const attributes = {
        curationId: curation.id,
        numberOfItemsInBag: Object.keys(abandonCurationArgs.products).length,
        shoppingSessionId: curation.shoppingSessionId
      }
      generateNewRelicLogs('abandonCuration', attributes)
      setCurationIdToDelete(undefined)
      deleteDraftNotes(abandonCurationArgs.curationId)
      deleteDraftOrderedItems(abandonCurationArgs.curationId)
      deleteDraftProductDiscovery(abandonCurationArgs.curationId)
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    setHandleAbandonCurationError(isAbandonCurationError)
  }, [setHandleAbandonCurationError, isAbandonCurationError])

  return (
    <>
      <CurationCard
        curation={curation}
        isDraft={true}
        hideMenu={false}
        handleOpenDeleteDraftDialog={setIsDeleteDraftDialogOpen}
        handleSetCurationIdToDelete={setCurationIdToDelete}
        isHomepageView={true}
      />
      {curationIdToDelete && isDeleteDraftDialogOpen && (
        <DeleteDraftDialog
          handleAbandon={() =>
            handleAbandon({
              curationId: curationIdToDelete,
              employeeId,
              products: [],
              isCancel: false
            })
          }
          open={isDeleteDraftDialogOpen}
          setIsDeleteDraftDialogOpen={setIsDeleteDraftDialogOpen}
        />
      )}
      {isAbandonCurationSuccess && <BaseSnackbar message={'Draft deleted.'} />}
    </>
  )
}

export default DraftCard
