import { useMemo } from 'react'
import { Box, Divider, List, ListSubheader, Typography } from '@mui/material'
import map from 'lodash/map'
import {
  mapCustomerListToDefault,
  mapCustomerListToSections
} from 'pages/CustomerHome/utils'
import { ConsentedCustomerListItemT } from 'types/ConsentedCustomer'
import CustomerCard from '../CustomerCard'
import ArchivedCustomerItem from '../ArchivedCustomerItem'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { CUSTOMER_SORT_KEYS, CustomerSortKeysT } from '../SortModal/utils'

type PropsT = {
  customers: ConsentedCustomerListItemT[]
  customerCardAction: (customerId: string) => void
  listRef?: React.RefObject<HTMLUListElement>
  maxHeight?: number
  query?: string
  displayArchivedSection?: boolean
  displayEmptySearchResults?: boolean
  sortFunctionKey?: CustomerSortKeysT
}

const CustomerList = (props: PropsT): JSX.Element => {
  const {
    customers,
    customerCardAction,
    listRef,
    maxHeight,
    query,
    displayArchivedSection,
    displayEmptySearchResults,
    sortFunctionKey
  } = props

  const displayListSubheader =
    sortFunctionKey === CUSTOMER_SORT_KEYS.byAlphabetical
  const isMobileDevice = checkBrowserClient.isMobile()

  const customerListMap = useMemo(
    () =>
      displayListSubheader
        ? mapCustomerListToSections(customers)
        : mapCustomerListToDefault(customers),
    [customers, displayListSubheader]
  )

  return (
    <>
      <List
        sx={{
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight,
          '& ul': { padding: 0 }
        }}
        subheader={<li />}
        ref={listRef}
      >
        {map(Object.keys(customerListMap), (sectionKey) => (
          <li key={`section-${sectionKey}`}>
            <ul>
              {displayListSubheader && (
                <>
                  <ListSubheader
                    sx={{
                      background: '#F9FAFA',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#000',
                      lineHeight: 2.5
                    }}
                  >
                    {sectionKey}
                  </ListSubheader>
                  <Divider />
                </>
              )}
              {(
                customerListMap[
                  sectionKey as keyof typeof customerListMap
                ] as ConsentedCustomerListItemT[]
              ).map((item) => (
                <CustomerCard
                  key={`item-${sectionKey}-${item.customerId}`}
                  customer={item}
                  onClick={customerCardAction}
                  query={query}
                />
              ))}
            </ul>
          </li>
        ))}
        {displayArchivedSection && (
          <>
            <Box
              sx={{
                background: '#f9fafa',
                height: 40,
                margin: 'auto',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                paddingX: 2
              }}
            />
            <ArchivedCustomerItem />
          </>
        )}
      </List>
      {displayEmptySearchResults && (
        <Box sx={{ paddingX: isMobileDevice ? '16px' : 0 }}>
          <Typography variant="subtitle2">{`No results found for "${query}"`}</Typography>
          <Typography variant="caption" color="#6B777B" fontWeight="390">
            Check the spelling and try again.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default CustomerList
