import { ReactElement, useState } from 'react'

import {
  Button,
  CircularProgress,
  TextField,
  Box,
  Typography
} from '@mui/material'

const getOnlyNumbersFromString = (value: string) => value.replace(/[\D]/g, '')

const HoldsButtonAddItem = ({
  isLoading,
  handleSubmit
}: {
  isLoading: boolean
  handleSubmit: (upc: string) => void
}): ReactElement => {
  const [upc, setUpc] = useState('')

  const onClickEnter = () => {
    handleSubmit(upc)
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="body2" color="black">
          Scan barcode or enter UPC number manually.
        </Typography>
      </Box>

      <Box display="flex" gap={1.5} mb={2}>
        <TextField
          fullWidth
          id="upc"
          label="UPC"
          name="upc"
          value={upc}
          onChange={(event) => {
            if (isLoading) {
              return
            }

            setUpc(getOnlyNumbersFromString(event.target.value))
          }}
          inputProps={{
            'data-testid': 'upc_field',
            maxLength: 13,
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
          aria-label="Add item by UPC number"
          size="small"
        />

        <Button variant="contained" onClick={onClickEnter} disabled={isLoading}>
          {isLoading ? <CircularProgress size={28} /> : 'Enter'}
        </Button>
      </Box>
    </>
  )
}

export default HoldsButtonAddItem
