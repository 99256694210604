export const colors = {
  fromStylistContainer: '#482FE91F',
  fromCustomerContainer: '#0000000F',
  white: '#FFF',
  sendMessageEnabled: '#482FE9',
  lightGray: '#96A0A4',
  modalLightGrey: '#0000000F',
  secondary: '#586366',
  disabled: '#1F21224D'
}
