import { Box, Divider, ListItemIcon, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useHistory } from 'react-router-dom'

const ArchivedCustomers: () => JSX.Element = () => {
  const history = useHistory()

  return (
    <>
      <Divider />
      <Box
        onClick={() =>
          history.push({
            pathname: `/customers/archived-customers`
          })
        }
        aria-label="archivedCustomerEntryPoint"
        data-testid="archivedCustomerEntryPoint"
        sx={{
          margin: 'auto',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 16px',
          height: 48,
          alignItems: 'center'
        }}
      >
        <Typography fontSize={16}>Archived Customers</Typography>
        <ListItemIcon sx={{ minWidth: 24 }}>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </Box>
      <Divider />
    </>
  )
}

export default ArchivedCustomers
