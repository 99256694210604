import { Button } from '@mui/material'
import { ReactElement } from 'react'
import SearchIcon from '@mui/icons-material/Search'

type PropsT = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const FashionMapSearchButton = (props: PropsT): ReactElement => {
  const { onClick } = props

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      fullWidth
      sx={{ mt: 2 }}
      startIcon={<SearchIcon />}
    >
      Concept Search
    </Button>
  )
}

export default FashionMapSearchButton
