import { DeliveryStatus } from '@twilio/conversations'

export enum CONVERSATION_STATE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CLOSED = 'closed'
}

export type ReduxConversation = {
  sid: string
  friendlyName: string | null
  notificationLevel: 'default' | 'muted'
  unreadMessagesCount: number
  mostRecentMessageDate: string
  state: CONVERSATION_STATE
  customerId: string
}

export type ConversationsRecord = Record<string, ReduxConversation>

export type ReduxMedia = {
  sid: string
  contentType: string
  url: string
}

export type ReduxMessage = {
  sid: string
  index: number
  body: string | null
  author: string | null
  participantSid: string | null
  dateCreated: string
  type: 'media' | 'text'
  attachedMedia: ReduxMedia
  deliveryReceipt: {
    status?: DeliveryStatus
    errorCode?: string | 0
  }
  failedToLoad?: boolean
}

export type MessagesCollection = {
  [sid: string]: ReduxMessage[]
}

export type TwilioClientSlice = {
  conversations: ReduxConversation[]
}
export interface ConversationT extends ReduxConversation {
  lastMessage: ReduxMessage
}

export enum ErrorMessages {
  MESSAGE_NOT_SENT = 'Message not sent to Twilio',
  MESSAGE_PREVIEW_FAILED_TO_LOAD = 'Message preview failed to load',
  PHONE_NUMBER_MAY_BE_A_LANDLINE = 'phone number may be a landline',
  PHONE_NUMBER_IS_A_LANDLINE = 'phone number is a landline',
  BLOCKED_AS_SPAM = 'blocked as spam',
  CONTENT_NOT_SUPPORTED_BY_RECEIVING_NUMBER = 'content not supported by receiving number',
  TRY_AGAIN = 'try again'
}

export enum HelperErrorMessages {
  KNOWN_MOBILE_NUMBER = 'Known mobile number?',
  THIS_THIS_IS_A_MISTAKE = 'Think this is a mistake?'
}

export enum MediaContentType {
  IMAGE = 'image',
  VIDEO = 'video'
}

export type AttachedMediaOptions = {
  media: File
  contentType: string
  filename: string
  src: string
}

export type UploadedAttachedMedia = {
  media?: AttachedMediaOptions
  isLoading: boolean
  uiMediaHasLoaded?: boolean
}

export enum messageStatusView {
  MESSAGE_THREAD = 'messageThread',
  CONVERSATION_LIST = 'conversationList'
}

export type DraftMessagesT = {
  [key: string]: {
    message?: string | null
    images?: AttachedMediaOptions[] | null
    personalBookingLink?: string | null
  }
}

export type ParticipantAttributesT = {
  display_name: string
  customer_id: string
}
