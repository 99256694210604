import BaseDialog from 'components/BaseDialog'
import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'

interface PropsT {
  handleAbandon: () => void
}

const AbandonOrContinueDialog = (props: PropsT): ReactElement => {
  const { handleAbandon, ...dialogProps } = props
  const history = useHistory()
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
    history.replace('/review')
  }

  return (
    <BaseDialog
      title={'Style board in progress'}
      contentText={
        'To start a new style board, you must finish or delete the one in progress.'
      }
      confirm={{
        action: () => handleAbandon(),
        text: 'Delete this style board'
      }}
      cancel={{
        action: handleClose,
        text: 'Keep working'
      }}
      onClose={() => handleClose()}
      open={open}
      {...dialogProps}
    />
  )
}

export default AbandonOrContinueDialog
