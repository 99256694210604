import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Box, Container, Select, MenuItem, Typography } from '@mui/material'
// eslint-disable-next-line
import { SelectChangeEvent } from '@mui/material/Select'

import LoadingSpinner from 'components/LoadingSpinner'

import SnapshotNavigation from '../../pages/SnapshotDrawer/components/SnapshotNavigation'
import ProductGrid from 'components/ProductGrid'
import PageError from 'components/PageError'

import { whenCustomerWishListNotEmpty } from 'utils/track'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  useGetCustomerQuery,
  useGetDefaultWishListDataQuery,
  useLazyGetWishListDataByIdQuery,
  useLazyGetAvailabilityFromSkusQuery
} from 'services/employeeExperienceApi'
import { skipToken } from '@reduxjs/toolkit/query'
import { pdpLink } from 'utils/pdpLink'
import { TransformedWishList } from 'types/WishList'
import AnniversaryToggle from '../../pages/CustomerDetails/components/AnniversaryToggle/AnniversaryToggle'
import StoreAvailabilityModal from './components/StoreAvailabilityModal'
import StoreAvailabilityButton from './components/StoreAvailabilityButton'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { ANNIVERSARY_ENTICEMENTS } from 'types/Product'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import { curationSelector } from 'app/curationSlice'
import { useAppSelector } from 'app/hooks'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_ENABLE_STORE_AVAILABILITY_FILTER,
  NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_OPEN_PDP,
  NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_SELECT_DIFFERENT_WISH_LIST
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

type PropsT = {
  customerId: string
  handleClickForPdp?: (webstyleId: string) => void
  hideSnapshotNavigation?: boolean
  isInCustomerDetails?: boolean
  shopperId?: string
  token?: string
}

const WishList = (props: PropsT): ReactElement => {
  const [isStoreAvailabilityModalOpen, setIsStoreAvailabilityModalOpen] =
    useState(false)
  const [storeAvailablityError, setStoreAvailabilityError] = useState<
    CopyErrorDataT | undefined
  >(undefined)
  const [skusAvailableInStore, setSkusAvailableInStore] = useState<
    Set<string> | undefined
  >(undefined)
  const [
    isStoreAvailabilityFilterSelected,
    setIsStoreAvailabilityFilterSelected
  ] = useState(false)
  const [
    isStoreAvailabilityCheckboxChecked,
    setIsStoreAvailabilityCheckboxChecked
  ] = useState(false)
  const [selectedWishList, setSelectedWishList] =
    useState<TransformedWishList>()
  const [isAnniversaryToggleSelected, setIsAnniversaryToggleSelected] =
    useState<boolean>(false)
  const [itemsAdded, setItemsAdded] = useState(new Set<string>())
  const {
    customerId,
    hideSnapshotNavigation,
    handleClickForPdp,
    isInCustomerDetails = false
  } = props
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const storeNumber = oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()
  const wishListsErrorTitle = 'Issue getting Wish Lists.'
  const { isAnniversaryFilterEnabled, isWishListAvailabilityFilterEnabled } =
    useFeatureFlags()
  const curationDetails = useAppSelector(curationSelector)
  const [isLoadingNewWishList, setIsLoadingNewWishList] = useState(false)

  const nrCustomerDetailsEventAttributes = {
    page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS,
    ocpId: customerId
  }

  const {
    data: customerData,
    isLoading: isCustomerDataLoading,
    isFetching: isCustomerDataFetching,
    isError: isCustomerDataError,
    error: customerDataError,
    endpointName: customerDataEndpointName
  } = useGetCustomerQuery(customerId, {
    skip: !customerId
  })

  const {
    data: wishListDefaultData,
    isLoading: isWishListDefaultDataLoading,
    isFetching: isWishListDefaultDataFetching,
    isError: isWishListDefaultDataError,
    error: defaultWishListError,
    endpointName: wishListEndpointName
  } = useGetDefaultWishListDataQuery(
    customerData?.shopperId ? { shopperId: customerData?.shopperId } : skipToken
  )

  const [
    getAvailabilityFromSkus,
    {
      isFetching: isAvailabilityFromSkusFetching,
      isLoading: isAvailabilityFromSkusLoading
    }
  ] = useLazyGetAvailabilityFromSkusQuery()

  const [
    getWishListById,
    { isFetching: isWishListByIdFetching, isLoading: isWishListByIdLoading }
  ] = useLazyGetWishListDataByIdQuery()

  useEffect(() => {
    setSelectedWishList(wishListDefaultData)
  }, [wishListDefaultData])

  useEffect(() => {
    const updateSkusAvailableInStore = async () => {
      if (!isStoreAvailabilityFilterSelected) {
        setSkusAvailableInStore(undefined)
      } else {
        const items = selectedWishList?.items ?? []
        const skusToFetch = items.map((item) => item.rmsSkuId)

        const { data, error, endpointName } = await getAvailabilityFromSkus({
          store: storeNumber,
          skus: skusToFetch
        })

        if (error) {
          setSkusAvailableInStore(undefined)
          setIsStoreAvailabilityFilterSelected(false)
          setIsStoreAvailabilityCheckboxChecked(false)
          setStoreAvailabilityError({
            errorData: error,
            endpoint: endpointName
          })
        } else if (!!data && !error) {
          setSkusAvailableInStore(new Set(data.availableSkus))
          setStoreAvailabilityError(undefined)
        }
      }
    }

    updateSkusAvailableInStore()
  }, [
    isStoreAvailabilityFilterSelected,
    selectedWishList,
    storeNumber,
    getAvailabilityFromSkus
  ])

  const onItemAdded = useCallback(
    (rmsSkuId: string) => {
      setItemsAdded(itemsAdded.add(rmsSkuId))

      generateNewRelicLogs('wishlistItemAddClick', {
        rmsSku: rmsSkuId,
        ...mapCurationSliceToNewRelicLog(curationDetails)
      })
    },
    [curationDetails, itemsAdded, setItemsAdded]
  )

  const onClickForPDP = (styleId?: string) => {
    if (!styleId) {
      return
    }
    // From Snapshot
    if (handleClickForPdp) {
      return handleClickForPdp(styleId.toString())
    }
    // From Customer Details
    generateNewRelicLogs(NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_OPEN_PDP, {
      ...nrCustomerDetailsEventAttributes,
      webStyleId: styleId
    })
    return window.open(pdpLink(styleId), '_blank')
  }
  const isLoading =
    isCustomerDataLoading ||
    isCustomerDataFetching ||
    isWishListDefaultDataLoading ||
    isWishListDefaultDataFetching ||
    isWishListByIdFetching ||
    isWishListByIdLoading ||
    isAvailabilityFromSkusFetching ||
    isAvailabilityFromSkusLoading ||
    isLoadingNewWishList

  if (isLoading) {
    return <LoadingSpinner />
  }

  // A customer has no shopper Id or no items in any wish lists
  const hasNoPopulatedWishLists =
    wishListDefaultData &&
    wishListDefaultData.items.length === 0 &&
    wishListDefaultData.wishLists.length === 1
  const hasNoShopperId = customerData && !customerData.shopperId

  if (hasNoPopulatedWishLists || hasNoShopperId) {
    {
      return (
        <Container>
          <Typography my="12px" mt={1} variant="body2" textAlign="left">
            No Wish Lists
          </Typography>
        </Container>
      )
    }
  }

  const handleWishListChange = async (event: SelectChangeEvent) => {
    setIsLoadingNewWishList(true)
    isInCustomerDetails &&
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_SELECT_DIFFERENT_WISH_LIST,
        nrCustomerDetailsEventAttributes
      )
    const newWishlist = await getWishListById({
      shopperId: customerData?.shopperId as string,
      wishListId: event.target.value as string
    }).unwrap()
    setSelectedWishList(newWishlist)
    setIsLoadingNewWishList(false)
  }
  const onWishListOpened = (event: SelectChangeEvent) => {
    whenCustomerWishListNotEmpty()
    handleWishListChange(event)
  }

  const handleStoreAvailabilityModalClose = () => {
    setIsStoreAvailabilityModalOpen(false)
  }

  const handleStoreAvailabilityModalApply = async (checked: boolean) => {
    isInCustomerDetails &&
      checked &&
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_ENABLE_STORE_AVAILABILITY_FILTER,
        nrCustomerDetailsEventAttributes
      )
    setIsStoreAvailabilityFilterSelected(checked)
    handleStoreAvailabilityModalClose()
  }

  const { items } = selectedWishList || {}
  const availableItems = items
    ? items.filter(({ isPublished }) => isPublished === true)
    : []
  const soldOutItems = items
    ? items.filter(({ isPublished }) => isPublished === false)
    : []
  let sortedItems = [...availableItems, ...soldOutItems]

  // Filter store availability
  if (isStoreAvailabilityFilterSelected && !!skusAvailableInStore) {
    sortedItems = sortedItems.filter((item) =>
      skusAvailableInStore.has(item.rmsSkuId)
    )
  }

  if (isAnniversaryToggleSelected) {
    sortedItems = sortedItems.filter((item) => {
      const isAnniversaryItem = item.enticements.some(
        (enticement) =>
          enticement.type &&
          Object.values<string>(ANNIVERSARY_ENTICEMENTS).includes(
            enticement.type
          )
      )

      return isAnniversaryItem
    })
  }

  return (
    <>
      {isStoreAvailabilityModalOpen && (
        <StoreAvailabilityModal
          isOpen={isStoreAvailabilityModalOpen}
          isFilterSelected={isStoreAvailabilityFilterSelected}
          isChecked={isStoreAvailabilityCheckboxChecked}
          handleApply={handleStoreAvailabilityModalApply}
          handleClose={handleStoreAvailabilityModalClose}
          setIsChecked={setIsStoreAvailabilityCheckboxChecked}
        />
      )}
      <Container>
        {!hideSnapshotNavigation && <SnapshotNavigation title="Wish List" />}
        {selectedWishList && (
          <>
            <Box my={3}>
              <Select
                id="wish-list-select"
                value={selectedWishList.id}
                onChange={onWishListOpened}
                sx={{
                  width: '343px'
                }}
              >
                {selectedWishList.wishLists.map((wishList, index) => {
                  return (
                    <MenuItem key={index} value={wishList.id}>
                      {wishList.name} ({wishList.productCount})
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            {storeAvailablityError && (
              <Box mb={'16px'}>
                <PageError
                  errorDetails={{
                    errorData: storeAvailablityError?.errorData,
                    endpoint: storeAvailablityError?.endpoint
                  }}
                  isErrorTitlePersonalized={true}
                  errorTitle="Issue filtering by store availability - try again"
                  isFullWidth
                  shouldShowRefresh={false}
                />
              </Box>
            )}
            <Box sx={{ display: 'flex' }}>
              {isWishListAvailabilityFilterEnabled?.active && (
                <StoreAvailabilityButton
                  onClick={() => setIsStoreAvailabilityModalOpen(true)}
                  isFilterSelected={isStoreAvailabilityFilterSelected}
                />
              )}
              {isAnniversaryFilterEnabled?.active && (
                <AnniversaryToggle
                  setIsAnniversaryToggleSelected={
                    setIsAnniversaryToggleSelected
                  }
                  isAnniversaryToggleSelected={isAnniversaryToggleSelected}
                  customerId={customerId}
                  isInCustomerDetails={isInCustomerDetails}
                />
              )}
            </Box>
            {(isAnniversaryToggleSelected ||
              isStoreAvailabilityFilterSelected) &&
              sortedItems.length === 0 && (
                <Box>
                  <Typography variant="subtitle2">No items found</Typography>
                  <Typography variant="body2" color={'secondary'}>
                    Adjust the filters and try again.
                  </Typography>
                </Box>
              )}
            <Box mb={4} mt={'24px'}>
              {selectedWishList && (
                <ProductGrid
                  customerId={customerId}
                  itemsAdded={itemsAdded}
                  onClickForPDP={onClickForPDP}
                  onItemAdded={onItemAdded}
                  products={sortedItems}
                  shopperId={props.shopperId}
                  isInCustomerDetails={isInCustomerDetails}
                  token={props.token}
                />
              )}
            </Box>
          </>
        )}
        {isWishListDefaultDataError && (
          <PageError
            isInlineAlert={true}
            errorTitle={wishListsErrorTitle}
            errorDetails={{
              endpoint: wishListEndpointName,
              errorData: defaultWishListError,
              identifiers: {
                customerId: customerId,
                curationId: 'NO-DATA',
                employeeId,
                shoppingSessionId: 'NO-DATA'
              }
            }}
          />
        )}
        {isCustomerDataError && (
          <PageError
            isInlineAlert={true}
            errorTitle={wishListsErrorTitle}
            errorDetails={{
              endpoint: customerDataEndpointName,
              errorData: customerDataError,
              identifiers: {
                customerId: customerId,
                curationId: 'NO-DATA',
                employeeId,
                shoppingSessionId: 'NO-DATA'
              }
            }}
          />
        )}
      </Container>
    </>
  )
}

export default WishList
