import { ReactElement } from 'react'
import { Tab } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'

export enum TabPosition {
  CUSTOMER = '0',
  PUBLIC = '1'
}
interface PropsT {
  children: JSX.Element | JSX.Element[]
  handleTabChange: (_event: React.SyntheticEvent, newValue: TabPosition) => void
  tabPosition: TabPosition
}

const CurationCardListTabs = (props: PropsT): ReactElement => {
  const { children, handleTabChange, tabPosition } = props

  return (
    <TabContext value={tabPosition}>
      <TabList onChange={handleTabChange}>
        <Tab value={TabPosition.CUSTOMER} label="Customer boards" />
        <Tab value={TabPosition.PUBLIC} label="Public boards" />
      </TabList>
      {children}
    </TabContext>
  )
}

export default CurationCardListTabs
