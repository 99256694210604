import { ReactElement, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  Fade,
  styled
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import {
  HoldsHandleCloseT,
  HoldsStartNewHoldFormParamsT
} from 'pages/Holds/types'

import { SNACKBAR_DURATION } from 'pages/constants'

import {
  HOLDS_COLOR_TEXT_UNDO,
  HOLDS_MEDIA_MAX_WIDTH_CREATE_HOLD_AND_EDIT_ITEMS,
  HOLDS_TEXT_CREATE_NEW_HOLD,
  HOLDS_TEXT_ITEM_ADDED_TO_HOLD,
  HOLDS_TEXT_ITEM_REMOVED,
  HOLDS_TEXT_UNDO
} from 'pages/Holds/constants'

import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import { useHoldsGetErrorMessage, useHoldsReducerStartNewHold } from './hooks'

import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'
import HoldsBoxDivider from 'pages/Holds/shared/components/HoldsBoxDivider'

import HoldsStoreNumber from 'pages/Holds/components/HoldsStoreNumber'

import {
  HoldsAddCustomer,
  HoldsDialogOutOfStock,
  HoldsManageItems,
  HoldsRequiredLabel,
  HoldsTypeRadioGroup
} from './components'

const BoxWithTopSpace = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3)
}))

const HoldsDialogBodyStartNewHold = ({
  handleClose,
  handleSubmit,
  isCreateHoldLoading,
  isCreateHoldSuccess,
  dataCreateHoldError
}: HoldsHandleCloseT & {
  handleSubmit: (params: HoldsStartNewHoldFormParamsT) => void
  isCreateHoldLoading: boolean
  isCreateHoldSuccess: boolean
  dataCreateHoldError: unknown
}): ReactElement => {
  const {
    handleNotificationAdd,
    handleNotificationAddSuccessMessage,
    handleNotificationRemove
  } = useHoldsContextNotification()

  const [formParams, dispatchFormParams] = useHoldsReducerStartNewHold()
  const [showErrorHelperText, setShowErrorHelperText] = useState(false)

  const { errorMessage, hasErrorMessage } = useHoldsGetErrorMessage({
    formParams
  })

  const allowErrorMessage = showErrorHelperText && hasErrorMessage
  const allowPlaceHold =
    !isCreateHoldLoading && !isCreateHoldSuccess && !hasErrorMessage

  return (
    <>
      <HoldsDialogAppBar
        title={HOLDS_TEXT_CREATE_NEW_HOLD}
        handleClose={handleClose}
        closeIconButtonProps={{
          children: <CloseIcon sx={{ color: 'text.primary' }} />
        }}
      />

      <HoldsDialogContent
        sx={{
          maxWidth: HOLDS_MEDIA_MAX_WIDTH_CREATE_HOLD_AND_EDIT_ITEMS
        }}
      >
        {allowErrorMessage && (
          <Fade in={allowErrorMessage}>
            <Alert
              severity="error"
              sx={{
                pb: 0,
                mb: 2
              }}
            >
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          </Fade>
        )}

        <HoldsRequiredLabel />

        <Box mt={1.5}>
          <HoldsAddCustomer
            value={formParams.customerInfo}
            onChange={(value) => {
              if (isCreateHoldLoading || isCreateHoldSuccess) {
                return
              }

              dispatchFormParams({
                payload: {
                  name: 'customerInfo',
                  value
                }
              })
            }}
          />
        </Box>

        <HoldsBoxDivider />

        <BoxWithTopSpace>
          <HoldsStoreNumber />
        </BoxWithTopSpace>

        <BoxWithTopSpace>
          <HoldsTypeRadioGroup
            value={formParams.holdType}
            onChange={(event, value) => {
              if (isCreateHoldLoading || isCreateHoldSuccess) {
                return
              }

              dispatchFormParams({
                payload: {
                  name: 'holdType',
                  value
                }
              })
            }}
          />
        </BoxWithTopSpace>

        <Box mt={2}>
          <HoldsManageItems
            value={formParams.itemsOnHold}
            onChange={(value) => {
              if (isCreateHoldLoading || isCreateHoldSuccess) {
                return
              }

              dispatchFormParams({
                payload: {
                  name: 'itemsOnHold',
                  value: [value, ...formParams.itemsOnHold]
                }
              })

              handleNotificationAddSuccessMessage({
                title: HOLDS_TEXT_ITEM_ADDED_TO_HOLD,
                subTitle: value.name
              })
            }}
            handleRemoveItem={(value) => {
              if (isCreateHoldLoading || isCreateHoldSuccess) {
                return
              }

              const clonedItemsOnHoldBeforeRemoving = [
                ...formParams.itemsOnHold
              ]

              dispatchFormParams({
                payload: {
                  name: 'itemsOnHold',
                  value: formParams.itemsOnHold.filter((item) => item !== value)
                }
              })

              handleNotificationAdd({
                message: HOLDS_TEXT_ITEM_REMOVED,
                snackBarProps: {
                  autoHideDuration: SNACKBAR_DURATION,
                  action: (
                    <Button
                      size="small"
                      sx={{
                        color: HOLDS_COLOR_TEXT_UNDO
                      }}
                      onClick={() => {
                        dispatchFormParams({
                          payload: {
                            name: 'itemsOnHold',
                            value: clonedItemsOnHoldBeforeRemoving
                          }
                        })

                        handleNotificationRemove()
                      }}
                    >
                      {HOLDS_TEXT_UNDO}
                    </Button>
                  )
                }
              })
            }}
          />
        </Box>
      </HoldsDialogContent>

      <DialogActions
        sx={{
          width: '100%',
          maxWidth: 526,
          margin: '0 auto',
          flexWrap: 'wrap',
          gap: 2,
          pb: 4,
          '&>:not(style)~:not(style)': {
            ml: 0
          }
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            setShowErrorHelperText(true)

            if (!allowPlaceHold) {
              return
            }

            handleSubmit(formParams)
          }}
          disabled={isCreateHoldLoading || isCreateHoldSuccess}
        >
          Place Hold & Print Ticket
        </Button>

        <Button
          fullWidth
          variant="outlined"
          onClick={handleClose}
          disabled={isCreateHoldLoading}
        >
          Cancel
        </Button>
      </DialogActions>

      <HoldsDialogOutOfStock
        dataCreateHoldError={dataCreateHoldError}
        dispatchFormParams={dispatchFormParams}
        formParams={formParams}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default HoldsDialogBodyStartNewHold
