import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  GetAppointmentLinkResponseT,
  PersonalizedAppointmentLinkParamsT
} from 'types/Appointments'

export const appointmentsService = createApi({
  reducerPath: 'appointmentsService',
  tagTypes: ['Appointments'],
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-Nord-Gateway', 'UTG')
      return headers
    },
    baseUrl: process.env.REACT_APP_APPOINTMENTS_SVC
  }),
  endpoints: (builder) => ({
    getPersonalizedLink: builder.query<
      GetAppointmentLinkResponseT,
      PersonalizedAppointmentLinkParamsT
    >({
      query: ({ store, email }) => {
        return {
          url: `/booking/staff/email/${email}/store/${store}/link`,
          method: 'GET'
        }
      },
      providesTags: ['Appointments']
    })
  })
})

export const { useGetPersonalizedLinkQuery } = appointmentsService
