import { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Typography, Button, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { checkBrowserClient } from 'utils/userAgentDetector'
import {
  useActivateEmployeeCommunicationsMutation,
  useUserHasActiveCommunicationsQuery
} from 'services/clientelingApi'
import ActivateCommunications from '../ActivateCommunications'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

const CustomerHomeHeader = ({
  employeeId,
  isCustomerHomeError
}: {
  employeeId: string
  isCustomerHomeError: boolean
}): ReactElement => {
  const isMobileDevice = checkBrowserClient.isMobile()
  const [isCommunicationsAlertVisible, setIsCommunicationsAlertVisible] =
    useState(false)

  const { data: userCommunicationsData, isError: isUserCommunicationsError } =
    useUserHasActiveCommunicationsQuery({ employeeId })
  const history = useHistory()

  useEffect(() => {
    if (!isUserCommunicationsError) {
      if (userCommunicationsData?.isActive) {
        localStorage.setItem('communications-status', 'active')
        setIsCommunicationsAlertVisible(false)
      } else {
        setIsCommunicationsAlertVisible(true)
      }
    } else {
      setIsCommunicationsAlertVisible(false)
    }
  }, [userCommunicationsData, isUserCommunicationsError])

  const [
    activateEmployeeCommunications,
    {
      endpointName: activateCommunicationsEndpoint,
      error: activateCommunicationsError,
      originalArgs: activateCommunicationsArgs,
      isError: isActivateCommunicationsError,
      isLoading: isActivateCommunicationsLoading,
      isSuccess: isActivateCommunicationsSuccess
    }
  ] = useActivateEmployeeCommunicationsMutation()

  const activateCommunications = () => {
    activateEmployeeCommunications({ employeeId })
      .unwrap()
      .then(() => {
        localStorage.setItem('communications-status', 'active')
        setIsCommunicationsAlertVisible(false)
        return
      })
      .catch(() => {
        return
      })
  }

  const inviteClick = () => {
    generateNewRelicLogs('customerHomeInviteButtonClick')
    history.push({ pathname: '/customers/invite' })
  }

  return (
    <Container
      sx={{
        maxWidth: 1040,
        height: '100%',
        paddingRight: isMobileDevice && 0,
        paddingLeft: isMobileDevice && 0,
        mt: '20px'
      }}
    >
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '20px'
        }}
      >
        <Typography variant="h6" sx={{ marginLeft: isMobileDevice && '16px' }}>
          Customer book
        </Typography>
        {!isCommunicationsAlertVisible &&
          !isUserCommunicationsError &&
          !isCustomerHomeError && (
            <Button
              onClick={inviteClick}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ fontSize: '16px', marginRight: isMobileDevice && '20px' }}
            >
              Invite
            </Button>
          )}
      </Box>
      {!isCustomerHomeError && (
        <ActivateCommunications
          isUserCommunicationsError={isUserCommunicationsError}
          isActivationSuccess={isActivateCommunicationsSuccess}
          isActivateCommunicationsError={isActivateCommunicationsError}
          activateCommunications={activateCommunications}
          isVisible={
            isCommunicationsAlertVisible &&
            !localStorage.getItem('communications-status')
          }
          isActivateCommunicationsLoading={isActivateCommunicationsLoading}
          errorDetails={{
            endpoint: activateCommunicationsEndpoint,
            errorData: activateCommunicationsError,
            identifiers: {
              employeeId
            },
            originalArgs: activateCommunicationsArgs
          }}
        />
      )}
    </Container>
  )
}

export default CustomerHomeHeader
