import { ReactElement } from 'react'
import Drawer from 'components/Drawer'
import SnapshotContent from './SnapshotContent'
import { SNAPSHOT_DRAWER_WIDTH } from 'pages/constants'

export type PropsT = {
  handleClickForPdp: (webstyleId: string) => void
  id: string | null
  isSellerInitiated: boolean
  onClose: () => void
  isSnapshotOpen: boolean
  shopperId?: string
  token?: string
}

const SnapshotDrawer = (props: PropsT): ReactElement | null => {
  const {
    handleClickForPdp,
    id,
    isSellerInitiated,
    onClose,
    isSnapshotOpen,
    shopperId,
    token
  } = props

  if (!isSnapshotOpen) {
    return null
  }

  if (!id) {
    return null
  }

  return (
    <Drawer
      variant="persistent"
      open={isSnapshotOpen}
      onClose={onClose}
      width={SNAPSHOT_DRAWER_WIDTH}
    >
      <SnapshotContent
        handleClickForPdp={handleClickForPdp}
        id={id}
        isSellerInitiated={isSellerInitiated}
        onClose={onClose}
        shopperId={shopperId}
        token={token}
      />
    </Drawer>
  )
}

export default SnapshotDrawer
