import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { HOLDS_TEXT_TITLE } from 'pages/Holds/constants'

const HoldsTitle = (): ReactElement => (
  <Typography component="h1" variant="h6">
    {HOLDS_TEXT_TITLE}
  </Typography>
)

export default HoldsTitle
