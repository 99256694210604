import { ReactElement } from 'react'
import { Typography } from '@mui/material'

const NoRecentItems = (): ReactElement => (
  <>
    <Typography variant="subtitle2" mb={1}>
      No recent items
    </Typography>
    <Typography variant="body2">
      Items you add to shopping bags will be listed here. Use them to help
      create new boards faster.
    </Typography>
  </>
)

export default NoRecentItems
