import { ReactElement, useEffect, useState, useRef, useMemo } from 'react'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import type {
  BarcodeCapture,
  BarcodeCaptureListener,
  BarcodeCaptureSession
} from 'scandit-web-datacapture-barcode'
import { SDKWithLoadingStatus } from './ScanditSDK'

interface PropsT {
  getByUPC: (upc: string) => void
  scanditSDK: SDKWithLoadingStatus
}

const ScanditScanner = (props: PropsT): ReactElement => {
  const {
    getByUPC,
    scanditSDK: { sdk, loaded }
  } = props
  const curationDetails = useAppSelector(curationSelector)
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const startScanTimeRef = useRef<number>(0)
  const [scanHadResponse, setScanHadResponse] = useState(false)
  const host = useRef<HTMLDivElement | null>(null)

  const onScan = useMemo<BarcodeCaptureListener>(
    () => ({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        if (sdk && session.newlyRecognizedBarcodes.length > 0) {
          setScanHadResponse(true)
          await sdk.enableScanning(false)
          await sdk.enableCamera(false)
          const barcode = session.newlyRecognizedBarcodes[0]
          const upc = session.newlyRecognizedBarcodes[0].data
          if (upc) {
            generateNewRelicLogs('ScanditScannerScanSuccess', {
              employeeId,
              curationId: curationDetails.id,
              upc,
              symbology: barcode.symbology,
              scannerStartToSuccessTime: Date.now() - startScanTimeRef.current
            })
            getByUPC(upc)
          } else {
            generateNewRelicLogs('ScanditScannerScanNoUPC', {
              employeeId,
              curationId: curationDetails.id,
              barcode,
              scannerStartToSuccessTime: Date.now() - startScanTimeRef.current
            })
          }
        }
      }
    }),
    [sdk, employeeId, curationDetails.id, getByUPC]
  )

  useEffect(() => {
    const startScanTime = Date.now()
    startScanTimeRef.current = startScanTime
    if (loaded && host.current && sdk) {
      sdk.enableCamera(true)
      sdk.connectToElement(host.current)
      sdk.enableScanning(true)
      sdk.addBarcodeCaptureListener(onScan)
    }
    return () => {
      if (!scanHadResponse) {
        const attributes = {
          scannerDrawerOpenDuration: Date.now() - startScanTimeRef.current,
          curationId: curationDetails.id
        }
        generateNewRelicLogs('scanditScannerClosedWithNoResponse', attributes)
      }
      if (sdk) {
        if (loaded) {
          sdk.removeBarcodeCaptureListener(onScan)
          sdk.detachFromElement()
        }
        sdk.enableScanning(false)
        sdk.enableCamera(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={host} className="w-full h-full" />
}

export default ScanditScanner
