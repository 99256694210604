import { ReactElement, useEffect, useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import { scrollToTop } from 'utils/scrollIntoView'
import { useStartCurationMutation } from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useHistory } from 'react-router-dom'
import {
  CURATION_IN_PROGRESS_ERROR_MESSAGE,
  NO_REQUESTS_IN_QUEUE_ERROR_MESSAGE
} from 'pages/CurationHome/constants'
import CurationCount from './CurationCount'
import RequestCountError from '../RequestCountError'
import { RequestedQueueDataT } from 'pages/CurationHome/CurationHome'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isStyleAdvisor } from 'utils/userPermissions'
import DividerComponent from '../DividerComponent'

interface PropsT {
  requestedQueueData?: RequestedQueueDataT
  requestedQueueError: boolean
  handleNoRequestsInQueue: () => void
  handleStartCurationError: (value: CopyErrorDataT) => void
}

const StartRequest = (props: PropsT): ReactElement => {
  const {
    requestedQueueData,
    requestedQueueError,
    handleNoRequestsInQueue,
    handleStartCurationError
  } = props
  const history = useHistory()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const { isGeneralRequestLIFOEnabled } = useFeatureFlags()
  const lifoEnabled = isGeneralRequestLIFOEnabled?.active && isStyleAdvisor()
  const [
    startCuration,
    { data, endpointName, error, isLoading, isSuccess, originalArgs }
  ] = useStartCurationMutation()

  const proceedToReviewPage = useCallback(() => {
    history.push({ pathname: '/review', hash: 'snapshot' })
  }, [history])

  useEffect(() => {
    if (error) {
      if ('status' in error) {
        const errorMessage = JSON.stringify(error.data)
        if (errorMessage?.includes(CURATION_IN_PROGRESS_ERROR_MESSAGE)) {
          history.push({
            pathname: '/review',
            hash: 'snapshot',
            state: { inProgressCuration: true }
          })
          return
        }

        if (errorMessage?.includes(NO_REQUESTS_IN_QUEUE_ERROR_MESSAGE)) {
          handleNoRequestsInQueue()
          scrollToTop()
          return
        }
      }

      handleStartCurationError({
        endpoint: endpointName,
        errorData: error,
        originalArgs
      })
      scrollToTop()
    }
  }, [
    endpointName,
    error,
    handleNoRequestsInQueue,
    handleStartCurationError,
    history,
    originalArgs
  ])

  useEffect(() => {
    if (data && isSuccess) {
      proceedToReviewPage()
    }
  }, [data, isSuccess, proceedToReviewPage])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Box>
      <DividerComponent />
      {requestedQueueError ? (
        <RequestCountError />
      ) : (
        <CurationCount curationCount={requestedQueueData?.count} />
      )}
      {requestedQueueData && (
        <Typography mt={1} mb={2} color="text.secondary" variant="subtitle1">
          Page refreshed {requestedQueueData.refreshedAt.format('MMM DD')} at{' '}
          {requestedQueueData.refreshedAt.format('h:mma')}
        </Typography>
      )}
      <Box mx="auto" mb={1} mt={4} width={[1, 1 / 4]}>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={async () => {
            startCuration({ employeeId, lifoEnabled })
          }}
        >
          Start New Request
        </Button>
      </Box>
    </Box>
  )
}

export default StartRequest
