import { TextField } from '@mui/material'
import { ReactElement, useContext } from 'react'
import {
  DESCRIPTION_FORM_HELPER_TEXT,
  ITEM_DESCRIPTION_LABEL,
  MAX_DESCRIPTION_LENGTH,
  UPDATE_DESCRIPTION
} from '../../constants'
import { EDIT_DESCRIPTION } from 'pages/CurationEdit/constants'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { determineHelperText } from 'utils/inputValidations'

type PropsT = {
  isEdit: boolean
  isError: boolean
  description: string
}

const CurationDescription = (props: PropsT): ReactElement => {
  const { isEdit, isError, description } = props
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)

  const helperText = determineHelperText(
    description.length,
    MAX_DESCRIPTION_LENGTH,
    DESCRIPTION_FORM_HELPER_TEXT
  )

  const handleChange = (value: string) => {
    if (isEdit) {
      editDispatch && editDispatch({ type: EDIT_DESCRIPTION, payload: value })
    } else {
      dispatch &&
        dispatch({
          type: UPDATE_DESCRIPTION,
          payload: { description: value }
        })
    }
  }

  return (
    <TextField
      error={isError}
      fullWidth
      helperText={helperText}
      id={'description-id'}
      label={ITEM_DESCRIPTION_LABEL}
      multiline
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        handleChange(event?.target.value)
      }}
      rows={7}
      variant="outlined"
      value={description}
    />
  )
}

export default CurationDescription
