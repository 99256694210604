import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { emDashCharacter } from 'pages/constants'

const HIT_A_SNAG_MESSAGE = `We hit a snag ${emDashCharacter} `

interface PropsT {
  errorDetails?: CopyErrorDataT
  setCopyToClipboardSuccess: (arg: boolean) => void
}

const HitASnagAlert = (props: PropsT): ReactElement => {
  const { errorDetails, setCopyToClipboardSuccess } = props
  return (
    <BasePageAlert
      alertTitle={HIT_A_SNAG_MESSAGE}
      errorDetails={errorDetails}
      isDismissable={true}
      setCopyToClipboardSuccess={setCopyToClipboardSuccess}
      shouldShowRefresh={true}
    />
  )
}

export default HitASnagAlert
