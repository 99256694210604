import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import FashionMapSearchError from '../FashionMapSearchError'
import FashionMapSearchResultCard from '../FashionMapSearchResultCard'
import { FashionMapSearchResultT } from 'types/FashionMapSearch'

interface PropsT {
  error: unknown
  handleClickForPdp: (styleId: string) => void
  isError: boolean
  isLoading: boolean
  results?: FashionMapSearchResultT[]
}

const NoSearchResults = (): ReactElement => {
  return (
    <Box mt={2}>
      <Typography fontWeight="bold" variant="subtitle2">
        No search results
      </Typography>
      <Typography
        color="secondary"
        variant="subtitle2"
        sx={{ fontWeight: 'regular' }}
      >
        Check the spelling or try a different term
      </Typography>
    </Box>
  )
}

const FashionMapSearchResults = (props: PropsT): ReactElement => {
  const { error, handleClickForPdp, isError, isLoading, results } = props

  if (isLoading) {
    return <LoadingSpinner hideText={true} />
  }

  if (isError) {
    return <FashionMapSearchError error={error} />
  }

  if (results && results.length === 0) {
    return <NoSearchResults />
  }

  return (
    <Box mt={2}>
      <Typography color="secondary" variant="caption">
        {results && results.length === 1
          ? '1 search result'
          : results &&
            results?.length > 0 &&
            `${results.length} search results`}
      </Typography>
      <Box mt={2}>
        {results?.map(({ brand, image, itemName, styleId, price }) => (
          <FashionMapSearchResultCard
            key={styleId}
            brand={brand}
            onClickForPdp={handleClickForPdp}
            image={image}
            itemName={itemName}
            styleId={styleId}
            price={price}
          />
        ))}
      </Box>
    </Box>
  )
}

export default FashionMapSearchResults
