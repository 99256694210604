import { ReactElement } from 'react'
import { emDashCharacter } from 'pages/constants'
import BasePageAlert from 'components/BasePageAlert'
import {
  ValidationDescriptionError,
  ValidationDuplicateItemError,
  ValidationItemError,
  ValidationTitleError
} from 'types/FormValidation'

interface ValidationResultT {
  titleError?: ValidationTitleError
  descriptionError?: ValidationDescriptionError
  itemError?: ValidationItemError | ValidationDuplicateItemError
  itemLimit?: number
}

interface ValidationAlertPropsT {
  validationResult: ValidationResultT
  isEdit: boolean
}

const setErrorMessages = (isEdit: boolean) => {
  const actionWord = isEdit ? 'edit' : 'send'

  return {
    DUPLICATE_ITEMS_ERROR: `Duplicate items ${emDashCharacter} remove duplicates and try again.`,
    FIX_VALIDATION_ERRORS_MESSAGE: `Fix errors to ${actionWord} style board`,
    NOT_ENOUGH_PRODUCTS_ERROR: isEdit
      ? 'Style board must have at least one item'
      : 'Add items to send style board',
    OVER_ITEM_LIMIT_ALERT_MESSAGE: `Remove items to ${actionWord} style board`,
    OVER_ITEM_LIMIT_ALERT_TITLE: `Item Limit`
  }
}

const ValidationAlert = (props: ValidationAlertPropsT): ReactElement => {
  const {
    isEdit,
    validationResult: { itemError, itemLimit }
  } = props

  const errorMessages = setErrorMessages(isEdit)
  const {
    DUPLICATE_ITEMS_ERROR,
    FIX_VALIDATION_ERRORS_MESSAGE,
    NOT_ENOUGH_PRODUCTS_ERROR,
    OVER_ITEM_LIMIT_ALERT_MESSAGE,
    OVER_ITEM_LIMIT_ALERT_TITLE
  } = errorMessages

  let alertTitle, errorMessage

  switch (itemError) {
    case ValidationItemError.ZERO:
      errorMessage = NOT_ENOUGH_PRODUCTS_ERROR
      break
    case ValidationItemError.TOO_MANY:
      errorMessage = OVER_ITEM_LIMIT_ALERT_MESSAGE
      alertTitle = `${itemLimit} ${OVER_ITEM_LIMIT_ALERT_TITLE}`
      break
    case ValidationDuplicateItemError.DUPLICATE_SKUS:
      errorMessage = DUPLICATE_ITEMS_ERROR
      break
    default:
      errorMessage = FIX_VALIDATION_ERRORS_MESSAGE
  }

  return (
    <BasePageAlert
      alertTitle={alertTitle}
      errorMessage={errorMessage}
      isDismissable={true}
    />
  )
}

export default ValidationAlert
