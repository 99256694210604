import { ReactElement, useEffect, useState } from 'react'
import CurationCardListTabs, {
  TabPosition
} from 'components/CurationCardListTabs'
import {
  useGetDraftCurationsQuery,
  useAbandonCurationMutation
} from 'services/curationSvc'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import DeleteDraftDialog from './DeleteDraftDialog'
import DeleteDraftAlert from './DeleteDraftAlert'
import BaseSnackbar from 'components/BaseSnackbar'
import EmptyQueue, { EmptyQueueIconOption } from 'components/EmptyQueue'
import { TabPanel } from '@mui/lab'
import CurationCardList from 'components/CurationCardList'
import { AbandonCurationArgsT } from 'types/Curation'
import oktaTokenStorage from 'utils/okta-token-utils'
import { deleteDraftNotes } from 'utils/draftNotes'
import { deleteDraftOrderedItems } from 'utils/draftOrderedItems'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { curationSelector } from 'app/curationSlice'
import { useAppSelector } from 'app/hooks'
import { customStylingTheme } from 'theme'
import { deleteDraftProductDiscovery } from 'utils/draftProductDiscovery'
import { deleteCustomerDossier } from 'utils/customerDossier'

const TAB_POSITION = 'draftsTabPosition'

const DraftsContent = (): ReactElement => {
  const [tabPosition, setTabPosition] = useState(TabPosition.CUSTOMER)
  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState(false)
  const [curationIdToDelete, setCurationIdToDelete] = useState<
    number | undefined
  >()
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()

  const curationDetails = useAppSelector(curationSelector)

  const [
    abandonCuration,
    {
      endpointName: abandonEndpoint,
      error: abandonError,
      isError: isAbandonCurationError,
      isLoading: isAbandonCurationLoading,
      originalArgs: abandonOriginalArgs,
      isSuccess: isAbandonCurationSuccess
    }
  ] = useAbandonCurationMutation()

  useEffect(() => {
    const storedTabPosition = localStorage.getItem(TAB_POSITION)
    if (
      storedTabPosition &&
      Object.values(TabPosition).includes(storedTabPosition as TabPosition)
    ) {
      setTabPosition(String(storedTabPosition) as TabPosition)
    }
    return () => {
      localStorage.removeItem(TAB_POSITION)
    }
  }, [])

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: TabPosition
  ) => {
    setTabPosition(newValue)
    localStorage.setItem(TAB_POSITION, String(newValue))
  }

  const { data, error, isError, isLoading } = useGetDraftCurationsQuery()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <PageError errorDetails={{ errorData: error }} />
  }

  const customerCurations = data?.curations.filter((curation) => curation.ocpId)
  const publicCurations = data?.curations.filter((curation) => !curation.ocpId)

  const handleAbandon = async (abandonCurationArgs: AbandonCurationArgsT) => {
    try {
      await abandonCuration(abandonCurationArgs).unwrap()
      const attributes = {
        curationId: abandonCurationArgs.curationId,
        numberOfItemsInBag: Object.keys(abandonCurationArgs.products).length,
        shoppingSessionId: curationDetails?.shoppingSessionId
      }
      generateNewRelicLogs('abandonCuration', attributes)
      setCurationIdToDelete(undefined)
      deleteDraftNotes(abandonCurationArgs.curationId)
      deleteDraftOrderedItems(abandonCurationArgs.curationId)
      deleteDraftProductDiscovery(abandonCurationArgs.curationId)
      deleteCustomerDossier(abandonCurationArgs.curationId)
    } catch (error) {
      return
    }
  }

  if (isAbandonCurationLoading) {
    return <LoadingSpinner />
  }

  if (customerCurations && publicCurations) {
    return (
      <>
        {isAbandonCurationError && (
          <DeleteDraftAlert
            errorDetails={{
              endpoint: abandonEndpoint,
              errorData: abandonError,
              identifiers: {
                employeeId
              },
              originalArgs: abandonOriginalArgs
            }}
          />
        )}
        <CurationCardListTabs
          tabPosition={tabPosition}
          handleTabChange={handleTabChange}
        >
          <>
            <TabPanel value={TabPosition.CUSTOMER} sx={{ p: 0 }}>
              {customerCurations.length > 0 ? (
                <CurationCardList
                  curations={customerCurations}
                  isPublicBoardList={false}
                  isDraft={true}
                  handleAbandon={handleAbandon}
                  handleOpenDeleteDraftDialog={setIsDeleteDraftDialogOpen}
                  handleSetCurationIdToDelete={setCurationIdToDelete}
                />
              ) : (
                <EmptyQueue
                  title={'No customer board drafts'}
                  subtitle={'Boards you draft for customers will appear here'}
                  height={'45vh'}
                  icon={EmptyQueueIconOption.DRAFT}
                  maxWidth={customStylingTheme.spacing(40)}
                />
              )}
            </TabPanel>
            <TabPanel value={TabPosition.PUBLIC} sx={{ p: 0 }}>
              {publicCurations.length > 0 ? (
                <CurationCardList
                  curations={publicCurations}
                  isPublicBoardList={true}
                  isDraft={true}
                  handleAbandon={handleAbandon}
                  handleOpenDeleteDraftDialog={setIsDeleteDraftDialogOpen}
                  handleSetCurationIdToDelete={setCurationIdToDelete}
                />
              ) : (
                <EmptyQueue
                  title={'No public board drafts'}
                  subtitle={'Boards you draft for the public will appear here'}
                  height={'45vh'}
                  icon={EmptyQueueIconOption.DRAFT}
                  maxWidth={customStylingTheme.spacing(40)}
                />
              )}
            </TabPanel>
            {curationIdToDelete && isDeleteDraftDialogOpen && (
              <DeleteDraftDialog
                handleAbandon={() =>
                  handleAbandon({
                    curationId: curationIdToDelete,
                    employeeId,
                    products: [],
                    isCancel: false
                  })
                }
                open={isDeleteDraftDialogOpen}
                setIsDeleteDraftDialogOpen={setIsDeleteDraftDialogOpen}
              />
            )}
          </>
        </CurationCardListTabs>
        {isAbandonCurationSuccess && (
          <BaseSnackbar message={'Draft deleted.'} />
        )}
      </>
    )
  }

  return <></>
}

export default DraftsContent
