import { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { CashApiToken } from 'types/CashApi'

const useIsTokenExpired = (token: string): Record<string, boolean> => {
  const [decodedToken, setDecodedToken] = useState<CashApiToken | undefined>(
    undefined
  )

  useEffect(() => {
    if (token && token !== '') {
      const decodedCheckoutToken: CashApiToken = jwt_decode<CashApiToken>(token)
      setDecodedToken(decodedCheckoutToken)
    }
  }, [token])

  const tokenExpirationTime = decodedToken && new Date(decodedToken.exp * 1000)

  const isTokenExpired =
    !!token && moment(tokenExpirationTime).isBefore(Date.now())

  return { isTokenExpired }
}

export default useIsTokenExpired
