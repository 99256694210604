export const SNACKBAR_DURATION = 6000
export const SNACKBAR_LONG_DURATION = 15000

export const FASHION_MAP_SEARCH_HASH = 'fashion-map-search'
export const RECENT_ITEMS_HASH = 'recent-items'
export const SNAPSHOT_BASE_HASH = 'snapshot'
export const SNAPSHOT_DRAWER_WIDTH = '33%'

// permissions group information can be found https://confluence.nordstrom.com/display/SEE/Personal+Book+Permissions+Overview
export const US_SELLERS_AD_GROUP = 'ACCESS_APP07298_SylingCurationUS'
export const STYLE_ADVISORS_AD_GROUP = 'ACCESS-Personal-Book-Style-Advisors'
export const SPMS_AD_GROUP = 'ACCESS-Personal-Book-SPMS'
export const PERSONAL_STYLISTS_AD_GROUP =
  'ACCESS_APP07298_SylingCuration_PersonalStylist'
export const ASMS_CURATION_APP_GROUP = 'ACCESS-Curation-App-ASMs'
export const DEV_SEC_AD_GROUP = 'TECH_Styling_Curation_Security'
export const BIZ_ADMINS_AD_GROUP = 'ACCESS-Personal-Book-Admins'

// alpha and beta user groups
export const BETA_TEST_USERS_GROUP = 'selling-empowerment-betatesting'

export const PERMISSIONS_TESTING_GROUP = 'permissions-testing-group'
export const PERMISSIONS_DOCUMENTATION_URL =
  'https://confluence.nordstrom.com/display/SEE/Personal+Book+Permissions+Overview'

export const emDashCharacter = '\u2014'

//Twilio Conversations
export const CONVERSATION_PAGE_SIZE = 50

// Finished boards messages
export const EMPTY_PUBLIC_BOARDS_MESSAGE =
  'Boards you complete for your profile and public use will appear here'
export const EMPTY_CUSTOMER_BOARDS_MESSAGE =
  'Boards you complete for customers will appear here'

// Snapshot Style Boards messages
export const SNAPSHOT_STYLE_BOARDS_ERROR_ALERT_TITLE =
  'We had a problem retrieving your finished boards for this customer.'
