import { CATEGORIES } from './track'

export type EventTrackingT = {
  event: string
  category: CATEGORIES
  properties?: Record<string, unknown>
}

export const track = (payload: EventTrackingT): void => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: payload.event,
    ...payload.properties
  })
}
