import { Container, Grid } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

type PropsT = {
  children: ReactNode[]
}

const ReviewDescriptionContainer = (props: PropsT): ReactElement => {
  const { children } = props

  return (
    <Container>
      <Grid container my={4}>
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ReviewDescriptionContainer
