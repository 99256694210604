import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Snackbar } from '@mui/material'
import { SNACKBAR_DURATION } from 'pages/constants'

interface PropsT {
  autoHideDuration?: number
  message?: ReactNode
  open?: boolean
  action?: ReactElement
  children?: ReactElement
  onClose?: () => void
  setCopyToClipboardSuccess?: (arg: boolean) => void
}

const BaseSnackbar = (props: PropsT): ReactElement => {
  const {
    autoHideDuration = SNACKBAR_DURATION,
    message,
    open,
    action,
    children,
    onClose,
    setCopyToClipboardSuccess
  } = props

  const [isDismissed, setIsDismissed] = useState(false)
  const mounted = useRef(false)

  useEffect(function mount() {
    mounted.current = true
    return function unmount() {
      mounted.current = false
    }
  })

  const onCloseDefault = (_event: React.SyntheticEvent, reason: string) => {
    setCopyToClipboardSuccess && setCopyToClipboardSuccess(false)
    if (!mounted.current) {
      return
    }
    if (reason === 'timeout') {
      setIsDismissed(true)
    }
  }

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      message={message}
      action={action}
      onClose={onClose ?? onCloseDefault}
      open={open ?? !isDismissed}
      sx={{
        // this zIndex puts all snackbars on top of the Gladly widget
        // the Gladly zIndex is 2147483000, so this needs to be higher than that
        paddingBottom: '20px',
        zIndex: 2200000000
      }}
    >
      {children}
    </Snackbar>
  )
}

export default BaseSnackbar
