import { ReactElement } from 'react'
import { LoadingButton } from '@mui/lab'
import Sort from '@mui/icons-material/Sort'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type PropsT = {
  sortSelected: boolean
  onClick: () => void
}

const SortButton = ({ sortSelected, onClick }: PropsT): ReactElement => {
  return (
    <LoadingButton
      color="secondary"
      variant={sortSelected ? 'contained' : 'outlined'}
      onClick={onClick}
      startIcon={<Sort />}
      endIcon={<ExpandMoreIcon />}
      sx={{ fontSize: '16px', marginBottom: '16px' }}
    >
      Sort
    </LoadingButton>
  )
}

export default SortButton
