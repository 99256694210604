import { ReactElement } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import ItemImage from 'components/ItemImage'
import PriceBlock from 'components/PriceBlock'
import { EnticementDetailsT } from 'types/Product'

interface PropsT {
  brandName?: string
  color?: string
  currentPrice?: number
  enticements?: EnticementDetailsT[]
  imageUrl?: string
  itemName?: string
  itemNumber?: string
  onClickForPDP?: (webStyleId: string) => void
  regularPrice?: number
  size?: string
  webStyleId?: string
}

const ItemDetails = (props: PropsT): ReactElement => {
  const {
    brandName,
    color,
    currentPrice,
    enticements,
    imageUrl,
    itemName,
    itemNumber,
    onClickForPDP,
    regularPrice,
    size,
    webStyleId
  } = props
  return (
    <Box display="flex">
      {webStyleId && (
        <Button
          aria-label="view product details"
          onClick={() => onClickForPDP && onClickForPDP(webStyleId)}
          sx={{ maxWidth: '74px', padding: 0 }}
        >
          <ItemImage
            autoHeight={true}
            imageAltText={`${brandName} ${itemName}`}
            imageUrl={imageUrl}
          />
        </Button>
      )}
      <Stack sx={{ ml: 1 }}>
        <Typography
          mb={0.5}
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
        >
          <strong>{brandName && `${brandName}`}</strong>{' '}
          {itemName && `${itemName}`}
        </Typography>
        <Typography
          mb={1}
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {size && `${size},`} {color && `${color}`}
        </Typography>
        <PriceBlock
          enticements={enticements}
          price={{
            current: currentPrice,
            original: regularPrice
          }}
        />
        <Typography color="text.secondary" variant="caption" mt={1}>
          {itemNumber && `Item #${itemNumber}`}
        </Typography>
      </Stack>
    </Box>
  )
}

export default ItemDetails
