import { ReactElement } from 'react'

import type { IconButtonProps, SxProps } from '@mui/material'

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import { HoldsHandleCloseT } from 'pages/Holds/types'

const HoldsDialogAppBar = ({
  title,
  handleClose,
  closeIconButtonProps,
  withButtonConfirm,
  isLoading,
  buttonConfirmTitle = 'Done',
  sx = {}
}: {
  title: string
  closeIconButtonProps?: IconButtonProps & {
    'data-testid'?: string
  }
  withButtonConfirm?: boolean
  isLoading?: boolean
  buttonConfirmTitle?: string
  sx?: SxProps
} & HoldsHandleCloseT): ReactElement => (
  <AppBar
    position="sticky"
    sx={{
      backgroundColor: 'initial',
      boxShadow: 0,
      ...sx
    }}
  >
    <Toolbar
      sx={{
        my: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <IconButton
          size="large"
          onClick={handleClose}
          aria-label="close"
          {...closeIconButtonProps}
        >
          {closeIconButtonProps?.children ?? (
            <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
          )}
        </IconButton>

        <Typography variant="h6" color="text.primary">
          {title}
        </Typography>
      </Box>
      {withButtonConfirm && (
        <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
          {buttonConfirmTitle}
        </Button>
      )}
    </Toolbar>
  </AppBar>
)

export default HoldsDialogAppBar
