import { Badge, Box, IconButton, InputAdornment } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import { ReactElement } from 'react'
import SendIcon from '@mui/icons-material/Send'
import { colors } from '../../../constants'

interface SubmitSMSButtonProps {
  badgeCount: number
  isSendActionAllowed: () => boolean
  isSubmitPending: boolean
  handleSubmit: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const SubmitSMSButton = ({
  badgeCount,
  isSendActionAllowed,
  isSubmitPending,
  handleSubmit
}: SubmitSMSButtonProps): ReactElement => {
  const badgeLabel = (badgeCount: number) =>
    badgeCount > 1 ? `${badgeCount} attachments` : '0 or 1 attachments'

  const isSendButtonEnabled = isSendActionAllowed()
  return (
    <InputAdornment position="end">
      <IconButton
        disabled={!isSendButtonEnabled}
        onClick={handleSubmit}
        aria-label="submit-message-button-customer"
      >
        {isSubmitPending ? (
          <Box
            sx={{
              paddingTop: '38%',
              paddingLeft: '62%'
            }}
          >
            <LoadingSpinner />
          </Box>
        ) : (
          <Badge
            badgeContent={badgeCount}
            invisible={!badgeCount || badgeCount <= 1}
            color="primary"
            overlap="circular"
            aria-label={badgeLabel(badgeCount)}
          >
            <SendIcon
              sx={{
                color: isSendButtonEnabled
                  ? colors.sendMessageEnabled
                  : colors.lightGray
              }}
              aria-label="submit-message-icon-customer"
            />
          </Badge>
        )}
      </IconButton>
    </InputAdornment>
  )
}

export default SubmitSMSButton
