import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'

export type PropsT = {
  width?: string
  height?: string
}

const ImageUnavailable = (props: PropsT): ReactElement => {
  const { width = '100%', height = '100%' } = props

  return (
    <Box
      sx={{
        width,
        height,
        backgroundColor: '#F9F9F9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography align="center" color="text.secondary" variant="caption">
        Image unavailable
      </Typography>
    </Box>
  )
}

export default ImageUnavailable
