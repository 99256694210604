import { createSelector } from '@reduxjs/toolkit'

import {
  ReduxConversation,
  MessagesCollection,
  ReduxMessage,
  ConversationT,
  ConversationsRecord,
  DraftMessagesT,
  CONVERSATION_STATE
} from 'types/Twilio'

import { getTime } from 'utils/date-utils'
import { RootState } from 'app/store'

export const activeConversationIdSelector: (state: RootState) => string | null =
  (state: RootState) => state.twilioSlice.activeConversationId

export const isLoadingSelector: (state: RootState) => boolean = (
  state: RootState
) => state.twilioSlice.isLoading

export const conversationsSelector: (state: RootState) => ConversationsRecord =
  (state: RootState) => state.twilioSlice.conversations

export const allMessagesById: (state: RootState) => MessagesCollection = (
  state: RootState
) => state.twilioSlice.messagesById

export const messagesByIdSelector = (
  state: RootState,
  id: string
): ReduxMessage[] => {
  return state.twilioSlice.messagesById[id] || []
}

export const conversationByIdSelector = (
  state: RootState,
  id: string
): ReduxConversation => {
  const conversation = state.twilioSlice.conversations[id]

  return conversation || ({} as ReduxConversation)
}

export const allDraftMessagesByIdSelector = (
  state: RootState
): DraftMessagesT => {
  return state.twilioSlice.draftMessages
}

export const draftMessageSelector = createSelector(
  activeConversationIdSelector,
  allDraftMessagesByIdSelector,
  (activeConversationId, messages) => {
    if (!activeConversationId) {
      return {}
    }

    return messages[activeConversationId] || {}
  }
)

const allConversationIds = createSelector(
  conversationsSelector,
  (conversations) => {
    return Object.keys(conversations || {})
      .slice()
      .filter((conversationSid) => {
        // Exclude Seller-Initiated Empty Conversations
        return !!conversations[conversationSid]?.mostRecentMessageDate
      })
  }
)

export const sortedConversationIds = createSelector(
  conversationsSelector,
  allConversationIds,
  (conversations, allConversationIds) => {
    const optedInCustomerConversations = allConversationIds.filter(
      (conversationId) => {
        return (
          conversations[conversationId]?.state === CONVERSATION_STATE.ACTIVE
        )
      }
    )

    return optedInCustomerConversations
      .slice()
      .sort(
        (oldest, newest) =>
          new Date(conversations[newest].mostRecentMessageDate).getTime() -
          new Date(conversations[oldest].mostRecentMessageDate).getTime()
      )
  }
)

export const sortedConversationsWithRecentMessage = createSelector(
  sortedConversationIds,
  conversationsSelector,
  allMessagesById,
  (sortedConversationIds, conversations, allMessages) => {
    return <ConversationT[]>sortedConversationIds.map((conversationId) => {
      const conversationMessages = allMessages[conversationId] || []
      const mostRecentMessage =
        conversationMessages[conversationMessages.length - 1] || {}
      const friendlyName = conversations[conversationId]?.friendlyName || ''
      const mostRecentMessageDate =
        mostRecentMessage?.dateCreated ||
        conversations[conversationId]?.mostRecentMessageDate
      return {
        ...conversations[conversationId],
        friendlyName,
        lastMessage: {
          ...mostRecentMessage,
          dateCreated: getTime(mostRecentMessageDate, { today: 'h:mm a' })
        }
      }
    })
  }
)

export const activeConversationMessagesSelector = createSelector(
  activeConversationIdSelector,
  allMessagesById,
  (activeConversationId, messages) => {
    if (!activeConversationId) {
      return []
    }

    return messages[activeConversationId] || []
  }
)

export const isConversationOptedIn = createSelector(
  activeConversationIdSelector,
  conversationsSelector,
  (activeConversationId, conversations) => {
    if (!activeConversationId) {
      return false
    }

    return conversations[activeConversationId]?.state !== 'inactive'
  }
)

export const allUnreadMessagesCountSelector = createSelector(
  conversationsSelector,
  (conversations) => {
    const conversationIds = Object.keys(conversations)
    return conversationIds.reduce<number>((tempSum, sid) => {
      const conversation = conversations[sid]

      const amountToAdd =
        conversation.state === CONVERSATION_STATE.ACTIVE
          ? conversation.unreadMessagesCount
          : 0

      const totalCount = tempSum + amountToAdd

      if (navigator && navigator.setAppBadge) {
        navigator.setAppBadge(totalCount > 0 ? 1 : 0)
      }

      return totalCount
    }, 0)
  }
)

export const hasUnreadMessagesSelector = createSelector(
  allUnreadMessagesCountSelector,
  (allUnreadMessagesCount = 0) => {
    return allUnreadMessagesCount > 0
  }
)
