/**
 * Get incremental timeout for each index between animation duration
 * Example: index 113 and 3 return the same timeout
 */
const holdsGetGrowTimeout = ({
  index,
  quantityInBatch = 10,
  minDuration = 300,
  maxDuration = 2000
}: {
  index: number
  quantityInBatch?: number
  minDuration?: number
  maxDuration?: number
}): number => {
  const currentIndexInBatch =
    index - Math.floor(index / quantityInBatch) * quantityInBatch
  const quantityDeltaDuration =
    ((maxDuration - minDuration) / quantityInBatch) * currentIndexInBatch

  return minDuration + quantityDeltaDuration
}

export default holdsGetGrowTimeout
