import { ReactElement, useState, useRef, useEffect, ReactChild } from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import { checkBrowserClient } from 'utils/userAgentDetector'
import './styles.css'

type CarouselElementT = { scrollWidth: number; offsetWidth: number }

interface PropsT {
  children: ReactChild[] | null | undefined
  displayArrows: boolean | null | undefined
  closeSpacing?: boolean
}

const Carousel = (props: PropsT): ReactElement => {
  const { children, displayArrows, closeSpacing } = props

  const carouselElement = useRef<HTMLInputElement | null>(null)

  const [carouselElementCurrent, setCarouselElementCurrent] =
    useState<CarouselElementT>({ scrollWidth: 0, offsetWidth: 0 })
  const [scrollPosition, setScrollPosition] = useState(0)

  const isMobile = checkBrowserClient.isMobile()

  useEffect(() => {
    if (carouselElement.current) {
      setCarouselElementCurrent({
        scrollWidth: carouselElement.current?.scrollWidth,
        offsetWidth: carouselElement?.current?.offsetWidth
      })
    }
  }, [carouselElement])

  const carouselTotalWidth =
    carouselElementCurrent.scrollWidth === carouselElementCurrent.offsetWidth
      ? 0
      : carouselElementCurrent.scrollWidth -
        carouselElementCurrent.offsetWidth -
        10
  const isLeftButtonActive = scrollPosition !== 0
  const isRightButtonActive = scrollPosition <= carouselTotalWidth

  const onScrollLeft = () => {
    carouselElement?.current?.scrollBy({
      top: 0,
      left: -300,
      behavior: 'smooth'
    })
    setScrollPosition(carouselElement?.current?.scrollLeft || 0)
    setCarouselElementCurrent({
      scrollWidth: carouselElement?.current?.scrollWidth || 0,
      offsetWidth: carouselElement?.current?.offsetWidth || 0
    })
  }

  const onScrollRight = () => {
    carouselElement?.current?.scrollBy({
      top: 0,
      left: 300,
      behavior: 'smooth'
    })
    setScrollPosition(carouselElement?.current?.scrollLeft || 0)
    setCarouselElementCurrent({
      scrollWidth: carouselElement?.current?.scrollWidth || 0,
      offsetWidth: carouselElement?.current?.offsetWidth || 0
    })
  }

  const onCarouselScroll = () => {
    if (carouselElement.current) {
      setScrollPosition(carouselElement.current.scrollLeft)
    } else {
      setScrollPosition(0)
    }
  }

  return (
    <div className="carouselContainer">
      <section className="carouselItems">
        <div
          className={
            !isMobile && displayArrows
              ? 'carouselWrapper'
              : 'carouselWrapper hideArrows'
          }
          id="carousel"
          ref={carouselElement}
          onScroll={onCarouselScroll}
          tabIndex={0}
        >
          <ul className="group">
            <template
              className={
                closeSpacing
                  ? 'items noGap'
                  : !isMobile
                  ? 'items'
                  : 'items mobile'
              }
            >
              {children?.map(
                (child: ReactChild | null | undefined, index: number) => (
                  <li className="itemsFlexActions" key={`item-${index}`}>
                    {child}
                  </li>
                )
              )}
            </template>
          </ul>
        </div>
        {!isMobile && displayArrows && (
          <>
            <IconButton
              aria-label={'back'}
              className={isLeftButtonActive ? 'left' : `left disabled`}
              onClick={() => onScrollLeft()}
            >
              <ArrowBackIosIcon data-testid="backButton" color="primary" />
            </IconButton>
            <IconButton
              aria-label={'forward'}
              className={isRightButtonActive ? 'right' : `right disabled`}
              onClick={() => onScrollRight()}
            >
              <ArrowForwardIosOutlinedIcon
                data-testid="backButton"
                color="primary"
              />
            </IconButton>
          </>
        )}
      </section>
    </div>
  )
}

export default Carousel
