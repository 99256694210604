// eslint-disable-next-line
import { RouteComponentProps } from 'react-router-dom'

export const goBack = (
  history: RouteComponentProps['history'],
  defaultPath?: string
): void => {
  const path = defaultPath || '/curations'

  // Handles case when page is accessed in a new tab without browser history
  history.length > 2 ? history.goBack() : history.push(path)
}
