import { ReactElement } from 'react'

import { Box, Button, Grow, Stack, Typography } from '@mui/material'

import { formatProductPrice } from 'utils/formatPrice'

import {
  HoldsHandleCloseT,
  HoldsProductHandleActionItemT,
  HoldsStartNewHoldFormParamsT
} from 'pages/Holds/types'

import {
  HOLDS_MEDIA_MAX_WIDTH_CREATE_HOLD_AND_EDIT_ITEMS,
  HOLDS_TEXT_EDIT_ITEM,
  LINE_ITEM_LIMIT_REACHED_SNACKBAR_MESSAGE,
  LINE_ITEM_LIMIT_REACHED_SNACKBAR_TITLE
} from 'pages/Holds/constants'

import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import BasePageAlert from 'components/BasePageAlert'

import holdsGetGrowTimeout from 'pages/Holds/shared/utils/holdsGetGrowTimeout'
import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'
import HoldsBoxDivider from 'pages/Holds/shared/components/HoldsBoxDivider'
import HoldsProductItemImage from 'pages/Holds/shared/components/HoldsProductItemImage'

const HoldsDialogBodyEditItems = ({
  value,
  allowAddMoreItems,
  handleClose,
  handleOpenAddItem,
  handleRemoveItem
}: HoldsHandleCloseT & {
  value: HoldsStartNewHoldFormParamsT['itemsOnHold']
  allowAddMoreItems: boolean
  handleOpenAddItem: () => void
  handleRemoveItem: HoldsProductHandleActionItemT
}): ReactElement => (
  <>
    <HoldsDialogAppBar
      title={HOLDS_TEXT_EDIT_ITEM}
      handleClose={handleClose}
      closeIconButtonProps={{
        'data-testid': 'back_arrow_edit_items'
      }}
    />

    <HoldsDialogContent
      sx={{
        maxWidth: HOLDS_MEDIA_MAX_WIDTH_CREATE_HOLD_AND_EDIT_ITEMS
      }}
    >
      <BoxWithGladlySpace>
        <Box
          sx={{
            py: {
              sm: 3
            },
            px: {
              sm: 11
            }
          }}
        >
          {!allowAddMoreItems && (
            <BasePageAlert
              alertTitle={LINE_ITEM_LIMIT_REACHED_SNACKBAR_TITLE}
              errorMessage={LINE_ITEM_LIMIT_REACHED_SNACKBAR_MESSAGE}
            />
          )}

          <Button
            disabled={!allowAddMoreItems}
            fullWidth
            variant="outlined"
            onClick={handleOpenAddItem}
          >
            Add More Items
          </Button>
        </Box>

        <HoldsBoxDivider />

        <Box mt={2}>
          <Stack direction="column" spacing={2} divider={<HoldsBoxDivider />}>
            {value.map((productItem, index) => (
              /**
               * TODO: HOLDS - should return from BE id from DB and paste to key
               */
              <Grow
                key={productItem.idGenerated}
                in
                timeout={holdsGetGrowTimeout({
                  index
                })}
              >
                <Box display="flex" gap={2}>
                  <HoldsProductItemImage
                    productItem={productItem}
                    height={178}
                    width={107}
                  />

                  <Box>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography>
                        <strong>{productItem.name}</strong>
                      </Typography>
                      <Typography>
                        {formatProductPrice(productItem.price)}
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={1} mt={2}>
                      <Typography>{productItem.size}</Typography>
                      <Typography>{productItem.color}</Typography>
                    </Box>

                    <Box mt={2}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleRemoveItem(productItem)
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grow>
            ))}
          </Stack>
        </Box>
      </BoxWithGladlySpace>
    </HoldsDialogContent>
  </>
)

export default HoldsDialogBodyEditItems
