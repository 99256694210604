import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { CURATION_TYPE } from 'types/Curation'

export type CurationSliceT = {
  id?: number | null
  type?: CURATION_TYPE | null
  shoppingSessionId?: string | null
  preferredEmployee?: string | null
  ocpId?: string | null
}

const curationSliceInitialState: CurationSliceT = {
  id: null,
  type: null,
  shoppingSessionId: null,
  preferredEmployee: null,
  ocpId: null
}

export const curationSlice = createSlice({
  name: 'curation',
  initialState: curationSliceInitialState,
  reducers: {
    setCuration: (state, { payload }) => {
      state.id = payload.id
      state.type = payload.type
      state.shoppingSessionId = payload.shoppingSessionId
      state.preferredEmployee = payload.preferredEmployee
      state.ocpId = payload.ocpId
    },

    resetCuration: () => curationSliceInitialState
  }
})

export const { resetCuration, setCuration } = curationSlice.actions
export const curationSelector = (state: RootState): CurationSliceT =>
  state.curation
