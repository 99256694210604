import { ReactElement } from 'react'

import type { TypographyProps } from '@mui/material'
import { Typography } from '@mui/material'

export const TypographySubTitle2 = (props: TypographyProps): ReactElement => (
  <Typography color="text.secondary" {...props} />
)

export const TypographyCaption = (props: TypographyProps): ReactElement => (
  <Typography
    variant="caption"
    fontSize="1.125rem"
    lineHeight="1.333em"
    {...props}
  />
)

export default Typography
