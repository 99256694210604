import './ReactotronConfig'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './/venderScripts/gladly.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { registerServiceWorker } from './utils/service-worker-utils'
import { setNRUserId } from 'utils/newRelicCustomLogHelper'

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser')
  worker.start()
}

registerServiceWorker()
setNRUserId()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
