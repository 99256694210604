import { ReactElement } from 'react'
import Box from '@mui/material/Box'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Typography from '@mui/material/Typography'
import { messageStatusContent } from 'services/twilioSlice/constants'
import { messageStatusView } from 'types/Twilio'

const MessageStatus = ({
  onClick,
  sx,
  view
}: {
  onClick?: () => void
  sx?: Record<string, unknown>
  view: messageStatusView
}): ReactElement => {
  const statusContent: string = messageStatusContent[view]
  return (
    <Box
      onClick={onClick}
      data-testid="notDeliveredStatus"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        ...sx
      }}
    >
      <ErrorOutlineIcon sx={{ color: '#D6243D' }} />
      <Typography variant="caption" sx={{ color: '#D6243D' }}>
        {statusContent}
      </Typography>
    </Box>
  )
}

export default MessageStatus
