import { List, ListItem, ListSubheader, Typography } from '@mui/material'
import CurationCard from 'components/CurationCard'
import { CURATION_STATE, CurationT } from 'types/Curation'

type CurationCardListPropsT = {
  curations: CurationT[]
  listSubHeaders: {
    title: string
    key: string
  }[]
  isDraft?: boolean
  currentPage: number
  displayFinishedBoardsSubHeader: boolean
  handleOpenDeleteDraftDialog?: (dialogOpen: boolean) => void
  handleSetCurationIdToDelete?: (curationId: number) => void
}

const StyleBoardsList = ({
  listSubHeaders,
  curations,
  currentPage,
  displayFinishedBoardsSubHeader,
  handleOpenDeleteDraftDialog,
  handleSetCurationIdToDelete
}: CurationCardListPropsT): JSX.Element => {
  return (
    <List subheader={listSubHeaders?.length ? <li /> : null}>
      <>
        {listSubHeaders.map((filter) => (
          <div key={filter.key}>
            {(currentPage === 1 || displayFinishedBoardsSubHeader) && (
              <ListSubheader
                color={'inherit'}
                disableSticky
                disableGutters
                sx={{ my: 1 }}
              >
                <Typography variant="subtitle2">{filter.title}</Typography>
              </ListSubheader>
            )}
            {curations
              .filter((curation) => curation.state === filter.key)
              .map((curation) => (
                <ListItem disableGutters key={curation.id}>
                  <CurationCard
                    curation={curation}
                    isDraft={curation.state === CURATION_STATE.DRAFT}
                    handleOpenDeleteDraftDialog={handleOpenDeleteDraftDialog}
                    handleSetCurationIdToDelete={handleSetCurationIdToDelete}
                  />
                </ListItem>
              ))}
          </div>
        ))}
      </>
    </List>
  )
}

export default StyleBoardsList
