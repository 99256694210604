import { ReactElement } from 'react'
import { List, ListItem, Typography, Box, Badge } from '@mui/material'
import { ConversationT, ErrorMessages, messageStatusView } from 'types/Twilio'
import {
  hasMessageFailedToBeDelivered,
  isSupportedMessageType
} from 'services/twilioSlice/utils'
import MessageStatus from 'components/MessageStatus'

type PropsT = {
  conversations: Array<ConversationT>
  goToMessages: (sid: string) => void
}

const ConversationList = (props: PropsT): ReactElement => {
  const { conversations, goToMessages } = props

  return (
    <List>
      {conversations.map((conversation) => {
        const { sid, friendlyName, lastMessage } = conversation
        const hasUnreadMessages = conversation.unreadMessagesCount > 0

        const messagePreview = lastMessage?.failedToLoad
          ? ErrorMessages.MESSAGE_PREVIEW_FAILED_TO_LOAD
          : lastMessage?.type === 'media'
          ? lastMessage?.attachedMedia.contentType.startsWith('image')
            ? 'Attachment: 1 image'
            : 'Attachment: 1 video'
          : lastMessage?.body

        const failedToBeDelivered = hasMessageFailedToBeDelivered(lastMessage)

        const getDisplayedMessage = () => {
          if (!isSupportedMessageType(lastMessage)) {
            return (
              <Typography
                variant="body2"
                color="text.secondary"
                fontStyle="italic"
              >
                Message type not supported
              </Typography>
            )
          } else if (failedToBeDelivered) {
            return <MessageStatus view={messageStatusView.CONVERSATION_LIST} />
          } else {
            return (
              <Typography
                variant="caption"
                color={hasUnreadMessages ? 'text.primary' : 'text.secondary'}
                sx={{
                  fontSize: '14px',
                  fontStyle: lastMessage.failedToLoad ? 'italic' : 'normal',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2
                }}
              >
                {messagePreview}
              </Typography>
            )
          }
        }

        return (
          <ListItem
            onClick={() => goToMessages(sid)}
            key={conversation.sid}
            divider
            aria-label="conversationItem"
            sx={{ cursor: 'pointer' }}
          >
            {hasUnreadMessages && (
              <Box sx={{ display: 'flex', left: '4px', position: 'relative' }}>
                <Badge
                  variant="dot"
                  color="primary"
                  data-testid="unreadMessagesBadge"
                />
              </Box>
            )}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '24px',
                width: '100%'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '24px'
                }}
              >
                <Typography variant="subtitle2">{friendlyName}</Typography>
                <Typography
                  variant="overline"
                  color={hasUnreadMessages ? 'text.primary' : 'text.secondary'}
                >
                  {lastMessage.dateCreated}
                </Typography>
              </Box>
              {getDisplayedMessage()}
            </Box>
          </ListItem>
        )
      })}
    </List>
  )
}

export default ConversationList
