import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import { useGetCurationsQuery } from 'services/curationSvc'
import moment from 'moment'
import oktaTokenStorage from 'utils/okta-token-utils'
import { CurationT, CURATION_STATE, CURATION_TYPE } from 'types/Curation'
import { CopyErrorDataT } from 'types/CopyErrorData'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import HitASnagAlert from 'components/HitASnagAlert'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import ContinueRequest from './components/Continue'
import StartRequest from './components/StartNew'
import NoNewCurationsAlert from './components/NoNewCurationsAlert'
import PublishCurationSnackbar from './components/PublishCurationSnackbar'
import PublicBoardLinkCopiedSnackbar from './components/PublicBoardLinkCopiedSnackbar'
import FinishedBoardsPreview from './components/FinishedBoardsPreview'
import OpenSelectBoardTypeButton from './components/OpenSelectStyleBoardTypeButton'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import StylingRequests from './components/StylingRequests'
import CreateStyleBoardDialog from './components/CreateStyleBoardDialog'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import InProgressRequest from './components/InProgressRequest'
import ManageFavoriteItems from './components/ManageFavoriteItems'
import OpenDrafts from './components/OpenDrafts'
import BaseSnackbar from 'components/BaseSnackbar'

type PublishCurationSuccessT = {
  isPublishCurationSuccess?: boolean
  isOutsideQueueRequest?: boolean
  isStyleBoardLinkCopySuccess?: boolean
  curationIdLink?: string
  editCurationSuccessMessage?: string
}

export type RequestedQueueDataT = {
  count: number
  refreshedAt: moment.Moment
}

const OUTSIDE_QUEUE_REQUEST_SUCCESS_MESSAGE =
  'Style board link copied. Send the link to the customer.'

const CurationHome = (): ReactElement => {
  const location = useLocation<PublishCurationSuccessT | undefined>()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const [errorDetails, setErrorDetails] = useState<CopyErrorDataT>()
  const [hasNoRequestsInQueue, setHasNoRequestsInQueue] = useState(false)
  const [requestedQueueData, setRequestedQueueData] =
    useState<RequestedQueueDataT>()
  const [showingErrorAlert, setShowingErrorAlert] = useState(false)
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const { isStylingRequestsEnabled, isFavoriteItemsEnabled } = useFeatureFlags()

  const [isCreateStyleBoardModalOpen, setIsCreateStyleBoardModalOpen] =
    useState(false)

  const {
    data: getInProgressCurationsData,
    endpointName: inProgressCurationsEndpointName,
    error: inProgressCurationsError,
    isError: isGetInProgressCurationsError,
    isFetching: isGetInProgressCurationsFetching,
    isLoading: isGetInProgressCurationsLoading,
    originalArgs: inProgressCurationsOriginalArgs
  } = useGetCurationsQuery({
    state: CURATION_STATE.IN_PROGRESS,
    employeeId: employeeId
  })

  const curationIdLink = location?.state?.curationIdLink

  const {
    data: getRequestedCurationsData,
    isError: isGetRequestedCurationsError,
    isFetching: isGetRequestedCurationsFetching,
    isLoading: isGetRequestedCurationsLoading
  } = useGetCurationsQuery({
    state: CURATION_STATE.REQUESTED
  })

  const [inProgressCuration]: [CurationT] | [] =
    getInProgressCurationsData?.curations || []

  useEffect(() => {
    if (getRequestedCurationsData) {
      setRequestedQueueData({
        count: getRequestedCurationsData.meta?.count,
        refreshedAt: moment()
      })
    }
  }, [getRequestedCurationsData])

  const handleStartCurationError = (errorDetails: CopyErrorDataT) => {
    setShowingErrorAlert(true)
    errorDetails &&
      setErrorDetails({
        ...errorDetails,
        identifiers: {
          customerId:
            getInProgressCurationsData?.curations[0]?.ocpId || 'NO-DATA',
          curationId: getInProgressCurationsData?.curations[0]?.id || 'NO-DATA',
          employeeId
        }
      })
  }

  if (
    (isGetInProgressCurationsLoading ||
      isGetInProgressCurationsFetching ||
      isGetRequestedCurationsLoading ||
      isGetRequestedCurationsFetching) &&
    !isStylingRequestsEnabled?.active
  ) {
    return <LoadingSpinner />
  }

  if (isGetInProgressCurationsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: inProgressCurationsEndpointName,
          errorData: inProgressCurationsError,
          identifiers: {
            customerId:
              getInProgressCurationsData?.curations[0]?.ocpId || 'NO-DATA',
            curationId:
              getInProgressCurationsData?.curations[0]?.id || 'NO-DATA',
            employeeId
          },
          originalArgs: inProgressCurationsOriginalArgs
        }}
      />
    )
  }

  return (
    <BoxWithGladlySpace>
      <>
        <Container disableGutters>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            px={[2, 3]}
            py={2}
          >
            <Box pb={2} width={[1, 1 / 4]}>
              <Typography variant="h6">Style boards</Typography>
            </Box>
            <Box width={[1, 1 / 4]}>
              <CreateStyleBoardDialog
                open={isCreateStyleBoardModalOpen}
                onClose={() => {
                  setIsCreateStyleBoardModalOpen(false)
                }}
                sourcePage="CurationHome"
              />
              <OpenSelectBoardTypeButton
                openModal={() => setIsCreateStyleBoardModalOpen(true)}
              />
            </Box>
          </Box>
          <Container>
            <Box width={'100%'}>
              {hasNoRequestsInQueue && <NoNewCurationsAlert />}
              {showingErrorAlert && (
                <HitASnagAlert
                  errorDetails={errorDetails}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                />
              )}
            </Box>
          </Container>
          {/*the below container should stay when feature flag is cleaned up, just remove the flag check */}
          {!!inProgressCuration && isStylingRequestsEnabled?.active && (
            <Container>
              <InProgressRequest
                isPublicBoard={
                  !inProgressCuration?.ocpId &&
                  inProgressCuration.type === CURATION_TYPE.SELLER_INITIATED
                }
              />
            </Container>
          )}
          <Container>
            {!isStylingRequestsEnabled?.active ? (
              inProgressCuration ? (
                <ContinueRequest
                  requestedQueueData={requestedQueueData}
                  requestedQueueError={isGetRequestedCurationsError}
                />
              ) : (
                <StartRequest
                  handleNoRequestsInQueue={() => setHasNoRequestsInQueue(true)}
                  handleStartCurationError={handleStartCurationError}
                  requestedQueueData={requestedQueueData}
                  requestedQueueError={isGetRequestedCurationsError}
                />
              )
            ) : (
              <StylingRequests />
            )}
          </Container>
        </Container>
        <>
          <Container>
            <OpenDrafts />
          </Container>
        </>

        {isFavoriteItemsEnabled?.active && (
          <>
            <Container>
              <ManageFavoriteItems />
            </Container>
          </>
        )}
      </>

      <Container>
        <FinishedBoardsPreview employeeId={employeeId} />
      </Container>
      <Container>
        {!showingErrorAlert && location?.state?.isPublishCurationSuccess && (
          <PublishCurationSnackbar />
        )}

        {!showingErrorAlert && location?.state?.editCurationSuccessMessage && (
          <PublishCurationSnackbar
            message={location.state.editCurationSuccessMessage}
          />
        )}

        {showingErrorAlert && isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
      </Container>
      <Container>
        {!showingErrorAlert &&
          location?.state?.isStyleBoardLinkCopySuccess &&
          curationIdLink && (
            <PublicBoardLinkCopiedSnackbar curationIdLink={curationIdLink} />
          )}
      </Container>
      <Container>
        {!showingErrorAlert &&
          location?.state?.isStyleBoardLinkCopySuccess &&
          location?.state?.isOutsideQueueRequest && (
            <BaseSnackbar message={OUTSIDE_QUEUE_REQUEST_SUCCESS_MESSAGE} />
          )}
      </Container>
    </BoxWithGladlySpace>
  )
}

export default CurationHome
