import { ReactElement, memo } from 'react'
import FiltersAccordion from '../FiltersAccordion'
import { FilterOptionT } from 'types/FashionMapSearch'

const PRODUCT_TYPE_FILTERS_TITLE = 'Product type'
const EMPTY_MESSAGE = 'There are no product types to show for this search'

interface PropsT {
  filters: FilterOptionT[]
  onSelectFilter: ({
    currentFilter,
    isSelected
  }: {
    currentFilter: string
    isSelected: boolean
  }) => void
}

const ProductTypeFilters = (props: PropsT): ReactElement => {
  const { filters, onSelectFilter } = props

  return (
    <FiltersAccordion
      filters={filters}
      noFiltersMessage={EMPTY_MESSAGE}
      onSelect={(e) => {
        onSelectFilter({
          currentFilter: e.target.name,
          isSelected: e.target.checked
        })
      }}
      showSearchBox={true}
      summaryTitle={PRODUCT_TYPE_FILTERS_TITLE}
    />
  )
}

export default memo(ProductTypeFilters)
