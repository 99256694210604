import { ReactElement } from 'react'
import { Box, CardMedia } from '@mui/material'

const HoldsProductItemImage = ({
  productItem,
  height = 100,
  width = 65
}: {
  productItem: {
    imageUrl: string
    name: string
  }
  height?: number
  width?: number
}): ReactElement => (
  <Box
    display="flex"
    justifyContent="center"
    height={height}
    minWidth={width}
    maxWidth={width}
  >
    <CardMedia
      component="img"
      image={productItem.imageUrl}
      alt={productItem.name}
      sx={{
        objectFit: 'contain'
      }}
    />
  </Box>
)

export default HoldsProductItemImage
