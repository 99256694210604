import { ReactElement, forwardRef, Ref } from 'react'

import type { DialogProps, SlideProps } from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'

import { Dialog, Slide } from '@mui/material'

import useHoldsMediaDesktop from 'pages/Holds/hooks/useHoldsMediaDesktop'

const DefaultTransitionSlideUp = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

DefaultTransitionSlideUp.displayName = 'Transition slide up wrapper'

const HoldsDialog = ({
  children,
  isDrawerMode,
  TransitionProps,
  sx = {},
  ...dialogProps
}: { isDrawerMode?: boolean; TransitionProps?: Partial<SlideProps> } & Omit<
  DialogProps,
  'TransitionProps'
>): ReactElement => {
  const { isDesktop } = useHoldsMediaDesktop()

  const allowDrawerMode = isDesktop && isDrawerMode

  return (
    <Dialog
      fullScreen
      TransitionComponent={DefaultTransitionSlideUp}
      sx={
        allowDrawerMode
          ? {
              '.MuiDialog-container': {
                position: 'fixed',
                top: 0,
                outline: 0,
                right: 0
              }
            }
          : sx
      }
      TransitionProps={
        !TransitionProps
          ? ({
              direction: allowDrawerMode ? 'left' : 'up'
            } as SlideProps)
          : TransitionProps
      }
      {...dialogProps}
    >
      {children}
    </Dialog>
  )
}

export default HoldsDialog
