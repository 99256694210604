import { ReactElement } from 'react'
import { Typography, Container } from '@mui/material'
import useMaxDraftsAgeInDays from 'hooks/useMaxDraftsAgeInDays'

const DraftsBanner = (): ReactElement => {
  const maxAgeInDays = useMaxDraftsAgeInDays()

  return (
    <Container
      sx={{
        bgcolor: 'grey.50',
        py: 2,
        display: 'flex'
      }}
    >
      <Typography variant="caption" color="secondary" fontWeight="390">
        {`Boards that have been in draft for more than ${maxAgeInDays} days will be automatically deleted.`}
      </Typography>
    </Container>
  )
}

export default DraftsBanner
