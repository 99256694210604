import { ReactElement, useEffect, useRef, useState } from 'react'
import { Collapse, Chip, Stack, Box, Button } from '@mui/material'
import useWindowDimensions from 'utils/useWindowDimensions'

const COLLAPSED_SIZE = 120

type PropsT = {
  data: string[]
}

const Chips = (props: PropsT): ReactElement => {
  const { data } = props

  const [showingAll, setShowingAll] = useState(false)
  const [height, setHeight] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowDimensions()

  const showMore = height && height > COLLAPSED_SIZE
  const buttonText = showingAll ? 'Show Less' : 'Show More'
  const STACK_MARGIN_BOTTOM = 12

  useEffect(() => {
    if (data && ref.current) {
      setHeight(ref.current.clientHeight)
    }
  }, [setHeight, data, height, windowWidth])

  const handleClick = () => {
    setShowingAll((showingAll) => !showingAll)
  }

  return (
    <>
      <Collapse
        in={showingAll}
        collapsedSize={
          COLLAPSED_SIZE > height
            ? height + STACK_MARGIN_BOTTOM
            : COLLAPSED_SIZE + STACK_MARGIN_BOTTOM
        }
      >
        <Stack
          direction="row"
          sx={{ flexWrap: 'wrap', mt: `${STACK_MARGIN_BOTTOM}px` }}
          ref={ref}
        >
          {data &&
            data.map((item) => (
              <Box key={item} mb={1} mr={1}>
                <Chip label={item} sx={{ maxWidth: '250px' }} />
              </Box>
            ))}
        </Stack>
      </Collapse>
      {showMore && (
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleClick} color="primary" variant="text">
            {buttonText}
          </Button>
        </Box>
      )}
    </>
  )
}

export default Chips
