import { ReactElement } from 'react'
import { FormControlLabel, Switch, Typography, Box } from '@mui/material'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_ENABLE_ANNIVERSARY_FILTER
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

const AnniversaryToggle = ({
  setIsAnniversaryToggleSelected,
  isAnniversaryToggleSelected,
  customerId,
  isInCustomerDetails
}: {
  setIsAnniversaryToggleSelected: (arg: boolean) => void
  isAnniversaryToggleSelected: boolean
  customerId: string
  isInCustomerDetails: boolean
}): ReactElement => {
  const handleToggle = () => {
    if (isAnniversaryToggleSelected) {
      setIsAnniversaryToggleSelected(false)
    } else {
      isInCustomerDetails &&
        generateNewRelicLogs(
          NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_ENABLE_ANNIVERSARY_FILTER,
          {
            page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS,
            ocpId: customerId
          }
        )
      setIsAnniversaryToggleSelected(true)
    }
  }

  return (
    <Box sx={{ marginLeft: '16px' }}>
      <FormControlLabel
        control={
          <Switch
            checked={isAnniversaryToggleSelected}
            name="isAnniversary"
            onChange={handleToggle}
            sx={{ background: 'white' }}
          />
        }
        style={{ background: '#fbe446' }}
        label={
          <Typography fontWeight={'bold'} ml={'2px'} mr={'2px'}>
            Anniversary
          </Typography>
        }
      />
    </Box>
  )
}

export default AnniversaryToggle
