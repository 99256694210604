import { ReactElement } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export type PropsT = {
  title?: string
  onClick: () => void
}

const RecentItemsNavigation = (props: PropsT): ReactElement => {
  const { title, onClick } = props

  return (
    <Stack direction="row" alignContent="center" spacing={2} my={1}>
      <Box width="50px" display="flex" alignItems="center">
        <IconButton aria-label="close" onClick={onClick}>
          <CloseIcon sx={{ color: 'common.black' }} />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography fontWeight="bold" variant="h6">
          {title}
        </Typography>
      </Box>
    </Stack>
  )
}

export default RecentItemsNavigation
