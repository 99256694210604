import { LoadingButton } from '@mui/lab'
import { Box, Typography, useTheme } from '@mui/material'

interface PropsT {
  isGenerateTitleDescriptionError: boolean
  isLoading: boolean
  onClick: () => void
}

const GenerateTitleAndDescription = ({
  isGenerateTitleDescriptionError,
  isLoading,
  onClick
}: PropsT): JSX.Element => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="100%"
      mt={isGenerateTitleDescriptionError ? theme.spacing(2) : theme.spacing(4)}
      p={2}
      sx={{ backgroundColor: theme.palette.grey[50] }}
    >
      <Typography variant="subtitle2">
        Generate a title & description
      </Typography>
      <Typography variant="caption" color="text.secondary">
        Please ensure there is no sensitive personal information in generated
        text.
      </Typography>
      {isGenerateTitleDescriptionError && (
        <Typography color="error" variant="body2" mt={1}>
          We had trouble generating a title and description. Please try again.
        </Typography>
      )}
      <LoadingButton
        loading={isLoading}
        loadingPosition="center"
        variant="outlined"
        onClick={onClick}
        sx={{
          mt: 1,
          maxWidth: 96
        }}
      >
        Generate
      </LoadingButton>
    </Box>
  )
}

export default GenerateTitleAndDescription
