import { Box, Typography, useTheme } from '@mui/material'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'

interface PropsT {
  isCameraPermissionDenied?: boolean
}
const CameraNotAvailable = (props: PropsT): JSX.Element => {
  const theme = useTheme()
  const { isCameraPermissionDenied } = props

  const cameraUnavailableTitle = 'Camera not available'
  const cameraUnavailableMessage =
    'Try manually entering the UPC, or use a mobile device in order to scan.'
  const cameraPermissionDeniedTitle = 'Access to camera was denied'
  const cameraPermissionDeniedMessage =
    'In order to scan barcodes, you must allow Personal Book to access your camera.'

  return (
    <Box
      flexGrow={1}
      sx={{
        backgroundColor: theme.palette.grey[700],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        flexGrow={1}
        sx={{ maxWidth: 250 }}
      >
        <NoPhotographyIcon
          htmlColor={theme.palette.primary.contrastText}
          sx={{ alignSelf: 'center', mb: 1 }}
        />
        <Typography variant="subtitle2" color={'primary.contrastText'} mb={0.5}>
          {isCameraPermissionDenied
            ? cameraPermissionDeniedTitle
            : cameraUnavailableTitle}
        </Typography>
        <Typography variant="caption" color={'primary.contrastText'}>
          {isCameraPermissionDenied
            ? cameraPermissionDeniedMessage
            : cameraUnavailableMessage}
        </Typography>
      </Box>
    </Box>
  )
}

export default CameraNotAvailable
