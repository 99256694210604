import { ReactElement, useState } from 'react'

import { HoldT } from 'types/Holds'

import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import { useHoldsRefState, useHoldsToggle } from 'pages/Holds/hooks'

import { useGetHoldsByTicketNumberMutation } from 'services/holdsApi'

import { getResponseErrorMessageOrErrorMessage } from 'pages/Holds/shared/utils/holdsErrorUtils'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsButtonScanItem from 'pages/Holds/shared/components/HoldsButtonScanItem'
import HoldsProductItemCardDetails from 'pages/Holds/shared/components/HoldsProductItemCardDetails'

const HoldsFindByTicketNumber = (): ReactElement => {
  const [hold, setHold] = useState<HoldT | undefined>()

  const { handleNotificationAddErrorMessage } = useHoldsContextNotification()

  const [fetchGetHoldByTicketNumber, { isLoading }] =
    useGetHoldsByTicketNumberMutation()

  const {
    isOpen: isOpenHoldDetails,
    handleOpen: handleOpenHoldDetails,
    handleClose: handleCloseHoldDetails
  } = useHoldsToggle()

  const refNotAllowFetchData = useHoldsRefState(isLoading || isOpenHoldDetails)

  const handleSubmit = async (ticketNumber: string) => {
    if (refNotAllowFetchData.current) {
      return
    }

    try {
      const response = await fetchGetHoldByTicketNumber({
        ticketNumber
      }).unwrap()

      if (response.ticketNumber) {
        setHold(response)
        handleOpenHoldDetails()
      } else {
        handleNotificationAddErrorMessage({
          title: 'Hold not found.'
        })
      }
    } catch (e) {
      handleNotificationAddErrorMessage({
        title:
          getResponseErrorMessageOrErrorMessage(e) ?? 'Response error for Hold.'
      })
    }
  }

  return (
    <>
      <HoldsButtonScanItem handleSubmit={handleSubmit} />

      <HoldsDialog
        data-testid="find_ticket_number_results_dialog"
        open={isOpenHoldDetails}
        onClose={handleCloseHoldDetails}
      >
        <HoldsProductItemCardDetails
          hold={hold as HoldT}
          isOpen={isOpenHoldDetails}
          handleUpdateDetails={setHold}
          handleClose={handleCloseHoldDetails}
        />
      </HoldsDialog>
    </>
  )
}

export default HoldsFindByTicketNumber
