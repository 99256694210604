import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { whenStyleBoardSent } from 'utils/track'

type PropsT = {
  handlePublish: () => void
  isLoading: boolean
  shouldShowSendButtonText: boolean
  didUserClickFinish: boolean
}

const DEFAULT_BUTTON_TEXT = 'Finish'
const EMAIL_TO_CUSTOMER_BUTTON_TEXT = 'Send Style Board'

const PublishButton = (props: PropsT): ReactElement => {
  const {
    handlePublish,
    isLoading,
    shouldShowSendButtonText,
    didUserClickFinish
  } = props

  const styleBoardSent = () => {
    whenStyleBoardSent()
    handlePublish()
  }

  return (
    <Grid item>
      <LoadingButton
        sx={{
          textTransform: 'none',
          minWidth: '20em'
        }}
        onPointerUp={styleBoardSent}
        loading={isLoading}
        loadingPosition="center"
        variant="contained"
        disabled={didUserClickFinish}
      >
        {shouldShowSendButtonText
          ? EMAIL_TO_CUSTOMER_BUTTON_TEXT
          : DEFAULT_BUTTON_TEXT}
      </LoadingButton>
    </Grid>
  )
}

export default PublishButton
