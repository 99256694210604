export const CONSENT_LINK_COPIED_TO_CLIPBOARD = 'Invitation link copied'

export const CONSENT_INVITE_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS'
}

export const CONSENT_SEARCH_BY = {
  EMAIL: 'EMAIL',
  MOBILE: 'MOBILE'
}

export const CUSTOMER_INFORMATION_STATUS: Record<
  string,
  CustomerInformationStatusT
> = {
  MOBILE_FOUND: 'MobileFound',
  ALREADY_IN_BOOK: 'AlreadyInBook',
  INVITATION_PENDING: 'InvitationPending',
  DECLINED: 'Declined',
  EMAIL_FOUND_NO_MOBILE: 'EmailFoundNoMobile'
}

export type CustomerInformationStatusT =
  | 'MobileFound'
  | 'AlreadyInBook'
  | 'InvitationPending'
  | 'Declined'
  | 'EmailFoundNoMobile'
