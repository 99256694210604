import { PriceTypes } from 'types/Product'

export const determineCurrentPrice = ({
  clearancePrice,
  priceType,
  promotionPrice,
  regularPrice
}: {
  clearancePrice?: number
  priceType?: PriceTypes
  promotionPrice?: number
  regularPrice?: number
}): number | undefined => {
  const currentPrice =
    priceType === PriceTypes.CLEARANCE
      ? clearancePrice
      : priceType === PriceTypes.PROMOTION
      ? promotionPrice
      : regularPrice
  return currentPrice
}
