import { ReactElement, useMemo, useState } from 'react'

import { produce } from 'immer'
import isEmpty from 'lodash/isEmpty'

import {
  Box,
  Button,
  DialogActions,
  Grow,
  Stack,
  Typography
} from '@mui/material'

import { HoldT } from 'types/Holds'
import { HoldEnumStatusType, HoldsHandleCloseT } from 'pages/Holds/types'

import { HOLDS_TEXT_UPDATE_HOLD_STATUS } from 'pages/Holds/constants'

import holdsGetGrowTimeout from 'pages/Holds/shared/utils/holdsGetGrowTimeout'
import HoldsBoxDivider from 'pages/Holds/shared/components/HoldsBoxDivider'
import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'
import HoldsProductItemImage from 'pages/Holds/shared/components/HoldsProductItemImage'

import HoldsStatusRadioGroup from './components/HoldsStatusRadioGroup'

const HoldsDialogBodyUpdateHoldStatus = ({
  hold,
  notAllowActions,
  handleSubmit,
  handleClose
}: {
  hold: HoldT
  notAllowActions: boolean
  handleSubmit: (lineItemsStatuses: Record<string, string>) => void
} & HoldsHandleCloseT): ReactElement => {
  const [lineItemsStatuses, setLineItemsStatuses] = useState<
    Record<string, `${HoldEnumStatusType}`>
  >(() =>
    Object.fromEntries(
      new Map(hold.lineItems.map(({ id, status }) => [id, status])).entries()
    )
  )

  const {
    allowChangeStatusOnlyForOnHoldLineItems,
    sortItemIndexListByOrderedStatus
  } = useMemo(() => {
    const allowChangeStatusOnlyForOnHoldLineItems: Record<number, boolean> = {}

    const sortItemsOnHoldByIndex: number[] = []
    const sortRestItemsByIndex: number[] = []

    hold.lineItems.forEach((lineItem, index) => {
      if (lineItem.status === HoldEnumStatusType.ON_HOLD) {
        allowChangeStatusOnlyForOnHoldLineItems[lineItem.id] = true
        sortItemsOnHoldByIndex.push(index)
      } else {
        sortRestItemsByIndex.push(index)
      }
    })

    const sortItemIndexListByOrderedStatus =
      sortItemsOnHoldByIndex.concat(sortRestItemsByIndex)

    return {
      allowChangeStatusOnlyForOnHoldLineItems,
      sortItemIndexListByOrderedStatus
    }
  }, [hold.lineItems])

  return (
    <>
      <HoldsDialogAppBar
        title={HOLDS_TEXT_UPDATE_HOLD_STATUS}
        handleClose={handleClose}
        closeIconButtonProps={{
          'data-testid': 'back_arrow_update_hold_status'
        }}
      />

      <HoldsDialogContent>
        <Box
          sx={{
            display: 'flex',
            gap: {
              sm: 5,
              xs: 2
            },
            flexFlow: {
              sm: 'row',
              xs: 'column'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              flex: 1.56,
              gap: 2
            }}
          >
            <Typography>
              Let us know what happened with the items that were part of this
              hold:
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              flex: 1,
              gap: 2
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              disabled={notAllowActions}
              onClick={() => {
                if (notAllowActions) {
                  return
                }

                setLineItemsStatuses(
                  produce((draft) => {
                    for (const lineItemKey of Object.keys(
                      allowChangeStatusOnlyForOnHoldLineItems
                    )) {
                      draft[lineItemKey] = HoldEnumStatusType.PURCHASED
                    }
                  })
                )
              }}
            >
              Mark All as Purchased
            </Button>
            <Button
              fullWidth
              variant="outlined"
              disabled={notAllowActions}
              onClick={() => {
                if (notAllowActions) {
                  return
                }

                setLineItemsStatuses(
                  produce((draft) => {
                    for (const lineItemKey of Object.keys(
                      allowChangeStatusOnlyForOnHoldLineItems
                    )) {
                      draft[lineItemKey] = HoldEnumStatusType.CANCELED
                    }
                  })
                )
              }}
            >
              Mark All as Canceled
            </Button>
          </Box>
        </Box>

        <HoldsBoxDivider />

        <Box mt={3}>
          <Stack direction="column" spacing={2} divider={<HoldsBoxDivider />}>
            {sortItemIndexListByOrderedStatus.map((productItemIndex, index) => {
              const productItem = hold.lineItems[productItemIndex]

              return (
                <Grow
                  key={productItemIndex}
                  in
                  timeout={holdsGetGrowTimeout({
                    index
                  })}
                >
                  <Box display="flex" gap={2}>
                    <HoldsProductItemImage
                      productItem={{
                        name: productItem.productName,
                        imageUrl: productItem.imageUrl
                      }}
                      height={178}
                      width={107}
                    />

                    <Box>
                      <Typography>
                        <strong>{productItem.productName}</strong>
                      </Typography>

                      <Box mt={2} ml={1}>
                        <HoldsStatusRadioGroup
                          disabled={
                            !allowChangeStatusOnlyForOnHoldLineItems[
                              productItem.id
                            ]
                          }
                          value={lineItemsStatuses[productItem.id]}
                          onChange={(_, status) => {
                            if (notAllowActions) {
                              return
                            }

                            setLineItemsStatuses(
                              produce((draft) => {
                                draft[productItem.id] = status
                              })
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grow>
              )
            })}
          </Stack>
        </Box>
      </HoldsDialogContent>

      <DialogActions
        sx={{
          width: '100%',
          maxWidth: 526,
          margin: '0 auto',
          flexWrap: 'wrap',
          gap: 2,
          pt: 1.25,
          pb: 4,
          '&>:not(style)~:not(style)': {
            ml: 0
          }
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            const allowedLineItemsStatusesForUpdating: Record<string, string> =
              {}

            for (const lineItemKey of Object.keys(
              allowChangeStatusOnlyForOnHoldLineItems
            )) {
              const status = lineItemsStatuses[lineItemKey]

              if (status !== HoldEnumStatusType.ON_HOLD) {
                allowedLineItemsStatusesForUpdating[lineItemKey] = status
              }
            }

            if (isEmpty(allowedLineItemsStatusesForUpdating)) {
              handleClose()

              return
            }

            handleSubmit(allowedLineItemsStatusesForUpdating)
          }}
          disabled={notAllowActions}
        >
          Save
        </Button>

        <Button
          fullWidth
          variant="outlined"
          onClick={handleClose}
          disabled={notAllowActions}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default HoldsDialogBodyUpdateHoldStatus
