import { ReactNode } from 'react'
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Typography,
  useTheme
} from '@mui/material'
import AssistantIcon from '@mui/icons-material/Assistant'
import {
  useGenerateCustomerDossierMutation,
  useGetCustomerQuery,
  useValidateCustomerDossierMutation
} from 'services/employeeExperienceApi'
import SummaryFeedback from './components/CustomerSummaryFeedback'
import { CustomerDossierT } from 'types/CustomerDossier'
import { CustomerDossierStatusT, generateCustomerSummaryLog } from './helpers'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import {
  DOSSIER_STORAGE_KEY,
  getItemFromLocalStorage,
  saveCustomerDossierToLocalStorage
} from 'utils/customerDossier'
import PageError from 'components/PageError'
import oktaTokenStorage from 'utils/okta-token-utils'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'

const NOT_ENOUGH_ORDERS_ERROR =
  'Customer does not have enough orders in their purchase history'

const SummaryTextBlock = ({ content }: { content: CustomerDossierT }) => {
  return (
    <Box mb={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        Style overview
      </Typography>
      {content?.styleKeys && (
        <Typography variant="body2">{content.styleKeys}</Typography>
      )}
      {content?.overview && (
        <Typography variant="body2" display="block" mb={1}>
          {content.overview}
        </Typography>
      )}
      <Typography variant="subtitle2" fontWeight="bold">
        Color palette
      </Typography>
      {content?.colorPalette && (
        <Typography variant="body2" mb={1}>
          {content.colorPalette}
        </Typography>
      )}
      <Typography variant="subtitle2" fontWeight="bold">
        Product summary
      </Typography>
      {content?.productSummary && (
        <Typography variant="body2">{content.productSummary}</Typography>
      )}
    </Box>
  )
}

const SummaryBoxWithIcon = ({ children }: { children: ReactNode }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="row">
        <AssistantIcon sx={{ mr: 1 }} />
        {children}
      </Box>
    </Box>
  )
}

const GenerateAISummaryLoading = (): JSX.Element => {
  return (
    <Box textAlign="center">
      <LinearProgress sx={{ mb: 1 }} />
      <Typography variant="caption" color="text.secondary">
        Generating... this may take a second
      </Typography>
    </Box>
  )
}

const GenerateAISummary = ({
  isLoading,
  onClick,
  enoughRecentOrdersForDossier,
  isNotEnoughOrdersError,
  error
}: {
  isLoading: boolean
  onClick: () => void
  enoughRecentOrdersForDossier: boolean
  isNotEnoughOrdersError: (
    error?: FetchBaseQueryError | SerializedError
  ) => boolean
  error?: FetchBaseQueryError | SerializedError
}): JSX.Element => {
  const isShowingButton = ({
    isLoading,
    enoughRecentOrdersForDossier,
    error
  }: {
    isLoading: boolean
    enoughRecentOrdersForDossier: boolean
    error?: FetchBaseQueryError | SerializedError
  }) =>
    !isLoading && enoughRecentOrdersForDossier && !isNotEnoughOrdersError(error)

  const isShowingNotEnoughPurchasesMessage = ({
    isLoading,
    enoughRecentOrdersForDossier,
    isNotEnoughOrdersError
  }: {
    isLoading: boolean
    enoughRecentOrdersForDossier: boolean
    isNotEnoughOrdersError: boolean
  }) => !isLoading && (!enoughRecentOrdersForDossier || isNotEnoughOrdersError)

  return (
    <SummaryBoxWithIcon>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" fontWeight="bold">
          Generate customer summary
        </Typography>
        <Typography variant="body2">
          Get a summary of this customer based on their purchases and other
          information.
        </Typography>
        <Box mt={2}>
          {isLoading && <GenerateAISummaryLoading />}
          {isShowingButton({
            isLoading,
            enoughRecentOrdersForDossier,
            error
          }) && (
            <Button onClick={onClick} variant="outlined" sx={{ maxWidth: 80 }}>
              Generate
            </Button>
          )}
          {isShowingNotEnoughPurchasesMessage({
            isLoading,
            enoughRecentOrdersForDossier,
            isNotEnoughOrdersError: isNotEnoughOrdersError(error)
          }) && (
            <Alert
              severity="info"
              sx={{
                bgcolor: '#F9FAFA',
                pl: 0,
                pr: 1,
                pt: 0,
                '& .MuiAlert-icon': { mr: 1 },
                '& .MuiAlert-message': { color: 'info.main' }
              }}
            >
              Not enough recent purchase info to generate summary for this
              customer
            </Alert>
          )}
        </Box>
      </Box>
    </SummaryBoxWithIcon>
  )
}

const CustomerSummaryBlock = ({
  customerName,
  data,
  curationId
}: {
  customerName?: string
  data: CustomerDossierT
  curationId: number
}): JSX.Element => {
  const curationSlice = useAppSelector(curationSelector)

  return (
    <SummaryBoxWithIcon>
      <Box display="flex" flexDirection="column">
        <Typography mb={2} variant="subtitle2" fontWeight="bold">
          {customerName
            ? `Generated summary of ${customerName}`
            : 'Generated summary'}
        </Typography>
        {data && <SummaryTextBlock content={data} />}
        <SummaryFeedback
          curationSlice={curationSlice}
          curationId={curationId}
        />
        <Typography variant="caption" display="block" color="text.secondary">
          Summary is generated by AI from customer purchases. For seller use
          only.
        </Typography>
      </Box>
    </SummaryBoxWithIcon>
  )
}

interface PropsT {
  curationId: number
  customerId: string
  enoughRecentOrdersForDossier: boolean
}

const CustomerDossier = ({
  curationId,
  customerId,
  enoughRecentOrdersForDossier
}: PropsT): JSX.Element => {
  const theme = useTheme()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const { data: customerData } = useGetCustomerQuery(customerId, {
    skip: !customerId
  })

  const generateDossierErrorTitle = 'Issue generating summary - try again'
  const [
    generateDossier,
    {
      data,
      isLoading: isGenerateDossierLoading,
      isError: isGenerateDossierError,
      endpointName,
      error
    }
  ] = useGenerateCustomerDossierMutation()

  const customerSummary =
    getItemFromLocalStorage(curationId, DOSSIER_STORAGE_KEY) || data || null

  const [
    validateDossier,
    // eslint-disable-next-line
    {}
  ] = useValidateCustomerDossierMutation()

  const onGenerateSummaryClick = () => {
    generateDossier({
      customerId,
      shopperId: customerData?.shopperId,
      curationId
    })
      .unwrap()
      .then((customerDossier) => {
        saveCustomerDossierToLocalStorage({
          curationId,
          data: customerDossier,
          storageKey: DOSSIER_STORAGE_KEY
        })
        if (curationId) {
          generateCustomerSummaryLog({
            curationId,
            customerId,
            status: CustomerDossierStatusT.SUCCESS
          })
        }
        // validate about 1 of every 20 dossiers generated
        if (curationId && curationId % 20 === 0) {
          validateDossier({
            customerId,
            shopperId: customerData?.shopperId,
            curationId,
            customerDossier
          })
        }
        return
      })
      .catch(() => {
        generateCustomerSummaryLog({
          curationId,
          customerId,
          status: CustomerDossierStatusT.ERROR
        })
      })
  }

  const isNotEnoughOrdersError = (
    error?: FetchBaseQueryError | SerializedError
  ) => {
    // https://redux-toolkit.js.org/rtk-query/usage-with-typescript#error-result-example
    if (error) {
      if ('status' in error) {
        const errorData = error.data as { error: string }
        return errorData?.error === NOT_ENOUGH_ORDERS_ERROR
      }
    }

    return false
  }

  return (
    <Box p={3} sx={{ backgroundColor: theme.palette.grey[50] }} mt={2} mb={2}>
      {customerSummary && (
        <CustomerSummaryBlock
          customerName={customerData?.firstName}
          data={customerSummary}
          curationId={curationId}
        />
      )}
      {!customerSummary && (
        <GenerateAISummary
          isLoading={isGenerateDossierLoading}
          onClick={onGenerateSummaryClick}
          enoughRecentOrdersForDossier={enoughRecentOrdersForDossier}
          isNotEnoughOrdersError={isNotEnoughOrdersError}
          error={error}
        />
      )}
      {isGenerateDossierError && !isNotEnoughOrdersError(error) && (
        <PageError
          customBoxStyle={{ backgroundColor: theme.palette.grey[50] }}
          customAlertStyle={{
            backgroundColor: theme.palette.grey[50],
            color: 'error.main',
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          isInlineAlert={true}
          errorTitle={generateDossierErrorTitle}
          shouldShowRefresh={false}
          errorDetails={{
            endpoint: endpointName,
            errorData: error,
            identifiers: {
              customerId: customerId,
              curationId: curationId ? curationId : 'NO-DATA',
              employeeId
            }
          }}
        />
      )}
    </Box>
  )
}

export default CustomerDossier
