import { DcapiFashionPillarT } from 'types/FashionPillar'

export const findPillar: (
  pillarList: DcapiFashionPillarT[] | undefined,
  pillarId: string | undefined
) => DcapiFashionPillarT | undefined = (
  pillarList: DcapiFashionPillarT[] | undefined,
  pillarId: string | undefined
) => {
  return pillarList?.find((item: DcapiFashionPillarT) => {
    return item.pas_id == pillarId
  })
}
