import { ReactElement, useEffect } from 'react'
import { Prompt } from 'react-router-dom'

import { Box, Button, CircularProgress, Fade, Typography } from '@mui/material'

import { HoldT } from 'types/Holds'

import isHoldStatusIncludesInRequestedStatuses from 'pages/Holds/utils/isHoldStatusIncludesInRequestedStatuses'

import { useFetchGetHoldsByEmployeeId, useHoldsToggle } from 'pages/Holds/hooks'

import { useHoldsContextFetchTrigger } from 'pages/Holds/contexts/HoldsContextFetchTrigger'

import PageError from 'components/PageError'

import {
  HOLDS_API_PARAMS_GET_ACTIVE_LIST,
  HOLDS_API_PARAMS_GET_COMPLETED_LIST
} from 'pages/Holds/constants'

import { TypographySubTitle2 } from 'pages/Holds/shared/components/Typography'
import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsProductItemCards from 'pages/Holds/shared/components/HoldsProductItemCards'

import HoldsDialogBody from './components/HoldsDialogBody'

const HoldsButtonProductList = ({
  title,
  requestParams,
  displayLimit
}:
  | {
      title: 'Active'
      requestParams: typeof HOLDS_API_PARAMS_GET_ACTIVE_LIST
      displayLimit: 3
    }
  | {
      title: 'Inactive'
      requestParams: typeof HOLDS_API_PARAMS_GET_COMPLETED_LIST
      displayLimit: 0
    }): ReactElement => {
  const { fetchTrigger, handleRefetchTrigger } = useHoldsContextFetchTrigger()

  const {
    holds,
    setHolds,
    isLoading,
    isError,
    endpointName,
    error,
    fetchGetHoldsByEmployeeIdCallback
  } = useFetchGetHoldsByEmployeeId({
    requestParams,
    offsetShiftDecrement: 0,
    withLoadMore: false
  })

  const { isOpen, handleOpen, handleClose } = useHoldsToggle()

  const handleCloseHoldsDialogProductList = () => {
    handleRefetchTrigger()
    handleClose()
  }

  useEffect(() => {
    fetchGetHoldsByEmployeeIdCallback()
  }, [fetchTrigger, fetchGetHoldsByEmployeeIdCallback])

  const holdItems = holds.data.slice(0, displayLimit)

  const allowShowViewAllButton = holds.total > displayLimit
  const allowShowEmptyMessage = !isLoading && !holds.total && !holds.data.length
  const allowShowTitleTotal =
    Boolean(displayLimit && holds.total) && allowShowViewAllButton

  const titleToLowerCase = title.toLowerCase()

  return (
    <>
      <Box display="flex" alignItems="center" mb={0.5}>
        <Typography
          id={`hold-list-${titleToLowerCase}`}
          component="h1"
          variant="h6"
          sx={{
            flex: 1
          }}
        >
          {title}
        </Typography>

        {allowShowViewAllButton && (
          <Button
            data-testid={`view_all_${titleToLowerCase}_holds`}
            onClick={handleOpen}
            aria-label={`view all ${titleToLowerCase} holds`}
          >
            View All
          </Button>
        )}
      </Box>

      {allowShowEmptyMessage && (
        <TypographySubTitle2
          sx={{
            textAlign: 'center',
            my: 2
          }}
        >
          0 {titleToLowerCase} holds
        </TypographySubTitle2>
      )}

      {allowShowTitleTotal && (
        <TypographySubTitle2
          sx={{
            my: 0.5
          }}
        >
          {displayLimit} of {holds.total}
        </TypographySubTitle2>
      )}

      <Fade in={isLoading}>
        <Box display="flex" justifyContent="center">
          <CircularProgress size={32} />
        </Box>
      </Fade>

      {isError && (
        <PageError
          errorTitle={`Failed to load ${title} holds.`}
          isErrorTitlePersonalized={true}
          isFullWidth={true}
          isInlineAlert={true}
          errorDetails={{ endpoint: endpointName, errorData: error }}
        />
      )}

      <HoldsProductItemCards
        holds={holdItems}
        handleUpdateDetails={(hold: HoldT) => {
          const holdStatusIncludesInRequestedStatuses =
            isHoldStatusIncludesInRequestedStatuses({
              requestParams,
              hold
            })

          setHolds((prevState) => {
            const cloneData = [...prevState.data]

            const index = cloneData.findIndex(
              (productItem) => productItem.ticketNumber === hold.ticketNumber
            )

            cloneData[index] = hold

            return {
              data: cloneData,
              total:
                holds.total - (holdStatusIncludesInRequestedStatuses ? 0 : 1)
            }
          })
        }}
        handleCloseHoldDetailsDialog={handleRefetchTrigger}
      />

      <HoldsDialog
        data-testid="view_all_holds_dialog"
        open={isOpen}
        onClose={handleCloseHoldsDialogProductList}
      >
        <HoldsDialogBody
          handleClose={handleCloseHoldsDialogProductList}
          title={title}
          requestParams={requestParams}
        />
      </HoldsDialog>

      {isOpen && (
        <Prompt
          message={() => {
            handleCloseHoldsDialogProductList()
            return false
          }}
        />
      )}
    </>
  )
}

export default HoldsButtonProductList
