import { Button } from '@mui/material'
import { ReactElement } from 'react'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'

type PropsT = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const ScanButton = (props: PropsT): ReactElement => {
  const { onClick } = props
  //todo change this button text on desktop?

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      fullWidth
      sx={{ mt: 2 }}
      startIcon={<QrCodeScannerIcon />}
    >
      Scan Items
    </Button>
  )
}

export default ScanButton
