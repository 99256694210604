import {
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Box,
  useTheme
} from '@mui/material'
import { BOARD_TYPE_FILTERS } from 'types/CurationAttribution'

type FilterPropsT = {
  selectedToggle: BOARD_TYPE_FILTERS
  setSelectedToggle: (toggle: BOARD_TYPE_FILTERS) => void
}

const BoardTypeToggleButtonGroup = ({
  selectedToggle,
  setSelectedToggle
}: FilterPropsT): JSX.Element => {
  const theme = useTheme()
  const handleToggleButtonClick = (
    _event: React.MouseEvent<HTMLElement>,
    newToggle: BOARD_TYPE_FILTERS | null
  ) => {
    if (newToggle) {
      setSelectedToggle(newToggle)
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption">Board type: </Typography>
      <ToggleButtonGroup
        color="primary"
        value={selectedToggle}
        onChange={handleToggleButtonClick}
        exclusive
      >
        <ToggleButton
          value={BOARD_TYPE_FILTERS.ALL}
          sx={{
            bgcolor: 'white',
            outline: `1px solid ${theme.palette.grey[400]}`,
            outlineOffset: '-1px'
          }}
        >
          All
        </ToggleButton>
        <ToggleButton
          value={BOARD_TYPE_FILTERS.CUSTOMER}
          sx={{
            bgcolor: 'white',
            outline: `1px solid ${theme.palette.grey[400]}`,
            outlineOffset: '-1px'
          }}
        >
          Customer
        </ToggleButton>
        <ToggleButton
          value={BOARD_TYPE_FILTERS.PUBLIC}
          sx={{
            bgcolor: 'white',
            outline: `1px solid ${theme.palette.grey[400]}`,
            outlineOffset: '-1px'
          }}
        >
          Public
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default BoardTypeToggleButtonGroup
