import { ReactElement } from 'react'
import { ChevronLeft } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { Typography, Stack, Box, IconButton } from '@mui/material'

const FavoriteItemsHeader = (): ReactElement => {
  const history = useHistory()
  const handleBackClick = () => {
    history.goBack()
  }

  return (
    <Stack direction="row" alignContent="center" spacing={2} my={1}>
      <Box width="50px" display="flex" alignItems="center">
        <IconButton size="large" aria-label={'back'} onClick={handleBackClick}>
          <ChevronLeft
            sx={{ color: 'common.black' }}
            data-testid="backButton"
          />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography component="h1" fontWeight="bold" variant="h6">
          Favorite items
        </Typography>
      </Box>
    </Stack>
  )
}
export default FavoriteItemsHeader
