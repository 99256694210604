import { Box, Typography } from '@mui/material'
import React from 'react'

interface PropsT {
  text: string
}

const Badge: React.FC<PropsT> = ({ text }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fbe446',
      color: '#1F2122',
      minWidth: 85,
      height: 24,
      borderRadius: 16,
      alignSelf: 'center',
      marginRight: '16px'
    }}
  >
    <Typography fontSize={13}>{text}</Typography>
  </Box>
)

export default Badge
