import { ReactElement } from 'react'
import { Stack } from '@mui/material'
import Skeleton from 'components/Skeleton'

const LoadingBrands = (): ReactElement => (
  <Stack direction="row" mt={2} spacing={2} width={'100%'}>
    <Skeleton.Text textVariant={'body2'} width="20%" />
    <Skeleton.Text textVariant={'body2'} width="20%" />
    <Skeleton.Text textVariant={'body2'} width="20%" />
    <Skeleton.Text textVariant={'body2'} width="20%" />
    <Skeleton.Text textVariant={'body2'} width="20%" />
  </Stack>
)

export default LoadingBrands
