import { Box, Tooltip, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen } from '@fortawesome/fontawesome-free-solid'
import { library } from '@fortawesome/fontawesome'
import { formatCurationOrderCount } from 'utils/formatCurationOrders'
import { formatCurationViewCount } from 'utils/formatCurationViews'

library.add(faBoxOpen)

const CurationViewsAndOrders = ({
  orders,
  views
}: {
  orders?: number
  views?: number
}): JSX.Element => {
  return (
    <Box
      display="flex"
      mt={1}
      flexDirection="row"
      sx={{
        variant: 'body2',
        color: 'text.secondary',
        alignItems: 'center'
      }}
    >
      <>
        <VisibilityIcon
          sx={{
            width: '20px',
            height: '20px',
            color: 'text.secondary'
          }}
        />
        <Typography
          ml={0.5}
          color="text.secondary"
          variant="caption"
          align="center"
        >
          {formatCurationViewCount(views)}
        </Typography>
      </>
      <Box ml={2}>
        <FontAwesomeIcon
          icon={'box-open'}
          data-testid="order-box"
          className="fa-sm"
        />
      </Box>
      <>
        <Tooltip
          title="Unique orders"
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8]
                }
              }
            ]
          }}
        >
          <Typography
            ml={0.5}
            color="text.secondary"
            variant="caption"
            align="center"
          >
            {formatCurationOrderCount(orders)}
          </Typography>
        </Tooltip>
      </>
    </Box>
  )
}

export default CurationViewsAndOrders
