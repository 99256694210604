import { ProductDiscoveryT } from 'types/Curation'
export const PRODUCT_DISCOVERY_STORAGE_KEY = 'draft_product_discovery_storage'

export const saveDraftProductDiscovery = ({
  curationId,
  productDiscovery
}: {
  curationId: number
  productDiscovery: ProductDiscoveryT
}): void => {
  const draftProductDiscovery = JSON.parse(
    localStorage.getItem(PRODUCT_DISCOVERY_STORAGE_KEY) || '{}'
  )
  const newDraftProductDiscovery = {
    ...draftProductDiscovery,
    [curationId]: productDiscovery
  }
  localStorage.setItem(
    PRODUCT_DISCOVERY_STORAGE_KEY,
    JSON.stringify(newDraftProductDiscovery)
  )
  return
}

export const loadDraftProductDiscovery = (
  curationId: number
): Record<string, unknown> => {
  return JSON.parse(
    localStorage.getItem(PRODUCT_DISCOVERY_STORAGE_KEY) || '{}'
  )[curationId]
}

export const deleteDraftProductDiscovery = (curationId: number): void => {
  const draftProductDiscovery = JSON.parse(
    localStorage.getItem(PRODUCT_DISCOVERY_STORAGE_KEY) || '{}'
  )
  delete draftProductDiscovery[curationId]
  localStorage.setItem(
    PRODUCT_DISCOVERY_STORAGE_KEY,
    JSON.stringify(draftProductDiscovery)
  )
  return
}
