import { ReactElement } from 'react'

import { HoldsHandleCloseT } from 'pages/Holds/types'

import {
  HOLDS_MEDIA_SM_WIDTH_ADD_ITEM_AND_FIND_EXISTING_HOLD,
  HOLDS_TEXT_ADD_ITEM,
  LINE_ITEM_LIMIT_REACHED_SNACKBAR_MESSAGE,
  LINE_ITEM_LIMIT_REACHED_SNACKBAR_TITLE
} from 'pages/Holds/constants'

import BasePageAlert from 'components/BasePageAlert'

import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'
import HoldsButtonScanItem from 'pages/Holds/shared/components/HoldsButtonScanItem'

import { HoldsButtonAddItem } from './components'

const HoldsDialogBodyAddItem = ({
  isLoading,
  allowAddMoreItems,
  handleSubmit,
  handleClose
}: {
  isLoading: boolean
  allowAddMoreItems: boolean
  handleSubmit: (upc: string) => void
} & HoldsHandleCloseT): ReactElement => (
  <>
    <HoldsDialogAppBar
      title={HOLDS_TEXT_ADD_ITEM}
      handleClose={handleClose}
      closeIconButtonProps={{
        'data-testid': 'back_arrow_add_item'
      }}
    />

    <HoldsDialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: {
          sm: HOLDS_MEDIA_SM_WIDTH_ADD_ITEM_AND_FIND_EXISTING_HOLD
        }
      }}
    >
      {!allowAddMoreItems && (
        <BasePageAlert
          alertTitle={LINE_ITEM_LIMIT_REACHED_SNACKBAR_TITLE}
          errorMessage={LINE_ITEM_LIMIT_REACHED_SNACKBAR_MESSAGE}
        />
      )}

      <HoldsButtonAddItem isLoading={isLoading} handleSubmit={handleSubmit} />
      <HoldsButtonScanItem handleSubmit={handleSubmit} />
    </HoldsDialogContent>
  </>
)

export default HoldsDialogBodyAddItem
