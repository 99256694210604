import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

type PropsT = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const RecentItemsButton = ({ onClick }: PropsT): JSX.Element => (
  <Button
    fullWidth
    onClick={onClick}
    variant="outlined"
    sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
    startIcon={<AddIcon />}
  >
    Add Recent
  </Button>
)

export default RecentItemsButton
