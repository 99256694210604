import { ReactElement } from 'react'
import { Button, Grid, IconButton, TextField } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

interface DrawerSearchBarT {
  handleInputChange: (value: string) => void
  isError: boolean
  isNumericOnlyTextField?: boolean
  numericInputHelperText?: string
  onClick: (value: string) => void
  placeholder: string
  query: string
  resetSearch: () => void
}

const DrawerSearchBar = (props: DrawerSearchBarT): ReactElement => {
  const {
    isError,
    isNumericOnlyTextField,
    handleInputChange,
    numericInputHelperText,
    query,
    onClick,
    placeholder,
    resetSearch
  } = props

  return (
    <Grid container flexDirection="row" spacing={1} flexWrap="nowrap">
      <Grid item flexGrow={1}>
        <TextField
          error={isError}
          fullWidth
          sx={{ mb: 2 }}
          size="small"
          value={query}
          placeholder={placeholder}
          onChange={(e) => handleInputChange(e.target.value)}
          helperText={
            isError && isNumericOnlyTextField ? numericInputHelperText : null
          }
          inputProps={isNumericOnlyTextField ? { inputMode: 'numeric' } : {}}
          InputProps={{
            endAdornment: query && (
              <IconButton aria-label="clear search" onClick={resetSearch}>
                <HighlightOffIcon />
              </IconButton>
            )
          }}
          onKeyUp={(e) => e.key === 'Enter' && query && onClick(query)}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => query && onClick(query)}>
          Search
        </Button>
      </Grid>
    </Grid>
  )
}

export default DrawerSearchBar
