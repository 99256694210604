import { keyframes } from '@emotion/react'

const grayscale = {
  paleGray: '#E3E3E3',
  softWhite: '#F7F7F7'
}

const shimmer = keyframes`
  0% {
    background-position: -200px 0
  }
  100% {
    background-position: calc(200px + 100%) 0
  }
`

export const baseSkeletonStyling = {
  animation: `${shimmer} 1.2s ease-in-out infinite`,
  backgroundColor: grayscale.paleGray,
  backgroundImage: `linear-gradient(90deg, ${grayscale.paleGray}, ${grayscale.softWhite}, ${grayscale.paleGray})`,
  backgroundSize: '200px 100%',
  backgroundRepeat: 'no-repeat'
}

export type TypographyVariants =
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2'
