import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const useReportingLinks = (): Record<
  'payrollCalendarUrl' | 'fiscalCalendarUrl',
  string | undefined
> => {
  const { personalBookReportingLinks } = useFeatureFlags()
  const { payload } = personalBookReportingLinks || {}

  let payrollCalendarUrl,
    fiscalCalendarUrl = undefined

  if (payload !== null && typeof payload === 'object') {
    if ('payrollCalendarUrl' in payload) {
      payrollCalendarUrl = String(payload.payrollCalendarUrl)
    }

    if ('fiscalCalendarUrl' in payload) {
      fiscalCalendarUrl = String(payload.fiscalCalendarUrl)
    }
  }

  return {
    payrollCalendarUrl,
    fiscalCalendarUrl
  }
}

export default useReportingLinks
