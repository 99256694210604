import { ReactElement, useState, useEffect, useRef } from 'react'
import useWindowDimensions from 'utils/useWindowDimensions'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { SavedSizesCategoryT } from 'types/ConsentedCustomer'
import {
  Typography,
  List,
  ListItem,
  Collapse,
  Box,
  Stack,
  Button
} from '@mui/material'

type PropsT = {
  sizes: SavedSizesCategoryT[]
  showingAll: boolean
  handleClick: () => void
  showMore: boolean
}

const SizesList = (props: PropsT): ReactElement => {
  const { sizes, showingAll, handleClick, showMore } = props
  const [height, setHeight] = useState(0)
  const isMobileDevice = checkBrowserClient.isMobile()

  const ref = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowDimensions()

  const buttonText = showingAll ? 'Show Less' : 'Show More'

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight)
    }
  }, [setHeight, height, windowWidth])

  return (
    <>
      <Collapse
        style={{
          height: 'auto'
        }}
        in={showingAll}
        collapsedSize="auto"
      >
        <Stack direction="row" sx={{ flexWrap: 'wrap' }} ref={ref}>
          <List
            sx={{
              pt: '12px',
              pb: 0,
              display: 'flex',
              flexDirection: !isMobileDevice ? 'row' : 'column',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            {sizes.map((size, index) => (
              <ListItem
                key={index}
                sx={{
                  width: !isMobileDevice ? '49.4%' : '100%',
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  border: '1px solid rgba(31, 33, 34, 0.23)',
                  mb: '8px',
                  minHeight: '64px'
                }}
              >
                <Typography variant="body2">{size.category}</Typography>
                <Typography variant="caption">{size.displayName}</Typography>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Collapse>
      {showMore && (
        <Box display="flex" justifyContent="flex-end" pt="8px">
          <Button onClick={handleClick} color="primary" variant="text">
            {buttonText}
          </Button>
        </Box>
      )}
    </>
  )
}

export default SizesList
