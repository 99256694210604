import { TextField } from '@mui/material'
import { ReactElement, useContext } from 'react'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { UPDATE_TITLE, MAX_TITLE_LENGTH } from '../../constants'
import { EDIT_TITLE } from 'pages/CurationEdit/constants'

const CURATION_TITLE_LABEL = 'Title'

type PropsT = {
  errorText: string
  title: string
  isEdit: boolean
}

const CurationTitle = (props: PropsT): ReactElement => {
  const { errorText, title, isEdit } = props
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)

  const determineHelperText = (titleLength: number) => {
    if (errorText) return errorText

    const characterTense =
      titleLength === MAX_TITLE_LENGTH - 1 ||
      titleLength === MAX_TITLE_LENGTH + 1
        ? 'character'
        : 'characters'

    if (titleLength <= MAX_TITLE_LENGTH)
      return `${MAX_TITLE_LENGTH - titleLength} ${characterTense} left`

    return `${titleLength - MAX_TITLE_LENGTH} ${characterTense} over`
  }

  const handleChange = (value: string) => {
    if (isEdit) {
      editDispatch && editDispatch({ type: EDIT_TITLE, payload: value })
    } else {
      dispatch && dispatch({ type: UPDATE_TITLE, payload: { title: value } })
    }
  }

  const helperText = determineHelperText(title.length)

  return (
    <TextField
      error={!!errorText}
      fullWidth
      helperText={helperText}
      id={'title-id'}
      label={CURATION_TITLE_LABEL}
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => {
        handleChange(event?.target.value)
      }}
      variant="outlined"
      value={title}
    />
  )
}

export default CurationTitle
