import { ReactElement } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { colors } from '../../../constants'

type PropsT = {
  onClose: () => void
}

const PersonalBookingLink = ({ onClose }: PropsT): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <AttachFileIcon
          color="primary"
          fontSize="small"
          sx={{ marginRight: '8px' }}
        />
        <Typography
          lineHeight="24px"
          letterSpacing="0.4px"
          fontWeight="bold"
          color="primary"
          variant="body2"
          fontSize="14px"
        >
          Your Booking Link
        </Typography>
      </Box>
      <IconButton onClick={onClose} data-testid="cancelBookingLink">
        <CloseIcon sx={{ color: colors.lightGray }} />
      </IconButton>
    </Box>
  )
}

export default PersonalBookingLink
