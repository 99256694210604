import { Box, Typography } from '@mui/material'
import type { SxProps } from '@mui/system'
import { ANNIVERSARY_ENTICEMENTS } from 'types/Product'

import { EnticementsT, SkuPricing } from 'types/WishList'

type PropsT = {
  price: SkuPricing
  enticements: Array<EnticementsT>
  styleId?: number
  isPublished: boolean
  sx?: SxProps
}

const ProductGridPrice = ({
  price,
  enticements,
  styleId,
  isPublished,
  sx
}: PropsT): JSX.Element => {
  const ANNIVERSARY_YELLOW = '#FBE446'

  const isSoldOut = isPublished === false
  const priceType = price.currentPriceType
  const isPriceTypePromotion = priceType === 'P' || priceType === 'PROMOTION'

  const hasAnniversaryEnticement = enticements.some(
    (enticement) =>
      enticement.type &&
      Object.values<string>(ANNIVERSARY_ENTICEMENTS).includes(enticement.type)
  )

  const isPromotionAnniversaryItem =
    isPriceTypePromotion && hasAnniversaryEnticement

  const isRegularAnniversaryItem =
    hasAnniversaryEnticement && !isPromotionAnniversaryItem

  const textDecoration =
    priceType === 'CLEARANCE' || isPriceTypePromotion ? 'line-through' : 'none'

  return (
    <Box sx={sx}>
      {isSoldOut ? (
        <Typography
          display="inline"
          variant="body2"
          sx={{
            ...(isPromotionAnniversaryItem && {
              backgroundColor: ANNIVERSARY_YELLOW
            }),
            fontWeight: 'bold'
          }}
        >
          Sold Out
        </Typography>
      ) : (
        <>
          <Box>
            {/* Non-Anniversary Promotional Pricing */}
            {(priceType === 'CLEARANCE' || priceType === 'PROMOTION') &&
              !isPromotionAnniversaryItem && (
                <Typography
                  display="inline"
                  variant="subtitle2"
                  color="red"
                >{`$${
                  priceType === 'CLEARANCE'
                    ? price.clearance?.price?.units
                    : price.promotion?.price?.units
                } `}</Typography>
              )}

            {/* Anniversary Promotional Pricing */}
            {isPromotionAnniversaryItem && (
              <Typography
                display="inline"
                variant="subtitle2"
                marginRight="4px"
                padding="0 4px"
                data-testid={`anniversary-item-${styleId}`}
                sx={{
                  backgroundColor: ANNIVERSARY_YELLOW
                }}
              >{`$${price.promotion?.price?.units}`}</Typography>
            )}

            {/* Standard Pricing */}
            <Typography
              display="inline"
              variant={priceType === 'REGULAR' ? 'subtitle2' : 'body2'}
              sx={{
                textDecoration: textDecoration,
                backgroundColor: isRegularAnniversaryItem
                  ? ANNIVERSARY_YELLOW
                  : 'transparent'
              }}
            >{`$${price.regular?.price?.units}`}</Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export default ProductGridPrice
