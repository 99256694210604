import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { DragOverlay } from '@dnd-kit/core'
import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core'
import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'
import { CurationItemT } from 'types/Curation'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { SET_ORDERED_ITEMS } from 'pages/CurationReview/constants'
import DndContext from 'components/DndContext'
import CurationItem from '../CurationItem'
import { checkBrowserClient } from 'utils/userAgentDetector'

type PropsT = {
  curationItems: CurationItemT[]
  duplicateSkus?: string[]
  itemNotesObject: Record<string, string>
  shouldShowSizes: boolean
  handleClickForPdp: (webStyleId: string) => void
  handleRemoveClick: (itemIds: string[]) => void
  isEdit?: boolean
  orderedItems?: string[]
}

const CurationItemsList = (props: PropsT): ReactElement => {
  const {
    curationItems,
    duplicateSkus,
    itemNotesObject,
    shouldShowSizes,
    handleClickForPdp,
    handleRemoveClick,
    isEdit = false,
    orderedItems
  } = props

  const dispatch = useContext(CurationReviewDispatchContext)
  const theme = useTheme()
  const isSingleColumnScreenSize = useMediaQuery(theme.breakpoints.down('sm'))

  const setOrderedItems = useCallback(
    (orderedItems: string[]) => {
      dispatch &&
        dispatch({
          type: SET_ORDERED_ITEMS,
          payload: { orderedItems }
        })
    },
    [dispatch]
  )

  useEffect(() => {
    if (!orderedItems) return
    if (curationItems && curationItems.length > orderedItems.length) {
      curationItems.forEach((item) => {
        if (!orderedItems.includes(item.rmsSku)) {
          setOrderedItems([...orderedItems, item.rmsSku])
        }
      })
    } else if (curationItems && curationItems.length < orderedItems.length) {
      const remainingRmsSkus = curationItems.map((item) => item.rmsSku)
      const updatedItemArray = orderedItems.filter((rmsSku) =>
        remainingRmsSkus.includes(rmsSku)
      )
      setOrderedItems(updatedItemArray)
    } else {
      setOrderedItems(orderedItems)
    }
  }, [curationItems, orderedItems, setOrderedItems])

  const alignOrderedItemIds = (
    orderedItems: string[],
    curationItems: CurationItemT[]
  ) => {
    const alignedOrderedItems: CurationItemT[] = []
    orderedItems.forEach((rmsSku) => {
      const foundItem = curationItems.find(
        (curationItem) => curationItem.rmsSku === rmsSku
      )

      if (foundItem) {
        alignedOrderedItems.push(foundItem)
      }
    })
    return alignedOrderedItems
  }

  const itemsToMap = orderedItems
    ? alignOrderedItemIds(orderedItems, curationItems)
    : curationItems

  const getTaskPosition = (orderedItems: string[], id: UniqueIdentifier) => {
    return orderedItems.findIndex((taskId) => taskId === id)
  }

  const [activeCuration, setActiveCuration] = useState<CurationItemT | null>(
    null
  )

  const handleDragStart = (event: DragStartEvent) => {
    setActiveCuration(
      itemsToMap.find((item) => item.id === event.active.id) || null
    )
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id || !orderedItems) {
      setActiveCuration(null)
      return
    }

    const ogPosition = getTaskPosition(orderedItems, active.id)
    const newPosition = getTaskPosition(orderedItems, over.id)

    const newOrderedItems = arrayMove(orderedItems, ogPosition, newPosition)

    setOrderedItems(newOrderedItems)
    setActiveCuration(null)
  }

  return (
    <DndContext
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      isMobile={!!checkBrowserClient.isMobile()}
      shouldRestrictToVerticalAxis={isSingleColumnScreenSize}
    >
      <Container disableGutters>
        <Grid container spacing={3}>
          <SortableContext
            items={itemsToMap}
            strategy={
              isSingleColumnScreenSize ? verticalListSortingStrategy : undefined
            }
          >
            {itemsToMap.map((itemProps) => {
              return (
                <Grid item key={itemProps.rmsSku} xs={12} sm={6} md={6} lg={3}>
                  <CurationItem
                    {...itemProps}
                    note={itemNotesObject[itemProps.rmsSku]}
                    showItemError={
                      isEdit && duplicateSkus?.includes(itemProps.rmsSku)
                    }
                    shouldShowSize={shouldShowSizes}
                    onClickForPDP={handleClickForPdp}
                    onRemoveItemClick={handleRemoveClick}
                    isEdit={isEdit}
                    isDragging={activeCuration?.id === itemProps.id}
                  />
                </Grid>
              )
            })}
          </SortableContext>
        </Grid>
      </Container>
      <DragOverlay>
        {activeCuration ? (
          <CurationItem
            {...activeCuration}
            note={itemNotesObject[activeCuration.rmsSku]}
            showItemError={false}
            shouldShowSize={shouldShowSizes}
            onClickForPDP={handleClickForPdp}
            onRemoveItemClick={handleRemoveClick}
            isEdit={isEdit}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

export default CurationItemsList
