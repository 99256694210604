import BaseSnackbar from 'components/BaseSnackbar'
import { ReactElement } from 'react'

export const ERROR_COPIED_SNACKBAR_MESSAGE =
  'Copied. Paste in chat to get help.'

type PropsT = {
  setCopyToClipboardSuccess?: (arg: boolean) => void
}

const CopiedErrorSnackbar = (props: PropsT): ReactElement => {
  const { setCopyToClipboardSuccess } = props

  return (
    <BaseSnackbar
      message={ERROR_COPIED_SNACKBAR_MESSAGE}
      setCopyToClipboardSuccess={setCopyToClipboardSuccess}
    />
  )
}

export default CopiedErrorSnackbar
