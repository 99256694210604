import { ReactElement, useEffect, useState, useRef } from 'react'
import { Html5Qrcode } from 'html5-qrcode'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { Box, Typography, Alert, AlertTitle } from '@mui/material'
import CopyErrorData from 'components/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import { emDashCharacter } from 'pages/constants'

const barcodeRegionId = 'html5qr-code-full-region'

interface PropsT {
  handleNotAllowedOrPermissionsError?: () => void
  onScanSuccess: (data: string) => void
}

const StartScannerError = ({ errorData }: { errorData: unknown }) => {
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  return (
    <>
      {errorData && (
        <Box sx={{ mt: 3, mx: 2 }}>
          <Alert severity="error">
            <AlertTitle>
              <strong data-testid="error-title">
                {`Issue starting the scanner ${emDashCharacter} try closing and reopening the page, or enter the UPC manually.`}
              </strong>
            </AlertTitle>
            <CopyErrorData
              errorDetails={{ errorData }}
              setCopyToClipboardSuccess={setCopyToClipboardSuccess}
            />
          </Alert>
        </Box>
      )}
      {copyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
    </>
  )
}

const BarcodeScanner = (props: PropsT): ReactElement => {
  const { handleNotAllowedOrPermissionsError, onScanSuccess } = props
  const curationDetails = useAppSelector(curationSelector)
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const [scanHadResponse, setScanHadResponse] = useState(false)
  const [scanner, setScanner] = useState<Html5Qrcode>()
  const [isAlertShowing, setIsAlertShowing] = useState(false)
  const [startScannerError, setStartScannerError] = useState<unknown>(undefined)

  const scannerRef = useRef<Html5Qrcode>()
  scannerRef.current = scanner
  const startScanTimeRef = useRef<number>(0)
  const timeoutId = useRef<NodeJS.Timeout>()

  const scanSuccessCallback = (decodedText: string, decodedResult: unknown) => {
    if (scanner && scanner.isScanning) {
      stopScanner(scanner)
    }
    setScanHadResponse(true)
    setIsAlertShowing(false)

    generateNewRelicLogs('BarcodeScannerScanSuccess', {
      employeeId,
      curationId: curationDetails.id,
      decodedText,
      decodedResult,
      scannerStartToSuccessTime: Date.now() - startScanTimeRef.current
    })

    onScanSuccess(decodedText)
  }

  const scanErrorCallback = (error: string) => {
    console.error(`Code scan error = ${error}`)
    setScanHadResponse(true)
    setIsAlertShowing(false)
    generateNewRelicLogs('BarcodeScannerScanError', {
      employeeId,
      curationId: curationDetails.id,
      error,
      scannerStartToErrorTime: Date.now() - startScanTimeRef.current
    })
    //todo do we want to display an error and have the user clear it before relaunching the scanner?
    if (scanner && !scanner.isScanning) {
      startScanner(scanner)
    }
  }

  const handleError = (error: string) => {
    /*
     * Should we just remove this Error callback since it fires every frame??
     * These two errors are extremely verbose As it fires every frame and will error constantly until the scanner has a success. There is no point
     * in passing these to the callback, I'm not sure what other errors may occur but these are of no use.
     */
    // this just keeps happening if there is no barcode in the window so I'm commenting it out for now - DP
    const noBarcodeDetected = error.includes('No barcode or QR code detected')
    const NotFoundException = error.includes(
      'No MultiFormat Readers were able to detect the code'
    )
    const indexSizeError = error.includes(
      'IndexSizeError: The index is not in the allowed range.'
    )

    if (
      scanErrorCallback &&
      !NotFoundException &&
      !noBarcodeDetected &&
      !indexSizeError
    ) {
      scanErrorCallback(error)
    }
  }

  useEffect(() => {
    setScanner(new Html5Qrcode(barcodeRegionId))
    return () => {
      // Cleanup function that runs on unmount
      if (scannerRef?.current?.isScanning) {
        const attributes = {
          scannerDrawerOpenDuration: Date.now() - startScanTimeRef.current,
          curationId: curationDetails.id
        }
        if (!scanHadResponse) {
          generateNewRelicLogs('upcScannerClosedWithNoResponse', attributes)
        }
        stopScanner(scannerRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const startScanner = async (scanner: Html5Qrcode) => {
    try {
      const startScanTime = Date.now()
      startScanTimeRef.current = startScanTime

      await scanner.start(
        { facingMode: 'environment' },
        {
          fps: 10,
          qrbox: { width: 329, height: 152 },
          aspectRatio: 1.777778,
          disableFlip: false
        },
        scanSuccessCallback,
        handleError
      )
      await scanner.applyVideoConstraints({
        focusMode: 'continuous',
        advanced: [{ advanced: 'continuous' }]
      })
    } catch (startScannerError) {
      if (
        handleNotAllowedOrPermissionsError &&
        typeof startScannerError === 'string'
      ) {
        const CAMERA_PERMISSION_DENIED = [
          'permission denied',
          'not allowed',
          'permissiondenied',
          'notallowed'
        ]
        if (
          CAMERA_PERMISSION_DENIED.some((phrase) =>
            startScannerError.toLowerCase().includes(phrase)
          )
        ) {
          handleNotAllowedOrPermissionsError()
        }
      } else {
        if (startScannerError instanceof Error) {
          setStartScannerError(startScannerError.toString())
        } else {
          const err = new Error(
            `an error occurred: { cause: ${startScannerError} }`
          )
          setStartScannerError(err.toString())
        }
      }

      generateNewRelicLogs('StartScannerError', {
        employeeId,
        curationId: curationDetails.id,
        startScannerError
      })
    }
    timeoutId.current = setTimeout(() => {
      setIsAlertShowing(true)
      generateNewRelicLogs('scanningHelperTextShown', {
        employeeId,
        curationId: curationDetails.id
      })
    }, 7000)
  }

  const stopScanner = async (scanner: Html5Qrcode) => {
    await scanner
      .stop()
      .then((ignore) => {
        scanner.clear()
        return ignore
      })
      .catch((error) => {
        console.error('Unable to stop scanning.', error)
        generateNewRelicLogs('StopScannerError', {
          employeeId,
          curationId: curationDetails.id,
          error
        })
      })
  }

  useEffect(() => {
    if (scanner) {
      startScanner(scanner)
    }
    return () => {
      if (timeoutId?.current) {
        clearTimeout(timeoutId?.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanner, timeoutId])

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box id={barcodeRegionId}></Box>
      {!scanHadResponse &&
        !startScannerError &&
        (!isAlertShowing ? (
          <Typography
            mx={2}
            p={1}
            align="center"
            variant="caption"
            position="relative"
            color="white"
            bottom={'80%'}
            sx={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
          >
            Center the barcode inside the frame to scan it. Try to avoid shadows
            and glare.
          </Typography>
        ) : (
          <Alert
            severity="warning"
            sx={{
              mx: 2,
              p: '6px',
              position: 'relative',
              bottom: '80%',
              align: 'center'
            }}
          >
            We&apos;re having trouble detecting a barcode. Try different
            lighting or angle, or manually enter the UPC instead.
          </Alert>
        ))}
      <StartScannerError errorData={startScannerError} />
    </Box>
  )
}

export default BarcodeScanner
