import React, { useMemo } from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber'
import { ConsentedCustomerListItemT } from 'types/ConsentedCustomer'
import NewCustomerBadge from 'components/NewCustomerBadge'
import { shouldDisplayNewCustomerBadge } from '../../utils'

interface CustomerCardProps {
  customer: ConsentedCustomerListItemT
  onClick: (id: string) => void
  query?: string
}

const CustomerCard: React.FC<CustomerCardProps> = (props) => {
  const { customer, onClick, query } = props
  const customerDisplayName =
    customer.firstName && customer.lastName
      ? `${customer.firstName || ''} ${customer.lastName || ''}`
      : customer.email

  const primaryData = useMemo(() => {
    return (
      <>
        {customerDisplayName}
        {shouldDisplayNewCustomerBadge(customer.relationshipDate) && (
          <ListItemIcon>
            <NewCustomerBadge />
          </ListItemIcon>
        )}
      </>
    )
  }, [customerDisplayName, customer.relationshipDate])

  const secondaryData = useMemo(() => {
    const isPhone = !!query && !isNaN(parseInt(query)) && !!customer.mobile
    const isEmail = !!query && !!customer.email
    let result = ''
    if (isPhone && customer.mobile.includes(query)) {
      result = formatPhoneNumber(customer.mobile)
    }
    if (
      isEmail &&
      customer.email.toLocaleLowerCase().includes(query.toLocaleLowerCase()) &&
      customerDisplayName !== customer.email
    ) {
      result = result.length
        ? result.concat(`\n${customer.email}`)
        : customer.email
    }
    return result
  }, [query, customer, customerDisplayName])

  return (
    <ListItem
      onClick={() => onClick(customer.customerId)}
      divider
      aria-label="customerListItem"
      data-testid="customerListItem"
      sx={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingY: '4px',
        minHeight: 48
      }}
    >
      <ListItemText
        sx={{
          width: '400px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start'
        }}
        primary={primaryData}
        primaryTypographyProps={{
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          noWrap: true
        }}
        secondary={secondaryData}
        secondaryTypographyProps={{
          style: { fontSize: '14px', whiteSpace: 'pre-wrap' }
        }}
      />
      <ListItemIcon sx={{ minWidth: 24 }}>
        <KeyboardArrowRightIcon />
      </ListItemIcon>
    </ListItem>
  )
}

export default CustomerCard
