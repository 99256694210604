import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { HOLDS_COLOR_ASTERISK } from 'pages/Holds/constants'

const HoldsRequiredLabel = (): ReactElement => (
  <Box display="flex" gap={0.5}>
    <Typography
      variant="body2"
      sx={{
        color: HOLDS_COLOR_ASTERISK
      }}
    >
      *
    </Typography>

    <Typography variant="body2">Required</Typography>
  </Box>
)

export default HoldsRequiredLabel
