import { ReactElement } from 'react'
import BaseDialog from 'components/BaseDialog'
import { Typography } from '@mui/material'

const OFFER_NOT_FOUND_DIALOG_TITLE = 'Result not found'
const OFFER_NOT_FOUND_CONTENT = (
  <>
    <Typography>
      Try scanning the barcode or manually entering the UPC again.
    </Typography>
    <Typography mt={3}>
      Note that we can&#39;t display results if items are not set up online or
      if they are non-Nordstrom items.
    </Typography>
  </>
)

const OfferNotFoundDialog = ({
  open,
  resetOfferError
}: {
  open: boolean
  resetOfferError: () => void
}): ReactElement => {
  return (
    <BaseDialog
      confirm={{ action: resetOfferError, text: 'Okay' }}
      open={open}
      title={OFFER_NOT_FOUND_DIALOG_TITLE}
      content={OFFER_NOT_FOUND_CONTENT}
    />
  )
}

export default OfferNotFoundDialog
