import ListItemInfo from 'pages/CustomerDetails/components/ListItemInfo'
import { fomatConsentedCustomerTripsText } from './utils'
import { colors, CONSENTED_CUSTOMER_TRIPS_TITLE } from './constants'

type PropsT = {
  numberOfTrips: number
}

const ConsentedCustomerTripsContent = ({
  numberOfTrips
}: PropsT): JSX.Element => {
  return (
    <ListItemInfo
      title={CONSENTED_CUSTOMER_TRIPS_TITLE}
      text={fomatConsentedCustomerTripsText(numberOfTrips)}
      styleOverride={{
        secondaryColor: numberOfTrips ? colors.PRIMARY : colors.SECONDARY
      }}
    />
  )
}

export default ConsentedCustomerTripsContent
