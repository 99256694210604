import moment from 'moment'

const holdsFormatDateToLocalDate = ({
  date,
  format = 'MM/DD/yyyy'
}: {
  date: string | Date
  format?: string
}): string => moment(date).local().format(format)

export default holdsFormatDateToLocalDate
