import { ReactElement } from 'react'
import { Typography, Box } from '@mui/material'
import Chips from 'components/Chips'
import PageError from 'components/PageError'
import { SHOPPED_BRAND_ERROR_TITLE } from 'pages/CustomerDetails/constants'

type PropsT = {
  brands: string[]
  isError?: boolean
}

interface ErrorPropsT {
  customerId: string
  endpointName?: string
}

const NoBrands = () => (
  <Typography
    variant="body2"
    color="text.secondary"
    style={{ fontSize: '1.125rem' }}
  >
    No shopped brands
  </Typography>
)

const ShoppedBrandDataError = ({ customerId, endpointName }: ErrorPropsT) => (
  <PageError
    isInlineAlert={true}
    errorTitle={SHOPPED_BRAND_ERROR_TITLE}
    errorDetails={{
      endpoint: endpointName,
      errorData: `PAS Error: ${SHOPPED_BRAND_ERROR_TITLE}`,
      identifiers: {
        customerId,
        curationId: 'NO-DATA',
        employeeId: 'NO-DATA',
        shoppingSessionId: 'NO-DATA'
      }
    }}
  />
)

const ShoppedBrands = ({
  brands,
  customerId,
  endpointName,
  isError
}: PropsT & ErrorPropsT): ReactElement => {
  return (
    <Box>
      <Typography variant="subtitle2" mt="12px">
        Shopped brands
      </Typography>
      {!isError ? (
        <>{brands ? <Chips data={brands} /> : <NoBrands />}</>
      ) : (
        <ShoppedBrandDataError
          customerId={customerId}
          endpointName={endpointName}
        />
      )}
    </Box>
  )
}
export default ShoppedBrands
