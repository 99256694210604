import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import isEmpty from 'lodash/isEmpty'
import setStandardHeaders from 'utils/set-standard-headers'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  ConsentedCustomerT,
  GetConsentedCustomerQueryParamsT,
  ActivateCommunicationsResponseT,
  ConsentedCustomerListItemT,
  GetConsentedCustomerListResponseT,
  ArchivedCustomerT
} from 'types/ConsentedCustomer'
import { EmployeeT, GetEmployeeQueryParamsT } from 'types/Employee'
import { CustomerByIdentifierT, GetCustomerQueryParams } from 'types/Customer'
import {
  CustomerBookFilters,
  CustomerBookFilterOption
} from 'types/CustomerBookFilters'
import {
  initializeNordyClubFilter,
  initializeCardmemberFilter,
  initializeSavedBrandsFilter,
  initializeAnniversaryFilter,
  initializeNotesFilter,
  initializeLastMessageTimeFilter,
  getFilterFromStorage,
  setFilters
} from 'services/customerBookFiltersSlice'
import { ConsentedCustomerOrderT } from 'types/Orders'

const customErrorHandlerQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const result = await fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CLIENTELING_URL,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })

      const accessToken = oktaTokenStorage.getOktaAccessToken()
      if (accessToken) {
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        headers.set('employee-id', employeeId)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    }
  })(args, api, extraOptions)
  if (result && result.error) {
    const nordRequestId = result.meta?.request.headers.get('nord-request-id')
    const traceContext = result.meta?.request.headers.get('TraceContext')

    // To bail out of retries if there is any timeout from server
    if (result.error?.status === 499 || result.error?.status === 504) {
      retry.fail(result.error)
    }

    const errorWithMetaData = {
      error: {
        ...result.error,
        errorMetaData: { nordRequestId, traceContext }
      }
    }

    // We don't want endpoints to retry when running unit tests. This section allows to do proper testing.
    if (process.env.NODE_ENV === 'test') {
      retry.fail(result.error)
    }

    return errorWithMetaData
  }

  return result
}

const baseQueryWithRetry = retry(customErrorHandlerQuery, { maxRetries: 3 })

export const clientelingApi = createApi({
  reducerPath: 'consentedCustomerListApi',
  baseQuery: baseQueryWithRetry,
  endpoints: (builder) => ({
    getConsentedCustomerList: builder.query<
      {
        customers: ConsentedCustomerListItemT[]
        filters: CustomerBookFilters
        hasArchivedCustomers: boolean
      },
      {
        retrieveFilters: boolean
        fetchAdditionalCustomerIds?: boolean
        retrieveQualification: boolean
      }
    >({
      query: (arg) => {
        const {
          retrieveFilters,
          retrieveQualification,
          fetchAdditionalCustomerIds
        } = arg
        return {
          url: '/customers',
          headers: {
            'fetch-additional-customer-ids':
              fetchAdditionalCustomerIds?.toString()
          },
          params: {
            'retrieve-filters': retrieveFilters,
            'retrieve-qualification': retrieveQualification,
            'retrieve-shopped-brands': true,
            'retrieve-notes': true
          }
        }
      },
      transformResponse: (response: GetConsentedCustomerListResponseT) => {
        let filtersToApply = {} as CustomerBookFilters
        const storedFilters = getFilterFromStorage()
        if (isEmpty(storedFilters)) {
          const initializedFilters: CustomerBookFilters = {
            qualification: initializeAnniversaryFilter(),
            loyaltyStatus: initializeNordyClubFilter(),
            cardmember: initializeCardmemberFilter(),
            nordstromNotes: initializeNotesFilter(),
            lastMessage: initializeLastMessageTimeFilter(),
            brands: initializeSavedBrandsFilter()
          }
          const savedBrandsOptions: CustomerBookFilterOption[] =
            response.filters.brands.map((brand) => ({
              name: brand,
              checked: false
            }))
          initializedFilters.brands.options = savedBrandsOptions
          filtersToApply = initializedFilters
        } else {
          filtersToApply = storedFilters
          if (
            storedFilters.brands.options.length !==
            response.filters.brands.length
          ) {
            let optionsCounter = 0
            const availableFiltersOptions: CustomerBookFilterOption[] =
              response.filters.brands.map((brand) => {
                const brandInStoredFilter = storedFilters.brands.options.find(
                  (storedBrandOption) => storedBrandOption.name === brand
                )
                const isChecked = brandInStoredFilter?.checked || false
                optionsCounter = isChecked ? optionsCounter + 1 : optionsCounter
                return {
                  name: brand,
                  checked: isChecked
                }
              })
            filtersToApply.brands.options = availableFiltersOptions
            filtersToApply.brands.selectedOptionsCounter = optionsCounter
          }
        }
        return {
          customers: response.customers,
          filters: filtersToApply,
          hasArchivedCustomers: response.hasArchivedCustomers
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setFilters({ filters: data.filters }))
        } catch (error) {
          console.error('error: ', error)
          // return error
        }
      }
    }),
    getArchivedCustomers: builder.query<
      { customers: ArchivedCustomerT[] },
      void
    >({
      query: () => {
        return {
          url: '/customers/archived',
          method: 'GET'
        }
      }
    }),
    getConsentedCustomer: builder.query<
      { customer: ConsentedCustomerT },
      GetConsentedCustomerQueryParamsT
    >({
      query: (arg) => {
        const {
          customerId,
          retrieveQualification,
          retrieveShoppedBrands,
          retrieveSavedSizes
        } = arg
        return {
          url: `/customers/${customerId}`,
          params: {
            'retrieve-qualification': retrieveQualification,
            'retrieve-shopped-brands': retrieveShoppedBrands,
            'retrieve-saved-sizes': retrieveSavedSizes
          },
          method: 'GET'
        }
      },
      transformResponse: (response: ConsentedCustomerT) => {
        return {
          customer: response
        }
      }
    }),
    getConsentedCustomerPurchaseHistory: builder.query<
      { numberOfTrips: number; orders: ConsentedCustomerOrderT[] },
      Pick<GetConsentedCustomerQueryParamsT, 'customerId'>
    >({
      query: (arg) => {
        const { customerId } = arg
        return {
          url: `/customers/${customerId}/purchase-history`,
          method: 'GET'
        }
      }
    }),
    activateEmployeeCommunications: builder.mutation<
      ActivateCommunicationsResponseT,
      Pick<ActivateCommunicationsResponseT, 'employeeId'>
    >({
      query: (arg) => {
        const { employeeId } = arg
        return {
          url: '/employees',
          method: 'POST',
          body: {
            employeeId
          }
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { employeeId } = args
        try {
          await queryFulfilled
          dispatch(
            clientelingApi.endpoints.userHasActiveCommunications.initiate(
              { employeeId },
              { forceRefetch: true }
            )
          )
        } catch (error) {
          // what to do with the error?
          console.log('error: ', error)
        }
      }
    }),
    userHasActiveCommunications: builder.query<
      { isActive: boolean },
      { employeeId: string }
    >({
      query: (arg) => {
        const { employeeId } = arg
        return {
          url: `/employees/${employeeId}/active`,
          method: 'GET'
        }
      },
      extraOptions: { maxRetries: 0 }
    }),
    getEmployee: builder.query<
      { employee: EmployeeT },
      GetEmployeeQueryParamsT
    >({
      query: (arg) => {
        const { employeeId } = arg
        return {
          url: `/employees/${employeeId}`,
          method: 'GET'
        }
      },
      transformResponse: (response: EmployeeT) => {
        return {
          employee: response
        }
      }
    }),
    getConsentedCustomerListWithoutFilters: builder.query<
      {
        customers: ConsentedCustomerListItemT[]
      },
      void
    >({
      query: () => {
        return {
          url: '/customers'
        }
      },
      transformResponse: (response: GetConsentedCustomerListResponseT) => {
        return {
          customers: response.customers
        }
      }
    }),
    updateCustomerArchiveStatus: builder.mutation<
      { relationshipCustomerId: string; archived: boolean },
      {
        relationshipCustomerId: string
        archived: boolean
      }
    >({
      query: (arg) => {
        const { relationshipCustomerId, archived } = arg
        return {
          url: `/relationships/customers/${relationshipCustomerId}`,
          method: 'PATCH',
          body: {
            archived
          }
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            clientelingApi.endpoints.getConsentedCustomerList.initiate(
              {
                retrieveFilters: true,
                fetchAdditionalCustomerIds: false,
                retrieveQualification: true
              },
              { forceRefetch: true }
            )
          )
          dispatch(
            clientelingApi.endpoints.getArchivedCustomers.initiate(undefined, {
              forceRefetch: true
            })
          )
        } catch (error) {
          console.log('error: ', error)
          throw error
        }
      }
    }),
    getCustomerByIdentifier: builder.mutation<
      CustomerByIdentifierT,
      GetCustomerQueryParams
    >({
      query: (arg) => {
        const { identifier, searchBy, retrieveQualification } = arg
        return {
          url: `consent/customers/${identifier}`,
          params: {
            searchBy,
            'retrieve-qualification': retrieveQualification
          },
          method: 'GET'
        }
      },
      extraOptions: { maxRetries: 0 }
    }),
    postInvitation: builder.mutation<
      void,
      { customerId: string; employeeId: string; inviteType: string }
    >({
      query: ({ customerId, employeeId, inviteType }) => {
        return {
          url: `consent/invitation`,
          method: 'POST',
          body: {
            customerId,
            employeeId,
            type: inviteType
          }
        }
      },
      extraOptions: { maxRetries: 0 }
    })
  })
})

export const {
  useGetConsentedCustomerListQuery,
  useLazyGetConsentedCustomerListQuery,
  useGetConsentedCustomerQuery,
  useGetConsentedCustomerPurchaseHistoryQuery,
  useActivateEmployeeCommunicationsMutation,
  useUserHasActiveCommunicationsQuery,
  useGetEmployeeQuery,
  useGetConsentedCustomerListWithoutFiltersQuery,
  useGetArchivedCustomersQuery,
  useUpdateCustomerArchiveStatusMutation,
  useGetCustomerByIdentifierMutation,
  usePostInvitationMutation
} = clientelingApi
