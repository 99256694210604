import { ReactElement } from 'react'

import { HoldsHandleCloseT } from 'pages/Holds/types'

import {
  HOLDS_MEDIA_SM_WIDTH_ADD_ITEM_AND_FIND_EXISTING_HOLD,
  HOLDS_TEXT_FIND_EXISTING_HOLD
} from 'pages/Holds/constants'

import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'

import HoldsFindByTicketNumber from './HoldsFindByTicketNumber'
import HoldsFindByPhoneNumber from './HoldsFindByPhoneNumber'

const HoldsFindExistingDialogBody = ({
  handleClose
}: HoldsHandleCloseT): ReactElement => {
  return (
    <>
      <HoldsDialogAppBar
        title={HOLDS_TEXT_FIND_EXISTING_HOLD}
        handleClose={handleClose}
        closeIconButtonProps={{
          'data-testid': 'back_arrow_find_existing'
        }}
      />

      <HoldsDialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: {
            sm: HOLDS_MEDIA_SM_WIDTH_ADD_ITEM_AND_FIND_EXISTING_HOLD
          }
        }}
      >
        <HoldsFindByPhoneNumber />
        <HoldsFindByTicketNumber />
      </HoldsDialogContent>
    </>
  )
}
export default HoldsFindExistingDialogBody
