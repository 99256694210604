import { ReactElement } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ChevronLeft } from '@mui/icons-material'

export type PropsT = {
  headerEndButton: ReactElement | null
  onClick: () => void
  showBackButton: boolean
  title: string
}

const DrawerNavigation = (props: PropsT): ReactElement => {
  const { headerEndButton, onClick, title, showBackButton } = props

  return (
    <Stack direction="row" alignContent="center" spacing={2} my={1}>
      <Box width="50px" display="flex" alignItems="center">
        <IconButton
          aria-label={showBackButton ? 'back' : 'close'}
          onClick={onClick}
        >
          {showBackButton ? (
            <ChevronLeft sx={{ color: 'common.black' }} />
          ) : (
            <CloseIcon sx={{ color: 'common.black' }} />
          )}
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center" flexGrow={1}>
        <Typography fontWeight="bold" variant="h6" component="h2">
          {title}
        </Typography>
      </Box>
      {headerEndButton}
    </Stack>
  )
}

export default DrawerNavigation
