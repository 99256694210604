import React, { ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { toLabel } from 'utils/toLabel'

type BaseDialogProps = Omit<
  React.ComponentProps<typeof Dialog>,
  'aria-modal' | 'aria-labelledby'
>

interface SortDialogAction {
  action: React.MouseEventHandler<HTMLButtonElement>
  selected?: boolean
  text: string
}

interface PropsT extends Omit<BaseDialogProps, 'aria-describedby'> {
  options: SortDialogAction[]
  open: boolean
  title: string
}

const SortListItem = (props: { option: SortDialogAction }): ReactElement => {
  const { option } = props
  return (
    <ListItem>
      <ListItemButton component={Button} onClick={option.action}>
        <ListItemText primary={option.text} />
        {option.selected && (
          <ListItemIcon
            sx={{
              justifyContent: 'flex-end'
            }}
          >
            <CheckIcon color="primary" />
          </ListItemIcon>
        )}
      </ListItemButton>
    </ListItem>
  )
}

const SortDialog = (props: PropsT): ReactElement => {
  const { options, open, title, ...baseDialogProps } = props
  return (
    <Dialog
      {...baseDialogProps}
      aria-describedby={`dialog_desc_${toLabel(title)}`}
      aria-modal={true}
      open={open}
      role="dialog"
    >
      <Paper sx={{ width: [311, 600] }}>
        <DialogTitle
          sx={{
            pb: 0
          }}
        >
          <strong>{title}</strong>
        </DialogTitle>
        <List>
          {options.map((option, index) => {
            return index ? (
              <React.Fragment key={index}>
                <Divider variant="middle" />
                <SortListItem option={option} />
              </React.Fragment>
            ) : (
              <SortListItem key={index} option={option} />
            )
          })}
        </List>
      </Paper>
    </Dialog>
  )
}

export default SortDialog
