import { ReactElement } from 'react'
import SubPageTemplateWithPageAppBar from 'components/SubPageTemplateWithPageAppBar'
import EditRequestsContent from './components/EditRequestsContent'

const EDIT_REQUESTS_TITLE = 'Edit requests'

const EditRequests = (): ReactElement => {
  return (
    <SubPageTemplateWithPageAppBar title={EDIT_REQUESTS_TITLE}>
      <EditRequestsContent />
    </SubPageTemplateWithPageAppBar>
  )
}

export default EditRequests
