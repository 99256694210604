import { ReactElement, MouseEventHandler, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import { useGetCustomerQuery } from 'services/employeeExperienceApi'
import { CurationT, AbandonCurationArgsT } from 'types/Curation'
import { skipToken } from '@reduxjs/toolkit/query/react'
import ResumeCurationButton from 'components/ResumeCurationButton'
import LoadingCurationCard from '../LoadingCurationCard'
import FinishedBoardButtons from '../FinishedBoardButtons'
import {
  generateNewRelicLogs,
  mapCurationToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import CurationCardContent from 'components/CurationCardContent'
import CurationViewsAndOrders from 'components/CurationViewsAndOrders'
import Skeleton from 'components/Skeleton'
import { determineCurationTitle } from 'utils/curationCardHelpers'

type CurationCardPropsT = {
  curation: CurationT
  isDraft: boolean
  hideMenu?: boolean
  isHomepageView?: boolean
  handleAbandon?: (abandonCurationArgs: AbandonCurationArgsT) => void
  handleOpenDeleteDraftDialog?: (dialogOpen: boolean) => void
  handleSetCurationIdToDelete?: (curationId: number) => void
}
type AnchorElementT = EventTarget & Element

type CurationCardHeaderPropsT = {
  handleOpenMenu: MouseEventHandler
}

const CurationCardHeaderActions = (props: CurationCardHeaderPropsT) => {
  const { handleOpenMenu } = props
  return (
    <IconButton
      onClick={handleOpenMenu}
      aria-label={'open-curation-card-menu-button'}
    >
      <MoreVertIcon />
    </IconButton>
  )
}

const CurationCard = (props: CurationCardPropsT): ReactElement => {
  const history = useHistory()
  const [menuAnchor, setMenuAnchor] = useState<AnchorElementT | undefined>(
    undefined
  )
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const {
    curation: {
      completedAt: curationCompletedAt,
      id: curationId,
      ocpId,
      title: curationTitle,
      products,
      updatedAt: curationUpdatedAt,
      views,
      orders,
      state: curationState
    },
    isDraft = false,
    hideMenu,
    isHomepageView,
    handleOpenDeleteDraftDialog,
    handleSetCurationIdToDelete
  } = props

  const { data, isLoading, isError } = useGetCustomerQuery(ocpId ?? skipToken)

  if (isLoading) {
    if (isHomepageView) {
      return (
        <Box width={'325px'}>
          <Skeleton.Image aspectRatio={2} height="180px" />
        </Box>
      )
    } else {
      return <LoadingCurationCard />
    }
  }

  const editCuration = () => {
    generateNewRelicLogs('editCurationClick', {
      ...mapCurationToNewRelicLog(props.curation),
      numberOfItemsInBag: products?.length
    })
    history.push({ pathname: `/styleboards/${curationId}/edit` })
  }

  const handleOpenMenu: MouseEventHandler = (event) => {
    setMenuAnchor(event.currentTarget)
    setIsMenuOpen(true)
  }

  const handleCloseMenu: MouseEventHandler = () => {
    setMenuAnchor(undefined)
    setIsMenuOpen(false)
  }

  const hasTitle = determineCurationTitle({
    curationTitle,
    curationState
  })

  return (
    <>
      <Box width={'100%'}>
        <Card
          variant={'outlined'}
          sx={{ width: isHomepageView ? '300px' : null }}
        >
          <CardHeader
            subheader={
              !ocpId
                ? isHomepageView
                  ? 'Public Board'
                  : 'Non-queue customer'
                : isError
                ? "Error getting customer's name"
                : data?.firstName + ' ' + data?.lastName
            }
            subheaderTypographyProps={{
              variant: 'body2',
              color: 'text.primary'
            }}
            title={
              determineCurationTitle({
                curationTitle,
                curationState
              }) || 'No title'
            }
            titleTypographyProps={{
              variant: 'subtitle2',
              fontWeight: 'bold',
              color: hasTitle ? 'text.primary' : 'text.secondary',
              ...(isHomepageView && {
                noWrap: true,
                textOverflow: 'ellipsis'
              })
            }}
            action={
              !hideMenu && (
                <CurationCardHeaderActions handleOpenMenu={handleOpenMenu} />
              )
            }
            sx={{
              '& .MuiCardHeader-content': {
                display: 'block',
                overflow: 'hidden'
              },
              pb: 0
            }}
          />
          <CardContent
            sx={{
              variant: 'body2',
              color: 'text.secondary',
              pt: 0
            }}
          >
            {
              <CurationCardContent
                isDraft={isDraft}
                curationUpdatedAt={curationUpdatedAt}
                curationCompletedAt={curationCompletedAt}
                products={products}
              />
            }
          </CardContent>
          <Stack direction="row">
            <CardContent sx={{ justifyContent: 'flex-start', width: '25%' }}>
              {!isDraft && (
                <CurationViewsAndOrders orders={orders} views={views} />
              )}
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'flex-end',
                width: '75%'
              }}
            >
              {!isDraft && <FinishedBoardButtons curationId={curationId} />}
              {isDraft && <ResumeCurationButton curationId={curationId} />}
            </CardActions>
          </Stack>
        </Card>
      </Box>
      {!hideMenu && (
        <Menu
          open={isMenuOpen}
          anchorEl={menuAnchor}
          onClose={handleCloseMenu}
          aria-label={'curation-card-menu'}
        >
          {!isDraft && (
            <MenuList>
              <MenuItem onClick={editCuration} aria-label={'edit-curation'}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>Edit</Typography>
                </ListItemText>
              </MenuItem>
            </MenuList>
          )}
          {isDraft &&
            handleSetCurationIdToDelete &&
            handleOpenDeleteDraftDialog && (
              <MenuList>
                <MenuItem
                  onClick={(_) => {
                    handleSetCurationIdToDelete(curationId)
                    handleOpenDeleteDraftDialog(true)
                    handleCloseMenu(_)
                  }}
                  aria-label={'delete-draft-curation-button'}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>Delete</Typography>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            )}
        </Menu>
      )}
    </>
  )
}

CurationCard.Loading = LoadingCurationCard

export default CurationCard
