import orderBy from 'lodash/fp/orderBy'
import groupBy from 'lodash/fp/groupBy'
import flow from 'lodash/fp/flow'
import intersection from 'lodash/fp/intersection'
import not from 'lodash/fp/negate'
import map from 'lodash/fp/map'
import reduceFp from 'lodash/fp/reduce'
import omitBy from 'lodash/fp/omitBy'
import filter from 'lodash/fp/filter'

import reduce from 'lodash/reduce'
import mapValues from 'lodash/mapValues'
import overEvery from 'lodash/overEvery'
import transform from 'lodash/transform'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import {
  ConsentedCustomerT,
  ConsentedCustomerListItemT,
  qualificationT,
  NordstromNoteT,
  ExtendedConsentedCustomerListItemT
} from 'types/ConsentedCustomer'
import {
  CustomerBookFilterKeys,
  CustomerBookFilters,
  CustomerBookFilterOption,
  CustomerBookNoteExpirationFilterCategories,
  AnniversaryEventFilterCategories,
  AnniversaryDateFilterCategories,
  CustomerBookLastMessageDateFilterCategories
} from 'types/CustomerBookFilters'
import { formatLoyaltyStatus } from 'pages/CustomerDetails/utils'
import { ConversationsRecord } from 'types/Twilio'
import { getConversationsWithCustomerIds } from 'services/twilioSlice/utils'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import { MAP_FILTER_INFO_TO_NR_EVENT } from '../constants'
import {
  NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS,
  NR_CUSTOMER_BOOK_FILTERS
} from '../../../constants/clienteling/newRelicEvents/nrCustomerBookPage'
import { NewRelicStylingAttributesT } from 'types/NewRelicAttributes'

const NO_CUSTOMER_ON_FILE_KEY = 'No customer name on file'

const firstNameToSort = (customer: ConsentedCustomerListItemT) =>
  customer.firstName ? customer.firstName.toLowerCase() : ''
const lastNameToSort = (customer: ConsentedCustomerListItemT) =>
  customer.lastName ? customer.lastName.toLowerCase() : ''
const groupingCriteria = (customer: ConsentedCustomerListItemT) =>
  customer.firstName && customer.lastName
    ? customer.firstName?.[0].toUpperCase()
    : NO_CUSTOMER_ON_FILE_KEY

export const mapCustomerListToSections = (
  customerList: ConsentedCustomerListItemT[]
): Record<string, ConsentedCustomerListItemT[]> =>
  flow(
    orderBy<ConsentedCustomerListItemT>(
      [firstNameToSort, lastNameToSort],
      ['asc', 'asc']
    ),
    groupBy<ConsentedCustomerListItemT>(groupingCriteria),
    (customersMap) => {
      const noCustomerOnFileData = customersMap[NO_CUSTOMER_ON_FILE_KEY]
      if (noCustomerOnFileData) {
        const orderedNoCustomerOnFileData = orderBy<ConsentedCustomerListItemT>(
          (customer) => customer.email
        )('asc')(noCustomerOnFileData)
        delete customersMap[NO_CUSTOMER_ON_FILE_KEY]
        customersMap[NO_CUSTOMER_ON_FILE_KEY] = orderedNoCustomerOnFileData
      }
      return customersMap
    }
  )(customerList)

export const mapCustomerListToDefault = (
  customerList: ConsentedCustomerListItemT[]
): Record<string, ConsentedCustomerListItemT[]> => ({
  default: customerList
})

export const filterByQuerySearch = (
  query: string,
  customers: ConsentedCustomerListItemT[]
): ConsentedCustomerListItemT[] =>
  customers.filter((item) => {
    const firstName = item.firstName?.toLowerCase() || ''
    const lastName = item.lastName?.toLowerCase() || ''
    const lowerCaseQuery = query.toLowerCase().replace(/\s/g, '') //remove the whitespace

    return (
      (firstName + lastName).includes(lowerCaseQuery) ||
      (lastName + firstName).includes(lowerCaseQuery) ||
      item.email?.includes(lowerCaseQuery) ||
      item.mobile?.includes(lowerCaseQuery)
    )
  })

export const shouldDisplayNewCustomerBadge: (
  customerCreationTime?: ConsentedCustomerT['relationshipDate']
) => boolean = (
  customerCreationTime?: ConsentedCustomerT['relationshipDate']
) => {
  if (!customerCreationTime) return false
  return moment(new Date()).diff(moment(customerCreationTime), 'days') < 8
}

const getEnabledFiltersOptions = (filters: CustomerBookFilters) => {
  const values = mapValues(filters, (filter) => {
    const checkedOptions = reduce(
      filter.options,
      (acc, option) => {
        if (option.checked) {
          acc.push(option.name)
        }
        return acc
      },
      [] as string[]
    )
    return checkedOptions
  })
  return values
}

export const filterAnniversaryEligible = (
  qualification?: qualificationT
): { eligibleEA: string; eligibleDate: string } => {
  let eligibleEA = AnniversaryEventFilterCategories.PUBLIC_SALE
  let eligibleDate = AnniversaryDateFilterCategories.PUBLIC_SALE
  if (qualification) {
    switch (true) {
      case qualification.staggeredEA1:
        eligibleEA = AnniversaryEventFilterCategories.ICON_EA
        eligibleDate = AnniversaryDateFilterCategories.ICON_EA
        break
      case qualification.staggeredEA2:
        eligibleEA = AnniversaryEventFilterCategories.AMBASSADOR_EA
        eligibleDate = AnniversaryDateFilterCategories.AMBASSADOR_EA
        break
      case qualification.staggeredEA3:
        eligibleEA = AnniversaryEventFilterCategories.INFLUENCER_EA
        eligibleDate = AnniversaryDateFilterCategories.INFLUENCER_EA
        break
      case qualification.staggeredEA4:
        eligibleEA = AnniversaryEventFilterCategories.ACCESS_PASS
        eligibleDate = AnniversaryDateFilterCategories.ACCESS_PASS
        break
      default:
        eligibleEA = AnniversaryEventFilterCategories.PUBLIC_SALE
        eligibleDate = AnniversaryDateFilterCategories.PUBLIC_SALE
        break
    }
  }
  return { eligibleEA, eligibleDate }
}

export const countExpiringNotesPerCategory = (
  notes: NordstromNoteT[]
): Record<string, number> =>
  flow(
    filter<NordstromNoteT>((note) => note.noteState === 'Active'),
    map((note) => moment(note.expirationDate).diff(moment(new Date()), 'days')),
    filter((daysUntilExpiration) => daysUntilExpiration >= 0),
    reduceFp(
      (acc, due) => {
        if (due <= 15) {
          acc[CustomerBookNoteExpirationFilterCategories.TWO_WEEKS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.ONE_MONTH] += 1
          acc[CustomerBookNoteExpirationFilterCategories.THREE_MONTHS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        } else if (due <= 30) {
          acc[CustomerBookNoteExpirationFilterCategories.ONE_MONTH] += 1
          acc[CustomerBookNoteExpirationFilterCategories.THREE_MONTHS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        } else if (due <= 90) {
          acc[CustomerBookNoteExpirationFilterCategories.THREE_MONTHS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        } else {
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        }

        return acc
      },
      {
        [CustomerBookNoteExpirationFilterCategories.TWO_WEEKS]: 0,
        [CustomerBookNoteExpirationFilterCategories.ONE_MONTH]: 0,
        [CustomerBookNoteExpirationFilterCategories.THREE_MONTHS]: 0,
        [CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES]: 0
      }
    ),
    omitBy((count) => count === 0)
  )(notes) as Record<string, number>

const getLastMessageFilterWithCounters = (lastMessageDate: string) => {
  const lastMessageMoment = moment(new Date(lastMessageDate))
  const now = moment(new Date())
  const datesDiffInMonths = now.diff(lastMessageMoment, 'months', true)
  const filterOptionsWithCounter = Object.values(
    CustomerBookLastMessageDateFilterCategories
  ).reduce((acc, category) => {
    acc[category] = 0
    return acc
  }, {} as Record<CustomerBookLastMessageDateFilterCategories, number>)

  if (!lastMessageDate) {
    filterOptionsWithCounter[
      CustomerBookLastMessageDateFilterCategories.NO_MESSAGES
    ] += 1
  } else {
    if (datesDiffInMonths <= 1) {
      filterOptionsWithCounter[
        CustomerBookLastMessageDateFilterCategories.ONE_MONTH
      ] += 1
    }
    if (datesDiffInMonths > 1) {
      filterOptionsWithCounter[
        CustomerBookLastMessageDateFilterCategories.MORE_THAN_ONE_MONTH
      ] += 1
    }
    if (datesDiffInMonths > 3) {
      filterOptionsWithCounter[
        CustomerBookLastMessageDateFilterCategories.MORE_THAN_THREE_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 6) {
      filterOptionsWithCounter[
        CustomerBookLastMessageDateFilterCategories.MORE_THAN_SIX_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 9) {
      filterOptionsWithCounter[
        CustomerBookLastMessageDateFilterCategories.MORE_THAN_NINE_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 12) {
      filterOptionsWithCounter[
        CustomerBookLastMessageDateFilterCategories.MORE_THAN_TWELVE_MONTHS
      ] += 1
    }
  }
  return filterOptionsWithCounter
}

const valueIsIncluded = (
  options: string[],
  value: string | boolean,
  filterKey: CustomerBookFilterKeys
) => {
  let returnValue = ''
  switch (filterKey) {
    case CustomerBookFilterKeys.NORDY_CLUB:
      returnValue = value
        ? formatLoyaltyStatus(value as string)
        : 'No loyalty status'
      break
    case CustomerBookFilterKeys.CARDMEMBER:
      returnValue = value ? 'Yes' : 'No'
      break
    default:
      returnValue = value as string
  }
  return Array.isArray(options) && options.includes(returnValue)
}

const createFilterCriteriaEvaluator =
  (options: string[], filterKey: CustomerBookFilterKeys) =>
  (customer: ExtendedConsentedCustomerListItemT) => {
    const valueToEvaluate = customer[filterKey]
    const getEnabledOptions = (values: string[]) => {
      const intersectionValue = intersection(options)(values)
      return intersectionValue
    }
    const isIncludedInSelectedFilterOptions = (
      value: typeof valueToEvaluate
    ) => {
      if (Array.isArray(valueToEvaluate)) {
        if (filterKey === CustomerBookFilterKeys.NORDSTROM_NOTES) {
          return flow(
            countExpiringNotesPerCategory,
            Object.keys,
            getEnabledOptions,
            not(isEmpty)
          )((value as NordstromNoteT[]) ?? [])
        } else {
          return flow(getEnabledOptions, not(isEmpty))(value as string[])
        }
      } else {
        if (filterKey === CustomerBookFilterKeys.ANNIVERSARY) {
          const valueAnniversary = filterAnniversaryEligible(
            valueToEvaluate as qualificationT
          ).eligibleEA
          return valueIsIncluded(options, valueAnniversary, filterKey)
        } else if (filterKey === CustomerBookFilterKeys.LAST_MESSAGE_TIME) {
          const filterOptionsWithCounters = getLastMessageFilterWithCounters(
            valueToEvaluate as string
          )
          const onlyEnabledOption = getEnabledOptions(
            Object.keys(filterOptionsWithCounters)
          )[0]
          const hasEnabledOptionResults =
            filterOptionsWithCounters[
              onlyEnabledOption as CustomerBookLastMessageDateFilterCategories
            ] > 0
          return hasEnabledOptionResults
        } else {
          return valueIsIncluded(
            options,
            valueToEvaluate as string | boolean,
            filterKey
          )
        }
      }
    }

    return isIncludedInSelectedFilterOptions(valueToEvaluate)
  }

export const getFilteredCustomers: (
  customers: ConsentedCustomerListItemT[],
  filters: CustomerBookFilters,
  conversations?: ConversationsRecord
) => ConsentedCustomerListItemT[] = (
  customers: ConsentedCustomerListItemT[],
  filters: CustomerBookFilters,
  conversations?: ConversationsRecord
) => {
  const enabledFilterOptions = getEnabledFiltersOptions(filters)
  const filterCriteriaEvaluators = transform(
    enabledFilterOptions,
    (evaluators, options, filterId) => {
      if (options.length === 0) return
      evaluators.push(
        createFilterCriteriaEvaluator(
          options,
          filterId as CustomerBookFilterKeys
        )
      )
    },
    [] as ((customer: ConsentedCustomerListItemT) => boolean)[]
  )
  let customersToFilter: ExtendedConsentedCustomerListItemT[] = customers
  if (conversations) {
    customersToFilter = getConversationsWithCustomerIds(
      conversations,
      customers
    )
  }
  return customersToFilter.filter((customer) =>
    overEvery(filterCriteriaEvaluators)(customer)
  )
}

export const optionsFilterByQuerySearch = (
  query: string,
  options: CustomerBookFilterOption[]
): CustomerBookFilterOption[] =>
  options.filter((item) => item.name?.toLowerCase().includes(query))

export const logAppliedFilterNREvents = ({
  customerBookFilters,
  generateNewRelicLogs
}: {
  customerBookFilters: CustomerBookFilters
  generateNewRelicLogs: (
    nrEvent:
      | typeof NR_CUSTOMER_BOOK_FILTERS[keyof typeof NR_CUSTOMER_BOOK_FILTERS]
      | typeof NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS[keyof typeof NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS],
    nrEventAttribute: NewRelicStylingAttributesT
  ) => void
}): void[] =>
  (Object.keys(customerBookFilters) as CustomerBookFilterKeys[]).map(
    (filterKey) => {
      const checkedOptions = customerBookFilters[filterKey].options.filter(
        (option) => option.checked
      )

      if (checkedOptions.length > 0) {
        const filterType = MAP_FILTER_INFO_TO_NR_EVENT[filterKey].filterType
        const nrEventAttributes = {
          page: NR_CLIENTELING_PAGES.CUSTOMER_BOOK_PAGE,
          customerFilterType: filterType
        }
        const selectionType =
          MAP_FILTER_INFO_TO_NR_EVENT[filterKey].selectionType
        generateNewRelicLogs(filterType, nrEventAttributes)

        checkedOptions.map((option) => {
          generateNewRelicLogs(selectionType, {
            ...nrEventAttributes,
            customerFilterSelection: option.name
          })
        })
      }
    }
  )
