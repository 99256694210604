import { CURATION_STATE } from 'types/Curation'
import { useGetCurationsQuery } from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  CardActions
} from '@mui/material'
import FlareIcon from '@mui/icons-material/Flare'
import { Link } from 'react-router-dom'
import { isUSSeller } from 'utils/userPermissions'

const PersonalRequests = (): JSX.Element => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const { data: getRequestedCurationsData } = useGetCurationsQuery({
    state: CURATION_STATE.REQUESTED,
    preferredEmployeeId: employeeId,
    perPage: 0,
    page: 1
  })

  const { data: getInProgressCurationsData } = useGetCurationsQuery({
    state: CURATION_STATE.IN_PROGRESS,
    employeeId,
    preferredEmployeeId: employeeId
  })

  const count =
    getInProgressCurationsData && getRequestedCurationsData
      ? getRequestedCurationsData.meta.count +
        getInProgressCurationsData.meta.count
      : null

  if (!count && isUSSeller()) return <></>

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <CardContent>
        <Box display="flex" mt={1} flexDirection="row">
          <Box
            display="flex"
            height={40}
            width={40}
            alignItems="center"
            justifyContent="center"
            bgcolor="#FAAF0014"
            borderRadius={50}
            mr={2}
          >
            <FlareIcon sx={{ fill: '#FAAF00' }} />
          </Box>
          <Box>
            <Typography variant="body2">Your requests</Typography>
            <Typography component={Box} variant="h6">
              {count}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <Button
          component={Link}
          to="/stylist/requests"
          variant="outlined"
          data-testid="personal-requests-view-all"
        >
          View All
        </Button>
      </CardActions>
    </Card>
  )
}

export default PersonalRequests
