import React, { ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import { toLabel } from 'utils/toLabel'

type BaseDialogProps = Omit<
  React.ComponentProps<typeof Dialog>,
  'aria-modal' | 'aria-labelledby'
>

interface PropsT extends Omit<BaseDialogProps, 'aria-describedby'> {
  cancel: () => void
  contextText: ReactElement
  isLoading?: boolean
  open: boolean
  title: string
}

const BaseInformationDialoag = (props: PropsT): ReactElement => {
  const { cancel, contextText, open, title, ...baseDialogProps } = props

  return (
    <Dialog
      {...baseDialogProps}
      aria-describedby={`dialog_desc_${toLabel(title)}`}
      aria-modal={true}
      open={open}
      role="alertdialog"
    >
      <DialogContent>
        <DialogContentText
          color="text.secondary"
          id={`dialog_desc_${toLabel(title)}`}
          sx={{ mt: 5.5 }}
        >
          {contextText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BaseInformationDialoag
