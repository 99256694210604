import { ReactElement, useEffect, useState } from 'react'

import { Box, Button, CircularProgress } from '@mui/material'

import { HoldT } from 'types/Holds'
import { HoldsHandleCloseT } from 'pages/Holds/types'

import {
  HOLDS_API_PARAMS_GET_ACTIVE_LIST,
  HOLDS_API_PARAMS_GET_COMPLETED_LIST
} from 'pages/Holds/constants'

import isHoldStatusIncludesInRequestedStatuses from 'pages/Holds/utils/isHoldStatusIncludesInRequestedStatuses'

import { useFetchGetHoldsByEmployeeId } from 'pages/Holds/hooks'

import BoxWithGladlySpace from 'components/BoxWithGladlySpace'

import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'
import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsProductItemCards from 'pages/Holds/shared/components/HoldsProductItemCards'

const HoldsDialogBody = ({
  handleClose,
  title,
  requestParams
}: HoldsHandleCloseT &
  (
    | {
        title: 'Active'
        requestParams: typeof HOLDS_API_PARAMS_GET_ACTIVE_LIST
      }
    | {
        title: 'Inactive'
        requestParams: typeof HOLDS_API_PARAMS_GET_COMPLETED_LIST
      }
  )): ReactElement => {
  const [offsetShiftDecrement, setOffsetShiftDecrement] = useState(0)

  const {
    holds,
    setHolds,
    isLoading,
    handleLoadMore,
    fetchGetHoldsByEmployeeIdCallback
  } = useFetchGetHoldsByEmployeeId({
    requestParams,
    offsetShiftDecrement,
    withLoadMore: true
  })

  const holdsLengthWithRequestedStatuses =
    holds.data.length - offsetShiftDecrement
  const holdsDeltaRemains = holds.total - holdsLengthWithRequestedStatuses
  const loadQuantityRemains = holdsDeltaRemains - offsetShiftDecrement

  useEffect(() => {
    fetchGetHoldsByEmployeeIdCallback()
  }, [fetchGetHoldsByEmployeeIdCallback])

  return (
    <>
      <HoldsDialogAppBar title={`${title} holds`} handleClose={handleClose} />

      <HoldsDialogContent>
        <BoxWithGladlySpace>
          {isLoading && !holds.data.length && (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={32} />
            </Box>
          )}

          <HoldsProductItemCards
            holds={holds.data}
            handleUpdateDetails={(hold: HoldT) => {
              const holdStatusIncludesInRequestedStatuses =
                isHoldStatusIncludesInRequestedStatuses({
                  requestParams,
                  hold
                })

              if (!holdStatusIncludesInRequestedStatuses) {
                setOffsetShiftDecrement((prevState) => prevState + 1)
              }

              setHolds((prevState) => {
                const cloneData = [...prevState.data]

                const index = cloneData.findIndex(
                  (productItem) =>
                    productItem.ticketNumber === hold.ticketNumber
                )

                cloneData[index] = hold

                return {
                  data: cloneData,
                  total:
                    holds.total -
                    (holdStatusIncludesInRequestedStatuses ? 0 : 1)
                }
              })
            }}
          />

          {loadQuantityRemains > 0 && (
            <Button
              fullWidth
              variant="text"
              onClick={handleLoadMore}
              disabled={isLoading}
              sx={{
                mt: 2
              }}
            >
              {isLoading ? (
                <CircularProgress size={28} />
              ) : (
                <>Load {loadQuantityRemains} more</>
              )}
            </Button>
          )}
        </BoxWithGladlySpace>
      </HoldsDialogContent>
    </>
  )
}

export default HoldsDialogBody
