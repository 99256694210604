import { ShopButtonTextOptionsT } from 'components/ShopButton/ShopButton'

const determineShopButtonText = ({
  isShoppingTokenExpired,
  shoppingToken,
  isEdit = false
}: {
  isShoppingTokenExpired: boolean
  shoppingToken: string | null
  isEdit?: boolean
}): ShopButtonTextOptionsT => {
  if (isShoppingTokenExpired) {
    return 'Renew Session'
  }
  if (!shoppingToken) {
    return 'Browse Nordstrom.com'
  }
  if (isEdit) {
    return 'Add more from Nordstrom.com'
  }
  return 'Continue Shopping'
}

export default determineShopButtonText
