import { configureStore } from '@reduxjs/toolkit'
import Reactotron from '../ReactotronConfig'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { curationApi } from '../services/curationSvc'
import { dcapiApi } from '../services/dcapiService'
import { clientelingApi } from '../services/clientelingApi'
import { stylistProfileApi } from 'services/stylistProfileService'
import { customerSlice } from 'app/customerSlice'
import { employeeSlice } from 'app/employeeSlice'
import { configmanApi } from 'services/configmanApi'
import { salesApi } from 'services/salesApi'
import { legacyONSalesApi } from 'services/legacyONSalesApi'
import { customerBookFiltersSlice } from 'services/customerBookFiltersSlice'
import twilioSlice from 'services/twilioSlice'
import { twilioApi } from 'services/twilioApi'
import { notificationsApi } from 'services/notificationsApi'
import { employeeExperienceApi } from 'services/employeeExperienceApi'
import { holdsApi } from '../services/holdsApi'
import { curationSlice } from './curationSlice'
import { appointmentsService } from 'services/appointmentsService'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const reactotronEnhancer = Reactotron.createEnhancer?.()

export const store = configureStore({
  // add any new api reducerPaths here as well as to `storeSpy.ts`
  // the `storeSpy()` is used in our test setup in the <Provider /> components
  reducer: {
    [curationApi.reducerPath]: curationApi.reducer,
    [dcapiApi.reducerPath]: dcapiApi.reducer,
    [configmanApi.reducerPath]: configmanApi.reducer,
    [clientelingApi.reducerPath]: clientelingApi.reducer,
    [stylistProfileApi.reducerPath]: stylistProfileApi.reducer,
    [salesApi.reducerPath]: salesApi.reducer,
    [legacyONSalesApi.reducerPath]: legacyONSalesApi.reducer,
    [customerSlice.name]: customerSlice.reducer,
    [employeeSlice.name]: employeeSlice.reducer,
    [curationSlice.name]: curationSlice.reducer,
    [customerBookFiltersSlice.name]: customerBookFiltersSlice.reducer,
    [twilioSlice.name]: twilioSlice.reducer,
    [twilioApi.reducerPath]: twilioApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [employeeExperienceApi.reducerPath]: employeeExperienceApi.reducer,
    [holdsApi.reducerPath]: holdsApi.reducer,
    [appointmentsService.reducerPath]: appointmentsService.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(curationApi.middleware)
      .concat(dcapiApi.middleware)
      .concat(configmanApi.middleware)
      .concat(clientelingApi.middleware)
      .concat(stylistProfileApi.middleware)
      .concat(salesApi.middleware)
      .concat(legacyONSalesApi.middleware)
      .concat(twilioApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(employeeExperienceApi.middleware)
      .concat(holdsApi.middleware)
      .concat(appointmentsService.middleware),
  enhancers: reactotronEnhancer ? [reactotronEnhancer] : []
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
