import { ReactElement } from 'react'
import { Box, Typography, Button } from '@mui/material'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import Resizer from 'react-image-file-resizer'
import { UploadedAttachedMedia } from 'types/Twilio'
import { generateMediaOptions } from 'services/twilioSlice/utils'
import { colors } from 'pages/CustomerMessages/constants'

type PropsT = {
  maxImages: number
  setUploadedImage: (images: UploadedAttachedMedia, index: number) => void
  setErrorMessage: (isOpen: string) => void
  uploadedImages: UploadedAttachedMedia[]
  isDisabled: boolean
  setIsDisabled: (isDisabled: boolean) => void
  isMultipleImagesToTextEnabled: boolean
}

const RESIZED_MAX_WIDTH = 600
const RESIZED_QUALITY = 100
const RESIZED_ROTATION = 0

const resizeFile = (
  file: File
): Promise<string | File | Blob | ProgressEvent<FileReader>> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      RESIZED_MAX_WIDTH,
      RESIZED_MAX_WIDTH,
      'JPEG',
      RESIZED_QUALITY,
      RESIZED_ROTATION,
      async (uri) => {
        const response = await fetch(uri as string)
        const blob = await response.blob()
        const newFile = new File([blob], file.name, { type: 'image/jpeg' })
        resolve(newFile)
      },
      'base64'
    )
  })

const AttachImageButton = ({
  maxImages,
  setUploadedImage,
  setErrorMessage,
  uploadedImages,
  isDisabled,
  setIsDisabled,
  isMultipleImagesToTextEnabled
}: PropsT): ReactElement => {
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorMessage('')

    const files = event.target.files ?? null

    // NOTE: Max total upload is 5MB, but messaging providers have their own limits
    // thus, it was decided to set our media size limit to 1MB
    // 1000000 bytes = 1 MB
    const maxFileSize = 1000000

    if (files) {
      const imagesToUploadLength = files.length + uploadedImages.length

      if (imagesToUploadLength > maxImages) {
        setErrorMessage(`Max ${maxImages} Attachments`)
        return
      }

      if (imagesToUploadLength === maxImages) {
        setIsDisabled(true)
      }

      try {
        await Promise.all(
          Array.from(files).map(async (file, index) => {
            setUploadedImage(
              { isLoading: true },
              isMultipleImagesToTextEnabled ? index + uploadedImages.length : 0
            )

            if (file.size > maxFileSize) {
              const resizedFile = (await resizeFile(file)) as File
              file = resizedFile
            }

            const imageFileDetails = await generateMediaOptions(file)

            if (imageFileDetails) {
              setUploadedImage(
                { media: imageFileDetails, isLoading: false },
                isMultipleImagesToTextEnabled
                  ? index + uploadedImages.length
                  : 0
              )
            }
          })
        )
      } catch {
        setErrorMessage('try again')
      }
    }
  }

  return (
    <Button
      disabled={isDisabled}
      sx={{
        padding: 0
      }}
    >
      <label htmlFor="html-attach-image-button" style={{ display: 'flex' }}>
        <AddPhotoAlternateIcon
          sx={{ color: isDisabled ? colors.disabled : colors.secondary }}
        />
        <Typography
          fontSize={16}
          lineHeight="22px"
          letterSpacing="0.4px"
          fontWeight="bold"
          color={isDisabled ? colors.disabled : colors.secondary}
        >
          Attach image
        </Typography>
      </label>
      <Box
        sx={{
          opacity: 0,
          position: 'absolute',
          display: 'flex'
        }}
      >
        <input
          type="file"
          multiple={isMultipleImagesToTextEnabled}
          accept="image/jpeg, image/jpg, image/gif, image/png, image/bmp, image/tiff"
          // source: https://www.twilio.com/docs/messaging/guides/accepted-mime-types
          onChange={(event) => handleImageUpload(event)}
          onClick={(event) => {
            ;(event.target as HTMLInputElement).value = ''
          }}
          id="html-attach-image-button"
          onError={() => {
            setErrorMessage('try again')
          }}
          data-testid="input-file-upload"
          style={{ width: '125px', padding: '8px 2px' }}
        />
      </Box>
    </Button>
  )
}

export default AttachImageButton
