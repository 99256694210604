import BaseDialog from 'components/BaseDialog'
import { ReactElement } from 'react'

interface PropsT {
  handleAbandon: () => void
  open: boolean
  setIsDeleteDraftDialogOpen: (arg: boolean) => void
}

const DeleteDraftDialog = (props: PropsT): ReactElement => {
  const { handleAbandon, open, setIsDeleteDraftDialogOpen } = props

  return (
    <BaseDialog
      title={'Are you sure you want to delete this draft?'}
      contentText={'This board will not be finished and will be deleted.'}
      open={open}
      onClose={() => setIsDeleteDraftDialogOpen(false)}
      confirm={{
        action: () => {
          handleAbandon()
          setIsDeleteDraftDialogOpen(false)
        },
        text: 'Yes, Delete'
      }}
      cancel={{
        action: () => setIsDeleteDraftDialogOpen(false),
        text: 'Nevermind'
      }}
    />
  )
}

export default DeleteDraftDialog
