import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, Typography, useTheme } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import { useContext, useEffect, useState } from 'react'
import { useGenerateSeedItemRecommendationsMutation } from 'services/employeeExperienceApi'
import { CurationItemT } from 'types/Curation'
import CategoryCarousel from './CategoryCarousel'
import { SET_SEED_ITEM_RECS_TERMS } from 'pages/CurationReview/constants'
import {
  CurationReviewDispatchContext,
  usePersistedCurationReviewReducer
} from '../CurationReviewContent/CurationReviewReducer'
import { emDashCharacter } from 'pages/constants'

export const RECS_ERROR_TITLE = `Issue getting recommendations ${emDashCharacter}`

const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

interface PropsT {
  seedItem?: CurationItemT
  onClickForPDP: (webStyleId: string) => void
  curationId: number
}

const ItemRecommendations = ({
  seedItem,
  onClickForPDP,
  curationId
}: PropsT): JSX.Element => {
  const theme = useTheme()
  const [tabPosition, setTabPosition] = useState('0')
  const [
    generateCategoriesAndTerms,
    { data, isLoading, error, endpointName, isError }
  ] = useGenerateSeedItemRecommendationsMutation()
  const dispatch = useContext(CurationReviewDispatchContext)
  const [state] = usePersistedCurationReviewReducer()

  useEffect(() => {
    const noRecsStored =
      state.itemRecommendations.itemRecommendationTerms?.recommendations
        ?.length === 0
    const isSeedItemUpdated =
      seedItem &&
      seedItem.webStyleId !== state.itemRecommendations.seedItemWebStyleId

    if (seedItem && (noRecsStored || isSeedItemUpdated)) {
      generateCategoriesAndTerms({ curationId, seedItemData: seedItem })
        .unwrap()
        .then((response) => {
          dispatch &&
            dispatch({
              type: SET_SEED_ITEM_RECS_TERMS,
              payload: {
                itemRecommendationTerms: response,
                webStyleId: seedItem.webStyleId
              }
            })
          return
        })
        .catch(() => null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curationId, seedItem, generateCategoriesAndTerms])

  useEffect(() => {
    if (tabPosition !== '0') setTabPosition('0')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedItem])

  if (!seedItem) {
    return <></>
  }

  const recommendationTerms =
    state.itemRecommendations.itemRecommendationTerms?.recommendations?.length >
    0
      ? state.itemRecommendations.itemRecommendationTerms
      : data

  return (
    <Box p={2} mb={3} sx={{ backgroundColor: theme.palette.grey[50] }}>
      <Typography variant="subtitle2" fontWeight={'bold'}>
        Complete the look
      </Typography>
      <Typography variant="subtitle2" fontWeight={'regular'}>
        Add items to round out this look
      </Typography>
      {isLoading && <LoadingSpinner hideText={true} />}
      {isError && (
        <PageError
          errorTitle={RECS_ERROR_TITLE}
          errorDetails={{ endpoint: endpointName, errorData: error }}
          refreshAction={() =>
            generateCategoriesAndTerms({ curationId, seedItemData: seedItem })
          }
        />
      )}
      {recommendationTerms && recommendationTerms.recommendations && (
        <TabContext value={tabPosition}>
          <TabList
            onChange={(_e, value) => setTabPosition(value)}
            variant="scrollable"
            sx={{ mb: 2 }}
          >
            {recommendationTerms.recommendations.map((rec, index) => (
              <Tab
                key={index}
                value={index.toString()}
                label={capitalize(rec.category)}
              />
            ))}
          </TabList>
          {recommendationTerms.recommendations.map((rec, index) => (
            <TabPanel key={index} value={index.toString()} sx={{ p: 0 }}>
              <CategoryCarousel
                recommendation={rec}
                onClickForPDP={onClickForPDP}
                curationId={curationId}
                productGender={seedItem.productGender}
              />
            </TabPanel>
          ))}
        </TabContext>
      )}
    </Box>
  )
}

export default ItemRecommendations
