//https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900

export const formatCurationViewCount = (views: number | undefined): string => {
  const numberOfViews = Number(views)

  switch (true) {
    case !views: {
      return '0'
    }
    case numberOfViews > 999999: {
      return `${Math.floor(Math.abs(numberOfViews) / 1000000)}` + 'm'
    }
    case numberOfViews > 9999: {
      return `${Math.floor(Math.abs(numberOfViews) / 1000)}` + 'k'
    }
    default: {
      return `${Math.abs(numberOfViews)}`
    }
  }
}
