export const formatPhoneNumber = (phoneInput: string): string => {
  if (phoneInput[0] === '+') {
    phoneInput = phoneInput.substring(2)
  }

  if (phoneInput.length === 10) {
    return phoneInput.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }

  return phoneInput
}
