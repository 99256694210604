import { ReactElement, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  DialogTitle,
  Fade,
  IconButton,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'

import get from 'lodash/fp/get'
import {
  HoldsCustomerInfoT,
  HoldsEnumCustomerType,
  HoldsHandleSubmitT,
  HoldsOpenDialogT
} from 'pages/Holds/types'

import convertHoldMobileToDigits from 'pages/Holds/utils/convertHoldMobileToNumber'

import { useGetCustomerByMobileMutation } from 'services/holdsApi'

import BasePageAlert from 'components/BasePageAlert'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'

import {
  HOLDS_BACKGROUND_COLOR_DIVIDER,
  HOLDS_COLOR_DIVIDER,
  HOLDS_MEDIA_SM_WIDTH_CUSTOMER_FOUND_AND_REPRINT,
  HOLDS_TEST_CUSTOMER_PROFILE_INCORRECT,
  HOLDS_TEXT_CUSTOMER_RESULTS
} from 'pages/Holds/constants'

import { useHoldsToggle } from 'pages/Holds/hooks'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'

import { HoldsTextFieldMobileNumber } from './components'

const HoldsButtonCustomerAddLookupHold = ({
  customerInfo,
  handleSubmit,
  dialogType,
  setDialogType
}: {
  customerInfo: HoldsCustomerInfoT
} & HoldsHandleSubmitT &
  HoldsOpenDialogT): ReactElement => {
  const {
    isOpen: isOpenConfirmationDialog,
    handleOpen,
    handleClose
  } = useHoldsToggle()

  const [
    fetchGetCustomerByMobile,
    { isLoading, isError, error, originalArgs, data }
  ] = useGetCustomerByMobileMutation()

  const isOpenDialog = dialogType === HoldsEnumCustomerType.LOOK_UP

  const isErrorNotFoundMobileNumber =
    isError &&
    get('status', error) === 404 &&
    get('data.code', error) === 'NOT_FOUND'

  const isErrorGeneral = isError && !isErrorNotFoundMobileNumber

  const [showErrorHelperText, setShowErrorHelperText] = useState(false)
  const [mobileNumber, setMobileNumber] = useState(
    customerInfo.customerId ? customerInfo.customerMobileNumber : ''
  )
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  const isFilledMobileNumber = mobileNumber.length === 16 // with 6 extra symbols
  const allowLookUp = !isLoading && isFilledMobileNumber

  const showErrorForMobileNumber = showErrorHelperText && !isFilledMobileNumber

  const onClickSubmit = async () => {
    setShowErrorHelperText(true)

    if (!allowLookUp) {
      return
    }

    try {
      const response = await fetchGetCustomerByMobile({
        phoneNumber: convertHoldMobileToDigits(mobileNumber)
      }).unwrap()

      if (response.customerId) {
        handleOpen()
      }
    } catch (e) {
      return
    }
  }

  const customerName = [data?.firstName, data?.lastName]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      {isOpenDialog && (
        <Fade in={isOpenDialog}>
          <Box mb={2}>
            <Typography variant="body2" color="black">
              Find and add an existing Nordstrom customer by entering their
              mobile number.
            </Typography>

            <Box mt={2.5}>
              {isErrorNotFoundMobileNumber && (
                <BasePageAlert
                  alertTitle="Customer not found"
                  errorMessage="Check the number is correct or try another customer mobile number"
                />
              )}
              {isErrorGeneral && (
                <BasePageAlert
                  alertTitle={`Issue ${
                    get('status', error) === 500 ? 'searching for' : 'getting'
                  } customer results - try again`}
                  errorDetails={{
                    errorData: error,
                    originalArgs
                  }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                />
              )}

              <HoldsTextFieldMobileNumber
                size="medium"
                value={mobileNumber}
                onChange={setMobileNumber}
                isLoading={isLoading}
                error={isErrorNotFoundMobileNumber || showErrorForMobileNumber}
                focused={isErrorGeneral ? true : undefined}
                helperText={
                  showErrorForMobileNumber && 'Mobile number required'
                }
              />
            </Box>
          </Box>
        </Fade>
      )}

      <Button
        fullWidth
        variant={isOpenDialog ? 'contained' : 'outlined'}
        onClick={
          isOpenDialog
            ? onClickSubmit
            : () => setDialogType(HoldsEnumCustomerType.LOOK_UP)
        }
        disabled={isOpenDialog && isLoading}
      >
        {isLoading ? (
          <CircularProgress size={28} />
        ) : (
          'Find Customer by Phone Number'
        )}
      </Button>

      {isCopyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}

      <HoldsDialog
        isDrawerMode
        open={isOpenConfirmationDialog}
        onClose={handleClose}
        sx={{
          pt: {
            xs: '25dvh'
          }
        }}
      >
        <HoldsDialogAppBar
          title={HOLDS_TEXT_CUSTOMER_RESULTS}
          handleClose={handleClose}
          closeIconButtonProps={{
            children: <CloseIcon sx={{ color: 'text.primary' }} />
          }}
          sx={{
            display: {
              xs: 'none',
              sm: 'flex'
            },
            mb: 2
          }}
        />

        <DialogTitle
          sx={{
            display: {
              xs: 'flex',
              sm: 'none'
            },
            justifyContent: 'flex-end',
            px: 2
          }}
        >
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <HoldsDialogContent
          sx={{
            width: {
              sm: HOLDS_MEDIA_SM_WIDTH_CUSTOMER_FOUND_AND_REPRINT
            }
          }}
        >
          <Alert
            sx={{
              pb: 0
            }}
          >
            <AlertTitle>Customer found</AlertTitle>
          </Alert>

          <Box
            sx={{
              p: 2,
              mt: 3,
              backgroundColor: HOLDS_BACKGROUND_COLOR_DIVIDER
            }}
          >
            <Box display="grid" gap={0.5}>
              <Typography component="h1" variant="h6">
                {customerName}
              </Typography>
              <Typography variant="body2">{mobileNumber}</Typography>
            </Box>

            <Box display="flex" justifyContent="center" mt={4} mb={2}>
              <Button
                data-testid="add_customer_confirmation"
                variant="contained"
                onClick={() => {
                  handleSubmit(
                    {
                      customerId: data?.customerId,
                      customerName,
                      customerMobileNumber: mobileNumber
                    },
                    HoldsEnumCustomerType.LOOK_UP
                  )

                  handleClose()
                }}
              >
                Add customer
              </Button>
            </Box>

            <Typography variant="body2" color={HOLDS_COLOR_DIVIDER}>
              {HOLDS_TEST_CUSTOMER_PROFILE_INCORRECT}
            </Typography>
          </Box>
        </HoldsDialogContent>
      </HoldsDialog>
    </>
  )
}

export default HoldsButtonCustomerAddLookupHold
