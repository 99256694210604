import { ReactElement } from 'react'
import { Box, ListItem, ListItemText, Typography } from '@mui/material'
import { colors } from 'pages/CustomerDetails/constants'

interface PropsT {
  title: string
  text: string
  secondaryText?: string
  children?: JSX.Element
  showDivider?: boolean
  styleOverride?: {
    secondaryColor?: string
  }
  sx?: Record<string, unknown>
}

const ListItemInfo = (props: PropsT): ReactElement => {
  const {
    title,
    text,
    secondaryText,
    children,
    showDivider = true,
    styleOverride,
    sx = {}
  } = props
  return (
    <>
      <ListItem sx={sx} divider={showDivider}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle2',
            component: 'div'
          }}
          primary={title}
          secondaryTypographyProps={{
            variant: 'body2',
            component: 'div',
            color: styleOverride?.secondaryColor ?? colors.primary,
            style: { wordWrap: 'break-word', whiteSpace: 'pre-wrap' }
          }}
          secondary={
            <Box>
              <Typography component="div">{text}</Typography>
              {secondaryText && (
                <Typography component="div" color={colors.subtitleGrey}>
                  {secondaryText}
                </Typography>
              )}
            </Box>
          }
        />
        {children}
      </ListItem>
    </>
  )
}

export default ListItemInfo
