import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import FolderCopyIcon from '@mui/icons-material/FolderCopy'
import { useHistory } from 'react-router-dom'
import { ReactElement } from 'react'
import { useGetDraftCurationsQuery } from 'services/curationSvc'
import PageError from 'components/PageError'
import DividerComponent from '../DividerComponent'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

const ALERT_TITLE = 'We had a problem retrieving your drafts.'

const OpenDrafts = (): ReactElement => {
  const history = useHistory()

  const handleClick = () => {
    const attributes = {
      page: 'styleboards'
    }

    generateNewRelicLogs('viewAllDraftsClick', attributes)
    history.push('/styleboards/drafts')
  }

  const { data, isError } = useGetDraftCurationsQuery()

  const draftCount = data?.meta?.count
  const boardText = draftCount != 1 ? 'boards' : 'board'

  return (
    <Box width={'100%'}>
      <DividerComponent />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" mb={1}>
          Drafts
        </Typography>
        <Button onClick={() => handleClick()}>View All</Button>
      </Box>
      {data && (
        <ListItem onClick={handleClick}>
          <ListItemButton>
            <ListItemIcon>
              <FolderCopyIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={`${draftCount} ${boardText} in draft`} />
          </ListItemButton>
        </ListItem>
      )}
      {isError && (
        <PageError
          errorTitle={ALERT_TITLE}
          isErrorTitlePersonalized={true}
          isFullWidth={true}
          isInlineAlert={true}
        />
      )}
    </Box>
  )
}

export default OpenDrafts
