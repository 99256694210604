import oktaTokenStorage from 'utils/okta-token-utils'
import {
  US_SELLERS_AD_GROUP,
  STYLE_ADVISORS_AD_GROUP,
  DEV_SEC_AD_GROUP,
  PERMISSIONS_TESTING_GROUP,
  PERSONAL_STYLISTS_AD_GROUP,
  BETA_TEST_USERS_GROUP,
  BIZ_ADMINS_AD_GROUP,
  SPMS_AD_GROUP,
  ASMS_CURATION_APP_GROUP
} from '../pages/constants'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'

export const getTestingGroup: () => string = () => {
  const testingGroup = window.localStorage.getItem(PERMISSIONS_TESTING_GROUP)
  return testingGroup ?? ''
}

export const userBelongsToGroup: (adGroup: string) => boolean = (
  adGroup: string
) => {
  const employeeGroups = oktaTokenStorage.getEmployeeGroupsFromOktaToken()
  getTestingGroup() && employeeGroups.push(getTestingGroup())
  if (employeeGroups) {
    return employeeGroups.includes(adGroup)
  }
  return false
}

// user personas
// if adding new user persona group, please add to new relic log helper getUserPersonaForLogging()
export const isUSSeller: () => boolean = () =>
  userBelongsToGroup(US_SELLERS_AD_GROUP)

export const isStyleAdvisor: () => boolean = () =>
  userBelongsToGroup(STYLE_ADVISORS_AD_GROUP)

export const isPersonalStylist: () => boolean = () =>
  userBelongsToGroup(PERSONAL_STYLISTS_AD_GROUP)

// admin personas
// if adding new admin persona group, please add to new relic log helper getAdminPersonaForLogging()
export const isDevAdmin: () => boolean = () =>
  userBelongsToGroup(DEV_SEC_AD_GROUP)

export const isBizAdmin: () => boolean = () =>
  userBelongsToGroup(BIZ_ADMINS_AD_GROUP)

export const isSPM: () => boolean = () => userBelongsToGroup(SPMS_AD_GROUP)

export const isASMCuration: () => boolean = () =>
  userBelongsToGroup(ASMS_CURATION_APP_GROUP)

// user groups
export const isMessagingUser: () => boolean = () => {
  return !isStyleAdvisor()
}

export const isBetaTestUser: () => boolean = () =>
  userBelongsToGroup(BETA_TEST_USERS_GROUP)

export const isFeatureEnabledForUser = (
  featureFlagAccessGroups: AccessGroupsFeatureFlagT
): boolean => {
  if (featureFlagAccessGroups?.showToAll) {
    return true
  }

  const { accessGroups } = featureFlagAccessGroups || []
  if (accessGroups && accessGroups.length > 0) {
    return accessGroups.some((group: string) => userBelongsToGroup(group))
  }
  return false
}
