import { ReactElement } from 'react'

import { IconButton, TextField } from '@mui/material'
import type { TextFieldProps } from '@mui/material'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const getFormattedMobileNumberNum = (input: string) => {
  let output = '('

  const [, g1, g2, g3] = [
    ...(input.match(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/) ?? [])
  ]

  if (g1.length) {
    output += g1
    if (g1.length == 3) {
      output += ')'
      if (g2.length) {
        output += ' ' + g2
        if (g2.length == 3) {
          output += ' - '
          if (g3.length) {
            output += g3
          }
        }
      }
    }
  }

  return output
}

const HoldsTextFieldMobileNumber = ({
  value,
  onChange,
  isLoading = false,
  ...restTextFieldProps
}: {
  value: unknown
  onChange: (nextValue: string) => void
  isLoading?: boolean
} & Omit<TextFieldProps, 'value' | 'onChange'>): ReactElement => {
  const hasMobileNumber = Boolean(value)

  const handleClearMobileNumber = () => onChange('')
  const handleChangeMobileNumber: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (isLoading) {
      return
    }

    const value = event.target.value
    const mobileNumberFormatted = getFormattedMobileNumberNum(value)

    const isDeleteContentBackward =
      (event.nativeEvent as InputEvent).inputType === 'deleteContentBackward'
    const lastSymbolNotANumber = isNaN(
      parseInt(mobileNumberFormatted.at(-1) as string)
    )

    if (isDeleteContentBackward && lastSymbolNotANumber) {
      const lastIndexWithNumber = value
        .split('')
        .findLastIndex((value) => !isNaN(parseInt(value)))

      if (lastIndexWithNumber !== -1) {
        const mobileNumberFormatted = getFormattedMobileNumberNum(
          value.substring(0, lastIndexWithNumber)
        )
        onChange(mobileNumberFormatted)

        return
      }

      onChange('')
      return
    }

    onChange(mobileNumberFormatted)
  }

  return (
    <TextField
      fullWidth
      type="tel"
      id="customerMobileName"
      label="Mobile number"
      name="customerMobileNumber"
      value={value}
      onChange={handleChangeMobileNumber}
      inputProps={{
        'data-testid': 'find_customer_by_phone_number_field',
        maxLength: 16,
        inputMode: 'numeric',
        pattern: '[0-9]*'
      }}
      size="small"
      aria-label="Mobile number text field"
      InputProps={{
        endAdornment: hasMobileNumber && (
          <IconButton onClick={handleClearMobileNumber} disabled={isLoading}>
            <HighlightOffIcon />
          </IconButton>
        )
      }}
      {...restTextFieldProps}
    />
  )
}

export default HoldsTextFieldMobileNumber
