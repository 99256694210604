import { Backdrop, CircularProgress } from '@mui/material'

const LoadingBackdrop = ({ open }: { open: boolean }): JSX.Element => {
  return (
    <Backdrop
      data-testid="loading-backdrop"
      sx={{
        backgroundColor: '#ffffff66',
        zIndex: (theme) => theme.zIndex.modal + 1
      }}
      open={open}
    >
      <CircularProgress />
    </Backdrop>
  )
}

export default LoadingBackdrop
