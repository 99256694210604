import { dlsV5Theme } from '@ndls/enterprise'
import merge from 'lodash.merge'

const stylingContainerComponent = {
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl'
      }
    }
  }
}

export const customStylingTheme = merge(dlsV5Theme, stylingContainerComponent)
