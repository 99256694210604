import { ReactElement } from 'react'
import { Button, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { colors } from 'pages/CustomerMessages/constants'

type PropsT = {
  attachLink: () => void
  isDisabled: boolean
}
const AttachBookingLink = ({
  attachLink,
  isDisabled
}: PropsT): ReactElement => {
  return (
    <Button
      sx={{ text: '#586366', marginLeft: '20px', paddingLeft: 0 }}
      disabled={isDisabled}
      onClick={attachLink}
    >
      <CalendarMonthIcon
        sx={{ color: isDisabled ? colors.disabled : colors.secondary }}
      />
      <Typography
        fontSize={16}
        lineHeight="22px"
        letterSpacing="0.4px"
        fontWeight="bold"
        color={isDisabled ? colors.disabled : colors.secondary}
      >
        Attach Booking Link
      </Typography>
    </Button>
  )
}

export default AttachBookingLink
