import { ReactElement, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Typography,
  Container,
  Box,
  Link
} from '@mui/material'
import type { SxProps } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { CopyErrorDataT } from 'types/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import ClickableText from 'components/ClickableText'
import { copyToClipboard } from 'utils/copyToClipboard'
import { checkBrowserClient } from 'utils/userAgentDetector'

interface PropsT {
  errorDetails?: CopyErrorDataT
  errorTitle?: string
  isErrorTitlePersonalized?: boolean
  isInlineAlert?: boolean
  customBoxStyle?: SxProps<Theme>
  customAlertStyle?: SxProps<Theme>
}

const DEFAULT_ALERT_TITLE = `Error`

const ErrorMessage = (props: PropsT): ReactElement => {
  const {
    errorDetails,
    errorTitle,
    isErrorTitlePersonalized,
    isInlineAlert,
    customBoxStyle,
    customAlertStyle
  } = props
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const isMobileDevice = checkBrowserClient.isMobile()

  const onClick = () => {
    copyToClipboard(
      JSON.stringify(
        { ...errorDetails, page: window.location.href },
        undefined,
        4
      )
    )
      .then((result) => {
        return setCopyToClipboardSuccess && setCopyToClipboardSuccess(result)
      })
      .catch((error) => {
        throw error
      })
  }

  const alertTitle = errorTitle
    ? isErrorTitlePersonalized
      ? errorTitle
      : `${errorTitle}`
    : DEFAULT_ALERT_TITLE

  const boxStyle = customBoxStyle ?? {
    mt: 2
  }

  const alertStyle = customAlertStyle ?? {
    backgroundColor: 'white',
    color: '#D6243D',
    '& .MuiAlert-icon': {
      color: '#D6243D'
    }
  }

  const refreshPage = () => {
    location.reload()
  }

  return (
    <>
      <Box sx={boxStyle}>
        <Alert
          severity="error"
          sx={{
            padding: isMobileDevice && '12px 6px',
            ...(isInlineAlert && alertStyle)
          }}
        >
          <AlertTitle sx={{ fontSize: '16px' }}>
            <strong data-testid="error-title">{alertTitle} </strong>
          </AlertTitle>
          <Typography
            color="warning"
            sx={{ overflowWrap: 'break-word' }}
            variant="body2"
          >
            Try{' '}
            <Link
              data-testid="page-error-refresh-button"
              component={ClickableText}
              onClick={refreshPage}
              sx={{
                color: 'inherit',
                textDecoration: 'underline',
                fontSize: 'inherit'
              }}
              variant="body2"
            >
              refreshing
            </Link>{' '}
            the screen. Still not working?
          </Typography>

          <Typography
            color="warning"
            sx={{ overflowWrap: 'break-word' }}
            variant="body2"
          >
            <Link
              data-testid="copy-error-button"
              component={ClickableText}
              onClick={onClick}
              sx={{
                color: 'inherit',
                textDecoration: 'underline',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              }}
              variant="body2"
            >
              Copy the error info
            </Link>{' '}
            and paste it in the support chat.
          </Typography>
        </Alert>
      </Box>
      <Container>
        {isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
      </Container>
    </>
  )
}

export default ErrorMessage
