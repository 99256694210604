import { Button } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'

type PropsT = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const QuickLinksButton = ({ onClick }: PropsT): JSX.Element => (
  <Button
    fullWidth
    onClick={onClick}
    variant="outlined"
    sx={{ textTransform: 'none' }}
    startIcon={<LinkIcon />}
  >
    Quick Links
  </Button>
)

export default QuickLinksButton
