import { useEffect, useState } from 'react'
import {
  Container,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Checkbox,
  Stack,
  Typography,
  ListItemText,
  Radio
} from '@mui/material'
import SearchBar from '../SearchBar'
import { CustomerBookFilterOption } from 'types/CustomerBookFilters'
import AnniversaryBadge from 'components/AnniversaryBadge'
import { optionsFilterByQuerySearch } from '../../utils'

type PropsT = {
  options: CustomerBookFilterOption[]
  toggleOption: (filterOptionIndex: number) => void
  isBrandsFilter?: boolean
  isAnniversaryFilter: boolean
  isRadioButton?: boolean
  disabled?: boolean
}

const FilterOptions: ({ options }: PropsT) => JSX.Element = ({
  disabled,
  options,
  toggleOption,
  isBrandsFilter,
  isAnniversaryFilter,
  isRadioButton
}: PropsT) => {
  const [query, setQuery] = useState<string>('')
  const [optionList, setOptionsList] = useState(options)
  const hasOptions = options.length > 0

  useEffect(() => {
    setOptionsList(options)
  }, [options])

  useEffect(() => {
    if (isBrandsFilter) {
      if (!options.length) {
        return
      }

      const optionsListFiltered = query
        ? optionsFilterByQuerySearch(query.toLowerCase(), options)
        : options

      setOptionsList(optionsListFiltered)
    }
  }, [isBrandsFilter, options, query])

  return (
    <div style={{ paddingBottom: '15px' }}>
      {isBrandsFilter && hasOptions && (
        <Stack paddingX={2}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            placeholder="Search by brands"
            testId="searchBrands"
          />
        </Stack>
      )}
      <Container>
        {optionList.map((option, index) => (
          <ListItem key={option.name} sx={{ px: 0, py: 0 }}>
            <ListItemButton
              disabled={disabled}
              sx={{ px: '4px', py: '2px' }}
              onClick={() =>
                toggleOption(query ? options.indexOf(option) : index)
              }
            >
              <ListItemIcon sx={{ minWidth: '24px' }}>
                {isRadioButton ? (
                  <Radio checked={option.checked} edge="start" />
                ) : (
                  <Checkbox edge="start" checked={option.checked} />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" display={'inline'}>
                  {option.name}
                </Typography>
                {isAnniversaryFilter && (
                  <ListItemIcon>
                    <AnniversaryBadge date={`${option.dateEA}`} />
                  </ListItemIcon>
                )}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </Container>
      {isBrandsFilter && query && !optionList.length && (
        <Stack paddingX={2}>
          <Typography variant="subtitle2">{`No results found for "${query}"`}</Typography>
          <Typography variant="caption" color="#6B777B" fontWeight="390">
            Check the spelling and try again.
          </Typography>
        </Stack>
      )}
      {isBrandsFilter && !hasOptions && (
        <Stack paddingX={2}>
          <Typography variant="body2">
            Customers’ saved and shopped brands will be shown here.
          </Typography>
        </Stack>
      )}
    </div>
  )
}

export default FilterOptions
