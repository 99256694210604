import { ReactElement } from 'react'
import ImportContactsSharpIcon from '@mui/icons-material/ImportContactsSharp'
import { Typography, Stack, Box, Button } from '@mui/material'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useHistory } from 'react-router-dom'

const EmptyCustomerBook = (): ReactElement => {
  const history = useHistory()

  const inviteClick = () => {
    generateNewRelicLogs('customerHomeInviteButtonClick')
    history.push({ pathname: '/customers/invite' })
  }
  return (
    <Stack
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingTop="45%"
      paddingBottom="55%"
      sx={{ alignSelf: 'center', alignContent: 'center' }}
    >
      <Box
        marginBottom="15px"
        display="flex"
        justifyContent="center"
        sx={{
          bgcolor: '#ebeff1',
          borderRadius: '50%',
          width: '67px',
          height: '67px'
        }}
      >
        <ImportContactsSharpIcon
          fontSize="large"
          sx={{
            color: '#6B777B',
            display: 'block',
            margin: 'auto'
          }}
        />
      </Box>
      <Typography variant="subtitle2" align="center" marginTop="16px">
        No customers in your book
      </Typography>
      <Box marginTop="4px" maxWidth="275px" textAlign="center">
        <Typography variant="caption" color="secondary" align="center">
          Customers who accept the invitation to be in your book will appear
          here
        </Typography>
      </Box>
      <Button
        onClick={inviteClick}
        variant="outlined"
        sx={{
          fontSize: 'medium',
          mt: '16px'
        }}
      >
        Invite Customers
      </Button>
    </Stack>
  )
}
export default EmptyCustomerBook
