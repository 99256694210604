import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import setStandardHeaders from '../utils/set-standard-headers'
import oktaTokenStorage from '../utils/okta-token-utils'
import {
  StylistProfileResponseT,
  QueryableStylistProfileAttributesT,
  GetStylistProfilesQueryDataT,
  GetAppDataDataT
} from '../types/StylistProfile'

export const stylistProfileApi = createApi({
  reducerPath: 'stylistProfileApi',
  tagTypes: ['StylistProfile'],
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })

      const accessToken = oktaTokenStorage.getOktaAccessToken()
      const idToken = oktaTokenStorage.getOktaIdToken()

      if (accessToken) {
        headers.set('Nord-Okta-Id-Token', idToken)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    },
    baseUrl: process.env.REACT_APP_STYLIST_PROFILE
  }),

  endpoints: (builder) => ({
    createStylistProfile: builder.mutation<
      { stylistProfile: StylistProfileResponseT },
      FormData
    >({
      query: (body) => ({
        url: `/stylist-profiles`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['StylistProfile']
    }),
    updateStylistProfile: builder.mutation<
      { stylistProfile: StylistProfileResponseT },
      { id: string; body: FormData }
    >({
      query: ({ id, body }) => {
        return {
          url: `/stylist-profiles/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['StylistProfile']
    }),
    getStylistProfiles: builder.query<
      GetStylistProfilesQueryDataT,
      Partial<QueryableStylistProfileAttributesT>
    >({
      query: (arg) => {
        const { employeeId, stylistId } = arg
        return {
          url: `/stylist-profiles`,
          method: 'GET',
          params: { employeeId, stylistId }
        }
      },
      providesTags: ['StylistProfile']
    }),
    getAppData: builder.query<GetAppDataDataT, void>({
      query: () => {
        return {
          url: `/app-data`,
          method: 'GET'
        }
      }
    })
  })
})

export const {
  useCreateStylistProfileMutation,
  useGetStylistProfilesQuery,
  useUpdateStylistProfileMutation,
  useGetAppDataQuery
} = stylistProfileApi
