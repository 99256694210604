import { SizesT, BudgetRangeT } from 'types/Curation'

const CLOTHING_CATEGORY_ORDER: Array<keyof SizesT | keyof BudgetRangeT> = [
  'tops',
  'bottoms',
  'dresses',
  'shoes'
]

export const sortCategories = (
  categoryA: { key: string },
  categoryB: { key: string }
): number => {
  const categoryAIndex = CLOTHING_CATEGORY_ORDER.indexOf(categoryA.key)
  const categoryBIndex = CLOTHING_CATEGORY_ORDER.indexOf(categoryB.key)

  // if both are not found in clothing category order list, keep current order
  if (categoryAIndex === -1 && categoryBIndex === -1) {
    return 0
  }

  // if categoryA is not found, return positive, put B before A
  // if categoryB is not found, return negative, put A before B
  if (categoryAIndex === -1 || categoryBIndex === -1) {
    return categoryBIndex - categoryAIndex
  }

  // if both are found in clothing category order list,
  // if categoryA is earlier in the array than B, return negative, put A before B
  // if categoryB is earlier in the array than A, return positive, put B before A
  return categoryAIndex - categoryBIndex
}
