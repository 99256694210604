import {
  ValidationDescriptionError,
  ValidationDuplicateItemError,
  ValidationItemError,
  ValidationResultT,
  ValidationStatus,
  ValidationTitleError
} from 'types/FormValidation'
import { v4 } from 'uuid'

export const validateDescription = (
  descriptionError: boolean
): ValidationDescriptionError | undefined => {
  return descriptionError ? ValidationDescriptionError.TOO_LONG : undefined
}

export const validateItemError = (
  underLimit: boolean,
  overLimit: boolean
): ValidationItemError | undefined => {
  let validationItemError = undefined
  if (underLimit) {
    validationItemError = ValidationItemError.ZERO
  } else if (overLimit) {
    validationItemError = ValidationItemError.TOO_MANY
  }
  return validationItemError
}

export const validateTitle = (
  title: string,
  titleError: string
): ValidationTitleError | undefined => {
  let validationTitleError = undefined
  if (!title) {
    validationTitleError = ValidationTitleError.MISSING
  } else if (titleError) {
    validationTitleError = ValidationTitleError.TOO_LONG
  }
  return validationTitleError
}

export const validateSkus = (
  addedSkus: string[],
  priorSkus: string[]
): ValidationDuplicateItemError | undefined => {
  if (addedSkus?.some((sku) => priorSkus?.includes(sku))) {
    return ValidationDuplicateItemError.DUPLICATE_SKUS
  }
}

export const validateEditForm = (
  title: string,
  titleError: string,
  descriptionError: boolean,
  overLimit: boolean,
  underLimit: boolean,
  skus?: Record<string, string[]>
): ValidationResultT => {
  let validationStatus = ValidationStatus.PASS
  const validationTitleError = validateTitle(title, titleError)
  const validationDescriptionError = validateDescription(descriptionError)
  const validationItemError =
    validateItemError(underLimit, overLimit) ||
    (skus && validateSkus(skus.new, skus.previous))

  if (
    validationTitleError !== undefined ||
    validationDescriptionError !== undefined ||
    validationItemError !== undefined
  ) {
    validationStatus = ValidationStatus.FAIL
  }

  return {
    validationStatus: validationStatus,
    titleError: validationTitleError,
    descriptionError: validationDescriptionError,
    itemError: validationItemError,
    validationId: v4()
  }
}
