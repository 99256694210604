import { useEffect, useState } from 'react'
import { Container, Box, Divider } from '@mui/material'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { Redirect } from 'react-router-dom'
import { useNavigation } from 'contexts/NavigationContext'
import {
  useAddEmployeeFavoriteItemsMutation,
  useGetEmployeeFavoriteItemsQuery,
  useRemoveEmployeeFavoriteItemsMutation
} from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import ProductFavoriteGrid from './components/ProductFavoriteGrid'
import FavoriteItemsHeader from './components/FavoriteItemsHeader'
import AddFavoriteItem from './components/AddFavoriteItem'
import BasePageAlert from 'components/BasePageAlert'
import { CopyErrorDataT } from 'types/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import LoadingSpinner from 'components/LoadingSpinner'

export type PropsT = {
  errorDetails?: CopyErrorDataT
  setCopyToClipboardSuccess?: (arg: boolean) => void
}

const FavoriteItems: React.FC = () => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const {
    error: getFavoriteItemsError,
    isError: isGetFavoriteItemsError,
    data: getFavoriteItemsData,
    endpointName: getFavoriteItemsEndpointName,
    isLoading: isGetFavoriteItemsLoading
  } = useGetEmployeeFavoriteItemsQuery({
    employeeId
  })
  const { setDisplayAppBar } = useNavigation()
  const { isFavoriteItemsEnabled } = useFeatureFlags()
  useEffect(() => {
    setDisplayAppBar(false)

    return () => {
      setDisplayAppBar(true)
    }
  }, [setDisplayAppBar])
  const [
    addFavorite,
    {
      isLoading: isAddItemLoading,
      isError: isAddFavoriteError,
      error: addFavoriteError,
      endpointName: addFavoriteEndpointName
    }
  ] = useAddEmployeeFavoriteItemsMutation()

  const [
    removeFavorite,
    {
      isError: isRemoveFavoriteError,
      isLoading: isRemoveItemLoading,
      error: removeFavoriteError,
      endpointName: removeFavoriteEndpointName
    }
  ] = useRemoveEmployeeFavoriteItemsMutation()
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  if (isFavoriteItemsEnabled && !isFavoriteItemsEnabled?.active) {
    return <Redirect to="/" />
  }
  if (isGetFavoriteItemsError) {
    return (
      <Container sx={{ mt: 2 }}>
        <BasePageAlert
          alertTitle={"Page didn't load."}
          errorDetails={{
            errorData: getFavoriteItemsError,
            endpoint: getFavoriteItemsEndpointName
          }}
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
        {isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
      </Container>
    )
  }

  return (
    <>
      <FavoriteItemsHeader />
      <LoadingBackdrop open={isAddItemLoading || isRemoveItemLoading} />

      <Container>
        {isRemoveFavoriteError && (
          <BasePageAlert
            alertTitle={'Issue removing item. Try again'}
            errorDetails={{
              errorData: removeFavoriteError,
              endpoint: removeFavoriteEndpointName
            }}
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
        {isAddFavoriteError && (
          <BasePageAlert
            alertTitle={'Issue adding item. Try again'}
            errorDetails={{
              errorData: addFavoriteError,
              endpoint: addFavoriteEndpointName
            }}
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
        {isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
        {isGetFavoriteItemsLoading && <LoadingSpinner />}

        <AddFavoriteItem addFavorite={addFavorite} />

        {getFavoriteItemsData && (
          <Box mb={4}>
            <Divider sx={{ mb: 2 }} />
            <ProductFavoriteGrid
              products={getFavoriteItemsData}
              removeFavorite={removeFavorite}
            />
          </Box>
        )}
      </Container>
    </>
  )
}

export default FavoriteItems
