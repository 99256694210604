import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import oktaTokenStorage from 'utils/okta-token-utils'
import setStandardHeaders from 'utils/set-standard-headers'
import { LegacyONSalesMetricT } from 'types/LegacyONSalesApi'

const baseQueryWithRetries = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: process.env.REACT_APP_SELLING_API_UTG_URL,
      prepareHeaders: (headers) => {
        setStandardHeaders({ headers: headers })
        const accessToken = oktaTokenStorage.getOktaAccessToken()
        const idToken = oktaTokenStorage.getOktaIdToken()

        if (accessToken) {
          headers.set('Nord-Okta-Id-Token', idToken)
          headers.set('Authorization', `Bearer ${accessToken}`)
        }

        return headers
      }
    })(args, api, extraOptions)

    if (result) {
      if (result.error) {
        const nordRequestId =
          result.meta?.request.headers.get('nord-request-id')
        const errorWithMetaData = {
          error: { ...result.error, meta: { nordRequestId } }
        }

        if (process.env.NODE_ENV === 'test') {
          retry.fail(result.error)
        }

        return errorWithMetaData
      }
    }

    return result
  },
  {
    maxRetries: 2
  }
)

export const legacyONSalesApi = createApi({
  reducerPath: 'legacyONSalesApi',
  baseQuery: baseQueryWithRetries,
  tagTypes: ['legacyONSales'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getLegacyONSales: builder.query<LegacyONSalesMetricT, void>({
      query: () => {
        return {
          url: '/employee-sales',
          method: 'GET'
        }
      },
      providesTags: ['legacyONSales']
    })
  })
})

export const { useGetLegacyONSalesQuery } = legacyONSalesApi
