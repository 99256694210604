import { ReactElement, useEffect } from 'react'

import { Prompt } from 'react-router-dom'
import oktaTokenStorage from 'utils/okta-token-utils'

import moment from 'moment'
import { Button } from '@mui/material'

import {
  HOLD_DEFAULT_EXPIRATION_LENGTHS,
  HoldFormData,
  HoldsType
} from 'types/Holds'

import { useCreateHoldMutation } from 'services/holdsApi'

import BaseDialog from 'components/BaseDialog'
import LoadingBackdrop from 'components/LoadingBackdrop'

import {
  HOLDS_TEXT_CREATE_NEW_HOLD,
  HOLDS_TEXT_HOLD_CREATED,
  HOLDS_TEXT_HOLD_NOT_CREATED
} from 'pages/Holds/constants'

import { HoldsStartNewHoldFormParamsT } from 'pages/Holds/types'

import convertHoldMobileToDigits from 'pages/Holds/utils/convertHoldMobileToNumber'

import { useHoldsToggle } from 'pages/Holds/hooks'

import { useHoldsContextFetchTrigger } from 'pages/Holds/contexts/HoldsContextFetchTrigger'
import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsDialogReprintHold from 'pages/Holds/shared/components/HoldsDialogReprintHold'

import HoldsDialogBodyStartNewHold from './components/HoldsDialogBodyStartNewHold'
import { getCreateHoldsErrorTitle } from './components/components/HoldsDialogOutOfStock'

const HoldsButtonStart = (): ReactElement => {
  const { handleRefetchTrigger } = useHoldsContextFetchTrigger()
  const {
    handleNotificationAddSuccessMessage,
    handleNotificationAddErrorMessage
  } = useHoldsContextNotification()

  const {
    isOpen: isOpenStartNewHoldDialog,
    handleOpen: handleOpenStartNewHoldDialog,
    handleClose: handleCloseStartNewDialog
  } = useHoldsToggle()

  const {
    isOpen: isCancelDialogOpen,
    handleOpen: handleOpenCancelDialog,
    handleClose: handleCloseCancelDialog
  } = useHoldsToggle()

  const {
    isOpen: isOpenPrintingHoldDialog,
    handleOpen: handleOpenPrintingHoldDialog,
    handleClose: handleClosePrintingHoldDialog
  } = useHoldsToggle()

  const [
    createHold,
    {
      isLoading: isCreateHoldLoading,
      isSuccess: isCreateHoldSuccess,
      data: dataCreatedHold,
      error: dataCreateHoldError,
      reset: resetCreateHoldRequestCache
    }
  ] = useCreateHoldMutation()

  const handleClose = () => {
    if (isCreateHoldLoading) {
      return
    }

    handleOpenCancelDialog()
  }

  const onCloseConfirm = () => {
    if (isCreateHoldLoading) {
      return
    }

    handleCloseCancelDialog()
    handleCloseStartNewDialog()
    handleClosePrintingHoldDialog()

    handleRefetchTrigger()
  }

  const onCloseCancel = () => {
    if (isCreateHoldLoading) {
      return
    }

    handleCloseCancelDialog()
  }

  const handleSubmit = async (params: HoldsStartNewHoldFormParamsT) => {
    if (isCreateHoldLoading || isCreateHoldSuccess) {
      return
    }

    const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
    const storeNumber = oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()

    const holdFormData: HoldFormData = {
      customerId: params.customerInfo.customerId ?? '',
      customerName: params.customerInfo.customerName,
      customerPhoneNumber: convertHoldMobileToDigits(
        params.customerInfo.customerMobileNumber
      ),
      employeeId,
      storeNumber: Number(storeNumber),
      type: params.holdType,
      expiresAt: moment()
        .add(
          HOLD_DEFAULT_EXPIRATION_LENGTHS[params.holdType as HoldsType],
          'days'
        )
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
      ...params.itemsOnHold.reduce(
        (accumulator, { upc, sku }) => {
          accumulator.lineItems.push({
            upc,
            sku
          })

          accumulator.itemsUpcs.push(upc)
          accumulator.itemsSku.push(sku)

          return accumulator
        },
        {
          lineItems: [],
          itemsUpcs: [],
          itemsSku: []
        } as Pick<HoldFormData, 'lineItems' | 'itemsUpcs' | 'itemsSku'>
      )
    }

    try {
      const response = await createHold({ body: holdFormData }).unwrap()
      const { ticketNumber } = response

      if (ticketNumber) {
        handleNotificationAddSuccessMessage({
          title: HOLDS_TEXT_HOLD_CREATED
        })

        handleOpenPrintingHoldDialog()
      } else {
        handleNotificationAddErrorMessage({
          title: HOLDS_TEXT_HOLD_NOT_CREATED
        })
      }
    } catch (error) {
      handleNotificationAddErrorMessage({
        title: getCreateHoldsErrorTitle({
          error,
          holdFormData
        })
      })
    }
  }

  useEffect(() => {
    if (isOpenStartNewHoldDialog) {
      resetCreateHoldRequestCache()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenStartNewHoldDialog])

  return (
    <>
      <Button
        data-testid="holds-button-start"
        fullWidth
        variant="contained"
        onClick={handleOpenStartNewHoldDialog}
      >
        {HOLDS_TEXT_CREATE_NEW_HOLD}
      </Button>

      <HoldsDialog open={isOpenStartNewHoldDialog} onClose={handleClose}>
        <HoldsDialogBodyStartNewHold
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          isCreateHoldLoading={isCreateHoldLoading}
          isCreateHoldSuccess={isCreateHoldSuccess}
          dataCreateHoldError={dataCreateHoldError}
        />
      </HoldsDialog>

      {isOpenStartNewHoldDialog && (
        <Prompt
          message={() => {
            if (isCreateHoldLoading) {
              return false
            }

            handleClose()
            return false
          }}
        />
      )}

      <BaseDialog
        title={'Are you sure you want to cancel?'}
        contentText={'The Hold will not saved.'}
        confirm={{
          action: onCloseConfirm,
          text: 'Yes, Cancel'
        }}
        cancel={{
          action: onCloseCancel,
          text: 'Nevermind'
        }}
        onClose={onCloseCancel}
        open={isCancelDialogOpen}
      />

      <LoadingBackdrop open={isCreateHoldLoading} />

      {dataCreatedHold && (
        <HoldsDialogReprintHold
          isOpen={isOpenPrintingHoldDialog}
          hold={dataCreatedHold}
          handleClose={onCloseConfirm}
        />
      )}
    </>
  )
}

export default HoldsButtonStart
