import { Grid, TextField } from '@mui/material'
import { ReactElement, useContext } from 'react'
import { ITEM_NOTE_LABEL, UPDATE_EDIT_NOTES } from '../../constants'
import { CurationEditDispatchContext } from '../../CurationEditReducer'

type PropsT = {
  curationProductId: number
  note: string | undefined
}

const EditCurationItemNote = (props: PropsT): ReactElement => {
  const { curationProductId, note } = props
  const dispatch = useContext(CurationEditDispatchContext)
  const productId = String(curationProductId)

  return (
    <Grid item mt={2}>
      <TextField
        fullWidth
        id={productId}
        label={ITEM_NOTE_LABEL}
        multiline
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          const newNote = event?.target.value || ''
          dispatch &&
            dispatch({
              type: UPDATE_EDIT_NOTES,
              payload: { [productId]: { note: newNote } }
            })
        }}
        rows={5}
        variant="outlined"
        value={note}
      />
    </Grid>
  )
}

export default EditCurationItemNote
