import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@mui/material'
import { DATE_RANGE } from 'types/CurationAttribution'

interface PropsT {
  error?: boolean
  handleChange?: (value: DATE_RANGE) => void
  selectedOption?: DATE_RANGE
}

const FiscalDatePicker = ({
  error,
  handleChange,
  selectedOption
}: PropsT): JSX.Element => {
  const theme = useTheme()

  const MENU_ITEM_OPTIONS = {
    [DATE_RANGE.MONTH]: 'Fiscal month to date',
    [DATE_RANGE.QUARTER]: 'Fiscal quarter to date',
    [DATE_RANGE.YEAR]: 'Fiscal year to date',
    [DATE_RANGE.ALL_TIME]: 'All time'
  }

  return (
    <>
      <FormControl fullWidth error={error}>
        <InputLabel id="time-frame-label">Timeframe</InputLabel>
        <Select
          labelId="time-frame-label"
          id="time-frame-select"
          value={selectedOption || ''}
          label="Timeframe"
          onChange={(event) =>
            handleChange && handleChange(event.target.value as DATE_RANGE)
          }
          sx={{
            backgroundColor: theme.palette.common.white,
            maxWidth: '343px'
          }}
        >
          <MenuItem key={DATE_RANGE.MONTH} value={DATE_RANGE.MONTH}>
            {MENU_ITEM_OPTIONS[DATE_RANGE.MONTH]}
          </MenuItem>
          <MenuItem key={DATE_RANGE.QUARTER} value={DATE_RANGE.QUARTER}>
            {MENU_ITEM_OPTIONS[DATE_RANGE.QUARTER]}
          </MenuItem>
          <MenuItem key={DATE_RANGE.YEAR} value={DATE_RANGE.YEAR}>
            {MENU_ITEM_OPTIONS[DATE_RANGE.YEAR]}
          </MenuItem>
          <MenuItem key={DATE_RANGE.ALL_TIME} value={DATE_RANGE.ALL_TIME}>
            {MENU_ITEM_OPTIONS[DATE_RANGE.ALL_TIME]}
          </MenuItem>
        </Select>
        {error && (
          <FormHelperText>
            <Typography variant="caption">
              Most recent fiscal dates are not yet available — try selecting a
              custom start and end date instead
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default FiscalDatePicker
