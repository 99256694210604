import { ReactElement } from 'react'
import { Button, Grid } from '@mui/material'

type PropsT = {
  handleOpenCancelDialog: (arg: boolean) => void
  isEditing: boolean
}

const CancelButton = (props: PropsT): ReactElement => {
  const { handleOpenCancelDialog, isEditing } = props
  const cancelButtonText = isEditing ? 'Cancel Edits' : 'Cancel'

  return (
    <Grid item>
      <Button
        onClick={() => {
          handleOpenCancelDialog(true)
        }}
      >
        {cancelButtonText}
      </Button>
    </Grid>
  )
}

export default CancelButton
