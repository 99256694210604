import React, { ReactElement, ReactNode } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material'
import { toLabel } from 'utils/toLabel'
import CloseIcon from '@mui/icons-material/Close'

type BaseDialogProps = Omit<
  React.ComponentProps<typeof Dialog>,
  'aria-modal' | 'aria-labelledby'
>

interface AlertDialogAction {
  action: React.MouseEventHandler<HTMLButtonElement>
  text: string
}

interface PropsT extends Omit<BaseDialogProps, 'aria-describedby'> {
  cancel?: AlertDialogAction
  confirm: AlertDialogAction
  content?: string | ReactElement
  contentText?: string
  isLoading?: boolean
  onClose?: () => void
  open: boolean
  showTitleWithCloseIcon?: boolean
  title: string
  confirmButtonStartIcon?: ReactNode | undefined
  cancelButtonStartIcon?: ReactNode | undefined
}

const BaseDialog = (props: PropsT): ReactElement => {
  const {
    cancel,
    confirm,
    content,
    contentText,
    onClose,
    open,
    showTitleWithCloseIcon = false,
    title,
    confirmButtonStartIcon = undefined,
    cancelButtonStartIcon = undefined,
    ...baseDialogProps
  } = props

  return (
    <Dialog
      {...baseDialogProps}
      aria-describedby={`dialog_desc_${toLabel(title)}`}
      aria-modal={true}
      open={open}
      role="alertdialog"
    >
      {showTitleWithCloseIcon ? (
        <DialogTitle
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <strong>{title}</strong>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <DialogTitle>
          <strong>{title}</strong>
        </DialogTitle>
      )}
      <DialogContent>
        {content}
        <DialogContentText
          color="text.secondary"
          id={`dialog_desc_${toLabel(title)}`}
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button
            startIcon={cancelButtonStartIcon}
            onClick={cancel.action}
            autoFocus
          >
            {cancel.text}
          </Button>
        )}
        <Button startIcon={confirmButtonStartIcon} onClick={confirm.action}>
          {confirm.text}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BaseDialog
