import { ReactElement, ReactNode } from 'react'
import { Drawer as MuiDrawer } from '@mui/material'
import { useDisableDrawerEnforceFocus } from 'hooks/useDisableDrawerEnforceFocus'

interface PropsT {
  children: ReactNode | ReactNode[]
  onClose: () => void
  open: boolean
  variant?: 'temporary' | 'permanent' | 'persistent'
  width?: string
}

const Drawer = (props: PropsT): ReactElement => {
  const {
    children,
    onClose,
    open,
    variant = 'temporary',
    width = '600px'
  } = props
  const { disableDrawerEnforceFocus } = useDisableDrawerEnforceFocus()

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      variant={variant}
      sx={{
        width: { xs: '100%', lg: `${width}` },
        '& .MuiDrawer-paper': {
          width: { xs: '100%', lg: `${width}` }
        }
      }}
      disableEnforceFocus={disableDrawerEnforceFocus}
    >
      {children}
    </MuiDrawer>
  )
}

export default Drawer
