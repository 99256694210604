import { ReactElement } from 'react'

import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'

import { Typography } from '@mui/material'
import oktaTokenStorage from 'utils/okta-token-utils'

const storeNumberToString = (storeNumber: string | null | undefined) => {
  if (isUndefined(storeNumber)) {
    return 'undefined'
  }

  if (isNull(storeNumber)) {
    return 'null'
  }

  return storeNumber
}

/**
 * TODO: HOLDS - we show store_number instead of storeName
 * as we get store_number undefined from okta
 */
const HoldsStoreNumber = (): ReactElement => {
  const storeNumber = oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()

  return (
    <Typography
      data-testid="holds-store-number"
      component="h2"
      variant="body1"
      display="flex"
      gap={1}
    >
      <strong>Store:</strong>

      {storeNumberToString(storeNumber)}
    </Typography>
  )
}

export default HoldsStoreNumber
