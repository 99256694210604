import { Container } from '@mui/material'
import type { SxProps } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import { checkBrowserClient } from 'utils/userAgentDetector'

type PropsT = {
  children: JSX.Element
  sx?: SxProps<Theme>
}
const MobileAndDesktopContainer: (props: PropsT) => JSX.Element = (
  props: PropsT
) => {
  const { children, sx } = props
  const isMobileDevice = checkBrowserClient.isMobile()
  return (
    <Container sx={{ paddingX: isMobileDevice && 0, ...sx }}>
      {children}
    </Container>
  )
}

export default MobileAndDesktopContainer
