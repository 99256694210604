import { NR_CLIENTELING_PAGES } from '../nrClientelingPages'

const { SINGLE_CONVERSATION_PAGE } = NR_CLIENTELING_PAGES

// Replaces `sendMessageClickSuccessful`:
export const NR_SINGLE_CONVERSATION_PAGE_SEND_MESSAGE_SUCCESS = `${SINGLE_CONVERSATION_PAGE}-sent_message_success`
// Replaces `retrySendMessage`:
export const NR_SINGLE_CONVERSATION_PAGE_SEND_MESSAGE_RETRY = `${SINGLE_CONVERSATION_PAGE}-sent_message_retry`
export const NR_SINGLE_CONVERSATION_PAGE_SEND_IMAGE = `${SINGLE_CONVERSATION_PAGE}-sent_image`
// Replaces `sendBookingLinkSuccessful`:
export const NR_SINGLE_CONVERSATION_PAGE_SEND_BOOKING_LINK = `${SINGLE_CONVERSATION_PAGE}-sent_booking_link`
