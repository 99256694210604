import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@mui/material'
import { ThumbDown, ThumbUp } from '@mui/icons-material'
import {
  CustomerSummaryFeedback,
  generateCustomerSummaryFeedbackDetailsLog,
  generateCustomerSummaryFeedbackLog
} from '../../helpers'
import { CurationSliceT } from 'app/curationSlice'
import {
  DOSSIER_FEEDBACK_STORAGE_KEY,
  NEGATIVE_FEEDBACK_DETAILS_STORAGE_KEY,
  getItemFromLocalStorage,
  saveCustomerDossierToLocalStorage
} from 'utils/customerDossier'
import { useState } from 'react'

const ThanksForFeedbackMessage = (): JSX.Element => {
  return (
    <Typography variant="caption" color="text.secondary">
      Thanks for your feedback! If you have more details to share, feel free to
      provide in the support chat.
    </Typography>
  )
}

const NegativeFeedback = ({
  curationSlice,
  curationId
}: {
  curationSlice?: CurationSliceT
  curationId: number
}): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const [feedbackDetailsProvided, setFeedbackDetailsProvided] = useState(
    !!getItemFromLocalStorage(curationId, NEGATIVE_FEEDBACK_DETAILS_STORAGE_KEY)
  )

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.name
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(selectedOption)) {
        return prevSelectedOptions.filter((option) => option !== selectedOption)
      } else {
        return [...prevSelectedOptions, selectedOption]
      }
    })
  }

  const isSubmitDisabled = selectedOptions.length === 0

  const handleFeedbackDetailSubmit = () => {
    generateCustomerSummaryFeedbackDetailsLog({
      curationSlice,
      customerSummaryFeedbackDetails: selectedOptions
    })
    setFeedbackDetailsProvided(true)
    saveCustomerDossierToLocalStorage({
      curationId,
      data: selectedOptions,
      storageKey: NEGATIVE_FEEDBACK_DETAILS_STORAGE_KEY
    })
  }

  const FeedbackCheckbox = ({
    name,
    labelText
  }: {
    name: string
    labelText: string
  }) => {
    return (
      <FormControlLabel
        sx={{ my: -0.2 }}
        control={
          <Checkbox
            size="small"
            name={name}
            checked={selectedOptions.includes(name)}
            onChange={handleCheckboxChange}
          />
        }
        label={
          <Typography variant="caption" color="text.secondary">
            {labelText}
          </Typography>
        }
      />
    )
  }

  return (
    <>
      {!feedbackDetailsProvided ? (
        <Box display="flex" flexDirection="column">
          <Typography sx={{ mb: 1 }} variant="caption" color="text.secondary">
            Thanks for your feedback! We&apos;re sorry this wasn&apos;t helpful.
            If you&apos;re able, please let us know why this was not helpful
            (select all that apply):
          </Typography>
          <FormControl sx={{}}>
            <FormGroup>
              <FeedbackCheckbox
                name="notAccurate"
                labelText="Not accurate"
              ></FeedbackCheckbox>
              <FeedbackCheckbox
                name="notUseful"
                labelText="Not useful / not enough info"
              ></FeedbackCheckbox>
              <FeedbackCheckbox
                name="hardToUnderstand"
                labelText="Hard to understand"
              ></FeedbackCheckbox>
              <Grid container justifyContent="flex-end">
                <Button
                  sx={{ mt: 1.5, maxWidth: 96, alignItems: 'right' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleFeedbackDetailSubmit}
                  disabled={isSubmitDisabled}
                >
                  Submit
                </Button>
              </Grid>
            </FormGroup>
          </FormControl>
        </Box>
      ) : (
        <ThanksForFeedbackMessage></ThanksForFeedbackMessage>
      )}
    </>
  )
}

const SummaryFeedback = ({
  curationSlice,
  curationId
}: {
  curationSlice?: CurationSliceT
  curationId: number
}): JSX.Element => {
  const [positiveFeedbackProvided, setPositiveFeedbackProvided] = useState(
    getItemFromLocalStorage(curationId, DOSSIER_FEEDBACK_STORAGE_KEY) ===
      'helpful'
  )
  const [negativeFeedbackProvided, setNegativeFeedbackProvided] = useState(
    getItemFromLocalStorage(curationId, DOSSIER_FEEDBACK_STORAGE_KEY) ===
      'unhelpful'
  )
  const onFeedbackClick = (summaryType: CustomerSummaryFeedback) => {
    generateCustomerSummaryFeedbackLog({
      curationSlice,
      customerSummaryFeedbackVote: summaryType
    })

    if (summaryType === CustomerSummaryFeedback.UNHELPFUL) {
      setNegativeFeedbackProvided(true)
    } else {
      setPositiveFeedbackProvided(true)
    }
    saveCustomerDossierToLocalStorage({
      curationId,
      data: summaryType,
      storageKey: DOSSIER_FEEDBACK_STORAGE_KEY
    })
  }

  return (
    <Box display="flex" flexDirection="row" mb={2}>
      {positiveFeedbackProvided && (
        <ThanksForFeedbackMessage></ThanksForFeedbackMessage>
      )}
      {negativeFeedbackProvided && (
        <NegativeFeedback
          curationSlice={curationSlice}
          curationId={curationId}
        />
      )}
      {!negativeFeedbackProvided && !positiveFeedbackProvided && (
        <>
          <Typography variant="caption" sx={{ mr: 2 }}>
            Was this helpful?
          </Typography>
          <Chip
            icon={<ThumbUp />}
            label="Yes"
            onClick={() => onFeedbackClick(CustomerSummaryFeedback.HELPFUL)}
            size="small"
            sx={{ mr: 3, gap: 1 }}
          />
          <Chip
            icon={<ThumbDown />}
            label="No"
            onClick={() => onFeedbackClick(CustomerSummaryFeedback.UNHELPFUL)}
            size="small"
            sx={{ gap: 1 }}
          />
        </>
      )}
    </Box>
  )
}

export default SummaryFeedback
