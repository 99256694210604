import { useCallback, useReducer, createContext, Dispatch } from 'react'
import { useLocalStorage } from 'react-use'
import { CurrentReviewStateT, StoredReviewStateT } from 'types/Curation'
import { CurationActionT } from 'pages/CurationReview/components/CurationReviewContent/Actions'
import {
  TITLE_MAX_LENGTH_ERROR_TEXT,
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  UPDATE_NOTE,
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  TITLE_REQUIRED_TEXT,
  SET_TITLE_REQUIRED_ERROR,
  SET_IN_PROGRESS_CURATION,
  SET_ORDERED_ITEMS,
  SET_SHOPPING_SESSION_TOKEN,
  SET_FASHION_MAP_SEARCH_DRAWER_OPEN,
  UPDATE_RECENT_ITEM_ADDS,
  UPDATE_RECENT_ITEM_VIEWS,
  UPDATE_WISHLIST_ADDS,
  UPDATE_SCANNER_VIEWS,
  UPDATE_SCANNER_ADDS,
  UPDATE_FASHION_MAP_VIEWS,
  UPDATE_SEED_ITEM_REC_VIEWS,
  SET_SEED_ITEM_RECS_TERMS
} from '../../constants'

export const LOCAL_STORAGE_KEY = 'curation_review_reducer_state'

const initialState = {
  notes: {},
  title: '',
  titleError: '',
  description: '',
  descriptionError: false,
  inProgressCurationId: null,
  isFashionMapSearchDrawerOpen: false,
  orderedItems: [],
  shoppingToken: null,
  productDiscovery: {
    rmsSkus: {},
    webStyleIds: {}
  },
  itemRecommendations: {
    itemRecommendationTerms: {
      recommendations: []
    },
    seedItemWebStyleId: ''
  }
}

const initialStoredState = (({
  notes,
  title,
  description,
  inProgressCurationId,
  isFashionMapSearchDrawerOpen,
  orderedItems,
  shoppingToken,
  productDiscovery,
  itemRecommendations
}) => ({
  notes,
  title,
  description,
  inProgressCurationId,
  isFashionMapSearchDrawerOpen,
  orderedItems,
  shoppingToken,
  productDiscovery,
  itemRecommendations
}))(initialState)

const reducer = (
  currentState: CurrentReviewStateT,
  action: CurationActionT
) => {
  switch (action.type) {
    case UPDATE_NOTE:
      return {
        ...currentState,
        notes: {
          ...currentState.notes,
          [action.payload.rmsSku]: action.payload.itemNote
        }
      }
    case SET_TITLE_REQUIRED_ERROR:
      return {
        ...currentState,
        titleError: `${TITLE_REQUIRED_TEXT}`
      }
    case UPDATE_TITLE:
      if (action.payload.title.length > MAX_TITLE_LENGTH) {
        return {
          ...currentState,
          titleError: `${TITLE_MAX_LENGTH_ERROR_TEXT}`,
          title: action.payload.title
        }
      } else {
        return {
          ...currentState,
          title: action.payload.title,
          titleError: ''
        }
      }
    case UPDATE_DESCRIPTION:
      if (action.payload.description.length > MAX_DESCRIPTION_LENGTH) {
        return {
          ...currentState,
          description: action.payload.description,
          descriptionError: true
        }
      } else {
        return {
          ...currentState,
          description: action.payload.description,
          descriptionError: false
        }
      }
    case SET_IN_PROGRESS_CURATION:
      if (
        currentState.inProgressCurationId != null &&
        currentState.inProgressCurationId != action.payload.id
      ) {
        return {
          ...initialState,
          inProgressCurationId: action.payload.id
        }
      } else {
        return {
          ...currentState,
          inProgressCurationId: action.payload.id
        }
      }
    case SET_ORDERED_ITEMS:
      return {
        ...currentState,
        orderedItems: action.payload.orderedItems
      }
    case SET_SHOPPING_SESSION_TOKEN:
      return {
        ...currentState,
        shoppingToken: action.payload.shoppingToken
      }
    case SET_FASHION_MAP_SEARCH_DRAWER_OPEN:
      return {
        ...currentState,
        isFashionMapSearchDrawerOpen: action.payload
      }
    case UPDATE_RECENT_ITEM_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'recentItemAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_RECENT_ITEM_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'recentItemViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case UPDATE_WISHLIST_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'wishlistAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_SCANNER_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'scannerAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_SCANNER_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'scannerViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case UPDATE_FASHION_MAP_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'fashionMapViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case UPDATE_SEED_ITEM_REC_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'seedItemRecViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case SET_SEED_ITEM_RECS_TERMS:
      return {
        ...currentState,
        itemRecommendations: {
          seedItemWebStyleId: action.payload.webStyleId,
          itemRecommendationTerms: action.payload.itemRecommendationTerms
        }
      }
    default:
      return currentState
  }
}

//https://www.benmvp.com/blog/sync-localstorage-react-usereducer-hook/
export const usePersistedCurationReviewReducer = (): [
  CurrentReviewStateT,
  Dispatch<CurationActionT>
] => {
  const [storedState, storeState] = useLocalStorage<StoredReviewStateT>(
    LOCAL_STORAGE_KEY,
    initialStoredState
  )

  const reducerLocalStorage = useCallback(
    (state, action) => {
      const newState = reducer(state, action)
      const newStoredState = (({
        notes,
        title,
        description,
        inProgressCurationId,
        orderedItems,
        shoppingToken,
        productDiscovery,
        itemRecommendations
      }) => ({
        notes,
        title,
        description,
        inProgressCurationId,
        orderedItems,
        shoppingToken,
        productDiscovery,
        itemRecommendations
      }))(newState)

      storeState(newStoredState)
      return newState
    },
    [storeState]
  )
  return useReducer(reducerLocalStorage, { ...initialState, ...storedState })
}

export const CurationReviewDispatchContext =
  createContext<null | Dispatch<CurationActionT>>(null)
