import { Box, Typography } from '@mui/material'
import { PERSONAL_BOOK_NAVIGATION_TITLE } from 'components/MenuBar/constants'
import React from 'react'

const MenuBarTitle: React.FC = () => (
  <Box
    sx={{
      marginLeft: '8px'
    }}
  >
    <Typography color="text.primary" variant="overline" fontWeight="bold">
      {PERSONAL_BOOK_NAVIGATION_TITLE}
    </Typography>
  </Box>
)

export default MenuBarTitle
