import { ReactElement, useEffect, useState, useRef, useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  twilioActions,
  twilioSelectors,
  twilioUtils
} from 'services/twilioSlice'
import {
  Conversation,
  Paginator,
  Message as TwilioMessage
} from '@twilio/conversations'

import { hideWidget, showWidget } from 'venderScripts/gladly'
import { useTwilioClient } from 'contexts/TwilioContext'
import { useNavigation } from 'contexts/NavigationContext'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { Box } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import NotFound from 'pages/NotFound'

import CreateMessageDrawer from './components/CreateMessageDrawer'
import ConversationMessageFeed from './components/ConversationMessageFeed'
import PageError from 'components/PageError'
import MessagesMenuBar from './components/ConversationMessageFeed/components/MessagesMenuBar'
import LinkCopiedSnackbar from 'pages/CustomerDetails/components/LinkCopiedSnackbar'
import BasePageAlert from 'components/BasePageAlert'
import oktaTokenStorage from 'utils/okta-token-utils'

const ERROR_TITLE = 'Error retrieving your messages.'

type ParamType = {
  conversationSid: string
}

type StateLinkT = {
  isStyleBoardLinkCopySuccess?: boolean
}

const CustomerMessages = (): ReactElement => {
  const { conversationSid }: ParamType = useParams()
  const { setDisplayAppBar } = useNavigation()
  const history = useHistory()
  const appDispatch = useAppDispatch()
  const {
    client,
    isError: isTwilioClientError,
    error: twilioClientError
  } = useTwilioClient()

  const containerRef = useRef<HTMLDivElement>(null)

  /*
   * Internal State
   */
  const [appHeight, setAppHeight] = useState(0)
  const [customerDetailsError, setCustomerDetailsError] = useState(false)
  const [conversation, setConversation] = useState<undefined | Conversation>()
  const [messagesHaveBeenFetched, setMessagesHaveBeenFetched] =
    useState<boolean>(false)
  const [conversationIsLoading, setConversationIsLoading] =
    useState<boolean>(false)
  const [messagesAreLoading, setMessagesAreLoading] = useState<boolean>(false)
  const [conversationError, setConversationError] = useState<boolean>(false)
  const [messagesPaginator, setMessagesPaginator] = useState<
    Paginator<TwilioMessage> | undefined
  >()
  const [additionalMessagesAreLoading, setAdditionalMessagesAreLoading] =
    useState<boolean>(false)
  const { state } = useLocation<StateLinkT>()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  /*
   * Selectors
   */
  const selectedConversationMessages = useAppSelector(
    twilioSelectors.activeConversationMessagesSelector
  )

  const selectedConversationId = useAppSelector(
    twilioSelectors.activeConversationIdSelector
  )

  const isCustomerOptedIn = useAppSelector(
    twilioSelectors.isConversationOptedIn
  )

  /*
   * UseEffect Callback Methods
   */
  const markConversationRead = useCallback(async () => {
    if (conversation && selectedConversationMessages?.length) {
      // conversation.setAllMessagesRead() will trigger the Twilio 'conversationUpdated' hook by updating the conversation's `lastReadMessageIndex` property
      await conversation.setAllMessagesRead()
    }
  }, [conversation, selectedConversationMessages?.length])

  const isCurrentEmployeeParticipantInConversation = useCallback(
    async (twilioConversation: Conversation): Promise<boolean> => {
      const participants = await twilioConversation.getParticipants()
      return participants.some(
        (participant) => participant.identity === employeeId
      )
    },
    [employeeId]
  )

  const fetchConversation = useCallback(async (): Promise<void> => {
    setConversationIsLoading(true)

    try {
      const twilioConversation = await client.getConversationBySid(
        conversationSid
      )
      if (
        await isCurrentEmployeeParticipantInConversation(twilioConversation)
      ) {
        setConversation(twilioConversation)
      } else {
        setConversationError(true)
      }
    } catch (error) {
      setConversationError(true)
    }

    setConversationIsLoading(false)
  }, [client, conversationSid, isCurrentEmployeeParticipantInConversation])

  const fetchFirstMessageBatch = useCallback(async (): Promise<void> => {
    if (!conversation) {
      return
    }
    setMessagesAreLoading(true)
    try {
      const MessagesPaginator = await conversation.getMessages()
      if (MessagesPaginator) {
        const formattedMessages = await twilioUtils.formatMultipleMessages(
          MessagesPaginator.items
        )
        appDispatch(
          twilioActions.addAndReplaceAllMessagesById({
            id: conversationSid,
            messages: formattedMessages
          })
        )
        await markConversationRead()
        setMessagesPaginator(MessagesPaginator)
      }

      setMessagesAreLoading(false)
      setMessagesHaveBeenFetched(true)
    } catch (error) {
      setConversationError(true)
    }
  }, [appDispatch, conversation, conversationSid, markConversationRead])

  const fetchAdditionalMessages = async () => {
    if (
      additionalMessagesAreLoading ||
      !messagesPaginator ||
      !messagesPaginator.hasPrevPage
    ) {
      return
    }

    setAdditionalMessagesAreLoading(true)

    const previousPage = await messagesPaginator.prevPage()

    setMessagesPaginator(previousPage)

    const formattedMessages = await twilioUtils.formatMultipleMessages(
      previousPage.items
    )
    appDispatch(
      twilioActions.addPreviousMessagesById({
        id: conversationSid,
        messages: formattedMessages
      })
    )
    setAdditionalMessagesAreLoading(false)
  }

  /*
   * UseEffects
   */
  useEffect(() => {
    appDispatch(
      twilioActions.updateActiveConversationId({ sid: conversationSid })
    )
    setDisplayAppBar(false)

    // Remove active conversation SID from redux on unmount
    return () => {
      appDispatch(twilioActions.updateActiveConversationId({ sid: '' }))
    }
  }, [setDisplayAppBar, appDispatch, history, conversationSid])

  useEffect(() => {
    markConversationRead()
  }, [markConversationRead])

  useEffect(() => {
    const appHeight = () => {
      setAppHeight(window.innerHeight)
    }
    hideWidget()
    setAppHeight(window.innerHeight)

    // ** note open and closing the browser tools will not trigger a resize event in chrome
    window.addEventListener('resize', appHeight)

    return () => {
      showWidget()
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  /*
   * Fetch the conversation and messages
   */
  useEffect(() => {
    if (!client || conversationIsLoading) {
      return
    }

    if (!conversation) {
      fetchConversation()
    }

    // Ensure that the conversation has been fetched and redux has been updated before fetching messages
    if (conversation && !messagesAreLoading && !messagesHaveBeenFetched) {
      fetchFirstMessageBatch()
    }
  }, [
    client,
    conversation,
    conversationIsLoading,
    messagesHaveBeenFetched,
    messagesAreLoading,
    fetchConversation,
    fetchFirstMessageBatch
  ])

  /* 
    This component is styed with flexbox in order to keep the header and the footer sticky.
    Word of warning You will mess up the styling if you add anything in between the <Box> components
  */
  if (conversationError) {
    return <NotFound />
  }

  if (isTwilioClientError) {
    return (
      <PageError
        errorTitle={ERROR_TITLE}
        errorDetails={{ errorData: twilioClientError }}
      />
    )
  }

  if (!messagesHaveBeenFetched || !selectedConversationId) {
    return <LoadingSpinner />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        height: `${appHeight}px`
      }}
    >
      <Box
        sx={{
          flex: '0 0 auto'
        }}
      >
        <MessagesMenuBar
          isCustomerOptedIn={isCustomerOptedIn}
          conversationName={conversation?.friendlyName || ''}
          messagesHaveBeenFetched={messagesHaveBeenFetched}
          conversation={conversation}
          setCustomerDetailsError={setCustomerDetailsError}
        />
      </Box>
      {!isCustomerOptedIn ? (
        <BasePageAlert
          alertTitle={`${
            conversation?.friendlyName || 'This customer'
          } is no longer in your book`}
          errorMessage="You can't view their profile or messages"
          severity="info"
        />
      ) : (
        <>
          <Box
            sx={{
              flex: '1 1 auto',
              overflowY: 'scroll',
              overflowX: 'hidden',
              wordBreak: 'break-word'
            }}
            ref={containerRef}
          >
            {customerDetailsError && (
              <PageError errorTitle={'Unable to retrieve customer details.'} />
            )}
            {!customerDetailsError && messagesHaveBeenFetched && (
              <ConversationMessageFeed
                client={client}
                selectedConversationId={selectedConversationId}
                fetchAdditionalMessages={fetchAdditionalMessages}
                additionalMessagesAreLoading={additionalMessagesAreLoading}
                containerRef={containerRef}
                hasAdditionalMessages={messagesPaginator?.hasPrevPage ?? false}
              />
            )}
          </Box>
          {state?.isStyleBoardLinkCopySuccess && <LinkCopiedSnackbar />}
          <Box
            sx={{
              flex: '0 0 auto'
            }}
          >
            <CreateMessageDrawer
              isClientReady={!!client}
              conversation={conversation || ({} as Conversation)}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

export default CustomerMessages
