import { ReactElement, useEffect, useRef, useState } from 'react'
import { Button } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import LaunchIcon from '@mui/icons-material/Launch'
import { copyToClipboard } from 'utils/copyToClipboard'
import BaseSnackbar from 'components/BaseSnackbar'

type FinishedBoardButtonsPropsT = {
  curationId: number
}

const FinishedBoardButtons = (
  props: FinishedBoardButtonsPropsT
): ReactElement => {
  const { curationId } = props
  const [isLinkCopiedAlertOpen, setIsLinkCopiedAlertOpen] = useState(false)
  const mounted = useRef(false)
  const curationLink = `${process.env.REACT_APP_NORDSTROM_URL}/curation/${curationId}`

  useEffect(function mount() {
    mounted.current = true
    return function unmount() {
      mounted.current = false
    }
  })

  const handleLinkCopied = () => {
    copyToClipboard(curationLink)
    setIsLinkCopiedAlertOpen(true)
  }

  const handleViewClick = () => {
    window.open(curationLink, '_blank', 'noreferrer')
  }

  const handleLinkCopiedAlertClosed = (
    _event?: Event | React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway' || !mounted.current) {
      return
    }
    setIsLinkCopiedAlertOpen(false)
  }

  return (
    <>
      <Button startIcon={<LaunchIcon />} onClick={handleViewClick}>
        {'View'}
      </Button>
      <Button startIcon={<LinkIcon />} onClick={handleLinkCopied}>
        {'Copy Link'}
      </Button>
      <BaseSnackbar
        autoHideDuration={5000}
        message={'Link copied'}
        onClose={handleLinkCopiedAlertClosed}
        open={isLinkCopiedAlertOpen}
      />
    </>
  )
}

export default FinishedBoardButtons
