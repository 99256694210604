import {
  Message,
  Conversation,
  Paginator,
  Client,
  Media,
  DetailedDeliveryReceipt,
  MessageType
} from '@twilio/conversations'
import moment from 'moment'
import {
  ReduxMessage,
  ReduxConversation,
  ErrorMessages,
  ReduxMedia,
  MediaContentType,
  AttachedMediaOptions,
  ParticipantAttributesT,
  CONVERSATION_STATE,
  ConversationsRecord
} from 'types/Twilio'
import { ERROR_MESSAGES_BY_ERROR_CODE, MESSAGE_STATUS } from './constants'
import { ExtendedConsentedCustomerListItemT } from 'types/ConsentedCustomer'

export const formatConversationForRedux = async (
  conversation: Conversation,
  unreadMessagesCount?: number | null,
  shouldFetchParticipants?: boolean
): Promise<ReduxConversation> => {
  let customerId = ''
  if (shouldFetchParticipants) {
    const participants = await conversation.getParticipants()
    customerId = participants.reduce((acc, participant) => {
      const customerId = (participant.attributes as ParticipantAttributesT)
        .customer_id
      if (customerId) {
        acc = customerId
      }
      return acc
    }, '')
  }
  return {
    sid: conversation.sid,
    friendlyName: conversation.friendlyName || 'CustomerName',
    notificationLevel: conversation.notificationLevel,
    unreadMessagesCount: unreadMessagesCount || 0,
    mostRecentMessageDate:
      moment(conversation.lastMessage?.dateCreated).toString() || '',
    state: (conversation.state?.current ||
      CONVERSATION_STATE.ACTIVE) as CONVERSATION_STATE,
    customerId
  }
}

export const formatMedia = async (media: Media): Promise<ReduxMedia> => {
  try {
    const urlMedia = (await media.getContentTemporaryUrl()) || ''
    return {
      sid: media.sid,
      contentType: media.contentType,
      url: urlMedia
    }
  } catch {
    return {
      sid: '',
      contentType: '',
      url: ''
    }
  }
}
export const formatMessage = async (
  message: Message
): Promise<ReduxMessage> => {
  const deliveryReceipt = await extractDeliveryReceipt(message)
  const { status, errorCode } = deliveryReceipt || {}

  const isMedia = message?.type === 'media' && !!message?.attachedMedia?.length
  const formattedMedia = isMedia
    ? await formatMedia(message.attachedMedia[0])
    : ({} as ReduxMedia)

  return {
    sid: message?.sid || '',
    index: message?.index || 0,
    dateCreated: message?.dateCreated
      ? moment(message.dateCreated).toString()
      : moment().toString(),
    body: message?.body || '',
    author: message?.author || '',
    participantSid: message?.participantSid || '',
    deliveryReceipt: {
      ...(status && { status }),
      ...((errorCode || errorCode === 0) && { errorCode })
    },
    type: message?.type,
    attachedMedia: formattedMedia
  }
}
export const formatMultipleMessages = async (
  messages: Message[]
): Promise<ReduxMessage[]> => {
  const resolvedMessages = await Promise.all(
    messages.map((message) => formatMessage(message))
  )
  return resolvedMessages
}

export const extractDeliveryReceipt = async (
  message: Message
): Promise<DetailedDeliveryReceipt | undefined> => {
  try {
    const deliveryReceipt: DetailedDeliveryReceipt[] =
      await message.getDetailedDeliveryReceipts()
    return deliveryReceipt?.length ? deliveryReceipt[0] : undefined
  } catch {
    return undefined
  }
}

export const getAllConversationsRecursively: (
  conversationsPaginator: Paginator<Conversation>
) => Promise<Conversation[]> = async (
  conversationsPaginator: Paginator<Conversation>
) => {
  const data = conversationsPaginator.items
    // Comment out the .filter method below to also view empty conversations in the Conversation List page
    .filter((conversation) => !!conversation.lastMessage?.dateCreated)
  if (conversationsPaginator.hasNextPage) {
    const nextPage = await conversationsPaginator.nextPage()
    const nextPageConversations = await getAllConversationsRecursively(nextPage)
    data.push(...nextPageConversations)
  }
  return data
}

export const getAndFormatMessage = async (
  client: Client,
  conversationId: string
): Promise<ReduxMessage> => {
  try {
    const twilioConversation = await client.getConversationBySid(conversationId)
    const recentMessagePaginator = await twilioConversation.getMessages(1)

    const formattedRecentMessage = await formatMessage(
      recentMessagePaginator.items[0]
    )

    return formattedRecentMessage
  } catch {
    return {} as ReduxMessage
  }
}

export const getRecentMessagesByConversationId: (
  client: Client,
  sortedConversationIds: string[]
) => Promise<Record<string, ReduxMessage[]>> = async (
  client: Client,
  sortedConversationIds: string[]
) => {
  const formattedMessagesSettledPromises = await Promise.allSettled(
    sortedConversationIds.map(async (conversationId) => {
      const formattedRecentMessage = await getAndFormatMessage(
        client,
        conversationId
      )

      if (
        !formattedRecentMessage?.type ||
        (formattedRecentMessage.type === 'text' &&
          !formattedRecentMessage?.body)
      ) {
        return Promise.reject('Empty Message')
      }
      return [formattedRecentMessage]
    })
  )

  return formattedMessagesSettledPromises
    .map((promiseResult) =>
      promiseResult.status === 'rejected'
        ? [createErrorMessage()]
        : promiseResult.value
    )
    .reduce((messagesByConversationId, message, index) => {
      const conversationId = sortedConversationIds[index]
      messagesByConversationId[conversationId] = message
      return messagesByConversationId
    }, {} as Record<string, ReduxMessage[]>)
}

export const isSupportedMessageType = (message: ReduxMessage): boolean => {
  // When messages have not loaded it could display "not supported" placeholder for a brief moment.
  // By doing this, we make sure this won't run all the code until the whole message is stored.
  if (!message.type) return true
  const isSupported =
    message.type === 'text' ||
    message.attachedMedia?.contentType?.startsWith('image/') ||
    message.attachedMedia.contentType.startsWith('video')

  return isSupported
}

export const getMediaContentType = (media: ReduxMedia): MediaContentType => {
  return media.contentType.split('/')[0] as MediaContentType
}

const createErrorMessage = (): ReduxMessage => ({
  sid: '',
  index: 0,
  dateCreated: moment().toString(),
  body: '',
  author: '',
  participantSid: '',
  type: 'text',
  deliveryReceipt: {
    status: undefined,
    errorCode: undefined
  },
  failedToLoad: true,
  attachedMedia: {} as ReduxMedia
})

export const getTwilioMessage = async (
  client: Client,
  conversationId: string,
  indexOfMessage: number
): Promise<Message> => {
  const conversation = await client.getConversationBySid(conversationId)

  const messagePaginator = await conversation.getMessages(1, indexOfMessage)
  const sdkMessage = messagePaginator.items[0]
  return sdkMessage
}

export const transformErrorCodeIntoUserMessages = (
  errorCode?: string | 0
): { errorMessage: string; helperMessage?: string } => {
  const unrecognizedErrorCode = {
    errorMessage: ErrorMessages.TRY_AGAIN,
    helperMessage: undefined
  }
  if (!errorCode) {
    return unrecognizedErrorCode
  } else {
    return ERROR_MESSAGES_BY_ERROR_CODE[errorCode] || unrecognizedErrorCode
  }
}

const convertImageUrlToBlobFile = async (
  twilioMessage: Message
): Promise<File | Blob | undefined> => {
  if (!twilioMessage.attachedMedia?.length) {
    return
  }
  try {
    const imageUrl =
      (await twilioMessage.attachedMedia[0].getContentTemporaryUrl()) || ''
    const imageResponse = await fetch(imageUrl)
    return await imageResponse.blob()
  } catch {
    return undefined
  }
}

export const generateMediaOptions = async (
  imageFile: File
): Promise<AttachedMediaOptions | undefined> => {
  if (!imageFile) {
    return
  }
  try {
    const reader = new FileReader()
    reader.readAsDataURL(imageFile)
    return await new Promise((resolve) => {
      reader.onloadend = async () => {
        if (reader.result) {
          resolve({
            src: reader.result as string,
            contentType: imageFile.type,
            filename: imageFile.name || 're-sent-image',
            media: imageFile as File
          } as AttachedMediaOptions)
        }
      }
    })
  } catch {
    return undefined
  }
}

export const getResentMessageMediaOptions = async ({
  twilioMessage,
  messageType
}: {
  twilioMessage: Message
  messageType: MessageType
}): Promise<AttachedMediaOptions | undefined> => {
  if (messageType !== 'media') {
    return
  }
  const imageBlob = await convertImageUrlToBlobFile(twilioMessage)
  return await generateMediaOptions(imageBlob as File)
}

export const handleSendMessage = async (
  conversation: Conversation,
  messageText?: string,
  mediaContent?: AttachedMediaOptions[] | null,
  personalBookingLink?: string | null
): Promise<
  typeof MESSAGE_STATUS.queued | typeof MESSAGE_STATUS.sent | string
> => {
  try {
    if (!conversation) {
      throw Error('Conversation still loading...')
    }

    let messageIndex: number | null = 0
    const mediaErrorMessages: Error[] = []

    if (mediaContent) {
      messageIndex = await sendMediaMessages(
        mediaContent,
        conversation,
        messageIndex,
        mediaErrorMessages
      )
    }

    if (messageText) {
      messageIndex = await conversation
        .prepareMessage()
        .setBody(messageText)
        .build()
        .send()
    }

    if (personalBookingLink) {
      messageIndex = await conversation
        .prepareMessage()
        .setBody(personalBookingLink)
        .build()
        .send()
    }

    await conversation.advanceLastReadMessageIndex(messageIndex ?? 0)

    if (mediaErrorMessages.length) {
      throw mediaErrorMessages[0]
    }

    const twilioSdklastReadMessageIndex = conversation.lastReadMessageIndex ?? 0
    if (twilioSdklastReadMessageIndex < (messageIndex ?? 0)) {
      return MESSAGE_STATUS.queued
    }
    return MESSAGE_STATUS.sent
  } catch (e) {
    return (e as Error).message
  }
}

export const hasMessageFailedToBeDelivered = (message: ReduxMessage): boolean =>
  message.deliveryReceipt?.status === MESSAGE_STATUS.failed ||
  message.deliveryReceipt?.status === MESSAGE_STATUS.undelivered

export const getParticipantAttributes = async (
  conversation: Conversation
): Promise<{ customerId: string } | Record<string, unknown>> => {
  try {
    const participants = await conversation.getParticipants()
    const participant = participants.find(
      (participant) =>
        participant.attributes &&
        (participant.attributes as ParticipantAttributesT)['display_name'] ===
          conversation.friendlyName
    )
    return {
      customerId: (participant?.attributes as ParticipantAttributesT)
        ?.customer_id
    }
  } catch {
    return {}
  }
}

export const getConversationsWithCustomerIds = (
  conversations: ConversationsRecord,
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  customers.map((customer) => {
    const { customerId, additionalCustomerIds } = customer
    const allCustomerIds = [customerId, ...(additionalCustomerIds || [])]
    const conversation = Object.values(conversations).find((conversation) =>
      allCustomerIds.includes(conversation.customerId)
    )
    if (customerId && conversation) {
      customer = {
        ...customer,
        lastMessage: conversation.mostRecentMessageDate
      }
    }
    return customer
  })

export const getConversationSidByCustomerId = (
  conversations: ConversationsRecord,
  customerId: string
): string | undefined => {
  const conversation = Object.values(conversations).find(
    (conversation) => conversation.customerId === customerId
  )
  return conversation?.sid
}

const sendMediaMessages = (
  mediaContent: AttachedMediaOptions[],
  conversation: Conversation,
  messageIndex: number | null,
  errorMessages: Error[]
) =>
  mediaContent.reduce(
    (mediaMessagesSentPromise, media) =>
      mediaMessagesSentPromise.then(async (previousIndex) => {
        try {
          const currentIndex = await conversation
            .prepareMessage()
            .addMedia(media)
            .build()
            .send()

          return currentIndex
        } catch (error) {
          if (error instanceof Error) {
            errorMessages.push(error)
          }

          return previousIndex
        }
      }),
    Promise.resolve(messageIndex)
  )
