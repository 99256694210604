import { CurationT } from 'types/Curation'

const isSellerInitiatedPrivateBoard = ({ type, ocpId }: CurationT): boolean => {
  if (type === 'CustomerInitiated') {
    return false
  }
  if (!ocpId) {
    return false
  }
  return true
}

export default isSellerInitiatedPrivateBoard
