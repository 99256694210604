import { ReactElement, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import type { Moment } from 'moment'
import {
  Box,
  IconButton,
  Link,
  Typography,
  TablePagination,
  useTheme,
  Button
} from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import { useLocation, useHistory } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  COMMISSION_AGREEMENT_LINK,
  START_DATE_SEARCH_PARAM,
  END_DATE_SEARCH_PARAM
} from 'pages/ReportingHome/constants'
import BaseInformationDialog from 'components/BaseInformationDialog'
import BasePageAlert from 'components/BasePageAlert'
import StylingDatePicker from 'components/StylingDatePicker'
import AttributionItem from './components/AttributionItem'
import FiscalDatePicker from '../FiscalDatePicker'
import { scrollToTop } from 'utils/scrollIntoView'
import {
  setURLSearchParam,
  deleteURLSearchParams,
  getDecodedURLSearchParam,
  setURLSearchParams
} from 'utils/urlUtils'

import { BOARD_TYPE_FILTERS, DATE_RANGE } from 'types/CurationAttribution'
import {
  StartAndEndDatesT,
  getFiscalMonthStartAndEndDates,
  getFiscalQuarterStartAndEndDates,
  getFiscalYearStartAndEndDates
} from 'utils/fiscalDates'
import {
  formatStartAndEndDates,
  parseTypeForEmptyAttributionsText,
  shouldSendBoardType
} from './helpers'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import BoardTypeToggleButtonGroup from './components/BoardTypePicker'
import { useGetCurationAttributionsQuery } from 'services/employeeExperienceApi'

const FEED_PAGE_SIZE = 20

const AttributionsError = ({
  errorData
}: {
  errorData: unknown
}): JSX.Element => {
  return (
    <Box mt={2}>
      <BasePageAlert
        alertTitle={`Cannot load style board insights.`}
        errorDetails={{ errorData }}
        shouldShowRefresh={true}
      />
    </Box>
  )
}

const EmptyAttributions = ({
  selectedBoardType
}: {
  selectedBoardType: string
}): JSX.Element => {
  return (
    <Box mt={2}>
      <Typography variant="body2">
        {`You do not have any ${selectedBoardType} insights for the selected timeframe`}
      </Typography>
    </Box>
  )
}

const InfoDialogText = (): JSX.Element => {
  return (
    <>
      Commission won&apos;t be advanced on items until they&apos;ve been
      shipped.{' '}
      <Link
        color="secondary.main"
        href={COMMISSION_AGREEMENT_LINK}
        target="_blank"
      >
        Commission agreement
      </Link>
    </>
  )
}

const AttributionsFeed = (): ReactElement => {
  const history = useHistory()
  const { search } = useLocation()
  const theme = useTheme()

  const query = new URLSearchParams(search)
  const page = parseInt(query.get('page') || '1')
  const decodedStartDate = getDecodedURLSearchParam(
    query,
    START_DATE_SEARCH_PARAM
  )
  const decodedEndDate = getDecodedURLSearchParam(query, END_DATE_SEARCH_PARAM)
  const boardType = query.get('boardType') as BOARD_TYPE_FILTERS

  const [open, setOpen] = useState(false)
  const [rangeOption, setRangeOption] = useState<DATE_RANGE | undefined>(
    DATE_RANGE.ALL_TIME
  )
  const [showFiscalDateError, setShowFiscalDateError] = useState(false)

  const { data, isError, error, isFetching } = useGetCurationAttributionsQuery(
    {
      page,
      perPage: FEED_PAGE_SIZE,
      startDate: decodedStartDate,
      endDate: decodedEndDate,
      ...(shouldSendBoardType(boardType) && { group: boardType })
    },
    {
      skip:
        !!decodedStartDate &&
        moment(decodedEndDate).isBefore(moment(decodedStartDate))
    }
  )

  useEffect(() => {
    if (
      (!!decodedStartDate || !!decodedEndDate) &&
      rangeOption === DATE_RANGE.ALL_TIME
    ) {
      setRangeOption(undefined)
    }
  }, [decodedEndDate, decodedStartDate, rangeOption])

  const onClickOrderInfo = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const clearDateRange = () => {
    if (setShowFiscalDateError) setShowFiscalDateError(false)
    setRangeOption(undefined)
  }

  const resetDateRange = () => {
    if (setShowFiscalDateError) setShowFiscalDateError(false)
    setRangeOption(DATE_RANGE.ALL_TIME)
  }

  const handleStartDateChange = useCallback(
    (date: Moment) => {
      clearDateRange()

      const beginningOfDate = date.startOf('day')
      setURLSearchParam(
        START_DATE_SEARCH_PARAM,
        moment(beginningOfDate).format(),
        history
      )

      generateNewRelicLogs('attributionsFeed.startDateSelected')
    },
    [history]
  )

  const handleEndDateChange = useCallback(
    (date: Moment) => {
      clearDateRange()

      const endOfDate = date.endOf('day')
      setURLSearchParam(
        END_DATE_SEARCH_PARAM,
        moment(endOfDate).format(),
        history
      )

      generateNewRelicLogs('attributionsFeed.endDateSelected')
    },
    [history]
  )

  const saveFiscalDatesInSearchParams = useCallback(
    (startAndEndDates: StartAndEndDatesT | null) => {
      startAndEndDates
        ? setURLSearchParams(formatStartAndEndDates(startAndEndDates), history)
        : setShowFiscalDateError(true)
    },
    [history]
  )

  const handleDateRangeChange = useCallback(
    (dateRange: DATE_RANGE) => {
      setRangeOption(dateRange)

      const today = moment()
      switch (dateRange) {
        case DATE_RANGE.MONTH: {
          const startAndEndDates = getFiscalMonthStartAndEndDates(today)
          saveFiscalDatesInSearchParams(startAndEndDates)
          break
        }
        case DATE_RANGE.QUARTER: {
          const startAndEndDates = getFiscalQuarterStartAndEndDates(today)
          saveFiscalDatesInSearchParams(startAndEndDates)
          break
        }
        case DATE_RANGE.YEAR: {
          const startAndEndDates = getFiscalYearStartAndEndDates(today)
          saveFiscalDatesInSearchParams(startAndEndDates)
          break
        }
        case DATE_RANGE.ALL_TIME:
        default: {
          deleteURLSearchParams(
            [START_DATE_SEARCH_PARAM, END_DATE_SEARCH_PARAM],
            history
          )
          break
        }
      }

      generateNewRelicLogs('attributionsFeed.fiscalDateRangeSelected', {
        fiscalDateRange: dateRange
      })
    },
    [history, saveFiscalDatesInSearchParams]
  )

  return (
    <Box pb={13}>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={3}
        minWidth="100%"
        p={2}
        sx={{ backgroundColor: theme.palette.grey[50] }}
      >
        <BoardTypeToggleButtonGroup
          selectedToggle={boardType || BOARD_TYPE_FILTERS.ALL}
          setSelectedToggle={(value) =>
            setURLSearchParam('boardType', value, history)
          }
        />
        <FiscalDatePicker
          error={showFiscalDateError}
          handleChange={(range) => handleDateRangeChange(range)}
          selectedOption={rangeOption}
        />
        <Box display="flex" gap={1}>
          <StylingDatePicker
            key="start"
            value={decodedStartDate ? moment(decodedStartDate) : null}
            onChange={handleStartDateChange}
            maxDate={decodedEndDate ? moment(decodedEndDate) : moment()}
            label={'Start date'}
          />
          <StylingDatePicker
            key="end"
            value={decodedEndDate ? moment(decodedEndDate) : null}
            onChange={handleEndDateChange}
            minDate={decodedStartDate ? moment(decodedStartDate) : undefined}
            label={'End date'}
          />
          <Button
            onClick={() => {
              resetDateRange()
              deleteURLSearchParams(
                [START_DATE_SEARCH_PARAM, END_DATE_SEARCH_PARAM],
                history
              )
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
      <Box px={2}>
        {isFetching ? (
          <LoadingSpinner hideText={true} />
        ) : data?.meta.count === 0 ? (
          <EmptyAttributions
            selectedBoardType={parseTypeForEmptyAttributionsText(boardType)}
          />
        ) : isError ? (
          <AttributionsError errorData={error} />
        ) : (
          <Box>
            <Typography
              my={3}
              variant="subtitle2"
              display="flex"
              alignItems="center"
            >
              Style board order insights
              <IconButton
                aria-label="info"
                color="info"
                size="small"
                onClick={onClickOrderInfo}
              >
                <InfoOutlinedIcon
                  sx={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              </IconButton>
            </Typography>
            <BaseInformationDialog
              cancel={handleClose}
              contextText={<InfoDialogText />}
              onClose={handleClose}
              open={open}
              title={'Commission Information'}
            />
            {data &&
              data?.attributions?.map((attribution, index) => (
                <AttributionItem key={index} attribution={attribution} />
              ))}
            <TablePagination
              component="div"
              count={data ? data.meta.count : 0}
              onPageChange={(_, newPage) => {
                const searchParams = new URLSearchParams(location.search)
                searchParams.set('page', String(newPage + 1))
                history.replace({ search: searchParams.toString() })

                scrollToTop()
              }}
              page={page - 1}
              rowsPerPage={FEED_PAGE_SIZE}
              rowsPerPageOptions={[FEED_PAGE_SIZE]}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AttributionsFeed
