import { ReactElement } from 'react'
import { Button, Box, Typography, Stack } from '@mui/material'
import { ProductT } from 'types/Product'
import ItemImage from '../../../../components/ItemImage'

export type PropsT = {
  product: ProductT
  onClickForPDP: (webStyleId: string) => void
}

const OrderItem = (props: PropsT): ReactElement => {
  const { product, onClickForPDP } = props

  return (
    <>
      <Stack direction="row" my={2} spacing={2}>
        <Box sx={{ minWidth: '122px', width: '122px' }}>
          <Button
            aria-label="view product details"
            onClick={() =>
              !!product.style && onClickForPDP(String(product.style))
            }
          >
            <ItemImage
              imageAltText={product.name}
              imageUrl={product.imageUrl}
            />
          </Button>
        </Box>
        <Box>
          <Typography variant="body2">
            {product.brandName} {product.name}
          </Typography>
          <Typography variant="body2" mt="4px">
            {product.size} {product.color}
          </Typography>
          <Typography variant="body2" mt="8px">
            ${Number(product.purchasePrice).toFixed(2)}
          </Typography>
          <Typography variant="body2" mt="8px">
            Item #{product.styleNumber}
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

export default OrderItem
