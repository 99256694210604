import { ReactElement, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import CurationReview from '../CurationReviewContent'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import {
  useAbandonCurationMutation,
  useAddItemToBagMutation,
  useGetCurationsQuery
} from 'services/curationSvc'
import { useRetrieveShoppingTokenMutation } from 'services/employeeExperienceApi'
import { AbandonCurationArgsT, CURATION_STATE } from 'types/Curation'
import oktaTokenStorage from 'utils/okta-token-utils'

export enum CurationInProgressDialog {
  AbandonOrContinue = 'ABANDON_OR_CONTINUE',
  None = 'NONE'
}

const CurationReviewContainer = (): ReactElement => {
  const history = useHistory()
  const { search } = useLocation()
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()

  const [
    abandonCuration,
    {
      endpointName: abandonEndpoint,
      error: abandonError,
      isError: isAbandonCurationError,
      isLoading: isAbandonCurationLoading,
      originalArgs: abandonOriginalArgs
    }
  ] = useAbandonCurationMutation()

  const handleCancel = async (abandonCurationArgs: AbandonCurationArgsT) => {
    try {
      await abandonCuration(abandonCurationArgs).unwrap()
      history.push('/')
    } catch (error) {
      return
    }
  }

  const {
    data: getCurationsData,
    endpointName: getCurationsEndpointName,
    error: getCurationsError,
    isError: isGetCurationsError,
    isFetching: isGetCurationsFetching,
    originalArgs: getCurationsOriginalArgs
  } = useGetCurationsQuery({
    state: CURATION_STATE.IN_PROGRESS,
    employeeId
  })

  const [inProgressCuration] = getCurationsData?.curations || []

  const [
    fetchToken,
    {
      data: fetchTokenData,
      endpointName: fetchTokenEndpointName,
      error: fetchTokenError,
      isError: isFetchTokenError,
      originalArgs: fetchTokenOriginalArgs
    }
  ] = useRetrieveShoppingTokenMutation()

  const [
    addItemToBag,
    {
      endpointName: addToBagEndpoint,
      error: addItemError,
      isError: isAddItemError,
      isLoading: isAddItemLoading
    }
  ] = useAddItemToBagMutation()

  useEffect(() => {
    if (inProgressCuration) {
      fetchToken({
        curationId: inProgressCuration.id,
        employeeEmail,
        employeeId
      })
        .unwrap()
        .then((fulfilled) => {
          const searchParams = new URLSearchParams(search)
          const rmsSku = searchParams.get('rmsSku')
          if (rmsSku && inProgressCuration.shoppingSessionId) {
            addItemToBag({
              rmsSku,
              token: fulfilled.token,
              shopperId: inProgressCuration.shoppingSessionId
            })
            history.replace('/review')
          }
          return
        })
        .catch(() => null)
    }
  }, [
    addItemToBag,
    employeeEmail,
    employeeId,
    fetchToken,
    history,
    inProgressCuration,
    search
  ])

  const initCheckoutToken = fetchTokenData?.token

  if (isGetCurationsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getCurationsEndpointName,
          errorData: getCurationsError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          },
          originalArgs: getCurationsOriginalArgs
        }}
      />
    )
  }

  if (isFetchTokenError) {
    return (
      <PageError
        errorDetails={{
          endpoint: fetchTokenEndpointName,
          errorData: fetchTokenError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          },
          originalArgs: fetchTokenOriginalArgs
        }}
      />
    )
  }

  if (isAddItemError) {
    return (
      <PageError
        errorDetails={{
          endpoint: addToBagEndpoint,
          errorData: addItemError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          }
        }}
      />
    )
  }

  if (isAbandonCurationError) {
    return (
      <PageError
        errorDetails={{
          endpoint: abandonEndpoint,
          errorData: abandonError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          },
          originalArgs: abandonOriginalArgs
        }}
      />
    )
  }

  const userHasNoInProgressCuration =
    !isGetCurationsFetching && !inProgressCuration

  if (userHasNoInProgressCuration) {
    return <Redirect to="/" />
  }

  if (
    isAbandonCurationLoading ||
    isAddItemLoading ||
    isGetCurationsFetching ||
    !inProgressCuration ||
    !initCheckoutToken
  ) {
    return <LoadingSpinner />
  }

  return (
    <CurationReview
      handleCancel={handleCancel}
      curationInProgressDialog={CurationInProgressDialog.None}
      inProgressCuration={inProgressCuration}
      initCheckoutToken={initCheckoutToken}
    />
  )
}

export default CurationReviewContainer
