import React from 'react'
import { Divider, Container } from '@mui/material'

interface DividerComponentT {
  marginSize?: number
}

const DividerComponent: React.FC<DividerComponentT> = ({ marginSize }) => (
  <Container disableGutters>
    <Divider
      sx={{
        borderBottomWidth: 16,
        borderColor: '#F9FAFA',
        my: marginSize ? marginSize : 3
      }}
    />
  </Container>
)

export default DividerComponent
