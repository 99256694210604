import { ReactElement, useEffect } from 'react'

import { v4 } from 'uuid'

import {
  Box,
  Button,
  Fade,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'

import { useGetProductByUPCMutation } from 'services/holdsApi'

import BaseDialog from 'components/BaseDialog'

import {
  HoldsProductHandleActionItemT,
  HoldsStartNewHoldFormParamsT
} from 'pages/Holds/types'

import {
  HOLDS_ADD_MAX_LINE_ITEM_LIMIT,
  HOLDS_LINE_ITEM_LIMIT_REACHED_SNACKBAR_ERROR_MESSAGE,
  HOLDS_LINE_ITEM_LIMIT_REACHED_SNACKBAR_ERROR_TITLE,
  HOLDS_STYLE_LABEL_ASTERISK
} from 'pages/Holds/constants'

import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import { useHoldsToggle } from 'pages/Holds/hooks'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsProductItemImage from 'pages/Holds/shared/components/HoldsProductItemImage'

import HoldsDialogBodyAddItem from './components/HoldsDialogBodyAddItem'
import HoldsDialogBodyEditItems from './components/HoldsDialogBodyEditItems'

const HoldsManageItems = ({
  value,
  onChange,
  handleRemoveItem,
  error
}: {
  value: HoldsStartNewHoldFormParamsT['itemsOnHold']
  onChange: HoldsProductHandleActionItemT
  handleRemoveItem: HoldsProductHandleActionItemT
  error?: boolean
}): ReactElement => {
  const hasProductItems = Boolean(value.length)

  const { handleNotificationRemove, handleNotificationAddErrorMessage } =
    useHoldsContextNotification()

  const [fetchGetProductByUPC, { isLoading, data }] =
    useGetProductByUPCMutation()

  const {
    isOpen: isOpenAddItemsDialog,
    handleOpen: handleOpenAddItemsDialog,
    handleClose: handleCloseAddItemsDialog
  } = useHoldsToggle({
    allowClose: !isLoading
  })

  const {
    isOpen: isOpenEditItemsDialog,
    handleOpen: handleOpenEditDialog,
    handleClose: handleCloseEditDialog
  } = useHoldsToggle()

  const {
    isOpen: isOpenPopupUpcDetails,
    handleOpen: handleOpenPopupUpcDetails,
    handleClose: handleClosePopupUpcDetails
  } = useHoldsToggle()

  const {
    isOpen: isOpenPopupUpcError,
    handleOpen: handleOpenPopupUpcError,
    handleClose: handleClosePopupUpcError
  } = useHoldsToggle()

  const notAllowFetchGetProductByUpc = isOpenPopupUpcDetails || isLoading

  const allowAddMoreItems = value.length < HOLDS_ADD_MAX_LINE_ITEM_LIMIT

  /**
   * FYI: after closing any dialogue should hide all notification
   */
  useEffect(() => {
    if (!isOpenAddItemsDialog || !isOpenEditItemsDialog) {
      handleNotificationRemove()
    }
  }, [isOpenAddItemsDialog, isOpenEditItemsDialog, handleNotificationRemove])

  return (
    <FormControl
      fullWidth
      error={error}
      sx={{
        '.MuiFormHelperText-root': {
          marginLeft: 0
        }
      }}
    >
      <Box display="flex" alignItems="center" mb={0.5}>
        <FormLabel
          id="hold-add-item"
          required
          sx={{
            ...HOLDS_STYLE_LABEL_ASTERISK,
            color: 'text.primary',
            '&.Mui-error': {
              color: 'text.primary'
            },
            flex: 1
          }}
        >
          <strong>Item(s) on hold</strong>
        </FormLabel>

        {!hasProductItems && (
          <Button
            data-testid="add_item_to_hold"
            startIcon={<AddIcon />}
            onClick={handleOpenAddItemsDialog}
          >
            Add
          </Button>
        )}
        {hasProductItems && (
          <Button
            data-testid="edit_hold_items"
            startIcon={<EditIcon />}
            onClick={handleOpenEditDialog}
          >
            Edit
          </Button>
        )}
      </Box>

      {error && (
        <Fade in={error}>
          <FormHelperText>Items on hold are required</FormHelperText>
        </Fade>
      )}

      <Stack
        direction="row"
        spacing={2}
        overflow="auto"
        sx={{
          pb: 1
        }}
      >
        {value.map((productItem, index) => (
          <HoldsProductItemImage key={index} productItem={productItem} />
        ))}
      </Stack>

      <HoldsDialog
        isDrawerMode
        open={isOpenAddItemsDialog}
        onClose={handleCloseAddItemsDialog}
      >
        <HoldsDialogBodyAddItem
          isLoading={isLoading}
          allowAddMoreItems={allowAddMoreItems}
          handleClose={handleCloseAddItemsDialog}
          handleSubmit={async (upc) => {
            if (notAllowFetchGetProductByUpc) {
              return
            }

            /**
             * Show adding reach limit message and close add popup and open edit popup dialog
             */
            if (value.length >= HOLDS_ADD_MAX_LINE_ITEM_LIMIT) {
              handleNotificationAddErrorMessage({
                title: HOLDS_LINE_ITEM_LIMIT_REACHED_SNACKBAR_ERROR_TITLE,
                subTitle: HOLDS_LINE_ITEM_LIMIT_REACHED_SNACKBAR_ERROR_MESSAGE
              })

              handleCloseAddItemsDialog()
              handleOpenEditDialog()

              return
            }

            try {
              const response = await fetchGetProductByUPC({ upc }).unwrap()

              if (response.sku) {
                handleOpenPopupUpcDetails()
              } else {
                handleOpenPopupUpcError()
              }
            } catch (e) {
              handleOpenPopupUpcError()
            }
          }}
        />
      </HoldsDialog>

      <BaseDialog
        data-testid="add_upc_details_popup"
        fullWidth
        open={isOpenPopupUpcDetails}
        onClose={handleClosePopupUpcDetails}
        title={'Item Result'}
        content={
          data && (
            <>
              <Box mb={2}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography sx={{ mb: 0.5 }}>{data.name}</Typography>
                  <Typography>
                    <strong>Style/Color:</strong> {data.color}
                  </Typography>
                  <Typography>
                    <strong>Size:</strong> {data.size}
                  </Typography>
                  <Typography>
                    <strong>Item #:</strong> {data.sku}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" justifyContent="center">
                <HoldsProductItemImage
                  productItem={data}
                  height={174}
                  width={145}
                />
              </Box>
            </>
          )
        }
        confirm={{
          action: () => {
            if (!data) {
              return
            }

            onChange({
              ...data,
              idGenerated: v4()
            })
            handleClosePopupUpcDetails()
          },
          text: 'Add item'
        }}
        cancel={{
          action: handleClosePopupUpcDetails,
          text: 'Cancel'
        }}
      />

      <BaseDialog
        open={isOpenPopupUpcError}
        onClose={handleClosePopupUpcError}
        title={'No item match found'}
        contentText={
          "Try scanning the barcode or manually typing the UPC again. Note that we can't display results for non-Nordstrom items"
        }
        confirm={{
          action: handleClosePopupUpcError,
          text: 'Try again'
        }}
      />

      <HoldsDialog
        data-testid="edit_items_dialog"
        open={isOpenEditItemsDialog}
        onClose={handleCloseEditDialog}
      >
        <HoldsDialogBodyEditItems
          value={value}
          allowAddMoreItems={allowAddMoreItems}
          handleClose={handleCloseEditDialog}
          handleOpenAddItem={handleOpenAddItemsDialog}
          handleRemoveItem={handleRemoveItem}
        />
      </HoldsDialog>
    </FormControl>
  )
}

export default HoldsManageItems
