import React from 'react'

const SCROLL_TYPE: Record<string, ScrollBehavior> = {
  AUTO: 'auto',
  SMOOTH: 'smooth'
}

export const scrollInitialLoad = (
  containerRef: React.RefObject<HTMLElement>,
  bottomRef: React.RefObject<HTMLElement>
): undefined | void => {
  if (!containerRef.current || !bottomRef.current) {
    return
  }

  scrollPage(bottomRef, SCROLL_TYPE.auto)
}

export const scrollNewMessage = (
  containerRef: React.RefObject<HTMLElement>,
  bottomRef: React.RefObject<HTMLElement>,
  messageSid: string
): undefined | void => {
  if (!containerRef.current || !bottomRef.current) {
    return
  }

  const lastMessageRef = containerRef.current.querySelector(`#${messageSid}`)

  if (!lastMessageRef || lastMessageRef.nodeName !== 'IMG') {
    scrollPage(bottomRef, SCROLL_TYPE.SMOOTH)
    return
  }

  if ((lastMessageRef as HTMLImageElement).complete) {
    scrollPage(bottomRef, SCROLL_TYPE.SMOOTH)
  } else {
    lastMessageRef.addEventListener(
      'load',
      () => scrollPage(bottomRef, SCROLL_TYPE.SMOOTH),
      {
        once: true
      }
    )
    lastMessageRef.addEventListener(
      'error',
      () => scrollPage(bottomRef, SCROLL_TYPE.SMOOTH),
      {
        once: true
      }
    )
  }
}

const scrollPage = (
  bottomRef: React.RefObject<HTMLElement>,
  scrollBehavior: ScrollBehavior
) => {
  if (!bottomRef.current) {
    return
  }

  bottomRef.current.scrollIntoView({ behavior: scrollBehavior })
}
