import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Button, CircularProgress, TablePagination } from '@mui/material'
import { TabPanel } from '@mui/lab'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SortIcon from '@mui/icons-material/Sort'
import { customStylingTheme } from 'theme'
import {
  EMPTY_CUSTOMER_BOARDS_MESSAGE,
  EMPTY_PUBLIC_BOARDS_MESSAGE
} from 'pages/constants'
import BaseSnackbar from 'components/BaseSnackbar'
import CurationCardListTabs, {
  TabPosition
} from 'components/CurationCardListTabs'
import CurationCardList from 'components/CurationCardList'
import EmptyQueue, { EmptyQueueIconOption } from 'components/EmptyQueue'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import SortDialog from 'components/SortDialog'
import UnpublishAlert from 'components/UnpublishAlert'
import {
  useGetPublishedCurationsQuery,
  useUnpublishCurationMutation
} from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import { scrollToTop } from 'utils/scrollIntoView'
import { PUBLISHED_CURATION_TYPE } from 'types/Curation'

const CUSTOMER_PAGE_PARAM_NAME = 'customerPage'
const FINISHED_BOARDS_PER_PAGE = 20
const PUBLISHED_ENDPOINT_API_VERSION = '2.0'
const PUBLIC_PAGE_PARAM_NAME = 'publicPage'
const TAB_POSITION = 'finishedBoardTabPosition'

export enum OrderBy {
  NEWEST = 'DESC',
  OLDEST = 'ASC'
}

const FinishedBoardsContent = (): ReactElement => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const [isSelectSortModalOpen, setIsSelectSortModalOpen] = useState(false)
  const [selectedSortBy, setSelectedSortBy] = useState(OrderBy.NEWEST)
  const [tabPosition, setTabPosition] = useState(TabPosition.CUSTOMER)

  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const customerPageParam = parseInt(query.get(CUSTOMER_PAGE_PARAM_NAME) || '1')
  const publicPageParam = parseInt(query.get(PUBLIC_PAGE_PARAM_NAME) || '1')

  useEffect(() => {
    const storedTabPosition = localStorage.getItem(TAB_POSITION)
    if (
      storedTabPosition &&
      Object.values(TabPosition).includes(storedTabPosition as TabPosition)
    ) {
      setTabPosition(String(storedTabPosition) as TabPosition)
    }
    return () => {
      localStorage.removeItem(TAB_POSITION)
    }
  }, [])

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: TabPosition
  ) => {
    setTabPosition(newValue)
    localStorage.setItem(TAB_POSITION, String(newValue))
  }

  const {
    data: customerCurationsData,
    error: customerError,
    isError: isCustomerError,
    isFetching: isCustomerFetching,
    isLoading: isCustomerLoading
  } = useGetPublishedCurationsQuery({
    employeeId,
    type: PUBLISHED_CURATION_TYPE.CUSTOMER,
    version: PUBLISHED_ENDPOINT_API_VERSION,
    page: customerPageParam,
    perPage: FINISHED_BOARDS_PER_PAGE,
    orderBy: selectedSortBy
  })

  const {
    data: publicCurationsData,
    error: publicError,
    isError: isPublicError,
    isFetching: isPublicFetching,
    isLoading: isPublicLoading
  } = useGetPublishedCurationsQuery({
    employeeId,
    type: PUBLISHED_CURATION_TYPE.PUBLIC,
    version: PUBLISHED_ENDPOINT_API_VERSION,
    page: publicPageParam,
    perPage: FINISHED_BOARDS_PER_PAGE,
    orderBy: selectedSortBy
  })

  const [
    unpublishCuration,
    {
      error: unpublishError,
      isError: isUnpublishError,
      isSuccess: isUnpublishSuccess
    }
  ] = useUnpublishCurationMutation()

  if (isCustomerLoading || isPublicLoading) {
    return <LoadingSpinner />
  }

  if (isCustomerError) {
    return <PageError errorDetails={{ errorData: customerError }} />
  }

  if (isPublicError) {
    return <PageError errorDetails={{ errorData: publicError }} />
  }

  const onPageChange = (
    _event: null,
    newPage: number,
    pageParamName: string
  ): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(pageParamName, String(newPage + 1))
    history.replace({ search: searchParams.toString() })

    scrollToTop()
  }

  const onSelectSortBy = (newSelectedSortBy: OrderBy) => {
    if (newSelectedSortBy === selectedSortBy) {
      setIsSelectSortModalOpen(false)
      return
    }

    setSelectedSortBy(newSelectedSortBy)
    history.replace({ search: undefined })
    setIsSelectSortModalOpen(false)
  }

  if (customerCurationsData && publicCurationsData) {
    return (
      <Box>
        {isUnpublishError && <UnpublishAlert unpublishError={unpublishError} />}
        {isUnpublishSuccess && (
          <BaseSnackbar message={'Public board deleted.'} />
        )}
        <SortDialog
          title={'Sort by'}
          options={[
            {
              text: 'Newest first',
              action: () => onSelectSortBy(OrderBy.NEWEST),
              selected: selectedSortBy === OrderBy.NEWEST
            },
            {
              text: 'Oldest first',
              action: () => onSelectSortBy(OrderBy.OLDEST),
              selected: selectedSortBy === OrderBy.OLDEST
            }
          ]}
          onClose={() => setIsSelectSortModalOpen(false)}
          open={isSelectSortModalOpen}
        />
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<SortIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={() => setIsSelectSortModalOpen(true)}
        >
          Sort
        </Button>
        <CurationCardListTabs
          handleTabChange={handleTabChange}
          tabPosition={tabPosition}
        >
          {isCustomerFetching || isPublicFetching ? (
            <Box display="flex" justifyContent="center" my={12}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TabPanel value={TabPosition.CUSTOMER} sx={{ p: 0 }}>
                {customerCurationsData.curations.length > 0 ? (
                  <>
                    <CurationCardList
                      curations={customerCurationsData.curations}
                      isPublicBoardList={false}
                    />
                    <TablePagination
                      component="div"
                      count={
                        customerCurationsData
                          ? customerCurationsData.meta.count
                          : 0
                      }
                      onPageChange={(_, newPage) =>
                        onPageChange(null, newPage, CUSTOMER_PAGE_PARAM_NAME)
                      }
                      page={customerPageParam - 1}
                      rowsPerPage={FINISHED_BOARDS_PER_PAGE}
                      rowsPerPageOptions={[FINISHED_BOARDS_PER_PAGE]}
                    />
                  </>
                ) : (
                  <EmptyQueue
                    title={'No customer boards'}
                    subtitle={EMPTY_CUSTOMER_BOARDS_MESSAGE}
                    disableContainerGutters={true}
                    height={'45vh'}
                    icon={EmptyQueueIconOption.FINISHED_BOARDS_CUSTOMER}
                    maxWidth={customStylingTheme.spacing(34)}
                  />
                )}
              </TabPanel>
              <TabPanel value={TabPosition.PUBLIC} sx={{ p: 0 }}>
                {publicCurationsData.curations.length > 0 ? (
                  <>
                    <CurationCardList
                      curations={publicCurationsData.curations}
                      isPublicBoardList={true}
                      unpublishCuration={unpublishCuration}
                    />
                    <TablePagination
                      component="div"
                      count={
                        publicCurationsData ? publicCurationsData.meta.count : 0
                      }
                      onPageChange={(_, newPage) =>
                        onPageChange(null, newPage, PUBLIC_PAGE_PARAM_NAME)
                      }
                      page={publicPageParam - 1}
                      rowsPerPage={FINISHED_BOARDS_PER_PAGE}
                      rowsPerPageOptions={[FINISHED_BOARDS_PER_PAGE]}
                    />
                  </>
                ) : (
                  <EmptyQueue
                    title={'No public boards'}
                    subtitle={EMPTY_PUBLIC_BOARDS_MESSAGE}
                    disableContainerGutters={true}
                    height={'45vh'}
                    icon={EmptyQueueIconOption.FINISHED_BOARDS_PUBLIC}
                    maxWidth={customStylingTheme.spacing(34)}
                  />
                )}
              </TabPanel>
            </>
          )}
        </CurationCardListTabs>
      </Box>
    )
  }

  return <></>
}

export default FinishedBoardsContent
