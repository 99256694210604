import { ReactElement } from 'react'
import { Box } from '@mui/material'

import { baseSkeletonStyling } from './constants'

import TextSkeleton from './components/TextSkeleton'
import ImageSkeleton from './components/ImageSkeleton'

export type PropsT = {
  width?: string
  height?: string
}

const Skeleton = (props: PropsT): ReactElement => {
  const { width = '200px', height = '20px' } = props

  return (
    <Box
      data-testid="skeleton"
      sx={{
        width,
        height,
        ...baseSkeletonStyling
      }}
    />
  )
}

Skeleton.Text = TextSkeleton
Skeleton.Image = ImageSkeleton

export default Skeleton
