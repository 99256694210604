export const fomatConsentedCustomerTripsText = (
  numberOfTips: number
): string => {
  if (numberOfTips === 0) {
    return 'No trips'
  } else if (numberOfTips === 1) {
    return `${numberOfTips} trip`
  } else {
    return `${numberOfTips} trips`
  }
}
