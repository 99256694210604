import { ListItem, ListItemText } from '@mui/material'
import { ReactElement } from 'react'

interface ItemTilePropsT {
  primaryText: string
  secondaryText: string | null | undefined
}

const ItemTile = (props: ItemTilePropsT) => {
  const { primaryText, secondaryText } = props
  return (
    <ListItem divider={true}>
      <ListItemText
        primaryTypographyProps={{
          variant: 'subtitle2'
        }}
        primary={primaryText}
        secondaryTypographyProps={{
          variant: 'body2'
        }}
        secondary={secondaryText}
      />
    </ListItem>
  )
}

interface PropsT {
  loyaltyLevel: string | null | undefined
  cardMember: boolean | null | undefined
}

const CardAndLoyaltyStatus = (props: PropsT): ReactElement => {
  const { loyaltyLevel, cardMember } = props
  const isCardMember = cardMember ? 'Yes' : 'No'

  const LIST_ITEMS_TITLES = {
    CARDMEMBER: 'Cardmember',
    NORDY_STATUS: 'Nordy Club status'
  }

  return (
    <>
      <ItemTile
        primaryText={LIST_ITEMS_TITLES.NORDY_STATUS}
        secondaryText={loyaltyLevel ? loyaltyLevel : 'No loyalty status'}
      />
      <ItemTile
        primaryText={LIST_ITEMS_TITLES.CARDMEMBER}
        secondaryText={isCardMember}
      />
    </>
  )
}

export default CardAndLoyaltyStatus
