import get from 'lodash/get'

import {
  HoldsPrintingErrorsStatus,
  HoldsPrintingStatus
} from 'pages/Holds/types'

import { NotificationAlertT } from 'pages/Holds/contexts/HoldsContextNotification'

import {
  HOLDS_TEXT_HOLD_TICKET_NOT_REPRINTED,
  HOLDS_POS_GENERAL_EXCEPTION_TITLE,
  HOLDS_PRINTER_COVER_OPEN_MESSAGE,
  HOLDS_PRINTER_COVER_OPEN_TITLE,
  HOLDS_PRINTER_NO_PAPER_MESSAGE,
  HOLDS_PRINTER_NO_PAPER_TITLE,
  HOLDS_PRINTER_NOT_AVAILABLE_TITLE,
  HOLDS_PRINTER_NOT_CONFIGURED_TITLE,
  HOLDS_PRINTER_OFFLINE_MESSAGE,
  HOLDS_PRINTER_OFFLINE_TITLE,
  HOLDS_PRINTER_OUT_OF_TONER_TITLE,
  HOLDS_PRINTER_STATUS_UNKNOWN_TITLE
} from 'pages/Holds/constants'

const HoldsPrintingErrorInfo = {
  [HoldsPrintingStatus.PRINTER_COVER_OPEN]: {
    title: HOLDS_PRINTER_COVER_OPEN_TITLE,
    subTitle: HOLDS_PRINTER_COVER_OPEN_MESSAGE
  },
  [HoldsPrintingStatus.PRINTER_OFFLINE]: {
    title: HOLDS_PRINTER_OFFLINE_TITLE,
    subTitle: HOLDS_PRINTER_OFFLINE_MESSAGE
  },
  [HoldsPrintingStatus.PRINTER_NO_PAPER]: {
    title: HOLDS_PRINTER_NO_PAPER_TITLE,
    subTitle: HOLDS_PRINTER_NO_PAPER_MESSAGE
  },
  [HoldsPrintingStatus.PRINTER_NOT_CONFIGURED]: {
    title: HOLDS_PRINTER_NOT_CONFIGURED_TITLE
  },
  [HoldsPrintingStatus.PRINTER_NOT_AVAILABLE]: {
    title: HOLDS_PRINTER_NOT_AVAILABLE_TITLE
  },
  [HoldsPrintingStatus.PRINTER_OUT_OF_TONER]: {
    title: HOLDS_PRINTER_OUT_OF_TONER_TITLE
  },
  [HoldsPrintingStatus.PRINTER_STATUS_UNKNOWN]: {
    title: HOLDS_PRINTER_STATUS_UNKNOWN_TITLE
  },
  [HoldsPrintingStatus.POS_GENERAL_EXCEPTION]: {
    title: HOLDS_POS_GENERAL_EXCEPTION_TITLE
  }
}

export const getPrinterErrorInfo = (
  status: HoldsPrintingErrorsStatus
): NotificationAlertT =>
  HoldsPrintingErrorInfo[status] ?? {
    title: HOLDS_TEXT_HOLD_TICKET_NOT_REPRINTED
  }

export const getResponseErrorMessageOrErrorMessage = (
  error: unknown
): string => {
  return get(error, 'data.message') || get(error, 'message')
}
