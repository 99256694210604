import { Box } from '@mui/material'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { twilioActions, twilioSelectors } from 'services/twilioSlice'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { getTime } from '../../../../utils/date-utils'
import oktaTokenStorage from 'utils/okta-token-utils'

import SingleMessage from './components/SingleMessage/SingleMessage'
import MessagesDateHeading from './components/MessagesDateHeading'
import AdditionalMessages from './components/AdditionalMessages'
import MessageStatusErrorModal from './components/MessageStatusErrorModal'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import {
  getResentMessageMediaOptions,
  getTwilioMessage,
  handleSendMessage
} from 'services/twilioSlice/utils'
import { Client, Message } from '@twilio/conversations'
import { AttachedMediaOptions, ReduxMessage } from 'types/Twilio'
import {
  scrollInitialLoad,
  scrollNewMessage
} from 'pages/CustomerMessages/utils'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_SINGLE_CONVERSATION_PAGE_SEND_MESSAGE_RETRY } from 'constants/clienteling/newRelicEvents/nrSingleConversationPage'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'

const ConversationMessageFeed = ({
  client,
  selectedConversationId,
  additionalMessagesAreLoading,
  containerRef,
  fetchAdditionalMessages,
  hasAdditionalMessages
}: {
  client: Client
  selectedConversationId: string | null
  additionalMessagesAreLoading: boolean
  containerRef: React.RefObject<HTMLDivElement>
  fetchAdditionalMessages: () => void
  hasAdditionalMessages: boolean
}): ReactElement => {
  const appDispatch = useAppDispatch()
  const messages = useAppSelector(
    twilioSelectors.activeConversationMessagesSelector
  )

  const isMobileDevice = !!checkBrowserClient.isMobile()
  const stylistEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const bottomRef = useRef<HTMLDivElement>(null)

  const [currentScrollPosition, setCurrentScrollPosition] = useState(0)
  const [lastMessageSid, setLastMessageSid] = useState('')
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const [clickedMessage, setClickedMessage] = useState<
    ReduxMessage | undefined
  >()
  const [isMessageStatusModalOpen, setIsMessageStatusModalOpen] =
    useState(false)
  const [
    areMessageStatusModalActionsDisabled,
    setAreMessageStatusModalActionsDisabled
  ] = useState(false)
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const [isRetryingSendingMessage, setIsRetryingMessage] = useState(false)
  const [isRemovingMessage, setIsRemovingMessage] = useState(false)

  // Handle Scrolling Events
  useEffect(() => {
    if (!containerRef.current || !bottomRef.current) {
      return
    }

    if (!messages?.length) {
      return
    }

    const isRealtimeMessage =
      lastMessageSid !== messages[messages.length - 1]?.sid

    if (isInitialLoad) {
      // Jump to bottom message on initial load
      scrollInitialLoad(containerRef, bottomRef)
      setLastMessageSid(messages[messages.length - 1]?.sid)
      setIsInitialLoad(false)
    } else if (isRealtimeMessage) {
      // Scroll to bottom message on a new real time message
      const newLastMessageSid = messages[messages.length - 1]?.sid
      scrollNewMessage(containerRef, bottomRef, newLastMessageSid)
      setLastMessageSid(newLastMessageSid)
      setCurrentScrollPosition(0)
    } else if (containerRef.current && currentScrollPosition) {
      // Keep the scroll at the same position when pagination messages are loaded
      const { scrollHeight, clientHeight } = containerRef.current
      containerRef.current.scrollTop =
        scrollHeight - currentScrollPosition - clientHeight
    }
  }, [
    lastMessageSid,
    setLastMessageSid,
    messages,
    setCurrentScrollPosition,
    currentScrollPosition,
    containerRef,
    isInitialLoad
  ])

  const handleFetchMoreMessages = () => {
    if (additionalMessagesAreLoading || !containerRef.current) {
      return
    }

    const { scrollHeight, clientHeight } = containerRef.current

    // Set the current scroll position so it will not move when more messages are loaded
    setCurrentScrollPosition(scrollHeight - clientHeight)
    fetchAdditionalMessages()
  }

  // Variable to keep track of the date header for grouping messages
  let messageHeaderDate = ''

  const addDateHeaderIfNeeded = (date: string) => {
    const currentMessageDate = getTime(date)

    // Only show the date header if it's different from the previous message
    if (messageHeaderDate === currentMessageDate) {
      return null
    }

    messageHeaderDate = currentMessageDate

    return (
      <MessagesDateHeading
        date={currentMessageDate}
        isMobileDevice={isMobileDevice}
      />
    )
  }

  const resetModalState = () => {
    setIsMessageStatusModalOpen(false)
    setClickedMessage(undefined)
    setAreMessageStatusModalActionsDisabled(false)
    setIsRemovingMessage(false)
    setIsRetryingMessage(false)
  }

  const removeMessage = async ({
    reduxMessage,
    twilioMessage = undefined,
    isMessageSendRetry = false
  }: {
    reduxMessage: ReduxMessage
    twilioMessage?: Message
    isMessageSendRetry?: boolean
  }) => {
    if (client && selectedConversationId) {
      if (!isMessageSendRetry) {
        setIsRemovingMessage(true)
      }
      setAreMessageStatusModalActionsDisabled(true)
      const message: Message = twilioMessage
        ? twilioMessage
        : await getTwilioMessage(
            client,
            selectedConversationId,
            reduxMessage.index
          )
      const deletedMessage = await message?.remove()
      appDispatch(
        twilioActions.removeMessageFromConversation({
          conversationId: selectedConversationId,
          messageId: reduxMessage.sid
        })
      )
      if (!isMessageSendRetry) {
        resetModalState()
      }
      return deletedMessage
    }
  }

  const retrySendingMessage = async (message: ReduxMessage) => {
    setIsRetryingMessage(true)

    generateNewRelicLogs(NR_SINGLE_CONVERSATION_PAGE_SEND_MESSAGE_RETRY, {
      page: NR_CLIENTELING_PAGES.SINGLE_CONVERSATION_PAGE,
      conversationSid: selectedConversationId
    })

    const twilioMessage = await getTwilioMessage(
      client,
      selectedConversationId || '',
      message.index
    )

    const mediaOptions: AttachedMediaOptions | undefined =
      await getResentMessageMediaOptions({
        twilioMessage,
        messageType: message.type
      })

    const deletedMessage: Message | undefined = await removeMessage({
      reduxMessage: message,
      twilioMessage,
      isMessageSendRetry: true
    })

    if (deletedMessage) {
      await handleSendMessage(
        deletedMessage.conversation,
        message.body || '',
        mediaOptions ? [mediaOptions] : undefined
      )
    }
    resetModalState()
  }

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '1040px',
        marginBottom: '30px'
      }}
      data-testid="ConversationMessageFeedWrapper"
    >
      {hasAdditionalMessages && (
        <AdditionalMessages
          additionalMessagesAreLoading={additionalMessagesAreLoading}
          handleFetchMoreMessages={handleFetchMoreMessages}
        />
      )}
      {messages &&
        messages.map((message) => {
          return (
            <Box
              key={message.sid}
              sx={{
                overflowWrap: 'break-word'
              }}
            >
              {addDateHeaderIfNeeded(message.dateCreated ?? '')}
              <SingleMessage
                message={message}
                stylistEmail={stylistEmail}
                employeeId={employeeId}
                setClickedMessage={setClickedMessage}
                setIsMessageStatusModalOpen={setIsMessageStatusModalOpen}
                isMobileDevice={isMobileDevice}
              />
            </Box>
          )
        })}
      {clickedMessage && (
        <MessageStatusErrorModal
          message={clickedMessage}
          isOpen={isMessageStatusModalOpen}
          onClose={resetModalState}
          isActionDisabled={areMessageStatusModalActionsDisabled}
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          retrySendingMessage={retrySendingMessage}
          removeMessage={removeMessage}
          isRetryingSendingMessage={isRetryingSendingMessage}
          isRemovingMessage={isRemovingMessage}
        />
      )}

      {isCopyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}

      <div ref={bottomRef} />
    </Box>
  )
}

export default ConversationMessageFeed
