import { ReactElement } from 'react'
import { Typography, Box } from '@mui/material'
import Chips from 'components/Chips'
import { useGetPreferredBrandsQuery } from 'services/employeeExperienceApi'
import LoadingBrands from './LoadingBrands'
import { BrandT } from 'types/Brands'
import PageError from 'components/PageError'

type PropsT = {
  customerId: string
}

const SavedBrands = (props: PropsT): ReactElement => {
  const { customerId } = props
  const { data, isLoading, isError, error, endpointName } =
    useGetPreferredBrandsQuery(customerId)
  const brands = data?.brands
  const brandErrorTitle = 'Issue getting brands.'

  const NoSavedBrands = () => (
    <Typography
      variant="body2"
      color="text.secondary"
      style={{ fontSize: '1.125rem' }}
    >
      No saved brands
    </Typography>
  )

  const BrandDataError = () => {
    if (error && 'status' in error && error.status === 404) {
      return <NoSavedBrands />
    }

    return (
      <PageError
        isInlineAlert={true}
        errorTitle={brandErrorTitle}
        errorDetails={{
          endpoint: endpointName,
          errorData: error,
          identifiers: {
            customerId: customerId,
            curationId: 'NO-DATA',
            employeeId: 'NO-DATA',
            shoppingSessionId: 'NO-DATA'
          }
        }}
      />
    )
  }

  return (
    <Box>
      <Typography variant="subtitle2" mt="12px">
        Saved brands
      </Typography>
      {isLoading ? (
        <LoadingBrands />
      ) : isError ? (
        <BrandDataError />
      ) : (
        brands && (
          <Chips data={data?.brands.map((item: BrandT) => item.brand)} />
        )
      )}
    </Box>
  )
}

export default SavedBrands
