import { useState, useEffect } from 'react'
import {
  Typography,
  Alert,
  ButtonBase,
  Container,
  Modal,
  Box,
  CircularProgress
} from '@mui/material'
import BaseSnackbar from 'components/BaseSnackbar'
import PageError from 'components/PageError'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { emDashCharacter } from 'pages/constants'
import BasePageAlert from 'components/BasePageAlert'

type PropsT = {
  isUserCommunicationsError: boolean
  isActivationSuccess: boolean
  isActivateCommunicationsError: boolean
  isVisible: boolean
  activateCommunications: () => void
  isActivateCommunicationsLoading: boolean
  errorDetails?: CopyErrorDataT
}

const activateCommunicationsErrorTitle = `Issue activating your Nordstrom phone number ${emDashCharacter} `
const hasActiveCommunicationsErrorTitle = 'Something went wrong.'

const ActivateCommunications: (props: PropsT) => JSX.Element = (
  props: PropsT
) => {
  const {
    isUserCommunicationsError,
    isActivationSuccess,
    isActivateCommunicationsError,
    isVisible,
    activateCommunications,
    isActivateCommunicationsLoading,
    errorDetails
  } = props

  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false)

  useEffect(() => {
    if (isActivateCommunicationsLoading) {
      return setIsLoadingModalOpen(true)
    } else {
      setIsLoadingModalOpen(false)
    }
  }, [isActivateCommunicationsLoading, isLoadingModalOpen])

  useEffect(() => {
    if (isActivateCommunicationsError) {
      setIsLoadingModalOpen(false)
    }
  }, [isActivateCommunicationsError])

  const buttonIsDisabled =
    isActivateCommunicationsLoading && !isActivateCommunicationsError

  return (
    <>
      {isActivateCommunicationsError && (
        <Box sx={{ marginTop: 3, paddingX: 3, marginBottom: '12px' }}>
          <BasePageAlert
            alertTitle={activateCommunicationsErrorTitle}
            errorDetails={errorDetails}
            shouldShowRefresh={true}
          />
        </Box>
      )}
      {isUserCommunicationsError && (
        <div
          style={{
            marginBottom: '12px'
          }}
        >
          <PageError errorTitle={hasActiveCommunicationsErrorTitle} />
        </div>
      )}
      {isVisible && (
        <Container>
          <Alert
            severity="info"
            style={{ marginBottom: '20px' }}
            action={
              <ButtonBase
                onClick={activateCommunications}
                data-testid="activate-now"
                sx={{ marginRight: '15px' }}
                disabled={buttonIsDisabled}
              >
                <Typography
                  variant="button"
                  sx={{
                    color: buttonIsDisabled
                      ? 'rgba(31, 33, 34, 0.3)'
                      : 'inherit'
                  }}
                >
                  Activate
                </Typography>
              </ButtonBase>
            }
          >
            Activate your Nordstrom phone number to invite customers to your
            book
          </Alert>
        </Container>
      )}
      {isActivationSuccess && (
        <BaseSnackbar
          message="Nordstrom phone number activated"
          data-testid="success-snackbar"
        />
      )}
      <Modal
        open={isLoadingModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CircularProgress />
            <Box sx={{ marginTop: '24px', paddingX: '16px' }}>
              <Typography align="center" color="white" variant="body2">
                Activating your Nordstrom phone number – do not leave or refresh
                the page. This can take up to 2 minutes.
              </Typography>
            </Box>
          </Box>
        </>
      </Modal>
    </>
  )
}

export default ActivateCommunications
