import moment from 'moment'

const getYesterday = (date: moment.Moment) =>
  date.clone().subtract(1, 'd').startOf('d')
const getLastWeek = (date: moment.Moment) =>
  date.clone().subtract(6, 'd').startOf('d')

type CustomFormatting = {
  today?: string
  yesterday?: string
  pastWeek?: string
  pastYear?: string
  older?: string
}

export const getTime: (
  date?: string,
  customFormatting?: CustomFormatting
) => string = (date?: string, customFormatting?: CustomFormatting) => {
  if (!date) {
    return moment().toString()
  }

  const { today, yesterday, pastWeek, pastYear, older } = customFormatting || {}
  const last = moment(new Date(date))
  const now = moment()

  if (last.isSame(now, 'day')) {
    return today ? last.format(today) : 'Today'
  } else if (last.isSame(getYesterday(now), 'day')) {
    return yesterday ? last.format(yesterday) : 'Yesterday'
  } else if (last.isAfter(getLastWeek(now))) {
    // the past week
    return pastWeek ? last.format(pastWeek) : last.format('dddd')
  } else if (last.year() === now.year()) {
    // the past year
    return pastYear ? last.format(pastYear) : last.format('MMM D')
  } else {
    // older than a year
    return older ? last.format(older) : last.format('M/DD/YYYY')
  }
}

/**
 * Returns a formatted date string based on the input date and format string.
 * @param date The input date string to format.
 * @param format The format string to use for formatting the date see the moment library for formatting options.
 * https://momentjs.com/docs/#/displaying/format/
 * @returns A formatted date string.
 */
export const getDateByFormat = ({
  date,
  format
}: {
  date?: string
  format: string
}): string => {
  const passedInDate = date || new Date().toString()
  /* 
    due to a deprecation of warning in momentjs, we need to use new Date() to create a new date object
    http://momentjs.com/guides/#/warnings/js-date/ 
  */
  return moment(new Date(passedInDate)).format(format)
}
