import { ReactElement } from 'react'
import { Grid, IconButton, TextField } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'

interface FashionMapSearchBarT {
  onClick: (value: string) => void
  placeholder: string
  query: string
  resetSearch: () => void
  setQuery: (value: string) => void
  isLoading: boolean
}

const FashionMapSearchBar = (props: FashionMapSearchBarT): ReactElement => {
  const { query, onClick, placeholder, resetSearch, setQuery, isLoading } =
    props

  return (
    <Grid container flexDirection="row" spacing={1} flexWrap="nowrap">
      <Grid item flexGrow={1}>
        <TextField
          fullWidth
          sx={{ mb: 2 }}
          size="small"
          value={query}
          placeholder={placeholder}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: query && (
              <IconButton aria-label="clear search" onClick={resetSearch}>
                <HighlightOffIcon />
              </IconButton>
            ),
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            )
          }}
          onKeyUp={(e) => e.key === 'Enter' && query && onClick(query)}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => query && onClick(query)}
        >
          Search
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default FashionMapSearchBar
