import { ReactElement } from 'react'
import { Box, IconButton } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

type PropsT = {
  cancelSelection: () => void
  selectedImage?: string | ArrayBuffer
  isImageUploading: boolean
  isImageSending: boolean
  setPreviewImageHasLoaded: (hasLoaded: boolean) => void
}

const ImagePreview = ({
  selectedImage,
  isImageUploading,
  cancelSelection,
  isImageSending,
  setPreviewImageHasLoaded
}: PropsT): ReactElement => {
  return (
    <Box
      id={'parent'}
      sx={{ maxWidth: '150px', maxHeight: '180px', flexShrink: 0 }}
    >
      {isImageUploading ? (
        <Box
          sx={{
            borderWidth: 1,
            backgroundColor: '#F2F5F6',
            width: '120px',
            height: '120px',
            borderRadius: '4px'
          }}
        >
          <LoadingSpinner sx={{ paddingBottom: '10px' }} />
          <IconButton
            disabled={isImageSending}
            id={'button'}
            onClick={cancelSelection}
            sx={{
              position: 'sticky',
              marginLeft: '80px',
              bottom: '95px'
            }}
          >
            <Cancel
              sx={{
                background: 'white',
                borderRadius: '11px'
              }}
            />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <img
            src={selectedImage as string | undefined}
            key={selectedImage as string | undefined}
            id={'image'}
            alt="preview"
            style={{
              borderRadius: '4px',
              width: '100%',
              maxHeight: '120px'
            }}
            onLoad={() => setPreviewImageHasLoaded(true)}
          />
          <IconButton
            disabled={isImageSending}
            id={'button'}
            onClick={cancelSelection}
            sx={{
              position: 'sticky',
              marginLeft: '-41px',
              bottom: '95px'
            }}
          >
            <Cancel
              sx={{
                background: 'white',
                borderRadius: '11px'
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default ImagePreview
