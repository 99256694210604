import BaseDialog from 'components/BaseDialog'
import { ReactElement, useState } from 'react'
import { CURATION_TYPE } from 'types/Curation'

interface PropsT {
  curationType: CURATION_TYPE
  handleCancel: () => void
  handleClose: () => void
  ocpId: string | null
}

const CancelOrContinueDialog = (props: PropsT): ReactElement => {
  const { curationType, handleCancel, handleClose, ocpId, ...dialogProps } =
    props
  const [open, setOpen] = useState(true)

  const handleExitCancel = () => {
    setOpen(false)
    handleClose()
  }

  const contentText =
    curationType === CURATION_TYPE.SELLER_INITIATED ||
    (curationType === CURATION_TYPE.CUSTOMER_INITIATED && !ocpId)
      ? 'This board will not be finished and will be deleted.'
      : 'The request will be returned to the queue, and any progress will be lost.'

  return (
    <BaseDialog
      title={'Are you sure you want to cancel this board?'}
      contentText={contentText}
      confirm={{
        action: handleCancel,
        text: 'Yes, Cancel'
      }}
      cancel={{
        action: handleExitCancel,
        text: 'Nevermind'
      }}
      onClose={handleExitCancel}
      open={open}
      {...dialogProps}
    />
  )
}

export default CancelOrContinueDialog
