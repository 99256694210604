import { HoldsType, Product } from 'types/Holds'

export type HoldsHandleCloseT = {
  handleClose: () => void
}

export enum HoldsEnumCustomerType {
  LOOK_UP,
  ADD_QUICK
}

export enum HoldEnumStatusType {
  ON_HOLD = 'ON_HOLD',
  PURCHASED = 'PURCHASED',
  CANCELED = 'CANCELED'
}

export const HOLDS_STATUS_LABELS = {
  [HoldEnumStatusType.ON_HOLD]: 'On hold',
  [HoldEnumStatusType.PURCHASED]: 'Purchased',
  [HoldEnumStatusType.CANCELED]: 'Canceled'
}

export type HoldsCustomerInfoT = {
  customerId?: string
  customerName: string
  customerMobileNumber: string
}

export type HoldsHandleSubmitT = {
  handleSubmit: (value: HoldsCustomerInfoT, type: HoldsEnumCustomerType) => void
}

export type HoldsIdGenerated = {
  idGenerated: string
}

export type HoldsProductHandleActionItemT = (
  productItem: Product & HoldsIdGenerated
) => void

export type HoldsOpenDialogT = {
  dialogType: HoldsEnumCustomerType
  setDialogType: (dialogType: HoldsEnumCustomerType) => void
}

export type HoldsStartNewHoldFormParamsT = {
  customerInfo: HoldsCustomerInfoT
  holdType: HoldsType.QUICK_HOLDS | HoldsType.PRE_SALE | ''
  itemsOnHold: (Product & HoldsIdGenerated)[]
}

export enum HoldsPrintingStatus {
  PRINT_OPERATION_SUCCESS = 'PRINT_OPERATION_SUCCESS',
  POS_GENERAL_EXCEPTION = 'POS_GENERAL_EXCEPTION',
  PRINTER_NOT_CONFIGURED = 'PRINTER_NOT_CONFIGURED',
  PRINTER_COVER_OPEN = 'PRINTER_COVER_OPEN',
  PRINTER_OFFLINE = 'PRINTER_OFFLINE',
  PRINTER_NO_PAPER = 'PRINTER_NO_PAPER',
  PRINTER_NOT_AVAILABLE = 'PRINTER_NOT_AVAILABLE',
  PRINTER_OUT_OF_TONER = 'PRINTER_OUT_OF_TONER',
  PRINTER_STATUS_UNKNOWN = 'PRINTER_STATUS_UNKNOWN'
}

export type HoldsPrintingErrorsStatus = Exclude<
  HoldsPrintingStatus,
  HoldsPrintingStatus.PRINT_OPERATION_SUCCESS
>
