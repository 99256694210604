import { Box } from '@mui/material'
import ImagePreview from '../ImagePreview'
import { UploadedAttachedMedia } from 'types/Twilio'
import { useEffect, useRef } from 'react'

type PropsT = {
  uploadedImages: UploadedAttachedMedia[]
  handleSetImagePreviewHasLoaded: (hasLoaded: boolean, index: number) => void
  handleCanceledUploadedImage: (image: UploadedAttachedMedia) => void
  isSubmitPending: boolean
}

const AttachmentsPerview = ({
  uploadedImages,
  handleSetImagePreviewHasLoaded,
  handleCanceledUploadedImage,
  isSubmitPending
}: PropsT): JSX.Element => {
  const imagePreviewEndRef = useRef<HTMLDivElement>(null)

  const allPreviewImagesLoaded = uploadedImages.every(
    (image) => image.uiMediaHasLoaded
  )

  useEffect(() => {
    if (allPreviewImagesLoaded && imagePreviewEndRef.current) {
      imagePreviewEndRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'end',
        block: 'end'
      })
    }
  }, [allPreviewImagesLoaded, imagePreviewEndRef])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        overflow: 'auto'
      }}
    >
      {!!uploadedImages.length &&
        uploadedImages.map((uploadedImage, index) => (
          <ImagePreview
            key={uploadedImage.media?.filename ?? index}
            selectedImage={uploadedImage.media?.src}
            setPreviewImageHasLoaded={(hasLoaded) =>
              handleSetImagePreviewHasLoaded(hasLoaded, index)
            }
            isImageUploading={uploadedImage.isLoading}
            cancelSelection={() => handleCanceledUploadedImage(uploadedImage)}
            isImageSending={isSubmitPending}
          />
        ))}
      <div ref={imagePreviewEndRef}></div>
    </Box>
  )
}

export default AttachmentsPerview
