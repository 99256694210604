import { ReactElement } from 'react'
import BaseSnackbar from 'components/BaseSnackbar'

const LINK_COPY_SUCCESS_MESSAGE =
  'Style board link copied. Paste in text or email to send.'

const LinkCopiedSnackbar = (): ReactElement => {
  return <BaseSnackbar message={LINK_COPY_SUCCESS_MESSAGE} />
}

export default LinkCopiedSnackbar
