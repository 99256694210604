import { ReactElement, useEffect } from 'react'

import { Box, Typography } from '@mui/material'

import { CreateHoldResponse, HoldT } from 'types/Holds'
import {
  HoldsHandleCloseT,
  HoldsPrintingErrorsStatus,
  HoldsPrintingStatus
} from 'pages/Holds/types'

import {
  HOLDS_MEDIA_SM_WIDTH_CUSTOMER_FOUND_AND_REPRINT,
  HOLDS_TEXT_HOLD_TICKET_NOT_REPRINTED,
  HOLDS_TEXT_HOLD_TICKET_REPRINTED,
  HOLDS_TEXT_PRINT_TICKET
} from 'pages/Holds/constants'

import { usePrintHoldMutation } from 'services/holdsApi'

import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import { useHoldsToggle } from 'pages/Holds/hooks'

import LoadingBackdrop from 'components/LoadingBackdrop'

import {
  getPrinterErrorInfo,
  getResponseErrorMessageOrErrorMessage
} from '../../utils/holdsErrorUtils'

import HoldsDialog from '../HoldsDialog'
import HoldsDialogAppBar from '../HoldsDialogAppBar'
import HoldsDialogContent from '../HoldsDialogContent'
import HoldsButtonScanItem from 'pages/Holds/shared/components/HoldsButtonScanItem'

import HoldsButtonPrintItem from './components/HoldsButtonPrintItem'

const HoldsDialogReprintHold = ({
  hold,
  isOpen,
  handleClose
}: HoldsHandleCloseT & {
  hold: HoldT | CreateHoldResponse
  isOpen: boolean
}): ReactElement => {
  const { handleNotificationAdd, handleNotificationAddErrorMessage } =
    useHoldsContextNotification()

  const [
    postPrintHold,
    { isLoading: isPrintHoldLoading, reset: resetPrintHoldRequestCache }
  ] = usePrintHoldMutation()

  const {
    isOpen: isPrintHoldSuccess,
    handleOpen: handleOpenPrintHoldSuccess,
    handleClose: handleClosePrintHoldSuccess
  } = useHoldsToggle()

  const notAllowPrintTicket = isPrintHoldSuccess || isPrintHoldLoading

  const handleCloseReprintDialog = () => {
    if (notAllowPrintTicket) {
      return
    }

    handleClosePrintHoldSuccess()
    handleClose()
  }

  const handleSubmit: (printerNumber: string) => void = async (
    printerNumber
  ) => {
    if (notAllowPrintTicket) {
      return
    }

    try {
      const response = await postPrintHold({
        body: {
          ticketNumber: hold.ticketNumber,
          printerName: printerNumber
        }
      }).unwrap()

      if (response?.status === HoldsPrintingStatus.PRINT_OPERATION_SUCCESS) {
        handleOpenPrintHoldSuccess()

        handleNotificationAdd({
          message: HOLDS_TEXT_HOLD_TICKET_REPRINTED
        })

        handleCloseReprintDialog()
      } else {
        handleNotificationAddErrorMessage(
          getPrinterErrorInfo(response?.status as HoldsPrintingErrorsStatus)
        )
      }
    } catch (e) {
      handleNotificationAddErrorMessage({
        title:
          getResponseErrorMessageOrErrorMessage(e) ??
          HOLDS_TEXT_HOLD_TICKET_NOT_REPRINTED
      })
    }
  }

  useEffect(() => {
    isOpen && resetPrintHoldRequestCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (isPrintHoldLoading) {
      handleClosePrintHoldSuccess()
    }
  }, [isPrintHoldLoading, handleClosePrintHoldSuccess])

  return (
    <HoldsDialog
      data-testid="print_hold_dialog"
      isDrawerMode
      open={isOpen}
      onClose={handleCloseReprintDialog}
    >
      <HoldsDialogAppBar
        title={HOLDS_TEXT_PRINT_TICKET}
        handleClose={handleClose}
        closeIconButtonProps={{
          'data-testid': 'back_arrow_print_ticket'
        }}
      />

      <HoldsDialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: {
            sm: HOLDS_MEDIA_SM_WIDTH_CUSTOMER_FOUND_AND_REPRINT
          }
        }}
      >
        <Box mb={2}>
          <Box mb={2}>
            <Typography variant="body2" color="black">
              Scan the barcode on the printer. The ticket should print
              automatically.
            </Typography>
          </Box>

          <HoldsButtonPrintItem
            isLoading={isPrintHoldLoading}
            handleSubmit={handleSubmit}
          />
        </Box>
        <HoldsButtonScanItem handleSubmit={handleSubmit} />
      </HoldsDialogContent>

      <LoadingBackdrop open={isPrintHoldLoading} />
    </HoldsDialog>
  )
}

export default HoldsDialogReprintHold
