import GeneralRequests from './components/GeneralRequests'
import PersonalRequests from './components/PersonalRequests'
import EditRequests from './components/EditRequests'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { Typography, Grid } from '@mui/material'
import { isUSSeller, isPersonalStylist } from 'utils/userPermissions'
import { ReactElement } from 'react'
import DividerComponent from '../DividerComponent'

const StylingRequests = (): ReactElement => {
  const { isEditRequestEnabledPersonalBook } = useFeatureFlags()
  return (
    <>
      {!isUSSeller() && (
        <>
          <DividerComponent />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Styling requests
          </Typography>
        </>
      )}
      <Grid container spacing={2}>
        {isEditRequestEnabledPersonalBook?.active && (
          <Grid item md={6} sm={12} xs={12}>
            <EditRequests />
          </Grid>
        )}
        <Grid item md={6} sm={12} xs={12}>
          <PersonalRequests />
        </Grid>
        {!isUSSeller() && !isPersonalStylist() && (
          <Grid item md={6} sm={12} xs={12}>
            <GeneralRequests />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default StylingRequests
