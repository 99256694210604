import { ReactElement, useState } from 'react'

import { Button, CircularProgress, Box } from '@mui/material'

import convertHoldMobileToDigits from 'pages/Holds/utils/convertHoldMobileToNumber'

import HoldsTextFieldMobileNumber from 'pages/Holds/components/HoldsButtonStart/components/components/HoldsAddCustomer/components/HoldsDialogBodyAddCustomer/components/components/HoldsTextFieldMobileNumber'

const HoldsButtonFindPhoneNumber = ({
  isLoading,
  handleSubmit
}: {
  isLoading: boolean
  handleSubmit: (customerPhoneNumber: string) => void
}): ReactElement => {
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')

  const onClickEnter = () => {
    handleSubmit(convertHoldMobileToDigits(customerPhoneNumber))
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <HoldsTextFieldMobileNumber
        value={customerPhoneNumber}
        onChange={setCustomerPhoneNumber}
        isLoading={isLoading}
      />

      <Button variant="contained" onClick={onClickEnter} disabled={isLoading}>
        {isLoading ? <CircularProgress size={28} /> : 'Find'}
      </Button>
    </Box>
  )
}

export default HoldsButtonFindPhoneNumber
