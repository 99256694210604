import { ReactElement } from 'react'
import { Grid, Typography } from '@mui/material'

export type FashionPillarImagesPropsT = {
  image: {
    alt_text: string
    url: string
  }
}

const FashionPillarImages = ({ image }: FashionPillarImagesPropsT) => (
  <Grid container width={1}>
    <Grid item>
      <img width="100%" alt={image.alt_text} src={image.url} />
    </Grid>
  </Grid>
)

export type FashionPillarDescriptionPropsT = {
  description: string
}

const FashionPillarDescription = ({
  description
}: FashionPillarDescriptionPropsT) => (
  <Grid container width={1}>
    <Typography variant="caption" pt={1} pb={2}>
      {description}
    </Typography>
  </Grid>
)

export type PropsT = {
  image: {
    alt_text: string
    url: string
  }
  description: string
}

export const FashionPillarItem = (props: PropsT): ReactElement => {
  const { description, image } = props

  return (
    <Grid container>
      {image && <FashionPillarImages image={image} />}
      {description && <FashionPillarDescription description={description} />}
    </Grid>
  )
}
