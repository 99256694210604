import { ReactElement } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Typography, Box, Button } from '@mui/material'
import { CURATION_ORDERING, CURATION_STATE } from 'types/Curation'
import oktaTokenStorage from 'utils/okta-token-utils'
import PageError from 'components/PageError'
import CurationCardList from 'components/CurationCardList'
import { useGetStyleBoardsQuery } from 'services/employeeExperienceApi'
import LoadingCurationCard from 'components/LoadingCurationCard'
import { SNAPSHOT_STYLE_BOARDS_ERROR_ALERT_TITLE } from 'pages/constants'

type PropsT = {
  previewCount: number
  customerId: string
}

const NoStyleBoards = () => (
  <Typography variant="body2" color="text.secondary">
    No style boards
  </Typography>
)

const StyleBoards = ({ previewCount, customerId }: PropsT): ReactElement => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const {
    data: publishedStyleBoardsPreviewData,
    endpointName: publishedStyleBoardsPreviewEndpoint,
    error: publishedStyleBoardsPreviewError,
    isError: isGetPublishedStyleBoardsPreviewError,
    isLoading: isGetPublishedStyleBoardsPreviewLoading
  } = useGetStyleBoardsQuery({
    state: CURATION_STATE.PUBLISHED,
    employeeId,
    customerId,
    perPage: previewCount,
    orderBy: CURATION_ORDERING.COMPLETED_DESC
  })

  const previewStyleBoards = publishedStyleBoardsPreviewData?.styleBoards
  const styleBoardsItemsPresent =
    previewStyleBoards && previewStyleBoards.length > 0

  return (
    <>
      <Box>
        <Typography variant="subtitle2">Style boards</Typography>

        {isGetPublishedStyleBoardsPreviewLoading ? (
          <LoadingCurationCard />
        ) : isGetPublishedStyleBoardsPreviewError ? (
          <PageError
            isInlineAlert
            isFullWidth
            isErrorTitlePersonalized
            errorTitle={SNAPSHOT_STYLE_BOARDS_ERROR_ALERT_TITLE}
            errorDetails={{
              endpoint: publishedStyleBoardsPreviewEndpoint,
              errorData: publishedStyleBoardsPreviewError,
              identifiers: { customerId, employeeId }
            }}
          />
        ) : styleBoardsItemsPresent ? (
          <>
            <CurationCardList
              curations={previewStyleBoards}
              isPublicBoardList={false}
              hideMenu
            />

            <Box sx={{ textAlign: 'center', py: '16px' }}>
              <Button
                fullWidth
                variant="outlined"
                component={ReactRouterLink}
                to={{ pathname: '/style-boards' }}
                sx={{ maxWidth: '343px', alignContent: 'center' }}
              >
                See All Style Boards
              </Button>
            </Box>
          </>
        ) : (
          <NoStyleBoards />
        )}
      </Box>
    </>
  )
}

export default StyleBoards
