import PageError from 'components/PageError'
import { ReactElement } from 'react'
interface FallbackProps<T extends Error> {
  error: T
  resetErrorBoundary: (...args: Array<unknown>) => void
}

export const ErrorFallback = ({
  error
}: FallbackProps<Error>): ReactElement => {
  return (
    <PageError
      errorDetails={{
        errorData: {
          name: error.name,
          message: error.message,
          stack: error.stack
        }
      }}
    />
  )
}

export default ErrorFallback
