import { useState, useEffect } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux' // eslint-disable-line
import type { AppDispatch, RootState } from './store'
import { getExpirationTimestamp } from 'utils/checkoutToken'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { IFrameStateT } from 'components/NordstromIFrame/NordstromIFrame'

export const useIframeConfig = (): {
  initialIframePathAndTitle: IFrameStateT | undefined
  iframePathAndTitle: IFrameStateT | undefined
  setIframePathAndTitle: (pathAndTitle: IFrameStateT | undefined) => void
} => {
  const { iFrameConfig } = useFeatureFlags()
  const [initialIframePathAndTitle, setInitialIframePathAndTitle] = useState<
    IFrameStateT | undefined
  >(undefined)
  const [iframePathAndTitle, setIframePathAndTitle] = useState<
    IFrameStateT | undefined
  >(undefined)

  useEffect(() => {
    if (iFrameConfig?.active && iFrameConfig?.payload) {
      const payload = Object(iFrameConfig.payload)
      setInitialIframePathAndTitle(payload)
      setIframePathAndTitle(payload)
    }
    return () => setIframePathAndTitle(undefined)
  }, [iFrameConfig, initialIframePathAndTitle])

  return {
    initialIframePathAndTitle,
    iframePathAndTitle,
    setIframePathAndTitle
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const DEFAULT_MUTATION_OBSERVER_OPTIONS = {
  config: { attributes: true, attributeFilter: ['class'] }
}
export const useMutationObservable = (
  targetElement: HTMLElement,
  callback: React.Dispatch<MutationRecord[]>,
  options = DEFAULT_MUTATION_OBSERVER_OPTIONS
): void => {
  const [observer, setObserver] = useState<MutationObserver | null>(null)

  useEffect(() => {
    const obs = new MutationObserver(callback)
    setObserver(obs)
  }, [callback, options, setObserver])

  useEffect(() => {
    if (!observer) return
    const { config } = options
    observer.observe(targetElement, config)
    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [observer, targetElement, options])
}

export const useHandleTokenRefreshBeforeExpiration = ({
  isFetchTokenLoading,
  checkoutToken,
  isIframeOpen,
  handleTokenRefresh,
  handleCloseIframeAndNotify
}: {
  isFetchTokenLoading: boolean
  checkoutToken: string
  isIframeOpen: boolean
  handleTokenRefresh: () => void
  handleCloseIframeAndNotify: () => void
}): void => {
  useEffect(() => {
    if (!checkoutToken) {
      return
    }
    if (!isFetchTokenLoading) {
      const getDelay = (expirationTimestamp: number) => {
        const sixtySecondsInMs = 1000 * 60
        const timeToRefresh = expirationTimestamp - sixtySecondsInMs
        const delay = timeToRefresh - Date.now()

        return delay
      }
      const expirationTimestamp = getExpirationTimestamp(checkoutToken)
      const delayUntilRefresh = getDelay(expirationTimestamp)

      const refreshInterval = setInterval(() => {
        if (!isIframeOpen) {
          handleTokenRefresh()
        } else {
          handleCloseIframeAndNotify()
        }
      }, delayUntilRefresh)

      return () => {
        clearInterval(refreshInterval)
      }
    }
  }, [
    isFetchTokenLoading,
    checkoutToken,
    isIframeOpen,
    handleTokenRefresh,
    handleCloseIframeAndNotify
  ])
}
