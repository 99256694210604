import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo
} from 'react'
import type { MutableRefObject } from 'react'

import groupBy from 'lodash/fp/groupBy'

import {
  Box,
  Button,
  Divider,
  Grow,
  Stack,
  Typography,
  styled
} from '@mui/material'

import {
  HOLDS_COMPLETION_PREFIXES_BY_STATUS,
  HOLDS_TYPE_LABELS,
  HoldT,
  HoldsStatus
} from 'types/Holds'

import {
  HOLDS_STATUS_LABELS,
  HoldEnumStatusType,
  HoldsHandleCloseT
} from 'pages/Holds/types'

import { HOLDS_TEXT_HOLD_DETAILS } from 'pages/Holds/constants'

import { formatPhoneNumber } from 'utils/formatPhoneNumber'

import holdsFormatDateToLocalDate from '../../utils/holdsFormatDateToLocalDate'

import { useHoldsRefState, useHoldsToggle } from 'pages/Holds/hooks'

import { useGetHoldsByTicketNumberMutation } from 'services/holdsApi'

import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Skeleton from 'components/Skeleton'

import holdsGetGrowTimeout from '../../utils/holdsGetGrowTimeout'
import HoldsBoxDivider from '../HoldsBoxDivider'
import HoldsDialog from '../HoldsDialog'
import HoldsDialogAppBar from '../HoldsDialogAppBar'
import HoldsDialogContent from '../HoldsDialogContent'

import HoldsProductItemImage from '../HoldsProductItemImage'
import HoldsDialogReprintHold from '../HoldsDialogReprintHold'

import HoldsDialogUpdateHoldStatus from './components/HoldsDialogUpdateHoldStatus'

const BoxWithSkeletonSpacing = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '23.99px'
})

export type HoldsRefApiProductItemCardDetailsT = {
  getHoldDetailsByTicketNumber: () => Promise<void>
}

export type HoldsRefApiProductItemCardDetailsWithMutableObjectT =
  MutableRefObject<HoldsRefApiProductItemCardDetailsT>

const HOLDS_LINE_ITEMS_STATUS_PRIORITY = [
  HoldEnumStatusType.ON_HOLD,
  HoldEnumStatusType.PURCHASED,
  HoldEnumStatusType.CANCELED
]

const HoldsProductItemCardDetails = ({
  hold,
  isOpen,
  handleClose,
  handleUpdateDetails,
  refApi
}: HoldsHandleCloseT & {
  hold: HoldT
  isOpen: boolean
  handleUpdateDetails: (hold: HoldT) => void
  refApi?: HoldsRefApiProductItemCardDetailsWithMutableObjectT
}): ReactElement => {
  const {
    isOpen: isOpenUpdateHoldStatusDialog,
    handleOpen: handleOpenUpdateHoldStatusDialog,
    handleClose: handleCloseUpdateHoldStatusDialog
  } = useHoldsToggle()

  const {
    isOpen: isOpenReprintHoldDialog,
    handleOpen: handleOpenReprintHoldDialog,
    handleClose: handleCloseReprintHoldDialog
  } = useHoldsToggle()

  const isActiveHoldStatus = hold.status === HoldsStatus.ACTIVE
  const isCompletedHoldStatus = hold.status === HoldsStatus.COMPLETED
  const isExpiredHoldStatus = hold.status === HoldsStatus.EXPIRED

  const groupedLineItems = useMemo(
    () => groupBy('status', hold.lineItems),
    [hold.lineItems]
  )

  const completionDate = isActiveHoldStatus
    ? hold.expiresAt
    : (hold.completedAt as string)

  const title = `Hold ${
    isActiveHoldStatus
      ? 'until'
      : HOLDS_COMPLETION_PREFIXES_BY_STATUS[hold.status].toLowerCase()
  } ${holdsFormatDateToLocalDate({ date: completionDate })}`

  const [fetchGetHoldByTicketNumber, { isLoading }] =
    useGetHoldsByTicketNumberMutation()

  const refHandleUpdateDetails = useHoldsRefState(handleUpdateDetails)
  const refHold = useHoldsRefState(hold)
  const refHoldIsLoading = useHoldsRefState(isLoading)
  const refHoldIsActiveHoldStatus = useHoldsRefState(isActiveHoldStatus)

  const completedByLatestUser = useMemo(() => {
    if (!hold.createdAt) {
      return null
    }

    const sortedByCompletedAtDesc = hold.lineItems
      .filter((a) => Boolean(a.completedAt))
      .sort((a, b) =>
        new Date(a.completedAt as string).getTime() >
        new Date(b.completedAt as string).getTime()
          ? -1
          : 1
      )

    return sortedByCompletedAtDesc[0]?.completedBy
  }, [hold])

  const getHoldDetailsByTicketNumber = useCallback(
    async (forceFetchDetails?: boolean) => {
      if (
        !refHoldIsLoading.current &&
        (!refHold.current.createdAt || forceFetchDetails)
      ) {
        try {
          const response = await fetchGetHoldByTicketNumber({
            ticketNumber: refHold.current.ticketNumber
          }).unwrap()

          if (response.ticketNumber) {
            /**
             * FYI: HOLDS - in list we have "completedAt"
             * when load details we don't have this field
             * also, we have for lineItems extra fields: completedAt and completedBy
             *
             * Shallow merge current hold details from list
             * with hold details as need data from both calls
             */
            refHandleUpdateDetails.current({
              ...refHold.current,
              ...response
            })
          }
        } catch (e) {
          return
        }
      }
    },
    [
      fetchGetHoldByTicketNumber,
      refHandleUpdateDetails,
      refHold,
      refHoldIsLoading
    ]
  )

  /**
   * FYI: When open hold - load hold details only once except Active status
   * for Active status always reload data when we open details
   */
  useEffect(() => {
    if (isOpen) {
      getHoldDetailsByTicketNumber(refHoldIsActiveHoldStatus.current)
    }
  }, [isOpen, getHoldDetailsByTicketNumber, refHoldIsActiveHoldStatus])

  useImperativeHandle(refApi, () => ({
    getHoldDetailsByTicketNumber
  }))

  const allowDisplayCompletedBy = () => {
    if (isCompletedHoldStatus) {
      return true
    }

    if (isExpiredHoldStatus) {
      if (isLoading && !completedByLatestUser) {
        return true
      }

      return Boolean(completedByLatestUser)
    }

    if (isActiveHoldStatus) {
      return Boolean(completedByLatestUser)
    }

    return false
  }

  return (
    <>
      <HoldsDialog
        data-testid="hold_details_dialog"
        open={isOpen}
        onClose={handleClose}
        TransitionProps={{
          direction: 'left'
        }}
      >
        <HoldsDialogAppBar
          title={HOLDS_TEXT_HOLD_DETAILS}
          handleClose={handleClose}
          closeIconButtonProps={{
            'data-testid': 'back_arrow_hold_details'
          }}
        />

        <HoldsDialogContent>
          <BoxWithGladlySpace>
            <Box
              sx={{
                display: 'flex',
                gap: 3,
                flexFlow: {
                  sm: 'row',
                  xs: 'column'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  flex: 1.56,
                  gap: 1
                }}
              >
                <Box>
                  <Typography>
                    <strong>{title}</strong>
                  </Typography>
                </Box>

                <Box display="flex" gap={1}>
                  <Typography>{HOLDS_TYPE_LABELS[hold.type]}</Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography>{hold.lineItems.length} items</Typography>
                </Box>

                <Box mt={2}>
                  <Typography>
                    <strong>Customer</strong>
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" gap={0.25}>
                  <Typography>
                    {hold.customer.firstName} {hold.customer.lastName}
                  </Typography>
                  <Typography>
                    {formatPhoneNumber(hold.customer.phoneNumber)}
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography>
                    <strong>Created by</strong>
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" gap={0.25}>
                  <Typography>
                    {hold.employee.firstName} {hold.employee.lastName} (#
                    {hold.employeeId})
                  </Typography>
                  <Typography>at {hold.store.name}</Typography>

                  {isLoading && !hold.createdAt && (
                    <BoxWithSkeletonSpacing>
                      <Skeleton.Text textVariant="body1" />
                    </BoxWithSkeletonSpacing>
                  )}

                  {Boolean(hold.createdAt) && (
                    <Typography>
                      on{' '}
                      {holdsFormatDateToLocalDate({
                        date: hold.createdAt as string
                      })}
                    </Typography>
                  )}
                </Box>

                {allowDisplayCompletedBy() && (
                  <Box mt={2}>
                    <Typography>
                      <strong>Completed by</strong>
                    </Typography>
                  </Box>
                )}

                {isLoading && !isActiveHoldStatus && !completedByLatestUser && (
                  <BoxWithSkeletonSpacing>
                    <Skeleton.Text textVariant="body1" />
                  </BoxWithSkeletonSpacing>
                )}

                {completedByLatestUser && (
                  <Box display="flex" flexDirection="column" gap={0.25}>
                    <Typography>
                      {completedByLatestUser.firstName}{' '}
                      {completedByLatestUser.lastName} (#
                      {completedByLatestUser.employeeId})
                    </Typography>
                  </Box>
                )}
              </Box>

              {isActiveHoldStatus && (
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    flex: 1,
                    gap: 2
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleOpenUpdateHoldStatusDialog}
                  >
                    Update Hold Status
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleOpenReprintHoldDialog}
                  >
                    Reprint Hold Ticket
                  </Button>
                </Box>
              )}
            </Box>

            <HoldsBoxDivider mt={3} height={isActiveHoldStatus ? 2 : 4} />

            {HOLDS_LINE_ITEMS_STATUS_PRIORITY.map((lineItemsGroupStatus) => {
              if (!groupedLineItems[lineItemsGroupStatus]) {
                return <Fragment key={lineItemsGroupStatus}></Fragment>
              }

              return (
                <Fragment key={lineItemsGroupStatus}>
                  <Box mt={3}>
                    <Typography variant="h6" component="div">
                      {HOLDS_STATUS_LABELS[lineItemsGroupStatus]}
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Stack
                      direction="column"
                      spacing={2}
                      divider={<HoldsBoxDivider />}
                    >
                      {groupedLineItems[lineItemsGroupStatus].map(
                        (productItem, index) => (
                          <Grow
                            key={index}
                            in
                            timeout={holdsGetGrowTimeout({
                              index
                            })}
                          >
                            <Box display="flex" gap={2}>
                              <HoldsProductItemImage
                                productItem={{
                                  name: productItem.productName,
                                  imageUrl: productItem.imageUrl
                                }}
                                height={178}
                                width={107}
                              />

                              <Box>
                                <Typography>
                                  <strong>{productItem.productName}</strong>
                                </Typography>

                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1}
                                  mt={2}
                                >
                                  <Typography>{productItem.size}</Typography>
                                  <Typography>{productItem.color}</Typography>
                                </Box>

                                <Box mt={2}>
                                  <Typography>
                                    Item #: {productItem.sku}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grow>
                        )
                      )}
                    </Stack>
                  </Box>
                </Fragment>
              )
            })}
          </BoxWithGladlySpace>
        </HoldsDialogContent>
      </HoldsDialog>

      <HoldsDialogUpdateHoldStatus
        isOpen={isOpenUpdateHoldStatusDialog}
        hold={hold}
        handleClose={handleCloseUpdateHoldStatusDialog}
        handleUpdateDetails={handleUpdateDetails}
      />

      <HoldsDialogReprintHold
        isOpen={isOpenReprintHoldDialog}
        hold={hold}
        handleClose={handleCloseReprintHoldDialog}
      />
    </>
  )
}

export default HoldsProductItemCardDetails
