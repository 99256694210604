import { Box, Link, Typography } from '@mui/material'
import ItemImage from 'components/ItemImage'
import moment from 'moment'
import { CurationAttributionT } from 'types/CurationAttribution'
import { pdpLink } from 'utils/pdpLink'

const NEGATIVE_STATUSES = ['Cancelled', 'Returned']

const AttributionItem = ({
  attribution
}: {
  attribution: CurationAttributionT
}): JSX.Element => {
  const { attributedAt, curation, customer, order, product } = attribution
  return (
    <Box display="flex" mb={3}>
      <Box
        flexBasis={74}
        style={{
          opacity:
            product.status && NEGATIVE_STATUSES.includes(product.status)
              ? 0.5
              : 1
        }}
      >
        <Link
          href={product.style ? pdpLink(product.style) : ''}
          target="_blank"
        >
          <ItemImage
            autoHeight={true}
            imageUrl={product.imageUrl}
            imageAltText={`${product.productName}, ${product.color}`}
          />
        </Link>
      </Box>
      <Box
        display="flex"
        flex="1"
        ml={2}
        flexDirection="column"
        sx={{ minWidth: 0 }}
      >
        <Typography variant="body2" pb={1}>
          <Box
            component="span"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {customer.name}
          </Box>
          {` - `}
          <Link
            href={`${process.env.REACT_APP_NORDSTROM_URL}/curation/${curation.id}`}
            target="_blank"
            color="text.primary"
          >
            {curation.title}
          </Link>
        </Typography>
        <Typography variant="body2">
          {`Order #${order.id} | ${moment(attributedAt).format('MM/DD/YYYY')}`}
        </Typography>
        <Typography variant="body2" pb={1}>
          {`$${product.purchasePrice}`}
        </Typography>
        <Typography variant="body2" color="secondary">
          {product.brandName}
        </Typography>
        <Typography
          variant="body2"
          pb={1}
          color="secondary"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {product.size && product.size + ', '}
          {product.color}
        </Typography>
        <Typography
          variant="overline"
          sx={{
            fontWeight: 'bold'
          }}
        >
          {product.status}
        </Typography>
      </Box>
    </Box>
  )
}

export default AttributionItem
