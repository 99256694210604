import { ReactElement, useCallback, useEffect, useState } from 'react'
import { OutlinedInput, Box, FormHelperText } from '@mui/material'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { twilioActions, twilioSelectors } from 'services/twilioSlice'
import { AttachedMediaOptions, UploadedAttachedMedia } from 'types/Twilio'
import AttachImageButton from '../AttachImageButton/AttachImageButton'
import AttachBookingLink from '../AttachBookingLink/AttachBookingLink'
import BasePageAlert from 'components/BasePageAlert'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { determineHelperText } from 'utils/inputValidations'
import { MESSAGE_STATUS } from '../../../../../services/twilioSlice/constants'
import { Conversation } from '@twilio/conversations'
import { handleSendMessage } from 'services/twilioSlice/utils'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useGetPersonalizedLinkQuery } from 'services/appointmentsService'
import oktaTokenStorage from 'utils/okta-token-utils'
import PersonalBookingLink from '../PersonalBookingLink'
import { useFocus } from 'hooks/useFocus'
import AttachmentsPerview from '../AttachmentsPreview'
import SubmitSMSButton from '../SubmitSMSButton'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import {
  NR_SINGLE_CONVERSATION_PAGE_SEND_MESSAGE_SUCCESS,
  NR_SINGLE_CONVERSATION_PAGE_SEND_IMAGE,
  NR_SINGLE_CONVERSATION_PAGE_SEND_BOOKING_LINK
} from 'constants/clienteling/newRelicEvents/nrSingleConversationPage'

const MAX_UPLOADED_IMAGES = 10

const isMobileDevice = checkBrowserClient.isMobile()

const MessageInputForm = ({
  isClientReady,
  conversation
}: {
  isClientReady: boolean
  conversation: Conversation
}): ReactElement => {
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [isMessageLengthValid, setIsMessageLengthValid] =
    useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false)
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] =
    useState<boolean>(false)
  const [isAlertVisible, setIsAlertVisible] = useState(false)

  const [isAttachImageButtonDisabled, setIsAttachImageButtonDisabled] =
    useState(false)
  const [uploadedImages, setUploadedImages] = useState<UploadedAttachedMedia[]>(
    []
  )

  const [exceededMaximumLength, setExceededMaximumLength] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [bookingLink, setBookingLink] = useState<string | null>(null)

  const [focusInputRef, setFocusInputRef] = useFocus<HTMLInputElement>()

  const { isMultipleImagesToTextEnabled } = useFeatureFlags()

  const employeeStore = oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()
  const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()

  const maxInputChars = 1600
  const appDispatch = useAppDispatch()

  const draftMessage = useAppSelector(twilioSelectors.draftMessageSelector)

  const areImagesUploading = uploadedImages.some((image) => image.isLoading)

  const validateMessageLength = (message: string) => {
    const valueLength = message.trim().length
    if (valueLength > maxInputChars) {
      setIsMessageLengthValid(false)
      setExceededMaximumLength(true)
    } else if (valueLength === 0) {
      setIsMessageLengthValid(false)
      setExceededMaximumLength(false)
    } else {
      setIsMessageLengthValid(true)
      setExceededMaximumLength(false)
    }
  }

  const { data: linkData } = useGetPersonalizedLinkQuery({
    store: employeeStore,
    email: employeeEmail
  })

  const personalizedLink = linkData?.link

  useEffect(() => {
    const { message: draftText, images: draftImages } = draftMessage

    if (draftText) {
      setFocusInputRef()
      setMessage(draftText)
      validateMessageLength(draftText)
    }

    if (draftImages && draftImages.length) {
      const uploadedImages = draftImages.map(
        (image): UploadedAttachedMedia => ({
          media: image,
          isLoading: false
        })
      )

      setUploadedImages(uploadedImages)
    }

    // NOTE: Only check for draft images on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateDraftImages = (images: UploadedAttachedMedia[]) => {
    if (images.some((image) => image.media)) {
      updateDraftMessage({
        message,
        images: images
          .map((image) => image.media)
          .filter((image): image is AttachedMediaOptions => !!image),
        personalBookingLink: bookingLink
      })
    }
  }

  const updateDraftMessage = ({
    message,
    images,
    personalBookingLink
  }: {
    message: string
    images: AttachedMediaOptions[] | undefined
    personalBookingLink: string | null
  }) => {
    if (message || images || personalBookingLink) {
      appDispatch(
        twilioActions.setDraftMessage({
          message,
          images: images,
          personalBookingLink
        })
      )
    } else {
      appDispatch(twilioActions.deleteDraftMessage())
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    validateMessageLength(value)
    setMessage(value)
  }

  const isMessageDraftValid = useCallback((): boolean => {
    if (!uploadedImages.length) {
      if (!isMessageLengthValid && !exceededMaximumLength && bookingLink) {
        // Only Booking link message.
        return true
      }
      // a text-only message
      return isMessageLengthValid
    } else {
      // a media message, with or without additional text content
      if (!isMessageLengthValid && !exceededMaximumLength) {
        // the message length is 0
        return true
      } else {
        return isMessageLengthValid
      }
    }
  }, [uploadedImages, isMessageLengthValid, exceededMaximumLength, bookingLink])

  const handleSetUploadedImage = (
    image: UploadedAttachedMedia,
    index: number
  ) => {
    setUploadedImages((oldImages) => {
      const newImages = [...oldImages]
      newImages[index] = image

      updateDraftImages(newImages)

      return newImages
    })
  }

  const handleSetImagePreviewHasLoaded = (
    hasLoaded: boolean,
    index: number
  ) => {
    setUploadedImages((oldImages) => {
      const newImages = [...oldImages]
      newImages[index] = {
        uiMediaHasLoaded: hasLoaded,
        ...newImages[index]
      }
      return newImages
    })
  }

  const handleCanceledUploadedImage = (image: UploadedAttachedMedia) => {
    setUploadedImages((oldImages) => {
      const newImages = oldImages.filter(
        (i) => i.media?.filename !== image.media?.filename
      )

      if (newImages.length < MAX_UPLOADED_IMAGES) {
        setIsAttachImageButtonDisabled(false)
      }

      updateDraftImages(newImages)

      return newImages
    })
  }

  const isSendActionAllowed = useCallback((): boolean => {
    if (!areImagesUploading && isMessageDraftValid() && !isSubmitPending) {
      return true
    } else {
      return false
    }
  }, [areImagesUploading, isMessageDraftValid, isSubmitPending])

  const handleSubmit = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault()
    setIsSubmitPending(true)
    try {
      const nrEventAttributes = {
        page: NR_CLIENTELING_PAGES.SINGLE_CONVERSATION_PAGE,
        conversationSid: conversation.sid
      }
      // If the message is sent successfully, then it is added to the conversation thread when the `messageAdded` webhook event triggers
      const sentMessageStatus = await handleSendMessage(
        conversation,
        message,
        uploadedImages
          .map((image) => image.media)
          .filter((media): media is AttachedMediaOptions => !!media),
        bookingLink
      )

      if (
        sentMessageStatus !== MESSAGE_STATUS.queued &&
        sentMessageStatus !== MESSAGE_STATUS.sent
      ) {
        throw Error(sentMessageStatus)
      }
      generateNewRelicLogs(
        NR_SINGLE_CONVERSATION_PAGE_SEND_MESSAGE_SUCCESS,
        nrEventAttributes
      )
      if (bookingLink) {
        generateNewRelicLogs(
          NR_SINGLE_CONVERSATION_PAGE_SEND_BOOKING_LINK,
          nrEventAttributes
        )
      }
      if (uploadedImages.length > 0) {
        generateNewRelicLogs(NR_SINGLE_CONVERSATION_PAGE_SEND_IMAGE, {
          ...nrEventAttributes,
          imageCount: uploadedImages.length
        })
      }

      setUploadedImages([])
      setIsAttachImageButtonDisabled(false)
      setMessage('')
      setBookingLink(null)

      updateDraftMessage({
        message: '',
        images: undefined,
        personalBookingLink: null
      })

      setIsMessageLengthValid(false)
    } catch (e) {
      setError((e as Error).message)
      setIsError(true)
      setIsAlertVisible(true)
    }

    setIsSubmitPending(false)
    setFocusInputRef()
  }

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      !isMobileDevice &&
      event.key === 'Enter' &&
      !event.shiftKey &&
      isSendActionAllowed()
    ) {
      handleSubmit(event)
    }
  }

  const attachBookingLink = () => {
    if (personalizedLink) {
      setBookingLink(personalizedLink)
      updateDraftMessage({
        message,
        images: uploadedImages
          .map((image) => image.media)
          .filter((image): image is AttachedMediaOptions => !!image),
        personalBookingLink: personalizedLink
      })
    }
  }

  const closeBookingLink = () => {
    setBookingLink(null)
    updateDraftMessage({
      message,
      images: uploadedImages
        .map((image) => image.media)
        .filter((image): image is AttachedMediaOptions => !!image),
      personalBookingLink: null
    })
  }

  return (
    <Box
      sx={{
        maxWidth: '1040px',
        margin: 'auto',
        marginBottom: isMobileDevice && errorMessage ? '45px' : '17px'
      }}
    >
      {!!bookingLink && <PersonalBookingLink onClose={closeBookingLink} />}
      <AttachmentsPerview
        uploadedImages={uploadedImages}
        handleSetImagePreviewHasLoaded={handleSetImagePreviewHasLoaded}
        handleCanceledUploadedImage={handleCanceledUploadedImage}
        isSubmitPending={isSubmitPending}
      />
      <Box sx={{ paddingTop: '1%' }} />
      <OutlinedInput
        error={exceededMaximumLength}
        value={message}
        onBlur={() =>
          updateDraftMessage({
            message,
            images: uploadedImages
              .map((image) => image.media)
              .filter((image): image is AttachedMediaOptions => !!image),
            personalBookingLink: bookingLink
          })
        }
        onChange={handleChange}
        multiline={true}
        maxRows={isMobileDevice ? 6 : 9}
        id="input-message"
        placeholder="Type a message"
        inputProps={{ onKeyDown: handleKeyPress }}
        inputRef={focusInputRef}
        disabled={!isClientReady || isSubmitPending}
        sx={{
          width: '100%',
          alignContent: 'stretch',
          padding: '16px 12px',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20px'
        }}
        endAdornment={
          <SubmitSMSButton
            badgeCount={uploadedImages.length}
            isSendActionAllowed={isSendActionAllowed}
            isSubmitPending={isSubmitPending}
            handleSubmit={handleSubmit}
          />
        }
      />
      <FormHelperText
        error={exceededMaximumLength}
        sx={{
          marginTop: '0px',
          marginLeft: '10px'
        }}
      >
        {determineHelperText(
          message.length,
          maxInputChars,
          'Max 1600 characters'
        )}
      </FormHelperText>
      <Box
        sx={{
          width: isMobileDevice ? '91%' : '50%',
          position: 'absolute',
          left: '10px',
          zIndex: 2
        }}
      >
        {errorMessage && (
          <BasePageAlert
            alertTitle={`Error including attachment - ${errorMessage}`}
            isDismissable={true}
            onDismissed={() => {
              setErrorMessage('')
            }}
          />
        )}
      </Box>
      <AttachImageButton
        maxImages={MAX_UPLOADED_IMAGES}
        setUploadedImage={handleSetUploadedImage}
        setErrorMessage={setErrorMessage}
        uploadedImages={uploadedImages}
        isDisabled={isAttachImageButtonDisabled || isSubmitPending}
        setIsDisabled={setIsAttachImageButtonDisabled}
        isMultipleImagesToTextEnabled={!!isMultipleImagesToTextEnabled?.active}
      />

      {!!personalizedLink && (
        <AttachBookingLink
          attachLink={attachBookingLink}
          isDisabled={!!bookingLink || isSubmitPending}
        />
      )}
      <Box
        sx={{
          maxWidth: isMobileDevice ? '100%' : '60%',
          boxShadow: '2px -1px 10px 2px #ebe5e5, -2px 2px 15px 2px #ebe5e5',
          borderRadius: '7px',
          position: isMobileDevice ? 'inherit' : 'absolute',
          bottom: '4%',
          left: '4%',
          '& >div >div': {
            margin: 0
          }
        }}
      >
        {isError && isAlertVisible && (
          <BasePageAlert
            alertTitle={`Message not delivered - try again`}
            errorDetails={{
              errorData: error
            }}
            isDismissable={true}
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
      </Box>
      {isCopyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
    </Box>
  )
}

export default MessageInputForm
