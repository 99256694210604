import { ReactElement, useContext } from 'react'
import { Button, Box, Grid, Typography } from '@mui/material'
import { EditItemsProductT } from 'types/Product'
import { CurationProductT } from 'types/Curation'
import ItemImage from 'components/ItemImage'
import PriceBlock from 'components/PriceBlock'
import EditCurationItemNote from '../EditCurationItemNote'
import { CurationEditDispatchContext } from '../../CurationEditReducer'
import {
  SET_DEACTIVATED_CURATION_PRODUCT,
  SET_REACTIVATED_CURATION_PRODUCT
} from '../../constants'
import { determineCurrentPrice } from 'utils/priceHelpers'

type PropsT = {
  product: EditItemsProductT
  curationProduct: CurationProductT | undefined
  shouldShowSize: boolean
  productRemoved: boolean
  note: string | undefined
  handleClickForPdp: (webStyleId: string) => void
}

const DEFAULT_SIZE_LABEL = 'Size'

const EditCurationItem = (props: PropsT): ReactElement => {
  const {
    shouldShowSize,
    curationProduct,
    product,
    productRemoved,
    note,
    handleClickForPdp
  } = props

  const {
    imageUrl,
    brandName,
    productName,
    clearancePrice,
    promotionPrice,
    regularPrice,
    currentPriceType,
    enticements,
    size,
    sizeLabel,
    color,
    webStyleId
  } = product

  const productOpacity = productRemoved ? 0.5 : 1

  const dispatch = useContext(CurationEditDispatchContext)

  const removeProduct = (curationProduct: CurationProductT) => {
    dispatch &&
      dispatch({
        type: SET_DEACTIVATED_CURATION_PRODUCT,
        payload: { id: curationProduct.id, sku: curationProduct.rmsSku }
      })
  }

  const undoRemoveProduct = (curationProduct: CurationProductT) => {
    dispatch &&
      dispatch({
        type: SET_REACTIVATED_CURATION_PRODUCT,
        payload: { id: curationProduct.id, sku: curationProduct.rmsSku }
      })
  }

  const currentPrice = determineCurrentPrice({
    clearancePrice: clearancePrice,
    priceType: currentPriceType,
    promotionPrice: promotionPrice,
    regularPrice: regularPrice
  })

  const price = {
    current: currentPrice,
    original: regularPrice
  }

  return (
    <Box mb={3}>
      {/* outer grid container */}
      <Grid container wrap="nowrap" spacing={3} sx={{ height: ['auto', 220] }}>
        {/* first column in outer grid */}
        <Grid item>
          {/* item in first column */}
          <Box
            m={0.5}
            data-testid="edit-curation-item"
            sx={{
              width: {
                xs: 70,
                sm: 80
              },
              opacity: productOpacity
            }}
          >
            <Button
              aria-label="view product details"
              onClick={() => webStyleId && handleClickForPdp(webStyleId)}
              sx={{ maxWidth: '74px', padding: 0 }}
            >
              <ItemImage imageAltText={brandName} imageUrl={imageUrl} />
            </Button>
          </Box>
        </Grid>
        {/* second column in outer grid */}
        <Grid item zeroMinWidth>
          {/* items in second column */}
          <Box
            sx={{
              height: '100%'
            }}
          >
            <Box sx={{ opacity: productOpacity }}>
              <Box sx={{ mb: 2 }}>
                {brandName && (
                  <Typography fontWeight="bold" variant="body2" noWrap>
                    {brandName}
                  </Typography>
                )}
                {productName && (
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical'
                    }}
                  >
                    {productName}
                  </Typography>
                )}
              </Box>
              <Box sx={{ mb: 2 }}>
                {price && (
                  <PriceBlock enticements={enticements} price={price} />
                )}
              </Box>
              {size && shouldShowSize && (
                <Typography variant="body2" noWrap>
                  {sizeLabel ? sizeLabel : DEFAULT_SIZE_LABEL}: {size}
                </Typography>
              )}
              {color && (
                <Typography variant="body2" noWrap>
                  Color: {color}
                </Typography>
              )}
            </Box>
            <Box>
              {curationProduct && (
                <Button
                  onClick={() => removeProduct(curationProduct)}
                  sx={{ mt: 1 }}
                  disabled={productRemoved}
                  aria-disabled={productRemoved}
                >
                  Remove
                </Button>
              )}
              {curationProduct && productRemoved && (
                <Button
                  onClick={() => undoRemoveProduct(curationProduct)}
                  sx={{ mt: 1, opacity: 1 }}
                >
                  Undo
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ opacity: productOpacity }}>
        {curationProduct && (
          <EditCurationItemNote
            curationProductId={curationProduct.id}
            note={note}
          />
        )}
      </Box>
    </Box>
  )
}

export default EditCurationItem
