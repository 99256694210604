import { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Container,
  Typography,
  AppBar,
  Toolbar,
  IconButton
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Form from './components/Form'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import { useGetStylistProfilesQuery } from 'services/stylistProfileService'
import oktaTokenStorage from 'utils/okta-token-utils'
import StylistLinks from './components/StylistLinks'
import { useNavigation } from 'contexts/NavigationContext'
import { useGetPersonalizedLinkQuery } from 'services/appointmentsService'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const StylistProfile = (): ReactElement => {
  const history = useHistory()
  const { setDisplayAppBar } = useNavigation()

  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const employeeStore = oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()
  const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()
  const { isPersonalizedBookingLinkEnabled } = useFeatureFlags()

  const {
    data: profileData,
    isError,
    isLoading
  } = useGetStylistProfilesQuery({
    employeeId
  })

  const skip = !employeeStore || !employeeEmail
  const { data: linkData, isLoading: isLinkLoading } =
    useGetPersonalizedLinkQuery(
      { store: employeeStore, email: employeeEmail },
      { skip }
    )

  useEffect(() => {
    setDisplayAppBar(false)
    return () => setDisplayAppBar(true)
  }, [setDisplayAppBar])

  const goBackAction = () => {
    history.goBack()
  }

  if (isLoading || (isPersonalizedBookingLinkEnabled && isLinkLoading)) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <PageError />
  }

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: 'white', boxShadow: 'none' }}
      >
        <Toolbar sx={{ marginY: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <IconButton
              onClick={goBackAction}
              size="large"
              sx={{ marginRight: '16px' }}
            >
              <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
            </IconButton>
            <Typography variant="h6" color="text.primary">
              Profile
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <StylistLinks
          stylistHandle={profileData?.stylistProfiles?.[0]?.handle}
          personalizedLink={linkData?.link}
        />
        <Form
          stylistProfile={
            profileData?.stylistProfiles ? profileData.stylistProfiles[0] : null
          }
        />
      </Container>
    </>
  )
}

export default StylistProfile
