import React, { ReactElement } from 'react'
import { Card, CardContent, Typography, Grid } from '@mui/material'
import CustomerInfo from './CustomerInfo'
import Attributes from './Attributes'
import CardAndLoyaltyStatus from './CardAndLoyaltyStatus'
import AnniversaryEligibility from './AnniversaryEligibility'
import { CurationT } from 'types/Curation'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useGetCustomerLoyaltyQuery } from 'services/employeeExperienceApi'

interface PropsT {
  curation: {
    isError: boolean
    isLoading: boolean
    data?: {
      curation: CurationT
    }
  }
  customerId: string
}
const RequestDetails = (props: PropsT): ReactElement => {
  const { isEditRequestEnabledPersonalBook, isAnniversaryEligibilityEnablePB } =
    useFeatureFlags()
  const { curation, customerId } = props
  const { data: customerLoyaltyData, isSuccess: isCustomerLoyaltySuccess } =
    useGetCustomerLoyaltyQuery(customerId)
  const { loyaltyLevel, cardMember, eligibilityDate } =
    customerLoyaltyData || {}

  // A customer should only have one edit request at a time
  // so it is safe to assume that the data needed is the first item in the array
  const hasOpenEditRequest =
    curation.data?.curation?.editRequests[0]?.state === 'edit_requested'
      ? true
      : false

  return (
    <>
      {hasOpenEditRequest && isEditRequestEnabledPersonalBook?.active && (
        <Typography
          fontWeight="bold"
          variant="h6"
          sx={{ marginBottom: '24px', marginTop: '24px' }}
        >
          Original request
        </Typography>
      )}
      <Card variant="outlined">
        <CardContent>
          <Grid container width={1}>
            <Grid item width={1 / 2}>
              <CustomerInfo customerId={customerId} />
            </Grid>
            <Grid item width={1 / 2} container justifyContent="flex-end">
              {isAnniversaryEligibilityEnablePB?.active &&
                isCustomerLoyaltySuccess && (
                  <AnniversaryEligibility eligibilityDate={eligibilityDate} />
                )}
            </Grid>
          </Grid>
          <Attributes curation={curation} />
        </CardContent>
      </Card>
      {isCustomerLoyaltySuccess && (
        <CardAndLoyaltyStatus
          loyaltyLevel={loyaltyLevel}
          cardMember={cardMember}
        />
      )}
    </>
  )
}

export default React.memo(RequestDetails)
