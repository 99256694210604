import { ReactElement, useState } from 'react'

import { Box, Divider, styled } from '@mui/material'

import {
  HoldsCustomerInfoT,
  HoldsEnumCustomerType,
  HoldsHandleCloseT,
  HoldsHandleSubmitT
} from 'pages/Holds/types'

import {
  HOLDS_COLOR_DIVIDER,
  HOLDS_MEDIA_MAX_WIDTH_ADD_CUSTOMER,
  HOLDS_TEXT_ADD_CUSTOMER
} from 'pages/Holds/constants'

import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'

import {
  HoldsButtonCustomerAddGuestHold,
  HoldsButtonCustomerAddLookupHold
} from './components'

const BoxWithTopSpace = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3)
}))

const HoldsDialogBodyAddCustomer = ({
  customerInfo,
  handleClose,
  handleSubmit
}: {
  customerInfo: HoldsCustomerInfoT
} & HoldsHandleCloseT &
  HoldsHandleSubmitT): ReactElement => {
  const [dialogType, setDialogType] = useState(
    customerInfo.customerId ||
      (!customerInfo.customerId && !customerInfo.customerName)
      ? HoldsEnumCustomerType.LOOK_UP
      : HoldsEnumCustomerType.ADD_QUICK
  )

  return (
    <>
      <HoldsDialogAppBar
        title={HOLDS_TEXT_ADD_CUSTOMER}
        handleClose={handleClose}
      />

      <HoldsDialogContent
        sx={{
          maxWidth: HOLDS_MEDIA_MAX_WIDTH_ADD_CUSTOMER
        }}
      >
        <HoldsButtonCustomerAddLookupHold
          customerInfo={customerInfo}
          dialogType={dialogType}
          setDialogType={setDialogType}
          handleSubmit={handleSubmit}
        />

        <BoxWithTopSpace>
          <Divider
            sx={{
              color: HOLDS_COLOR_DIVIDER
            }}
          >
            or
          </Divider>
        </BoxWithTopSpace>

        <BoxWithTopSpace>
          <HoldsButtonCustomerAddGuestHold
            customerInfo={customerInfo}
            dialogType={dialogType}
            setDialogType={setDialogType}
            handleSubmit={handleSubmit}
          />
        </BoxWithTopSpace>
      </HoldsDialogContent>
    </>
  )
}

export default HoldsDialogBodyAddCustomer
