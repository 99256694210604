import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import {
  Container,
  Pagination,
  paginationClasses,
  Typography
} from '@mui/material'
import SnapshotNavigation from 'pages/SnapshotDrawer/components/SnapshotNavigation'
import { useGetStyleBoardsQuery } from 'services/employeeExperienceApi'
import { CURATION_ORDERING, CURATION_STATE } from 'types/Curation'
import oktaTokenStorage from 'utils/okta-token-utils'
import PageError from 'components/PageError'
import CurationCardList from 'components/CurationCardList'
import LoadingSpinner from 'components/LoadingSpinner'
import { SNAPSHOT_STYLE_BOARDS_ERROR_ALERT_TITLE } from 'pages/constants'

const PAGE_NUMBER_KEY = 'SnapshotStyleBoardsPageNumberKey'

const NoStyleBoards = () => (
  <Container>
    <Typography my="12px" mt={1} variant="body2" textAlign="left">
      No style boards
    </Typography>
  </Container>
)

type PropsT = {
  perPage: number
  customerId: string
}

const StyleBoardsList = ({ customerId, perPage }: PropsT): ReactElement => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const history = useHistory()
  const [page, setPage] = useState(1)
  const topNavigationRef = useRef<HTMLDivElement>(null)
  const pageKey = useMemo(
    () => `${customerId}/${PAGE_NUMBER_KEY}`,
    [customerId]
  )

  const {
    data: publishedStyleBoardsData,
    endpointName: publishedStyleBoardsEndpoint,
    error: publishedStyleBoardsError,
    isError: isGetPublishedStyleBoardsError,
    isLoading: isGetPublishedStyleBoardsLoading,
    isFetching: isGetPublishedStyleBoardsFetching
  } = useGetStyleBoardsQuery({
    state: CURATION_STATE.PUBLISHED,
    employeeId,
    customerId,
    page,
    perPage,
    orderBy: CURATION_ORDERING.COMPLETED_DESC
  })

  useEffect(() => {
    topNavigationRef.current?.scrollIntoView()
  }, [])

  useEffect(() => {
    const pageInStorage = sessionStorage.getItem(pageKey)

    if (pageInStorage) {
      setPage(Number(pageInStorage))
    }
  }, [pageKey])

  const handleBackButtonClick = () => history.goBack()

  const handleChangePage = (page: number) => {
    sessionStorage.setItem(pageKey, page.toString())
    setPage(page)
  }

  const styleBoards = publishedStyleBoardsData?.styleBoards
  const totalStyleBoardsCount = publishedStyleBoardsData?.meta.count

  const isDataLoading =
    isGetPublishedStyleBoardsLoading || isGetPublishedStyleBoardsFetching

  if (!styleBoards?.length && !isDataLoading && !publishedStyleBoardsError) {
    return <NoStyleBoards />
  }

  return (
    <Container>
      <div ref={topNavigationRef} />
      <SnapshotNavigation
        title="Style boards"
        onClick={handleBackButtonClick}
      />

      {isDataLoading ? (
        <LoadingSpinner />
      ) : !isGetPublishedStyleBoardsError &&
        styleBoards &&
        totalStyleBoardsCount ? (
        <>
          <CurationCardList
            hideMenu
            curations={styleBoards}
            isPublicBoardList={false}
          />

          <Pagination
            data-testid="Pagination"
            count={Math.ceil(totalStyleBoardsCount / perPage)}
            page={page}
            onChange={(_event, page) => handleChangePage(page)}
            sx={{
              [`& .${paginationClasses.ul}`]: {
                justifyContent: 'center'
              },
              paddingBottom: '20px'
            }}
          />
        </>
      ) : (
        <PageError
          isInlineAlert
          isFullWidth
          isErrorTitlePersonalized
          errorTitle={SNAPSHOT_STYLE_BOARDS_ERROR_ALERT_TITLE}
          errorDetails={{
            endpoint: publishedStyleBoardsEndpoint,
            errorData: publishedStyleBoardsError,
            identifiers: { customerId, employeeId }
          }}
        />
      )}
    </Container>
  )
}

export default StyleBoardsList
