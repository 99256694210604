import {
  EmployeeProductT,
  PriceFormatTypes,
  ServerEditItemsProductT,
  ServerEmployeeProductT
} from 'types/Product'
import { formatNumericPrice } from './formatPrice'

export const parsePrice = (
  product: ServerEmployeeProductT | ServerEditItemsProductT,
  priceType: PriceFormatTypes
): number => {
  const units =
    product?.sku?.sellingControls[0]?.price?.[priceType]?.price?.units
  const nanos =
    product?.sku?.sellingControls[0]?.price?.[priceType]?.price?.nanos

  return formatNumericPrice(units, nanos)
}

export const formatOfferProductToEmployeeProduct = (
  product: ServerEmployeeProductT
): EmployeeProductT => {
  const item = {
    isAvailable:
      product?.sku?.sellingControls[0]?.productAttributes?.isOnlinePurchasable,
    brandName: product?.productAttributes?.vendor?.labelName,
    clearancePrice: parsePrice(product, PriceFormatTypes.CLEARANCE),
    color: product?.mediaExperiences?.carouselsByColor[0]?.colorName,
    currentPriceType: product.sku?.sellingControls[0]?.price.currentPriceType,
    enticements:
      product?.sku?.sellingControls[0]?.marketingAttributes?.enticements,
    imageUrl:
      product?.mediaExperiences?.carouselsByColor[0]?.orderedShots[0]?.url,
    itemName: product?.productAttributes?.name,
    itemNumber: product?.ids.rmsStyleGroup?.id,
    promotionPrice: parsePrice(product, PriceFormatTypes.PROMOTION),
    regularPrice: parsePrice(product, PriceFormatTypes.REGULAR),
    rmsSku: product?.sku?.ids?.rmsSku?.id,
    size: product?.sku?.productAttributes?.size?.name,
    sizeLabel: product?.productAttributes?.sizeLabel,
    webStyleId: product?.ids?.webStyle?.id
  }
  return item
}
