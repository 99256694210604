import { ReactElement } from 'react'
import {
  Accordion,
  AccordionSummary,
  Container,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FashionPillarItem } from 'pages/SnapshotDrawer/components/FashionPillar/FashionPillarItem'
import { OtherStylesT } from 'types/FashionPillar'

export type PropsT = {
  otherPillars: OtherStylesT[]
}

const OtherStyles = (props: PropsT): ReactElement => {
  const { otherPillars } = props

  return (
    <>
      <Typography variant="overline" pl={[2, 3]}>
        Other Styles
      </Typography>

      {otherPillars.map((pillarInfo: OtherStylesT) => {
        const dcapiPillar = pillarInfo.dcapiPillar
        const pasPillar = pillarInfo.pasPillar
        return (
          <Accordion key={pasPillar.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {dcapiPillar && (
                <Typography variant="body2" pl={[0, 1]}>
                  {dcapiPillar.title}
                </Typography>
              )}

              <Typography color="text.secondary" variant="body2" pl={1}>
                {`${pasPillar.percent}%`}
              </Typography>
            </AccordionSummary>

            <Container>
              {dcapiPillar && (
                <FashionPillarItem
                  description={dcapiPillar.description}
                  image={dcapiPillar.image}
                />
              )}
            </Container>
          </Accordion>
        )
      })}
    </>
  )
}

export default OtherStyles
