import { ReactElement } from 'react'
import { Link, Box, Card } from '@mui/material'
import GridPanel from 'components/Tabs/GridPanel'
import OrderInformation from '../OrderInformation'

import { OrderT } from 'types/Orders'

export type PropsT = {
  order: OrderT
}

const RecentPurchasesCard = (props: PropsT): ReactElement => {
  const { order } = props
  const products = order.productsAndStatus[0].products
  return (
    <Link
      href={`#snapshot/purchase-history/order/${order.orderId}?pageNumber=1`}
      underline="none"
    >
      <Card variant="outlined" sx={{ mb: 2, py: 0 }}>
        <Box p={2}>
          <OrderInformation order={order} displayItemCount={false} />
        </Box>

        {products && <GridPanel products={products} />}
      </Card>
    </Link>
  )
}

export default RecentPurchasesCard
