import { Box, Divider, ListItemIcon, ListItemText } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { ArchivedCustomerT } from 'types/ConsentedCustomer'

type PropsT = {
  archivedCustomer: ArchivedCustomerT
  goToDetails: (customerId: string) => void
}
const ArchivedCustomersListItem: (props: PropsT) => JSX.Element = (
  props: PropsT
) => {
  const { archivedCustomer, goToDetails } = props
  const displayName =
    archivedCustomer.firstName && archivedCustomer.lastName
      ? `${archivedCustomer.firstName} ${archivedCustomer.lastName}`
      : archivedCustomer.email
  return (
    <Box
      key={archivedCustomer.customerId}
      onClick={() => goToDetails(archivedCustomer.customerId)}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        paddingY={1}
        paddingX={2}
        sx={{
          cursor: 'pointer',
          height: 48
        }}
      >
        <ListItemText
          sx={{ width: '400px' }}
          primary={displayName}
          primaryTypographyProps={{
            style: {
              fontWeight: 'bold',
              fontSize: '16px'
            },
            noWrap: true
          }}
        />
        <ListItemIcon sx={{ minWidth: 24 }}>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </Box>
      <Divider />
    </Box>
  )
}

export default ArchivedCustomersListItem
