import { ReactElement } from 'react'
import { Stack } from '@mui/material'
import Skeleton from 'components/Skeleton'

const LoadingAttributes = (): ReactElement => (
  <Stack direction="row">
    <Stack direction="column" spacing={2} width={1 / 3} pr={2}>
      <Skeleton.Text textVariant="body2" width="100%" />
      <Skeleton.Text textVariant="body2" width="100%" />
    </Stack>
    <Stack direction="column" spacing={2} width={1 / 3} pr={2}>
      <Skeleton.Text textVariant="body2" width="100%" />
      <Skeleton.Text textVariant="body2" width="100%" />
    </Stack>
    <Stack direction="column" spacing={2} width={1 / 3}>
      <Skeleton.Text textVariant="body2" width="100%" />
      <Skeleton.Text textVariant="body2" width="100%" />
    </Stack>
  </Stack>
)

export default LoadingAttributes
