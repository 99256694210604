import { ReactElement, useEffect } from 'react'

import { useCompleteHoldMutation } from 'services/holdsApi'

import { HoldT } from 'types/Holds'
import { HoldEnumStatusType, HoldsHandleCloseT } from 'pages/Holds/types'

import {
  HOLDS_TEXT_HOLD_STATUS_NOT_UPDATED,
  HOLDS_TEXT_HOLD_STATUS_UPDATED
} from 'pages/Holds/constants'

import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import { useHoldsToggle } from 'pages/Holds/hooks'

import BaseDialog from 'components/BaseDialog'
import LoadingBackdrop from 'components/LoadingBackdrop'

import { getResponseErrorMessageOrErrorMessage } from 'pages/Holds/shared/utils/holdsErrorUtils'
import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'

import HoldsDialogBodyUpdateHoldStatus from './components/HoldsDialogBodyUpdateHoldStatus'

const HoldsDialogUpdateHoldStatus = ({
  hold,
  isOpen,
  handleClose,
  handleUpdateDetails
}: HoldsHandleCloseT & {
  hold: HoldT
  isOpen: boolean
  handleUpdateDetails: (hold: HoldT) => void
}): ReactElement => {
  const {
    handleNotificationAddSuccessMessage,
    handleNotificationAddErrorMessage
  } = useHoldsContextNotification()

  const {
    isOpen: isCancelDialogOpen,
    handleOpen: handleOpenCancelDialog,
    handleClose: handleCloseCancelDialog
  } = useHoldsToggle()

  const [
    postCompleteHold,
    {
      isLoading: isUpdateHoldLoading,
      isSuccess: isUpdateHoldSuccess,
      reset: resetUpdateHoldRequestCache
    }
  ] = useCompleteHoldMutation()

  const notAllowActions = isUpdateHoldLoading || isUpdateHoldSuccess

  const onCloseConfirm = () => {
    if (notAllowActions) {
      return
    }

    handleCloseCancelDialog()
    handleClose()
  }

  const onCloseCancel = () => {
    if (notAllowActions) {
      return
    }

    handleCloseCancelDialog()
  }

  const handleSave = async (lineItemsStatuses: Record<string, string>) => {
    try {
      const response = await postCompleteHold({
        ticketNumber: hold.ticketNumber,
        lineItems: Object.entries(lineItemsStatuses).map(([id, status]) => ({
          id: Number(id),
          status: status as HoldEnumStatusType
        }))
      }).unwrap()

      if (response.ticketNumber) {
        /**
         * FYI: HOLDS - in list we have "completedAt"
         * when load details we don't have this field
         * also, we have for lineItems extra fields: completedAt and completedBy
         *
         * Shallow merge current hold details from list
         * with hold details as need data from both calls
         */
        handleUpdateDetails({
          ...hold,
          ...response
        })

        handleNotificationAddSuccessMessage({
          title: HOLDS_TEXT_HOLD_STATUS_UPDATED
        })

        handleClose()
      } else {
        handleNotificationAddErrorMessage({
          title: HOLDS_TEXT_HOLD_STATUS_NOT_UPDATED
        })
      }
    } catch (e) {
      handleNotificationAddErrorMessage({
        title:
          getResponseErrorMessageOrErrorMessage(e) ??
          HOLDS_TEXT_HOLD_STATUS_NOT_UPDATED
      })

      return
    }
  }

  useEffect(() => {
    isOpen && resetUpdateHoldRequestCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      <HoldsDialog
        data-testid="update_hold_status_dialog"
        open={isOpen}
        onClose={handleOpenCancelDialog}
      >
        <HoldsDialogBodyUpdateHoldStatus
          key={hold.lineItems.length}
          hold={hold}
          notAllowActions={notAllowActions}
          handleSubmit={handleSave}
          handleClose={handleOpenCancelDialog}
        />
      </HoldsDialog>

      <LoadingBackdrop open={isUpdateHoldLoading} />

      <BaseDialog
        title={'Are you sure you want to cancel?'}
        contentText={'The Hold statuses will not be saved.'}
        confirm={{
          action: onCloseConfirm,
          text: 'Yes, Cancel'
        }}
        cancel={{
          action: onCloseCancel,
          text: 'Nevermind'
        }}
        onClose={onCloseCancel}
        open={isCancelDialogOpen}
      />
    </>
  )
}

export default HoldsDialogUpdateHoldStatus
