import { ReactElement, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import {
  useGetPublishedCurationsQuery,
  useUnpublishCurationMutation
} from 'services/curationSvc'
import {
  EMPTY_CUSTOMER_BOARDS_MESSAGE,
  EMPTY_PUBLIC_BOARDS_MESSAGE
} from 'pages/constants'
import BaseSnackbar from 'components/BaseSnackbar'
import CurationCardListTabs, {
  TabPosition
} from 'components/CurationCardListTabs'
import CurationCardList from 'components/CurationCardList'
import PageError from 'components/PageError'
import LoadingSpinner from 'components/LoadingSpinner'
import UnpublishAlert from 'components/UnpublishAlert'
import { useHistory } from 'react-router-dom'
import { PUBLISHED_CURATION_TYPE } from 'types/Curation'
import { TabPanel } from '@mui/lab'
import DividerComponent from '../DividerComponent'

type PropsT = {
  employeeId: string
}

const FINISHED_BOARDS_PAGE_PATH = '/styleboards/finished'
const PREVIEW_TAB_POSTION = 'previewBoardTabPosition'
const PUBLISHED_ENDPOINT_API_VERSION = '2.0'
const ALERT_TITLE = 'We had a problem retrieving your finished boards.'

const EmptyFinishedBoardsPreview = ({
  title,
  subtitle
}: {
  title: string
  subtitle: string
}) => {
  return (
    <Box mt={2}>
      <Typography variant="body2" fontWeight="bold" mb={0.5}>
        {title}
      </Typography>
      <Typography variant="body2">{subtitle}</Typography>
    </Box>
  )
}

const FinishedBoardsPreview = (props: PropsT): ReactElement => {
  const { employeeId } = props
  const [tabPosition, setTabPosition] = useState(TabPosition.CUSTOMER)

  const history = useHistory()

  const {
    data: customerCurationsData,
    isLoading: isCustomerCurationsLoading,
    isError: isCustomerCurationsError
  } = useGetPublishedCurationsQuery({
    employeeId,
    perPage: 5,
    version: PUBLISHED_ENDPOINT_API_VERSION,
    type: PUBLISHED_CURATION_TYPE.CUSTOMER
  })

  const {
    data: publicCurationsData,
    isLoading: isPublicCurationsLoading,
    isError: isPublicCurationsError
  } = useGetPublishedCurationsQuery({
    employeeId,
    perPage: 5,
    version: PUBLISHED_ENDPOINT_API_VERSION,
    type: PUBLISHED_CURATION_TYPE.PUBLIC
  })

  const [
    unpublishCuration,
    {
      error: unpublishError,
      isError: isUnpublishError,
      isSuccess: isUnpublishSuccess
    }
  ] = useUnpublishCurationMutation()

  useEffect(() => {
    const storedTabPosition = localStorage.getItem(PREVIEW_TAB_POSTION)
    if (
      storedTabPosition &&
      Object.values(TabPosition).includes(storedTabPosition as TabPosition)
    ) {
      setTabPosition(String(storedTabPosition) as TabPosition)
    }
    return () => {
      localStorage.removeItem(PREVIEW_TAB_POSTION)
    }
  }, [])

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: TabPosition
  ) => {
    setTabPosition(newValue)
    localStorage.setItem(PREVIEW_TAB_POSTION, String(newValue))
  }

  if (isCustomerCurationsLoading || isPublicCurationsLoading) {
    return <LoadingSpinner />
  }

  if (isCustomerCurationsError || isPublicCurationsError) {
    return (
      <PageError
        errorTitle={ALERT_TITLE}
        isErrorTitlePersonalized={true}
        isFullWidth={true}
        isInlineAlert={true}
      />
    )
  }

  if (customerCurationsData && publicCurationsData) {
    return (
      <>
        <DividerComponent />
        <Box width={'100%'}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h6" mb={1}>
              Finished boards
            </Typography>
            <Button onClick={() => history.push(FINISHED_BOARDS_PAGE_PATH)}>
              View All
            </Button>
          </Box>

          {isUnpublishError && (
            <UnpublishAlert unpublishError={unpublishError} />
          )}
          {isUnpublishSuccess && (
            <BaseSnackbar message={'Public board deleted.'} />
          )}

          <CurationCardListTabs
            handleTabChange={handleTabChange}
            tabPosition={tabPosition}
          >
            <TabPanel value={TabPosition.CUSTOMER} sx={{ p: 0 }}>
              {customerCurationsData.curations.length > 0 ? (
                <CurationCardList
                  curations={customerCurationsData.curations}
                  isPublicBoardList={false}
                />
              ) : (
                <EmptyFinishedBoardsPreview
                  title="No customer boards"
                  subtitle={EMPTY_CUSTOMER_BOARDS_MESSAGE}
                />
              )}
            </TabPanel>
            <TabPanel value={TabPosition.PUBLIC} sx={{ p: 0 }}>
              {publicCurationsData.curations.length > 0 ? (
                <CurationCardList
                  curations={publicCurationsData.curations}
                  isPublicBoardList={true}
                  unpublishCuration={unpublishCuration}
                />
              ) : (
                <EmptyFinishedBoardsPreview
                  title="No public boards"
                  subtitle={EMPTY_PUBLIC_BOARDS_MESSAGE}
                />
              )}
            </TabPanel>
          </CurationCardListTabs>
        </Box>
      </>
    )
  }

  return <></>
}

export default FinishedBoardsPreview
