import { PushSubscription } from 'types/Notification'

export const checkDoesBrowserSupportServiceWorkers = (): boolean => {
  if ('serviceWorker' in navigator) {
    return true
  } else {
    return false
  }
}

export const registerServiceWorker = async (): Promise<void> => {
  const isServiceWorkerSupported = checkDoesBrowserSupportServiceWorkers()
  try {
    if (!isServiceWorkerSupported) {
      throw new Error('Browser does not support service workers')
    }
    const baseUrl = window.location.origin
    await navigator.serviceWorker.register(`${baseUrl}/service-worker.js`, {
      // Without specifying a narrow scope, the service worker competes with MSW in dev env and breaks on page reload
      scope: `${baseUrl}/messages`
    })
  } catch (error) {
    console.error('Unable to register service worker: ' + error)
  }
}

export const getServiceWorkerRegistration = async (): Promise<
  ServiceWorkerRegistration | undefined
> => {
  const isServiceWorkerSupported = checkDoesBrowserSupportServiceWorkers()
  try {
    if (!isServiceWorkerSupported) {
      throw new Error('Browser does not support service workers')
    }
    const registrations =
      (await navigator.serviceWorker.getRegistrations()) || []
    return registrations.find((registration) => {
      const scriptUrl = registration?.active?.scriptURL || ''
      return scriptUrl.includes('service-worker.js')
    })
  } catch (error) {
    console.error('Unable to retrieve service worker registration: ' + error)
    return
  }
}

export const getPushManager = async (): Promise<PushManager | undefined> => {
  try {
    const registration = await getServiceWorkerRegistration()
    if (!registration) {
      throw new Error('No registered service worker')
    }
    const pushManager = registration.pushManager
    if (!pushManager) {
      throw new Error('No push manager associated with service worker')
    }
    return pushManager
  } catch (error) {
    console.error('Unable to retrieve push manager: ' + error)
    return
  }
}

export const getDidUserBlockNotifications = async (): Promise<boolean> => {
  try {
    const pushManager = await getPushManager()
    const permissionState: PermissionState | undefined =
      await pushManager?.permissionState({ userVisibleOnly: true })
    return permissionState === 'denied' || !permissionState
  } catch (error) {
    console.error('Unable to retrieve push permissions: ' + error)
    return false
  }
}

export const getIsUserSubscribed = async (): Promise<boolean> => {
  try {
    const pushSubscription = await getPushSubscription()
    return !!pushSubscription
  } catch (error) {
    console.error('Unable to retrieve push subscription: ' + error)
    return false
  }
}

export const askForPermission = async (): Promise<undefined> => {
  const permissionResult = await Notification.requestPermission()
  if (permissionResult !== 'granted') {
    throw new Error('Permission not granted.')
  }
}

export const subscribeToPushNotifications = async (): Promise<
  PushSubscription | undefined
> => {
  try {
    await askForPermission()
    const pushManager = await getPushManager()
    const pushSubscription = await pushManager?.subscribe({
      applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
      userVisibleOnly: true
    })
    const pushSubscriptionToJSON = pushSubscription?.toJSON()
    return {
      endpoint: pushSubscriptionToJSON?.endpoint,
      keys: {
        auth: pushSubscriptionToJSON?.keys?.auth,
        p256dh: pushSubscriptionToJSON?.keys?.p256dh
      }
    } as PushSubscription
  } catch (error) {
    // TODO: Handle in NON-1568
    console.error('error: ', error)
  }
}

export const getPushSubscription = async (): Promise<
  globalThis.PushSubscription | null | undefined
> => {
  const pushManager = await getPushManager()
  const pushSubscription = await pushManager?.getSubscription()
  return pushSubscription
}

export const unsubscribeFromPushNotifications = async (): Promise<
  boolean | undefined
> => {
  const pushSubscription = await getPushSubscription()
  if (!pushSubscription) return
  const unsubscribed = await pushSubscription.unsubscribe()
  return unsubscribed
}
