import { CardMedia, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { CSSProperties, ReactElement, useState } from 'react'
import { getMediaContentType } from 'services/twilioSlice/utils'
import { MediaContentType, ReduxMedia } from 'types/Twilio'
import { checkBrowserClient } from 'utils/userAgentDetector'

const isMobileDevice = checkBrowserClient.isMobile()

const VideoMessage = ({
  mediaUrl,
  id,
  hasLoaded,
  setHasLoaded
}: {
  mediaUrl: string
  id: string
  hasLoaded: boolean
  setHasLoaded: () => void
}): ReactElement => {
  return (
    <>
      <CardMedia
        id={id}
        src={`${mediaUrl}#t=0.001`}
        onCanPlay={setHasLoaded}
        component="video"
        data-testid="videoMessage"
        controls
        sx={{
          display: !hasLoaded ? 'none' : undefined,
          height: 'auto',
          width: 'auto',
          maxWidth: isMobileDevice
            ? '253px'
            : {
                lg: '336px',
                md: '270px',
                sm: '253px'
              }
        }}
      />
    </>
  )
}

const ImageMessage = ({
  mediaUrl,
  onClick,
  fullScreen,
  id,
  hasLoaded,
  setHasLoaded
}: {
  mediaUrl: string
  onClick?: () => void
  fullScreen?: boolean
  id: string
  hasLoaded: boolean
  setHasLoaded: () => void
}): ReactElement => {
  const theme = useTheme()
  const matchesScreenSizeMd = useMediaQuery(
    theme.breakpoints.between('md', 'lg')
  )
  const matchesScreenSizeSm = useMediaQuery(
    theme.breakpoints.between('xs', 'md')
  )

  const imageWidth =
    isMobileDevice || matchesScreenSizeSm
      ? '253px'
      : matchesScreenSizeMd
      ? '270px'
      : '336px'

  const fullscreenStyle: CSSProperties = {
    maxWidth: '100%',
    maxHeight: '80vh',
    objectFit: 'contain',
    height: !hasLoaded ? 0 : 'auto'
  }

  const defaultStyle: CSSProperties = {
    cursor: 'pointer',
    height: !hasLoaded ? 0 : isMobileDevice ? '180px' : 'auto',
    objectFit: 'cover',
    width: 'auto',
    maxWidth: imageWidth
  }

  return (
    <img
      id={id}
      src={mediaUrl}
      alt="Image message"
      onClick={onClick}
      onLoad={setHasLoaded}
      style={fullScreen ? fullscreenStyle : defaultStyle}
      data-testid="imageMessage"
    />
  )
}

const MediaMessage = ({
  media,
  fullScreen,
  onClick,
  id
}: {
  media: ReduxMedia
  fullScreen?: boolean
  onClick?: () => void
  id: string
}): ReactElement => {
  const [hasMediaLoaded, setHasMediaLoaded] = useState(false)

  const type = getMediaContentType(media)

  return (
    <>
      {!hasMediaLoaded && (
        <Skeleton
          sx={{ height: 180, width: 253 }}
          animation="wave"
          variant="rectangular"
        />
      )}

      {type === MediaContentType.IMAGE ? (
        <ImageMessage
          mediaUrl={media.url}
          id={id}
          onClick={onClick}
          fullScreen={fullScreen}
          hasLoaded={hasMediaLoaded}
          setHasLoaded={() => setHasMediaLoaded(true)}
        />
      ) : type === MediaContentType.VIDEO ? (
        <VideoMessage
          mediaUrl={media.url}
          id={id}
          hasLoaded={hasMediaLoaded}
          setHasLoaded={() => setHasMediaLoaded(true)}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default MediaMessage
