import { ReactElement, useEffect, useState } from 'react'

import { Box, Button, CircularProgress, Typography } from '@mui/material'

import BoxWithGladlySpace from 'components/BoxWithGladlySpace'

import { GetHoldsQueryDataT, HoldT } from 'types/Holds'

import { HOLDS_TEXT_FOUND } from 'pages/Holds/constants'

import { useHoldsContextNotification } from 'pages/Holds/contexts/HoldsContextNotification'

import type { HoldsErrorMessageT } from 'pages/Holds/hooks'

import {
  useFetchGetHoldsByPhoneNumber,
  useHoldsRefState,
  useHoldsToggle
} from 'pages/Holds/hooks'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'
import HoldsDialogContent from 'pages/Holds/shared/components/HoldsDialogContent'
import HoldsDialogAppBar from 'pages/Holds/shared/components/HoldsDialogAppBar'
import HoldsProductItemCards from 'pages/Holds/shared/components/HoldsProductItemCards'

import { HoldsButtonFindPhoneNumber } from './components'

const offsetShiftDecrement = 0

const HoldsFindByPhoneNumber = (): ReactElement => {
  const [toggleFetchRequest, setToogleFetchRequest] = useState(false)
  const handleToggleForceFetchRequest = () =>
    setToogleFetchRequest((prevState) => !prevState)

  const { handleNotificationAdd, handleNotificationAddErrorMessage } =
    useHoldsContextNotification()

  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('')
  const refStateCustomerPhoneNumber = useHoldsRefState(customerPhoneNumber)

  const {
    holds,
    setHolds,
    isLoading,
    handleLoadMore,
    fetchGetHoldsByPhoneNumberCallback
  } = useFetchGetHoldsByPhoneNumber({
    customerPhoneNumber,
    offsetShiftDecrement,
    withLoadMore: true
  })

  const holdsLengthWithRequestedStatuses = holds.data.length
  const holdsDeltaRemains = holds.total - holdsLengthWithRequestedStatuses
  const loadQuantityRemains = holdsDeltaRemains

  const {
    isOpen: isHoldsDetailsListOpen,
    handleOpen: handleHoldsDetailsListOpen,
    handleClose: handleHoldsDetailsListClose
  } = useHoldsToggle({
    allowClose: !isLoading
  })

  /**
   * Fetch holds data if reference changed due to updating customerPhoneNumber or offset
   * Allow recall fetch only if we have customerPhoneNumber
   */
  useEffect(() => {
    if (!refStateCustomerPhoneNumber.current) {
      return
    }

    fetchGetHoldsByPhoneNumberCallback()
      .then((response) => {
        if (response) {
          if ((response as HoldsErrorMessageT).errorMessage) {
            handleNotificationAddErrorMessage({
              title: (response as HoldsErrorMessageT)?.errorMessage ?? ''
            })

            return
          }

          if (
            !(response as GetHoldsQueryDataT)?.data?.length &&
            !(response as GetHoldsQueryDataT)?.total
          ) {
            handleNotificationAddErrorMessage({
              title: `Holds not found.`
            })

            return
          }

          handleHoldsDetailsListOpen()
        } else {
          handleNotificationAddErrorMessage({
            title: 'Response error for Holds.'
          })
        }

        return
      })
      .catch(() => {
        return
      })
  }, [
    toggleFetchRequest,
    fetchGetHoldsByPhoneNumberCallback,
    refStateCustomerPhoneNumber,
    handleHoldsDetailsListOpen,
    handleNotificationAdd,
    handleNotificationAddErrorMessage
  ])

  /**
   * Reset customer name and hold list with offset inside hook
   * when closing dialog
   */
  useEffect(
    () => () => {
      if (isHoldsDetailsListOpen) {
        setCustomerPhoneNumber('')
      }
    },
    [isHoldsDetailsListOpen, setHolds]
  )

  return (
    <>
      <Box mb={2}>
        <Typography variant="body2">
          To look up an existing hold, search by the customer’s mobile number or
          scan the barcode on the hold ticket below.
        </Typography>
      </Box>

      <Box mb={2}>
        <HoldsButtonFindPhoneNumber
          isLoading={isLoading}
          handleSubmit={(customerPhoneNumber) => {
            setCustomerPhoneNumber(customerPhoneNumber)
            handleToggleForceFetchRequest()
          }}
        />
      </Box>

      <HoldsDialog
        data-testid="find_phone_number_results_dialog"
        open={isHoldsDetailsListOpen}
        onClose={handleHoldsDetailsListClose}
      >
        <HoldsDialogAppBar
          title={HOLDS_TEXT_FOUND}
          handleClose={handleHoldsDetailsListClose}
          closeIconButtonProps={{
            'data-testid': 'back_arrow_holds_found'
          }}
        />

        <HoldsDialogContent>
          <BoxWithGladlySpace>
            {isLoading && !holds.data.length && (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={32} />
              </Box>
            )}
            <HoldsProductItemCards
              holds={holds.data}
              handleUpdateDetails={(hold: HoldT) => {
                setHolds((prevState) => {
                  const cloneData = [...prevState.data]

                  const index = cloneData.findIndex(
                    (productItem) =>
                      productItem.ticketNumber === hold.ticketNumber
                  )

                  cloneData[index] = hold

                  return {
                    data: cloneData,
                    total: holds.total
                  }
                })
              }}
            />

            {loadQuantityRemains > 0 && (
              <Button
                fullWidth
                variant="text"
                onClick={handleLoadMore}
                disabled={isLoading}
                sx={{
                  mt: 2
                }}
              >
                {isLoading ? (
                  <CircularProgress size={28} />
                ) : (
                  <>Load {loadQuantityRemains} more</>
                )}
              </Button>
            )}
          </BoxWithGladlySpace>
        </HoldsDialogContent>
      </HoldsDialog>
    </>
  )
}

export default HoldsFindByPhoneNumber
