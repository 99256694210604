export enum CustomerBookFilterKeys {
  ANNIVERSARY = 'qualification',
  NORDY_CLUB = 'loyaltyStatus',
  CARDMEMBER = 'cardmember',
  NORDSTROM_NOTES = 'nordstromNotes',
  SAVED_BRANDS = 'brands',
  LAST_MESSAGE_TIME = 'lastMessage'
}

export type CustomerBookFilterOption = {
  name: string
  checked: boolean
  dateEA?: string
}

export type CustomerBookFilter = {
  title: string
  options: CustomerBookFilterOption[]
  selectedOptionsCounter: number
  disabled: boolean
}
export type CustomerBookFilters = Record<
  CustomerBookFilterKeys,
  CustomerBookFilter
>

export enum CustomerBookNoteExpirationFilterCategories {
  TWO_WEEKS = 'Expiring within 2 weeks',
  ONE_MONTH = 'Expiring within 1 month',
  THREE_MONTHS = 'Expiring within 3 months',
  AVAILABLE_NOTES = 'Any available notes'
}

export enum AnniversaryEventFilterCategories {
  ICON_EA = 'Icon EA',
  AMBASSADOR_EA = 'Ambassador EA',
  INFLUENCER_EA = 'Influencer EA',
  ACCESS_PASS = 'Access Pass',
  PUBLIC_SALE = 'Public Sale'
}

/* Anniversary Dates - 2024 */
export enum AnniversaryDateFilterCategories {
  ICON_EA = '7/9',
  AMBASSADOR_EA = '7/10',
  INFLUENCER_EA = '7/11',
  ACCESS_PASS = '7/13',
  PUBLIC_SALE = '7/15'
}

export enum CustomerBookLastMessageDateFilterCategories {
  ONE_MONTH = 'Last 30 days',
  MORE_THAN_ONE_MONTH = '1+ month ago',
  MORE_THAN_THREE_MONTHS = '3+ months ago',
  MORE_THAN_SIX_MONTHS = '6+ months ago',
  MORE_THAN_NINE_MONTHS = '9+ months ago',
  MORE_THAN_TWELVE_MONTHS = '12+ months ago',
  NO_MESSAGES = 'No messages'
}
