import { ReactElement } from 'react'
import { Stack, Container } from '@mui/material'
import Skeleton from 'components/Skeleton'

export const LoadingFashionPillar = (): ReactElement => (
  <Container>
    <Stack direction="column" mt={2} spacing={2} width={'100%'}>
      <Skeleton.Text textVariant="body2" width="20%" />
      <Skeleton.Image aspectRatio={112 / 65} />
      <Skeleton.Text textVariant="body2" width="100%" />
      <Skeleton.Text textVariant="body2" width="100%" />
    </Stack>
  </Container>
)
