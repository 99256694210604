import { useState, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import AccordionSummary from '@mui/material/AccordionSummary'
import Divider from '@mui/material/Divider'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import {
  toggleFilterOption,
  customerBookFiltersSelector,
  clearFilters,
  customerBookFiltersCounterSelector
} from 'services/customerBookFiltersSlice'
import { CustomerBookFilterKeys } from 'types/CustomerBookFilters'
import FilterOptions from './FilterOptions'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { logAppliedFilterNREvents } from 'pages/CustomerHome/utils'

export const FILTERS_DRAWER_WIDTH = 320
export const FILTERS_HASH = 'filters'

const RESPONSIVE_WIDTH = { xs: '100%', sm: `${FILTERS_DRAWER_WIDTH}px` }

type mapSectionT = Record<CustomerBookFilterKeys, boolean>

const mapSectionDefault = {
  qualification: true,
  loyaltyStatus: true,
  cardmember: true,
  nordstromNotes: true,
  brands: true,
  lastMessage: true
}

type PropsT = {
  onClose: () => void
  customersCount: number
}

const FiltersContent = (props: PropsT): JSX.Element => {
  const { onClose, customersCount } = props
  const [clearAllButtonDisabled, setClearAllButtonDisabled] = useState(false)
  const [viewCustomersButtonDisabled, setViewCustomersButtonDisabled] =
    useState(false)
  const hasFilterResults = useMemo(() => customersCount > 0, [customersCount])
  const viewButtonText = `View ${customersCount} customer${
    customersCount !== 1 ? 's' : ''
  }`
  const {
    isClientelingAnniversaryStylingAppEnabled,
    isLastMessageFilterEnabled
  } = useFeatureFlags()
  const isAnniversary = isClientelingAnniversaryStylingAppEnabled?.active
  const appDispatch = useAppDispatch()
  const customerBookFilters = useAppSelector(customerBookFiltersSelector)
  const [sectionSelected, setSectionSelected] =
    useState<mapSectionT>(mapSectionDefault)

  const customerBookFiltersCounter = useAppSelector(
    customerBookFiltersCounterSelector
  )
  const clearAllAction = () => appDispatch(clearFilters())

  const handleViewCustomersClick = () => {
    logAppliedFilterNREvents({
      customerBookFilters,
      generateNewRelicLogs
    })
    onClose()
  }

  useEffect(() => {
    setClearAllButtonDisabled(customerBookFiltersCounter === 0)
    setViewCustomersButtonDisabled(
      customerBookFiltersCounter > 0 && !hasFilterResults
    )
  }, [customerBookFiltersCounter, hasFilterResults])

  return (
    <Box
      sx={{
        width: RESPONSIVE_WIDTH,
        overflow: 'scroll',
        '&::-webkit-scrollbar': { display: 'none' }
      }}
    >
      <Container
        sx={{
          mt: '8px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'fixed',
          zIndex: 10,
          backgroundColor: '#fff',
          marginTop: 0,
          height: '64px',
          width: RESPONSIVE_WIDTH
        }}
      >
        <IconButton
          sx={{ mr: '20px', color: 'text.primary' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Filters</Typography>
      </Container>
      <Container sx={{ height: '50px' }} />
      {viewCustomersButtonDisabled && (
        <Alert
          severity="warning"
          style={{
            marginTop: '8px',
            marginLeft: '16px',
            marginRight: '16px'
          }}
        >
          <AlertTitle>No customers found</AlertTitle>
          Adjust the filters and try again.
        </Alert>
      )}
      {Object.keys(customerBookFilters)
        .filter((key) =>
          isAnniversary ? !!key : key !== CustomerBookFilterKeys.ANNIVERSARY
        )
        .filter((key) =>
          isLastMessageFilterEnabled?.active
            ? !!key
            : key !== CustomerBookFilterKeys.LAST_MESSAGE_TIME
        )
        .map((key) => {
          const item = customerBookFilters[key as CustomerBookFilterKeys]
          const isbrandsFilter = item.title === customerBookFilters.brands.title
          const isAnniversaryFilter =
            item.title === customerBookFilters.qualification.title &&
            isAnniversary
          const isRadioButton =
            item.title === customerBookFilters.nordstromNotes.title ||
            item.title === customerBookFilters.lastMessage.title ||
            item.title === customerBookFilters.cardmember.title

          const toggleOption = (filterOptionIndex: number) => {
            appDispatch(
              toggleFilterOption({
                filterId: key as CustomerBookFilterKeys,
                filterOptionIndex
              })
            )
          }

          return (
            <Box key={key}>
              <Accordion
                style={{ marginTop: 0, marginBottom: 0 }}
                sx={{
                  boxShadow: 'none',
                  '&:before': {
                    display: 'none'
                  }
                }}
                expanded={sectionSelected[key as CustomerBookFilterKeys]}
                onChange={() => {
                  if (!isAnniversaryFilter) {
                    setSectionSelected({
                      ...sectionSelected,
                      [key]: !sectionSelected[key as CustomerBookFilterKeys]
                    })
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={!isAnniversaryFilter && <ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    height: '48px'
                  }}
                >
                  <Typography variant="subtitle2">{item.title}</Typography>
                </AccordionSummary>
                <FilterOptions
                  options={item.options}
                  toggleOption={toggleOption}
                  isBrandsFilter={isbrandsFilter}
                  isAnniversaryFilter={isAnniversaryFilter}
                  isRadioButton={isRadioButton}
                  disabled={item.disabled}
                />
              </Accordion>
              <Divider
                style={{
                  background: isAnniversaryFilter
                    ? '#F9FAFA'
                    : 'rgba(31, 33, 34, 0.12)',
                  height: isAnniversaryFilter ? '16px' : '',
                  border: isAnniversaryFilter ? 'none' : ''
                }}
              />
            </Box>
          )
        })}
      <Box sx={{ height: '100px' }} />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'fixed',
          zIndex: 10,
          bottom: 0,
          backgroundColor: '#fff',
          pt: '18px',
          pb: '18px',
          width: RESPONSIVE_WIDTH,
          borderTop: '1px solid rgba(31, 33, 34, 0.12)'
        }}
      >
        <Button
          variant="text"
          onClick={clearAllAction}
          disabled={clearAllButtonDisabled}
        >
          Clear All
        </Button>
        <Button
          variant="contained"
          onClick={handleViewCustomersClick}
          disabled={viewCustomersButtonDisabled}
          data-testid="viewCustomersButton"
        >
          {viewButtonText}
        </Button>
      </Container>
    </Box>
  )
}

export default FiltersContent
