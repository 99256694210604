import { ReactElement } from 'react'
import { Button, Box, Grid, Typography, IconButton, Paper } from '@mui/material'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { UniqueIdentifier } from '@dnd-kit/core'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ItemImage from 'components/ItemImage'
import PriceBlock from 'components/PriceBlock'
import CurationItemNote from '../CurationItemNote'
import { PriceDetailsT } from 'types/Checkout'
import { EnticementDetailsT } from 'types/Product'

type PropsT = {
  allItemIds?: string[]
  brandName?: string
  color?: string
  enticements?: EnticementDetailsT[]
  id: UniqueIdentifier
  imageAltText?: string
  imageUrl?: string
  isDragging?: boolean
  isEdit: boolean
  itemId: string
  note: string
  onClickForPDP: (webStyleId: string) => void
  onRemoveItemClick: (itemIds: string[]) => void
  price?: PriceDetailsT
  productName?: string
  rmsSku: string
  shouldShowSize: boolean
  showItemError?: boolean
  size?: string
  sizeLabel?: string
  webStyleId?: string
}

const DEFAULT_SIZE_LABEL = 'Size'

const CurationItem = (props: PropsT): ReactElement => {
  const {
    allItemIds,
    brandName,
    color,
    enticements,
    imageAltText,
    imageUrl,
    isDragging = false,
    isEdit,
    itemId,
    note,
    onClickForPDP,
    onRemoveItemClick,
    price,
    productName,
    rmsSku,
    shouldShowSize,
    showItemError = false,
    size,
    sizeLabel = DEFAULT_SIZE_LABEL,
    webStyleId
  } = props

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  return (
    <Paper
      sx={{
        backgroundColor: 'white',
        boxShadow: 'none',
        opacity: isDragging ? 0.5 : 1,
        transition,
        transform: CSS.Transform.toString(transform)
      }}
      ref={setNodeRef}
    >
      <Box mb={3}>
        {/* outer grid container */}
        <Grid
          container
          wrap="nowrap"
          spacing={3}
          sx={{ height: ['auto', 220] }}
        >
          {/* first column in outer grid */}
          <Grid item>
            {/* item in first column */}
            <Box
              m={0.5}
              sx={{
                width: {
                  xs: 70,
                  sm: 80
                }
              }}
            >
              <Button
                aria-label="view product details"
                onClick={() => webStyleId && onClickForPDP(webStyleId)}
                sx={{ maxWidth: '74px', padding: 0 }}
              >
                <ItemImage imageAltText={imageAltText} imageUrl={imageUrl} />
              </Button>
            </Box>
          </Grid>
          {/* second column in outer grid */}
          <Grid item zeroMinWidth xs={8}>
            {/* items in second column */}
            <Box
              sx={{
                height: '100%'
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Box display="flex" justifyContent="space-between">
                  {brandName && (
                    <Typography fontWeight="bold" variant="body2" noWrap>
                      {brandName}
                    </Typography>
                  )}
                  {isEdit && showItemError && (
                    <ErrorOutlineIcon color="error" />
                  )}
                </Box>
                {productName && (
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical'
                    }}
                  >
                    {productName}
                  </Typography>
                )}
              </Box>
              <Box sx={{ mb: 2 }}>
                {price && (
                  <PriceBlock enticements={enticements} price={price} />
                )}
              </Box>
              {size && shouldShowSize && (
                <Typography variant="body2" noWrap>
                  {sizeLabel}: {size}
                </Typography>
              )}
              {color && (
                <Typography variant="body2" noWrap>
                  Color: {color}
                </Typography>
              )}
              <Button
                onClick={() =>
                  onRemoveItemClick(allItemIds ? allItemIds : [itemId])
                }
                sx={{ mt: 1 }}
              >
                Remove
              </Button>
            </Box>
          </Grid>
          {!isEdit && (
            <Grid item zeroMinWidth>
              <IconButton
                {...attributes}
                {...listeners}
                sx={{ touchAction: 'none' }}
              >
                <DragIndicatorIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <CurationItemNote rmsSku={rmsSku} note={note} isEdit={isEdit} />
      </Box>
    </Paper>
  )
}

export default CurationItem
