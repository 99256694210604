import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const DEFAULT_MAX_DRAFT_AGE_IN_DAYS = 90

const useMaxDraftsAgeInDays = (): number => {
  const { isMaxDraftAgeInDaysEnabled } = useFeatureFlags()
  const isMaxDraftAgeEnabled = isMaxDraftAgeInDaysEnabled?.active
  const convertedMaxDraftAgePayload = Number(
    isMaxDraftAgeInDaysEnabled?.payload
  )

  // https://stackoverflow.com/questions/30314447/how-do-you-test-for-nan-in-javascript
  const shouldUseFlagPayload = !Number.isNaN(convertedMaxDraftAgePayload)

  const maxDraftAgeInDays =
    isMaxDraftAgeEnabled && shouldUseFlagPayload
      ? convertedMaxDraftAgePayload
      : DEFAULT_MAX_DRAFT_AGE_IN_DAYS

  return maxDraftAgeInDays
}

export default useMaxDraftsAgeInDays
