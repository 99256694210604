import { ReactElement, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { curationSelector } from 'app/curationSlice'
import { useAppSelector } from 'app/hooks'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import BasePageAlert from 'components/BasePageAlert'
import GenerateTitleAndDescription from './components/GenerateTitleAndDescription'
import CurationDescription from '../CurationDescription'
import CurationTitle from '../CurationTitle'
import ReviewDescriptionContainer from '../ReviewDescriptionContainer'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

interface PropsT {
  bagDataCount?: number
  description: string
  descriptionError: boolean
  isEdit: boolean
  isGenerateTitleDescriptionError?: boolean
  isLoading?: boolean
  onGenerateTitleDescriptionClick?: () => void
  title: string
  titleError: string
}

const CurationTitleAndDescriptionEntry = (props: PropsT): ReactElement => {
  const {
    bagDataCount,
    description,
    descriptionError,
    isEdit,
    isGenerateTitleDescriptionError,
    isLoading,
    onGenerateTitleDescriptionClick,
    title,
    titleError
  } = props

  const curationDetails = useAppSelector(curationSelector)

  const { isTitleDescriptionGenerationEnabled } = useFeatureFlags()

  const [
    shouldDisplayNoItemsForTitleAndDescriptionError,
    setShouldDisplayNoItemsForTitleAndDescriptionError
  ] = useState(false)

  const shouldShowGenerateTitleDescriptionButton =
    !isEdit && isTitleDescriptionGenerationEnabled?.active

  const onGenerateTitleDescriptionButtonClick = () => {
    if (!(bagDataCount && bagDataCount > 0)) {
      setShouldDisplayNoItemsForTitleAndDescriptionError(true)
      return
    }

    if (shouldDisplayNoItemsForTitleAndDescriptionError) {
      setShouldDisplayNoItemsForTitleAndDescriptionError(false)
    }

    onGenerateTitleDescriptionClick && onGenerateTitleDescriptionClick()

    generateNewRelicLogs('generateTitleAndDescriptionClick', {
      curationId: curationDetails.id,
      type: curationDetails.type,
      preferredEmployeeId: curationDetails.preferredEmployee,
      numberOfItemsInBag: bagDataCount,
      hasTitle: !!title,
      hasDescription: !!description
    })
  }

  return (
    <ReviewDescriptionContainer>
      <Typography
        gutterBottom
        variant="h6"
        fontWeight="bold"
        mb={shouldDisplayNoItemsForTitleAndDescriptionError ? 1 : 4}
      >
        Title & description
      </Typography>
      {shouldDisplayNoItemsForTitleAndDescriptionError && (
        <BasePageAlert
          errorMessage={'Add items to generate title and description.'}
          isDismissable={true}
        />
      )}
      <CurationTitle errorText={titleError} title={title} isEdit={isEdit} />
      <Grid item mt={4}>
        <CurationDescription
          isError={descriptionError}
          description={description}
          isEdit={isEdit}
        />
        {shouldShowGenerateTitleDescriptionButton && (
          <GenerateTitleAndDescription
            isGenerateTitleDescriptionError={!!isGenerateTitleDescriptionError}
            isLoading={!!isLoading}
            onClick={onGenerateTitleDescriptionButtonClick}
          />
        )}
      </Grid>
    </ReviewDescriptionContainer>
  )
}

export default CurationTitleAndDescriptionEntry
