import { Dispatch, useReducer } from 'react'
import { produce } from 'immer'

import { HoldsStartNewHoldFormParamsT } from 'pages/Holds/types'

export type HoldsStartNewActionT = {
  payload:
    | {
        name: 'customerInfo'
        value: HoldsStartNewHoldFormParamsT['customerInfo']
      }
    | {
        name: 'holdType'
        value: HoldsStartNewHoldFormParamsT['holdType']
      }
    | {
        name: 'itemsOnHold'
        value: HoldsStartNewHoldFormParamsT['itemsOnHold']
      }
}

const reducerFormParams = produce(
  (draft: HoldsStartNewHoldFormParamsT, { payload }: HoldsStartNewActionT) => {
    switch (payload.name) {
      case 'customerInfo':
        draft[payload.name] = payload.value
        break
      case 'holdType':
        draft[payload.name] = payload.value
        break
      case 'itemsOnHold':
        draft[payload.name] = payload.value
        break
      default:
        throw new Error('undefined type')
    }
  }
)

export const FORM_PARAMS_INITIAL_VALUE: HoldsStartNewHoldFormParamsT = {
  customerInfo: {
    customerId: '', // FYI: exist only in search by mobile number
    customerName: '',
    customerMobileNumber: '' // FYI: always formatted to (xxx) xxx - xxxx or empty
  },
  holdType: '',
  itemsOnHold: []
}

const useHoldsReducerStartNewHold = (): [
  Readonly<HoldsStartNewHoldFormParamsT>,
  Dispatch<HoldsStartNewActionT>
] => useReducer(reducerFormParams, FORM_PARAMS_INITIAL_VALUE)

export default useHoldsReducerStartNewHold
