import { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt1'
import { useHistory } from 'react-router-dom'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { EmployeeT } from 'types/Employee'
import Skeleton from 'components/Skeleton'
import OpenSelectBoardTypeButton from 'pages/CurationHome/components/OpenSelectStyleBoardTypeButton/OpenSelectStyleBoardTypeButton'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import CreateStyleBoardDialog from 'pages/CurationHome/components/CreateStyleBoardDialog'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

interface PropsT {
  employee: { employee: EmployeeT } | undefined
  isLoading: boolean
  isFetching: boolean
  showInviteButton: boolean
}

const WelcomeHome = (props: PropsT): JSX.Element => {
  const { employee, isLoading, isFetching, showInviteButton } = props
  const [isCreateStyleBoardModalOpen, setIsCreateStyleBoardModalOpen] =
    useState<boolean>(false)
  const history = useHistory()
  const isMobileDevice = checkBrowserClient.isMobile()

  const inviteClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('inviteACustomerClick', attributes)

    history.push({ pathname: '/customers/invite' })
  }

  const preferredName = employee?.employee?.preferredName || ''
  const welcomeMessage = preferredName
    ? `Welcome, ${preferredName} 👋`
    : 'Welcome 👋'

  return (
    <Box px={2} mt={3}>
      {isLoading && isFetching && (
        <Box display={'grid'} gap={3} width={'100%'}>
          <Skeleton.Text
            textVariant={'h5'}
            width={isMobileDevice ? '60%' : '210px'}
          />
          <Skeleton.Text
            textVariant={'h6'}
            width={isMobileDevice ? '25%' : '100px'}
          />
          <Skeleton.Text
            textVariant={'h4'}
            width={isMobileDevice ? '55%' : '190px'}
          />
        </Box>
      )}
      {!isLoading && (
        <>
          <Typography
            variant="h6"
            sx={{
              display: 'inline-block',
              wordBreak: 'break-word'
            }}
          >
            {welcomeMessage}
          </Typography>
          <Typography variant="subtitle2" mt={2}>
            Get started
          </Typography>
          <Box
            mt={2}
            display={!showInviteButton && isMobileDevice ? 'block' : 'flex'}
            gap={2}
          >
            <Box>
              <CreateStyleBoardDialog
                open={isCreateStyleBoardModalOpen}
                onClose={() => {
                  setIsCreateStyleBoardModalOpen(false)
                }}
                sourcePage="Homepage"
              />
              <OpenSelectBoardTypeButton
                buttonVariant="outlined"
                openModal={() => setIsCreateStyleBoardModalOpen(true)}
                startIcon={
                  <DashboardCustomizeIcon fontSize="small" color="primary" />
                }
                isSizeSmall={isMobileDevice ? true : false}
              />
            </Box>
            {showInviteButton && (
              <Button
                onClick={inviteClick}
                variant="outlined"
                startIcon={<PersonAddAltIcon />}
                size={isMobileDevice ? 'small' : 'medium'}
              >
                Invite Customer
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default WelcomeHome
