import React, { ReactElement, ReactNode } from 'react'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { toLabel } from 'utils/toLabel'

type BaseDialogProps = Omit<
  React.ComponentProps<typeof Dialog>,
  'aria-modal' | 'aria-labelledby'
>

interface ListDialogAction {
  action: React.MouseEventHandler<HTMLButtonElement>
  text: string
  subtext?: ReactElement
}

interface PropsT extends Omit<BaseDialogProps, 'aria-describedby'> {
  options: ListDialogAction[]
  contentText?: string
  isAnniversaryList?: boolean
  isLoading?: boolean
  open: boolean
  title: string
  toggleButtons?: ReactNode
  shouldDecreaseListPadding?: boolean
}

const AnniversarySaleStyledLink = ({
  linkModifier
}: {
  linkModifier: string
}): JSX.Element => (
  <Typography variant="body2" data-testid={`anniversary-link-${linkModifier}`}>
    <span style={{ backgroundColor: '#fbe446', fontWeight: 'bold' }}>
      Anniversary Sale
    </span>{' '}
    <span>- {linkModifier}</span>
  </Typography>
)

const DefaultLink = ({ linkText }: { linkText: string }) => {
  return <Typography variant="body2">{linkText}</Typography>
}

const ListDialog = (props: PropsT): ReactElement => {
  const {
    options,
    contentText,
    isAnniversaryList,
    isLoading,
    open,
    shouldDecreaseListPadding,
    title,
    toggleButtons,
    ...baseDialogProps
  } = props
  return (
    <Dialog
      {...baseDialogProps}
      aria-describedby={`dialog_desc_${toLabel(title)}`}
      aria-modal={true}
      open={open}
      role="dialog"
    >
      <Paper sx={{ width: [311, 444] }}>
        <DialogTitle
          sx={{
            fontWeight: '450 bold',
            fontSize: '23'
          }}
        >
          <strong>{title}</strong>
        </DialogTitle>
        {toggleButtons && <Box ml={3}>{toggleButtons}</Box>}
        {contentText && (
          <DialogContent>
            <DialogContentText color="text.primary">
              {contentText}
            </DialogContentText>
          </DialogContent>
        )}
        <List sx={{ mb: '50px', px: [0, 1] }}>
          {isLoading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {options.map((option, index) => (
            <React.Fragment key={index}>
              <ListItem sx={shouldDecreaseListPadding ? { p: 1 } : {}}>
                <ListItemButton
                  component={Button}
                  onClick={option.action}
                  sx={{
                    fontSize: '18',
                    fontWeight: '390',
                    color: '#1F2122'
                  }}
                >
                  <ListItemText
                    primary={
                      isAnniversaryList ? (
                        <AnniversarySaleStyledLink linkModifier={option.text} />
                      ) : (
                        <DefaultLink linkText={option.text} />
                      )
                    }
                    secondary={option.subtext && option.subtext}
                  />
                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <NavigateNextIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Dialog>
  )
}

export default ListDialog
