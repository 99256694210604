import { DeliveryStatus } from '@twilio/conversations'
import {
  ErrorMessages,
  HelperErrorMessages,
  messageStatusView
} from 'types/Twilio'

export const MESSAGE_STATUS: Record<DeliveryStatus, DeliveryStatus> = {
  sent: 'sent',
  delivered: 'delivered',
  failed: 'failed',
  read: 'read',
  undelivered: 'undelivered',
  queued: 'queued'
}

export const ERROR_MESSAGES_BY_ERROR_CODE: Record<
  string,
  { errorMessage: string; helperMessage: string }
> = {
  '21614': {
    errorMessage: ErrorMessages.PHONE_NUMBER_MAY_BE_A_LANDLINE,
    helperMessage: HelperErrorMessages.KNOWN_MOBILE_NUMBER
  },
  '30003': {
    errorMessage: ErrorMessages.PHONE_NUMBER_MAY_BE_A_LANDLINE,
    helperMessage: HelperErrorMessages.KNOWN_MOBILE_NUMBER
  },
  '30005': {
    errorMessage: ErrorMessages.PHONE_NUMBER_MAY_BE_A_LANDLINE,
    helperMessage: HelperErrorMessages.KNOWN_MOBILE_NUMBER
  },
  '30006': {
    errorMessage: ErrorMessages.PHONE_NUMBER_IS_A_LANDLINE,
    helperMessage: HelperErrorMessages.KNOWN_MOBILE_NUMBER
  },
  '30007': {
    errorMessage: ErrorMessages.BLOCKED_AS_SPAM,
    helperMessage: HelperErrorMessages.THIS_THIS_IS_A_MISTAKE
  },
  '30011': {
    errorMessage: ErrorMessages.CONTENT_NOT_SUPPORTED_BY_RECEIVING_NUMBER,
    helperMessage: HelperErrorMessages.THIS_THIS_IS_A_MISTAKE
  }
}

export const messageStatusContent: Record<messageStatusView, string> = {
  [messageStatusView.MESSAGE_THREAD]: 'Not delivered - tap for more info',
  [messageStatusView.CONVERSATION_LIST]: 'Message not delivered'
}
