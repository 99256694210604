import { ReactElement } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'

const LoadingSpinner = ({
  hideText,
  sx = {}
}: {
  hideText?: boolean
  sx?: Record<string, unknown>
}): ReactElement => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ alignItems: 'center', ...sx }}
      data-testid="loading-spinner"
    >
      <Box my={3}>
        <CircularProgress />
      </Box>
      {!hideText ? (
        <Typography>Loading...</Typography>
      ) : (
        <Typography sx={visuallyHidden}>Loading</Typography>
      )}
    </Box>
  )
}

export default LoadingSpinner
