import { ReactElement, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import BaseSnackbar from 'components/BaseSnackbar'
import { copyToClipboard } from 'utils/copyToClipboard'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isUSSeller } from 'utils/userPermissions'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

export type PropsT = {
  stylistHandle: string | undefined
  personalizedLink: string | undefined | null
}

type StylistLinksT = {
  [key: string]: {
    title: string
    calculateLink: (handle: string | undefined | null) => string
  }
}

const StylistLinks = (props: PropsT): ReactElement => {
  const { stylistHandle, personalizedLink } = props
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const { isPublicBoardsLinkEnabled, isPersonalizedBookingLinkEnabled } =
    useFeatureFlags()

  const stylistLinks: StylistLinksT = {}
  if (isPersonalizedBookingLinkEnabled?.active && personalizedLink) {
    const personalizedLink: StylistLinksT = {
      personalizedLink: {
        title: 'Personalized booking link',
        calculateLink: (link) => `${link}`
      }
    }
    Object.assign(stylistLinks, personalizedLink)
  }

  if (stylistHandle) {
    const profileLink: StylistLinksT = {
      profile: {
        title: 'Nordstrom.com profile link',
        calculateLink: (handle) => `/stylists/profile/${handle}`
      }
    }
    Object.assign(stylistLinks, profileLink)

    if (!isUSSeller()) {
      const requestLink: StylistLinksT = {
        request: {
          title: 'Personal request form link',
          calculateLink: (handle) => `/curation/request?handle=${handle}`
        }
      }
      Object.assign(stylistLinks, requestLink)
    }

    if (isPublicBoardsLinkEnabled?.active) {
      const publicBoards: StylistLinksT = {
        publicBoards: {
          title: 'Public boards page link',
          calculateLink: (handle) => `/stylists/${handle}/stylelinks`
        }
      }
      Object.assign(stylistLinks, publicBoards)
    }
  }

  const handleLinkCopied = (linkType: string) => {
    const stylistLink = stylistLinks[linkType]
    const link =
      linkType == 'personalizedLink'
        ? stylistLink.calculateLink(personalizedLink)
        : `${process.env.REACT_APP_NORDSTROM_URL}${stylistLink.calculateLink(
            stylistHandle
          )}`
    copyToClipboard(link)
    setCopyToClipboardSuccess(true)
    generateNewRelicLogs(`stylistProfileLinkClicked-${linkType}`)
  }

  return (
    <Box
      sx={{
        bgcolor: '#F9F9F9',
        padding: '16px'
      }}
    >
      <Typography gutterBottom component="h4" fontWeight="bold" variant="body1">
        Your links
      </Typography>
      {Object.keys(stylistLinks).map((linkType, key) => (
        <Box
          display="flex"
          key={key}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="body2">
            {stylistLinks[linkType].title}
          </Typography>
          <Button
            data-testid={linkType + '-button'}
            onClick={() => handleLinkCopied(linkType)}
          >
            {'Copy'}
          </Button>
        </Box>
      ))}
      {!stylistHandle && (
        <Typography fontSize="14px" variant="body2">
          Create your profile to access more custom links
        </Typography>
      )}
      {isCopyToClipboardSuccess && (
        <BaseSnackbar
          message="Link copied"
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
    </Box>
  )
}

export default StylistLinks
