import { GetHoldsQueryDataParamsT, HoldT } from 'types/Holds'

const isHoldStatusIncludesInRequestedStatuses = ({
  requestParams,
  hold
}: {
  requestParams: GetHoldsQueryDataParamsT
  hold: HoldT
}): boolean => requestParams.status.split(',').includes(hold.status)

export default isHoldStatusIncludesInRequestedStatuses
