import { ReactElement } from 'react'
import { ListItem, ListItemText } from '@mui/material'
import { getTime } from 'utils/date-utils'
import { Notification, Status as NotificationStatus } from 'types/Notification'
import { backgroundColors } from '../../constants'

type PropsT = {
  notification: Notification
  notificationAction: (notification: Notification) => void
  NotificationText: () => JSX.Element
}

const NotificationItem = (props: PropsT): ReactElement => {
  const { notification, notificationAction, NotificationText } = props
  return (
    <>
      <ListItem
        key={notification.id}
        onClick={() => notificationAction(notification)}
        sx={{
          backgroundColor:
            notification.status === NotificationStatus.UNREAD
              ? backgroundColors.unread
              : backgroundColors.white,
          cursor: 'pointer',
          '&:hover': {
            background:
              notification.status === NotificationStatus.UNREAD
                ? backgroundColors.unreadHover
                : backgroundColors.hover
          }
        }}
        divider
        aria-label="notificationListItem"
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ListItemText
            primary={<NotificationText />}
            secondary={getTime(notification.createdAt, { today: 'h:mm a' })}
            primaryTypographyProps={{ lineHeight: '20px' }}
            secondaryTypographyProps={{ fontSize: '14px', lineHeight: '20px' }}
            sx={{ marginY: 0, paddingY: '4px' }}
          />
        </div>
      </ListItem>
    </>
  )
}

export default NotificationItem
