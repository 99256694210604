import { ReactElement, useEffect, useState, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useNavigation } from 'contexts/NavigationContext'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import SnapshotDrawer from '../SnapshotDrawer'
import EditCurationItemsList from './components/EditCurationItemsList'
import ItemsListHeader from '../CurationReview/components/ItemsListHeader'
import CurationTitleAndDescriptionEntry from '../CurationReview/components/CurationTitleAndDescription'
import {
  usePersistedEditReducer,
  CurationEditDispatchContext
} from './CurationEditReducer'
import CancelButton from '../CurationReview/components/CancelButton'
import BaseDialog from 'components/BaseDialog'
import {
  RESET_EDITS,
  SET_INITIAL_DESCRIPTION,
  SET_INITIAL_NOTES,
  SET_INITIAL_TITLE,
  IFRAME_PATH,
  SET_IS_FASHION_MAP_SEARCH_DRAWER_OPEN,
  SET_IS_QUICK_LINKS_DIALOG_OPEN,
  SET_IS_RECENT_ITEMS_DRAWER_OPEN,
  SET_DUPLICATE_SKUS,
  SET_IS_SCANNING_DRAWER_OPEN,
  UPDATE_FASHION_MAP_VIEWS,
  UPDATE_SCANNER_VIEWS,
  UPDATE_RECENT_ITEM_VIEWS,
  SET_IN_EDIT_CURATION,
  SET_EDIT_SHOPPING_SESSION_ID,
  SET_EDIT_SHOPPING_TOKEN
} from './constants'
import LoadingButton from '@mui/lab/LoadingButton'
import { MIN_NUMBER_OF_ITEMS } from '../CurationReview/constants'
import { CURATION_TYPE, CurationItemT, CurationT } from 'types/Curation'
import { EditItemsProductT } from 'types/Product'
import {
  SNAPSHOT_BASE_HASH,
  SNAPSHOT_DRAWER_WIDTH,
  emDashCharacter
} from '../constants'
import { scrollToTop } from 'utils/scrollIntoView'
import {
  ValidationDuplicateItemError,
  ValidationResultT,
  ValidationStatus
} from 'types/FormValidation'
import FashionMapSearchDrawer from 'pages/FashionMapSearchDrawer'
import ValidationAlert from 'pages/CurationReview/components/ValidationAlert/ValidationAlert'
import CurationMenuBar from 'pages/CurationReview/components/CurationMenuBar'
import SyncErrorAlert from 'pages/CurationReview/components/SyncErrorAlert'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import { validateEditForm } from 'utils/curationValidation'
import BasePageAlert from 'components/BasePageAlert'
import CurationItemsList from 'components/CurationItemsList'
import FashionMapSearchButton from 'components/FashionMapSearchButton'
import NordstromIFrame from 'components/NordstromIFrame'
import ReviewButtonGrid from 'components/ReviewButtonGrid'
import QuickLinksButton from 'components/QuickLinksButton'
import RecentItemsButton from 'components/RecentItemsButton'
import ShopButton from 'components/ShopButton'
import SyncButton from 'components/SyncButton'
import QuickLinksDialog from 'components/QuickLinksDialog'
import RecentItemsDrawer from 'pages/RecentItemsDrawer'
import determineShopButtonText from 'utils/determineShopButtonText'
import isSellerInitiatedPrivateBoard from 'utils/isSellerInitiatedPrivateBoard'
import { copyToClipboard } from 'utils/copyToClipboard'
import { handleBeforeUnload } from 'utils/handleBeforeUnload'
import {
  generateNewRelicLogs,
  generateProductDiscoveryAttributes
} from 'utils/newRelicCustomLogHelper'
import {
  determineProductSource,
  ProductDiscovery,
  ProductSourceType
} from 'utils/determineProductSourceHelper'
import {
  useHandleTokenRefreshBeforeExpiration,
  useIframeConfig
} from 'app/hooks'
import useCurationFashionMapFeatures from 'utils/useCurationFashionMapFeatures'
import BaseSnackbar from 'components/BaseSnackbar'
import ScanButton from 'components/ScanButton'
import ScannerDrawer from 'pages/ScannerDrawer'
import { useScanningFeatures } from 'utils/useScanningFeatures'

type RemoveItems = (arg: {
  itemIds: string[]
  shoppingSessionId: string
  token: string
}) => void

interface PropsT {
  editCurationData: {
    curation: CurationT
    products: EditItemsProductT[]
    editRequestId: number | null
  }
  checkoutPageResult: {
    data?: CurationItemT[]
    isLoading: boolean
    isFetching: boolean
    isError: boolean
    endpointName?: string
    error?: unknown
    originalArgs?: { shopperId: string; token: string }
    refetch: () => void
  }
  shoppingSession: { token: string; id: string }
  fetchTokenMutation: {
    fetchToken: (arg: { curationId: number; shoppingSessionId: string }) => void
    refreshToken: (arg: {
      editShoppingSessionId: string
      curationId: number
    }) => void
    fetchTokenStatus: { isLoading: boolean }
  }
  removeFromBagMutation: {
    removeItems: RemoveItems
    removeFromBagMutationStatus: { isLoading: boolean }
  }
  completeEditMutation: {
    completeEdit: (arg: {
      curationId: number
      deactivatedCurationProductIds: number[]
      updatedProducts: Record<string, Record<string, string>>
      newProducts: { rmsSku: string; note: string }[]
      updatedTitle: string
      updatedDescription: string
      editRequestId: number | null
    }) => void
    completeEditMutationStatus: {
      isLoading: boolean
      isError: boolean
      error?: unknown
      originalArgs?: Record<string, unknown>
    }
  }
}

const CheckoutBagDataItemsList = ({
  checkoutBagData,
  duplicateSkus,
  shouldShowSizes,
  notes,
  removeItems,
  handleClickForPdp,
  shopperId,
  checkoutToken
}: {
  checkoutBagData: CurationItemT[]
  duplicateSkus: string[]
  shouldShowSizes: boolean
  notes: Record<string, string>
  removeItems: RemoveItems
  handleClickForPdp: (webStyleId: string) => void
  shopperId: string
  checkoutToken: string
}) => {
  const isEmpty = checkoutBagData.length === 0
  return (
    <>
      <Typography component={'h2'} variant={'body1'} fontWeight={'bold'} my={3}>
        {isEmpty && 'Add items'}
        {!isEmpty && 'Adding items'}
      </Typography>
      {isEmpty && (
        <Typography mb={3}>
          No additional items added yet. If you add more items to this board,
          they will appear here.
        </Typography>
      )}
      {!isEmpty && (
        <CurationItemsList
          curationItems={checkoutBagData}
          duplicateSkus={duplicateSkus}
          shouldShowSizes={shouldShowSizes}
          itemNotesObject={notes}
          handleClickForPdp={handleClickForPdp}
          handleRemoveClick={(webStyleIds) =>
            removeItems({
              itemIds: webStyleIds,
              shoppingSessionId: shopperId,
              token: checkoutToken
            })
          }
          isEdit={true}
        />
      )}
    </>
  )
}

const CurationEdit = (props: PropsT): ReactElement => {
  const {
    editCurationData,
    checkoutPageResult: {
      data: checkoutBagData = [],
      isLoading: isGetCheckoutDataLoading,
      isFetching: isGetCheckoutDataFetching,
      isError: isGetCheckoutDataError,
      endpointName: checkoutDataEndpointName,
      error: checkoutDataError,
      originalArgs: checkoutDataOriginalArgs,
      refetch: syncItems
    },
    shoppingSession: { id: shopperId, token: checkoutToken },
    fetchTokenMutation: {
      fetchToken,
      refreshToken,
      fetchTokenStatus: { isLoading: isFetchTokenLoading }
    },
    removeFromBagMutation: { removeItems },
    completeEditMutation: {
      completeEdit,
      completeEditMutationStatus: {
        isLoading: isCompleteEditLoading,
        isError: isCompleteEditError,
        error: completeEditError,
        originalArgs: completeEditOriginalArgs
      }
    }
  } = props
  const history = useHistory()
  const shouldShowFashionMap = useCurationFashionMapFeatures()
  const shouldShowScanButton = useScanningFeatures()
  const {
    isItemLimitEnabled,
    isEditRequestEnabledPersonalBook,
    isAnniversaryUSPreview,
    isAnniversaryUSPublic,
    isAnniversaryUSEarlyAccess4,
    isAnniversaryUSEarlyAccess3,
    isAnniversaryUSEarlyAccess2,
    isAnniversaryUSEarlyAccess1,
    isAnniversaryUSEarlyAccess
  } = useFeatureFlags()
  const { setDisplayAppBar } = useNavigation()
  const theme = useTheme()
  const isLargeScreenSize = useMediaQuery(theme.breakpoints.up('lg'))
  const [validationResult, setValidationResult] =
    useState<ValidationResultT | null>(null)
  const [containerMargin, setContainerMargin] = useState('')
  const { hash } = useLocation()
  const isSnapshotOpen = hash.startsWith(`#${SNAPSHOT_BASE_HASH}`)
  const [errorByEmptyEdit, setErrorByEmptyEdit] = useState(false)

  useEffect(() => {
    setDisplayAppBar(false)
    return () => {
      setDisplayAppBar(true)
    }
  }, [setDisplayAppBar])

  const [state, dispatch] = usePersistedEditReducer()
  useEffect(() => {
    dispatch({
      type: SET_IN_EDIT_CURATION,
      payload: {
        id: editCurationData.curation.id
      }
    })
  }, [editCurationData, dispatch])

  useEffect(() => {
    dispatch({
      type: SET_EDIT_SHOPPING_SESSION_ID,
      payload: {
        editShoppingSessionId: shopperId
      }
    })
  }, [shopperId, dispatch])

  useEffect(() => {
    dispatch({
      type: SET_EDIT_SHOPPING_TOKEN,
      payload: {
        editShoppingToken: checkoutToken
      }
    })
  }, [checkoutToken, dispatch])

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const {
    initialIframePathAndTitle,
    setIframePathAndTitle,
    iframePathAndTitle
  } = useIframeConfig()

  const handleOpenIFrame = () => {
    if (state.isQuickLinksDialogOpen) {
      dispatch({
        type: SET_IS_QUICK_LINKS_DIALOG_OPEN,
        payload: false
      })
    }
    if (isSnapshotOpen && isLargeScreenSize) {
      history.push({
        pathname: `/styleboards/${editCurationData.curation.id}/edit/build`,
        hash: location.hash
      })
    } else {
      history.push({
        pathname: `/styleboards/${editCurationData.curation.id}/edit/build`
      })
    }
  }

  const handleCloseIframe = useCallback(() => {
    if (isSnapshotOpen) {
      history.push({
        pathname: `/styleboards/${editCurationData.curation.id}/edit`,
        hash: location.hash
      })
    } else {
      history.push(`/styleboards/${editCurationData.curation.id}/edit`)
    }

    setIframePathAndTitle(initialIframePathAndTitle)
  }, [
    history,
    setIframePathAndTitle,
    initialIframePathAndTitle,
    editCurationData,
    isSnapshotOpen
  ])

  const handleTokenRefresh = useCallback(() => {
    refreshToken({
      editShoppingSessionId: shopperId,
      curationId: editCurationData.curation.id
    })
  }, [refreshToken, shopperId, editCurationData.curation.id])

  const [isTokenExpiredSnackbarOpen, setIsTokenExpiredSnackbarOpen] =
    useState(false)

  const handleCloseIframeAndNotify = useCallback(() => {
    handleCloseIframe()
    setIsTokenExpiredSnackbarOpen(true)
  }, [handleCloseIframe])

  useHandleTokenRefreshBeforeExpiration({
    isFetchTokenLoading,
    checkoutToken,
    isIframeOpen: history.location.pathname.includes(IFRAME_PATH),
    handleTokenRefresh,
    handleCloseIframeAndNotify
  })

  const updateUrl = (hash: string | undefined) => {
    const hasEditRequestParam = history.location.search === `?forEditRequest`
    const updatedUrl = hasEditRequestParam
      ? { search: `?forEditRequest`, hash: hash }
      : { hash: hash }

    history.replace(updatedUrl)
  }

  const onSnapshotOpen = () => {
    generateNewRelicLogs('snapshotOpenFromEdit', newRelicAttributes)
    updateUrl(SNAPSHOT_BASE_HASH)
  }

  const onSnapshotClose = () => {
    generateNewRelicLogs('snapshotCloseFromEdit', newRelicAttributes)
    updateUrl(undefined)
  }

  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  useEffect(() => {
    if (editCurationData) {
      const { curation } = editCurationData
      const curationProducts = curation.CurationProducts
      const noteObject = {} as { [key: string]: { note: string } }
      curationProducts &&
        curationProducts.forEach((product) => {
          noteObject[String(product.id)] = { note: product.note || '' }
        })
      dispatch({
        type: SET_INITIAL_NOTES,
        payload: noteObject
      })
      dispatch({
        type: SET_INITIAL_TITLE,
        payload: curation.title
      })
      dispatch({
        type: SET_INITIAL_DESCRIPTION,
        payload: curation.description || ''
      })
    }
    if (isCompleteEditError) {
      scrollToTop()
    }
  }, [dispatch, editCurationData, isCompleteEditError])

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [])

  useEffect(() => {
    if (isSnapshotOpen) {
      setContainerMargin(`${SNAPSHOT_DRAWER_WIDTH}`)
    }
    if (!isSnapshotOpen) {
      setContainerMargin('')
    }
  }, [isSnapshotOpen])

  const {
    deactivatedCurationProductIds,
    deactivatedCurationProductRmsSkus,
    duplicateSkus,
    editedDescription,
    editedNotes,
    editedTitle,
    notes,
    title,
    titleError,
    description,
    descriptionError,
    checkoutBagDataNotes,
    editedItemCount,
    productDiscovery
  } = state

  const handleCancelEdits = () => {
    generateNewRelicLogs('editsCancelled', newRelicAttributes)
    dispatch({
      type: RESET_EDITS
    })
    setIsCancelDialogOpen(false)
    history.push('/')
  }

  const handleExitCancelEdits = () => {
    generateNewRelicLogs('exitCancelEditsDialogClick', newRelicAttributes)
    setIsCancelDialogOpen(false)
  }

  const {
    curation: publishedCuration,
    products: publishedCurationProducts,
    editRequestId
  } = editCurationData

  const isSellerInitiatedCuration =
    publishedCuration.type === CURATION_TYPE.SELLER_INITIATED
  const currentNumberOfItems =
    publishedCurationProducts?.length +
      checkoutBagData.length +
      editedItemCount || 0
  const overLimit =
    isItemLimitEnabled?.active &&
    currentNumberOfItems > Number(isItemLimitEnabled?.payload)
  const underLimit = currentNumberOfItems < MIN_NUMBER_OF_ITEMS

  const newRelicAttributes = useMemo(() => {
    return {
      curationId: publishedCuration.id,
      ocpId: publishedCuration.ocpId,
      type: publishedCuration.type,
      preferredEmployeeId: publishedCuration.preferredEmployeeId,
      numberOfItemsInBag: currentNumberOfItems
    }
  }, [publishedCuration, currentNumberOfItems])

  const editCompleted = () => {
    const newItemSkus = checkoutBagData.map((item) => item.rmsSku)
    const previousItemSkus = editCurationData.products
      .map((product) => product.rmsSku)
      .filter((sku) => !deactivatedCurationProductRmsSkus.includes(sku))

    const validationResult = validateEditForm(
      title,
      titleError,
      descriptionError,
      overLimit,
      underLimit,
      {
        new: newItemSkus,
        previous: previousItemSkus
      }
    )
    setValidationResult(validationResult)

    if (
      validationResult.itemError === ValidationDuplicateItemError.DUPLICATE_SKUS
    ) {
      const duplicateSkus = newItemSkus.filter((sku) =>
        previousItemSkus.includes(sku)
      )
      dispatch({
        type: SET_DUPLICATE_SKUS,
        payload: duplicateSkus
      })
    }

    if (validationResult.validationStatus === ValidationStatus.FAIL) {
      scrollToTop()
    }
    if (validationResult.validationStatus === ValidationStatus.PASS) {
      const buildProduct = (item: CurationItemT) => {
        const rmsSku = item.rmsSku
        const note = state.checkoutBagDataNotes[rmsSku]
        const webStyleId = item.webStyleId
        const source = determineProductSource(
          item,
          productDiscovery as ProductDiscovery
        )
        return {
          rmsSku,
          webStyleId,
          note,
          source
        }
      }

      const newProducts = checkoutBagData.map((item) => buildProduct(item))

      const newProductSources = newProducts.map(
        (product) => product.source
      ) as ProductSourceType[]

      const productDiscoveryAttributes =
        generateProductDiscoveryAttributes(newProductSources)

      const attributes = {
        curationId: publishedCuration.id,
        shoppingSessionId: shopperId,
        ocpId: editCurationData.curation.ocpId,
        curationType: editCurationData.curation.type,
        preferredEmployeeId: editCurationData.curation.preferredEmployeeId,
        numberOfItemsInBag: currentNumberOfItems,
        validationStatus: validationResult.validationStatus,
        webStyleIds: newProducts.map((product) => product.webStyleId),
        ...productDiscoveryAttributes,
        numberOfNewProducts: newProducts.length
      }
      generateNewRelicLogs('completeEdit', attributes)

      isSellerInitiatedPrivateBoard(publishedCuration) &&
        copyToClipboard(
          `${process.env.REACT_APP_NORDSTROM_URL}/curation/${publishedCuration.id}`
        )

      const curationWasEdited =
        deactivatedCurationProductIds.length ||
        Object.keys(editedNotes).length ||
        editedTitle ||
        editedDescription ||
        newProducts.length

      if (curationWasEdited) {
        completeEdit({
          curationId: publishedCuration.id,
          deactivatedCurationProductIds,
          updatedProducts: editedNotes,
          updatedTitle: editedTitle,
          updatedDescription: editedDescription,
          newProducts,
          editRequestId
        })
      } else {
        setErrorByEmptyEdit(true)
        scrollToTop()
      }
    }
  }

  const handleShopButtonClick = () => {
    generateNewRelicLogs('ncomIFrameOpenedFromEdit', newRelicAttributes)
    setIframePathAndTitle({ path: '', title: 'nordstrom.com' })
    handleOpenIFrame()
  }

  const handleFashionMapSearchButtonClick = () => {
    generateNewRelicLogs('fmsDrawerOpenedFromEdit', newRelicAttributes)
    dispatch({
      type: SET_IS_FASHION_MAP_SEARCH_DRAWER_OPEN,
      payload: true
    })
  }

  const handleClickForPdp = (webStyleId: string) => {
    setIframePathAndTitle({
      path: `s/${webStyleId}`,
      title: 'nordstrom.com product details'
    })
    handleOpenIFrame()
  }

  const handleOpenPDPFromScanner = (webStyleId: string) => {
    dispatch &&
      dispatch({
        type: UPDATE_SCANNER_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleOpenPDPFromRecentItems = (webStyleId: string) => {
    dispatch &&
      dispatch({
        type: UPDATE_RECENT_ITEM_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleOpenPDPFromFMS = (webStyleId: string) => {
    generateNewRelicLogs('viewFromFashionMapClick', newRelicAttributes)
    dispatch &&
      dispatch({
        type: UPDATE_FASHION_MAP_VIEWS,
        payload: { webStyleId }
      })
    handleClickForPdp(webStyleId)
  }

  const handleOpenRecentItems = () => {
    generateNewRelicLogs('openRecentItemsFromEditClick', newRelicAttributes)
    dispatch({
      type: SET_IS_RECENT_ITEMS_DRAWER_OPEN,
      payload: true
    })
  }

  const handleOpenScanDrawer = () => {
    generateNewRelicLogs('openScanDrawerFromEditClick', newRelicAttributes)
    dispatch({
      type: SET_IS_SCANNING_DRAWER_OPEN,
      payload: true
    })
  }

  const handleSyncButtonClick = () => {
    generateNewRelicLogs('syncButtonClickFromEdit', newRelicAttributes)
    syncItems()
  }

  const isFromEditRequestPage = history.location.search === `?forEditRequest`
  const isEditingForCustomerRequest =
    isEditRequestEnabledPersonalBook?.active &&
    !!editRequestId &&
    !isFromEditRequestPage

  return (
    <BoxWithGladlySpace sx={{ mr: containerMargin }}>
      <CurationEditDispatchContext.Provider value={dispatch}>
        <CurationMenuBar
          customerId={publishedCuration.ocpId}
          handleBackClick={
            history.location.pathname.includes(IFRAME_PATH)
              ? handleCloseIframe
              : () => setIsCancelDialogOpen(true)
          }
          isEditRequest={true}
          isOutsideQueueRequest={false}
          isPublicBoard={false}
          onViewDetails={onSnapshotOpen}
          shouldShowShoppingHeader={history.location.pathname.includes(
            IFRAME_PATH
          )}
        />

        <Divider />
        {history.location.pathname.includes(IFRAME_PATH) ? (
          <NordstromIFrame
            checkoutToken={checkoutToken}
            pathAndTitle={iframePathAndTitle}
          />
        ) : (
          <>
            <Container sx={{ mt: 2 }}>
              {validationResult &&
                validationResult.validationStatus === ValidationStatus.FAIL && (
                  <ValidationAlert
                    isEdit={true}
                    key={validationResult.validationId}
                    validationResult={validationResult}
                  />
                )}
              {isEditingForCustomerRequest && (
                <BasePageAlert
                  alertTitle="You are editing for a request"
                  errorMessage="There is an open edit request for this board. View details to see more info from the customer."
                  severity="info"
                />
              )}

              <ItemsListHeader
                currentNumberOfItems={currentNumberOfItems}
                isItemLimitEnabled={isItemLimitEnabled?.active}
                itemLimit={Number(isItemLimitEnabled?.payload)}
                overLimit={overLimit}
                underLimit={underLimit}
              />
              {isCompleteEditError && (
                <BasePageAlert
                  alertTitle={`Issue updating board ${emDashCharacter} try again`}
                  errorDetails={{
                    errorData: completeEditError,
                    originalArgs: completeEditOriginalArgs
                  }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                />
              )}
              {errorByEmptyEdit && (
                <BasePageAlert
                  errorMessage="Make an edit in order to send style board"
                  isDismissable
                  onDismissed={() => setErrorByEmptyEdit(false)}
                />
              )}
              {isGetCheckoutDataError && (
                <SyncErrorAlert
                  errorDetails={{
                    endpoint: checkoutDataEndpointName,
                    errorData: checkoutDataError,
                    originalArgs: checkoutDataOriginalArgs
                  }}
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  onDismissed={() => {
                    setCopyToClipboardSuccess(false)
                  }}
                />
              )}
              <ReviewButtonGrid
                fashionMapSearchButton={
                  <FashionMapSearchButton
                    onClick={handleFashionMapSearchButtonClick}
                  />
                }
                quickLinksButton={
                  <QuickLinksButton
                    onClick={() => {
                      const attributes = {
                        curationId: editCurationData.curation.id,
                        type: editCurationData.curation.type,
                        preferredEmployeeId:
                          editCurationData.curation.preferredEmployeeId,
                        ocpId: editCurationData.curation.ocpId
                      }
                      generateNewRelicLogs('quickLinksOpened', attributes)
                      dispatch({
                        type: SET_IS_QUICK_LINKS_DIALOG_OPEN,
                        payload: true
                      })
                    }}
                  />
                }
                recentItemsButton={
                  <RecentItemsButton onClick={handleOpenRecentItems} />
                }
                scanButton={<ScanButton onClick={handleOpenScanDrawer} />}
                shopButton={
                  <ShopButton
                    isLoading={isFetchTokenLoading}
                    onClick={handleShopButtonClick}
                    message={determineShopButtonText({
                      isShoppingTokenExpired: false,
                      shoppingToken: checkoutToken,
                      isEdit: true
                    })}
                  />
                }
                shouldShowLoadingButton={
                  !state.isQuickLinksDialogOpen &&
                  (isGetCheckoutDataFetching || isGetCheckoutDataLoading)
                }
                shouldShowScanButton={shouldShowScanButton}
                shouldShowSyncButton={true}
                syncButton={
                  <SyncButton onSyncButtonClick={handleSyncButtonClick} />
                }
                isSnapshotOpen={isSnapshotOpen}
              />
              <CheckoutBagDataItemsList
                checkoutBagData={checkoutBagData}
                duplicateSkus={duplicateSkus}
                shouldShowSizes={!!publishedCuration.ocpId}
                notes={checkoutBagDataNotes}
                removeItems={removeItems}
                handleClickForPdp={handleClickForPdp}
                shopperId={shopperId}
                checkoutToken={checkoutToken}
              />
              <Divider />
              <Typography
                component={'h2'}
                variant={'body1'}
                fontWeight={'bold'}
                my={3}
              >
                In board
              </Typography>
              <EditCurationItemsList
                shouldShowSizes={!!publishedCuration.ocpId}
                publishedCurationItems={publishedCurationProducts}
                publishedCurationProducts={publishedCuration.CurationProducts}
                deactivatedCurationProductIds={deactivatedCurationProductIds}
                notes={{ ...notes, ...editedNotes }}
                handleClickForPdp={handleClickForPdp}
              />
            </Container>
            <Divider />
            <CurationTitleAndDescriptionEntry
              description={editedDescription || description || ''}
              descriptionError={descriptionError}
              isEdit={true}
              title={editedTitle || title}
              titleError={titleError}
            />
            <Divider />
            <Container>
              <Grid
                container
                mb={3}
                sx={{
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  alignItems: { xs: 'center', sm: 'flex-end' },
                  flexDirection: { xs: 'column-reverse', sm: 'row' },
                  mt: { xs: 3, sm: 2 }
                }}
                columnSpacing={{ xs: 2 }}
              >
                <Grid sx={{ mt: { xs: 1 } }} item>
                  <CancelButton
                    handleOpenCancelDialog={setIsCancelDialogOpen}
                    isEditing={true}
                  />
                </Grid>
                <Grid item>
                  <LoadingButton
                    sx={{
                      textTransform: 'none',
                      minWidth: '20em'
                    }}
                    onPointerUp={editCompleted}
                    loading={isCompleteEditLoading}
                    loadingPosition="center"
                    variant="contained"
                  >
                    Finish Edits
                  </LoadingButton>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        <SnapshotDrawer
          handleClickForPdp={handleClickForPdp}
          id={publishedCuration.ocpId}
          onClose={onSnapshotClose}
          isSellerInitiated={isSellerInitiatedCuration}
          isSnapshotOpen={isSnapshotOpen}
          shopperId={shopperId}
          token={checkoutToken}
        />
        <QuickLinksDialog
          open={state.isQuickLinksDialogOpen}
          onQuickLinkClick={({ path, title }) => {
            generateNewRelicLogs('chosenQuickLink', {
              ...newRelicAttributes,
              quickLinkTitle: title
            })
            setIframePathAndTitle({ path, title })
            handleOpenIFrame()
            dispatch({
              type: SET_IS_QUICK_LINKS_DIALOG_OPEN,
              payload: false
            })
          }}
          isLoading={isFetchTokenLoading}
          onClose={() => {
            dispatch({
              type: SET_IS_QUICK_LINKS_DIALOG_OPEN,
              payload: false
            })
          }}
          shouldShowAnniversaryLinks={[
            isAnniversaryUSPreview,
            isAnniversaryUSPublic,
            isAnniversaryUSEarlyAccess4,
            isAnniversaryUSEarlyAccess3,
            isAnniversaryUSEarlyAccess2,
            isAnniversaryUSEarlyAccess1,
            isAnniversaryUSEarlyAccess
          ].some((flag) => flag?.active)}
        />

        <RecentItemsDrawer
          handleAddItem={function noOpBecauseMutationCausesRefetch() {
            return
          }}
          onClose={() =>
            dispatch({ type: SET_IS_RECENT_ITEMS_DRAWER_OPEN, payload: false })
          }
          handleClickForPdp={(webStyleId) => {
            dispatch({
              type: SET_IS_RECENT_ITEMS_DRAWER_OPEN,
              payload: false
            })
            handleOpenPDPFromRecentItems(webStyleId)
          }}
          shopperId={shopperId}
          token={checkoutToken}
          refetchToken={() => {
            fetchToken({
              curationId: publishedCuration.id,
              shoppingSessionId: shopperId
            })
          }}
          open={state.isRecentItemsDrawerOpen}
          isEdit={true}
        />

        {shouldShowFashionMap && (
          <FashionMapSearchDrawer
            handleClickForPdp={handleOpenPDPFromFMS}
            onClose={() =>
              dispatch({
                type: SET_IS_FASHION_MAP_SEARCH_DRAWER_OPEN,
                payload: false
              })
            }
            open={
              state.isFashionMapSearchDrawerOpen &&
              !location.pathname.includes(IFRAME_PATH)
            }
          />
        )}

        <ScannerDrawer
          open={state.isScanningDrawerOpen}
          onClose={() =>
            dispatch({
              type: SET_IS_SCANNING_DRAWER_OPEN,
              payload: false
            })
          }
          onClickForPdp={handleOpenPDPFromScanner}
          shoppingSessionId={shopperId}
          syncItems={syncItems}
          token={checkoutToken}
          isEdit={true}
          refetchToken={() => {
            fetchToken({
              curationId: publishedCuration.id,
              shoppingSessionId: shopperId
            })
          }}
        />

        {isCancelDialogOpen && (
          <BaseDialog
            title={'Are you sure you want to cancel all edits?'}
            contentText={'No updates will be applied to this board.'}
            confirm={{
              action: handleCancelEdits,
              text: 'Yes, Cancel'
            }}
            cancel={{
              action: handleExitCancelEdits,
              text: 'Nevermind'
            }}
            onClose={handleExitCancelEdits}
            open={isCancelDialogOpen}
          />
        )}
        {isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
        <BaseSnackbar
          open={isTokenExpiredSnackbarOpen}
          onClose={() => setIsTokenExpiredSnackbarOpen(false)}
          message={
            'Your nordstrom.com shopping session expired and we had to refresh it. You can continue shopping normally.'
          }
        />
      </CurationEditDispatchContext.Provider>
    </BoxWithGladlySpace>
  )
}

export default CurationEdit
