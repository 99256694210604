import { Box, Button, Collapse, Typography } from '@mui/material'
import { ReactElement, useEffect, useRef, useState } from 'react'

type PropsT = {
  customerNote?: string | null
  title?: string
}

const EMPTY_NOTE_DEFAULT = "Customer didn't leave any notes"
const COLLAPSED_SIZE = 80

const CustomerNotes = (props: PropsT): ReactElement => {
  const { customerNote, title } = props

  const [showingAll, setShowingAll] = useState(false)
  const [height, setHeight] = useState<number | undefined>(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHeight(ref?.current?.clientHeight)
  }, [])

  const handleClick = () => {
    setShowingAll((showingAll) => !showingAll)
  }

  const showMore = height && height > COLLAPSED_SIZE
  const buttonText = showingAll ? 'Show Less' : 'Show More'

  return (
    <>
      {title && (
        <Typography variant="body2" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
      )}

      {showMore ? (
        <Collapse in={showingAll} collapsedSize={COLLAPSED_SIZE}>
          <Typography variant="body2" ref={ref}>
            {customerNote}
          </Typography>
        </Collapse>
      ) : customerNote ? (
        <Typography variant="body2" ref={ref}>
          {customerNote}
        </Typography>
      ) : (
        <Typography color="text.secondary" variant="body2" ref={ref}>
          {EMPTY_NOTE_DEFAULT}
        </Typography>
      )}
      {showMore && (
        <Box display="flex" justifyContent="flex-start">
          <Button onClick={handleClick} color="info">
            {buttonText}
          </Button>
        </Box>
      )}
    </>
  )
}

export default CustomerNotes
