import { ReactElement, useCallback, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import {
  useAbandonCurationMutation,
  useGetCurationsQuery,
  useResumeCurationMutation,
  useSaveCurationAsDraftMutation
} from 'services/curationSvc'
import { useRetrieveShoppingTokenMutation } from 'services/employeeExperienceApi'
import CurationReview from '../CurationReviewContent'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import {
  AbandonCurationArgsT,
  CURATION_STATE,
  CURATION_TYPE,
  CurationT
} from 'types/Curation'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  deleteDraftNotes,
  loadDraftNotes,
  saveDraftNotes
} from 'utils/draftNotes'
import { LOCAL_STORAGE_KEY } from '../CurationReviewContent/CurationReviewReducer'
import { CurationInProgressDialog } from 'pages/CurationReview/components/CurationReviewContainer/CurationReviewContainer'
import {
  deleteDraftOrderedItems,
  loadDraftOrderedItems,
  saveDraftOrderedItems
} from 'utils/draftOrderedItems'
import {
  generateNewRelicLogs,
  generateSaveAsDraftNewRelicLogs
} from 'utils/newRelicCustomLogHelper'
import { curationSelector } from 'app/curationSlice'
import { useAppSelector } from 'app/hooks'
import {
  deleteDraftProductDiscovery,
  loadDraftProductDiscovery,
  saveDraftProductDiscovery
} from 'utils/draftProductDiscovery'

interface PropsT {
  resumeCurationId: string
}

const ResumeCurationWrapper = (props: PropsT): ReactElement => {
  const { resumeCurationId } = props
  const history = useHistory()
  const curationDetails = useAppSelector(curationSelector)
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()

  const {
    data: getCurationsData,
    endpointName: getCurationsEndpointName,
    error: getCurationsError,
    isError: isGetCurationsError,
    isFetching: isGetCurationsFetching,
    isLoading: isGetCurationsLoading,
    originalArgs: getCurationsOriginalArgs
  } = useGetCurationsQuery({
    state: CURATION_STATE.IN_PROGRESS,
    employeeId
  })

  const [inProgressCuration] = getCurationsData?.curations || []

  const [
    abandonCuration,
    {
      isError: isAbandonCurationError,
      endpointName: abandonEndpoint,
      error: abandonCurationError,
      originalArgs: abandonOriginalArgs
    }
  ] = useAbandonCurationMutation()

  const [
    fetchToken,
    {
      data: fetchTokenData,
      endpointName: fetchTokenEndpointName,
      error: fetchTokenError,
      isError: isFetchTokenError,
      originalArgs: fetchTokenOriginalArgs
    }
  ] = useRetrieveShoppingTokenMutation()

  const [
    resumeCuration,
    {
      endpointName: resumeEndpointName,
      error: resumeError,
      isError: isResumeError,
      isLoading: isResumeLoading,
      originalArgs: resumeOriginalArgs
    }
  ] = useResumeCurationMutation()

  const [
    saveCurationAsDraft,
    {
      endpointName: saveAsDraftEndpoint,
      error: saveCurationAsDraftError,
      isError: isSaveCurationAsDraftError,
      isLoading: isSaveCurationAsDraftLoading,
      originalArgs: saveCurationAsDraftOriginalArgs
    }
  ] = useSaveCurationAsDraftMutation()

  const handleAbandon = async (abandonCurationArgs: AbandonCurationArgsT) => {
    try {
      await abandonCuration(abandonCurationArgs).unwrap()
      const attributes = {
        curationId: abandonCurationArgs.curationId,
        numberOfItemsInBag: Object.keys(abandonCurationArgs.products).length,
        shoppingSessionId: curationDetails?.shoppingSessionId
      }
      generateNewRelicLogs('abandonCuration', attributes)
      handleResumeCuration()
    } catch (error) {
      return
    }
  }

  const handleCancel = async (abandonCurationArgs: AbandonCurationArgsT) => {
    try {
      await abandonCuration(abandonCurationArgs).unwrap()
      const { curationId } = abandonCurationArgs
      deleteDraftNotes(curationId)
      deleteDraftOrderedItems(curationId)
      deleteDraftProductDiscovery(curationId)
      history.push('/')
    } catch (error) {
      return
    }
  }

  const prefillLocalStorageWithResumedCuration = (curation: CurationT) => {
    const notes = loadDraftNotes(curation.id)
    const orderedItems = loadDraftOrderedItems(curation.id)
    const productDiscovery = loadDraftProductDiscovery(curation.id)
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        description: curation.description || '',
        notes: notes || {},
        orderedItems: orderedItems || [],
        title: curation.title || '',
        productDiscovery: productDiscovery || { rmsSkus: {}, webStyleIds: {} }
      })
    )
    deleteDraftNotes(curation.id)
    deleteDraftOrderedItems(curation.id)
    deleteDraftProductDiscovery(curation.id)
  }

  const handleResumeCuration = useCallback(async () => {
    try {
      const { curation } = await resumeCuration({
        curationId: resumeCurationId
      }).unwrap()
      prefillLocalStorageWithResumedCuration(curation)
      history.replace('/review')
    } catch (e) {
      return
    }
  }, [history, resumeCuration, resumeCurationId])

  const handleSaveCurationAsDraft = useCallback(
    async (curationId: number) => {
      try {
        const reviewLocalStorage =
          localStorage.getItem(LOCAL_STORAGE_KEY) || '{}'
        const { title, description, notes, orderedItems, productDiscovery } =
          JSON.parse(reviewLocalStorage)
        await saveCurationAsDraft({ curationId, title, description }).unwrap()
        saveDraftNotes({
          curationId,
          notes
        })
        saveDraftOrderedItems({
          curationId,
          orderedItems
        })
        saveDraftProductDiscovery({
          curationId,
          productDiscovery
        })
        generateSaveAsDraftNewRelicLogs(
          curationId,
          orderedItems,
          notes,
          title,
          description
        )
        await handleResumeCuration()
      } catch (e) {
        return
      }
    },
    [handleResumeCuration, saveCurationAsDraft]
  )

  useEffect(() => {
    if (
      inProgressCuration &&
      inProgressCuration.type === CURATION_TYPE.CUSTOMER_INITIATED
    ) {
      fetchToken({
        curationId: inProgressCuration.id,
        employeeEmail,
        employeeId
      })
    }
  }, [inProgressCuration, fetchToken, employeeId, employeeEmail])

  useEffect(() => {
    if (!inProgressCuration && !isGetCurationsLoading && !isResumeError) {
      handleResumeCuration()
    }
  }, [
    handleResumeCuration,
    inProgressCuration,
    isGetCurationsLoading,
    isResumeError
  ])

  useEffect(() => {
    if (
      inProgressCuration &&
      inProgressCuration.type === CURATION_TYPE.SELLER_INITIATED &&
      inProgressCuration.id !== Number(resumeCurationId)
    ) {
      handleSaveCurationAsDraft(inProgressCuration.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgressCuration, resumeCurationId])

  if (isAbandonCurationError) {
    return (
      <PageError
        errorDetails={{
          endpoint: abandonEndpoint,
          errorData: abandonCurationError,
          identifiers: {
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId
          },
          originalArgs: abandonOriginalArgs
        }}
      />
    )
  }

  if (isFetchTokenError) {
    return (
      <PageError
        errorDetails={{
          endpoint: fetchTokenEndpointName,
          errorData: fetchTokenError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          },
          originalArgs: fetchTokenOriginalArgs
        }}
      />
    )
  }

  if (isGetCurationsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getCurationsEndpointName,
          errorData: getCurationsError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          },
          originalArgs: getCurationsOriginalArgs
        }}
      />
    )
  }

  if (isResumeError) {
    return (
      <PageError
        errorDetails={{
          endpoint: resumeEndpointName,
          errorData: resumeError,
          originalArgs: resumeOriginalArgs,
          identifiers: { curationId: inProgressCuration?.id || 'NO-DATA' }
        }}
      />
    )
  }

  if (isSaveCurationAsDraftError) {
    return (
      <PageError
        errorDetails={{
          endpoint: saveAsDraftEndpoint,
          errorData: saveCurationAsDraftError,
          identifiers: {
            customerId: inProgressCuration?.ocpId || 'NO-DATA',
            curationId: inProgressCuration?.id || 'NO-DATA',
            employeeId,
            shoppingSessionId:
              inProgressCuration?.shoppingSessionId || 'NO-DATA'
          },
          originalArgs: saveCurationAsDraftOriginalArgs
        }}
      />
    )
  }

  const initCheckoutToken = fetchTokenData?.token

  if (
    !inProgressCuration ||
    (!initCheckoutToken &&
      inProgressCuration.type === CURATION_TYPE.CUSTOMER_INITIATED) ||
    isGetCurationsLoading ||
    isGetCurationsFetching ||
    isResumeLoading ||
    isSaveCurationAsDraftLoading
  ) {
    return <LoadingSpinner />
  }

  if (resumeCurationId === String(inProgressCuration?.id)) {
    return <Redirect to="/review" />
  }

  const userHasCustomerInitiatedCurationInProgress =
    inProgressCuration &&
    inProgressCuration.type === CURATION_TYPE.CUSTOMER_INITIATED

  return (
    <CurationReview
      handleAbandon={handleAbandon}
      handleCancel={handleCancel}
      initCheckoutToken={initCheckoutToken || ''}
      inProgressCuration={inProgressCuration}
      curationInProgressDialog={
        userHasCustomerInitiatedCurationInProgress
          ? CurationInProgressDialog.AbandonOrContinue
          : CurationInProgressDialog.None
      }
    />
  )
}

export default ResumeCurationWrapper
