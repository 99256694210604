import { ReactElement, useState } from 'react'
import { savedSizesT } from 'types/ConsentedCustomer'
import { Typography, Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import SizeList from './SizeList'
import PageError from 'components/PageError'
import { SAVED_SIZE_ERROR_TITLE } from 'pages/CustomerDetails/constants'

type PropsT = {
  savedSizes: savedSizesT
  isError?: boolean
}

interface ErrorPropsT {
  customerId: string
  endpointName?: string
}

const NoSavedSizes = () => (
  <Typography
    variant="body2"
    color="text.secondary"
    style={{ fontSize: '1.125rem' }}
  >
    No saved sizes
  </Typography>
)

const SavedSizeDataError = ({ customerId, endpointName }: ErrorPropsT) => (
  <PageError
    isInlineAlert={true}
    errorTitle={SAVED_SIZE_ERROR_TITLE}
    errorDetails={{
      endpoint: endpointName,
      errorData: `PAS Error: ${SAVED_SIZE_ERROR_TITLE}`,
      identifiers: {
        customerId,
        curationId: 'NO-DATA',
        employeeId: 'NO-DATA',
        shoppingSessionId: 'NO-DATA'
      }
    }}
  />
)

const SavedSizes = ({
  savedSizes,
  isError,
  customerId,
  endpointName
}: PropsT & ErrorPropsT): ReactElement => {
  const showSavedSizes = savedSizes?.FEMALE || savedSizes?.MALE
  const [showingAll, setShowingAll] = useState(false)

  const data = [
    {
      label: 'Women’s',
      value: 'female',
      length: savedSizes?.FEMALE?.length || 0,
      data: savedSizes?.FEMALE || []
    },
    {
      label: 'Men’s',
      value: 'male',
      length: savedSizes?.MALE?.length || 0,
      data: savedSizes?.MALE || []
    }
  ].sort((a, b) => b.length - a.length)
  const [department, setDepartment] = useState<string | null>(data[0].value)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newDepartment: string | null
  ) => {
    setDepartment(newDepartment)
  }

  const handleClickShowingAll = () => {
    setShowingAll((showingAll) => !showingAll)
  }

  return (
    <Box>
      <Typography variant="subtitle2" mt="12px">
        Saved sizes
      </Typography>
      {!isError ? (
        <>
          {showSavedSizes ? (
            <Box height="100%">
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={department}
                exclusive
                onChange={handleChange}
                aria-label="sizes"
                sx={{ pt: '12px' }}
              >
                {data.map(
                  (gender) =>
                    gender.length && (
                      <ToggleButton key={gender.value} value={gender.value}>
                        {gender.label}
                      </ToggleButton>
                    )
                )}
              </ToggleButtonGroup>
              <Box height="100%">
                {data.map(
                  (size) =>
                    department === size.value &&
                    size.length && (
                      <SizeList
                        key={size.label}
                        sizes={showingAll ? size.data : size.data.slice(0, 6)}
                        handleClick={handleClickShowingAll}
                        showingAll={showingAll}
                        showMore={size.data.length > 6}
                      />
                    )
                )}
              </Box>
            </Box>
          ) : (
            <NoSavedSizes />
          )}
        </>
      ) : (
        <SavedSizeDataError
          customerId={customerId}
          endpointName={endpointName}
        />
      )}
    </Box>
  )
}

export default SavedSizes
