import BaseSnackbar from 'components/BaseSnackbar'
import { ReactElement } from 'react'

const PUBLISH_SUCCESS_MESSAGE = 'Style board sent.'

const PublishCurationSnackbar = ({
  message = PUBLISH_SUCCESS_MESSAGE
}: {
  message?: string
}): ReactElement => {
  return <BaseSnackbar message={message} />
}

export default PublishCurationSnackbar
