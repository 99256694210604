import moment from 'moment'
import type { Moment } from 'moment'

export interface StartAndEndDatesT {
  start: Moment
  end: Moment
}

type FiscalDateMapT = Record<string, StartAndEndDatesT>

export const getFiscalStartAndEndDates = (
  date: Moment,
  fiscalDateMap: FiscalDateMapT
): StartAndEndDatesT | null => {
  for (const entry in fiscalDateMap) {
    if (date.isBetween(fiscalDateMap[entry].start, fiscalDateMap[entry].end)) {
      return fiscalDateMap[entry]
    }
  }
  return null
}

export const getFiscalMonthStartAndEndDates = (
  date: Moment
): StartAndEndDatesT | null => {
  return getFiscalStartAndEndDates(date, FISCAL_MONTH_DATES_2024)
}

export const getFiscalQuarterStartAndEndDates = (
  date: Moment
): StartAndEndDatesT | null => {
  return getFiscalStartAndEndDates(date, FISCAL_QUARTER_DATES_2024)
}

export const getFiscalYearStartAndEndDates = (
  date: Moment
): StartAndEndDatesT | null => {
  return getFiscalStartAndEndDates(date, FISCAL_YEAR_DATES)
}

// TODO: These should be updated prior to January 2025
export const FISCAL_MONTH_DATES_2024: FiscalDateMapT = {
  FEBRUARY: {
    start: moment('2024-02-04').startOf('day'),
    end: moment('2024-03-02').endOf('day')
  },
  MARCH: {
    start: moment('2024-03-03').startOf('day'),
    end: moment('2024-04-06').endOf('day')
  },
  APRIL: {
    start: moment('2024-04-07').startOf('day'),
    end: moment('2024-05-04').endOf('day')
  },
  MAY: {
    start: moment('2024-05-05').startOf('day'),
    end: moment('2024-06-01').endOf('day')
  },
  JUNE: {
    start: moment('2024-06-02').startOf('day'),
    end: moment('2024-07-06').endOf('day')
  },
  JULY: {
    start: moment('2024-07-07').startOf('day'),
    end: moment('2024-08-03').endOf('day')
  },
  AUGUST: {
    start: moment('2024-08-04').startOf('day'),
    end: moment('2024-08-31').endOf('day')
  },
  SEPTEMBER: {
    start: moment('2024-09-01').startOf('day'),
    end: moment('2024-10-05').endOf('day')
  },
  OCTOBER: {
    start: moment('2024-10-06').startOf('day'),
    end: moment('2024-11-02').endOf('day')
  },
  NOVEMBER: {
    start: moment('2024-11-03').startOf('day'),
    end: moment('2024-11-30').endOf('day')
  },
  DECEMBER: {
    start: moment('2024-12-01').startOf('day'),
    end: moment('2025-01-04').endOf('day')
  },
  JANUARY: {
    start: moment('2025-01-05').startOf('day'),
    end: moment('2025-02-01').endOf('day')
  }
}

// These should be updated prior to January 2025
export const FISCAL_QUARTER_DATES_2024: FiscalDateMapT = {
  Q1: {
    start: FISCAL_MONTH_DATES_2024.FEBRUARY.start,
    end: FISCAL_MONTH_DATES_2024.APRIL.end
  },
  Q2: {
    start: FISCAL_MONTH_DATES_2024.MAY.start,
    end: FISCAL_MONTH_DATES_2024.JULY.end
  },
  Q3: {
    start: FISCAL_MONTH_DATES_2024.AUGUST.start,
    end: FISCAL_MONTH_DATES_2024.OCTOBER.end
  },
  Q4: {
    start: FISCAL_MONTH_DATES_2024.NOVEMBER.start,
    end: FISCAL_MONTH_DATES_2024.JANUARY.end
  }
}

export const FISCAL_YEAR_DATES = {
  2024: {
    start: FISCAL_QUARTER_DATES_2024.Q1.start,
    end: FISCAL_QUARTER_DATES_2024.Q4.end
  }
}
