// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
export const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

// returns string price formatted with dollar sign: '$10.50'
export const formatProductPrice = (dollars = 0, nanos = 0): string => {
  //Nanos are partial units of money expressed in nanos (10^9)
  //an example would be 500000000 equating to 50 cents
  //dividing by 1000000000 puts it in cents (.50) so we can add it to the dollars nicely
  const cents = nanos / 1000000000
  const unformattedPrice = dollars + cents
  return formatPrice.format(unformattedPrice)
}

// returns numeric price formatted without dollar sign: 10.5
export const formatNumericPrice = (dollars = 0, nanos = 0): number => {
  const cents = nanos / 1000000000
  const numericPrice = dollars + cents

  return numericPrice
}
