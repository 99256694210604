import { LoadingButton } from '@mui/lab'
import { ReactElement } from 'react'
import LocalMallIcon from '@mui/icons-material/LocalMall'

export type ShopButtonTextOptionsT =
  | 'Renew Session'
  | 'Continue Shopping'
  | 'Browse Nordstrom.com'
  | 'Add more from Nordstrom.com'

type PropsT = {
  isLoading: boolean
  onClick: () => void
  message:
    | 'Renew Session'
    | 'Continue Shopping'
    | 'Browse Nordstrom.com'
    | 'Add more from Nordstrom.com'
}

const ShopButton = (props: PropsT): ReactElement => {
  const { isLoading, onClick, message } = props

  return (
    <LoadingButton
      loading={isLoading}
      loadingPosition="center"
      variant="outlined"
      onClick={onClick}
      fullWidth
      sx={{ textTransform: 'none' }}
      startIcon={<LocalMallIcon />}
    >
      {message}
    </LoadingButton>
  )
}

export default ShopButton
