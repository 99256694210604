import { ReactElement } from 'react'

type Props = {
  children: React.ReactNode
  value: number
  index: number
}

type A11yProps = {
  id: string
  'aria-controls': string
}

export const a11yProps = (index: number): A11yProps => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export const TabPanel = (props: Props): ReactElement => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
