import {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import { Portal } from '@mui/core'
import { Box, Typography } from '@mui/material'
import { useAddItemToBagMutation } from 'services/curationSvc'
import { useGetItemByUPCMutation } from 'services/employeeExperienceApi'
import { emDashCharacter } from 'pages/constants'
import BasePageAlert from 'components/BasePageAlert'
import Drawer from 'components/Drawer'
import DrawerNavigation from 'components/DrawerNavigation'
import BarcodeScanner from 'components/BarcodeScanner'
import ScanditScanner from 'components/BarcodeScanner/Scandit/ScanditScanner'
import { useSDK } from 'components/BarcodeScanner/Scandit/ScanditSDK'
import DrawerSearchBar from 'components/SearchBar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import AddItemSuccessSnackbar from './components/AddItemSuccessSnackbar'
import AddItemErrorSnackbar from 'components/AddItemErrorSnackbar'
import CameraNotAvailable from './components/CameraNotAvailable'
import OfferNotFoundDialog from './components/OfferNotFoundDialog'
import UPCProductResultDialog from './components/UPCProductResultDialog'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { UPDATE_SCANNER_ADDS } from 'pages/CurationReview/constants'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import useIsTokenExpired from 'hooks/useIsTokenExpired'
import { useIsScanningWithScanditEnabledForUser } from 'utils/useScanningFeatures'
import { validateNumberOnlyInput } from './helpers/validateNumberOnlyInput'

enum OfferError {
  NOT_FOUND = 'not-found',
  OTHER = 'other'
}

enum AddItemError {
  UNAVAILABLE = 'unavailable',
  OTHER = 'other'
}

export type PropsT = {
  onClickForPdp: (webstyleId: string) => void
  onClose: () => void
  open: boolean
  shoppingSessionId: string
  syncItems: () => void
  token: string
  isEdit: boolean | null
  refetchToken: () => void
}

const ScannerDrawer = (props: PropsT): ReactElement | null => {
  const {
    onClickForPdp,
    onClose,
    open: isDrawerOpen,
    shoppingSessionId,
    syncItems,
    token,
    isEdit,
    refetchToken
  } = props

  const [query, setQuery] = useState('')
  const [addedItemBrandAndName, setAddedItemBrandAndName] = useState('')
  const isMobileDevice = !!checkBrowserClient.isMobile()
  const [addItemError, setAddItemError] = useState<AddItemError>()
  const [offerError, setOfferError] = useState<OfferError>()
  const [isCameraAllowed, setIsCameraAllowed] = useState<boolean>(true)
  const [inputError, setInputError] = useState(false)
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)
  const { isTokenExpired: isCheckoutTokenExpired } = useIsTokenExpired(
    token || ''
  )
  const [addToBagCallRequired, setAddToBagCallRequired] = useState(false)
  const scanditSDK = useSDK()

  const shouldUseScanditScanner = useIsScanningWithScanditEnabledForUser()

  const [
    getItemDetailsByUPC,
    {
      data: upcData,
      endpointName: upcEndpoint,
      error: upcError,
      isLoading: isUpcLoading,
      reset: resetUPCData
    }
  ] = useGetItemByUPCMutation()

  const curationDetails = useAppSelector(curationSelector)

  const shouldShowScanner =
    isMobileDevice && !upcData && !offerError && isDrawerOpen && isCameraAllowed

  const newRelicAttributes = useMemo(() => {
    return {
      curationId: curationDetails?.id,
      type: curationDetails?.type,
      preferredEmployeeId: curationDetails?.preferredEmployee,
      ocpId: curationDetails?.ocpId
    }
  }, [
    curationDetails?.id,
    curationDetails?.ocpId,
    curationDetails?.preferredEmployee,
    curationDetails?.type
  ])

  const handleGetByUPC = (upc: string) => {
    if (offerError) {
      setOfferError(undefined)
    }

    if (!validateNumberOnlyInput(upc)) {
      setInputError(true)
      return
    }

    const trimmedUpc = upc.trim()
    getItemDetailsByUPC(trimmedUpc)
      .unwrap()
      .catch((error) => {
        let offerError = OfferError.OTHER
        if ('status' in error) {
          const errorMessage = JSON.stringify(error.data)
          if (
            errorMessage?.includes('Offer not found') ||
            errorMessage?.includes('Invalid UPC')
          ) {
            offerError = OfferError.NOT_FOUND
          }
        }
        setOfferError(offerError)
      })
  }

  const handleInputChange = (value: string) => {
    if (inputError) setInputError(false)

    setQuery(value)
  }

  const handleNotAllowedOrPermissionsError = () => {
    setIsCameraAllowed(false)
  }

  const [
    addItemToBag,
    {
      isLoading: isAddItemLoading,
      isSuccess: isAddItemSuccess,
      reset: resetAddToBag
    }
  ] = useAddItemToBagMutation()

  const handleAddToBag = useCallback(() => {
    upcData?.rmsSku &&
      addItemToBag({
        shopperId: shoppingSessionId,
        token,
        rmsSku: upcData.rmsSku
      })
        .unwrap()
        .then(() => {
          if (upcData?.rmsSku && !isEdit && dispatch) {
            dispatch({
              type: UPDATE_SCANNER_ADDS,
              payload: { rmsSku: upcData.rmsSku }
            })
          }
          if (upcData?.rmsSku && isEdit && editDispatch) {
            editDispatch({
              type: UPDATE_SCANNER_ADDS,
              payload: { rmsSku: upcData.rmsSku }
            })
          }
          upcData?.rmsSku &&
            generateNewRelicLogs('itemAddedFromUpcScan', {
              ...newRelicAttributes,
              rmsSku: upcData?.rmsSku,
              webStyleId: upcData?.webStyleId
            })

          const brandAndItemName = `${upcData?.brandName} ${upcData?.itemName}`
          setAddedItemBrandAndName(brandAndItemName)
          setAddToBagCallRequired(false)
          resetUPCData()
          syncItems()
          return
        })
        .catch((error) => {
          let addItemError = AddItemError.OTHER
          if ('status' in error) {
            const errorMessage = JSON.stringify(error.data)
            const itemUnavailable = errorMessage?.includes(
              'One or more items is not purchasable online'
            )
            if (itemUnavailable) {
              addItemError = AddItemError.UNAVAILABLE
            }
          }
          setAddItemError(addItemError)
        })
  }, [
    addItemToBag,
    dispatch,
    editDispatch,
    isEdit,
    newRelicAttributes,
    resetUPCData,
    shoppingSessionId,
    syncItems,
    token,
    upcData?.brandName,
    upcData?.itemName,
    upcData?.rmsSku,
    upcData?.webStyleId
  ])

  useEffect(() => {
    if (addToBagCallRequired && !isCheckoutTokenExpired) {
      handleAddToBag()
    }
  }, [handleAddToBag, addToBagCallRequired, isCheckoutTokenExpired, token])

  const onAddToBagClick = () => {
    if (isCheckoutTokenExpired) {
      setAddToBagCallRequired(true)
      refetchToken()
    } else {
      handleAddToBag()
    }
  }

  const onDrawerClose = () => {
    resetSearch()
    if (addedItemBrandAndName) setAddedItemBrandAndName('')
    if (addItemError) setAddItemError(undefined)
    resetAddToBag()
    resetUPCData()
    onClose()
  }

  const onViewItemClick = () => {
    upcData?.webStyleId && onClickForPdp(upcData?.webStyleId)
    onDrawerClose()
    upcData?.rmsSku &&
      generateNewRelicLogs('viewPdpFromUpcScan', {
        ...newRelicAttributes,
        rmsSku: upcData?.rmsSku,
        webStyleId: upcData?.webStyleId
      })
  }

  const resetSearch = () => {
    if (inputError) setInputError(false)
    if (query) setQuery('')
    if (offerError) setOfferError(undefined)
  }

  return (
    <Drawer onClose={onDrawerClose} open={isDrawerOpen}>
      <LoadingBackdrop open={isUpcLoading} />
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box sx={{ px: 2 }}>
          <DrawerNavigation
            title="Scan items"
            headerEndButton={null}
            onClick={onDrawerClose}
            showBackButton={false}
          />
          <Box mb={2}>
            <Typography ml={0.5} variant="caption" align="center">
              Scan barcode or enter UPC manually.
            </Typography>
          </Box>
          <DrawerSearchBar
            handleInputChange={handleInputChange}
            isError={inputError}
            isNumericOnlyTextField={true}
            numericInputHelperText={'UPC should be numbers only'}
            onClick={() => handleGetByUPC(query)}
            placeholder={'UPC'}
            query={query}
            resetSearch={resetSearch}
          />
        </Box>
        {offerError === OfferError.OTHER && (
          <Box sx={{ px: 2 }}>
            <BasePageAlert
              alertTitle={`Issue scanning item ${emDashCharacter} try again`}
              errorDetails={{ endpoint: upcEndpoint, errorData: upcError }}
            />
          </Box>
        )}

        <OfferNotFoundDialog
          open={offerError === OfferError.NOT_FOUND}
          resetOfferError={() => setOfferError(undefined)}
        />
        {upcData && (
          <UPCProductResultDialog
            isAddItemLoading={isAddItemLoading}
            onAddToBag={() => onAddToBagClick()}
            onClickForPdP={onViewItemClick}
            onClose={() => resetUPCData()}
            open={!!upcData}
            result={upcData}
          />
        )}
        {shouldShowScanner &&
          (shouldUseScanditScanner ? (
            <ScanditScanner getByUPC={handleGetByUPC} scanditSDK={scanditSDK} />
          ) : (
            <BarcodeScanner
              handleNotAllowedOrPermissionsError={
                handleNotAllowedOrPermissionsError
              }
              onScanSuccess={handleGetByUPC}
            />
          ))}
        {(!isMobileDevice || isCameraAllowed === false) && (
          <CameraNotAvailable
            isCameraPermissionDenied={isCameraAllowed === false}
          />
        )}
        {
          <Portal>
            {isAddItemSuccess && (
              <AddItemSuccessSnackbar productName={addedItemBrandAndName} />
            )}
            {addItemError && (
              <AddItemErrorSnackbar
                itemUnavailable={addItemError === AddItemError.UNAVAILABLE}
              />
            )}
          </Portal>
        }
      </Box>
    </Drawer>
  )
}

export default ScannerDrawer
