import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import {
  getDidUserBlockNotifications,
  getIsUserSubscribed,
  getPushSubscription,
  subscribeToPushNotifications,
  unsubscribeFromPushNotifications
} from 'utils/service-worker-utils'
import { SavedPushSubscription } from 'types/Notification'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_SETTINGS_HOME_PAGE_ENABLE_PUSH_NOTIFICATIONS } from 'constants/clienteling/newRelicEvents/nrSettingsHomePage'

type PropsT = {
  savePushNotificationSubscription: (
    pushSubscription: PushSubscriptionJSON
  ) => Promise<SavedPushSubscription>
  deletePushNotificationSubscription: (
    pushSubscription: PushSubscriptionJSON
  ) => Promise<void>
  setIsSavePushNotificationSubscriptionError: (arg: boolean) => void
  setIsErrorUnsubscribingPushNotification: (value: boolean) => void
  setPushNotificationErrorMessage: (value: string) => void
}

const PushSubscriptionToggle = ({
  savePushNotificationSubscription,
  setIsSavePushNotificationSubscriptionError,
  deletePushNotificationSubscription,
  setIsErrorUnsubscribingPushNotification,
  setPushNotificationErrorMessage
}: PropsT): ReactElement => {
  const [isSubscribedToPushNotifications, setIsSubscribedToPushNotifications] =
    useState<boolean>(false)
  const [hasBlockedPushNotifications, setHasBlockedPushNotifications] =
    useState(true)
  const [isPending, setIsPending] = useState<boolean>(false)

  const checkAndUpdatePushPermissions = useCallback(async () => {
    const didUserBlockNotifications = await getDidUserBlockNotifications()
    setHasBlockedPushNotifications(didUserBlockNotifications)
    const isUserSubscribed = await getIsUserSubscribed()
    setIsSubscribedToPushNotifications(isUserSubscribed)
  }, [])

  useEffect(() => {
    const setServiceWorkerPushPermissions = async () => {
      setIsPending(true)
      await checkAndUpdatePushPermissions()
      setIsPending(false)
    }
    setServiceWorkerPushPermissions()
  }, [checkAndUpdatePushPermissions])

  const handleChange = async () => {
    setIsPending(true)
    setIsSavePushNotificationSubscriptionError(false)
    await checkAndUpdatePushPermissions()
    if (!isSubscribedToPushNotifications) {
      const pushSubscription = await subscribeToPushNotifications()
      if (pushSubscription) {
        try {
          await savePushNotificationSubscription(pushSubscription)
          setIsSubscribedToPushNotifications(true)
          localStorage.setItem('isSubscribedToPushNotifications', 'true')
          generateNewRelicLogs(NR_SETTINGS_HOME_PAGE_ENABLE_PUSH_NOTIFICATIONS)
        } catch (error) {
          setIsSavePushNotificationSubscriptionError(true)
          await unsubscribeFromPushNotifications()
        }
      } else {
        setIsSavePushNotificationSubscriptionError(true)
        setHasBlockedPushNotifications(true)
      }
    } else {
      try {
        const subscriptionToRemove = await getPushSubscription()
        const isUnsubscribedFromPushService =
          await unsubscribeFromPushNotifications()
        if (isUnsubscribedFromPushService) {
          if (subscriptionToRemove) {
            await deletePushNotificationSubscription(subscriptionToRemove)
          }
          setIsSubscribedToPushNotifications(false)
          localStorage.setItem('isSubscribedToPushNotifications', 'false')
        }
      } catch (error) {
        const isUserStillSubscribed = await getIsUserSubscribed()
        if (isUserStillSubscribed) {
          setIsErrorUnsubscribingPushNotification(true)
          setIsSubscribedToPushNotifications(true)
          setPushNotificationErrorMessage(error as string)
        } else {
          setIsSubscribedToPushNotifications(false)
        }
      }
    }
    setIsPending(false)
  }

  return (
    <>
      <Switch
        edge="end"
        disabled={isPending || hasBlockedPushNotifications}
        onChange={handleChange}
        checked={isSubscribedToPushNotifications}
        inputProps={{ 'aria-label': 'controlled' }}
        data-testid="pushNotificationsToggle"
      />
    </>
  )
}

export default PushSubscriptionToggle
