import { ReactElement } from 'react'
import { Stack, Container, Grid, Divider } from '@mui/material'
import Skeleton from 'components/Skeleton'

const PurchaseDetailSkeleton = () => (
  <Container>
    <Stack direction="column" mt={2} spacing={2} width={'100%'}>
      <Skeleton.Text textVariant={'h5'} />
      <Skeleton.Text textVariant={'body2'} />
      <Grid container rowSpacing={2} columns={4}>
        <Grid item display="flex" xs={1} pr={2}>
          <Skeleton.Image aspectRatio={6 / 9} />
        </Grid>
        <Grid item display="flex" xs={3} pr={2}>
          <Stack direction="column" spacing={2} width={'100%'}>
            <Skeleton.Text textVariant={'body2'} />
            <Skeleton.Text textVariant={'body2'} />
            <Skeleton.Text textVariant={'body2'} />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  </Container>
)

const LoadingPurchasesList = (): ReactElement => (
  <>
    <PurchaseDetailSkeleton />
    <Divider sx={{ mt: '12px' }} />
    <PurchaseDetailSkeleton />
    <Divider sx={{ mt: '12px' }} />
    <PurchaseDetailSkeleton />
  </>
)

export default LoadingPurchasesList
