import { v4 } from 'uuid'

const setStandardHeaders = ({ headers }: { headers: Headers }): void => {
  headers.set('Nord-Request-Id', makeSlug())
  headers.set('Nord-Client-Id', 'APP07298')
  headers.set('Nord-Channel-Brand', 'NORDSTROM')
}

const makeSlug = () => {
  const bytes = v4(null, Buffer.alloc(16))
  const base64 = bytes.toString('base64')
  const slug = base64.replace(/\+/g, '-').replace(/\//g, '_').substring(0, 22)
  return slug
}

export default setStandardHeaders
