import { ReactComponentElement } from 'react'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import { colors } from '../MenuBar/constants'
import CustomerBookSubNav from 'components/CustomerBookSubNav'
import NavMenuItem from 'components/NavMenuItem'

type PropsT = {
  selectedIndex: number | null
  setSelectedIndex: (index: number) => void
  totalMainMenuOptions: number
  icon: ReactComponentElement<typeof SvgIcon>
  title: string
  path: string
  index: number
  goToPath: ({ path, index }: { path: string; index: number }) => void
  isSelected: boolean
  isCustomerBookSubNavExpanded: boolean
  setIsCustomerBookSubNavExpanded: (
    isCustomerBookSubNavExpanded: boolean
  ) => void
}

const NavMenuItemExpandable = (props: PropsT): JSX.Element => {
  const {
    selectedIndex,
    setSelectedIndex,
    totalMainMenuOptions,
    icon,
    title,
    path,
    index,
    goToPath,
    isSelected,
    isCustomerBookSubNavExpanded,
    setIsCustomerBookSubNavExpanded
  } = props

  const isCustomerBookOption = path === '/customers'

  const handleSubNavArrowClick = () => {
    if (isCustomerBookOption) {
      setIsCustomerBookSubNavExpanded(!isCustomerBookSubNavExpanded)
    }
  }

  // Adjust if more submenus are added for other top-level menu options
  const isSubNavOpen = isCustomerBookOption && isCustomerBookSubNavExpanded

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <NavMenuItem
          icon={icon}
          title={title}
          path={path}
          index={index}
          goToPath={() => goToPath({ path, index })}
          isSelected={isSelected}
        />
        <IconButton
          sx={{
            color: colors.primaryWithTransparency,
            position: 'absolute',
            top: 0,
            right: 0,
            marginTop: '5px',
            marginRight: '5px',
            '&:hover': {
              backgroundColor: colors.gray
            }
          }}
          onClick={handleSubNavArrowClick}
        >
          {isSubNavOpen ? (
            <ExpandLess data-testid="expand-less" />
          ) : (
            <ExpandMore data-testid="expand-more" />
          )}
        </IconButton>
      </Box>
      <Collapse
        key={`${title}-exapandable-subnav-handler`}
        in={isSubNavOpen}
        timeout="auto"
        orientation="vertical"
        unmountOnExit
      >
        {isCustomerBookOption && (
          <CustomerBookSubNav
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            totalMainMenuOptions={totalMainMenuOptions}
            goToPath={goToPath}
          />
        )}
      </Collapse>
    </Box>
  )
}

export default NavMenuItemExpandable
