import { ReactElement } from 'react'
import { Typography } from '@mui/material'

type PropsT = {
  curationCount?: number
}

const CurationCount = (props: PropsT): ReactElement => {
  const { curationCount } = props

  const requestText = curationCount != 1 ? 'Requests' : 'Request'

  // explicitly checking null or undefined since we want to display if there are 0 curations
  if (curationCount == null) {
    return <></>
  }

  return (
    <Typography color="text.primary" textAlign="left">
      {`${curationCount} Styling ${requestText}`}
    </Typography>
  )
}
export default CurationCount
