import { CustomerSummaryFeedback } from 'pages/SnapshotDrawer/components/CustomerDossier/helpers'
import { CustomerDossierT } from 'types/CustomerDossier'

export const DOSSIER_FEEDBACK_STORAGE_KEY = 'customer_dossier_feedback_storage'
export const DOSSIER_STORAGE_KEY = 'customer_dossier_storage'
export const NEGATIVE_FEEDBACK_DETAILS_STORAGE_KEY =
  'negative_feedback_details_storage_key'

export const saveCustomerDossierToLocalStorage = ({
  storageKey,
  curationId,
  data
}: {
  storageKey: string
  curationId: number
  data: CustomerSummaryFeedback | CustomerDossierT | string[]
}): void => {
  const customerDossiers = JSON.parse(localStorage.getItem(storageKey) || '{}')
  const newDossier = {
    ...customerDossiers,
    [curationId]: data
  }
  localStorage.setItem(storageKey, JSON.stringify(newDossier))
  return
}

export const getItemFromLocalStorage = (
  curationId: number,
  storageKey: string
): CustomerSummaryFeedback | CustomerDossierT | string[] => {
  const data = localStorage.getItem(storageKey)
  return data ? JSON.parse(data)[curationId] : null
}

export const deleteCustomerDossier = (curationId: number): undefined => {
  const dossier = JSON.parse(localStorage.getItem(DOSSIER_STORAGE_KEY) || '{}')
  const dossierFeedback = JSON.parse(
    localStorage.getItem(DOSSIER_FEEDBACK_STORAGE_KEY) || '{}'
  )
  delete dossier[curationId]
  delete dossierFeedback[curationId]
  localStorage.setItem(DOSSIER_STORAGE_KEY, JSON.stringify(dossier))
  localStorage.setItem(
    DOSSIER_FEEDBACK_STORAGE_KEY,
    JSON.stringify(dossierFeedback)
  )
  return
}
