import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Conversation } from '@twilio/conversations'
import { twilioUtils } from 'services/twilioSlice'
import {
  Box,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  ListItemIcon
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import MoreVert from '@mui/icons-material/MoreVert'
import PersonIcon from '@mui/icons-material/Person'

const MessagesMenuBar = ({
  isCustomerOptedIn,
  messagesHaveBeenFetched,
  conversationName,
  conversation,
  setCustomerDetailsError
}: {
  isCustomerOptedIn: boolean
  messagesHaveBeenFetched: boolean
  conversationName: string
  conversation: Conversation | undefined
  setCustomerDetailsError: React.Dispatch<React.SetStateAction<boolean>>
}): ReactElement => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleViewDetails = async () => {
    if (conversation) {
      try {
        const { customerId } = await twilioUtils.getParticipantAttributes(
          conversation
        )

        if (customerId) {
          history.push({
            pathname: `/customers/details/${customerId}`
          })
        } else {
          setCustomerDetailsError(true)
        }
      } catch (e) {
        setCustomerDetailsError(true)
      }
    }
  }

  const goBack = async () => {
    if (isCustomerOptedIn && history.length > 1) {
      history.goBack()
    } else {
      history.push('/customers')
    }
  }

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: 'white', boxShadow: 'none' }}
    >
      <Toolbar sx={{ marginY: '8px' }}>
        <IconButton onClick={goBack} size="large" sx={{ marginRight: '16px' }}>
          <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}>
          {messagesHaveBeenFetched && (
            <Typography variant="h6" color="text.primary">
              {conversationName}
            </Typography>
          )}
        </Box>
        {isCustomerOptedIn && (
          <Box
            sx={{
              display: 'flex ',
              flexDirection: 'row',
              justifyContent: 'right'
            }}
          >
            <IconButton
              data-testid="kebobButton"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVert sx={{ color: 'text.primary' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  handleViewDetails()
                }}
                dense
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <Typography>View Details</Typography>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default MessagesMenuBar
