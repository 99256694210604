import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customerId: ''
  },
  reducers: {
    setCustomerId: (state, { payload }) => {
      state.customerId = payload
    }
  }
})

export const { setCustomerId } = customerSlice.actions
export const customerIdSelector = (state: RootState): string =>
  state.customer.customerId
