import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'

const TOKEN_EXPIRED_ALERT_TITLE = 'Shopping Session Timed Out'
const TOKEN_EXPIRED_ALERT_MESSAGE =
  'For customer security, your session expired. Renew session to continue shopping.'

const TokenExpiredAlert = (): ReactElement => {
  return (
    <BasePageAlert
      alertTitle={TOKEN_EXPIRED_ALERT_TITLE}
      errorMessage={TOKEN_EXPIRED_ALERT_MESSAGE}
    />
  )
}

export default TokenExpiredAlert
