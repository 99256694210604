import { CURATION_STATE } from 'types/Curation'

export const determineCurationTitle = ({
  curationTitle,
  curationState
}: {
  curationTitle?: string
  curationState: CURATION_STATE
}): string | undefined => {
  if (curationState === CURATION_STATE.IN_PROGRESS) {
    const { title } = JSON.parse(
      localStorage.getItem('curation_review_reducer_state') || '{}'
    )
    return title
  }

  return curationTitle
}
