import { ReactElement, useState } from 'react'

import { Button, CircularProgress, TextField, Box } from '@mui/material'

const HoldsButtonPrintItem = ({
  isLoading,
  handleSubmit
}: {
  isLoading: boolean
  handleSubmit: (printerNumber: string) => void
}): ReactElement => {
  const [printerNumber, setPrinterNumber] = useState('')

  const onClickEnter = () => {
    handleSubmit(printerNumber)
  }

  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      <TextField
        fullWidth
        id="printerNumber"
        label="Printer number"
        name="printerNumber"
        value={printerNumber}
        onChange={(event) => setPrinterNumber(event.target.value.toUpperCase())}
        inputProps={{
          'data-testid': 'printer_number_field',
          maxLength: 9
        }}
        aria-label="Print ticket by printer number"
        size="small"
      />

      <Button variant="contained" onClick={onClickEnter} disabled={isLoading}>
        {isLoading ? <CircularProgress size={28} /> : 'Enter'}
      </Button>
    </Box>
  )
}

export default HoldsButtonPrintItem
