import { ReactElement } from 'react'
import { Prompt } from 'react-router-dom'

import { Button } from '@mui/material'

import { HOLDS_TEXT_FIND_EXISTING_HOLD } from 'pages/Holds/constants'

import { useHoldsContextFetchTrigger } from 'pages/Holds/contexts/HoldsContextFetchTrigger'

import { useHoldsToggle } from 'pages/Holds/hooks'

import HoldsDialog from 'pages/Holds/shared/components/HoldsDialog'

import HoldsFindExistingDialogBody from './components/HoldsFindExistingDialogBody'

const HoldsButtonFindExisting = (): ReactElement => {
  const { handleRefetchTrigger } = useHoldsContextFetchTrigger()

  const {
    isOpen: isOpenFindExistingDialog,
    handleOpen: handleOpenFindExistingDialog,
    handleClose: handleCloseFindExistingDialog
  } = useHoldsToggle()

  const handleClose = () => {
    handleRefetchTrigger()
    handleCloseFindExistingDialog()
  }

  return (
    <>
      <Button
        data-testid="holds-button-find"
        fullWidth
        variant="outlined"
        onClick={handleOpenFindExistingDialog}
      >
        {HOLDS_TEXT_FIND_EXISTING_HOLD}
      </Button>

      <HoldsDialog
        data-testid="find_existing_hold_dialog"
        isDrawerMode
        open={isOpenFindExistingDialog}
        onClose={handleClose}
      >
        <HoldsFindExistingDialogBody handleClose={handleClose} />
      </HoldsDialog>

      {isOpenFindExistingDialog && (
        <Prompt
          message={() => {
            handleClose()
            return false
          }}
        />
      )}
    </>
  )
}

export default HoldsButtonFindExisting
