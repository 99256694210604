import { Box, Typography, List, ListItem } from '@mui/material'
import { customStylingTheme } from 'theme'
import { TipsAndUpdates } from '@mui/icons-material'
import { ReactElement } from 'react'

const THREE_HUNDRED_PX = 37.5

const FashionMapSearchHelperTextContent = () => (
  <Box
    sx={{
      maxWidth: customStylingTheme.spacing(THREE_HUNDRED_PX),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    }}
  >
    <Typography variant="subtitle2">
      Get the most out of Concept Search
    </Typography>
    <Typography variant="caption" color="text.secondary">
      Try searching by characteristics such as style, occasion, season,
      location, and item features. For example:
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            pl: customStylingTheme.spacing(0.25),
            lineHeight: customStylingTheme.spacing(0.5)
          },
          marginY: customStylingTheme.spacing(1.5),
          marginLeft: customStylingTheme.spacing(0.75)
        }}
      >
        <ListItem>Edgy blazer for winter party</ListItem>
        <ListItem>Romantic long floral dress for spring</ListItem>
        <ListItem>Classic structured gold button office jacket</ListItem>
      </List>
      Apply filters to further narrow results.
    </Typography>
  </Box>
)

const FashionMapSearchHelperText = (): ReactElement => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '80vh',
      textAlign: 'left',
      mt: customStylingTheme.spacing(4)
    }}
  >
    <Box
      sx={{
        maxWidth: customStylingTheme.spacing(THREE_HUNDRED_PX),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#EBEFF1',
          borderRadius: '50px',
          width: '67px',
          height: '67px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px'
        }}
      >
        <TipsAndUpdates
          fontSize="large"
          color="secondary"
          sx={{ textAlign: 'center' }}
        />
      </Box>
      <FashionMapSearchHelperTextContent />
    </Box>
  </Box>
)

export default FashionMapSearchHelperText
