import { MutableRefObject, useRef } from 'react'

const useHoldsRefState = <ValueType>(
  value: ValueType
): MutableRefObject<ValueType> => {
  const refState = useRef<ValueType>(value)
  refState.current = value

  return refState
}

export default useHoldsRefState
