import { ReactElement } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { GridProductT } from 'types/Product'
import { checkBrowserClient } from '../../../utils/userAgentDetector'
import ItemImage from '../../ItemImage'

export type Props = {
  handleClickForPdp?: (webstyleId: string) => void
  products: GridProductT[]
  fromSnapShot?: boolean
  totalItems: number
}

export type Item = {
  name: string
  id: string
  imageUrl?: string
}

const GridPanel = ({
  handleClickForPdp,
  products,
  fromSnapShot,
  totalItems
}: Props): ReactElement => {
  const MAX_COUNT = checkBrowserClient.isMobile() || fromSnapShot ? 4 : 6
  const overflow = totalItems > MAX_COUNT
  const overflowCount = totalItems - MAX_COUNT + 1
  const numberOfDisplayedItems = overflow ? MAX_COUNT - 1 : MAX_COUNT

  return (
    <Box mt={2}>
      <Grid
        container
        rowSpacing={{ xs: 2 }}
        columnSpacing={{ xs: 2 }}
        columns={MAX_COUNT}
      >
        {products
          .slice(0, numberOfDisplayedItems)
          .map((product: GridProductT, i) => (
            <Grid item display="flex" key={product.name + i}>
              <Button
                aria-label="view product details"
                onClick={() =>
                  product.styleId &&
                  handleClickForPdp &&
                  handleClickForPdp(product.styleId.toString())
                }
                sx={{
                  height: {
                    xs: 115,
                    sm: fromSnapShot ? 190 : 197,
                    lg: fromSnapShot ? 190 : 248
                  },
                  width: {
                    xs: 74,
                    sm: fromSnapShot ? 122 : 127,
                    lg: fromSnapShot ? 122 : 160
                  },
                  padding: 0
                }}
              >
                <ItemImage
                  imageUrl={product.imageUrl}
                  imageAltText={product.name}
                />
              </Button>
            </Grid>
          ))}
        {overflow && (
          <Grid
            item
            display="flex"
            xs={1}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box display="flex">
              <Typography variant="body2" marginX={1}>
                +{overflowCount} items
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default GridPanel
