import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'

type PropsT = {
  isLoading: boolean
  handleSaveAsDraft: () => void
}

const SaveAsDraftButton = (props: PropsT): ReactElement => {
  const { isLoading, handleSaveAsDraft } = props

  return (
    <Grid item>
      <LoadingButton
        loading={isLoading}
        fullWidth
        onClick={handleSaveAsDraft}
        variant="outlined"
        sx={{ textTransform: 'none' }}
      >
        Save for Later
      </LoadingButton>
    </Grid>
  )
}

export default SaveAsDraftButton
