import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import CurationCount from './CurationCount'
import RequestCountError from '../RequestCountError'
import { RequestedQueueDataT } from 'pages/CurationHome/CurationHome'
import DividerComponent from '../DividerComponent'

type PropsT = {
  requestedQueueData?: RequestedQueueDataT
  requestedQueueError: boolean
}

const ContinueRequest = (props: PropsT): ReactElement => {
  const { requestedQueueData, requestedQueueError } = props
  const history = useHistory()

  const proceedToReviewPage = useCallback(() => {
    history.push({ pathname: '/review', hash: undefined })
  }, [history])

  return (
    <Box>
      <DividerComponent />
      <Typography component="h1" variant="body1">
        You have 1 unfinished style board
      </Typography>
      {requestedQueueError ? (
        <RequestCountError />
      ) : (
        <CurationCount requestedQueueData={requestedQueueData} />
      )}
      <Box mx="auto" mb={1} mt={4} width={[1, 1 / 4]}>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={async () => {
            await proceedToReviewPage()
          }}
        >
          Continue Style Board
        </Button>
      </Box>
    </Box>
  )
}

export default ContinueRequest
