import { ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useCheckIpAddressQuery } from 'services/curationSvc'

const POLLING_INTERVAL = 10_000

const NordstromNetworkIndicator = (): ReactElement => {
  const { data: checkIpAddressData } = useCheckIpAddressQuery(
    {},
    {
      pollingInterval: POLLING_INTERVAL
    }
  )

  return (
    <>
      {checkIpAddressData?.isJwnEgressIp ? (
        <Stack
          direction="row"
          justifyContent="end"
          bgcolor="#F2F5F6"
          display="flex"
          justifyItems={'center'}
          sx={{ px: 1, py: 0.5 }}
        >
          <CheckCircleOutlineIcon color="success" fontSize="small" />
          <Typography variant="caption" sx={{ pl: 0.5 }}>
            Nordstrom network detected
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="end"
          bgcolor="#F2F5F6"
          display="flex"
          justifyItems={'center'}
          sx={{ px: 1, py: 0.5 }}
        >
          <WarningAmberIcon color="warning" fontSize="small" />
          <Typography variant="caption" sx={{ pl: 0.5 }}>
            Nordstrom network not detected
          </Typography>
        </Stack>
      )}
    </>
  )
}

export default NordstromNetworkIndicator
