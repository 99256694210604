export const determineHelperText = (
  descriptionLength: number,
  maxDescriptionLength: number,
  descriptionFromHelperText: string
): string => {
  if (descriptionLength === 0) return descriptionFromHelperText

  const characterTense =
    descriptionLength === maxDescriptionLength - 1 ||
    descriptionLength === maxDescriptionLength + 1
      ? 'character'
      : 'characters'

  if (descriptionLength <= maxDescriptionLength)
    return `${maxDescriptionLength - descriptionLength} ${characterTense} left`

  return `${descriptionLength - maxDescriptionLength} ${characterTense} over`
}
