import { ReactElement } from 'react'
import SubPageTemplateWithPageAppBar from 'components/SubPageTemplateWithPageAppBar'
import PersonalRequestsContent from './components/PersonalRequestsContent'

const PERSONAL_REQUESTS_TITLE = 'Your requests'

const PersonalRequests = (): ReactElement => {
  return (
    <SubPageTemplateWithPageAppBar title={PERSONAL_REQUESTS_TITLE}>
      <PersonalRequestsContent />
    </SubPageTemplateWithPageAppBar>
  )
}

export default PersonalRequests
