import { ReactElement } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material'
import CopyErrorData from 'components/CopyErrorData'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { Message } from '@twilio/conversations'
import { ReduxMessage } from 'types/Twilio'
import { transformErrorCodeIntoUserMessages } from 'services/twilioSlice/utils'
import { colors } from 'pages/CustomerMessages/constants'

export type PropsT = {
  message: ReduxMessage
  isOpen: boolean
  onClose: () => void
  isActionDisabled: boolean
  setCopyToClipboardSuccess: (arg: boolean) => void
  retrySendingMessage: (message: ReduxMessage) => Promise<void>
  removeMessage: ({
    reduxMessage,
    twilioMessage,
    isMessageSendRetry
  }: {
    reduxMessage: ReduxMessage
    twilioMessage?: Message
    isMessageSendRetry?: boolean
  }) => Promise<Message | undefined>
  isRetryingSendingMessage: boolean
  isRemovingMessage: boolean
}

const MessageStatusErrorModal = ({
  message,
  isOpen,
  onClose,
  isActionDisabled,
  setCopyToClipboardSuccess,
  retrySendingMessage,
  removeMessage,
  isRetryingSendingMessage,
  isRemovingMessage
}: PropsT): ReactElement => {
  const errorCode = message?.deliveryReceipt?.errorCode
  const errorDetails: CopyErrorDataT = {
    identifiers: {
      messageSid: message.sid,
      twilioErrorCode: errorCode || ''
    }
  }
  const { errorMessage, helperMessage } =
    transformErrorCodeIntoUserMessages(errorCode)

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{`Message not delivered - ${errorMessage}`}</DialogTitle>
      <DialogContent sx={{ paddingBottom: '12px' }}>
        <CopyErrorData
          errorDetails={errorDetails}
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          helperMessage={helperMessage}
        />
      </DialogContent>
      <DialogActions>
        {isRetryingSendingMessage && <CircularProgress size={25} />}
        <Button
          disabled={isActionDisabled}
          onClick={() => retrySendingMessage(message)}
          data-testid="retryMessageButton"
        >
          Try Again
        </Button>
        {isRemovingMessage && (
          <CircularProgress style={{ color: colors.lightGray }} size={25} />
        )}
        <Button
          disabled={isActionDisabled}
          onClick={() => removeMessage({ reduxMessage: message })}
          color="secondary"
          data-testid="deleteMessageButton"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageStatusErrorModal
