import { ReactElement, useState, useRef, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Typography,
  Box,
  Button,
  Pagination,
  paginationClasses
} from '@mui/material'
import { useGetStyleBoardsQuery } from 'services/employeeExperienceApi'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { checkBrowserClient } from 'utils/userAgentDetector'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  AbandonCurationArgsT,
  CURATION_ORDERING,
  CurationT
} from 'types/Curation'
import LoadingSpinner from 'components/LoadingSpinner'
import CurationCardList from 'components/CurationCardList'
import { colors } from '../../constants'
import StyleBoardsList from './StyleBoardsList'
import BoardsFilters, { FILTER_BY } from './Filters'
import PageError from 'components/PageError'
import { useAbandonCurationMutation } from 'services/curationSvc'
import { deleteDraftNotes } from 'utils/draftNotes'
import { deleteDraftOrderedItems } from 'utils/draftOrderedItems'
import DeleteDraftDialog from 'pages/Drafts/components/DeleteDraftDialog'
import DeleteDraftAlert from 'pages/Drafts/components/DeleteDraftAlert'
import BaseSnackbar from 'components/BaseSnackbar'
import { deleteCustomerDossier } from 'utils/customerDossier'

type PropsT = {
  customerId: string
  customerName: string
}

const PAGE_SIZE = 20
const BOARDS_SUB_HEADERS = [
  { title: 'Drafts', key: FILTER_BY.DRAFT },
  { title: 'Finished Boards', key: FILTER_BY.FINISHED }
]
const ERROR_TITLE = 'We had a problem retrieving your boards for this customer.'

const StyleBoards = ({ customerId, customerName }: PropsT): ReactElement => {
  const [filterParam, setFilterParam] = useState<FILTER_BY>(FILTER_BY.ALL)
  const [page, setPage] = useState(1)
  const [firstFinishedBoardElementId, setFirstFinishedBoardElementId] =
    useState<null | number>(null)
  const haveFirstBoardElementBeenStored = useRef(false)
  const [hasDrafts, setHasDrafts] = useState(false)
  const [hasFinishedBoards, setHasFinishedBoards] = useState(false)
  const [isDeleteDraftDialogOpen, setIsDeleteDraftDialogOpen] = useState(false)
  const [curationIdToDelete, setCurationIdToDelete] = useState<
    number | undefined
  >()
  const history = useHistory()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const isMobileDevice = checkBrowserClient.isMobile()

  const {
    data = { styleBoards: [] as CurationT[], meta: { count: 0 } },
    isLoading,
    isError,
    error,
    endpointName,
    refetch
  } = useGetStyleBoardsQuery({
    employeeId,
    customerId,
    state:
      filterParam === FILTER_BY.ALL
        ? [FILTER_BY.DRAFT, FILTER_BY.FINISHED]
        : filterParam,
    perPage: PAGE_SIZE,
    page: page,
    orderBy: CURATION_ORDERING.CREATED_DESC
  })

  useEffect(() => {
    if (!haveFirstBoardElementBeenStored.current) {
      const boardItem = data.styleBoards.find(
        (styleBoard) => (styleBoard.state as string) === FILTER_BY.FINISHED
      )
      if (boardItem) {
        setFirstFinishedBoardElementId(boardItem.id)
        haveFirstBoardElementBeenStored.current = true
      }
    }
  }, [data])

  const firstFinishedBoardIsOnCurrentPage = useMemo(() => {
    const isOnPage = data.styleBoards.some(
      (styleBoard) => styleBoard.id === firstFinishedBoardElementId
    )
    return isOnPage
  }, [data, firstFinishedBoardElementId])

  useEffect(() => {
    const draftsInCollection = data.styleBoards.some(
      (styleBoard) => (styleBoard.state as string) === FILTER_BY.DRAFT
    )
    const publishedBoardsInCollection = data.styleBoards.some(
      (styleBoard) => (styleBoard.state as string) === FILTER_BY.FINISHED
    )

    if (filterParam === FILTER_BY.ALL && draftsInCollection) {
      setHasDrafts(true)
    }
    if (filterParam === FILTER_BY.ALL && publishedBoardsInCollection) {
      setHasFinishedBoards(true)
    }
  }, [data, filterParam])

  useEffect(() => {
    if (hasFinishedBoards && !hasDrafts) {
      setFilterParam(FILTER_BY.FINISHED)
    }
    if (hasDrafts && !hasFinishedBoards) {
      setFilterParam(FILTER_BY.DRAFT)
    }
  }, [data, hasDrafts, hasFinishedBoards])

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    filter: FILTER_BY
  ) => {
    if (filter !== null) {
      setFilterParam(filter)
    }
    setPage(1)
  }

  const goToCreateStyleBoard = () => {
    const attributes = {
      ocpId: customerId
    }
    generateNewRelicLogs(
      'startBoardForCustomerFromCustomerDetailsClick',
      attributes
    )
    history.push({
      pathname: '/review',
      search: `?createFor=${customerId}`
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const [
    abandonCuration,
    {
      endpointName: abandonEndpoint,
      error: abandonError,
      isError: isAbandonCurationError,
      isLoading: isAbandonCurationLoading,
      originalArgs: abandonOriginalArgs,
      isSuccess: isAbandonCurationSuccess
    }
  ] = useAbandonCurationMutation()

  const handleAbandon = async (abandonCurationArgs: AbandonCurationArgsT) => {
    try {
      await abandonCuration(abandonCurationArgs).unwrap()
      refetch()
      const attributes = {
        curationId: abandonCurationArgs.curationId,
        numberOfItemsInBag: Object.keys(abandonCurationArgs.products).length,
        shoppingSessionId: ''
      }
      generateNewRelicLogs('abandonCuration', attributes)
      setCurationIdToDelete(undefined)
      deleteDraftNotes(abandonCurationArgs.curationId)
      deleteDraftOrderedItems(abandonCurationArgs.curationId)
      deleteCustomerDossier(abandonCurationArgs.curationId)
    } catch (error) {
      return
    }
  }

  if (isLoading || isAbandonCurationLoading) {
    return <LoadingSpinner />
  }

  if (!isLoading && isError) {
    return (
      <PageError
        isInlineAlert={true}
        errorTitle={ERROR_TITLE}
        errorDetails={{
          endpoint: endpointName,
          errorData: error,
          identifiers: {
            customerId,
            employeeId
          }
        }}
      />
    )
  }

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: colors.backgroundGrey }}
      >
        <Box
          sx={
            !isMobileDevice
              ? {
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }
              : { marginBottom: '16px' }
          }
        >
          <Box
            sx={{
              textAlign: isMobileDevice ? 'center' : 'right',
              py: '16px',
              flex: 1
            }}
          >
            <Button
              variant="contained"
              fullWidth={true}
              sx={{ maxWidth: '343px', alignContent: 'center' }}
              onClick={goToCreateStyleBoard}
            >
              Create Style Board
            </Button>
          </Box>
          <Typography variant="h6" sx={{ flex: 1 }} textAlign="left">
            Boards by you
          </Typography>
        </Box>
        {isAbandonCurationError && (
          <DeleteDraftAlert
            errorDetails={{
              endpoint: abandonEndpoint,
              errorData: abandonError,
              identifiers: {
                employeeId
              },
              originalArgs: abandonOriginalArgs
            }}
          />
        )}
        {data.styleBoards?.length > 0 ? (
          <>
            <BoardsFilters
              selectedFilter={filterParam}
              handleFilterChange={handleFilterChange}
              hasDrafts={hasDrafts}
              hasPublished={hasFinishedBoards}
            />
            <Box
              sx={{
                paddingBottom: '60px'
              }}
            >
              {filterParam === FILTER_BY.ALL ? (
                <>
                  <StyleBoardsList
                    curations={data.styleBoards}
                    listSubHeaders={BOARDS_SUB_HEADERS}
                    currentPage={page}
                    displayFinishedBoardsSubHeader={
                      firstFinishedBoardIsOnCurrentPage
                    }
                    handleOpenDeleteDraftDialog={setIsDeleteDraftDialogOpen}
                    handleSetCurationIdToDelete={setCurationIdToDelete}
                  />
                </>
              ) : (
                <CurationCardList
                  curations={data.styleBoards}
                  isPublicBoardList={false}
                  isDraft={filterParam === FILTER_BY.DRAFT}
                  handleOpenDeleteDraftDialog={setIsDeleteDraftDialogOpen}
                  handleSetCurationIdToDelete={setCurationIdToDelete}
                />
              )}
              {data.meta.count > PAGE_SIZE && (
                <Pagination
                  count={Math.ceil(data.meta.count / PAGE_SIZE)}
                  page={page}
                  onChange={(event, page) => handlePageChange(page)}
                  sx={{
                    [`& .${paginationClasses.ul}`]: {
                      justifyContent: 'center'
                    }
                  }}
                />
              )}
            </Box>
            {curationIdToDelete && (
              <DeleteDraftDialog
                handleAbandon={() =>
                  handleAbandon({
                    curationId: curationIdToDelete,
                    employeeId,
                    products: [],
                    isCancel: false
                  })
                }
                open={isDeleteDraftDialogOpen}
                setIsDeleteDraftDialogOpen={setIsDeleteDraftDialogOpen}
              />
            )}
            {isAbandonCurationSuccess && (
              <BaseSnackbar message={'Draft deleted.'} />
            )}
          </>
        ) : (
          <Typography>{`Boards you create for ${customerName} will appear here.`}</Typography>
        )}
      </Container>
    </>
  )
}

export default StyleBoards
