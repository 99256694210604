import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Paper
} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useHistory } from 'react-router-dom'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const NORDSTROM_BASE_URL = process.env.REACT_APP_NORDSTROM_URL

export interface IFrameStateT {
  path: string
  hash?: string
  title: string
}
interface PropsT {
  checkoutToken: string
  pathAndTitle: IFrameStateT | undefined
}

const NordstromIFrame = ({
  checkoutToken,
  pathAndTitle: { path, title } = { path: '', title: 'nordstrom.com' }
}: PropsT): JSX.Element => {
  const iFrameSrc = `${NORDSTROM_BASE_URL}/${path}?cspct=${checkoutToken}`

  const history = useHistory()
  const { isPwaNavBarEnabled } = useFeatureFlags()
  const isPwa = window.matchMedia('(display-mode: standalone)').matches

  return (
    <>
      <Container
        disableGutters
        sx={{
          display: 'flex',
          height: '100vh',
          minWidth: '100%'
        }}
      >
        <iframe
          allowFullScreen
          frameBorder="0"
          src={iFrameSrc}
          title={title}
          width="100%"
        ></iframe>
      </Container>
      {isPwa && isPwaNavBarEnabled?.active && (
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={0}
        >
          <BottomNavigation
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <BottomNavigationAction
              aria-label="back-button"
              sx={{ maxWidth: 25 }}
              icon={<ArrowBackIosNewIcon />}
              onClick={() => history.goBack()}
            />
            <BottomNavigationAction
              aria-label="forward-button"
              sx={{ maxWidth: 25 }}
              icon={<ArrowForwardIosIcon />}
              onClick={() => history.goForward()}
            />
          </BottomNavigation>
        </Paper>
      )}
    </>
  )
}

export default NordstromIFrame
