import { ReactElement } from 'react'
import { Box, Drawer } from '@mui/material'
import MessageInputForm from './MessageInputForm'
import { Conversation } from '@twilio/conversations'

const CreateMessageDrawer = ({
  isClientReady,
  conversation
}: {
  isClientReady: boolean
  conversation: Conversation
}): ReactElement => {
  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      open={true}
      hideBackdrop={true}
      ModalProps={{ keepMounted: true }}
      sx={{
        display: 'block',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          display: 'block',
          position: 'inherit'
        },
        width: '100%'
      }}
    >
      <Box
        sx={{
          padding: '16px',
          width: '100%'
        }}
      >
        <MessageInputForm
          isClientReady={isClientReady}
          conversation={conversation}
        />
      </Box>
    </Drawer>
  )
}

export default CreateMessageDrawer
