export const formattedPathName = (path: string): string => {
  const segments = path.split('/').filter(Boolean)

  const transformedSegments = segments.map((segment) =>
    /[0-9]/.test(segment) ? 'id' : segment
  )

  const transformedPath = `/${transformedSegments.join('/')}`

  return transformedPath === '/'
    ? 'homepage'
    : transformedPath.replace(/\//g, '-').slice(1)
}
