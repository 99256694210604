import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import oktaTokenStorage from 'utils/okta-token-utils'
import setStandardHeaders from 'utils/set-standard-headers'

const customErrorHandlerQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const result = await fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TWILIO,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })
      return headers
    }
  })(args, api, extraOptions)
  if (result && result.error) {
    const nordRequestId = result.meta?.request.headers.get('nord-request-id')
    const traceContext = result.meta?.request.headers.get('TraceContext')

    const errorWithMetaData = {
      error: {
        ...result.error,
        errorMetaData: { nordRequestId, traceContext }
      }
    }

    // We don't want endpoints to retry when running unit tests. This section allows to do proper testing.
    if (process.env.NODE_ENV === 'test') {
      retry.fail(result.error)
    }

    return errorWithMetaData
  }

  return result
}

const baseQueryWithRetry = retry(customErrorHandlerQuery, { maxRetries: 3 })

export const twilioApi = createApi({
  reducerPath: 'twilioApi',
  baseQuery: baseQueryWithRetry,
  endpoints: (builder) => ({
    getTwilioToken: builder.query<
      {
        token: string
      },
      { oktaAccessToken: string }
    >({
      query: (arg) => {
        const { oktaAccessToken } = arg
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        return {
          url: `/tokens/${employeeId}`,
          headers: { Authorization: `Bearer ${oktaAccessToken}` }
        }
      },
      transformResponse: (response: { token: string }) => {
        return {
          token: response.token
        }
      }
    }),
    createConversation: builder.mutation<
      {
        conversationSid: string
      },
      { customerId: string }
    >({
      query: (arg) => {
        const { customerId } = arg
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()
        const oktaAccessToken = oktaTokenStorage.getOktaAccessToken()

        return {
          method: 'POST',
          url: `/conversations`,
          headers: { Authorization: `Bearer ${oktaAccessToken}` },
          body: {
            employeeId,
            customerId,
            employeeEmail
          }
        }
      }
    })
  })
})

export const { useLazyGetTwilioTokenQuery, useCreateConversationMutation } =
  twilioApi
