import jwtDecode from 'jwt-decode'

export const getExpirationTimestamp = (token: string): number => {
  const { exp: expirationTimestampInSeconds } =
    jwtDecode<{ exp: number }>(token)

  const expirationTimestamp = expirationTimestampInSeconds * 1000

  return expirationTimestamp
}

export const isTokenExpired = (token: string): boolean => {
  if (!token) {
    return true
  }

  const expirationTimestamp = getExpirationTimestamp(token)
  const isExpirationTimestampInPast = Date.now() > expirationTimestamp

  return isExpirationTimestampInPast
}
