import { ReactElement } from 'react'
import { Box, Link, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import useReportingLinks from 'hooks/useReportingLinks'

const SalesTerms = (): ReactElement => {
  const { fiscalCalendarUrl } = useReportingLinks()

  return (
    <Box
      mt={4}
      p={2}
      color="secondary.main"
      sx={{ backgroundColor: 'grey.50' }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 0.5 }}>
          <InfoIcon />
        </Typography>
        <Typography variant="subtitle2">Sales terms</Typography>
      </Box>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`All: `}
        </Typography>
        unaudited store and style board sales combined
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Style board: `}
        </Typography>
        unaudited sales from your public and customer style boards
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Net: `}
        </Typography>
        total unaudited sales minus returns and canceled items
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Gross: `}
        </Typography>
        total unaudited sales, including shipped items. Does not include returns
        or canceled items
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Fiscal: `}
        </Typography>
        {`Nordstrom's `}
        <Link color="secondary.main" href={fiscalCalendarUrl} target="_blank">
          financial calendar dates
        </Link>
      </Typography>
    </Box>
  )
}

export default SalesTerms
