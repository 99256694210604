import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { TotalPriceRangeT } from 'types/Product'
import { formatProductPrice } from 'utils/formatPrice'

const PriceRange = ({ price }: { price?: TotalPriceRangeT }): ReactElement => {
  const formattedMinPrice = formatProductPrice(
    price?.totalPriceRange?.min.units,
    price?.totalPriceRange?.min.nanos
  )
  const formattedMaxPrice = formatProductPrice(
    price?.totalPriceRange?.max.units,
    price?.totalPriceRange?.max.nanos
  )

  let displayPrice
  if (formattedMinPrice === formattedMaxPrice) {
    displayPrice = `${formattedMinPrice}`
  } else if (formattedMinPrice && formattedMaxPrice) {
    displayPrice = `${formattedMinPrice} - ${formattedMaxPrice}`
  } else if (formattedMinPrice) {
    displayPrice = formattedMinPrice
  } else if (formattedMaxPrice) {
    displayPrice = formattedMaxPrice
  } else {
    displayPrice = 'NO PRICE'
  }

  return (
    <Typography variant="subtitle2" fontWeight="bold">
      {displayPrice}
    </Typography>
  )
}

export default PriceRange
