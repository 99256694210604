import { Box, Chip, Typography } from '@mui/material'
import { ReactElement, memo } from 'react'
import { FilterOptionT, SelectFilterFunctionT } from 'types/FashionMapSearch'

interface PropsT {
  selectedBrandFilters: FilterOptionT[]
  selectedDepartmentFilters: FilterOptionT[]
  selectedProductTypeFilters: FilterOptionT[]
  onSelectBrand: SelectFilterFunctionT
  onSelectDepartment: SelectFilterFunctionT
  onSelectProductType: SelectFilterFunctionT
}

interface BaseChipPropsT {
  filter: string
  onDelete: ({
    currentFilter,
    isSelected
  }: {
    currentFilter: string
    isSelected: boolean
  }) => void
}

const BaseChip = ({ filter, onDelete }: BaseChipPropsT) => {
  return (
    <Chip
      key={filter}
      label={filter}
      data-testid={`${filter}-chip`}
      onDelete={() =>
        onDelete({
          currentFilter: filter,
          isSelected: false
        })
      }
      sx={{ mr: 1, mt: 1 }}
    />
  )
}

const SelectedFilters = (props: PropsT): ReactElement => {
  const {
    onSelectBrand,
    onSelectDepartment,
    onSelectProductType,
    selectedBrandFilters,
    selectedDepartmentFilters,
    selectedProductTypeFilters
  } = props

  const hasFiltersSelected =
    selectedBrandFilters.length > 0 ||
    selectedDepartmentFilters.length > 0 ||
    selectedProductTypeFilters.length > 0

  return (
    <>
      <Typography ml={2} mt={2} fontWeight="bold" variant="subtitle2">
        Filtered by:
      </Typography>
      <Box ml={2} mt={1} mb={2}>
        {!hasFiltersSelected ? (
          <Typography mt={2} variant="body2" color="text.secondary">
            No filters selected
          </Typography>
        ) : (
          <>
            {selectedDepartmentFilters.map((filter) => (
              <BaseChip
                key={filter.id}
                filter={filter.name}
                onDelete={() =>
                  onSelectDepartment({
                    currentFilter: filter.name,
                    isSelected: false
                  })
                }
              />
            ))}
            {selectedProductTypeFilters.map((filter) => (
              <BaseChip
                key={filter.id}
                filter={filter.name}
                onDelete={() =>
                  onSelectProductType({
                    currentFilter: filter.name,
                    isSelected: false
                  })
                }
              />
            ))}
            {selectedBrandFilters.map((filter) => (
              <BaseChip
                key={filter.id}
                filter={filter.name}
                onDelete={() =>
                  onSelectBrand({
                    currentFilter: filter.name,
                    isSelected: false
                  })
                }
              />
            ))}
          </>
        )}
      </Box>
    </>
  )
}

export default memo(SelectedFilters)
