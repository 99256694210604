import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { DcapiFashionPillarsT, DcapiFashionPillarT } from 'types/FashionPillar'
import setStandardHeaders from 'utils/set-standard-headers'

export const dcapiApi = createApi({
  reducerPath: 'dcapiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DCAPI_URL,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })
      headers.set('ApplicationName', 'Shop')
      headers.set('NordApiVersion', '2.0')
      headers.set('NordApiToken', 'StyleProfile')
      headers.set('Nord-Channel-Branch', 'Nordstrom')

      return headers
    }
  }),
  endpoints: (builder) => ({
    getDcapiFashionPillars: builder.query<DcapiFashionPillarT[], void>({
      query: () => ({
        url: `/page/fashion-pillars`
      }),
      transformResponse: (response: DcapiFashionPillarsT) => {
        return response?.Components
      }
    })
  })
})

export const { useGetDcapiFashionPillarsQuery } = dcapiApi
