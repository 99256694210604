import { useGetEditRequestsInQueueQuery } from 'services/curationSvc'
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  CardActions
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Link } from 'react-router-dom'
import { isUSSeller } from 'utils/userPermissions'

const EditRequests = (): JSX.Element => {
  const { data } = useGetEditRequestsInQueueQuery()
  const requestsCount = data?.meta?.count

  if (!requestsCount && isUSSeller()) return <></>

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <CardContent>
        <Box display="flex" mt={1} flexDirection="row">
          <Box
            display="flex"
            height={40}
            width={40}
            alignItems="center"
            justifyContent="center"
            bgcolor="#0E804214"
            borderRadius={50}
            mr={2}
          >
            <EditIcon sx={{ fill: '#0E8042' }} />
          </Box>
          <Box>
            <Typography variant="body2">Your edit requests</Typography>
            <Typography component={Box} variant="h6">
              {requestsCount}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <Button
          component={Link}
          to="/stylist/edit-requests"
          variant="outlined"
          data-testid="edit-requests-view-all"
        >
          View All
        </Button>
      </CardActions>
    </Card>
  )
}

export default EditRequests
