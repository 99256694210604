import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { LegacyONSalesMetricT } from 'types/LegacyONSalesApi'
import { formatPrice } from 'utils/formatPrice'
import moment from 'moment'

type PropsT = {
  legacyONSalesData: LegacyONSalesMetricT
}

const AllSales = (props: PropsT): ReactElement => {
  const { legacyONSalesData } = props

  return (
    <>
      <Typography variant="subtitle2">
        {moment(legacyONSalesData.bus_day_of).format('MMM Do')} - Net sales
      </Typography>
      <Typography sx={{ pt: 1 }} variant="h5">
        {formatPrice.format(legacyONSalesData.ty_daily_net_sales)}
      </Typography>
      <Typography sx={{ pt: 3 }} variant="subtitle2">
        Pay period
      </Typography>
      <Typography sx={{ py: 1 }} variant="h5">
        {formatPrice.format(legacyONSalesData.ty_ptd)}
      </Typography>
      <Typography color="secondary" variant="body2">
        Last year: {formatPrice.format(legacyONSalesData.ly_ptd)}
      </Typography>
      <Typography sx={{ pt: 3 }} variant="subtitle2">
        Year to date
      </Typography>
      <Typography sx={{ py: 1 }} variant="h5">
        {formatPrice.format(legacyONSalesData.ty_ytd)}
      </Typography>
      <Typography color="secondary" variant="body2">
        Last year: {formatPrice.format(legacyONSalesData.ly_ytd)}
      </Typography>
    </>
  )
}

export default AllSales
