import { ReactElement, memo } from 'react'
import FiltersAccordion from '../FiltersAccordion'
import { FilterOptionT } from 'types/FashionMapSearch'

interface PropsT {
  filters: FilterOptionT[]
  onSelectFilter: ({
    currentFilter,
    isSelected
  }: {
    currentFilter: string
    isSelected: boolean
  }) => void
}

const DEPARTMENTS_FILTERS_TITLE = 'Departments'

const DepartmentFilters = (props: PropsT): ReactElement => {
  const { filters, onSelectFilter } = props

  return (
    <FiltersAccordion
      filters={filters}
      onSelect={(e) => {
        onSelectFilter({
          currentFilter: e.target.name,
          isSelected: e.target.checked
        })
      }}
      showSearchBox={false}
      summaryTitle={DEPARTMENTS_FILTERS_TITLE}
    />
  )
}

export default memo(DepartmentFilters)
