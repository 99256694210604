import { ReactElement, useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Typography, Box, Tabs, Tab, Button } from '@mui/material'
import { a11yProps } from 'components/Tabs/TabPanel'
import GridPanel from 'components/Tabs/GridPanel'
import PageError from 'components/PageError'

import {
  useGetCustomerQuery,
  useGetDefaultWishListDataQuery,
  useLazyGetWishListDataByIdQuery
} from 'services/employeeExperienceApi'
import LoadingWishListPreview from './LoadingWishListPreview'
import { skipToken } from '@reduxjs/toolkit/query'
import oktaTokenStorage from 'utils/okta-token-utils'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { TransformedWishList } from 'types/WishList'

type PropsT = {
  customerId: string
  handleClickForPdp: (webstyleId: string) => void
}

const WishListPreview = (props: PropsT): ReactElement => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [selectedWishList, setSelectedWishList] =
    useState<TransformedWishList>()

  const { customerId, handleClickForPdp } = props
  const curationDetails = useAppSelector(curationSelector)
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const wishListsErrorTitle = 'Issue getting Wish Lists.'
  const {
    data: customerData,
    isLoading: isCustomerDataLoading,
    isFetching: isCustomerDataFetching,
    isError: isCustomerDataFetchingError,
    error: customerDataError,
    endpointName: customerDataEndpointName
  } = useGetCustomerQuery(customerId, {
    skip: !customerId
  })

  const {
    data: wishListData,
    isLoading: isWishListDataIsLoading,
    isFetching: isWishListDataIsFetching,
    isError: isWishListDataError,
    error: wishListError,
    endpointName: wistListEndpointName
  } = useGetDefaultWishListDataQuery(
    customerData?.shopperId ? { shopperId: customerData?.shopperId } : skipToken
  )

  const [
    getWishListById,
    { isLoading: isWishListByIdLoading, isFetching: isWishListByIdFetching }
  ] = useLazyGetWishListDataByIdQuery()

  useEffect(() => {
    if (wishListData) {
      setSelectedWishList(wishListData)
    }
  }, [wishListData])

  if (
    isCustomerDataLoading ||
    isCustomerDataFetching ||
    isWishListDataIsLoading ||
    isWishListDataIsFetching ||
    isWishListByIdLoading ||
    isWishListByIdFetching
  ) {
    return <LoadingWishListPreview />
  }

  // A customer has no shopper Id or no items in any wish lists
  const hasNoPopulatedWishLists =
    wishListData &&
    wishListData.items.length === 0 &&
    wishListData.wishLists.length === 1
  const hasNoShopperId = customerData && !customerData.shopperId

  const wishListTitles = wishListData?.wishLists

  const handleChange = async (_event: React.SyntheticEvent, index: number) => {
    const newWishListInfo = wishListData?.wishLists[index]

    if (!newWishListInfo) {
      return
    }

    const newWishList = await getWishListById({
      shopperId: customerData?.shopperId as string,
      wishListId: newWishListInfo.id
    }).unwrap()

    setSelectedWishList(newWishList)
    setSelectedTabIndex(index)
  }

  return (
    <Box>
      <Typography fontWeight="bold">Wish Lists</Typography>
      {isCustomerDataFetchingError || isWishListDataError ? (
        <PageError
          isInlineAlert={true}
          errorTitle={wishListsErrorTitle}
          errorDetails={{
            endpoint: isCustomerDataFetchingError
              ? customerDataEndpointName
              : wistListEndpointName,
            errorData: isCustomerDataFetchingError
              ? customerDataError
              : wishListError,
            identifiers: {
              customerId: customerId,
              curationId: 'NO-DATA',
              employeeId,
              shoppingSessionId: 'NO-DATA'
            }
          }}
        />
      ) : !selectedWishList || hasNoPopulatedWishLists || hasNoShopperId ? (
        <Typography mt={1} variant="body2" color="secondary">
          No Wish Lists
        </Typography>
      ) : (
        <Box>
          <Box>
            <Tabs
              value={selectedTabIndex}
              onChange={handleChange}
              aria-label="WishList Tabs"
            >
              {wishListTitles &&
                wishListTitles.map((wishList, index) => {
                  return (
                    <Tab
                      key={wishList.id}
                      label={wishList.name}
                      {...a11yProps(index)}
                    />
                  )
                })}
            </Tabs>
          </Box>
          <Box>
            <GridPanel
              handleClickForPdp={handleClickForPdp}
              products={selectedWishList.items}
              totalItems={selectedWishList.items.length}
            />
            <Box sx={{ mt: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                component={ReactRouterLink}
                to="/wish-list"
                onClick={() => {
                  const attributes = {
                    curationId: curationDetails?.id,
                    ocpId: customerId,
                    type: curationDetails?.type,
                    preferredEmployeeId: curationDetails?.preferredEmployee,
                    shoppingSessionId: curationDetails?.shoppingSessionId
                  }
                  generateNewRelicLogs('seeMoreWishListsClick', attributes)
                }}
              >
                See More Wish Lists
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default WishListPreview
