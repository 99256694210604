import { MutableRefObject, useEffect, useRef } from 'react'

const useHoldsRefMounted = (): MutableRefObject<boolean> => {
  const refMounted = useRef<boolean>(true)

  useEffect(() => {
    refMounted.current = true

    return () => {
      refMounted.current = false
    }
  }, [])

  return refMounted
}

export default useHoldsRefMounted
