import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { RequestedQueueDataT } from 'pages/CurationHome/CurationHome'

type PropsT = {
  requestedQueueData?: RequestedQueueDataT
}

const CurationCount = (props: PropsT): ReactElement => {
  const { requestedQueueData } = props

  const multipleCurations = requestedQueueData?.count != 1
  const requestText = multipleCurations ? 'requests' : 'request'
  const refreshDate = requestedQueueData?.refreshedAt.format('MMM DD')
  const refreshTime = requestedQueueData?.refreshedAt.format('h:mma')

  if (!requestedQueueData) {
    return <></>
  }

  return (
    <Typography
      textAlign="left"
      mt={1}
      mb={2}
      color="text.secondary"
      variant="subtitle1"
    >
      {`${requestedQueueData?.count} ${requestText} in queue as of ${refreshDate} at ${refreshTime}`}
    </Typography>
  )
}

export default CurationCount
