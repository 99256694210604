import { Box, Container, Stack } from '@mui/material'
import Skeleton from 'components/Skeleton'
import { ReactElement } from 'react'

const LoadingCard = () => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    minHeight={120}
    my={2}
  >
    <Stack direction="row" spacing={2}>
      <Box sx={{ minWidth: '74px' }}>
        <Skeleton.Image aspectRatio={120 / 50} height="100%" />
      </Box>
      <Stack direction="column" spacing={1} width="160px">
        <Skeleton.Text textVariant="body2" width="100%" />
        <Skeleton.Text textVariant="body2" width="100%" />
        <Skeleton.Text textVariant="body2" width="50%" />
        <Skeleton.Text textVariant="body2" width="50%" />
      </Stack>
    </Stack>
    <Stack direction="column" justifyContent="center" width="70px">
      <Skeleton width="100%" height="40px" />
    </Stack>
  </Stack>
)

const RecentItemsLoading = (): ReactElement => {
  return (
    <Container>
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </Container>
  )
}

export default RecentItemsLoading
