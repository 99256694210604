import { ReactElement, ReactNode } from 'react'
import { Button } from '@mui/material'

type PropsT = {
  buttonVariant?: 'text' | 'outlined' | 'contained'
  openModal: () => void
  startIcon?: ReactNode | undefined
  isSizeSmall?: boolean | undefined
}

const OpenSelectBoardTypeButton = (props: PropsT): ReactElement => {
  const {
    openModal,
    buttonVariant = 'contained',
    startIcon = undefined,
    isSizeSmall
  } = props

  return (
    <Button
      startIcon={startIcon}
      variant={buttonVariant}
      fullWidth={true}
      onClick={openModal}
      size={isSizeSmall ? 'small' : 'medium'}
    >
      Create Style Board
    </Button>
  )
}

export default OpenSelectBoardTypeButton
