import oktaTokenStorage from 'utils/okta-token-utils'
// import { checkBrowserClient } from 'utils/userAgentDetector'
// These functions use hide/show the Gladly widget based on the classes in the Gladly.css file
// The gladlyWidget is re created whenever the function is called to avoid
// issues caused by react rerendering the element.

export const hideWidget = (): void => {
  const GladlyWidget = document.getElementById('gladlyChat_container')
  if (GladlyWidget) {
    GladlyWidget.classList.add('hidden')
  }
}

export const showWidget = (): void => {
  const GladlyWidget = document.getElementById('gladlyChat_container')
  if (GladlyWidget) {
    GladlyWidget.classList.remove('hidden')
  }
}

export const setGladlyUser = (): void => {
  if (!window.Gladly.setUser) {
    return
  }

  if (oktaTokenStorage.getOktaAccessToken()) {
    const email = oktaTokenStorage.getEmployeeEmailFromOktaToken()
    const firstName = oktaTokenStorage.getEmployeeFirstNameFromOktaToken()
    const lastName = oktaTokenStorage.getEmployeeLastNameFromOktaToken()

    if (lastName && firstName && email) {
      const name = `${firstName} ${lastName}`
      try {
        window.Gladly.setUser({ name, email })
      } catch (err) {
        return
      }
    } else {
      return
    }
  }
}
