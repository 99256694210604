import { ReactElement, useState } from 'react'
import { isBizAdmin, isDevAdmin } from 'utils/userPermissions'
import {
  Container,
  Typography,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  Switch,
  Link
} from '@mui/material'
import { Redirect } from 'react-router-dom'
import {
  US_SELLERS_AD_GROUP,
  STYLE_ADVISORS_AD_GROUP,
  PERMISSIONS_TESTING_GROUP,
  PERSONAL_STYLISTS_AD_GROUP,
  SPMS_AD_GROUP,
  ASMS_CURATION_APP_GROUP,
  PERMISSIONS_DOCUMENTATION_URL,
  BETA_TEST_USERS_GROUP
} from 'pages/constants'

const PermissionsAdmin = (): ReactElement => {
  const initialChecked =
    window.localStorage.getItem(PERMISSIONS_TESTING_GROUP) ?? 'admin'

  const [checked, setChecked] = useState(initialChecked)
  const prefix = 'switch-list-label-'

  const handleToggle = (value: string) => () => {
    window.localStorage.setItem(PERMISSIONS_TESTING_GROUP, value)
    setChecked(value)
  }

  const isDevOrBizAdmin = isDevAdmin() || isBizAdmin()
  if (!isDevOrBizAdmin) {
    return <Redirect to={'/reporting'} />
  }

  return (
    <Container>
      <Typography variant="h6" mb={'2'} component="h1">
        Permissions Admin
      </Typography>
      <Link
        color="secondary.main"
        variant="body2"
        sx={{ margin: '10px' }}
        href={PERMISSIONS_DOCUMENTATION_URL}
        target="_blank"
        rel="noopener"
      >
        Permissions Groups Documentation
      </Link>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        subheader={<ListSubheader>Settings</ListSubheader>}
      >
        <ListItem>
          <ListItemText
            id={`${prefix}${US_SELLERS_AD_GROUP}`}
            primary="US Sellers"
          />
          <Switch
            edge="end"
            onChange={handleToggle(US_SELLERS_AD_GROUP)}
            checked={checked === US_SELLERS_AD_GROUP}
            inputProps={{
              'aria-labelledby': `${prefix}${US_SELLERS_AD_GROUP}`
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id={`${prefix}${STYLE_ADVISORS_AD_GROUP}`}
            primary="Style Advisors"
          />
          <Switch
            edge="end"
            onChange={handleToggle(STYLE_ADVISORS_AD_GROUP)}
            checked={checked === STYLE_ADVISORS_AD_GROUP}
            inputProps={{
              'aria-labelledby': `${prefix}${STYLE_ADVISORS_AD_GROUP}`
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id={`${prefix}${PERSONAL_STYLISTS_AD_GROUP}`}
            primary="Personal Stylists"
          />
          <Switch
            edge="end"
            onChange={handleToggle(PERSONAL_STYLISTS_AD_GROUP)}
            checked={checked === PERSONAL_STYLISTS_AD_GROUP}
            inputProps={{
              'aria-labelledby': `${prefix}${PERSONAL_STYLISTS_AD_GROUP}`
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id={`${prefix}${SPMS_AD_GROUP}`}
            primary="Stylist Program Managers (SPMs)"
          />
          <Switch
            edge="end"
            onChange={handleToggle(SPMS_AD_GROUP)}
            checked={checked === SPMS_AD_GROUP}
            inputProps={{
              'aria-labelledby': `${prefix}${SPMS_AD_GROUP}`
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id={`${prefix}${ASMS_CURATION_APP_GROUP}`}
            primary="Area Sales Managers (ASMs)"
          />
          <Switch
            edge="end"
            onChange={handleToggle(ASMS_CURATION_APP_GROUP)}
            checked={checked === ASMS_CURATION_APP_GROUP}
            inputProps={{
              'aria-labelledby': `${prefix}${ASMS_CURATION_APP_GROUP}`
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id={`${prefix}${BETA_TEST_USERS_GROUP}`}
            primary="Beta Test Users"
          />
          <Switch
            edge="end"
            onChange={handleToggle(BETA_TEST_USERS_GROUP)}
            checked={checked === BETA_TEST_USERS_GROUP}
            inputProps={{
              'aria-labelledby': `${prefix}${BETA_TEST_USERS_GROUP}`
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemText id={`${prefix}admin`} primary="Personal Book Admins" />
          <Switch
            edge="end"
            onChange={handleToggle('admin')}
            checked={checked === 'admin'}
            inputProps={{
              'aria-labelledby': `${prefix}admin`
            }}
          />
        </ListItem>
      </List>
    </Container>
  )
}

export default PermissionsAdmin
