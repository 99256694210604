import moment from 'moment'
import { BOARD_TYPE_FILTERS } from 'types/CurationAttribution'
import { StartAndEndDatesT } from 'utils/fiscalDates'

export const formatStartAndEndDates = (
  startAndEndDates: StartAndEndDatesT
): Record<string, string> => {
  const returnValue: Record<string, string> = {}
  if (startAndEndDates.start) {
    returnValue.startDate = moment(startAndEndDates.start).format()
  }

  if (startAndEndDates.end) {
    const today = moment().format()
    returnValue.endDate = today
  }

  return returnValue
}

export const shouldSendBoardType = (
  boardType: BOARD_TYPE_FILTERS | null
): boolean => {
  if (
    boardType === BOARD_TYPE_FILTERS.CUSTOMER ||
    boardType === BOARD_TYPE_FILTERS.PUBLIC
  ) {
    return true
  }
  return false
}

export const parseTypeForEmptyAttributionsText = (
  type: BOARD_TYPE_FILTERS
): string => {
  switch (type) {
    case BOARD_TYPE_FILTERS.CUSTOMER:
      return 'customer'
    case BOARD_TYPE_FILTERS.PUBLIC:
      return 'public'
    default:
      return ''
  }
}
