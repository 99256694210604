import { useGetConsentedCustomerPurchaseHistoryQuery } from 'services/clientelingApi'
import { Skeleton } from '@mui/material'
import ConsentedCustomerTripsContent from './ConsentedCustomerTripsContent'
import ConsentedCustomerTripsError from './ConsentedCustomerTripsError'

type PropsT = {
  customerId: string
}

const ConsentedCustomerTripsListItem = ({
  customerId
}: PropsT): JSX.Element => {
  const { data, isLoading, isError, error, endpointName } =
    useGetConsentedCustomerPurchaseHistoryQuery({
      customerId
    })

  const numberOfTrips = data?.numberOfTrips ?? 0

  if (isError) {
    return (
      <ConsentedCustomerTripsError
        errorDetails={{
          errorData: error,
          endpoint: endpointName,
          identifiers: {
            customerId
          }
        }}
      />
    )
  }

  return isLoading ? (
    <Skeleton
      sx={{
        marginLeft: '16px'
      }}
    >
      <ConsentedCustomerTripsContent numberOfTrips={numberOfTrips} />
    </Skeleton>
  ) : (
    <ConsentedCustomerTripsContent numberOfTrips={numberOfTrips} />
  )
}

export default ConsentedCustomerTripsListItem
