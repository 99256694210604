import { ReactElement } from 'react'
import { Stack, Container, Grid, Divider } from '@mui/material'
import Skeleton from 'components/Skeleton'

const SkeletonPurchase = () => (
  <Container>
    <Stack direction="column" mt={2} spacing={2} width={'100%'}>
      <Skeleton.Text textVariant={'h4'} />
      <Skeleton.Text textVariant={'body2'} />
      <Grid container rowSpacing={2} columns={4}>
        {[...Array(4)].map((_, index) => (
          <Grid item xs={1} key={index} pr={2}>
            <Skeleton.Image aspectRatio={6 / 9} />
          </Grid>
        ))}
      </Grid>
      <Skeleton.Text textVariant={'h4'} width={'100%'} />
    </Stack>
  </Container>
)

const LoadingPurchasesList = (): ReactElement => (
  <>
    <SkeletonPurchase />
    <Divider sx={{ mt: '12px' }} />
    <SkeletonPurchase />
  </>
)

export default LoadingPurchasesList
