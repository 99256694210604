import { ChevronLeft } from '@mui/icons-material'
import {
  AppBar,
  Box,
  IconButton,
  SvgIcon,
  Toolbar,
  Typography
} from '@mui/material'
import { ReactElement } from 'react'

interface PropsT {
  handleBackClick?: () => void
  icon?: { component: React.ComponentType<typeof SvgIcon>; ariaLabel: string }
  title: string
}

const SubPageAppBar = ({
  handleBackClick,
  icon,
  title
}: PropsT): ReactElement => {
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: 'background.default',
        boxShadow: 0
      }}
    >
      <Toolbar>
        <IconButton
          aria-label={icon?.ariaLabel ? icon?.ariaLabel : 'back'}
          onClick={handleBackClick}
        >
          {icon?.component ? (
            icon.component
          ) : (
            <ChevronLeft
              sx={{ color: 'common.black' }}
              fontSize="large"
              data-testid="backButton"
            />
          )}
        </IconButton>
        <Box display="flex" alignItems="center" ml={2}>
          <Typography color="text.primary" component="h1" variant="h6">
            {title}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default SubPageAppBar
