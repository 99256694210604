import { ReactElement } from 'react'
import { Box } from '@mui/material'
import { baseSkeletonStyling } from '../../constants'

export type PropsT = {
  aspectRatio: number
  height?: string
}

const ImageSkeleton = (props: PropsT): ReactElement => {
  const { aspectRatio, height } = props

  return (
    <Box
      width="100%"
      pb={`${(1 / aspectRatio) * 100}%`}
      display="inline-block"
      data-testid="skeleton"
      height={height}
      sx={{
        ...baseSkeletonStyling
      }}
    />
  )
}

export default ImageSkeleton
