import { MouseEventHandler, ReactElement, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { CurationT, AbandonCurationArgsT } from 'types/Curation'
import ResumeCurationButton from 'components/ResumeCurationButton'
import LoadingCurationCard from '../LoadingCurationCard'
import FinishedBoardButtons from '../FinishedBoardButtons'
import BaseDialog from '../BaseDialog'
import FinishedBoardProductImages from '../FinishedBoardProductImages'
import {
  generateNewRelicLogs,
  mapCurationToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import CurationViewsAndOrders from 'components/CurationViewsAndOrders'
import { determineCurationTitle } from 'utils/curationCardHelpers'

type CurationCardPropsT = {
  curation: CurationT
  isDraft: boolean
  unpublishCuration?: (curationId: number) => void
  handleAbandon?: (abandonCurationArgs: AbandonCurationArgsT) => void
  handleOpenDeleteDraftDialog?: (dialogOpen: boolean) => void
  handleSetCurationIdToDelete?: (curationId: number) => void
}

type AnchorElementT = EventTarget & Element

const dialogTitle = 'Are you sure you want to delete this public board?'
const dialogContent = (curationTitle: string) =>
  `"${curationTitle}" will no longer be shown on your profile and will be deleted forever.`

const PublicBoardCard = (props: CurationCardPropsT): ReactElement => {
  const history = useHistory()
  const [menuAnchor, setMenuAnchor] = useState<AnchorElementT | undefined>(
    undefined
  )
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const curationForLogging = useMemo(
    () => mapCurationToNewRelicLog(props.curation),
    [props.curation]
  )

  const editCuration = () => {
    generateNewRelicLogs('editCurationClick', {
      ...curationForLogging,
      numberOfItemsInBag: products?.length
    })
    history.push({ pathname: `/styleboards/${curationId}/edit` })
  }

  const onNevermindClick = () => {
    generateNewRelicLogs('unpublishNevermindClick', curationForLogging)
    setIsDeleteDialogOpen(false)
  }

  const onUnpublishCuration = () => {
    unpublishCuration && unpublishCuration(curationId)
    generateNewRelicLogs('unpublishPublicBoardClick', {
      ...curationForLogging,
      numberOfItemsInBag: products?.length
    })
    setIsDeleteDialogOpen(false)
  }

  const {
    curation: {
      completedAt: curationCompletedAt,
      id: curationId,
      title: curationTitle,
      products,
      updatedAt: curationUpdatedAt,
      views,
      orders,
      state: curationState
    },
    isDraft = false,
    unpublishCuration,
    handleOpenDeleteDraftDialog,
    handleSetCurationIdToDelete
  } = props

  const hasTitle = determineCurationTitle({ curationState, curationTitle })

  const handleOpenMenu: MouseEventHandler = (event) => {
    setMenuAnchor(event.currentTarget)
    setIsMenuOpen(true)
  }

  const handleCloseMenu: MouseEventHandler = () => {
    setMenuAnchor(undefined)
    setIsMenuOpen(false)
  }

  const CurationCardContent = () => {
    return (
      <>
        <Typography variant="body2">
          {new Date(
            isDraft ? curationUpdatedAt : curationCompletedAt
          ).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })}
        </Typography>
        <Box
          sx={{
            pt: 3
          }}
        >
          <FinishedBoardProductImages products={products} />
        </Box>
      </>
    )
  }

  return (
    <>
      <Box width={'100%'}>
        <Card variant={'outlined'}>
          <CardHeader
            subheader={null}
            subheaderTypographyProps={{
              variant: 'body2',
              color: 'text.primary'
            }}
            title={
              determineCurationTitle({ curationState, curationTitle }) ||
              'No title'
            }
            titleTypographyProps={{
              variant: 'subtitle2',
              fontWeight: 'bold',
              color: hasTitle ? 'text.primary' : 'text.secondary'
            }}
            action={
              <IconButton
                onClick={handleOpenMenu}
                aria-label={'open-curation-card-menu-button'}
              >
                <MoreVertIcon />
              </IconButton>
            }
            sx={{
              pb: 0
            }}
          />
          <CardContent
            sx={{
              variant: 'body2',
              color: 'text.secondary',
              pt: 0
            }}
          >
            {<CurationCardContent />}
          </CardContent>
          <Stack direction="row">
            <CardContent sx={{ justifyContent: 'flex-start', width: '25%' }}>
              {!isDraft && (
                <CurationViewsAndOrders orders={orders} views={views} />
              )}
            </CardContent>
            <CardActions
              sx={{
                justifyContent: 'flex-end',
                width: '75%'
              }}
            >
              {!isDraft && <FinishedBoardButtons curationId={curationId} />}
              {isDraft && <ResumeCurationButton curationId={curationId} />}
            </CardActions>
          </Stack>
        </Card>
      </Box>
      <Menu
        open={isMenuOpen}
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
        aria-label={'curation-card-menu'}
      >
        {!isDraft && (
          <MenuList>
            <MenuItem onClick={editCuration} aria-label={'edit-curation'}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Edit</Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(_) => {
                setIsDeleteDialogOpen(true)
                handleCloseMenu(_)
              }}
              aria-label={'delete-curation'}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Delete</Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        )}
        {isDraft && handleSetCurationIdToDelete && handleOpenDeleteDraftDialog && (
          <MenuList>
            <MenuItem
              onClick={(_) => {
                handleSetCurationIdToDelete(curationId)
                handleOpenDeleteDraftDialog(true)
                handleCloseMenu(_)
              }}
              aria-label={'delete-draft-curation-button'}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Delete</Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        )}
      </Menu>
      {!isDraft && unpublishCuration && (
        <BaseDialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          title={dialogTitle}
          contentText={dialogContent(curationTitle)}
          confirm={{
            action: onUnpublishCuration,
            text: 'Yes, Delete'
          }}
          cancel={{
            action: onNevermindClick,
            text: 'Nevermind'
          }}
        />
      )}
    </>
  )
}

PublicBoardCard.Loading = LoadingCurationCard

export default PublicBoardCard
