export const HOLDS_TEXT_TITLE = 'Holds'
export const HOLDS_TEXT_CREATE_NEW_HOLD = 'Create new hold'
export const HOLDS_TEXT_FIND_EXISTING_HOLD = 'Find existing hold'
export const HOLDS_TEXT_ADD_CUSTOMER = 'Add customer'

export const HOLDS_TEXT_ADD_ITEM = 'Add item'
export const HOLDS_TEXT_EDIT_ITEM = 'Edit items'

export const HOLDS_TEXT_FOUND = 'Holds found'
export const HOLDS_TEXT_HOLD_DETAILS = 'Hold details'
export const HOLDS_TEXT_UPDATE_HOLD_STATUS = 'Update hold status'
export const HOLDS_TEXT_PRINT_TICKET = 'Print ticket'

export const HOLDS_TEXT_HOLD_CREATED = 'Hold created.'
export const HOLDS_TEXT_HOLD_NOT_CREATED = 'Hold not created.'

export const HOLDS_TEXT_ITEM_ADDED_TO_HOLD = 'Item added to hold.'
export const HOLDS_TEXT_ITEM_REMOVED = 'Item removed.'
export const HOLDS_TEXT_UNDO = 'UNDO'

export const HOLDS_TEXT_HOLD_TICKET_REPRINTED = 'Hold ticket reprinted.'
export const HOLDS_TEXT_HOLD_TICKET_NOT_REPRINTED = 'Hold ticket not reprinted.'

export const HOLDS_TEXT_HOLD_STATUS_UPDATED = 'Hold status updated.'
export const HOLDS_TEXT_HOLD_STATUS_NOT_UPDATED = 'Hold status not updated.'

export const HOLDS_TEST_CUSTOMER_PROFILE_INCORRECT =
  'If this customer profile is incorrect, call 1.800.964.1800.'

export const HOLDS_TEXT_CUSTOMER_RESULTS = 'Customer Results'

export const HOLDS_STYLE_SNACKBAR_ALERT_SUCCESS = {
  backgroundColor: '#e7f2ec',
  color: '#14351E'
}

export const HOLDS_STYLE_SNACKBAR_ALERT_ERROR = {
  backgroundColor: '#fbe9ec',
  color: '#541A1C'
}

export const HOLDS_COLOR_ARROW = '#1F21228A'

export const HOLDS_COLOR_TEXT_UNDO = '#8484fb'

export const HOLDS_BACKGROUND_COLOR_HOLD_CARD_WHITE = '#FFF'
export const HOLDS_BACKGROUND_COLOR_DIVIDER = '#F9FAFA'
export const HOLDS_COLOR_DIVIDER = '#6B777B'
export const HOLDS_COLOR_ASTERISK = '#D6243D'
export const HOLDS_STYLE_LABEL_ASTERISK = {
  '.MuiFormLabel-asterisk': {
    color: HOLDS_COLOR_ASTERISK
  }
}
export const HOLDS_STYLE_LABEL_ASTERISK_WITH_FOCUSED = {
  '.Mui-focused .MuiFormLabel-asterisk': {
    color: HOLDS_COLOR_ASTERISK
  }
}
export const HOLDS_STYLE_ERROR_LABEL_COLOR_PRIMARY = {
  '&.Mui-error': {
    color: 'text.primary'
  }
}

export const HOLDS_API_PARAMS_GET_ACTIVE_LIST = {
  status: 'ACTIVE',
  sort: 'expiresAt',
  direction: 'ASC'
}

export const HOLDS_API_PARAMS_GET_COMPLETED_LIST = {
  status: 'COMPLETED,EXPIRED',
  sort: 'completedAt',
  direction: 'DESC'
}

export const HOLDS_MEDIA_MAX_WIDTH_CREATE_HOLD_AND_EDIT_ITEMS = 736
export const HOLDS_MEDIA_MAX_WIDTH_ADD_CUSTOMER = 375
export const HOLDS_MEDIA_SM_WIDTH_ADD_ITEM_AND_FIND_EXISTING_HOLD = 590
export const HOLDS_MEDIA_SM_WIDTH_CUSTOMER_FOUND_AND_REPRINT = 400

export const HOLDS_ADD_MAX_LINE_ITEM_LIMIT = 20

export const LINE_ITEM_LIMIT_REACHED_SNACKBAR_TITLE = 'Item limit reached'
export const LINE_ITEM_LIMIT_REACHED_SNACKBAR_MESSAGE =
  'You are at the max allowed items for this hold. You will need to remove items first before you can scan any other items.'

export const HOLDS_LINE_ITEM_LIMIT_REACHED_SNACKBAR_ERROR_TITLE =
  'More items can’t be added'
export const HOLDS_LINE_ITEM_LIMIT_REACHED_SNACKBAR_ERROR_MESSAGE =
  'You are at the max allowed items for this hold. You will need to remove items first before you can add any other items.'

export const HOLDS_PRINTER_COVER_OPEN_TITLE = 'Printer cover open.'
export const HOLDS_PRINTER_COVER_OPEN_MESSAGE =
  'Close the printer cover and try again.'
export const HOLDS_PRINTER_OFFLINE_TITLE = 'Printer is offline.'
export const HOLDS_PRINTER_OFFLINE_MESSAGE =
  'Make sure the printer is on and try again.'
export const HOLDS_PRINTER_NO_PAPER_TITLE = 'Printer receipt roll empty.'
export const HOLDS_PRINTER_NO_PAPER_MESSAGE =
  'Replace the printer receipt roll and try again.'
export const HOLDS_PRINTER_NOT_AVAILABLE_TITLE = 'Printer not available.'
export const HOLDS_PRINTER_OUT_OF_TONER_TITLE = 'Printer out of toner.'
export const HOLDS_PRINTER_STATUS_UNKNOWN_TITLE = 'Printer status unknown.'
export const HOLDS_PRINTER_NOT_CONFIGURED_TITLE = 'Printer not configured.'
export const HOLDS_POS_GENERAL_EXCEPTION_TITLE = 'POS General exception.'
