import { BudgetRangeT, SizesT } from 'types/Curation'
import { sortCategories } from 'utils/clothingCategoryHelper'

export const formatSizesAndBudgetRange = (
  object: SizesT | BudgetRangeT
): Array<{
  key: string
  value: Array<string | number> | string
}> => {
  const formattedSizesAndBudget = Object.entries(object).map(([key, value]) => {
    return { key, value }
  })
  formattedSizesAndBudget.sort(sortCategories)

  return formattedSizesAndBudget
}
