import { ReactElement } from 'react'

import {
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'

import { HOLDS_STYLE_LABEL_ASTERISK } from 'pages/Holds/constants'
import { HOLDS_TYPE_LABELS, HoldsType } from 'types/Holds'

const HoldsTypeRadioGroup = ({
  value,
  onChange,
  error
}: {
  value: unknown
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: HoldsType.QUICK_HOLDS | HoldsType.PRE_SALE
  ) => void
  error?: boolean
}): ReactElement => (
  <FormControl
    fullWidth
    error={error}
    sx={{
      '.MuiFormHelperText-root': {
        marginLeft: 0
      }
    }}
  >
    <FormLabel
      required
      id="hold-type"
      sx={{
        ...HOLDS_STYLE_LABEL_ASTERISK,
        color: 'text.primary',
        '&.Mui-error': {
          color: 'text.primary'
        },
        mb: 1.5
      }}
    >
      <strong>Hold type</strong>
    </FormLabel>

    <RadioGroup
      row
      aria-labelledby="hold-type"
      name="holdType"
      value={value}
      onChange={(event, value) =>
        onChange(event, value as HoldsType.QUICK_HOLDS | HoldsType.PRE_SALE)
      }
      sx={{
        gap: {
          xs: 4,
          sm: 13.5
        }
      }}
    >
      <FormControlLabel
        value={HoldsType.QUICK_HOLDS}
        control={<Radio />}
        label={HOLDS_TYPE_LABELS[HoldsType.QUICK_HOLDS]}
        sx={{
          mx: 0
        }}
      />
      <FormControlLabel
        value={HoldsType.PRE_SALE}
        control={<Radio />}
        label={HOLDS_TYPE_LABELS[HoldsType.PRE_SALE]}
        sx={{
          mx: 0
        }}
      />
    </RadioGroup>

    {error && (
      <Fade in={error}>
        <FormHelperText>Hold type is required</FormHelperText>
      </Fade>
    )}
  </FormControl>
)

export default HoldsTypeRadioGroup
