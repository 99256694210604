import { ReactElement } from 'react'
import { emDashCharacter } from 'pages/constants'
import PageError from 'components/PageError'

interface PropsT {
  error: unknown
}

export const FASHION_MAP_ERROR_TITLE = `Issue getting search results ${emDashCharacter} try again`

const FashionMapSearchError = (props: PropsT): ReactElement => {
  const { error } = props

  return (
    <PageError
      errorTitle={FASHION_MAP_ERROR_TITLE}
      errorDetails={{
        endpoint: 'fashionMapSearch',
        errorData: error
      }}
      isErrorTitlePersonalized={true}
      isFullWidth={true}
      isInlineAlert={true}
    />
  )
}

export default FashionMapSearchError
