import { ReactElement } from 'react'
import { Button, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type PropsT = {
  onClick: () => void
  isFilterSelected: boolean
}

const StoreAvailabilityButton = ({
  onClick,
  isFilterSelected
}: PropsT): ReactElement => {
  return (
    <Button
      variant={isFilterSelected ? 'contained' : 'outlined'}
      color="secondary"
      onClick={onClick}
      style={{
        marginBottom: '40px'
      }}
    >
      <Typography fontWeight={'bold'} marginRight="8px">
        Store Availability
      </Typography>
      <ExpandMoreIcon />
    </Button>
  )
}

export default StoreAvailabilityButton
