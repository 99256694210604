import { Box, Button, Typography } from '@mui/material'
import ItemImage from 'components/ItemImage'
import LoadingSpinner from 'components/LoadingSpinner'
import PriceRange from 'pages/FashionMapSearchDrawer/components/PriceRange'
import Carousel from 'pages/Home/components/Carousel'
import { useGetPolarisSearchResultsQuery } from 'services/employeeExperienceApi'
import { RecommendationDetailsT } from 'types/SeedItemRecommendations'
import LaunchIcon from '@mui/icons-material/Launch'
import PageError from 'components/PageError'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { ProductGenderValues } from 'types/Checkout'
import { RECS_ERROR_TITLE } from './ItemRecommendations'

interface PropsT {
  recommendation: RecommendationDetailsT
  onClickForPDP: (webStyleId: string) => void
  curationId: number
  productGender?: ProductGenderValues
}

const CategoryCarousel = ({
  recommendation,
  onClickForPDP,
  curationId,
  productGender
}: PropsT): JSX.Element => {
  const { data, endpointName, error, isLoading, isError, refetch } =
    useGetPolarisSearchResultsQuery({
      curationId,
      searchTerm: recommendation.searchTerms[0],
      minPrice: recommendation.priceRange?.min,
      maxPrice: recommendation.priceRange?.max,
      productGender
    })

  const isMobile = checkBrowserClient.isMobile()

  if (isError) {
    return (
      <PageError
        errorDetails={{ endpoint: endpointName, errorData: error }}
        errorTitle={RECS_ERROR_TITLE}
        refreshAction={refetch}
      />
    )
  }

  if (isLoading) {
    return <LoadingSpinner hideText={true} />
  }

  return (
    <Carousel displayArrows={!isMobile} closeSpacing={true}>
      {data &&
        data.products &&
        data.products.map((item, index) => (
          <Box key={index} sx={{ width: '100px' }}>
            <Button
              aria-label="view product details"
              onClick={() =>
                item?.webStyleId && onClickForPDP(item.webStyleId.toString())
              }
              sx={{ maxWidth: '74px', padding: 0 }}
              startIcon={
                <LaunchIcon
                  sx={{
                    position: 'absolute',
                    top: '0',
                    right: '-2px',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    padding: '5px',
                    width: '1.5em',
                    height: 'auto',
                    opacity: '0.85'
                  }}
                />
              }
            >
              <ItemImage imageUrl={item.imgUrl} height={100} width={100} />
            </Button>
            <Typography
              variant={'body2'}
              my={0.5}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                maxWidth: '90px'
              }}
            >
              {item.brandName}
            </Typography>
            <PriceRange price={item.price} />
          </Box>
        ))}
    </Carousel>
  )
}

export default CategoryCarousel
