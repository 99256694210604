import { useMediaQuery, useTheme } from '@mui/material'

const useHoldsMediaDesktop = (): Readonly<{
  isDesktop: boolean
}> => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return {
    isDesktop
  } as const
}

export default useHoldsMediaDesktop
