export enum ValidationTitleError {
  MISSING = 'MISSING',
  TOO_LONG = 'TOO_LONG'
}

export enum ValidationDescriptionError {
  TOO_LONG = 'TOO_LONG'
}

export enum ValidationItemError {
  TOO_MANY = 'TOO_MANY',
  ZERO = 'ZERO'
}

export enum ValidationDuplicateItemError {
  DUPLICATE_SKUS = 'DUPLICATE_SKUS'
}

export enum ValidationStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
  IN_PROGRESS = 'IN_PROGRESS'
}

export interface ValidationResultT {
  descriptionError?: ValidationDescriptionError
  itemError?: ValidationItemError | ValidationDuplicateItemError
  itemLimit?: number
  titleError?: ValidationTitleError
  validationId: string
  validationStatus: ValidationStatus
}
