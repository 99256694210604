import { Box, Typography } from '@mui/material'
import FinishedBoardProductImages from 'components/FinishedBoardProductImages'
import { OfferProductT } from 'types/Curation'

const CurationCardContent = ({
  isDraft,
  curationUpdatedAt,
  curationCompletedAt,
  products
}: {
  isDraft: boolean
  curationUpdatedAt: Date
  curationCompletedAt: Date
  products: OfferProductT[]
}): JSX.Element => {
  return (
    <>
      <Typography variant="body2">
        {new Date(
          isDraft ? curationUpdatedAt : curationCompletedAt
        ).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })}
      </Typography>
      <Box
        sx={{
          pt: 3
        }}
      >
        <FinishedBoardProductImages products={products} />
      </Box>
    </>
  )
}

export default CurationCardContent
