import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import nordstromAvatar from 'assets/NAvatar.png'
import nordstromBanner from 'assets/NBanner.png'
import { ReactElement } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { EmployeeT } from 'types/Employee'
import { GetStylistProfilesQueryDataT } from 'types/StylistProfile'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'

type PropsT = {
  open: boolean
  handleClose: () => void
  employeeInviteLink: string
  onClickCopyLink: () => void
  stylistData: GetStylistProfilesQueryDataT | undefined
  employeeData: { employee: EmployeeT } | undefined
  didFeatureFlagsLoad: boolean
}

const QrModal = ({
  open,
  handleClose,
  employeeInviteLink,
  onClickCopyLink,
  stylistData,
  employeeData,
  didFeatureFlagsLoad
}: PropsT): ReactElement => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        sx={{
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          zIndex: 2147483200 // zIndex for sending the gladly widget behind the dialog
        }}
      >
        <DialogTitle id="dialog-title" sx={{ p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '8px'
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                marginLeft: 'auto',
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            overflow: 'hidden'
          }}
        >
          <CardMedia
            component="img"
            height="140"
            image={nordstromBanner}
            alt="Nordstrom banner"
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              top: '-120px',
              zIndex: 1
            }}
          >
            <Avatar
              src={stylistData?.stylistProfiles[0]?.imageUrl || nordstromAvatar}
              sx={{
                width: '95px',
                height: '95px',
                backgroundColor: 'text.primary',
                borderRadius: '50%'
              }}
              alt={
                stylistData?.stylistProfiles[0]?.imageUrl
                  ? 'profile picture'
                  : 'nordstrom image'
              }
            />
          </Box>
          <Card
            sx={{
              position: 'relative',
              top: '-165px',
              left: '50%',
              transform: 'translateX(-50%)',
              maxWidth: '272px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }}
          >
            <CardContent sx={{ mt: '59.5px', p: 0 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Typography variant="subtitle2">
                    {stylistData?.stylistProfiles[0]?.displayName ||
                      employeeData?.employee?.preferredName ||
                      ''}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    {employeeData?.employee?.mobile
                      ? formatPhoneNumber(employeeData?.employee?.mobile)
                      : 'No saved number'}
                  </Typography>
                </Stack>
                {employeeData?.employee && (
                  <Box>
                    <QRCode
                      value={employeeInviteLink + '&linkSource=qrCode'}
                      quietZone={0}
                      size={162}
                    />
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
          <DialogActions
            sx={{
              justifyContent: 'center',
              top: '-146px',
              position: 'relative'
            }}
          >
            <Button
              variant="contained"
              sx={{ fontSize: '16px', backgroundColor: 'text.secondary' }}
              color="secondary"
              disabled={!didFeatureFlagsLoad}
              onClick={onClickCopyLink}
            >
              Copy Invitation Link
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default QrModal
