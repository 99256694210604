//https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900

export const formatCurationOrderCount = (
  orders: number | undefined
): string => {
  const numberOfOrders = Number(orders)

  switch (true) {
    case !orders: {
      return '0'
    }
    case numberOfOrders > 999999: {
      return `${Math.floor(Math.abs(numberOfOrders) / 1000000)}` + 'm'
    }
    case numberOfOrders > 9999: {
      return `${Math.floor(Math.abs(numberOfOrders) / 1000)}` + 'k'
    }
    default: {
      return `${Math.abs(numberOfOrders)}`
    }
  }
}
