import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import BaseSnackbar from 'components/BaseSnackbar'

interface PropsT {
  productName?: string
}

const AddItemSuccessSnackbar = ({ productName }: PropsT): ReactElement => {
  return (
    <BaseSnackbar
      message={
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
        >
          Added {productName ? productName : 'item'}
        </Typography>
      }
    />
  )
}

export default AddItemSuccessSnackbar
