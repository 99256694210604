import { Grid, Typography } from '@mui/material'
import { ReactElement } from 'react'
import LocalMallIcon from '@mui/icons-material/LocalMall'

const EmptyCurationMessage = (): ReactElement => {
  return (
    <Grid container item flexDirection="column" alignItems="center" mb={2}>
      <Grid item mb={3}>
        <LocalMallIcon />
      </Grid>
      <Grid item mb={2}>
        <Typography align="center" variant="body2">
          Add or edit items from Nordstrom.com
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EmptyCurationMessage
