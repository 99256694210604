import { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { CUSTOMER_SORT_KEYS, CustomerSortKeysT } from './utils'

type PropsT = {
  isOpen: boolean
  handleClose: () => void
  setSortFunctionKey: Dispatch<SetStateAction<CustomerSortKeysT>>
  defaultKey: CustomerSortKeysT
  isLastMessageSortEnabledActive: boolean
}
const isMobileDevice = checkBrowserClient.isMobile()
export const SORT_STORAGE_KEY = 'sortStorageKey'

const SortModal = ({
  isOpen,
  handleClose,
  setSortFunctionKey,
  defaultKey,
  isLastMessageSortEnabledActive
}: PropsT): ReactElement => {
  const [selected, setSelected] = useState<CustomerSortKeysT>(defaultKey)

  const onCancel = () => {
    handleClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value as CustomerSortKeysT)
  }

  const handleApply = () => {
    setSortFunctionKey(selected)
    storeSortFunctionKey(selected)
    handleClose()
  }

  const storeSortFunctionKey = (selectedOption: CustomerSortKeysT) =>
    sessionStorage.setItem(SORT_STORAGE_KEY, selectedOption)

  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          paddingBottom: isMobileDevice ? '55%' : 0
        }}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Typography
              fontWeight={'bold'}
              fontSize="23px"
              paddingRight={isMobileDevice ? '150px' : '438px'}
            >
              Sort By
            </Typography>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingLeft: isMobileDevice ? '14%' : '50px',
            maxWidth: '300px'
          }}
        >
          <FormControl>
            <RadioGroup defaultValue={defaultKey} onChange={handleChange}>
              <FormControlLabel
                value={CUSTOMER_SORT_KEYS.byAlphabetical}
                label={
                  <Typography fontSize="16px">Customer name: A-Z</Typography>
                }
                control={<Radio edge="start" />}
              />
              {isLastMessageSortEnabledActive && (
                <>
                  <FormControlLabel
                    value={CUSTOMER_SORT_KEYS.byOldestMessages}
                    label={
                      <Typography fontSize="16px">Messages: older</Typography>
                    }
                    control={<Radio edge="start" />}
                  />
                  <FormControlLabel
                    value={CUSTOMER_SORT_KEYS.byNewestMessages}
                    label={
                      <Typography fontSize="16px">Messages: newer</Typography>
                    }
                    control={<Radio edge="start" />}
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApply}>
            <Typography fontWeight={'bold'}>Apply</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SortModal
