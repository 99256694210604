import orderBy from 'lodash/fp/orderBy'
import { ArchivedCustomerT } from 'types/ConsentedCustomer'

const firstNameToSort = (customer: ArchivedCustomerT) =>
  customer.firstName
    ? customer.firstName.toLowerCase()
    : customer.email?.toLowerCase() || ''
const lastNameToSort = (customer: ArchivedCustomerT) =>
  customer.lastName
    ? customer.lastName.toLowerCase()
    : customer.email?.toLowerCase() || ''

export const orderArchivedCustomerList = (
  customerList: ArchivedCustomerT[]
): ArchivedCustomerT[] =>
  orderBy<ArchivedCustomerT>(
    [firstNameToSort, lastNameToSort],
    ['asc', 'asc']
  )(customerList)
