import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'

const NO_NEW_CURATIONS_MESSAGE = 'No new requests to start'

const NoNewCurationsAlert = (): ReactElement => {
  return (
    <BasePageAlert
      errorMessage={NO_NEW_CURATIONS_MESSAGE}
      isDismissable={true}
    />
  )
}

export default NoNewCurationsAlert
