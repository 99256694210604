import { useAppSelector } from 'app/hooks'
import Box from '@mui/material/Box'
import SmsIcon from '@mui/icons-material/Sms'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Badge from '@mui/material/Badge'
import NavMenuItem from 'components/NavMenuItem'
import { twilioSelectors } from 'services/twilioSlice'
import { List } from '@mui/material'

type PropsT = {
  selectedIndex: number | null
  setSelectedIndex: (index: number) => void
  goToPath: ({ path, index }: { path: string; index: number }) => void
  totalMainMenuOptions: number
}

const CustomerBookSubNav = (props: PropsT): JSX.Element => {
  const { selectedIndex, goToPath, totalMainMenuOptions } = props

  const hasUnreadMessages = useAppSelector(
    twilioSelectors.hasUnreadMessagesSelector
  )

  const CustomerBookSubMenuOptions = [
    {
      title: 'Invite customers',
      path: '/customers/invite',
      icon: <PersonAddIcon />
    },

    {
      title: 'Messages',
      path: '/messages',
      icon: (
        <Badge
          data-testid="messages-badge"
          color="primary"
          variant="dot"
          invisible={!hasUnreadMessages}
        >
          <SmsIcon />
        </Badge>
      )
    }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch'
      }}
    >
      <List sx={{ width: '100%', paddingBottom: 0, paddingTop: 0 }} dense>
        {CustomerBookSubMenuOptions.map((subNavOption, index) => {
          // This subNavIndex is generated from the total options + subnav index
          // to give the subnav a unique index from the main menu options. If more
          // submenus are added we may need to centralize this logic. 2023/08/08
          const subNavIndex = totalMainMenuOptions + index
          const { path } = subNavOption
          const isSelected = selectedIndex
            ? subNavIndex === selectedIndex
            : window.location.pathname.startsWith(path)
          return (
            subNavOption && (
              <NavMenuItem
                key={subNavOption.title}
                icon={subNavOption.icon}
                title={subNavOption.title}
                path={path}
                index={subNavIndex}
                goToPath={() =>
                  goToPath({
                    path: subNavOption.path,
                    index: subNavIndex
                  })
                }
                isSelected={isSelected}
                customStyle={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '30px'
                }}
              />
            )
          )
        })}
      </List>
    </Box>
  )
}

export default CustomerBookSubNav
