import { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { v4 } from 'uuid'
import {
  useCompleteEditMutation,
  useGetCheckoutPageQuery,
  useGetEditCurationDataQuery,
  useRemoveFromBagMutation
} from 'services/curationSvc'
import CurationEdit from './CurationEdit'
import {
  CURATION_EDIT_LOCAL_STORAGE_KEY,
  initialStoredState
} from './CurationEditReducer'
import { useAppDispatch } from 'app/hooks'
import { resetCuration, setCuration } from 'app/curationSlice'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import isSellerInitiatedPrivateBoard from 'utils/isSellerInitiatedPrivateBoard'
import oktaTokenStorage from 'utils/okta-token-utils'
import { StoredEditStateT } from 'types/Curation'
import { useRetrieveShoppingTokenForEditMutation } from 'services/employeeExperienceApi'

type ParamType = {
  curationId: string
}

const CurationEditWrapper = (): ReactElement => {
  const { curationId }: ParamType = useParams()
  const appDispatch = useAppDispatch()

  const [storedState] = useLocalStorage<StoredEditStateT>(
    CURATION_EDIT_LOCAL_STORAGE_KEY,
    initialStoredState
  )

  const {
    data: getEditCurationData,
    isLoading: isGetEditCurationDataLoading,
    error: getEditCurationDataError,
    isError: isGetEditCurationDataError,
    originalArgs: getEditCurationDataOriginalArgs,
    endpointName: getEditCurationDataEndpointName
  } = useGetEditCurationDataQuery({
    curationId: Number(curationId)
  })

  const shouldUseStoredSessionId =
    getEditCurationData?.curation.id === storedState?.inEditCurationId

  const editShoppingSessionId = useMemo(
    () =>
      shouldUseStoredSessionId ? storedState?.editShoppingSessionId : v4(),
    [shouldUseStoredSessionId, storedState?.editShoppingSessionId]
  )

  const [fetchToken, fetchTokenStatus] =
    useRetrieveShoppingTokenForEditMutation()

  const {
    endpointName: fetchTokenEndpoint,
    error: fetchTokenError,
    isError: isFetchTokenError,
    originalArgs: fetchTokenOriginalArgs,
    data: fetchTokenData
  } = fetchTokenStatus

  const checkoutPageResult = useGetCheckoutPageQuery(
    {
      shopperId: editShoppingSessionId || '',
      token: fetchTokenData?.token || ''
    },
    {
      refetchOnFocus: true,
      skip: !editShoppingSessionId || !fetchTokenData?.token
    }
  )

  useEffect(() => {
    if (getEditCurationData && editShoppingSessionId) {
      fetchToken({
        curationId: Number(curationId),
        shoppingSessionId: editShoppingSessionId
      })
    }
  }, [curationId, editShoppingSessionId, fetchToken, getEditCurationData])

  const refreshToken = useCallback(
    ({
      editShoppingSessionId,
      curationId
    }: {
      editShoppingSessionId: string
      curationId: number
    }) => {
      fetchToken({
        curationId,
        shoppingSessionId: editShoppingSessionId
      }).unwrap()
    },
    [fetchToken]
  )

  useEffect(() => {
    if (getEditCurationData?.curation) {
      const { id, preferredEmployeeId, shoppingSessionId, type } =
        getEditCurationData.curation
      appDispatch(
        setCuration({ id, preferredEmployeeId, shoppingSessionId, type })
      )
    }
    return () => {
      appDispatch(resetCuration())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditCurationData])

  const [removeItems, removeFromBagMutationStatus] = useRemoveFromBagMutation()
  const [completeEdit, completeEditMutationStatus] = useCompleteEditMutation()

  const { isSuccess: isCompleteEditSuccess } = completeEditMutationStatus

  useEffect(() => {
    if (isCompleteEditSuccess) {
      localStorage.removeItem(CURATION_EDIT_LOCAL_STORAGE_KEY)
    }
  }, [isCompleteEditSuccess])

  if (isFetchTokenError) {
    return (
      <PageError
        errorDetails={{
          endpoint: fetchTokenEndpoint,
          errorData: fetchTokenError,
          originalArgs: fetchTokenOriginalArgs
        }}
      />
    )
  }

  if (isGetEditCurationDataError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getEditCurationDataEndpointName,
          errorData: getEditCurationDataError,
          originalArgs: getEditCurationDataOriginalArgs,
          identifiers: {
            curationId,
            employeeId: oktaTokenStorage
              .getEmployeeNumberFromOktaToken()
              .toString(),
            shoppingSessionId: storedState?.editShoppingSessionId
          }
        }}
      />
    )
  }

  if (
    isGetEditCurationDataLoading ||
    !getEditCurationData ||
    !editShoppingSessionId
  ) {
    return <LoadingSpinner />
  }

  return (
    <>
      {fetchTokenData?.token && editShoppingSessionId && (
        <CurationEdit
          editCurationData={getEditCurationData}
          shoppingSession={{
            token: fetchTokenData.token,
            id: editShoppingSessionId
          }}
          checkoutPageResult={checkoutPageResult}
          fetchTokenMutation={{ fetchToken, refreshToken, fetchTokenStatus }}
          removeFromBagMutation={{ removeItems, removeFromBagMutationStatus }}
          completeEditMutation={{ completeEdit, completeEditMutationStatus }}
        />
      )}
      {isCompleteEditSuccess &&
        isSellerInitiatedPrivateBoard(getEditCurationData.curation) && (
          <Redirect
            to={{
              pathname: '/',
              state: {
                editCurationSuccessMessage:
                  'Style board updated & link copied. Let your customer know you made changes.'
              }
            }}
          />
        )}
      {isCompleteEditSuccess &&
        !isSellerInitiatedPrivateBoard(getEditCurationData.curation) && (
          <Redirect
            to={{
              pathname: '/',
              state: {
                editCurationSuccessMessage: 'Style board updated.'
              }
            }}
          />
        )}
    </>
  )
}

export default CurationEditWrapper
