import { ReactElement, memo } from 'react'
import { Divider } from '@mui/material'
import DepartmentFilters from '../DepartmentFilters'
import ProductTypeFilters from '../ProductTypeFilters'
import { SelectFilterFunctionT, FilterOptionT } from 'types/FashionMapSearch'
import BrandsFilters from '../BrandsFilters'
import SelectedFilters from '../SelectedFilters'

interface PropsT {
  brandFilters: FilterOptionT[]
  departmentFilters: FilterOptionT[]
  productTypeFilters: FilterOptionT[]
  onSelectBrand: SelectFilterFunctionT
  onSelectDepartment: SelectFilterFunctionT
  onSelectProductType: SelectFilterFunctionT
}

const FilterOptions = (props: PropsT): ReactElement => {
  const {
    brandFilters,
    departmentFilters,
    productTypeFilters,
    onSelectBrand,
    onSelectDepartment,
    onSelectProductType
  } = props

  return (
    <>
      <SelectedFilters
        onSelectDepartment={onSelectDepartment}
        onSelectProductType={onSelectProductType}
        onSelectBrand={onSelectBrand}
        selectedBrandFilters={brandFilters.filter(
          (option) => option.isSelected
        )}
        selectedDepartmentFilters={departmentFilters.filter(
          (option) => option.isSelected
        )}
        selectedProductTypeFilters={productTypeFilters.filter(
          (option) => option.isSelected
        )}
      />
      <Divider />
      <DepartmentFilters
        filters={departmentFilters}
        onSelectFilter={onSelectDepartment}
      />
      <Divider />
      <ProductTypeFilters
        filters={productTypeFilters}
        onSelectFilter={onSelectProductType}
      />
      <Divider />
      <BrandsFilters filters={brandFilters} onSelectFilter={onSelectBrand} />
      <Divider />
    </>
  )
}

export default memo(FilterOptions)
