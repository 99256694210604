import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { PriceDetailsT } from 'types/Checkout'
import { ANNIVERSARY_ENTICEMENTS, EnticementDetailsT } from 'types/Product'
import { formatPrice } from 'utils/formatPrice'

enum PriceColors {
  ANNIVERSARY_YELLOW = '#fbe446',
  SALE_RED = '#d6243d'
}

interface PropsT {
  enticements?: EnticementDetailsT[]
  price: PriceDetailsT
}

const ItemPrice = ({
  price,
  isSalePrice = false,
  isAnniversaryItem = false,
  shouldStrikeThrough = false
}: {
  price: number
  isSalePrice?: boolean
  isAnniversaryItem?: boolean
  shouldStrikeThrough?: boolean
}): ReactElement => {
  return (
    <Typography
      component="span"
      color={isSalePrice ? PriceColors.SALE_RED : 'text.primary'}
      fontWeight={shouldStrikeThrough ? 'normal' : 'inherit'}
      fontSize="inherit"
      sx={{
        backgroundColor: isAnniversaryItem
          ? PriceColors.ANNIVERSARY_YELLOW
          : 'transparent',
        textDecoration: shouldStrikeThrough ? 'line-through' : 'none'
      }}
    >
      {formatPrice.format(price)}
    </Typography>
  )
}

const PriceBlock = (props: PropsT): ReactElement | null => {
  const { enticements, price } = props

  const isAnniversaryItem = !!enticements?.find((enticement) =>
    Object.values<string>(ANNIVERSARY_ENTICEMENTS).includes(enticement.type)
  )

  if (isAnniversaryItem) {
    const shouldShowOriginalPrice =
      price.original && price.current !== price.original

    return (
      <Typography
        data-testid="anniversary-price"
        fontWeight="bold"
        variant="body2"
        noWrap
      >
        {price.current && (
          <ItemPrice price={price.current} isAnniversaryItem={true} />
        )}{' '}
        {shouldShowOriginalPrice && price.original && (
          <ItemPrice price={price.original} shouldStrikeThrough={true} />
        )}
      </Typography>
    )
  }

  const isSalePrice =
    price.current && price.original && price.current !== price.original

  if (isSalePrice) {
    return (
      <Typography
        data-testid="sale-price"
        fontWeight="bold"
        variant="body2"
        noWrap
      >
        {price.current && (
          <ItemPrice price={price.current} isSalePrice={true} />
        )}{' '}
        {price.original && (
          <ItemPrice price={price.original} shouldStrikeThrough={true} />
        )}
      </Typography>
    )
  }

  if (price?.current) {
    return (
      <Typography
        data-testid="regular-price"
        fontWeight="bold"
        variant="body2"
        noWrap
      >
        <ItemPrice price={price?.current} />
      </Typography>
    )
  }

  return null
}

export default PriceBlock
