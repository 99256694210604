import { HoldEnumStatusType } from 'pages/Holds/types'

import {
  HOLDS_API_PARAMS_GET_ACTIVE_LIST,
  HOLDS_API_PARAMS_GET_COMPLETED_LIST
} from 'pages/Holds/constants'

//there may be more types than this
export enum HoldsType {
  QUICK_HOLDS = 'QUICK_HOLDS',
  PRE_SALE = 'PRE_SALE',
  STYLING_APPOINTMENT = 'STYLING_APPOINTMENT',
  NORDSTROM_TO_YOU = 'NORDSTROM_TO_YOU',
  CUSTOMER_SPECIAL_ORDER = 'CUSTOMER_SPECIAL_ORDER',
  DESIGNER_RESERVATIONS = 'DESIGNER_RESERVATIONS'
}

//there may be more statuses than this
export enum HoldsStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED'
  // CANCELED = 'CANCELED'
}

export const HOLD_DEFAULT_EXPIRATION_LENGTHS = {
  [HoldsType.QUICK_HOLDS]: 0,
  [HoldsType.PRE_SALE]: 7,
  [HoldsType.STYLING_APPOINTMENT]: 0,
  [HoldsType.NORDSTROM_TO_YOU]: 0,
  [HoldsType.CUSTOMER_SPECIAL_ORDER]: 4,
  [HoldsType.DESIGNER_RESERVATIONS]: 1
}

export const HOLDS_COMPLETION_PREFIXES_BY_STATUS = {
  [HoldsStatus.ACTIVE]: 'Ends',
  [HoldsStatus.EXPIRED]: 'Expired',
  [HoldsStatus.COMPLETED]: 'Completed'
}

export const HOLDS_TYPE_LABELS = {
  [HoldsType.QUICK_HOLDS]: 'Quick Hold',
  [HoldsType.PRE_SALE]: 'Pre-Sale Hold',
  [HoldsType.STYLING_APPOINTMENT]: 'Styling Appointments',
  [HoldsType.NORDSTROM_TO_YOU]: 'Nordstrom To You',
  [HoldsType.CUSTOMER_SPECIAL_ORDER]: 'Customer Special Order',
  [HoldsType.DESIGNER_RESERVATIONS]: 'Designer Reservations'
}

export type Customer = {
  customerId: string
  firstName: string
  lastName: string
  phoneNumber: string
}

export type Employee = {
  employeeId: string
  firstName: string
  lastName: string
  preferredName: string
}

export type Store = {
  name: string
  number: number
  addressLine: string
  postalCode: string
  city: string
  countryCode: string
  stateCode: string
  phoneNumber: string
}

export type LineItem = {
  upc: string
  sku: string
}

export type Product = {
  classNumber: number
  deptNumber: number
  price: number
  sku: string
  upc: string
  brand: string
  name: string
  color: string
  size: string
  imageUrl: string
}

export type HoldsCustomer = {
  customerId: string
  firstName: string
  lastName: string
  phoneNumber: string
}

export type StoreFormData = {
  employeeId: string
  storeNumber: number
}

export type HoldFormData = {
  customerId: string
  customerName: string
  customerPhoneNumber: string
  employeeId: string
  storeNumber: number
  type: string
  expiresAt: string
  lineItems: LineItem[]
  itemsUpcs: string[]
  itemsSku: string[]
}

export type ProductFetched = {
  id: number
  sku: string
  upc: string
  status: `${HoldEnumStatusType}`
  globalLineItemId: string
  brand: string
  productName: string
  color: string
  size: string
  imageUrl: string
  completedAt?: string
  completedBy?: {
    employeeId: string
    firstName: string
    lastName: string
    preferredName: string
  }
}

export type HoldT = {
  ticketNumber: string
  type: `${HoldsType}`
  customerId: string
  customer: Customer
  employeeId: string
  employee: Employee
  store: Store
  status: `${HoldsStatus}`
  expiresAt: string
  completedAt?: string
  createdAt?: string
  lineItems: ProductFetched[]
}

export type CreateHoldsUnavailableItemsResponse = {
  ticketNumber: null
  unavailableLineItems: LineItem[] | null
}

export type CreateHoldResponse = {
  ticketNumber: string
  unavailableLineItems: null
}

export type PrintHoldResponseT = { status: string }

export type GetHoldsQueryDataT = {
  data: HoldT[]
  total: number
}

export type GetHoldsQueryDataParamsT =
  | typeof HOLDS_API_PARAMS_GET_ACTIVE_LIST
  | typeof HOLDS_API_PARAMS_GET_COMPLETED_LIST

export type HoldsApiGetStoresFindResponse = {
  name: string
  number: number
  addressLine: string
  postalCode: string
  city: string
  countryCode: string
  stateCode: string
  phoneNumber: string
}

export type HoldsProductLineItemComplete = Pick<ProductFetched, 'id' | 'status'>

export type HoldsProductCompleteRequest = {
  ticketNumber: string
  lineItems: HoldsProductLineItemComplete[]
}

export type HoldsGetHoldsByTicketNumberRequest = {
  ticketNumber: string
}
