import { ReactElement } from 'react'
import { Container } from '@mui/material'
import FinishedBoardsContent from './components/FinishedBoardsContent'
import SubPageTemplateWithPageAppBar from 'components/SubPageTemplateWithPageAppBar'

const FINISHED_BOARDS_TITLE = 'Finished boards'

const FinishedBoards = (): ReactElement => {
  return (
    <SubPageTemplateWithPageAppBar title={FINISHED_BOARDS_TITLE}>
      <Container sx={{ mb: 3 }}>
        <FinishedBoardsContent />
      </Container>
    </SubPageTemplateWithPageAppBar>
  )
}

export default FinishedBoards
