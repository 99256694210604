import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { FashionPillarItem } from './FashionPillarItem'
import OtherStyles from '../OtherStyles'
import { useGetPasFashionPillarQuery } from 'services/employeeExperienceApi'
import { useGetDcapiFashionPillarsQuery } from 'services/dcapiService'
import { LoadingFashionPillar } from './LoadingFashionPillar'
import { findPillar } from './utils'
import PageError from 'components/PageError'

export type PropsT = {
  customerId: string
}

const FashionPillar = (props: PropsT): ReactElement => {
  const { customerId } = props

  const errorTitle = 'Issue getting Fashion Pillars.'

  const {
    data: pasFashionPillarsData,
    error: pasError,
    isError: isPasError,
    isLoading: pasLoading,
    endpointName: fashionPillarEndpointName
  } = useGetPasFashionPillarQuery(customerId)

  const {
    data: dcapiFashionPillarsData,
    isLoading: dcapiPillarsLoading,
    isError: dcapiPillarsError
  } = useGetDcapiFashionPillarsQuery()

  const ErrorMessage = () => (
    <PageError
      isInlineAlert={true}
      errorTitle={errorTitle}
      errorDetails={{
        endpoint: fashionPillarEndpointName,
        errorData: pasError,
        identifiers: {
          customerId: customerId,
          curationId: 'NO-DATA',
          employeeId: 'NO-DATA',
          shoppingSessionId: 'NO-DATA'
        }
      }}
    />
  )

  const NoStylePreference = () => (
    <>
      <Typography variant="subtitle2" mt="12px">
        Styles
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        style={{ fontSize: '1.125rem' }}
      >
        No style preference set
      </Typography>
    </>
  )

  if (pasLoading || dcapiPillarsLoading) {
    return <LoadingFashionPillar />
  }
  if (isPasError || dcapiPillarsError) {
    if (pasError && 'status' in pasError && pasError.status === 404) {
      return <NoStylePreference />
    }
    return <ErrorMessage />
  }

  if (pasFashionPillarsData) {
    const primaryDcapiPillar = findPillar(
      dcapiFashionPillarsData,
      pasFashionPillarsData.primaryPillar?.id
    )

    if (!primaryDcapiPillar) return <></>

    const primaryTitle = primaryDcapiPillar.title
    const primaryDescription = primaryDcapiPillar.description
    const primaryImage = primaryDcapiPillar.image
    const primaryPercent = pasFashionPillarsData.primaryPillar?.percent
    const otherPillars = pasFashionPillarsData.otherPillars

    const otherStyles = otherPillars.map((pasPillar) => ({
      pasPillar,
      dcapiPillar: findPillar(dcapiFashionPillarsData, pasPillar.id)
    }))
    const showOtherStyles = otherPillars && otherStyles.length > 0

    return (
      <>
        <Typography variant="subtitle2" mt="12px">
          Styles
        </Typography>
        <Box mb={1} mt={2}>
          <Typography display="inline" mb={0.5} variant="body2">
            {primaryTitle}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            display="inline"
            pl={1}
          >
            {primaryPercent}%
          </Typography>
        </Box>
        <Box mt={2}>
          {primaryDescription && primaryImage && (
            <FashionPillarItem
              description={primaryDescription}
              image={primaryImage}
            />
          )}
        </Box>

        {showOtherStyles && <OtherStyles otherPillars={otherStyles} />}
      </>
    )
  } else {
    return <ErrorMessage />
  }
}

export default FashionPillar
