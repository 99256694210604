import { ReactElement } from 'react'
import { Modal, Box, Typography, List, ListItem } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import { AddressT } from 'types/ConsentedCustomer'
import { colors } from 'pages/CustomerDetails/constants'
import { formatCustomerAddressForModal } from 'pages/CustomerDetails/utils'
import { checkBrowserClient } from 'utils/userAgentDetector'

type PropsT = {
  isModalOpen: boolean
  setIsModalOpen: (x: boolean) => void
  defaultShippingAddress?: AddressT
  standardShippingAddresses?: Array<AddressT>
}

type AddressListItemPropsT = {
  isDefaultShippingAddress: boolean
  address: AddressT
}

const AddressListItem = ({
  isDefaultShippingAddress,
  address
}: AddressListItemPropsT): ReactElement => (
  <ListItem
    divider
    sx={{
      flexDirection: 'column',
      alignItems: 'baseline',
      py: '12px'
    }}
  >
    {isDefaultShippingAddress && (
      <Typography variant="subtitle2">Primary shipping address</Typography>
    )}
    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
      {formatCustomerAddressForModal(address)}
    </Typography>
  </ListItem>
)

const AddressesModal = ({
  isModalOpen,
  setIsModalOpen,
  defaultShippingAddress,
  standardShippingAddresses
}: PropsT): ReactElement => {
  const isMobileDevice = checkBrowserClient.isMobile()
  return (
    <Modal open={isModalOpen}>
      <>
        <Box
          sx={{
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: '12px',
              py: '8px',
              flexShrink: 0
            }}
          >
            <Box
              sx={{
                display: 'flex',
                p: '12px',
                mr: '16px'
              }}
            >
              <ArrowBackIosIcon
                data-testid="address-modal-close-icon"
                sx={{
                  color: colors.primary,
                  ':hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => setIsModalOpen(false)}
              />
            </Box>
            <Typography variant="h6">Addresses</Typography>
          </Box>
          <List
            sx={{ p: 0, mx: !isMobileDevice ? '32px' : 0, overflow: 'scroll' }}
          >
            {defaultShippingAddress && (
              <AddressListItem
                address={defaultShippingAddress}
                isDefaultShippingAddress={true}
                key="default-shipping-address"
              />
            )}
            {standardShippingAddresses?.map((address, index) => (
              <AddressListItem
                address={address}
                isDefaultShippingAddress={false}
                key={`address-${index}`}
              />
            ))}
          </List>
        </Box>
      </>
    </Modal>
  )
}

export default AddressesModal
