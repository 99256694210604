export const NOTES_STORAGE_KEY = 'draft_notes_storage'

export const saveDraftNotes = ({
  curationId,
  notes
}: {
  curationId: number
  notes: Record<string, string>
}): undefined => {
  const draftNotes = JSON.parse(localStorage.getItem(NOTES_STORAGE_KEY) || '{}')
  const newDraftNotes = {
    ...draftNotes,
    [curationId]: notes
  }
  localStorage.setItem(NOTES_STORAGE_KEY, JSON.stringify(newDraftNotes))
  return
}

export const loadDraftNotes = (
  curationId: number
): Record<string, string> | undefined => {
  return JSON.parse(localStorage.getItem(NOTES_STORAGE_KEY) || '{}')[curationId]
}

export const deleteDraftNotes = (curationId: number): undefined => {
  const draftNotes = JSON.parse(localStorage.getItem(NOTES_STORAGE_KEY) || '{}')
  delete draftNotes[curationId]
  localStorage.setItem(NOTES_STORAGE_KEY, JSON.stringify(draftNotes))
  return
}
