import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@mui/material'

type PropsT = {
  onClose: () => void
  isOpen: boolean
  customerName: string
  archiveCustomer: (value: boolean) => void
}

const ArchiveCustomerModal: (props: PropsT) => JSX.Element = (
  props: PropsT
) => {
  const { archiveCustomer, isOpen, customerName, onClose } = props
  return (
    <Dialog open={isOpen} onClose={onClose} data-testid="archivedCustomerModal">
      <DialogTitle>Are you sure you want to archive this customer?</DialogTitle>
      <DialogContent sx={{ paddingBottom: '12px' }}>
        <Typography variant="body2">{`${customerName} will be moved to your archived list in customer book.`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Nevermind</Button>
        <Button onClick={() => archiveCustomer(true)} autoFocus>
          Yes, Archive
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ArchiveCustomerModal
