import { ReactElement, ReactNode } from 'react'
import { List, ListItem, ListSubheader, Typography } from '@mui/material'
import CurationCard from 'components/CurationCard'
import { CurationT, AbandonCurationArgsT, CURATION_TYPE } from 'types/Curation'
import PublicBoardCard from '../PublicBoardCard'

type ChildrenPropsT = {
  children: ReactNode
}
type ListHeaderT = {
  listHeader?: string
}

type BaseCurationCardListT = ChildrenPropsT & ListHeaderT

type CurationCardListPropsT = ListHeaderT & {
  curations: CurationT[]
  isDraft?: boolean
  hideMenu?: boolean
  isPublicBoardList: boolean
  unpublishCuration?: (curationId: number) => void
  handleAbandon?: (abandonCurationArgs: AbandonCurationArgsT) => void
  handleOpenDeleteDraftDialog?: (dialogOpen: boolean) => void
  handleSetCurationIdToDelete?: (curationId: number) => void
}

const BaseCurationCardList = ({
  listHeader,
  children
}: BaseCurationCardListT) => {
  return (
    <List
      subheader={
        listHeader ? (
          <ListSubheader
            color={'inherit'}
            disableSticky
            disableGutters
            sx={{ mb: 1 }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{listHeader}</Typography>
          </ListSubheader>
        ) : null
      }
    >
      {children}
    </List>
  )
}
const CurationCardListItem = ({ children }: ChildrenPropsT) => {
  return <ListItem disableGutters>{children}</ListItem>
}

const CurationCardList = (props: CurationCardListPropsT): ReactElement => {
  const {
    curations,
    isDraft = false,
    hideMenu,
    isPublicBoardList,
    listHeader,
    unpublishCuration,
    handleAbandon,
    handleOpenDeleteDraftDialog,
    handleSetCurationIdToDelete
  } = props

  return (
    <BaseCurationCardList listHeader={listHeader}>
      {curations.map((curation) => {
        const shouldShowInCustomerTab =
          curation.type === CURATION_TYPE.CUSTOMER_INITIATED ||
          (curation.type === CURATION_TYPE.SELLER_INITIATED && curation.ocpId)

        return (
          <CurationCardListItem key={curation.id}>
            {!isPublicBoardList && shouldShowInCustomerTab && (
              <CurationCard
                curation={curation}
                isDraft={isDraft}
                hideMenu={hideMenu}
                handleAbandon={handleAbandon}
                handleOpenDeleteDraftDialog={handleOpenDeleteDraftDialog}
                handleSetCurationIdToDelete={handleSetCurationIdToDelete}
              />
            )}
            {isPublicBoardList && (unpublishCuration || isDraft) && (
              <PublicBoardCard
                curation={curation}
                unpublishCuration={unpublishCuration}
                isDraft={isDraft}
                handleAbandon={handleAbandon}
                handleOpenDeleteDraftDialog={handleOpenDeleteDraftDialog}
                handleSetCurationIdToDelete={handleSetCurationIdToDelete}
              />
            )}
          </CurationCardListItem>
        )
      })}
    </BaseCurationCardList>
  )
}

export default CurationCardList
