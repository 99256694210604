import {
  Box,
  Card,
  Typography,
  CardContent,
  IconButton,
  useTheme
} from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowIcon from '@mui/icons-material/ArrowForward'
import PushPinIcon from '@mui/icons-material/PushPin'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

interface PropsT {
  isPublicBoard: boolean
}

const InProgressRequest = (props: PropsT): JSX.Element => {
  const theme = useTheme()
  const { isPublicBoard } = props

  const handleClickLog = () => {
    generateNewRelicLogs('pickupWhereYouLeftOffClick')
  }

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F0EEFD'
      }}
    >
      <CardContent sx={{ paddingLeft: 4 }}>
        <Box display="flex">
          <Box display="flex" alignItems="center" mr={3}>
            <PushPinIcon sx={{ fill: theme.palette.primary.main }} />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">
              Pick up where you left off
            </Typography>
            <Typography variant="body2">
              {isPublicBoard ? 'Public board' : 'Style board for customer'}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <Box display="flex" pr={3} alignItems="center">
        <IconButton
          onClick={handleClickLog}
          aria-label="arrow"
          component={Link}
          to="/review"
        >
          <ArrowIcon sx={{ fill: theme.palette.primary.main }} />
        </IconButton>
      </Box>
    </Card>
  )
}

export default InProgressRequest
