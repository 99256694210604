import { ReactElement, useEffect, useRef, useState } from 'react'
import {
  Container,
  Stack,
  Divider,
  Typography,
  paginationClasses,
  Pagination
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { customerIdSelector } from 'app/customerSlice'
import RecentPurchasesItem from '../../components/RecentPurchasesItem'
import SnapshotNavigation from '../../components/SnapshotNavigation'
import PageError from 'components/PageError'
import LoadingPurchasesList from './LoadingPurchasesList'

import { useGetPurchaseHistoryQuery } from 'services/employeeExperienceApi'
import { OrderT, PHOrderSchemaT } from 'types/Orders'
import useWindowDimensions from 'utils/useWindowDimensions'
import oktaTokenStorage from 'utils/okta-token-utils'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PURCHASE_DETAILS
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

type PropsT = {
  customerId?: string
  hideSnapshotNavigation?: boolean
  sourcePath: string
}

const PAGE_NUMBER_KEY = 'pageNumberKey'
const SCROLL_POSITION_KEY = 'purchaseHistoryScrollPosition'

const RecentPurchasesList = (props: PropsT): ReactElement => {
  const stackRef = useRef<HTMLDivElement>(null)
  const { hideSnapshotNavigation } = props
  const customerIdFromProps = props.customerId || ''
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const history = useHistory()
  const ORDER_COUNT_OFFSET = 10
  const [page, setPage] = useState(1)

  const purchasesErrorTitle = 'Issue getting purchases.'
  const customerIdFromState = useAppSelector(customerIdSelector)
  const customerId = customerIdFromProps || customerIdFromState
  const pageKey = `${customerId}/${PAGE_NUMBER_KEY}`
  const schema: PHOrderSchemaT = {
    customerId,
    orderCount: ORDER_COUNT_OFFSET,
    skip: (page - 1) * ORDER_COUNT_OFFSET
  }
  const {
    data: phResponse,
    isLoading,
    isFetching,
    isError,
    error,
    endpointName
  } = useGetPurchaseHistoryQuery(schema)

  const { height } = useWindowDimensions()

  const storeScrollPosition = () => {
    if (stackRef.current) {
      sessionStorage.setItem(
        SCROLL_POSITION_KEY,
        String(stackRef.current.scrollTop)
      )
    }
  }

  const goToDetails = (orderId: string) => {
    // From Customer Details Page
    hideSnapshotNavigation &&
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PURCHASE_DETAILS,
        {
          page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES,
          ocpId: customerId,
          orderId
        }
      )
    storeScrollPosition()
    history.push({
      pathname: `/purchase-history/order/${orderId}`,
      state: {
        customerId: customerId || customerIdFromProps,
        page
      }
    })
  }

  useEffect(() => {
    if (phResponse?.orders.length) {
      const scrollPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
      const pageInStorage = sessionStorage.getItem(pageKey)
      if (scrollPosition && stackRef.current) {
        stackRef.current.scrollTop = Number(scrollPosition)
        sessionStorage.removeItem(SCROLL_POSITION_KEY)
      }
      if (pageInStorage) {
        setPage(Number(pageInStorage))
      }
    }
  }, [phResponse?.orders, pageKey])

  useEffect(() => {
    window.addEventListener('beforeunload', storeScrollPosition)
  }, [])

  const NoOrders = () => (
    <Container>
      <Typography my="12px" mt={1} variant="body2" textAlign="left">
        No purchases
      </Typography>
    </Container>
  )

  if (isLoading || isFetching) {
    return <LoadingPurchasesList />
  }

  if (!phResponse?.orders?.length && page === 1 && !isError) {
    return <NoOrders />
  }

  // Controlled navigation to take user to Snapshot homepage.
  const handleBackButtonClick = () => {
    history.push('')
  }

  const onPageChange = (page: number) => {
    sessionStorage.setItem(pageKey, page.toString())
    setPage(page)
  }

  return (
    <>
      {!hideSnapshotNavigation && (
        <Container>
          <SnapshotNavigation
            title="Purchases"
            onClick={handleBackButtonClick}
          />
        </Container>
      )}
      {!isError && phResponse ? (
        <div
          style={{
            maxHeight: hideSnapshotNavigation ? height - 176 : height,
            position: 'relative',
            overflow: 'auto'
          }}
          ref={stackRef}
        >
          <Divider
            style={{ background: '#F5F5F5', border: 'none', height: 16 }}
          />
          <Stack
            divider={
              <Divider
                style={{ background: '#F5F5F5', border: 'none', height: 16 }}
              />
            }
            spacing={2}
            my={2}
          >
            {phResponse.orders.map((order: OrderT) => (
              <RecentPurchasesItem
                customerId={customerId}
                key={order.orderId}
                order={order}
                goToDetails={goToDetails}
                fromSnapShot={!hideSnapshotNavigation}
              />
            ))}
          </Stack>
          {phResponse?.actualToDateOrders > ORDER_COUNT_OFFSET && (
            <Pagination
              count={Math.ceil(
                phResponse?.actualToDateOrders / ORDER_COUNT_OFFSET
              )}
              page={page}
              onChange={(event, page) => onPageChange(page)}
              sx={{
                [`& .${paginationClasses.ul}`]: {
                  justifyContent: 'center'
                },
                marginBottom: '20px'
              }}
              data-testid={`paginationComponent-${Math.ceil(
                phResponse?.actualToDateOrders / ORDER_COUNT_OFFSET
              )}`}
            />
          )}
        </div>
      ) : (
        <PageError
          isInlineAlert={true}
          errorTitle={purchasesErrorTitle}
          errorDetails={{
            endpoint: endpointName,
            errorData: error,
            identifiers: {
              customerId: customerId,
              curationId: 'NO-DATA',
              employeeId,
              shoppingSessionId: 'NO-DATA'
            }
          }}
        />
      )}
    </>
  )
}

export default RecentPurchasesList
