import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isBetaTestUser, isStyleAdvisor } from 'utils/userPermissions'

const useCurationFashionMapFeatures = (): boolean => {
  const { isFashionMapBetaTestEnabled, isFashionMapSearchEnabled } =
    useFeatureFlags()

  return (
    (isStyleAdvisor() && isFashionMapSearchEnabled?.active) ||
    (isBetaTestUser() && isFashionMapBetaTestEnabled?.active)
  )
}

export default useCurationFashionMapFeatures
