import { ReactElement } from 'react'

const MessagesDateHeading = ({
  date,
  isMobileDevice
}: {
  date: string
  isMobileDevice: boolean
}): ReactElement => {
  return (
    <div
      style={{
        fontSize: isMobileDevice ? 11 : 13,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#6B777B'
      }}
    >
      <div
        style={{
          fontSize: isMobileDevice ? 11 : 13,
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#6B777B',
          textTransform: 'uppercase'
        }}
      >
        {date}
      </div>
    </div>
  )
}

export default MessagesDateHeading
