import { Button } from '@mui/material'
import { FilterList, ExpandMore } from '@mui/icons-material'
import { checkBrowserClient } from '../../../../utils/userAgentDetector'

const isMobileDevice = checkBrowserClient.isMobile()

type PropsT = {
  filtersSelected: number
  onClick?: () => void
}

const FiltersButton: React.FC<PropsT> = (props: PropsT) => {
  const { filtersSelected, onClick } = props

  return (
    <div
      style={{
        paddingLeft: isMobileDevice ? 16 : 0
      }}
    >
      <Button
        color="secondary"
        variant={filtersSelected ? 'contained' : 'outlined'}
        startIcon={<FilterList />}
        endIcon={<ExpandMore />}
        sx={{ fontSize: '16px', marginBottom: '16px' }}
        onClick={onClick}
      >
        Filters {filtersSelected > 0 && `(${filtersSelected})`}
      </Button>
    </div>
  )
}

export default FiltersButton
