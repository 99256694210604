import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

type FetchTriggerT = {
  fetchTrigger: boolean
  handleRefetchTrigger: () => void
}

const ContextFetchTrigger = createContext<FetchTriggerT>({
  fetchTrigger: false,
  handleRefetchTrigger: () => {
    return
  }
})

export const useHoldsContextFetchTrigger = (): FetchTriggerT =>
  useContext(ContextFetchTrigger)

export const HoldsContextFetchTrigger = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [fetchTrigger, setFetchTrigger] =
    useState<FetchTriggerT['fetchTrigger']>(false)

  const handleRefetchTrigger = useCallback(() => {
    setFetchTrigger((prevState) => !prevState)
  }, [])

  const value = useMemo(
    () => ({
      fetchTrigger,
      handleRefetchTrigger
    }),
    [fetchTrigger, handleRefetchTrigger]
  )

  return (
    <ContextFetchTrigger.Provider value={value}>
      {children}
    </ContextFetchTrigger.Provider>
  )
}
