import { ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import Skeleton from 'components/Skeleton'

const LoadingWishListPreview = (): ReactElement => (
  <>
    <Typography fontWeight="bold">Wish Lists</Typography>
    <Stack direction="row" mt={2} spacing={2} width={'100%'}>
      <Skeleton.Image aspectRatio={150 / 65} />
      <Skeleton.Image aspectRatio={150 / 65} />
      <Skeleton.Image aspectRatio={150 / 65} />
    </Stack>
  </>
)

export default LoadingWishListPreview
