import BaseSnackbar from 'components/BaseSnackbar'
import { ReactElement } from 'react'

export const CURATION_IN_PROGRESS_SNACKBAR_MESSAGE =
  'Taking you to your in-progress curation.'

const CurationInProgressSnackbar = (): ReactElement => {
  return <BaseSnackbar message={CURATION_IN_PROGRESS_SNACKBAR_MESSAGE} />
}

export default CurationInProgressSnackbar
