export interface RequestCardType {
  renderIcon: () => React.ReactNode
  title: string
  link: string
  count: number
  testId: string
  colorIcon: string
  buttonText: string
  visible?: boolean
  queueType: QueueType
}

export enum QueueType {
  GENERAL = 'general',
  PREFERRED_EMPLOYEE = 'preferred employee',
  EDIT = 'edit'
}
