import { Grid, TextField } from '@mui/material'
import { ReactElement, useContext } from 'react'
import { ITEM_NOTE_LABEL, UPDATE_NOTE } from 'pages/CurationReview/constants'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { UPDATE_CHECKOUT_BAG_DATA_NOTES } from 'pages/CurationEdit/constants'
import { checkBrowserClient } from 'utils/userAgentDetector'

type PropsT = {
  rmsSku: string
  note: string
  isEdit: boolean
}

const CurationItemNote = (props: PropsT): ReactElement => {
  const { rmsSku, note, isEdit } = props
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)
  const isMobileDevice = checkBrowserClient.isMobile()

  return (
    <Grid item mt={2}>
      <TextField
        fullWidth
        id={rmsSku}
        label={ITEM_NOTE_LABEL}
        multiline
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          if (!isEdit) {
            dispatch &&
              dispatch({
                type: UPDATE_NOTE,
                payload: { rmsSku: rmsSku, itemNote: event?.target.value }
              })
          }
          if (isEdit) {
            editDispatch &&
              editDispatch({
                type: UPDATE_CHECKOUT_BAG_DATA_NOTES,
                payload: { rmsSku: rmsSku, itemNote: event?.target.value }
              })
          }
        }}
        rows={isMobileDevice ? 2 : 5}
        variant="outlined"
        value={note}
      />
    </Grid>
  )
}

export default CurationItemNote
