import { useCallback, useState } from 'react'
import oktaTokenStorage from 'utils/okta-token-utils'

import { CopyErrorDataT } from 'types/CopyErrorData'
import { GetHoldsQueryDataParamsT, GetHoldsQueryDataT } from 'types/Holds'

import { useGetHoldsByEmployeeIdMutation } from 'services/holdsApi'

import useHoldsRefMounted from './useHoldsRefMounted'
import useHoldsRefState from './useHoldsRefState'

const HOLDS_REQUEST_LIMIT = 10

const useFetchGetHoldsByEmployeeId = ({
  requestParams,
  offsetShiftDecrement,
  withLoadMore
}: {
  requestParams: GetHoldsQueryDataParamsT
  offsetShiftDecrement: number
  withLoadMore: boolean
}): Readonly<{
  holds: GetHoldsQueryDataT
  setHolds: React.Dispatch<React.SetStateAction<GetHoldsQueryDataT>>
  isLoading: boolean
  isError: boolean
  endpointName: string | undefined
  error: Pick<CopyErrorDataT, 'errorData'>
  handleLoadMore: () => void
  fetchGetHoldsByEmployeeIdCallback: () => Promise<void>
}> => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const refMounted = useHoldsRefMounted()

  const [
    fetchGetHoldsByEmployeeId,
    { isLoading, isError, endpointName, error }
  ] = useGetHoldsByEmployeeIdMutation()

  const [offsetOriginal, setOffsetOriginal] = useState(0)
  const [holds, setHolds] = useState<GetHoldsQueryDataT>({
    data: [],
    total: 0
  })

  const refOffsetShiftDecrement = useHoldsRefState(offsetShiftDecrement)
  const refHolds = useHoldsRefState(holds)
  const refWithLoadMore = useHoldsRefState(withLoadMore)

  const handleLoadMore = useCallback(() => {
    if (!refMounted.current) {
      return
    }

    if (refHolds.current.total > offsetOriginal) {
      setOffsetOriginal(offsetOriginal + HOLDS_REQUEST_LIMIT)
    }
  }, [offsetOriginal, refHolds, refMounted])

  const fetchGetHoldsByEmployeeIdCallback = useCallback(async () => {
    try {
      const response = await fetchGetHoldsByEmployeeId({
        ...requestParams,
        employeeId,
        offset: offsetOriginal - refOffsetShiftDecrement.current
      }).unwrap()

      if (response?.data) {
        if (refWithLoadMore.current) {
          setHolds((prevState) => {
            const data = [...prevState.data]

            /**
             * FYI: detect collision by ticketNumber and prevent
             * if exist - replace on new Hold data
             * everything else - add to the end
             */
            const hashMapHoldsTicketNumbers = new Map(
              response.data.map((hold) => [hold.ticketNumber, hold])
            )

            data.forEach((hold, index) => {
              const findHold = hashMapHoldsTicketNumbers.get(hold.ticketNumber)

              if (findHold) {
                data[index] = findHold

                hashMapHoldsTicketNumbers.delete(hold.ticketNumber)
              }
            })

            data.push(...Array.from(hashMapHoldsTicketNumbers.values()))

            return {
              data,
              total: response.total
            }
          })
        } else {
          setHolds(response)
        }
      }
    } catch (e) {
      return
    }
  }, [
    fetchGetHoldsByEmployeeId,
    requestParams,
    employeeId,
    offsetOriginal,
    refOffsetShiftDecrement,
    refWithLoadMore
  ])

  return {
    holds,
    setHolds,
    isLoading,
    isError,
    endpointName,
    error: error as Pick<CopyErrorDataT, 'errorData'>,
    handleLoadMore,
    fetchGetHoldsByEmployeeIdCallback
  } as const
}

export default useFetchGetHoldsByEmployeeId
