import { ReactElement } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import ItemImage from '../ItemImage'
import { OfferProductT } from 'types/Curation'

type FinishedBoardProductImagesT = {
  products: Array<OfferProductT> | undefined
}

const NUMBER_OF_DISPLAYED_ITEMS = 4

const FinishedBoardProductImages = (
  props: FinishedBoardProductImagesT
): ReactElement => {
  const { products } = props
  const overflow = products && products.length > NUMBER_OF_DISPLAYED_ITEMS
  const overflowCount =
    overflow && products && products.length - NUMBER_OF_DISPLAYED_ITEMS
  return (
    <Box>
      <Grid
        container
        flexDirection={'row'}
        justifyContent={'flex-start'}
        wrap="nowrap"
      >
        {products &&
          products
            .slice(0, NUMBER_OF_DISPLAYED_ITEMS)
            .map((product: OfferProductT, i) => (
              <Grid
                item
                key={i}
                mr={1}
                sx={{
                  height: '85.1px',
                  width: '55.5px'
                }}
              >
                <ItemImage
                  imageAltText={product.name}
                  imageUrl={product.imageUrl}
                />
              </Grid>
            ))}
        {overflow && (
          <Grid
            item
            sx={{
              height: '85.1px',
              width: '55.5px'
            }}
          >
            <Box>
              <Typography
                alignItems={'center'}
                variant="body2"
                paddingTop={'12.5px'}
                color={'text.primary'}
                paddingLeft={0.5}
              >
                +{overflowCount} more items
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default FinishedBoardProductImages
