import { useCallback, useState } from 'react'
import { useMutationObservable } from 'app/hooks'

const useDisableDrawerEnforceFocus = (): {
  disableDrawerEnforceFocus: boolean
} => {
  const [disableDrawerEnforceFocus, setDisableDrawerEnforceFocus] =
    useState(false)

  const isGladlyOpen = (element: Element) => {
    return element.classList.contains('gladlyChat_open')
  }

  const onSidekickChange = useCallback(
    (mutationsList) => {
      setDisableDrawerEnforceFocus(isGladlyOpen(mutationsList[0].target))
    },
    [setDisableDrawerEnforceFocus]
  )

  useMutationObservable(
    document.getElementsByTagName('body')[0],
    onSidekickChange
  )

  return { disableDrawerEnforceFocus }
}

export { useDisableDrawerEnforceFocus }
