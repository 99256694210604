import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'
import { DeleteDraftErrorDataT } from 'types/DeleteDraftErrorData'
import { emDashCharacter } from 'pages/constants'

interface PropsT {
  errorDetails?: DeleteDraftErrorDataT
}
const DeleteDraftAlert = (props: PropsT): ReactElement => {
  const { errorDetails } = props
  return (
    <BasePageAlert
      alertTitle={`Issue deleting board ${emDashCharacter} try again`}
      errorDetails={errorDetails}
    />
  )
}

export default DeleteDraftAlert
