import { ReactElement, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Typography,
  Container,
  Box,
  Link
} from '@mui/material'
import type { SxProps } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { emDashCharacter } from 'pages/constants'
import { CopyErrorDataT } from 'types/CopyErrorData'
import CopyErrorData from 'components/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import ClickableText from 'components/ClickableText'

interface PropsT {
  errorDetails?: CopyErrorDataT
  errorTitle?: string
  isErrorTitlePersonalized?: boolean
  errorDescription?: string
  isInlineAlert?: boolean
  isFullWidth?: boolean
  customBoxStyle?: SxProps<Theme>
  customAlertStyle?: SxProps<Theme>
  shouldShowRefresh?: boolean
  refreshAction?: () => void
}

const DEFAULT_ALERT_TITLE = `Page error ${emDashCharacter}`

const PageError = (props: PropsT): ReactElement => {
  const {
    errorDetails,
    errorTitle,
    isErrorTitlePersonalized,
    errorDescription,
    isInlineAlert,
    isFullWidth,
    customBoxStyle,
    customAlertStyle,
    shouldShowRefresh = true,
    refreshAction
  } = props
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  const alertTitle = errorTitle
    ? isErrorTitlePersonalized
      ? errorTitle
      : `${errorTitle}`
    : DEFAULT_ALERT_TITLE

  const boxStyle = customBoxStyle ?? {
    mt: 3
  }

  const alertStyle = customAlertStyle ?? {
    backgroundColor: 'white',
    color: '#D6243D',
    '& .MuiAlert-icon': {
      color: '#D6243D'
    }
  }

  const onRefreshClick = () => {
    refreshAction ? refreshAction() : location.reload()
  }

  return (
    <>
      <Box sx={{ ...(!isFullWidth && { paddingX: 3 }), ...boxStyle }}>
        <Alert
          severity="error"
          sx={{
            ...(isInlineAlert && alertStyle)
          }}
        >
          <AlertTitle sx={{ fontSize: '16px' }}>
            <>
              <strong data-testid="error-title">
                {alertTitle}{' '}
                {shouldShowRefresh && (
                  <>
                    <Link
                      data-testid="page-error-refresh-button"
                      component={ClickableText}
                      onClick={onRefreshClick}
                      sx={{
                        color: 'inherit',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        fontSize: 'inherit'
                      }}
                      variant="body2"
                    >
                      Click here
                    </Link>{' '}
                    to refresh
                  </>
                )}
              </strong>
              {errorDescription && (
                <Typography
                  color="warning"
                  sx={{ overflowWrap: 'break-word' }}
                  variant="body2"
                >
                  {errorDescription}
                </Typography>
              )}
            </>
          </AlertTitle>
          {errorDetails && (
            <CopyErrorData
              errorDetails={errorDetails}
              setCopyToClipboardSuccess={setCopyToClipboardSuccess}
            />
          )}
        </Alert>
      </Box>
      <Container>
        {isCopyToClipboardSuccess && (
          <CopiedErrorSnackbar
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
        )}
      </Container>
    </>
  )
}

export default PageError
