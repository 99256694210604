import { Dispatch, ReactElement, useEffect, useMemo } from 'react'

import get from 'lodash/get'

import { Box, Grow, Stack, Typography } from '@mui/material'

import { CreateHoldsUnavailableItemsResponse, HoldFormData } from 'types/Holds'

import {
  HoldsHandleCloseT,
  HoldsStartNewHoldFormParamsT
} from 'pages/Holds/types'

import { HOLDS_TEXT_HOLD_NOT_CREATED } from 'pages/Holds/constants'

import BaseDialog from 'components/BaseDialog'

import { HoldsStartNewActionT } from '../hooks/useHoldsReducerStartNewHold'
import { useHoldsRefState, useHoldsToggle } from 'pages/Holds/hooks'

import { formatProductPrice } from 'utils/formatPrice'

import { getResponseErrorMessageOrErrorMessage } from 'pages/Holds/shared/utils/holdsErrorUtils'

import holdsGetGrowTimeout from 'pages/Holds/shared/utils/holdsGetGrowTimeout'
import HoldsBoxDivider from 'pages/Holds/shared/components/HoldsBoxDivider'
import HoldsProductItemImage from 'pages/Holds/shared/components/HoldsProductItemImage'

const HOLDS_DIALOG_TITLE = 'Some items now out of stock'
const HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_TITLE = 'Items now out of stock'

const HOLDS_DIALOG_SUB_INFO =
  "These items are unavailable. You won't be able to place them on hold for your customer."
const MODAL_RECOMMENDATION =
  'Remove out of stock items to proceed with the hold.'
const HOLDS_PRIMARY_BUTTON_TEXT = 'Remove Items & Print Ticket'
const HOLDS_SECONDARY_BUTTON_TEXT = 'Go Back'

const HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_SUB_INFO =
  "All items added to this hold are unavailable. You won't be able to place them on hold for your customer."
const HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_RECOMMENDATION =
  'Go back to add in-stock items, or cancel the hold.'
const HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_PRIMARY_BUTTON_TEXT = 'Go Back'
const HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_SECONDARY_BUTTON_TEXT = 'Cancel Hold'

const HOLDS_ERROR_CODE_UNAVAILABLE_ITEMS = 422

export const getCreateHoldsErrorTitle = ({
  error,
  holdFormData
}: {
  error: unknown
  holdFormData: HoldFormData
}): string => {
  if (get(error, 'status') === HOLDS_ERROR_CODE_UNAVAILABLE_ITEMS) {
    const unavailableLineItems: CreateHoldsUnavailableItemsResponse['unavailableLineItems'] =
      get(error, 'data.unavailableLineItems') ?? []

    const isAllItemsOutOfStock =
      unavailableLineItems?.length === holdFormData.lineItems.length

    if (!isAllItemsOutOfStock) {
      return HOLDS_DIALOG_TITLE
    }

    return HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_TITLE
  }

  return (
    getResponseErrorMessageOrErrorMessage(error) ?? HOLDS_TEXT_HOLD_NOT_CREATED
  )
}

const HoldsDialogOutOfStock = ({
  dataCreateHoldError,
  formParams,
  dispatchFormParams,
  handleSubmit,
  handleClose
}: {
  dataCreateHoldError: unknown
  formParams: Readonly<HoldsStartNewHoldFormParamsT>
  dispatchFormParams: Dispatch<HoldsStartNewActionT>
  handleSubmit: (params: HoldsStartNewHoldFormParamsT) => void
} & HoldsHandleCloseT): ReactElement => {
  const refFormParams = useHoldsRefState(formParams)

  const {
    isOpen: isOpenErrorUnavailableItemsDialogOpen,
    handleOpen: handleOpenErrorUnavailableItemsDialog,
    handleClose: handleCloseErrorUnavailableItemsDialog
  } = useHoldsToggle()

  const isUnavailableLineItems =
    get(dataCreateHoldError, 'status') === HOLDS_ERROR_CODE_UNAVAILABLE_ITEMS

  const unavailableLineItems = useMemo(() => {
    const unavailableLineItems: CreateHoldsUnavailableItemsResponse['unavailableLineItems'] =
      get(dataCreateHoldError, 'data.unavailableLineItems')

    return unavailableLineItems?.map(({ upc }) => upc) ?? []
  }, [dataCreateHoldError])

  const isAllItemsOutOfStock =
    unavailableLineItems.length === formParams.itemsOnHold.length

  const onConfirmErrorUnavailableItemsDialog = () => {
    if (!isAllItemsOutOfStock) {
      const itemsOnHoldWithoutUnavailableItems = formParams.itemsOnHold.filter(
        ({ upc }) => !unavailableLineItems.includes(upc)
      )

      dispatchFormParams({
        payload: {
          name: 'itemsOnHold',
          value: itemsOnHoldWithoutUnavailableItems
        }
      })

      /**
       * FYI: should submit items without unavailable items
       * after updating state
       */
      setTimeout(() => {
        handleSubmit(refFormParams.current)
      })
    }

    handleCloseErrorUnavailableItemsDialog()
  }

  const onCloseErrorUnavailableItemsDialog = () => {
    handleCloseErrorUnavailableItemsDialog()

    if (isAllItemsOutOfStock) {
      handleClose()
    }
  }

  useEffect(() => {
    if (!isUnavailableLineItems) {
      return
    }

    handleOpenErrorUnavailableItemsDialog()
  }, [isUnavailableLineItems, handleOpenErrorUnavailableItemsDialog])

  return (
    <BaseDialog
      data-testid="holds-dialog-out-of-stock"
      title={
        !isAllItemsOutOfStock
          ? HOLDS_DIALOG_TITLE
          : HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_TITLE
      }
      content={
        <>
          <Typography
            color="text.secondary"
            sx={{
              textWrap: 'balance'
            }}
          >
            {!isAllItemsOutOfStock
              ? HOLDS_DIALOG_SUB_INFO
              : HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_SUB_INFO}
          </Typography>

          <Typography
            color="text.secondary"
            sx={{
              textWrap: 'balance',
              mt: 1
            }}
          >
            {!isAllItemsOutOfStock
              ? MODAL_RECOMMENDATION
              : HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_RECOMMENDATION}
          </Typography>

          {!isAllItemsOutOfStock && (
            <>
              <HoldsBoxDivider />
              <Box mt={3}>
                <Stack
                  direction="column"
                  spacing={2}
                  divider={<HoldsBoxDivider />}
                >
                  {formParams.itemsOnHold
                    .filter(({ upc }) => unavailableLineItems.includes(upc))
                    .map((productItem, index) => (
                      /**
                       * TODO: HOLDS - should return from BE id from DB and paste to key
                       */
                      <Grow
                        key={productItem.idGenerated}
                        in
                        timeout={holdsGetGrowTimeout({
                          index
                        })}
                      >
                        <Box display="flex" gap={2}>
                          <HoldsProductItemImage
                            productItem={productItem}
                            height={178}
                            width={107}
                          />

                          <Box>
                            <Box display="flex" flexDirection="column" gap={1}>
                              <Typography>
                                <strong>{productItem.name}</strong>
                              </Typography>
                              <Typography>
                                {formatProductPrice(productItem.price)}
                              </Typography>
                            </Box>

                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={1}
                              mt={2}
                            >
                              <Typography>{productItem.size}</Typography>
                              <Typography>{productItem.color}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grow>
                    ))}
                </Stack>
              </Box>
            </>
          )}
        </>
      }
      confirm={{
        action: onConfirmErrorUnavailableItemsDialog,
        text: !isAllItemsOutOfStock
          ? HOLDS_PRIMARY_BUTTON_TEXT
          : HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_PRIMARY_BUTTON_TEXT
      }}
      cancel={{
        action: onCloseErrorUnavailableItemsDialog,
        text: !isAllItemsOutOfStock
          ? HOLDS_SECONDARY_BUTTON_TEXT
          : HOLDS_ALL_ITEMS_OUT_OF_STOCK_DIALOG_SECONDARY_BUTTON_TEXT
      }}
      onClose={onCloseErrorUnavailableItemsDialog}
      open={isOpenErrorUnavailableItemsDialogOpen}
    />
  )
}

export default HoldsDialogOutOfStock
