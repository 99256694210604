import { ReactElement, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Box } from '@mui/material'
import { HOLDS_COLOR_DIVIDER } from 'pages/Holds/constants'

import { ErrorFallback } from 'pages/ErrorFallback'
import BarcodeScanner from 'components/BarcodeScanner'

const HoldsButtonScanItem = ({
  handleSubmit
}: {
  handleSubmit: (data: string) => void
}): ReactElement => {
  const [refreshBarCodeScanner, setRefreshBarCodeScanner] = useState(false)
  const handleRefreshBarCodeScanner = () =>
    setRefreshBarCodeScanner(!refreshBarCodeScanner)

  return (
    <Box
      sx={{
        py: 1,
        px: 0,
        textAlign: 'center',
        backgroundColor: '#414141',
        color: HOLDS_COLOR_DIVIDER,
        flex: 1,
        alignContent: 'center',
        mt: 1.2,
        mx: {
          xs: -2,
          sm: -3
        },
        mb: -2.5,
        overflow: 'hidden'
      }}
    >
      <ErrorBoundary fallbackRender={ErrorFallback}>
        <BarcodeScanner
          key={Number(refreshBarCodeScanner)}
          onScanSuccess={(data) => {
            handleSubmit(data)

            handleRefreshBarCodeScanner()
          }}
        />
      </ErrorBoundary>
    </Box>
  )
}

export default HoldsButtonScanItem
