import {
  ReactElement,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import LaunchIcon from '@mui/icons-material/Launch'
import { LoadingButton } from '@mui/lab'
import { visuallyHidden } from '@mui/utils'
import AddItemErrorSnackbar from 'components/AddItemErrorSnackbar'

import { TransformedWishListItemT } from 'types/WishList'
import moment from 'moment'
import ItemImage from 'components/ItemImage'
import { checkBrowserClient } from 'utils/userAgentDetector'
import ProductGridPrice from './components/ProductGridPrice'
import { useAddItemToBagMutation } from 'services/curationSvc'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { UPDATE_WISHLIST_ADDS } from 'pages/CurationReview/constants'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_CREATE_BOARD_WITH_ITEM
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

const PURCHASED_BACKGROUND = '#482FE91F'

export type PropsT = {
  customerId?: string
  isInCustomerDetails?: boolean
  itemsAdded?: Set<string>
  onClickForPDP?: (webstyleId: string) => void
  onItemAdded?: (rmsSkuId: string) => void
  products: Array<TransformedWishListItemT>
  shopperId?: string
  token?: string
}

export type ProductGridItemPropsT = {
  customerId?: string
  isInCustomerDetails?: boolean
  isItemAdded: boolean
  isMobileDevice: boolean
  onClickForPDP?: (webstyleId: string) => void
  onItemAdded?: (rmsSkuId: string) => void
  product: TransformedWishListItemT
  shopperId?: string
  token?: string
}

const ProductGridItem = (props: ProductGridItemPropsT): ReactElement => {
  const {
    customerId,
    isInCustomerDetails,
    isItemAdded,
    isMobileDevice,
    onClickForPDP,
    onItemAdded,
    product,
    shopperId,
    token
  } = props

  const {
    brandName,
    addedAt,
    imageUrl,
    name,
    price,
    originalStyleGroupNumber,
    styleId,
    isPublished,
    colorOption,
    size1Option,
    enticements = [],
    hasPurchased,
    quantityRequested,
    quantity,
    rmsSkuId
  } = product

  const [addedItemUnavailable, setAddedItemUnavailable] = useState(false)
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)
  const history = useHistory()

  const [
    addItemToBag,
    {
      error: addItemError,
      isLoading: isAddItemLoading,
      isError: isAddItemError
    }
  ] = useAddItemToBagMutation()

  useEffect(() => {
    if (addItemError) {
      if ('status' in addItemError) {
        const errorMessage = JSON.stringify(addItemError.data)
        const itemUnavailable = errorMessage?.includes(
          'One or more items is not purchasable online'
        )
        setAddedItemUnavailable(itemUnavailable)
      }
    }
  }, [addItemError])

  const onClickAdd = useCallback(
    (rmsSku: string) => {
      shopperId &&
        token &&
        addItemToBag({
          shopperId,
          token,
          rmsSku
        })
          .unwrap()
          .then(() => {
            onItemAdded && onItemAdded(rmsSku)
            dispatch &&
              dispatch({
                type: UPDATE_WISHLIST_ADDS,
                payload: { rmsSku }
              })
            editDispatch &&
              editDispatch({
                type: UPDATE_WISHLIST_ADDS,
                payload: { rmsSku }
              })
            return
          })
          .catch(() => null)
    },
    [onItemAdded, addItemToBag, dispatch, editDispatch, shopperId, token]
  )

  const onClickCreateCuration = ({
    customerId,
    rmsSku
  }: {
    customerId: string
    rmsSku: string
  }) => {
    isInCustomerDetails &&
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_CREATE_BOARD_WITH_ITEM,
        {
          page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS,
          ocpId: customerId,
          rmsSku: rmsSkuId
        }
      )
    generateNewRelicLogs('createCurationFromCustomerListWishlistClick', {
      rmsSku: rmsSkuId,
      ocpId: customerId
    })

    history.push({
      pathname: '/review',
      search: `?createFor=${customerId}&rmsSku=${rmsSku}`
    })
  }

  const productNameWithoutBrand = name.replace(brandName, '')
  const gridBreakpoint = isMobileDevice ? 6 : 3

  const productSize =
    size1Option?.value === 'One Size' ? '' : `Size ${size1Option?.value}`
  const productColor =
    colorOption?.value === 'NO COLOR' ? '' : colorOption?.value
  const productColorAndSize =
    productSize && productColor
      ? `${productSize}, ${productColor}`
      : productColor || productSize

  return (
    <>
      <Grid
        item
        key={rmsSkuId}
        xs={gridBreakpoint}
        display="flex"
        flexDirection="column"
      >
        <Button
          aria-label="view product details"
          onClick={() =>
            styleId && onClickForPDP && onClickForPDP(styleId.toString())
          }
          sx={{ width: '100%', padding: 0 }}
        >
          <ItemImage imageUrl={imageUrl} imageAltText={name} />
        </Button>
        {shopperId && quantity > 0 && isPublished && (
          <LoadingButton
            disabled={isItemAdded}
            onClick={() => onClickAdd(rmsSkuId)}
            loading={isAddItemLoading}
            loadingPosition="center"
            startIcon={isItemAdded ? <CheckIcon /> : <AddIcon />}
          >
            {isItemAdded ? 'Added' : 'Add'}
          </LoadingButton>
        )}
        {shopperId && quantity === 0 && (
          <Button
            onClick={() =>
              styleId && onClickForPDP && onClickForPDP(styleId.toString())
            }
            startIcon={<LaunchIcon />}
          >
            View <Typography sx={visuallyHidden}>product details</Typography>
          </Button>
        )}
        {isInCustomerDetails && customerId && quantity > 0 && (
          <Button
            onClick={() =>
              onClickCreateCuration({ customerId, rmsSku: rmsSkuId })
            }
            size="small"
            sx={{ mt: '8px' }}
          >
            Create Board With Item
          </Button>
        )}
        <Box mt="8px">
          {hasPurchased && (
            <Box
              sx={{
                backgroundColor: PURCHASED_BACKGROUND,
                textAlign: 'center',
                marginBottom: '4px'
              }}
            >
              <Typography variant="overline">PURCHASED</Typography>
            </Box>
          )}
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {brandName}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {productNameWithoutBrand}
          </Typography>
          <ProductGridPrice
            price={price}
            enticements={enticements}
            styleId={styleId}
            isPublished={isPublished}
            sx={{ mt: '8px', mb: '8px' }}
          />
          {(productColor || productSize) && (
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {productColorAndSize}
            </Typography>
          )}
          {isPublished && quantity !== 0 && quantity < 6 && (
            <Box display="flex" mt={1}>
              <AccessTimeIcon sx={{ mr: 1 }} fontSize="small" />
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                Only
                {quantity === 1 ? ' 1 ' : quantity === 2 ? ' 2 ' : ' a few '}
                left
              </Typography>
            </Box>
          )}
          {originalStyleGroupNumber && (
            <Typography variant="body2" mt={1}>
              Item #{originalStyleGroupNumber}
            </Typography>
          )}
        </Box>
        <Box mt="8px">
          {quantityRequested && quantityRequested > 1 && (
            <Typography variant="body2" color="text.secondary">
              {quantityRequested} requested
            </Typography>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: '14px' }}
          >
            Added {moment(addedAt).format('MM/DD/YY')}
          </Typography>
        </Box>
      </Grid>
      {isAddItemError && (
        <AddItemErrorSnackbar itemUnavailable={addedItemUnavailable} />
      )}
    </>
  )
}

const ProductGrid = (props: PropsT): ReactElement => {
  const {
    customerId,
    isInCustomerDetails,
    itemsAdded,
    onClickForPDP,
    onItemAdded,
    products,
    shopperId,
    token
  } = props

  const isMobileDevice = checkBrowserClient.isMobile()
  return (
    <Grid container columnSpacing="16px" rowSpacing="24px">
      {products.map((product, i) => {
        const isItemAdded = itemsAdded && itemsAdded.has(product.rmsSkuId)
        return (
          <ProductGridItem
            customerId={customerId}
            isInCustomerDetails={isInCustomerDetails}
            isItemAdded={!!isItemAdded}
            isMobileDevice={!!isMobileDevice}
            key={`${i}-${product.rmsSkuId}`}
            onClickForPDP={onClickForPDP}
            onItemAdded={onItemAdded}
            product={product}
            shopperId={shopperId}
            token={token}
          />
        )
      })}
    </Grid>
  )
}

export default ProductGrid
