import { ChangeEvent, ReactElement, memo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import { FilterOptionT } from 'types/FashionMapSearch'

interface PropsT {
  filters: FilterOptionT[]
  noFiltersMessage?: string
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void
  showSearchBox: boolean
  summaryTitle: string
}

interface FilterOptionCheckBoxPropsT {
  filterName: string
  isSelected: boolean
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const NoFilterMatches = ({ query }: { query: string }): ReactElement => {
  return (
    <Box mb={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        No results found for &quot;{query}&quot;
      </Typography>
      <Typography variant="caption">
        Check the spelling and try again.
      </Typography>
    </Box>
  )
}

const calculateRender = (
  prev: FilterOptionCheckBoxPropsT,
  next: FilterOptionCheckBoxPropsT
) => {
  return prev.isSelected === next.isSelected
}

const FilterOptionCheckBox = memo(function FilterOptionCheckBox({
  filterName,
  isSelected,
  onSelect
}: FilterOptionCheckBoxPropsT) {
  return (
    <ListItem disablePadding>
      <ListItemIcon>
        <Checkbox
          name={filterName}
          checked={isSelected}
          onChange={(e) => {
            onSelect(e)
          }}
          data-testid={`${filterName}-checkbox`}
        />
      </ListItemIcon>
      <ListItemText
        primary={filterName}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
},
calculateRender)

const FiltersAccordion = (props: PropsT): ReactElement => {
  const { filters, noFiltersMessage, onSelect, showSearchBox, summaryTitle } =
    props
  const [query, setQuery] = useState('')

  const searchedFilterMatches = filters.filter((option) =>
    option.name.toLowerCase().includes(query.toLowerCase())
  )

  return (
    <Accordion
      defaultExpanded={true}
      disableGutters
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none'
        }
      }}
      TransitionProps={{ timeout: 0 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${summaryTitle}-content`}
        id={`${summaryTitle}-header`}
      >
        <Typography variant="subtitle2">{summaryTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        {showSearchBox && (
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            size="small"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setQuery('')}
                >
                  <HighlightOffIcon />
                </IconButton>
              ),
              startAdornment: <SearchIcon />
            }}
          />
        )}

        {filters.length === 0 ? (
          <Typography variant="body2" p={2}>
            {noFiltersMessage}
          </Typography>
        ) : (
          <List>
            {!query ? (
              filters.map((filter) => (
                <FilterOptionCheckBox
                  key={filter.id}
                  filterName={filter.name}
                  isSelected={filter.isSelected}
                  onSelect={onSelect}
                />
              ))
            ) : searchedFilterMatches.length > 0 ? (
              searchedFilterMatches.map((filter) => {
                return (
                  <FilterOptionCheckBox
                    key={filter.id}
                    filterName={filter.name}
                    isSelected={filter.isSelected}
                    onSelect={onSelect}
                  />
                )
              })
            ) : (
              <NoFilterMatches query={query} />
            )}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(FiltersAccordion)
