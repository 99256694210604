import { reportCustomNewRelicError } from 'utils/reportCustomNewRelicError'

// differentiating here for support for multiple browsers
export const copyToClipboard = (str: string): Promise<boolean> => {
  if (typeof ClipboardItem && navigator?.clipboard?.write) {
    const type = 'text/plain'
    const blob = new Blob([str], { type })
    const data = new ClipboardItem({
      [type]: new Promise((resolve) => resolve(blob))
    })

    return navigator.clipboard
      .write([data])
      .then(() => true)
      .catch((err) => {
        reportCustomNewRelicError({ error: err })
        return false
      })
  } else {
    if (navigator?.clipboard?.writeText) {
      return navigator.clipboard
        .writeText(str)
        .then(() => true)
        .catch((err) => {
          reportCustomNewRelicError({ error: err })
          return false
        })
    }
    reportCustomNewRelicError({
      customErrorText:
        'copyToClipboard - navigator.clipboard.write and navigator.clipboard.writeText are not supported'
    })
    return Promise.resolve(false)
  }
}
