import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Typography,
  List,
  ListItem,
  useTheme
} from '@mui/material'
import WarningIcon from '@mui/icons-material/WarningAmber'
import oktaTokenStorage from 'utils/okta-token-utils'
import moment from 'moment'

import { useGetCustomersQuery } from 'services/employeeExperienceApi'
import { useGetCurationsQuery } from 'services/curationSvc'
import { CURATION_STATE, CurationT } from 'types/Curation'
import EmptyQueue, { EmptyQueueIconOption } from 'components/EmptyQueue'
import BasePageAlert from 'components/BasePageAlert'
import PageError from 'components/PageError'
import LoadingSpinner from 'components/LoadingSpinner'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

const PersonalRequestsContent = (): ReactElement => {
  const theme = useTheme()
  const history = useHistory()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const {
    data: getCurationsData,
    endpointName: getCurationsEndpointName,
    error: getCurationsError,
    isError: isGetCurationsError,
    isLoading: isGetCurationsLoading,
    originalArgs: getCurationsOriginalArgs
  } = useGetCurationsQuery({
    state: CURATION_STATE.REQUESTED,
    preferredEmployeeId: employeeId,
    allRecords: 'true'
  })

  const {
    data: getInProgressCurationsData,
    endpointName: getInprogressCurationsEndpointName,
    error: getInProgressCurationsError,
    isError: isGetInProgressCurationsError,
    isLoading: isGetInProgressCurationsLoading,
    originalArgs: getInProgressCurationsOriginalArgs
  } = useGetCurationsQuery({
    state: CURATION_STATE.IN_PROGRESS,
    employeeId,
    preferredEmployeeId: employeeId
  })

  const customerIds = [
    ...(getCurationsData?.curations?.map(({ ocpId }) => ocpId) || []),
    ...(getInProgressCurationsData?.curations?.map(({ ocpId }) => ocpId) || [])
  ].filter((customerId): customerId is string => customerId !== null)

  const {
    data: getCustomersData,
    endpointName: getCustomersEndpointName,
    error: getCustomersError,
    isError: isGetCustomersError,
    isLoading: isGetCustomersLoading
  } = useGetCustomersQuery(customerIds, {
    skip: isGetCurationsLoading || isGetInProgressCurationsLoading
  })

  const renderCuration = (curation: CurationT, index: number) => {
    const customer = getCustomersData?.customers?.find(
      ({ customerId }) => curation.ocpId === customerId
    )

    const handleClick = () => {
      const attributes = {
        ocpId: customer?.customerId
      }
      generateNewRelicLogs('startPreferredRequestClick', attributes)
      history.push({
        pathname: '/review',
        search: `?preferredRequestId=${curation.id}`
      })
    }

    return (
      <ListItem
        divider
        disableGutters
        secondaryAction={
          <Button onClick={handleClick} variant="contained">
            {curation.state === CURATION_STATE.IN_PROGRESS
              ? 'Continue'
              : 'Start'}
          </Button>
        }
        key={index}
      >
        <Box>
          <Typography variant="body2">{customer?.fullName}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography
                component="span"
                variant="body2"
                color={theme.palette.secondary.main}
              >
                {curation.occasions?.join(', ')}
              </Typography>
              <Typography component="span" variant="body2">{` | `}</Typography>
              <Typography
                component="span"
                variant="body2"
                color={
                  curation.hasLapsedPreferredEmployeeRequest
                    ? theme.palette.warning.main
                    : theme.palette.secondary.main
                }
              >
                {moment(curation.createdAt).format('MMM DD')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', ml: 0.5 }}>
              {curation.hasLapsedPreferredEmployeeRequest && (
                <WarningIcon sx={{ fill: theme.palette.warning.main }} />
              )}
            </Box>
          </Box>
        </Box>
      </ListItem>
    )
  }

  const isLoading =
    isGetCurationsLoading ||
    isGetCustomersLoading ||
    isGetInProgressCurationsLoading

  const curations = [
    ...(getInProgressCurationsData?.curations || []),
    ...(getCurationsData?.curations || [])
  ]

  const hasLapsedRequests = !!curations.find(
    (curation) => curation.hasLapsedPreferredEmployeeRequest
  )

  if (isGetCurationsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getCurationsEndpointName,
          errorData: getCurationsError,
          originalArgs: getCurationsOriginalArgs
        }}
      />
    )
  }

  if (isGetInProgressCurationsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getInprogressCurationsEndpointName,
          errorData: getInProgressCurationsError,
          originalArgs: getInProgressCurationsOriginalArgs
        }}
      />
    )
  }

  if (isGetCustomersError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getCustomersEndpointName,
          errorData: getCustomersError
        }}
      />
    )
  }

  if (!curations.length && !isLoading) {
    return (
      <EmptyQueue
        title="No new requests"
        subtitle="New styling requests from customers will appear here"
        icon={EmptyQueueIconOption.PREFERRED}
      />
    )
  }

  return (
    <Container>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box pt={1}>
          {hasLapsedRequests && (
            <BasePageAlert
              alertTitle="Requests need your attention"
              errorMessage="Requests older than 5 days are shared to the general queue"
              isDismissable
              severity="warning"
            />
          )}
          <List>{curations.map(renderCuration)}</List>
        </Box>
      )}
    </Container>
  )
}

export default PersonalRequestsContent
