import { Box, Typography } from '@mui/material'
import PageError from 'components/PageError'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { CONSENTED_CUSTOMER_TRIPS_TITLE } from './constants'

type PropsT = {
  errorDetails: CopyErrorDataT
}

const ConsentedCustomerTripsError = ({ errorDetails }: PropsT): JSX.Element => {
  return (
    <Box
      sx={{
        paddingX: '16px',
        paddingY: '8px'
      }}
    >
      <Typography variant="subtitle2">
        {CONSENTED_CUSTOMER_TRIPS_TITLE}
      </Typography>
      <PageError
        errorTitle="Issue getting trips with you - try again"
        isInlineAlert={true}
        errorDetails={errorDetails}
      />
    </Box>
  )
}

export default ConsentedCustomerTripsError
