import { ReactElement, memo, useRef } from 'react'

import type { TypographyProps } from '@mui/material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grow,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import {
  HOLDS_COMPLETION_PREFIXES_BY_STATUS,
  HOLDS_TYPE_LABELS,
  HoldT,
  HoldsStatus
} from 'types/Holds'

import {
  HOLDS_BACKGROUND_COLOR_HOLD_CARD_WHITE,
  HOLDS_COLOR_ARROW
} from 'pages/Holds/constants'

import holdsFormatDateToLocalDate from '../utils/holdsFormatDateToLocalDate'
import holdsGetGrowTimeout from '../utils/holdsGetGrowTimeout'

import { useHoldsToggle } from 'pages/Holds/hooks'

import { TypographyCaption } from './Typography'
import HoldsProductItemImage from './HoldsProductItemImage'
import HoldsProductItemCardDetails, {
  HoldsRefApiProductItemCardDetailsT,
  HoldsRefApiProductItemCardDetailsWithMutableObjectT
} from './HoldsProductItemCardDetails/HoldsProductItemCardDetails'

const HOLDS_MAX_DISPLAY_ITEMS = 4

const subheaderTypographyProps: TypographyProps = {
  variant: 'body2',
  color: 'text.secondary',
  fontSize: 14,
  lineHeight: '20px'
}

const subheaderTypographyColorPrimaryProps: TypographyProps = {
  ...subheaderTypographyProps,
  color: 'text.primary'
}

const HoldsProductItemCard = ({
  hold,
  handleUpdateDetails,
  handleCloseHoldDetailsDialog
}: {
  hold: HoldT
  handleUpdateDetails: (hold: HoldT) => void
  handleCloseHoldDetailsDialog?: (hold: HoldT) => void
}): ReactElement => {
  const refApiHoldDetailsDialog = useRef<HoldsRefApiProductItemCardDetailsT>()

  const {
    isOpen: isOpenHoldDetails,
    handleOpen: handleOpenHoldDetails,
    handleClose: _handleCloseHoldDetails
  } = useHoldsToggle()

  const handleCloseHoldDetails = () => {
    _handleCloseHoldDetails()
    handleCloseHoldDetailsDialog?.(hold)
  }

  const handlePreloadHoldDetailsData = () => {
    refApiHoldDetailsDialog.current?.getHoldDetailsByTicketNumber()
  }

  const isActiveHoldStatus = hold.status === HoldsStatus.ACTIVE

  const completionDate = isActiveHoldStatus ? hold.expiresAt : hold.completedAt
  const messageByStatus = [
    HOLDS_COMPLETION_PREFIXES_BY_STATUS[hold.status],
    completionDate &&
      holdsFormatDateToLocalDate({
        date: completionDate
      })
  ]
    .filter(Boolean)
    .join(' ')

  const moreItemsLength = hold.lineItems.length - HOLDS_MAX_DISPLAY_ITEMS
  const hasMoreItemsLength = moreItemsLength > 0

  return (
    <Card
      data-testid={`product_item_${hold.ticketNumber}`}
      onPointerEnter={handlePreloadHoldDetailsData}
    >
      <CardHeader
        titleTypographyProps={{
          variant: 'subtitle1',
          // variant: 'subtitle2',
          fontWeight: 'bold'
          // fontSize: '1.6875rem',
          // lineHeight: '24px'
        }}
        title={`${hold.customer.firstName} ${hold.customer.lastName}`}
        subheader={
          <Box display="flex" flexDirection="column" gap={0.5} mt={0.5}>
            <Box display="flex" gap={1} data-testid="subheader">
              <TypographyCaption>
                {HOLDS_TYPE_LABELS[hold.type]}
              </TypographyCaption>
              <Divider orientation="vertical" flexItem />
              <TypographyCaption>{messageByStatus}</TypographyCaption>
            </Box>

            {isActiveHoldStatus && (
              <Box display="flex" alignItems="center" gap={1}>
                <AccessTimeIcon />
                <TypographyCaption>{hold.status}</TypographyCaption>
              </Box>
            )}
          </Box>
        }
        action={
          <IconButton
            aria-label="open hold details"
            onClick={handleOpenHoldDetails}
          >
            <ArrowForwardIosIcon sx={{ color: HOLDS_COLOR_ARROW }} />
          </IconButton>
        }
      />
      <CardContent>
        <Stack direction="row" spacing={2} overflow="auto">
          {(hasMoreItemsLength
            ? hold.lineItems.slice(0, HOLDS_MAX_DISPLAY_ITEMS)
            : hold.lineItems
          ).map((productItem, index) => (
            <Grow
              key={index}
              in
              timeout={holdsGetGrowTimeout({
                index
              })}
            >
              <Box>
                <HoldsProductItemImage
                  productItem={{
                    name: productItem.productName,
                    imageUrl: productItem.imageUrl
                  }}
                />
              </Box>
            </Grow>
          ))}
          {hasMoreItemsLength && (
            <Box
              sx={{
                position: 'sticky',
                right: 0,
                display: 'grid',
                alignContent: 'center',
                background: HOLDS_BACKGROUND_COLOR_HOLD_CARD_WHITE,
                py: 0.5,
                px: 1.5
              }}
            >
              <Typography {...subheaderTypographyColorPrimaryProps}>
                + {moreItemsLength}
              </Typography>
              <Typography {...subheaderTypographyColorPrimaryProps}>
                more
              </Typography>
              <Typography {...subheaderTypographyColorPrimaryProps}>
                items
              </Typography>
            </Box>
          )}
        </Stack>
      </CardContent>

      <HoldsProductItemCardDetails
        refApi={
          refApiHoldDetailsDialog as HoldsRefApiProductItemCardDetailsWithMutableObjectT
        }
        isOpen={isOpenHoldDetails}
        hold={hold}
        handleClose={handleCloseHoldDetails}
        handleUpdateDetails={handleUpdateDetails}
      />
    </Card>
  )
}

export const HoldsProductItemCardMemo = memo(
  HoldsProductItemCard,
  (prevProps, nextProps) => prevProps.hold === nextProps.hold
)

export default HoldsProductItemCard
