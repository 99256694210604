import { ReactElement } from 'react'
import BaseDialog from 'components/BaseDialog'
import ItemDetails from 'components/ItemDetails/ItemDetails'
import { EmployeeProductT } from 'types/Product'
import { determineCurrentPrice } from 'utils/priceHelpers'
import LoadingSpinner from 'components/LoadingSpinner'
import { Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import AddIcon from '@mui/icons-material/Add'

interface PropsT {
  isAddItemLoading: boolean
  onAddToBag: (rmsSku: string) => void
  onClickForPdP: (webStyleId: string) => void
  onClose: () => void
  open: boolean
  result: EmployeeProductT
}

const UPCProductResultDialog = (props: PropsT): ReactElement => {
  const { isAddItemLoading, onAddToBag, onClickForPdP, onClose, open, result } =
    props
  const currentPrice = determineCurrentPrice({
    clearancePrice: result.clearancePrice,
    priceType: result.currentPriceType,
    promotionPrice: result.promotionPrice,
    regularPrice: result.regularPrice
  })
  return (
    <BaseDialog
      title={'Item Result'}
      content={
        isAddItemLoading ? (
          <LoadingSpinner hideText={true} />
        ) : result.isAvailable ? (
          <ItemDetails
            brandName={result.brandName}
            color={result.color}
            currentPrice={currentPrice}
            enticements={result.enticements}
            itemName={result.itemName}
            imageUrl={result.imageUrl}
            itemNumber={result.itemNumber}
            regularPrice={result.regularPrice}
            size={result.size}
            webStyleId={result.webStyleId}
          />
        ) : (
          <>
            <Typography sx={{ mb: 2 }} variant="body2">
              Exact size and color not available online. View item details to
              see other options.
            </Typography>
            <ItemDetails
              brandName={result.brandName}
              color={result.color}
              currentPrice={currentPrice}
              enticements={result.enticements}
              itemName={result.itemName}
              imageUrl={result.imageUrl}
              itemNumber={result.itemNumber}
              regularPrice={result.regularPrice}
              size={result.size}
              webStyleId={result.webStyleId}
            />
          </>
        )
      }
      confirm={{
        action: result.isAvailable
          ? () => result.rmsSku && onAddToBag(result.rmsSku)
          : () => result.webStyleId && onClickForPdP(result.webStyleId),
        text: result.isAvailable ? 'Add to Board' : 'View Item'
      }}
      cancel={
        result.isAvailable
          ? {
              action: () =>
                result.webStyleId && onClickForPdP(result.webStyleId),
              text: 'View Item'
            }
          : undefined
      }
      fullWidth={true}
      onClose={onClose}
      open={open}
      showTitleWithCloseIcon={true}
      confirmButtonStartIcon={
        result.isAvailable ? <AddIcon /> : <OpenInNewIcon />
      }
      cancelButtonStartIcon={<OpenInNewIcon />}
    />
  )
}

export default UPCProductResultDialog
