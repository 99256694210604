import { ReactElement } from 'react'
import { Box, useTheme } from '@mui/material'
import { baseSkeletonStyling, TypographyVariants } from '../../constants'

export type PropsT = {
  width?: string
  textVariant?: TypographyVariants
}

const TextSkeleton = (props: PropsT): ReactElement => {
  const { textVariant = 'body2', width = '300px' } = props
  const theme = useTheme()
  const variantFontSize = theme.typography[textVariant].fontSize

  return (
    <Box
      data-testid="skeleton"
      sx={{
        height: variantFontSize,
        width,
        ...baseSkeletonStyling
      }}
    />
  )
}

export default TextSkeleton
