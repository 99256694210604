import { ReactElement } from 'react'
import PageError from 'components/PageError'

const ALERT_TITLE = `Number of requests didn't load.`

const RequestCountError = (): ReactElement => {
  return (
    <PageError
      errorTitle={ALERT_TITLE}
      isErrorTitlePersonalized={true}
      isFullWidth={true}
      isInlineAlert={true}
    />
  )
}
export default RequestCountError
