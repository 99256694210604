import { ProductT } from './Product'
export interface PHOrderSchemaT {
  customerId: string
  orderCount?: number
  skip?: number
}

export enum OrderStatusT {
  DELIVERED,
  PICKED_UP,
  READY_FOR_PICKUP,
  PREPARING_FOR_PICKUP,
  IN_ROUTE,
  PREPARING_TO_SHIP,
  ORDER_RECEIVED,
  PURCHASED,
  ADJUSTMENT_APPLIED,
  CANCEL_PENDING,
  DESTINATION_CHANGE_PENDING,
  RETURNED,
  CANCELLED,
  BACKORDERED,
  UNKNOWN // must always be last
}

export type OrderT = {
  orderType: string
  orderId: string
  orderDate: Date | string
  totalItemCount: number
  itemSubtotal: number
  shippingCharge: number
  employeeDiscount: number
  salesTax: number
  totalPrice: number
  storeName: string
  billingInfo: {
    city: string
    state: string
    country: string
    tenderType: string // not sure if this is correct
  }
  productsAndStatus: ProductsAndStatusT[]
}

export interface ProductsAndStatusT {
  sortValue: OrderStatusT
  deliveryStatus: string
  products: ProductT[]
  trackingUrl?: string
}

export interface OrdersI {
  actualToDateItems?: number
  actualToDateOrders: number
  orders?: OrderI[]
}

export interface OrdersT {
  actualToDateOrders: number
  orders: OrderT[]
}

export interface OrderI {
  advancedExchange?: AdvancedExchange
  barcode?: string
  billingAddress?: Address
  channelBrand?: string
  countryCode?: string
  currencySymbol?: string
  employeeDiscount?: number
  expressReturnEligible?: boolean
  fees?: Fee[]
  giftOptionCharge?: number
  iconId?: string
  internalTransaction?: InternalTransaction
  isInternational?: boolean
  isModernStack?: boolean
  isOrderCancellable?: boolean
  itemSubTotal?: number
  looksUrl?: string
  lookupKey?: string
  orderDate?: Date
  orderId: string
  orderModificationRequests?: OrderModificationRequests
  orderNumber?: number
  orderType?: string
  payments?: Payment[]
  purchaseStore?: Store
  refundTotal?: number
  registerNumber?: number
  salesTax?: number
  shippingCharge?: number
  shopperId?: string
  status?: Status[]
  totalPrice?: number
  totalTaxBreakdown?: Down
  transactionNumber?: string
}

export type ConsentedCustomerOrderT = Pick<
  OrderI,
  | 'orderId'
  | 'orderNumber'
  | 'orderDate'
  | 'orderType'
  | 'totalPrice'
  | 'itemSubTotal'
  | 'salesTax'
> & { status: ConsentedCustomerOrderStatus[] }

type ConsentedCustomerOrderStatus = Pick<
  Status,
  | 'trackingNumber'
  | 'trackingUrl'
  | 'deliveryService'
  | 'expectedArrival'
  | 'deliveryStatus'
> & { items: ConsentedCustomerOrderItem[] }

export type ConsentedCustomerOrderItem = {
  id?: string
  style?: number
  styleNumber?: string
  description: string
  quantity?: number
  purchasePrice: number
  listedPrice: number
  originalPrice?: number
  adjustedPrice?: number
  priceBeforeAdjustment?: number
  businessType?: 'JWN' | 'ECONCESSION'
  imageUrl?: string
  productName?: string
  brandName?: string
  size?: string
  color?: string
  lineItemNumber?: string
  orderLineId?: string
  commissionSalespersonId: string
}

export interface AdvancedExchange {
  creditApplied?: string
  creditTotal?: string
  exchangedOrders?: SourceOrderElement[]
  isAllOriginalItemsReturned?: boolean
  sourceOrder?: SourceOrderElement
}
export interface SourceOrderElement {
  createdDate?: string
  creditApplied?: string
  lookupKey?: string
  orderNumber?: string
}

export interface Address {
  city?: string
  country?: string
  firstName?: string
  lastName?: string
  postalCode?: string
  state?: string
  streetAddress1?: string
  streetAddress2?: string
  streetAddress3?: string
}

export interface Fee {
  amount?: number
  feeId?: string
  feeType?: string
  refundAmount?: number
}

export interface InternalTransaction {
  advancedExchange?: AdvancedExchange
  channel?: string
  channelBrand?: string
  countryCode?: string
  customerContact?: CustomerContact
  employeeEmail?: string
  fees?: Fee[]
  iconId?: string
  id?: number
  isChargeSend?: boolean
  isDtc?: boolean
  isInternational?: boolean
  isModernStack?: boolean
  isOrderCancellable?: boolean
  items?: InternalTransactionItem[]
  lastModifiedDate?: Date
  nordTest?: string
  orderId?: string
  orderModificationRequests?: OrderModificationRequests
  platform?: string
  registerNumber?: number
  shopperId?: string
  status?: string
  storeNumber?: number
  tenderItems?: TenderItem[]
  totalAmount?: TotalAmount
  transactionDate?: Date
  transactionNumber?: number
  transactionType?: string
  transactionVersion?: string
  unexpectedPurchaseDetails?: UnexpectedPurchaseDetails
}

export interface CustomerContact {
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
}

export interface InternalTransactionItem {
  advancedExchangeOrderNumber?: string
  amountCurrency?: string
  cancelReason?: string
  carrierServiceCode?: string
  description?: string
  employeeDiscountAmount?: number
  employeeDiscountFlag?: string
  fulfillingEmployeeNumber?: string
  fulfillingStoreNumber?: string
  giftOption?: GiftOption
  id?: string
  inStoreReturnNaturalKey?: string
  instructions?: Instruction[]
  isFinalSale?: boolean
  itemTax?: number
  itemTaxBreakdown?: Down
  lastModifiedDate?: Date
  lineItemNumber?: string
  orderLineId?: string
  orderModificationRequests?: OrderModificationRequests
  originalTransactionDate?: Date
  paidPrice?: number
  promiseType?: string
  promotionDiscountAmount?: number
  promotionReason?: string
  returnType?: string
  rmsSkuId?: string
  shipMethod?: string
  shippingInfo?: ShippingInfo
  status?: string
  storeNumberForItem?: number
  styleNumber?: number
  type?: string
  unitPrice?: number
  upcCode?: string
  webStyleId?: number
}

export interface GiftOption {
  charge?: number
  from?: string
  index?: number
  message?: string
  recipientEmail?: string
  serviceLineId?: string
  to?: string
  type?: string
}

export interface Instruction {
  text?: string
  type?: string
}

export interface Down {
  additionalProp1?: number
  additionalProp2?: number
  additionalProp3?: number
}

export interface OrderModificationRequests {
  additionalProp1?: AdditionalProp
  additionalProp2?: AdditionalProp
  additionalProp3?: AdditionalProp
}

export interface AdditionalProp {
  inEligibilityReasons?: string[]
  isEligible?: boolean
  isRejected?: boolean
  platform?: string
  rejectReason?: string
  requestedTime?: Date
}

export interface ShippingInfo {
  backOrderAvailabilityDate?: Date
  carrierName?: string
  deliveredDate?: Date
  ead?: Ead
  firstName?: string
  lastName?: string
  middleName?: string
  shippedDate?: Date
  shippingAddress?: Address
  trackingNumber?: string
  trackingUrl?: string
}

export interface Ead {
  endead?: Date
  startead?: Date
}

export interface TenderItem {
  billingAddress?: BillingAddress
  cardSubType?: string
  cardType?: string
  lastFour?: string
  paidCurrency?: string
  paidPrice?: number
  paymentVendorType?: string
  paypalEmail?: string
  tenderId?: string
  tenderType?: string
}

export interface BillingAddress {
  city?: string
  country?: string
  firstName?: string
  isMasked?: boolean
  lastName?: string
  line1?: string
  line2?: string
  line3?: string
  middleName?: string
  postalCode?: string
  state?: string
}

export interface TotalAmount {
  giftOptionCharge?: number
  giftOptionTax?: number
  merchandiseTotal?: number
  paidCurrency?: string
  paidPrice?: number
  refundTotal?: number
  shippingCharge?: number
  shippingTax?: number
  totalTax?: number
  totalTaxBreakdown?: Down
}

export interface UnexpectedPurchaseDetails {
  reason?: string
  shippedOrderNumber?: string
}

export interface Payment {
  paymentVendorType?: string
  paypalEmail?: string
  tenderType?: string
  token?: string
  type?: string
}

export interface Store {
  address?: string
  city?: string
  name?: string
  number?: number
  phoneNumber?: string
  postalCode?: string
  state?: string
}

export interface Status {
  carrier?: string
  collapsed?: boolean
  deliveryAddress?: Address
  deliveryService?: string
  deliveryStatus?: string
  deliveryStatusCode?: string
  expectedArrival?: ExpectedArrival
  isAnniversary?: boolean
  isStatusAlterable?: boolean
  items?: Product[]
  pickUpDate?: string
  pickupStore?: Store
  statusDeliveryDate?: Date
  trackingNumber?: string
  trackingUrl?: string
}

export interface ExpectedArrival {
  after?: Date
  before?: Date
}

export interface Product {
  advancedExchangeOrderNumber?: string
  backOrderAvailabilityDate?: Date
  brandName?: string
  canReturn?: boolean
  color?: string
  description?: string
  discount?: number
  estimatedRefund?: number
  giftOption?: GiftOption
  id?: string
  imageUrl?: string
  imsProductType?: ImsProductType
  isBopus?: boolean
  isFinalSale?: boolean
  isItemAlterable?: boolean
  isPriceAdjustable?: boolean
  isShipToStore?: boolean
  isSkuAvailable?: boolean
  itemTaxBreakDown?: Down
  lineItemNumber?: string
  looks?: Looks
  orderLineId?: string
  orderModificationRequests?: OrderModificationRequests
  priceAdjustmentInEligibilityReasons?: string[]
  productDetail?: ProductDetail
  productName?: string
  promiseType?: string
  promotionReason?: string
  purchasePrice?: number
  quantity?: number
  salesTax?: number
  size?: string
  style?: number
  styleNumber?: string
}

export interface ImsProductType {
  code?: string
  name?: string
  parentCode?: string
  parentName?: string
}

export interface Looks {
  isLookAvailable?: boolean
}

export interface ProductDetail {
  hasRealFur?: boolean
  isDropShip?: boolean
  isHazmat?: boolean
  isPython?: boolean
}
