import { Button } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import { ReactElement } from 'react'

type PropsT = {
  onSyncButtonClick: () => void
}

const SyncButton = (props: PropsT): ReactElement => {
  const { onSyncButtonClick } = props

  return (
    <Button
      onClick={() => {
        onSyncButtonClick()
      }}
    >
      <SyncIcon /> Sync Items
    </Button>
  )
}

export default SyncButton
