import { ReactElement } from 'react'
import { Link, Typography } from '@mui/material'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { copyToClipboard } from 'utils/copyToClipboard'
import ClickableText from 'components/ClickableText'

type PropsT = {
  errorDetails: CopyErrorDataT
  setCopyToClipboardSuccess?: (arg: boolean) => void
  helperMessage?: string
}

const CopyErrorData = (props: PropsT): ReactElement => {
  const { errorDetails, setCopyToClipboardSuccess, helperMessage } = props

  const onClick = () => {
    copyToClipboard(
      JSON.stringify(
        { ...errorDetails, page: window.location.href },
        undefined,
        4
      )
    )
      .then((result) => {
        return setCopyToClipboardSuccess && setCopyToClipboardSuccess(result)
      })
      .catch((error) => {
        throw error
      })
  }

  return (
    <Typography
      color="warning"
      sx={{ overflowWrap: 'break-word' }}
      variant="body2"
    >
      {helperMessage ?? 'Still not working?'}{' '}
      <Link
        data-testid="copy-error-button"
        component={ClickableText}
        onClick={onClick}
        sx={{
          color: 'inherit',
          textDecoration: 'underline',
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        }}
        variant="body2"
      >
        Click here
      </Link>{' '}
      to copy error info and paste in support chat.
    </Typography>
  )
}

export default CopyErrorData
