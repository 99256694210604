import { ReactElement } from 'react'

import { Box, Container } from '@mui/material'

import {
  HOLDS_API_PARAMS_GET_ACTIVE_LIST,
  HOLDS_API_PARAMS_GET_COMPLETED_LIST
} from './constants'

import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

import { HoldsContextNotification } from './contexts/HoldsContextNotification'
import { HoldsContextFetchTrigger } from './contexts/HoldsContextFetchTrigger'

import BoxWithGladlySpace from 'components/BoxWithGladlySpace'

import HoldsBoxDivider from './shared/components/HoldsBoxDivider'

import HoldsTitle from './components/HoldsTitle'
import HoldsStoreNumber from './components/HoldsStoreNumber'
import HoldsButtonStart from './components/HoldsButtonStart'
import HoldsButtonProductList from './components/HoldsButtonProductList'
import HoldsButtonFindExisting from './components/HoldsButtonFindExisting'

const Holds = (): ReactElement => {
  const { isHoldsEnabled } = useFeatureFlags()
  const allowAccessToHolds = isHoldsEnabled?.active

  if (!allowAccessToHolds) {
    return <></>
  }

  return (
    <HoldsContextNotification>
      <HoldsContextFetchTrigger>
        <BoxWithGladlySpace>
          <Container
            sx={{
              mb: 3
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: 920,
                margin: '0 auto',
                py: {
                  sm: 3
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexFlow: {
                    sm: 'row',
                    xs: 'column'
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    flex: 1.56,
                    gap: 1
                  }}
                >
                  <HoldsTitle />
                  <HoldsStoreNumber />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    flex: 1,
                    gap: 2
                  }}
                >
                  <HoldsButtonStart />
                  <HoldsButtonFindExisting />
                </Box>
              </Box>

              <HoldsBoxDivider mt={3} />

              <Box mt={3}>
                <HoldsButtonProductList
                  title={'Active'}
                  requestParams={HOLDS_API_PARAMS_GET_ACTIVE_LIST}
                  displayLimit={3}
                />
              </Box>

              <Box mt={3}>
                <HoldsButtonProductList
                  title={'Inactive'}
                  requestParams={HOLDS_API_PARAMS_GET_COMPLETED_LIST}
                  displayLimit={0}
                />
              </Box>
            </Box>
          </Container>
        </BoxWithGladlySpace>
      </HoldsContextFetchTrigger>
    </HoldsContextNotification>
  )
}

export default Holds
