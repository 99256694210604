import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { colors } from './constants'

const EmptyNotifications = (): ReactElement => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 200px)'
      }}
    >
      <Box>
        <Box
          sx={{
            backgroundColor: colors.secondaryWithTransparency,
            borderRadius: '50%',
            height: '67px',
            width: '67px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <NotificationsActiveIcon
            data-testid="notifications-icon"
            sx={{
              color: colors.secondary,
              width: '35px',
              height: '35px',
              margin: 'auto'
            }}
          />
        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 'bold',
            margin: '.5rem'
          }}
        >
          No notifications yet
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Notifications for new customers will show here.
        </Typography>
      </Box>
    </Box>
  )
}

export default EmptyNotifications
