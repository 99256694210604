import { Box, Button, TextField, Typography } from '@mui/material'
import { ReactElement, useState } from 'react'

const ADD_ITEM_TEXTFIELD_LABEL = 'Item URL'
const ADD_URL_TEXTFIELD_MESSAGE = 'Enter a URL to add an item to favorites.'
export type PropsT = {
  addFavorite: (arg: { webStyleIds: string[] }) => void
}
const AddFavoriteItem = (props: PropsT): ReactElement => {
  const [textInput, setTextInput] = useState<string | undefined>('')
  const [isInputError, setIsInputError] = useState(false)
  const { addFavorite } = props
  const getWebStyleIdFromURL = (url: string) => {
    try {
      const urlFromTextField = new URL(url)
      const urlPathName = urlFromTextField.pathname.toString()
      const splitURL = urlPathName.split('/')
      const WebStyleIdFromURL = splitURL.pop()
      if (!WebStyleIdFromURL) {
        throw new Error()
      }
      return WebStyleIdFromURL
    } catch (error) {
      setIsInputError(true)
    }
  }
  const handleInputWebStyleId = () => {
    if (!textInput) {
      setIsInputError(true)
      return
    }

    const webStyleId = getWebStyleIdFromURL(textInput)
    webStyleId && addFavorite({ webStyleIds: [webStyleId] })
    setTextInput('')
  }

  return (
    <Box display={'flex'} flexDirection={'column'} mb={2}>
      <Typography variant="body2" mb={2}>
        Copy an item’s URL from Nordstrom.com and paste it here to add the item
        to your favorites.
      </Typography>
      <TextField
        error={!!isInputError}
        fullWidth
        id="favorite-item-input"
        label={ADD_ITEM_TEXTFIELD_LABEL}
        helperText={isInputError && ADD_URL_TEXTFIELD_MESSAGE}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          if (isInputError) {
            setIsInputError(false)
          }
          setTextInput(event?.target.value)
        }}
        value={textInput}
      />
      <Button
        sx={{ alignSelf: 'flex-end', mt: 2 }}
        variant="contained"
        onClick={handleInputWebStyleId}
      >
        Add to Favorites
      </Button>
    </Box>
  )
}
export default AddFavoriteItem
