import { useCallback, useState } from 'react'
import useHoldsRefMounted from './useHoldsRefMounted'

type HoldsToggleT = Readonly<{
  isOpen: boolean
  handleOpen: () => void
  handleClose: () => void
}>

const useHoldsToggle = ({
  allowOpen = true,
  allowClose = true
}: {
  allowOpen?: boolean
  allowClose?: boolean
} = {}): HoldsToggleT => {
  const refMounted = useHoldsRefMounted()

  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    if (refMounted.current && allowOpen) {
      setOpen(true)
    }
  }, [allowOpen, refMounted])

  const handleClose = useCallback(() => {
    if (refMounted.current && allowClose) {
      setOpen(false)
    }
  }, [allowClose, refMounted])

  return {
    isOpen: open,
    handleOpen,
    handleClose
  } as const
}

export default useHoldsToggle
