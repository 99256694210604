import { NR_CLIENTELING_PAGES } from '../nrClientelingPages'

const { CUSTOMER_BOOK_PAGE } = NR_CLIENTELING_PAGES

export const NR_CUSTOMER_BOOK_PAGE_VIEW_CUSTOMER = `${CUSTOMER_BOOK_PAGE}-clicked_to_view_customer`
export const NR_CUSTOMER_BOOK_PAGE_VIEW_ARCHIVED_CUSTOMER = `${CUSTOMER_BOOK_PAGE}-clicked_to_view_archived_customer`

export const NR_CUSTOMER_BOOK_FILTERS = {
  ANNIVERSARY_FILTER: `${CUSTOMER_BOOK_PAGE}-anniversary_filter`,
  NORDY_CLUB_FILTER: `${CUSTOMER_BOOK_PAGE}-nordy_club_filter`,
  CARDMEMBER_FILTER: `${CUSTOMER_BOOK_PAGE}-cardmember_filter`,
  NORDSTROM_NOTES_FILTER: `${CUSTOMER_BOOK_PAGE}-nordstrom_notes_filter`,
  LAST_MESSAGE_SENT_FILTER: `${CUSTOMER_BOOK_PAGE}-last_message_sent_filter`,
  BRANDS_FILTER: `${CUSTOMER_BOOK_PAGE}-brands_filter`
} as const

export const NR_CUSTOMER_BOOK_FILTER_SELECTION_EVENTS = {
  ANNIVERSARY_FILTER: `${NR_CUSTOMER_BOOK_FILTERS.ANNIVERSARY_FILTER}-selection`,
  NORDY_CLUB_FILTER: `${NR_CUSTOMER_BOOK_FILTERS.NORDY_CLUB_FILTER}-selection`,
  CARDMEMBER_FILTER: `${NR_CUSTOMER_BOOK_FILTERS.CARDMEMBER_FILTER}-selection`,
  NORDSTROM_NOTES_FILTER: `${NR_CUSTOMER_BOOK_FILTERS.NORDSTROM_NOTES_FILTER}-selection`,
  LAST_MESSAGE_SENT_FILTER: `${NR_CUSTOMER_BOOK_FILTERS.LAST_MESSAGE_SENT_FILTER}-selection`,
  BRANDS_FILTER: `${NR_CUSTOMER_BOOK_FILTERS.BRANDS_FILTER}-selection`
} as const
