import { ReactElement, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import {
  Avatar,
  Button,
  Box,
  TextField,
  Autocomplete,
  Switch,
  FormControlLabel,
  InputAdornment
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  MAX_BIO_LENGTH,
  MAX_BRANDS_LENGTH,
  MAX_CATEGORIES_LENGTH,
  MAX_DISPLAY_NAME_LENGTH,
  MAX_SPECIALTIES_LENGTH,
  MAX_HANDLE_LENGTH,
  MAX_INSTAGRAM_LENGTH,
  HANDLE_REGEX,
  HANDLE_UNIQUENESS_VALIDATION_MESSAGE
} from './constants'
import {
  useCreateStylistProfileMutation,
  useUpdateStylistProfileMutation,
  useGetAppDataQuery
} from 'services/stylistProfileService'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import oktaTokenStorage from 'utils/okta-token-utils'
import BaseSnackbar from 'components/BaseSnackbar'
import { StylistProfileResponseT } from 'types/StylistProfile'
import isEqual from 'lodash/isEqual'
import {
  StyleT,
  BrandT,
  SpecialtyT,
  LocationT
} from '../../../../types/StylistProfile'
import BaseDialog from 'components/BaseDialog'
import { isUSSeller } from 'utils/userPermissions'
import { handleBeforeUnload } from 'utils/handleBeforeUnload'

interface NewStylistProfile {
  image: undefined | Blob
  isAcceptingRequests: boolean
  displayName: string
  handle: string
  instagramUsername: string
  bio: string | null
  location: LocationT | null
  styles: StyleT[]
  brands: BrandT[]
  specialties: SpecialtyT[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export interface PropsT {
  stylistProfile: StylistProfileResponseT | null
}

const initialState = (stylistProfile: StylistProfileResponseT | null) => ({
  image: undefined,
  isAcceptingRequests: stylistProfile?.isAcceptingRequests || false,
  displayName: stylistProfile?.displayName || '',
  handle: stylistProfile?.handle || '',
  instagramUsername: stylistProfile?.instagramUsername || '',
  bio: stylistProfile?.bio || null,
  location: stylistProfile?.location || null,
  styles: stylistProfile?.styles || [],
  brands: stylistProfile?.brands || [],
  specialties: stylistProfile?.specialties || []
})

const Form = (props: PropsT): ReactElement => {
  const [newStylistProfile, setNewStylistProfile] = useState<NewStylistProfile>(
    initialState(props.stylistProfile)
  )
  const [openDialog, setOpenDialog] = useState(false)
  const { data: appData } = useGetAppDataQuery()

  const [
    updateStylistProfile,
    {
      isLoading: isUpdateStylistProfileLoading,
      isSuccess: isUpdateStylistProfileSuccess,
      isError: isUpdateStylistProfileError,
      error: updateStylistProfileError,
      data: updatedStylistProfile
    }
  ] = useUpdateStylistProfileMutation()

  const [
    createStylistProfile,
    {
      isLoading: isCreateStylistProfileLoading,
      isSuccess: isCreateStylistProfileSuccess,
      isError: isCreateStylistProfileError,
      error: createStylistProfileError,
      data: createdStylistProfile
    }
  ] = useCreateStylistProfileMutation()

  const stylistProfile =
    updatedStylistProfile?.stylistProfile ||
    createdStylistProfile?.stylistProfile ||
    props.stylistProfile

  const sendRequest = () => {
    const formData = new FormData()
    formData.append('displayName', newStylistProfile.displayName)
    formData.append('handle', newStylistProfile.handle)
    formData.append('instagramUsername', newStylistProfile.instagramUsername)
    formData.append('stylistId', oktaTokenStorage.getUidFromOktaToken())
    formData.append(
      'employeeId',
      oktaTokenStorage.getEmployeeNumberFromOktaToken()
    )
    formData.append(
      'isAcceptingRequests',
      newStylistProfile.isAcceptingRequests.toString()
    )
    if (newStylistProfile.bio) {
      formData.append('bio', newStylistProfile.bio)
    }
    if (newStylistProfile.image) {
      formData.append('image', newStylistProfile.image)
    }
    if (newStylistProfile.location) {
      formData.append('locationId', newStylistProfile.location.id)
    }
    newStylistProfile.styles.forEach((style) =>
      formData.append('styleIds[]', style.id)
    )
    newStylistProfile.brands.forEach((brand) =>
      formData.append('brandIds[]', brand.id)
    )
    newStylistProfile.specialties.forEach((specialty) =>
      formData.append('specialtyIds[]', specialty.id)
    )
    stylistProfile
      ? updateStylistProfile({ body: formData, id: stylistProfile.id })
      : createStylistProfile(formData)
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    if (stylistProfile && newStylistProfile.handle !== stylistProfile.handle) {
      setOpenDialog(true)
    } else {
      sendRequest()
    }

    event.preventDefault()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { pattern, name, value } = event.target

    if (pattern && !new RegExp(pattern, 'i').test(value)) {
      return
    }

    setNewStylistProfile((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewStylistProfile((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked
    }))
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewStylistProfile((prev) => ({
      ...prev,
      [event.target.name]: event.target.files?.[0]
    }))
  }

  const handleSelect = (attribute: string) => {
    return (
      event: React.SyntheticEvent,
      newValue: string | null | StyleT[] | BrandT[] | SpecialtyT[] | LocationT
    ) => {
      setNewStylistProfile((prev) => ({
        ...prev,
        [attribute]: newValue
      }))
    }
  }
  const helperText = (charLength: number, maxLength: number) => {
    if (charLength === 0) return `Max ${maxLength} characters`

    const characterTense =
      charLength === maxLength - 1 || charLength === maxLength + 1
        ? 'character'
        : 'characters'

    if (charLength <= maxLength)
      return `${maxLength - charLength} ${characterTense} left`
  }

  // Prevents image from re-uploading
  useEffect(() => {
    setNewStylistProfile((prev) => ({
      ...prev,
      image: undefined
    }))
  }, [stylistProfile])

  const isDirty = !isEqual(initialState(stylistProfile), newStylistProfile)
  const isImageUploadRequired = !(
    !!newStylistProfile.image || !!stylistProfile?.imageUrl
  )

  const isHandleUniquenessError =
    (createStylistProfileError &&
      'data' in createStylistProfileError &&
      JSON.stringify(createStylistProfileError?.data).includes(
        HANDLE_UNIQUENESS_VALIDATION_MESSAGE
      )) ||
    (updateStylistProfileError &&
      'data' in updateStylistProfileError &&
      JSON.stringify(updateStylistProfileError?.data).includes(
        HANDLE_UNIQUENESS_VALIDATION_MESSAGE
      ))

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [isDirty])

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        '& .MuiTextField-root': { mb: 2 }
      }}
    >
      {!isUSSeller() && (
        <FormControlLabel
          sx={{ mb: 3 }}
          control={
            <Switch
              name="isAcceptingRequests"
              checked={newStylistProfile.isAcceptingRequests}
              value={newStylistProfile.isAcceptingRequests}
              onChange={handleToggleChange}
            />
          }
          label="Accept Requests"
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2
        }}
      >
        <Avatar
          variant="square"
          sx={{ height: 286, width: 224, mb: 2 }}
          src={
            (newStylistProfile.image &&
              URL.createObjectURL(newStylistProfile.image)) ||
            stylistProfile?.imageUrl
          }
        />
        <Button variant="outlined" component="label" disableRipple>
          <Box sx={{ pl: [0, 0, '15px'] }}>
            Upload stylist image
            <Box ml={2} display="inline-block">
              <input
                type="file"
                name="image"
                data-testid="image"
                required={isImageUploadRequired}
                onChange={handleImageChange}
              />
            </Box>
          </Box>
        </Button>
      </Box>

      <TextField
        value={newStylistProfile.displayName}
        inputProps={{
          maxLength: 50
        }}
        helperText={helperText(
          newStylistProfile.displayName.length,
          MAX_DISPLAY_NAME_LENGTH
        )}
        onChange={handleChange}
        name="displayName"
        required
        fullWidth
        label="Display Name"
        id="Display Name"
      />
      <TextField
        value={newStylistProfile.handle}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ marginRight: 0 }} position="start">
              nordstrom.com/stylists/profile/
            </InputAdornment>
          ),
          inputProps: {
            pattern: HANDLE_REGEX,
            maxLength: MAX_HANDLE_LENGTH
          }
        }}
        helperText={
          isHandleUniquenessError
            ? HANDLE_UNIQUENESS_VALIDATION_MESSAGE
            : `Must contain only letters, numbers, periods and underscores with a maximum of ${MAX_HANDLE_LENGTH} characters`
        }
        onChange={handleChange}
        error={isHandleUniquenessError}
        name="handle"
        required
        fullWidth
        label="Profile page url handle"
        id="Handle"
      />
      <TextField
        value={newStylistProfile.instagramUsername}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ marginRight: 0 }} position="start">
              instagram.com/
            </InputAdornment>
          ),
          inputProps: {
            pattern: HANDLE_REGEX,
            maxLength: MAX_INSTAGRAM_LENGTH
          }
        }}
        helperText={`Must contain only letters, numbers, periods and underscores with a maximum of ${MAX_INSTAGRAM_LENGTH} characters`}
        onChange={handleChange}
        name="instagramUsername"
        fullWidth
        label="Instagram Username"
        id="InstagramUsername"
      />
      {!isUSSeller() && (
        <TextField
          value={newStylistProfile.bio}
          inputProps={{
            maxLength: MAX_BIO_LENGTH
          }}
          helperText={helperText(
            newStylistProfile?.bio?.length ?? 0,
            MAX_BIO_LENGTH
          )}
          onChange={handleChange}
          name="bio"
          fullWidth
          multiline
          required
          minRows={4}
          label="Bio"
          id="Bio"
        />
      )}
      <Autocomplete
        fullWidth
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={newStylistProfile.location}
        options={appData?.locations || []}
        onChange={handleSelect('location')}
        getOptionLabel={({ name }) => name}
        data-testid="location"
        renderInput={(params) => (
          <TextField {...params} required label="Location" />
        )}
      />
      <Autocomplete
        fullWidth
        multiple
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={newStylistProfile.styles}
        options={appData?.styles || []}
        onChange={handleSelect('styles')}
        getOptionLabel={({ name }) => name}
        data-testid="styleCategories"
        disableCloseOnSelect
        getOptionDisabled={(option) =>
          newStylistProfile.styles.length === MAX_CATEGORIES_LENGTH &&
          !newStylistProfile.styles.find(({ id }) => id === option.id)
        }
        renderOption={(props, { name }, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required={newStylistProfile.styles.length === 0}
            helperText="Choose up to 3 styles that you love styling"
            label="Style Categories"
          />
        )}
      />
      <Autocomplete
        fullWidth
        multiple
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={newStylistProfile.brands}
        options={appData?.brands || []}
        onChange={handleSelect('brands')}
        getOptionLabel={({ name }) => name}
        data-testid="brands"
        disableCloseOnSelect
        getOptionDisabled={(option) =>
          newStylistProfile.brands.length === MAX_BRANDS_LENGTH &&
          !newStylistProfile.brands.find((brand) => brand.id === option.id)
        }
        renderOption={(props, { name }, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required={newStylistProfile.brands.length === 0}
            error={newStylistProfile.brands.length > MAX_BRANDS_LENGTH}
            helperText="Choose up to 5 of your favorite brands"
            label="Favorite Brands"
          />
        )}
      />
      <Autocomplete
        fullWidth
        multiple
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={newStylistProfile.specialties}
        options={appData?.specialties || []}
        onChange={handleSelect('specialties')}
        getOptionLabel={({ name }) => name}
        data-testid="specialties"
        disableCloseOnSelect
        getOptionDisabled={(option) =>
          newStylistProfile.specialties.length === MAX_SPECIALTIES_LENGTH &&
          !newStylistProfile.specialties.find(
            (specialty) => specialty.id === option.id
          )
        }
        renderOption={(props, { name }, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required={newStylistProfile.specialties.length === 0}
            label="Specialties"
            helperText="Choose up to 5 of your top specialties"
          />
        )}
      />
      <LoadingButton
        loading={isCreateStylistProfileLoading || isUpdateStylistProfileLoading}
        size="large"
        variant="contained"
        type="submit"
        fullWidth
        sx={{ mb: '100px' }}
      >
        Save Profile
      </LoadingButton>
      {isCreateStylistProfileSuccess && (
        <BaseSnackbar message="Profile created." />
      )}
      {isUpdateStylistProfileSuccess && (
        <BaseSnackbar message="Profile updated." />
      )}
      {(isCreateStylistProfileError || isUpdateStylistProfileError) &&
        !isHandleUniquenessError && (
          <BaseSnackbar message="An error has occurred. Try selecting an alternative image file." />
        )}
      <BaseDialog
        title="Are you sure you want to change your handle?"
        contentText="Your profile link will be updated with the new handle. Your old profile link will no longer work."
        confirm={{
          action: () => {
            setOpenDialog(false)
            sendRequest()
          },
          text: 'Save all changes'
        }}
        cancel={{
          action: () => setOpenDialog(false),
          text: 'Go back'
        }}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
      {isDirty && (
        <Prompt
          message={(_location, action) => {
            return action !== 'POP'
              ? true
              : 'Are you sure? Changes you made may not be saved.'
          }}
        />
      )}
    </Box>
  )
}

export default Form
