import { ReactElement, useState, useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { useNavigation } from 'contexts/NavigationContext'
import {
  CONSENT_LINK_COPIED_TO_CLIPBOARD,
  CONSENT_INVITE_TYPES,
  CONSENT_SEARCH_BY
} from './constants'
import { useGetStylistProfilesQuery } from 'services/stylistProfileService'
import {
  useGetEmployeeQuery,
  useUserHasActiveCommunicationsQuery,
  useActivateEmployeeCommunicationsMutation,
  useGetCustomerByIdentifierMutation,
  usePostInvitationMutation
} from 'services/clientelingApi'
import { copyToClipboard } from 'utils/copyToClipboard'
import oktaTokenStorage from 'utils/okta-token-utils'
import BaseSnackbar from 'components/BaseSnackbar'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import ActivateCommunications from 'pages/CustomerHome/components/ActivateCommunications'
import CustomerLookup from './components/CustomerLookup'
import QrModal from './components/QrModal'
import InviteCard from './components/InviteCard'
import CustomerInformation from './components/CustomerInformation'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { isFeatureEnabledForUser } from 'utils/userPermissions'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'
import SubPageTemplateWithPageAppBar from 'components/SubPageTemplateWithPageAppBar'

const GET_CUSTOMER_BY_IDENTIFIER_FETCH_ERROR_TITLE = 'Error finding customer.'
const SEND_CUSTOMER_INVITE_POST_ERROR_TITLE =
  'There was an error sending the invite.'

const InviteHome = (): ReactElement => {
  const { setDisplayAppBar } = useNavigation()

  const [copiedToClipBoard, setCopiedToClipBoard] = useState<boolean>(false)
  const [isErrorCopiedToClipBoard, setIsErrorCopiedToClipBoard] =
    useState<boolean>(false)
  const [isCommunicationsAlertVisible, setIsCommunicationsAlertVisible] =
    useState<boolean>(false)
  const [isActivationSuccess, setIsActivationSuccess] = useState<boolean>(false)
  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false)
  const [isInviteSuccessSnackbarOpen, setIsInviteSuccessSnackbarOpen] =
    useState<boolean>(false)
  const [isInviteSubmitDisabled, setIsInviteSubmitDisabled] =
    useState<boolean>(false)
  const [searchedCustomerIdentifier, setSearchedCustomerIdentifier] =
    useState<string>('')
  const [consentSearchBy, setConsentSearchBy] = useState<string>(
    CONSENT_SEARCH_BY.MOBILE
  )
  const [isCustomerDrawerOpen, setIsCustomerDrawerOpen] =
    useState<boolean>(false)

  const featureFlags = useFeatureFlags()
  const didFeatureFlagsLoad = !!Object.keys(featureFlags)?.length
  const { isSISUConsentEnabled } = featureFlags

  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const {
    data: stylistData,
    isError: isStylistError,
    isLoading: isStylistLoading
  } = useGetStylistProfilesQuery({
    employeeId
  })

  const {
    data: userCommunicationsData,
    isError: isUserCommunicationsError,
    isLoading: loadingActiveStatus
  } = useUserHasActiveCommunicationsQuery({ employeeId })

  const {
    data: employeeData,
    isError: isEmployeeError,
    isLoading: isEmployeeLoading
  } = useGetEmployeeQuery(
    { employeeId },
    { skip: !didFeatureFlagsLoad || !userCommunicationsData?.isActive }
  )

  const [
    activateEmployeeCommunications,
    {
      isError: isActivateCommunicationsError,
      isLoading: isActivateCommunicationsLoading
    }
  ] = useActivateEmployeeCommunicationsMutation()

  const [
    getCustomerByIdentifier,
    {
      data: customerData,
      isLoading: isGetCustomerByIdentifierLoading,
      isError: isGetCustomerByIdentifierError,
      error: customerByIdentifierError,
      endpointName: getCustomerByIdentifierEndpointName
    }
  ] = useGetCustomerByIdentifierMutation()

  const getCustomerByIdentifierError = customerByIdentifierError as {
    status?: number
  }
  const isCustomerNotFoundError =
    isGetCustomerByIdentifierError &&
    getCustomerByIdentifierError?.status === 404

  const [
    sendCustomerInvite,
    {
      isSuccess: isSendCustomerInviteSuccess,
      isLoading: isSendCustomerInviteLoading,
      isError: isSendCustomerInviteError,
      error: sendCustomerInviteError,
      endpointName: sendCustomerInviteEndpointName
    }
  ] = usePostInvitationMutation()

  const shouldDisplayCommunicationsAlert = !localStorage.getItem(
    'communications-status'
  )

  const employeeInviteLink =
    isSISUConsentEnabled?.active &&
    isFeatureEnabledForUser(
      isSISUConsentEnabled?.payload as AccessGroupsFeatureFlagT
    )
      ? employeeData?.employee?.url || ''
      : employeeData?.employee?.legacyUrl || ''

  const activateCommunications = () => {
    activateEmployeeCommunications({ employeeId })
      .unwrap()
      .then(() => {
        localStorage.setItem('communications-status', 'active')
        setIsCommunicationsAlertVisible(false)
        return setIsActivationSuccess(true)
      })
      .catch(() => {
        return
      })
  }

  useEffect(() => {
    if (!isUserCommunicationsError) {
      if (userCommunicationsData?.isActive) {
        localStorage.setItem('communications-status', 'active')
        setIsCommunicationsAlertVisible(false)
      } else {
        setIsCommunicationsAlertVisible(true)
      }
    } else {
      setIsCommunicationsAlertVisible(false)
    }
  }, [userCommunicationsData, isUserCommunicationsError])

  useEffect(() => {
    setDisplayAppBar(false)
    return () => setDisplayAppBar(true)
  }, [setDisplayAppBar])

  useEffect(() => {
    if (isGetCustomerByIdentifierError && !isCustomerNotFoundError) {
      setIsCustomerDrawerOpen(false)

      consentSearchBy === CONSENT_SEARCH_BY.MOBILE
        ? generateNewRelicLogs('inviteHomeCustomerSearchErrorByMobile')
        : generateNewRelicLogs('inviteHomeCustomerSearchErrorByEmail')
    }

    if (isCustomerNotFoundError) {
      consentSearchBy === CONSENT_SEARCH_BY.MOBILE
        ? generateNewRelicLogs('inviteHomeCustomerNotFoundByMobile')
        : generateNewRelicLogs('inviteHomeCustomerNotFoundByEmail')
    }
  }, [isGetCustomerByIdentifierError, isCustomerNotFoundError, consentSearchBy])

  useEffect(() => {
    if (isSendCustomerInviteSuccess) {
      setIsInviteSubmitDisabled(true)
      setIsInviteSuccessSnackbarOpen(true)

      consentSearchBy === CONSENT_SEARCH_BY.MOBILE
        ? generateNewRelicLogs('customerInfoTextInviteSuccess')
        : generateNewRelicLogs('customerInfoEmailInviteSuccess')
    }

    if (isSendCustomerInviteError) {
      setIsInviteSubmitDisabled(false)
      setIsCustomerDrawerOpen(false)

      consentSearchBy === CONSENT_SEARCH_BY.MOBILE
        ? generateNewRelicLogs('customerInfoTextInviteError')
        : generateNewRelicLogs('customerInfoEmailInviteError')
    }
  }, [isSendCustomerInviteSuccess, isSendCustomerInviteError, consentSearchBy])

  const onClickCopyLink = () => {
    console.log('setCopiedToClipBoard', copiedToClipBoard)
    copyToClipboard(employeeInviteLink + '&linkSource=copyLink')
      .then((result) => setCopiedToClipBoard(result))
      .catch((error) => {
        setIsErrorCopiedToClipBoard(true)
        throw error
      })
    setCopiedToClipBoard(false)
  }

  const onSubmitCustomerLookupBy = ({
    identifier,
    searchBy = CONSENT_SEARCH_BY.MOBILE,
    retrieveQualification
  }: {
    identifier: string
    searchBy?: string
    retrieveQualification?: boolean
  }) => {
    setIsInviteSubmitDisabled(false)
    getCustomerByIdentifier({
      identifier,
      searchBy,
      retrieveQualification
    })
    setSearchedCustomerIdentifier(identifier)
    setConsentSearchBy(searchBy)
    setIsCustomerDrawerOpen(true)

    consentSearchBy === CONSENT_SEARCH_BY.MOBILE
      ? generateNewRelicLogs('inviteHomeCustomerSearchByMobile')
      : generateNewRelicLogs('inviteHomeCustomerSearchByEmail')
  }

  const onSubmitInviteCustomerWithText = ({
    customerId
  }: {
    customerId: string
  }) => {
    generateNewRelicLogs('customerInfoTextInviteClick')
    sendCustomerInvite({
      customerId,
      employeeId,
      inviteType: CONSENT_INVITE_TYPES.SMS
    })
  }

  const onSubmitInviteCustomerWithEmail = ({
    customerId
  }: {
    customerId: string
  }) => {
    generateNewRelicLogs('customerInfoEmailInviteClick')
    sendCustomerInvite({
      customerId,
      employeeId,
      inviteType: CONSENT_INVITE_TYPES.EMAIL
    })
  }

  const handleQRModalOpen = () => {
    generateNewRelicLogs('inviteHomeQRCodeClick')
    setIsQRModalOpen(true)
  }

  const handleOnInviteWithQR = () => {
    generateNewRelicLogs('customerInfoQRCodeClick')
    setIsQRModalOpen(true)
  }

  if (isStylistLoading || isEmployeeLoading || loadingActiveStatus) {
    return (
      <>
        <SubPageTemplateWithPageAppBar title={'Invite customers'}>
          <LoadingSpinner />
        </SubPageTemplateWithPageAppBar>
      </>
    )
  }

  if (
    !shouldDisplayCommunicationsAlert &&
    (isStylistError || isEmployeeError)
  ) {
    return (
      <>
        <SubPageTemplateWithPageAppBar title={'Invite customers'}>
          <PageError errorTitle="Error getting your QR code." />
        </SubPageTemplateWithPageAppBar>
      </>
    )
  }

  return (
    <SubPageTemplateWithPageAppBar title={'Invite customers'}>
      {!shouldDisplayCommunicationsAlert && isErrorCopiedToClipBoard && (
        <Box sx={{ zIndex: '1000', position: 'absolute', width: '100%' }}>
          <PageError errorTitle="Error copying invitation link." />
        </Box>
      )}
      {!shouldDisplayCommunicationsAlert && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <InviteCard
            stylistData={stylistData}
            employeeData={employeeData}
            handleQRModalOpen={handleQRModalOpen}
          />
          <CustomerLookup
            isLoading={isGetCustomerByIdentifierLoading}
            onSubmit={onSubmitCustomerLookupBy}
          />
          <CustomerInformation
            customerData={customerData}
            isCustomerNotFoundError={isCustomerNotFoundError}
            isCustomerInformationOpen={
              !isGetCustomerByIdentifierLoading && isCustomerDrawerOpen
            }
            isInviteSubmitLoading={isSendCustomerInviteLoading}
            isInviteSubmitDisabled={isInviteSubmitDisabled}
            searchText={searchedCustomerIdentifier}
            onClose={() => setIsCustomerDrawerOpen(false)}
            onInviteWithText={onSubmitInviteCustomerWithText}
            onInviteWithEmail={onSubmitInviteCustomerWithEmail}
            onInviteWithQR={handleOnInviteWithQR}
          />
          <QrModal
            open={isQRModalOpen}
            handleClose={() => setIsQRModalOpen(false)}
            employeeInviteLink={employeeInviteLink}
            onClickCopyLink={onClickCopyLink}
            stylistData={stylistData}
            employeeData={employeeData}
            didFeatureFlagsLoad={didFeatureFlagsLoad}
          />
          <BaseSnackbar
            message={'Invitation link sent'}
            open={isInviteSuccessSnackbarOpen}
            onClose={() => setIsInviteSuccessSnackbarOpen(false)}
          />
          <Box sx={{ margin: '10px' }}>
            {isGetCustomerByIdentifierError && !isCustomerNotFoundError && (
              <PageError
                errorTitle={GET_CUSTOMER_BY_IDENTIFIER_FETCH_ERROR_TITLE}
                errorDetails={{
                  endpoint: getCustomerByIdentifierEndpointName,
                  errorData: getCustomerByIdentifierError,
                  identifiers: {
                    employeeId
                  }
                }}
              />
            )}
            {isSendCustomerInviteError && (
              <PageError
                errorTitle={SEND_CUSTOMER_INVITE_POST_ERROR_TITLE}
                errorDetails={{
                  endpoint: sendCustomerInviteEndpointName,
                  errorData: sendCustomerInviteError,
                  identifiers: {
                    employeeId
                  }
                }}
              />
            )}
          </Box>
        </Stack>
      )}
      {shouldDisplayCommunicationsAlert && (
        <ActivateCommunications
          isUserCommunicationsError={isUserCommunicationsError}
          isActivationSuccess={isActivationSuccess}
          isActivateCommunicationsError={isActivateCommunicationsError}
          activateCommunications={activateCommunications}
          isVisible={isCommunicationsAlertVisible}
          isActivateCommunicationsLoading={isActivateCommunicationsLoading}
        />
      )}
      {copiedToClipBoard && (
        <BaseSnackbar message={CONSENT_LINK_COPIED_TO_CLIPBOARD} />
      )}
    </SubPageTemplateWithPageAppBar>
  )
}

export default InviteHome
