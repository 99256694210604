import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isFeatureEnabledForUser } from './userPermissions'
import { useHistory } from 'react-router-dom'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'

export const useIsBarcodeScanningEnabledForUser = (): boolean => {
  const { isBarcodeScanningEnabledForGroup } = useFeatureFlags()
  const accessGroupsPayload =
    isBarcodeScanningEnabledForGroup?.payload as AccessGroupsFeatureFlagT

  return (
    isBarcodeScanningEnabledForGroup?.active &&
    isFeatureEnabledForUser(accessGroupsPayload)
  )
}

export const useIsScanningWithScanditEnabledForUser = (): boolean => {
  const history = useHistory()
  const { isScanditScanningEnabledForGroup } = useFeatureFlags()
  const scanditAccessGroupsPayload =
    isScanditScanningEnabledForGroup?.payload as {
      accessGroups?: Array<string>
      showToAll?: boolean
    }
  const hasScanditParam = history.location.search === `?scanner=scandit`
  return (
    (isScanditScanningEnabledForGroup?.active &&
      isFeatureEnabledForUser(scanditAccessGroupsPayload)) ||
    hasScanditParam
  )
}

export const useScanningFeatures = (): boolean => {
  const isBarCodeScanningEnabledForUser = useIsBarcodeScanningEnabledForUser()
  const isScanningWithScandingEnabledForUser =
    useIsScanningWithScanditEnabledForUser()
  if (isBarCodeScanningEnabledForUser || isScanningWithScandingEnabledForUser) {
    return true
  }
  return false
}
