import { ReactElement, useState } from 'react'
import { Button } from '@mui/material'
import BaseSnackbar from 'components/BaseSnackbar'
import { copyToClipboard } from 'utils/copyToClipboard'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'

type PropsT = {
  curationIdLink: string
}
const PUBLIC_LINK_COPY_SUCCESS_MESSAGE =
  'Style board added to your profile and link copied for sharing.'

const PUBLIC_LINK_COPIED_AGAIN_MESSAGE = 'Link copied'

const PublicBoardLinkCopiedSnackbar = (props: PropsT): ReactElement => {
  const { curationIdLink } = props
  const curationDetails = useAppSelector(curationSelector)

  const [isCopyLinkSuccess, setCopyLinkSuccess] = useState(false)

  const handleCopyLinkAgain = () => {
    const attributes = {
      curationId: curationDetails?.id,
      type: curationDetails?.type,
      preferredEmployeeId: curationDetails?.preferredEmployee,
      shoppingSessionId: curationDetails?.shoppingSessionId
    }
    generateNewRelicLogs('copyLinkAgain', attributes)
    copyToClipboard(curationIdLink)

    setCopyLinkSuccess(true)
  }
  const action = <Button onClick={handleCopyLinkAgain}>Copy Link Again</Button>

  return (
    <>
      <BaseSnackbar
        message={
          isCopyLinkSuccess
            ? PUBLIC_LINK_COPIED_AGAIN_MESSAGE
            : PUBLIC_LINK_COPY_SUCCESS_MESSAGE
        }
        action={action}
      />
    </>
  )
}

export default PublicBoardLinkCopiedSnackbar
