import { Typography, Box } from '@mui/material'

const ArchivedCustomerChip: () => JSX.Element = () => {
  return (
    <Box
      data-testid="archivedCustomerChip"
      style={{
        backgroundColor: '#586366',
        width: '70px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        marginRight: '10px',
        alignSelf: 'center'
      }}
    >
      <Typography variant="body2" fontSize="13px" color="#FFF">
        Archived
      </Typography>
    </Box>
  )
}

export default ArchivedCustomerChip
