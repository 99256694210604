import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { OrderT } from 'types/Orders'
import { checkBrowserClient } from 'utils/userAgentDetector'

export type PropsT = {
  displayItemCount?: boolean
  order: OrderT
}

const isMobileDevice = checkBrowserClient.isMobile()

const OrderInformation = (props: PropsT): ReactElement => {
  const { displayItemCount = true, order } = props
  const {
    storeName,
    orderType,
    orderId,
    orderDate,
    totalPrice,
    totalItemCount
  } = order

  const formattedDate = new Date(orderDate).toLocaleDateString()

  return (
    <Box display="inline-block">
      <Typography
        variant="subtitle2"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: isMobileDevice ? 'vertical' : 'unset',
          WebkitLineClamp: displayItemCount ? 2 : 1
        }}
      >
        Purchased {storeName || orderType}
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {`Order #${orderId} | ${formattedDate} | $${Number(totalPrice).toFixed(
          2
        )}`}
      </Typography>
      {displayItemCount && (
        <Typography color="text.secondary">{totalItemCount} items</Typography>
      )}
    </Box>
  )
}

export default OrderInformation
