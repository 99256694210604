import { styled } from '@mui/material'
import ButtonUnstyled from '@mui/core/ButtonUnstyled'

const ClickableText = styled(ButtonUnstyled)`
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  &:hover {
    background-color: rgba(31, 33, 34, 0.04);
  }
  &:active {
    background-color: rgb(197, 170, 170);
  }
`

export default ClickableText
