import { ReactElement, useState } from 'react'
import ImageUnavailable from 'components/ImageUnavailable'

type PropsT = {
  autoHeight?: boolean
  imageUrl?: string
  imageAltText?: string
  height?: number
  width?: number
}

const ItemImage = (props: PropsT): ReactElement => {
  const {
    autoHeight,
    imageUrl,
    imageAltText,
    width = '100%',
    height = '100%'
  } = props
  const [imageError, setImageError] = useState(false)
  const optimizedImageUrl = imageUrl + '?q=50'

  const handleImageError = () => {
    setImageError(true)
  }

  if (imageError || !imageUrl) {
    return <ImageUnavailable />
  }

  return (
    <img
      id=""
      src={optimizedImageUrl}
      width={width}
      height={autoHeight ? 'auto' : height}
      alt={imageAltText}
      onError={handleImageError}
      style={{ objectFit: 'contain' }}
    />
  )
}

export default ItemImage
