import { ReactElement } from 'react'
import { Typography } from '@mui/material'

import { EditRequestT } from 'types/EditRequest'

export type PropsT = {
  editRequest: EditRequestT
}

const EditRequestDetails = (props: PropsT): ReactElement => {
  const { editRequest } = props
  const { editNote } = editRequest

  if (editRequest.state !== 'edit_requested') {
    return <></>
  }

  return (
    <>
      <Typography
        fontWeight="bold"
        variant="h6"
        sx={{ marginBottom: '24px', marginTop: '16px' }}
      >
        Edit request
      </Typography>
      <Typography
        fontWeight="bold"
        variant="subtitle2"
        sx={{ marginBottom: '4px' }}
      >
        Notes
      </Typography>
      <Typography paragraph={true} variant="body2" gutterBottom>
        {editNote}
      </Typography>
    </>
  )
}

export default EditRequestDetails
