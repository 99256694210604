import { ReactElement } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { Button, Divider } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const AdditionalMessages = ({
  additionalMessagesAreLoading,
  handleFetchMoreMessages
}: {
  additionalMessagesAreLoading: boolean
  handleFetchMoreMessages: () => void
}): ReactElement => {
  return (
    <>
      {additionalMessagesAreLoading ? (
        <LoadingSpinner
          hideText
          sx={{ height: '50px', '> div': { margin: 0 } }}
        />
      ) : (
        <Button
          sx={{
            display: 'flex',
            margin: 'auto',
            height: '50px'
          }}
          variant="text"
          onClick={handleFetchMoreMessages}
        >
          <ArrowUpwardIcon sx={{ marginRight: '5px' }} />
          Load More Messages
        </Button>
      )}
      <Divider sx={{ margin: '15px' }} />
    </>
  )
}

export default AdditionalMessages
