import {
  useState,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react'
import { useLocation } from 'react-router-dom'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { formattedPathName } from 'utils/formattedPathName'

export type NavigationUpdateContextType = {
  displayAppBar: boolean
  setDisplayAppBar: Dispatch<SetStateAction<boolean>>
}

const NavigationContext = createContext<NavigationUpdateContextType>({
  displayAppBar: true,
  setDisplayAppBar: () => undefined
})

export const useNavigation = (): NavigationUpdateContextType => {
  return useContext(NavigationContext)
}

export const NavigationProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [displayAppBar, setDisplayAppBar] = useState<boolean>(true)
  const location = useLocation()

  useEffect(() => {
    const attributes = {
      page:
        typeof location !== 'undefined' && location.pathname
          ? formattedPathName(location.pathname)
          : 'unknown',
      searchParams:
        location.search || location.hash ? location.search + location.hash : ''
    }
    generateNewRelicLogs('pageView', attributes)
  }, [location])

  return (
    <NavigationContext.Provider value={{ displayAppBar, setDisplayAppBar }}>
      {children}
    </NavigationContext.Provider>
  )
}
