import { ReactElement } from 'react'
import BasePageAlert from 'components/BasePageAlert'
import { CopyErrorDataT } from 'types/CopyErrorData'

interface PropsT {
  errorDetails?: CopyErrorDataT
  onDismissed: () => void
  setCopyToClipboardSuccess: (arg: boolean) => void
}

const SYNC_CART_ERROR_MESSAGE = 'Issue syncing items. Try again.'

const SyncErrorAlert = (props: PropsT): ReactElement => {
  const { errorDetails, onDismissed, setCopyToClipboardSuccess } = props

  return (
    <BasePageAlert
      alertTitle={SYNC_CART_ERROR_MESSAGE}
      errorDetails={errorDetails}
      isDismissable={true}
      onDismissed={onDismissed}
      setCopyToClipboardSuccess={setCopyToClipboardSuccess}
    />
  )
}

export default SyncErrorAlert
