import { IconButton, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

type Props = {
  query: string
  setQuery: (value: string) => void
  placeholder: string
  testId?: string
}

const SearchBar: React.FC<Props> = (props: Props) => {
  const { query, setQuery, placeholder, testId } = props
  return (
    <TextField
      fullWidth
      style={{ marginBottom: 12 }}
      size="small"
      value={query}
      placeholder={placeholder}
      onChange={(e) => setQuery(e.target.value)}
      inputProps={{
        'data-testid': testId,
        style: { fontSize: '16px' }
      }}
      InputProps={{
        endAdornment: query && (
          <IconButton onClick={() => setQuery('')}>
            <HighlightOffIcon />
          </IconButton>
        ),
        startAdornment: (
          <IconButton>
            <SearchIcon />
          </IconButton>
        )
      }}
    />
  )
}

export default SearchBar
