import { useCallback, useEffect, useState } from 'react'

import { CopyErrorDataT } from 'types/CopyErrorData'
import { GetHoldsQueryDataT } from 'types/Holds'

import { useGetHoldsByPhoneNumberMutation } from 'services/holdsApi'

import useHoldsRefMounted from './useHoldsRefMounted'
import useHoldsRefState from './useHoldsRefState'

type ErrorT = {
  error?: string
  message?: string
}

export type HoldsErrorMessageT = {
  errorMessage?: string
}

const HOLDS_REQUEST_LIMIT = 10
const HOLDS_INITIAL_STATE = {
  data: [],
  total: 0
}

/**
 * TODO: hooks for fetching very similar, later apply common hook
 */
const useFetchGetHoldsByPhoneNumber = ({
  customerPhoneNumber,
  offsetShiftDecrement,
  withLoadMore
}: {
  customerPhoneNumber: string
  offsetShiftDecrement: number
  withLoadMore: boolean
}): Readonly<{
  holds: GetHoldsQueryDataT
  setHolds: React.Dispatch<React.SetStateAction<GetHoldsQueryDataT>>
  isLoading: boolean
  isError: boolean
  endpointName: string | undefined
  error: Pick<CopyErrorDataT, 'errorData'>
  handleLoadMore: () => void
  fetchGetHoldsByPhoneNumberCallback: () => Promise<
    GetHoldsQueryDataT | HoldsErrorMessageT | undefined
  >
}> => {
  const refMounted = useHoldsRefMounted()

  const [
    fetchGetHoldsByPhoneNumber,
    { isLoading, isError, endpointName, error }
  ] = useGetHoldsByPhoneNumberMutation()

  const [offsetOriginal, setOffsetOriginal] = useState(0)
  const [holds, setHolds] = useState<GetHoldsQueryDataT>(HOLDS_INITIAL_STATE)

  const refOffsetShiftDecrement = useHoldsRefState(offsetShiftDecrement)
  const refHolds = useHoldsRefState(holds)
  const refWithLoadMore = useHoldsRefState(withLoadMore)

  const handleLoadMore = useCallback(() => {
    if (!refMounted.current) {
      return
    }

    if (refHolds.current.total > offsetOriginal) {
      setOffsetOriginal(offsetOriginal + HOLDS_REQUEST_LIMIT)
    }
  }, [offsetOriginal, refHolds, refMounted])

  const fetchGetHoldsByPhoneNumberCallback = useCallback(async () => {
    try {
      const response = await fetchGetHoldsByPhoneNumber({
        direction: 'DESC',
        customerPhoneNumber,
        offset: offsetOriginal - refOffsetShiftDecrement.current
      }).unwrap()

      if (response?.data) {
        if (refWithLoadMore.current) {
          setHolds((prevState) => {
            const data = [...prevState.data]

            /**
             * FYI: detect collision by ticketNumber and prevent
             * if exist - replace on new Hold data
             * everything else - add to the end
             */
            const hashMapHoldsTicketNumbers = new Map(
              response.data.map((hold) => [hold.ticketNumber, hold])
            )

            data.forEach((hold, index) => {
              const findHold = hashMapHoldsTicketNumbers.get(hold.ticketNumber)

              if (findHold) {
                data[index] = findHold

                hashMapHoldsTicketNumbers.delete(hold.ticketNumber)
              }
            })

            data.push(...Array.from(hashMapHoldsTicketNumbers.values()))

            return {
              data,
              total: response.total
            }
          })
        } else {
          setHolds(response)
        }
      }

      if ((response as ErrorT).message) {
        return {
          errorMessage: (response as ErrorT).message
        }
      }

      return response
    } catch (e) {
      return {
        errorMessage: (e as ErrorT).message ?? (e as ErrorT).error
      }
    }
  }, [
    fetchGetHoldsByPhoneNumber,
    customerPhoneNumber,
    offsetOriginal,
    refOffsetShiftDecrement,
    refWithLoadMore
  ])

  useEffect(() => {
    if (!customerPhoneNumber) {
      setOffsetOriginal(0)
      setHolds(HOLDS_INITIAL_STATE)
    }
  }, [customerPhoneNumber])

  return {
    holds,
    setHolds,
    isLoading,
    isError,
    endpointName,
    error: error as Pick<CopyErrorDataT, 'errorData'>,
    handleLoadMore,
    fetchGetHoldsByPhoneNumberCallback
  } as const
}

export default useFetchGetHoldsByPhoneNumber
