type ProductWithNotes = {
  rmsSku: string
  webStyleId?: string
  note?: string
  productOrder?: number
}

// if adding a new product source, you must first add it in curation_svc
// https://git.jwn.app/TM01377/APP07384-curation_svc/-/blob/main/src/models/curationproduct.ts
export type ProductSourceType =
  | 'fashionMapViews'
  | 'iframe'
  | 'recentItemAdds'
  | 'recentItemViews'
  | 'scannerAdds'
  | 'scannerViews'
  | 'seedItemRecViews'
  | 'wishlistAdds'

export type ProductDiscovery = {
  rmsSkus: { [key: string]: ProductSourceType }
  webStyleIds: { [key: string]: ProductSourceType }
}

export const determineProductSource = (
  product: ProductWithNotes,
  productDiscovery: ProductDiscovery
): ProductSourceType => {
  if (Object.keys(productDiscovery.rmsSkus).includes(product.rmsSku)) {
    return productDiscovery.rmsSkus[product.rmsSku]
  }
  if (
    product.webStyleId &&
    Object.keys(productDiscovery.webStyleIds).includes(product.webStyleId)
  ) {
    return productDiscovery.webStyleIds[product.webStyleId]
  }
  return 'iframe'
}
