import { ReactElement } from 'react'
import { Box, Grow, Stack } from '@mui/material'
import { HoldT } from 'types/Holds'

import holdsGetGrowTimeout from '../utils/holdsGetGrowTimeout'
import HoldsBoxDivider from './HoldsBoxDivider'
import { HoldsProductItemCardMemo } from './HoldsProductItemCard'

const HoldsProductItemCards = ({
  holds,
  handleUpdateDetails,
  handleCloseHoldDetailsDialog
}: {
  holds: HoldT[]
  handleUpdateDetails: (hold: HoldT) => void
  handleCloseHoldDetailsDialog?: (hold: HoldT) => void
}): ReactElement => (
  <Stack direction="column" spacing={2} divider={<HoldsBoxDivider />}>
    {holds.map((hold, index) => (
      <Grow
        key={hold.ticketNumber}
        in
        timeout={holdsGetGrowTimeout({
          index
        })}
      >
        <Box>
          <HoldsProductItemCardMemo
            hold={hold}
            handleUpdateDetails={handleUpdateDetails}
            handleCloseHoldDetailsDialog={handleCloseHoldDetailsDialog}
          />
        </Box>
      </Grow>
    ))}
  </Stack>
)

export default HoldsProductItemCards
