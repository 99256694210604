import { ReactElement } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ForumIcon from '@mui/icons-material/Forum'
import useWindowDimensions from 'utils/useWindowDimensions'

type PropsT = {
  setIsCustomersDialogOpen: (arg: boolean) => void
}

const EmptyConversationList = ({
  setIsCustomersDialogOpen
}: PropsT): ReactElement => {
  const { height } = useWindowDimensions()

  const openCustomerSearch = () => {
    setIsCustomersDialogOpen(true)
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          height: height - 72,
          justifyContent: 'center'
        }}
        data-testid="emptyConversationList"
      >
        <div
          style={{
            display: 'flex',
            marginTop: '-72px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '250px'
          }}
        >
          <Box
            sx={{
              backgroundColor: '#EBEFF1',
              width: '67px',
              height: '67px',
              borderRadius: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ForumIcon color="action" fontSize="large" />
          </Box>
          <Typography
            sx={{ marginTop: '16px', marginBottom: '4px' }}
            variant="subtitle2"
            color="text.primary"
          >
            No text messages
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            textAlign="center"
          >
            Messages with your customers will appear here
          </Typography>
          <Box>
            <Button
              sx={{
                color: 'primary',
                borderRadius: '5px',
                borderOutline: '5px',
                marginTop: '16px'
              }}
              onClick={openCustomerSearch}
              variant="outlined"
            >
              Start New Message
            </Button>
          </Box>
        </div>
      </Box>
    </>
  )
}

export default EmptyConversationList
