import { ReactElement } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HashRouter, Route } from 'react-router-dom'
import { Typography, Container } from '@mui/material'

import Snapshot from './components/Snapshot'
import SnapshotNavigation from './components/SnapshotNavigation'
import RecentPurchaseDetails from './pages/RecentPurchaseDetails'
import RecentPurchasesList from './pages/RecentPurchasesList'
import StyleBoardsList from './pages/StyleBoardsList'
import WishList from 'components/WishList'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

export type PropsT = {
  handleClickForPdp: (webstyleId: string) => void
  id: string
  isSellerInitiated: boolean
  onClose: () => void
  shopperId?: string
  token?: string
}

const SnapshotContent = (props: PropsT): ReactElement => {
  const featureFlags = useFeatureFlags()
  const didFeatureFlagsLoad = !!Object.keys(featureFlags)?.length

  const {
    id,
    handleClickForPdp,
    isSellerInitiated,
    onClose,
    shopperId,
    token
  } = props

  return (
    <ErrorBoundary
      fallback={
        <Container>
          <SnapshotNavigation isCloseButton onClick={onClose} />
          <Typography mt={2} align="center" variant="body2" color="error">
            Oops. Something is not right.
          </Typography>
        </Container>
      }
    >
      <HashRouter basename="snapshot" hashType="noslash">
        <Route exact path="/">
          <Snapshot
            customerId={id}
            handleClickForPdp={handleClickForPdp}
            isSellerInitiated={isSellerInitiated}
            onClose={onClose}
          />
        </Route>

        <Route exact path="/purchase-history">
          <RecentPurchasesList customerId={id} sourcePath="/purchase-history" />
        </Route>

        <Route exact path="/purchase-history/order/:orderNumber">
          <RecentPurchaseDetails
            handleClickForPdp={handleClickForPdp}
            customerId={id}
            routedFromSnapshot={true}
          />
        </Route>

        {didFeatureFlagsLoad && (
          <Route exact path="/wish-list">
            <WishList
              customerId={id}
              handleClickForPdp={handleClickForPdp}
              shopperId={shopperId}
              token={token}
            />
          </Route>
        )}

        <Route exact path="/style-boards">
          <StyleBoardsList customerId={id} perPage={20} />
        </Route>
      </HashRouter>
    </ErrorBoundary>
  )
}

export default SnapshotContent
