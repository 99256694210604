import { CurationSliceT } from 'app/curationSlice'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'

export enum CustomerDossierStatusT {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export const generateCustomerSummaryLog = ({
  curationId,
  customerId,
  status
}: {
  curationId: number
  customerId: string
  status: CustomerDossierStatusT
}): void => {
  generateNewRelicLogs('generateSummary', {
    status,
    curationId,
    ocpId: customerId
  })
}

export const generateCustomerSummaryFeedbackDetailsLog = ({
  curationSlice,
  customerSummaryFeedbackDetails
}: {
  curationSlice?: CurationSliceT
  customerSummaryFeedbackDetails: string[]
}): void => {
  generateNewRelicLogs('customerSummaryFeedbackDetails', {
    ...(curationSlice
      ? { ...mapCurationSliceToNewRelicLog(curationSlice) }
      : {}),
    notAccurate: customerSummaryFeedbackDetails.includes('notAccurate'),
    notUseful: customerSummaryFeedbackDetails.includes('notUseful'),
    hardToUnderstand:
      customerSummaryFeedbackDetails.includes('hardToUnderstand')
  })
}

export enum CustomerSummaryFeedback {
  HELPFUL = 'helpful',
  UNHELPFUL = 'unhelpful'
}

export const generateCustomerSummaryFeedbackLog = ({
  curationSlice,
  customerSummaryFeedbackVote
}: {
  curationSlice?: CurationSliceT
  customerSummaryFeedbackVote: CustomerSummaryFeedback
}): void => {
  generateNewRelicLogs('customerSummaryFeedback', {
    ...(curationSlice
      ? { ...mapCurationSliceToNewRelicLog(curationSlice) }
      : {}),
    customerSummaryFeedbackVote
  })
}
