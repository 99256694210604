import { CurationItemT, OrderedItemsT } from 'types/Curation'
export const ORDERED_ITEMS_STORAGE_KEY = 'draft_ordered_items_storage'

export const saveDraftOrderedItems = ({
  curationId,
  orderedItems
}: {
  curationId: number
  orderedItems: string[]
}): undefined => {
  const draftOrderedItems = JSON.parse(
    localStorage.getItem(ORDERED_ITEMS_STORAGE_KEY) || '{}'
  )
  const newDraftOrderedItems = {
    ...draftOrderedItems,
    [curationId]: orderedItems
  }
  localStorage.setItem(
    ORDERED_ITEMS_STORAGE_KEY,
    JSON.stringify(newDraftOrderedItems)
  )
  return
}

export const loadDraftOrderedItems = (
  curationId: number
): string[] | undefined => {
  const orderedItemsFromLocalStorage: OrderedItemsT | string[] = JSON.parse(
    localStorage.getItem(ORDERED_ITEMS_STORAGE_KEY) || '{}'
  )[curationId]

  let orderedItemsToReturn = orderedItemsFromLocalStorage as string[]

  // if the stored ordered items data is in the old format
  if (
    Array.isArray(orderedItemsFromLocalStorage) &&
    orderedItemsFromLocalStorage.length > 0 &&
    typeof orderedItemsFromLocalStorage[0] === 'object' &&
    'rmsSku' in (orderedItemsFromLocalStorage[0] as CurationItemT)
  ) {
    // transform it into what the component expects
    orderedItemsToReturn = (orderedItemsFromLocalStorage as OrderedItemsT).map(
      (item) => item.rmsSku
    )
  }

  return orderedItemsToReturn
}

export const deleteDraftOrderedItems = (curationId: number): undefined => {
  const draftOrderedItems = JSON.parse(
    localStorage.getItem(ORDERED_ITEMS_STORAGE_KEY) || '{}'
  )
  delete draftOrderedItems[curationId]
  localStorage.setItem(
    ORDERED_ITEMS_STORAGE_KEY,
    JSON.stringify(draftOrderedItems)
  )
  return
}
