import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  ListItem,
  Button,
  List,
  Box,
  Typography,
  useTheme
} from '@mui/material'
import moment from 'moment'

import { useGetCustomersQuery } from 'services/employeeExperienceApi'
import { useGetEditRequestsInQueueQuery } from 'services/curationSvc'
import { EditRequestCurationDetailsT } from 'types/Curation'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import EmptyQueue, { EmptyQueueIconOption } from 'components/EmptyQueue'
import NotFound from 'pages/NotFound'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const EditRequestsContent = (): ReactElement => {
  const history = useHistory()
  const theme = useTheme()
  const { isEditRequestEnabledPersonalBook } = useFeatureFlags()

  const {
    data: getQueueData,
    endpointName: getQueueEndpointName,
    error: getQueueError,
    isError: isGetQueueError,
    isLoading: isGetQueueLoading
  } = useGetEditRequestsInQueueQuery()

  const customerIds = [
    ...(getQueueData?.editRequests?.map(({ ocpId }) => ocpId) || [])
  ].filter((customerId): customerId is string => customerId !== null)

  const editRequests = getQueueData?.editRequests || []

  const {
    data: getCustomersData,
    endpointName: getCustomersEndpointName,
    error: getCustomersError,
    isError: isGetCustomersError,
    isLoading: isGetCustomersLoading
  } = useGetCustomersQuery(customerIds, {
    skip: isGetQueueLoading
  })

  const renderEditRequest = (
    editRequest: EditRequestCurationDetailsT,
    index: number
  ) => {
    const customer = getCustomersData?.customers.find(
      ({ customerId }) => editRequest.ocpId === customerId
    )

    const handleClick = () => {
      history.push({
        pathname: `/styleboards/${editRequest.curationId}/edit`,
        search: `?forEditRequest`
      })
    }

    return (
      <ListItem
        divider
        disableGutters
        key={index}
        secondaryAction={
          <Button onClick={handleClick} variant="contained">
            Start Edits
          </Button>
        }
      >
        <Box>
          <Typography variant="body2">{customer?.fullName}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography
                component="span"
                variant="body2"
                color={theme.palette.secondary.main}
              >
                {editRequest.curationOccasions?.join(', ')}
              </Typography>
              <Typography component="span" variant="body2">{` | `}</Typography>
              <Typography
                component="span"
                variant="body2"
                color={theme.palette.secondary.main}
              >
                {moment(editRequest.createdAt).format('MMM DD')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ListItem>
    )
  }

  const isLoading = isGetQueueLoading || isGetCustomersLoading

  if (isGetQueueError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getQueueEndpointName,
          errorData: getQueueError
        }}
      />
    )
  }

  if (isGetCustomersError) {
    return (
      <PageError
        errorDetails={{
          endpoint: getCustomersEndpointName,
          errorData: getCustomersError
        }}
      />
    )
  }

  if (!isLoading && !editRequests.length) {
    return (
      <EmptyQueue
        title="No edit requests"
        subtitle="New edit requests from customers will appear here"
        icon={EmptyQueueIconOption.EDIT}
      />
    )
  }

  if (!isEditRequestEnabledPersonalBook?.active) {
    return <NotFound />
  }

  return (
    <Container>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <List>{editRequests.map(renderEditRequest)}</List>
      )}
    </Container>
  )
}

export default EditRequestsContent
