import { HoldsStartNewHoldFormParamsT } from 'pages/Holds/types'
import { useMemo } from 'react'

export enum HoldsStartNewHoldErrorMessages {
  CUSTOMER_NAME_REQUIRED = 'Customer name is required.',
  HOLD_TYPE_REQUIRED = 'Hold type is required.',
  ITEMS_ON_HOLD_REQUIRED = 'Items on hold are required.',
  ALL_FIELDS_REQUIRED = 'All fields required.'
}

type HoldsGetErrorMessageT = Readonly<{
  errorMessage: false | HoldsStartNewHoldErrorMessages
  hasErrorMessage: boolean
}>

const useHoldsGetErrorMessage = ({
  formParams
}: {
  formParams: HoldsStartNewHoldFormParamsT
}): HoldsGetErrorMessageT => {
  const hasErrorCustomerName = !formParams.customerInfo.customerMobileNumber
  const hasErrorHoldType = !formParams.holdType
  const hasErrorItemsOnHold = !formParams.itemsOnHold.length

  const errorMessage = useMemo(() => {
    const errorMessages = [
      hasErrorCustomerName &&
        HoldsStartNewHoldErrorMessages.CUSTOMER_NAME_REQUIRED,
      hasErrorHoldType && HoldsStartNewHoldErrorMessages.HOLD_TYPE_REQUIRED,
      hasErrorItemsOnHold &&
        HoldsStartNewHoldErrorMessages.ITEMS_ON_HOLD_REQUIRED
    ].filter(Boolean)

    if (errorMessages.length > 1) {
      return HoldsStartNewHoldErrorMessages.ALL_FIELDS_REQUIRED
    }

    return errorMessages[0]
  }, [hasErrorCustomerName, hasErrorHoldType, hasErrorItemsOnHold])

  const hasErrorMessage = Boolean(errorMessage)

  return {
    errorMessage,
    hasErrorMessage
  } as const
}

export default useHoldsGetErrorMessage
