import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { useTheme } from '@mui/material'
import type { Moment } from 'moment'

interface PropsT {
  label: string
  value: Moment | null
  onChange: (date: Moment) => void
  maxDate?: Moment
  minDate?: Moment
}

const StylingDatePicker = ({
  label,
  value,
  onChange,
  maxDate,
  minDate
}: PropsT): JSX.Element => {
  const theme = useTheme()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker<Moment>
        disableCloseOnSelect={false}
        label={label}
        value={value}
        onChange={(newValue: Moment | null) => {
          if (newValue) {
            onChange(newValue)
          }
        }}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              backgroundColor: theme.palette.common.white
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default StylingDatePicker
