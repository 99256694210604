import {
  createContext,
  ReactNode,
  SyntheticEvent,
  useContext,
  useEffect,
  useState
} from 'react'

import { v4 } from 'uuid'
import merge from 'lodash.merge'

import type { AlertProps, SnackbarProps, SxProps } from '@mui/material'
import { Alert, AlertTitle, IconButton, Snackbar } from '@mui/material'
import { shadows } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'

import { SNACKBAR_DURATION } from 'pages/constants'

import {
  HOLDS_STYLE_SNACKBAR_ALERT_ERROR,
  HOLDS_STYLE_SNACKBAR_ALERT_SUCCESS
} from '../constants'

import { useHoldsRefMounted, useHoldsToggle } from '../hooks'

type NotificationAlertPropsT = {
  alertProps: AlertProps
}

type NotificationSnackBarPropsT = SnackbarProps

type NotificationBaseSnackBarT = Required<
  Pick<NotificationSnackBarPropsT, 'message'>
> &
  Partial<{
    snackBarProps: Omit<NotificationSnackBarPropsT, 'message' & 'open'>
  }>

export type NotificationAlertT = {
  title: string
  subTitle?: string
} & Partial<NotificationAlertPropsT>

type NotificationKeyT = {
  key: string
}

type ContextNotificationT = {
  handleNotificationAdd: (params: NotificationBaseSnackBarT) => void
  handleNotificationAddSuccessMessage: (params: NotificationAlertT) => void
  handleNotificationAddErrorMessage: (params: NotificationAlertT) => void
  handleNotificationRemove: () => void
}

const ContextNotification = createContext<ContextNotificationT>(
  {} as ContextNotificationT
)

export const useHoldsContextNotification = (): ContextNotificationT =>
  useContext(ContextNotification)

const DEFAULT_NOTIFICATION_STATE = undefined

const DEFAULT_SNACKBAR_SX: {
  sx: SxProps
} = {
  sx: {
    width: {
      sm: 445
    },
    // default snackbar title inside paper
    '.MuiPaper-root': {
      width: {
        sm: '100%'
      }
    },
    // TODO: HOLDS use BaseSnackbar instead when onClose will be with arguments
    // this zIndex puts all snackbars on top of the Gladly widget
    // the Gladly zIndex is 2147483000, so this needs to be higher than that
    paddingBottom: '20px',
    zIndex: 2200000000
  }
}

const DEFAULT_NOTIFICATION_ALERT_SX: SxProps = {
  width: '100%',
  ...shadows({
    boxShadow: 6
  }),
  '&.MuiAlert-filledSuccess, &.MuiAlert-filled.MuiAlert-colorSuccess':
    HOLDS_STYLE_SNACKBAR_ALERT_SUCCESS,
  '&.MuiAlert-filledError, &.MuiAlert-filled.MuiAlert-colorError':
    HOLDS_STYLE_SNACKBAR_ALERT_ERROR
}

export const HoldsContextNotification = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const refMounted = useHoldsRefMounted()

  const {
    isOpen: isOpenNotification,
    handleOpen: handleOpenNotification,
    handleClose: handleCloseNotification
  } = useHoldsToggle()
  const [snackPackNotification, setSnackPackNotification] = useState<
    (NotificationKeyT & NotificationBaseSnackBarT)[]
  >([])
  const [notification, setNotification] = useState<
    | (NotificationKeyT & NotificationBaseSnackBarT)
    | typeof DEFAULT_NOTIFICATION_STATE
  >()

  const {
    isOpen: isOpenNotificationAlert,
    handleOpen: handleOpenNotificationAlert,
    handleClose: handleCloseNotificationAlert
  } = useHoldsToggle()
  const [snackPackNotificationAlert, setSnackPackNotificationAlert] = useState<
    (NotificationKeyT & NotificationAlertT)[]
  >([])
  const [notificationAlert, setNotificationAlert] = useState<
    (NotificationKeyT & NotificationAlertT) | typeof DEFAULT_NOTIFICATION_STATE
  >()

  const handleBaseNotificationAddAlert = (params: NotificationAlertT) => {
    if (!refMounted.current) {
      return
    }

    handleCloseNotification()
    setSnackPackNotificationAlert((prevState) => [
      ...prevState,
      {
        key: v4(),
        ...params
      }
    ])
  }

  const [actions] = useState(() => ({
    handleNotificationAdd: (params: NotificationBaseSnackBarT) => {
      if (!refMounted.current) {
        return
      }

      handleCloseNotificationAlert()
      setSnackPackNotification((prevState) => [
        ...prevState,
        {
          key: v4(),
          ...merge<
            NotificationBaseSnackBarT,
            Pick<NotificationBaseSnackBarT, 'snackBarProps'>
          >(params, {
            snackBarProps: DEFAULT_SNACKBAR_SX
          })
        }
      ])
    },
    handleNotificationAddSuccessMessage: (params: NotificationAlertT) => {
      handleBaseNotificationAddAlert(
        merge<NotificationAlertT, NotificationAlertPropsT>(params, {
          alertProps: {
            sx: DEFAULT_NOTIFICATION_ALERT_SX
          }
        })
      )
    },
    handleNotificationAddErrorMessage: (params: NotificationAlertT) => {
      handleBaseNotificationAddAlert(
        merge<NotificationAlertT, NotificationAlertPropsT>(params, {
          alertProps: {
            severity: 'error',
            sx: DEFAULT_NOTIFICATION_ALERT_SX
          }
        })
      )
    },
    handleNotificationRemove: () => {
      if (!refMounted.current) {
        return
      }

      handleCloseNotification()
      handleCloseNotificationAlert()
    }
  }))

  const onCloseSnackBarNotification = (
    event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    handleCloseNotification()
  }
  const onCloseSnackBarNotificationAlert = (
    event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    handleCloseNotificationAlert()
  }

  const handleExitedNotification = () => {
    setNotification(DEFAULT_NOTIFICATION_STATE)
  }
  const handleExitedNotificationAlert = () => {
    setNotificationAlert(DEFAULT_NOTIFICATION_STATE)
  }

  useEffect(() => {
    if (snackPackNotification.length && !notification) {
      // Set a new snack when we don't have an active one
      setNotification({ ...snackPackNotification[0] })
      setSnackPackNotification((prev) => prev.slice(1))
      handleOpenNotification()
    } else if (
      snackPackNotification.length &&
      notification &&
      isOpenNotification
    ) {
      // Close an active snack when a new one is added
      handleCloseNotification()
    }
  }, [
    snackPackNotification,
    notification,
    isOpenNotification,
    handleOpenNotification,
    handleCloseNotification
  ])

  useEffect(() => {
    if (snackPackNotificationAlert.length && !notificationAlert) {
      // Set a new snack when we don't have an active one
      setNotificationAlert({ ...snackPackNotificationAlert[0] })
      setSnackPackNotificationAlert((prev) => prev.slice(1))
      handleOpenNotificationAlert()
    } else if (
      snackPackNotificationAlert.length &&
      notificationAlert &&
      isOpenNotificationAlert
    ) {
      // Close an active snack when a new one is added
      handleCloseNotificationAlert()
    }
  }, [
    snackPackNotificationAlert,
    notificationAlert,
    isOpenNotificationAlert,
    handleOpenNotificationAlert,
    handleCloseNotificationAlert
  ])

  return (
    <ContextNotification.Provider value={actions}>
      <>
        {children}

        <Snackbar
          key={notification?.key}
          open={isOpenNotification}
          autoHideDuration={SNACKBAR_DURATION / 2}
          onClose={onCloseSnackBarNotification}
          TransitionProps={{ onExited: handleExitedNotification }}
          message={notification?.message}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onCloseSnackBarNotification}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          {...notification?.snackBarProps}
        />

        <Snackbar
          key={notificationAlert?.key}
          open={isOpenNotificationAlert}
          autoHideDuration={SNACKBAR_DURATION / 2}
          onClose={onCloseSnackBarNotificationAlert}
          TransitionProps={{ onExited: handleExitedNotificationAlert }}
          sx={DEFAULT_SNACKBAR_SX.sx}
        >
          <Alert
            onClose={onCloseSnackBarNotificationAlert}
            variant="filled"
            {...notificationAlert?.alertProps}
          >
            {notificationAlert?.subTitle ? (
              <>
                <AlertTitle>{notificationAlert?.title}</AlertTitle>
                {notificationAlert?.subTitle}
              </>
            ) : (
              notificationAlert?.title
            )}
          </Alert>
        </Snackbar>
      </>
    </ContextNotification.Provider>
  )
}
