import { ReactElement, ReactNode } from 'react'
import { Box } from '@mui/material'
import merge from 'lodash.merge'

interface PropsT extends React.ComponentProps<typeof Box> {
  children:
    | string
    | JSX.Element
    | JSX.Element[]
    | (() => JSX.Element)
    | ReactNode
}

const BoxWithGladlySpace = (props: PropsT): ReactElement => {
  const { children, sx, ...rest } = props
  const sxProps = merge(sx, { pb: 8 })

  return (
    <Box sx={{ ...sxProps }} {...rest}>
      {children}
    </Box>
  )
}

export default BoxWithGladlySpace
